import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import BlacklistTable from "../../../elements/blacklist/blacklist-table";
import BlacklistForm from "../../../elements/blacklist/blacklist-form";
export default function CentralBlacklist() {
    const { t } = useTranslation();
    const [isAddOpened, setIsAddOpened] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("blacklist.blacklisted-phones"), breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("blacklist.blacklisted-phones"),
                        link: "/administration/blacklist",
                    },
                ] }), _jsx(BlacklistTable, { type: "central", isAdded: isAdded, setIsAdded: setIsAdded }), _jsx(BlacklistForm, { type: "central", isAddOpened: isAddOpened, setIsAddOpened: setIsAddOpened, setIsAdded: setIsAdded })] }));
}
