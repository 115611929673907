import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../../../elements/blocks/intro";
import Nope from "../../../../elements/blocks/nope";
import PhoneAreaDetails from "../../../../elements/options/phone-areas/phone-area-details";
import { useApiTenant } from "../../../../../api/api-tenant";
export default function PhoneAreaView() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [phoneArea, setPhoneArea] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_phoneArea(id)
            .then(response => {
            setPhoneArea(response.data.phone_area);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: phoneArea ? phoneArea.name : t("options.phone-areas.phone-area") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.phone-areas.phone-areas"),
                        link: "/settings/options/phone-areas",
                    },
                    {
                        name: phoneArea ? phoneArea.name : t("options.phone-areas.phone-area"),
                        link: "/settings/options/phone-areas/" + id,
                    },
                ], button: phoneArea
                    ? {
                        label: t("options.phone-areas.edit-phone-area"),
                        link: "/settings/options/phone-areas/" + id + "/edit",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : phoneArea ? (_jsx(PhoneAreaDetails, { phoneArea: phoneArea })) : (_jsx(Nope, { title: t("errors.404"), caption: t("options.phone-areas.errors.not-found") }))] }));
}
