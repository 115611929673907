import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import InputRadio from "../inputs/input-radio";
export default function ContactSearch({ search, setSearch, showSearch, searchBy, setSearchBy, isLoading, setApplySearch, }) {
    const { t } = useTranslation();
    return (_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showSearch }, { children: [_jsxs(DialogContent, { children: [_jsx(Box, Object.assign({ sx: { paddingTop: 2 } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(OutlinedInput, { autoFocus: true, placeholder: t("search.search") +
                                    " " +
                                    t("search.contacts") +
                                    " (" +
                                    t("search.by") +
                                    " " +
                                    (searchBy === "phones"
                                        ? t("contacts.contact-attributes.phones").toLowerCase()
                                        : searchBy === "emails"
                                            ? t("contacts.contact-attributes.emails").toLowerCase()
                                            : searchBy === "full_name"
                                                ? t("contacts.contact-attributes.full-name").toLowerCase()
                                                : searchBy === "full_name,company"
                                                    ? t("contacts.contact-attributes.full-name-company").toLowerCase()
                                                    : searchBy === "first_name"
                                                        ? t("contacts.contact-attributes.first-name").toLowerCase()
                                                        : searchBy === "last_name"
                                                            ? t("contacts.contact-attributes.last-name").toLowerCase()
                                                            : searchBy === "company"
                                                                ? t("contacts.contact-attributes.company").toLowerCase()
                                                                : searchBy === "address"
                                                                    ? t("contacts.contact-attributes.address").toLowerCase()
                                                                    : searchBy === "type"
                                                                        ? t("contacts.contact-attributes.type").toLowerCase()
                                                                        : searchBy === "created_at"
                                                                            ? t("contacts.contact-attributes.created-at").toLowerCase()
                                                                            : searchBy === "updated_at"
                                                                                ? t("contacts.contact-attributes.updated-at").toLowerCase()
                                                                                : searchBy === "id"
                                                                                    ? t("contacts.contact").toLowerCase() + " ID"
                                                                                    : "OIB") +
                                    ")", value: search, onChange: event => setSearch(event.target.value), onKeyPress: event => {
                                    if (event.key === "Enter")
                                        setApplySearch(true);
                                }, startAdornment: _jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchIcon, {}) })), endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: search.length > 0 && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => setSearch(""), size: "large" }, { children: isLoading ? (_jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5, style: {
                                                marginRight: "4px",
                                            } })) : (_jsx(ClearIcon, {})) }))) })) }) })) })), _jsxs(Box, Object.assign({ sx: { paddingTop: 4 } }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: t("search.search") + " " + t("search.by") })) })), _jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                                    {
                                        value: "phones",
                                        label: t("contacts.contact-attributes.phones"),
                                    },
                                    {
                                        value: "emails",
                                        label: t("contacts.contact-attributes.emails"),
                                    },
                                    {
                                        value: "full_name,company",
                                        label: t("contacts.contact-attributes.full-name-company"),
                                    },
                                    {
                                        value: "full_name",
                                        label: t("contacts.contact-attributes.full-name"),
                                    },
                                    {
                                        value: "company",
                                        label: t("contacts.contact-attributes.company"),
                                    },
                                    {
                                        value: "address",
                                        label: t("contacts.contact-attributes.address"),
                                    },
                                    {
                                        value: "oib",
                                        label: "OIB",
                                    },
                                    {
                                        value: "id",
                                        label: t("contacts.contact") + " ID",
                                    },
                                ], value: searchBy ? searchBy : "phones", setValue: setSearchBy })] }))] }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", loading: isLoading, disabled: isLoading, onClick: () => {
                            setApplySearch(true);
                        }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("search.search") })) })) })] })));
}
