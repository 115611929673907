import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { withStyles, createStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import PlusIcon from "@mui/icons-material/AddBoxOutlined";
import MinusIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Settings";
import CampaignStepsForm from "./campaign-steps-form";
import { prettifyText } from "../../../../helpers/clean";
import { useRoles } from "../../../../services/roles";
import { useApiTenant } from "../../../../api/api-tenant";
const StyledTreeItem = withStyles((theme) => createStyles({
    iconContainer: {
        "& .close": {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 15,
        paddingLeft: 8,
        borderLeft: `1px dashed ${theme.palette.text.disabled}`,
    },
    label: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: "60px",
    },
}))((props) => _jsx(TreeItem, Object.assign({}, props)));
export default function CampaignStepsView({ campaign }) {
    const { t } = useTranslation();
    const roles = useRoles();
    const api = useApiTenant();
    const [steps, setSteps] = useState([]);
    const [selectedStep, setSelectedStep] = useState(null);
    const [selectedParentStep, setSelectedParentStep] = useState(null);
    const [selectedStepNode, setSelectedStepNode] = useState("");
    const [stepStatus, setStepStatus] = useState(null);
    const [refreshSteps, setRefreshSteps] = useState(false);
    const [editCustomField, setEditCustomField] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchCampaignSteps();
        setStepStatus(null);
        setRefreshSteps(false);
        return () => {
            api.cancel();
        };
    }, [refreshSteps]);
    const fetchCampaignSteps = () => {
        api.GET_campaignSteps(campaign.id)
            .then(response => {
            setSteps(response.data.campaign_steps);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    let flatStepList = new Array();
    const flattenList = (steps, level) => {
        return steps.map(step => {
            if (flatStepList) {
                if (!flatStepList.find(el => el.id === step.id)) {
                    flatStepList.push(step);
                }
            }
            if (step.campaign_steps && step.campaign_steps.length > 0) {
                step.campaign_steps.map(step1 => flatStepList.push(step1));
                flattenList(step.campaign_steps, level + 1);
            }
        });
    };
    flattenList(steps, 0);
    const treeItems = (steps) => {
        return steps.map((step, index) => {
            var _a;
            let children = null;
            if (step.campaign_steps && step.campaign_steps.length > 0) {
                children = treeItems(step.campaign_steps);
            }
            return (_jsxs(Box, Object.assign({ sx: {
                    position: "relative",
                    width: "100%",
                    paddingTop: 1,
                } }, { children: [_jsx(StyledTreeItem, { nodeId: step.id.toString(), label: _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Tooltip, Object.assign({ title: step.shared_campaign_step_id
                                        ? prettifyText(step.type +
                                            " (" +
                                            t("campaigns.campaign-steps.shared") +
                                            ")")
                                        : prettifyText(step.type), placement: "right", arrow: true }, { children: _jsx(Typography, { children: step.name }) })) })) })), children: children }, index), _jsxs(Box, Object.assign({ style: {
                            opacity: selectedStepNode == step.id ? 1 : 0,
                            visibility: selectedStepNode == step.id ? "initial" : "hidden",
                            position: "absolute",
                            top: 0,
                            right: "4px",
                            transition: "all .3s",
                        } }, { children: [roles.isAdmin &&
                                ((step.type !== "choice" &&
                                    step.type !== "group" &&
                                    ((_a = step.campaign_steps) === null || _a === void 0 ? void 0 : _a.length) === 0) ||
                                    step.type === "choice") && (_jsx(Tooltip
                            // @ts-ignore
                            , Object.assign({ 
                                // @ts-ignore
                                title: t("campaigns.campaign-steps.campaign-steps-view.new-child-step"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                        setSelectedStep(step);
                                        setSelectedParentStep(step);
                                        setStepStatus("new");
                                        setEditCustomField(false);
                                    }, size: "large" }, { children: _jsx(AddIcon, {}) })) }))), _jsx(Tooltip
                            // @ts-ignore
                            , Object.assign({ 
                                // @ts-ignore
                                title: t("campaigns.campaign-steps.campaign-steps-view.edit-step"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                        let parent = flatStepList.find(a => a.id === step.parent_campaign_step_id);
                                        setSelectedStep(step);
                                        setSelectedParentStep(parent ? parent : null);
                                        setStepStatus("edit");
                                        setEditCustomField(false);
                                    }, size: "large" }, { children: _jsx(EditIcon, {}) })) }))] }))] }), index));
        });
    };
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 5 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                            position: "relative",
                            padding: 4,
                        } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                            } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsxs(Fragment, { children: [steps.length > 0 && (_jsx(TreeView, Object.assign({ selected: selectedStepNode, onNodeSelect: (event, value) => {
                                        setSelectedStepNode(value);
                                        setStepStatus(null);
                                    }, defaultExpandIcon: _jsx(PlusIcon, {}), defaultCollapseIcon: _jsx(MinusIcon, {}), defaultEndIcon: _jsx(PlusIcon, { color: "disabled" }) }, { children: treeItems(steps) }))), roles.isAdmin && steps.length === 0 && (_jsx(Box, Object.assign({ sx: {
                                        paddingTop: steps.length > 0 ? 2 : 0,
                                        paddingLeft: 0.5,
                                    } }, { children: _jsx(Button, Object.assign({ size: "small", color: "inherit", startIcon: _jsx(AddIcon, {}), onClick: () => {
                                            setSelectedStep(null);
                                            setSelectedParentStep(null);
                                            setSelectedStepNode("");
                                            setStepStatus("new");
                                        } }, { children: t("campaigns.campaign-steps.campaign-steps-view.add-first-step") })) })))] })) })) }) })), (stepStatus === "new" || stepStatus === "edit") && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 7 }, { children: _jsx(Box, Object.assign({ sx: {
                        position: "sticky",
                        top: "100px",
                    } }, { children: _jsx(CampaignStepsForm, { status: stepStatus, campaign: campaign, step: selectedStep, parentStep: selectedParentStep, editCustomField: editCustomField, setEditCustomField: setEditCustomField, setRefreshSteps: setRefreshSteps }) })) })))] })));
}
