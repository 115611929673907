import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import { useAlert } from "../../../../services/alert";
import { copy } from "../../../../helpers/copy";
export default function CustomFieldColumn({ displayOption, customFieldValue }) {
    var _a, _b;
    const { t } = useTranslation();
    const alert = useAlert();
    return displayOption.custom_field && customFieldValue ? (displayOption.custom_field.field_type == "date_time" ? (displayOption.custom_field.date_time_type == "time" ? (_jsx(Typography, { children: new Date(Date.parse(customFieldValue)).toLocaleTimeString("hr-HR", {
            hour: "2-digit",
            minute: "2-digit",
        }) })) : displayOption.custom_field.date_time_type == "date" ? (_jsx(Typography, { children: new Date(Date.parse(customFieldValue)).toLocaleDateString("en-GB") })) : (_jsx(Typography, { children: new Date(Date.parse(customFieldValue)).toLocaleDateString("en-GB") +
            " " +
            new Date(Date.parse(customFieldValue)).toLocaleTimeString("hr-HR", {
                hour: "2-digit",
                minute: "2-digit",
            }) }))) : displayOption.custom_field.field_type == "text" ||
        displayOption.custom_field.field_type == "text_area" ||
        displayOption.custom_field.field_type == "number" ||
        displayOption.custom_field.field_type == "text_select" ||
        displayOption.custom_field.field_type == "radio_text" ||
        displayOption.custom_field.field_type == "checkbox" ||
        displayOption.custom_field.field_type == "slider" ||
        displayOption.custom_field.field_type == "rating" ||
        displayOption.custom_field.field_type == "range" ||
        displayOption.custom_field.field_type == "radio_image" ||
        displayOption.custom_field.field_type == "radio_icon" ||
        displayOption.custom_field.field_type == "conversation" ? (_jsx(Typography, { children: customFieldValue.substring(0, 75) })) : displayOption.custom_field.field_type == "html_text" ? (_jsx(Typography, { dangerouslySetInnerHTML: {
            __html: customFieldValue,
        } })) : displayOption.custom_field.field_type == "switch" ? (_jsx(Typography, { children: customFieldValue === "1" ? t("yes") : t("no") })) : displayOption.custom_field.field_type == "file" ? (_jsx(Link, Object.assign({ href: customFieldValue.file_url, target: "_blank", underline: "hover", color: "primary" }, { children: customFieldValue.file_name }))) : displayOption.custom_field.field_type == "link" ? (_jsx(Link, Object.assign({ href: customFieldValue, target: "_blank", underline: "hover", color: "primary" }, { children: customFieldValue }))) : displayOption.custom_field.field_type == "address" ? (_jsx(Link, Object.assign({ href: `https://www.google.com/maps/search/?api=1&query=${encodeURI(((_a = customFieldValue.address.street_name) !== null && _a !== void 0 ? _a : "") +
            (customFieldValue.address.street_name &&
                customFieldValue.address.street_number
                ? ` ${customFieldValue.address.street_number}`
                : "") +
            (customFieldValue.address.street_name && customFieldValue.address.city
                ? `, ${customFieldValue.address.city}`
                : "") +
            (!customFieldValue.address.street_name && customFieldValue.address.city
                ? customFieldValue.address.city
                : "") +
            ((customFieldValue.address.street_name || customFieldValue.address.city) &&
                customFieldValue.address.country
                ? `, ${customFieldValue.address.country}`
                : "") +
            (!customFieldValue.address.street_name &&
                !customFieldValue.address.city &&
                customFieldValue.address.country
                ? customFieldValue.address.country
                : ""))}`, target: "_blank", underline: "hover" }, { children: ((_b = customFieldValue.address.street_name) !== null && _b !== void 0 ? _b : "") +
            (customFieldValue.address.street_name && customFieldValue.address.street_number
                ? ` ${customFieldValue.address.street_number}`
                : "") +
            (customFieldValue.address.street_name && customFieldValue.address.city
                ? `, ${customFieldValue.address.city}`
                : "") +
            (!customFieldValue.address.street_name && customFieldValue.address.city
                ? customFieldValue.address.city
                : "") +
            ((customFieldValue.address.street_name || customFieldValue.address.city) &&
                customFieldValue.address.country
                ? `, ${customFieldValue.address.country}`
                : "") +
            (!customFieldValue.address.street_name &&
                !customFieldValue.address.city &&
                customFieldValue.address.country
                ? customFieldValue.address.country
                : "") }))) : displayOption.custom_field.field_type == "phone" ? (_jsx(Chip, { size: "small", variant: "outlined", label: customFieldValue, onDelete: () => {
            copy(customFieldValue);
            alert.show(t("phone-copied"), "info");
        }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
            // @ts-ignore
            title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) })) : displayOption.custom_field.field_type == "mutable_number" ? (_jsx(Typography, { children: customFieldValue })) : (_jsx(Fragment, {}))) : (displayOption.campaign_step && _jsx(Typography, { children: customFieldValue }));
}
