import ReactGA from "react-ga4";
const initGA = () => {
    if (process.env.APP_ENV !== "production")
        return;
    // @ts-ignore
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS, {
    // debug: true,
    });
};
const sendPageView = (path, user) => {
    if (process.env.APP_ENV !== "production")
        return;
    if (user)
        ReactGA.set({ userId: user.id });
    ReactGA.send({ hitType: "pageview", page: path });
};
const sendModalView = (path, user) => {
    if (process.env.APP_ENV !== "production")
        return;
    if (user)
        ReactGA.set({ userId: user.id });
    ReactGA.send({ hitType: "pageview", page: path });
};
const sendEvent = (payload, user) => {
    if (process.env.APP_ENV !== "production")
        return;
    if (user)
        ReactGA.set({ userId: user.id });
    ReactGA.event(payload);
};
export default {
    initGA,
    sendPageView,
    sendModalView,
    sendEvent,
};
