import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import PersonIcon from "@mui/icons-material/Person";
import AccountIcon from "@mui/icons-material/AccountCircle";
import SignOutIcon from "@mui/icons-material/ExitToApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTenancy } from "../../../services/tenancy";
import { usePrefs } from "../../../services/prefs";
import { useAuth } from "../../../services/auth";
import { useRoles } from "../../../services/roles";
import { useApiCentral } from "../../../api/api-central";
import analytics from "../../../helpers/analytics";
const StyledBadge = withStyles(theme => ({
    badge: {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badge);
export default function UserMenu() {
    var _a, _b;
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const prefs = usePrefs();
    const auth = useAuth();
    const tenancy = useTenancy();
    const api = useApiCentral();
    const roles = useRoles();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const [lang, setLang] = useState(prefs.lang);
    const [theme, setTheme] = useState(prefs.theme);
    const [saveChanges, setSaveChanges] = useState(false);
    useEffect(() => {
        if (location.pathname.includes("/login"))
            return;
        setMenuOpen(false);
    }, [location]);
    useEffect(() => {
        if (!saveChanges)
            return;
        api.UPDATE_userOptions(lang, theme === "dark")
            .then(() => { })
            .catch(error => {
            console.error(error.response);
        });
        setSaveChanges(false);
    }, [saveChanges]);
    return (auth.user && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginRight: "-8px",
                } }, { children: _jsx(Button, Object.assign({ onClick: () => setMenuOpen(!isMenuOpen), ref: anchorRef, color: "inherit" }, { children: _jsx(StyledBadge, Object.assign({ overlap: "circular", anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        }, variant: "dot" }, { children: auth.user.avatar_url || ((_a = roles.userTenant) === null || _a === void 0 ? void 0 : _a.avatar_url) ? (_jsx(Avatar, { src: auth.user.avatar_url
                                ? auth.user.avatar_url
                                : (_b = roles.userTenant) === null || _b === void 0 ? void 0 : _b.avatar_url, imgProps: {
                                style: {
                                    objectFit: "contain",
                                },
                            }, style: {
                                backgroundColor: "#fff",
                            } })) : (_jsx(Avatar, Object.assign({ style: {
                                color: "#000",
                                backgroundColor: "#fff",
                            } }, { children: auth.user.first_name ? (auth.user.first_name[0].toUpperCase() +
                                (auth.user.last_name
                                    ? auth.user.last_name[0].toUpperCase()
                                    : "")) : (_jsx(PersonIcon, {})) }))) })) })) })), _jsxs(Menu, Object.assign({ open: isMenuOpen, onBackdropClick: () => setMenuOpen(false), anchorEl: anchorRef.current, TransitionComponent: Fade }, { children: [_jsx(MenuItem, Object.assign({ to: tenancy.link("/account"), selected: location.pathname.includes(tenancy.link("/account")), component: Link, disabled: !roles.roleTenant }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexGrow: 1,
                                justifyContent: "space-between",
                                alignItems: "center",
                            } }, { children: [_jsx(Box, Object.assign({ component: "span" }, { children: t("users.account") })), _jsx(Box, Object.assign({ component: "span", sx: {
                                        display: "flex",
                                    } }, { children: _jsx(AccountIcon, {}) }))] })) })), ((process.env.APP_ID === "mcd" && tenancy.client && !roles.isAgent) ||
                        process.env.APP_ID !== "mcd") && (_jsx(MenuItem, Object.assign({ disabled: !roles.roleTenant, to: tenancy.link("/notifications"), selected: location.pathname.includes(tenancy.link("/notifications")), component: Link }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexGrow: 1,
                                justifyContent: "space-between",
                                alignItems: "center",
                            } }, { children: [_jsx(Box, Object.assign({ component: "span" }, { children: t("notifications.notifications") })), _jsx(Box, Object.assign({ component: "span", sx: {
                                        display: "flex",
                                    } }, { children: _jsx(NotificationsIcon, {}) }))] })) }))), _jsx(Box, Object.assign({ sx: {
                            marginY: 1,
                        } }, { children: _jsx(Divider, { variant: "middle" }) })), _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "text", color: "inherit", fullWidth: true }, { children: [_jsx(Button, Object.assign({ disabled: prefs.lang === "en", onClick: () => {
                                    i18n.changeLanguage("en").then(() => {
                                        prefs.setLang("en");
                                    });
                                    setLang("en");
                                    setSaveChanges(true);
                                    analytics.sendEvent({
                                        category: "Prefs",
                                        action: "English",
                                    }, auth.user);
                                } }, { children: "English" })), _jsx(Button, Object.assign({ disabled: prefs.lang === "hr", onClick: () => {
                                    i18n.changeLanguage("hr").then(() => {
                                        prefs.setLang("hr");
                                    });
                                    setLang("hr");
                                    setSaveChanges(true);
                                    analytics.sendEvent({
                                        category: "Prefs",
                                        action: "Hrvatski",
                                    }, auth.user);
                                } }, { children: "Hrvatski" }))] })), _jsx(Box, Object.assign({ sx: {
                            marginY: 1,
                        } }, { children: _jsx(Divider, { variant: "middle" }) })), _jsx(MenuItem, { children: _jsx(Box, Object.assign({ sx: {
                                paddingX: 1,
                            } }, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", size: "small", checked: prefs.theme === "dark", onChange: () => {
                                        prefs.setTheme(prefs.theme === "light" ? "dark" : "light");
                                        setTheme(prefs.theme === "light" ? "dark" : "light");
                                        setSaveChanges(true);
                                        analytics.sendEvent({
                                            category: "Prefs",
                                            action: (prefs.theme === "light"
                                                ? "Dark"
                                                : "Light") + " theme",
                                        }, auth.user);
                                    } }), label: _jsx(Box, Object.assign({ sx: {
                                        paddingX: 1,
                                    } }, { children: t("header.user-menu.dark-mode") })) }) })) }), _jsx(Box, Object.assign({ sx: {
                            marginY: 1,
                        } }, { children: _jsx(Divider, { variant: "middle" }) })), _jsx(MenuItem, Object.assign({ onClick: auth.logOut }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexGrow: 1,
                                justifyContent: "space-between",
                                alignItems: "center",
                            } }, { children: [_jsx(Box, Object.assign({ component: "span" }, { children: t("header.user-menu.log-out") })), _jsx(Box, Object.assign({ component: "span", sx: {
                                        display: "flex",
                                    } }, { children: _jsx(SignOutIcon, {}) }))] })) }))] }))] })));
}
