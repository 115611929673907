import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Nope from "../../../../elements/blocks/nope";
import Intro from "../../../../elements/blocks/intro";
import CustomFieldForm from "../../../../elements/custom-fields/custom-field-form";
import CustomFieldDelete from "../../../../elements/custom-fields/custom-field-delete";
import { useApiTenant } from "../../../../../api/api-tenant";
export default function GroupFieldEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [customField, setCustomField] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_customField(id)
            .then(response => {
            setCustomField(response.data.custom_field);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: customField ? customField.field_name : t("fields.field") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("groups.groups"),
                        link: "/settings/fields/groups",
                    },
                    {
                        name: customField ? customField.field_name : t("fields.field"),
                        link: "/settings/fields/groups/" + id,
                    },
                    {
                        name: t("fields.edit-field"),
                        link: "/settings/fields/groups/" + id + "/edit",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : customField ? (_jsxs(Fragment, { children: [_jsx(CustomFieldForm, { status: "edit", type: "tenant_groups", customField: customField }), _jsx(CustomFieldDelete, { type: "tenant_groups", customField: customField })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("fields.group-fields.errors.not-found") }))] }));
}
