import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Nope from "../../elements/blocks/nope";
import Intro from "../../elements/blocks/intro";
import AudienceForm from "../../elements/campaigns/audience/audience-form";
import { useApiTenant } from "../../../api/api-tenant";
export default function AudienceNew() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [campaign, setCampaign] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_campaign(id)
            .then(response => {
            setCampaign(response.data.campaign);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("campaigns.audiences.new-audience"), breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: campaign ? campaign.name : t("campaigns.campaign") + " #" + id,
                        link: "/campaigns/" + id,
                    },
                    {
                        name: t("campaigns.audiences.new-audience"),
                        link: "/campaigns/" + id + "/audience/new",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : campaign ? (_jsx(AudienceForm, { campaignId: id, status: "new" })) : (_jsx(Nope, { title: t("errors.404"), caption: t("campaigns.errors.not-found") }))] }));
}
