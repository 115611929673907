import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { makeStyles } from "@mui/styles";
import ConversationsView from "../custom-fields/conversations-view";
import { useAuth } from "../../../services/auth";
const useStyles = makeStyles(() => ({
    timeline: {
        margin: 0,
        padding: 0,
        "& .MuiTimelineContent-root": {
            padding: "4px 0 4px 16px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            content: "none",
        },
        "& .MuiTimelineDot-root": {
            boxShadow: "none",
            width: "12px",
            height: "12px",
        },
    },
}));
export default function InputConversation({ label, required, value, setValue, caption, info, rows, autoRows, pattern, disabled, error, errors, isError, setIsError, conversations, }) {
    const classes = useStyles();
    const auth = useAuth();
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: caption })) }))), _jsxs(Timeline, Object.assign({ className: classes.timeline }, { children: [conversations && _jsx(ConversationsView, { conversations: conversations, hasNewForm: true }), _jsxs(TimelineItem, { children: [_jsx(TimelineSeparator, { children: _jsx(TimelineDot, { color: "secondary" }) }), _jsxs(TimelineContent, { children: [auth.user && (_jsx(Box, Object.assign({ sx: {
                                            marginBottom: 2,
                                        } }, { children: _jsx(Typography, { children: auth.user.first_name + " " + auth.user.last_name }) }))), _jsx(FormControl, Object.assign({ fullWidth: true, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: _jsx(TextField, { disabled: disabled ? disabled : false, label: label, fullWidth: true, multiline: true, required: required, value: value, minRows: rows, maxRows: autoRows ? Infinity : rows, inputProps: {
                                                pattern: pattern,
                                            }, onChange: event => {
                                                setValue(event.target.value);
                                                if (isError &&
                                                    errors &&
                                                    error &&
                                                    setIsError &&
                                                    (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                                    setIsError(false);
                                            }, helperText: isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error))
                                                ? errors[error][0]
                                                : info
                                                    ? info
                                                    : false, error: isError && errors && error
                                                ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)
                                                : false }) }))] })] })] }))] }));
}
