import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
export default function TicketDelete({ ticket, setDeletedTicket, setShowTicketModal }) {
    const { t } = useTranslation();
    const alert = useAlert();
    const api = useApiTenant();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const deleteTicket = () => {
        setIsDeleting(true);
        api.DELETE_ticket(ticket.id)
            .then(() => {
            setIsDeleting(false);
            setDeletedTicket(true);
            if (setShowTicketModal)
                setShowTicketModal(false);
            alert.show(t("tickets.ticket-delete.deleted"), "warning");
        })
            .catch(error => {
            setIsDeleting(false);
            console.error(error.response);
            alert.show(t("tickets.ticket-delete.deleting-error"), "error");
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Button, Object.assign({ size: "small", sx: {
                    color: "rgb(244, 67, 54)",
                }, onClick: () => setIsOpened(true) }, { children: ticket.is_draft
                    ? t("tickets.ticket-delete.delete-draft")
                    : t("tickets.ticket-delete.delete-ticket") })), _jsxs(Dialog, Object.assign({ open: isOpened, onClose: () => {
                    setIsOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: ticket.is_draft
                                    ? t("tickets.ticket-delete.draft-delete-confirm")
                                    : t("tickets.ticket-delete.ticket-delete-confirm") }), _jsx(DialogContentText, { children: _jsx("em", { children: t("delete-component.confirm-caption") }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsOpened(false);
                                } }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", size: "small", style: {
                                    color: "rgb(244, 67, 54)",
                                }, loading: isDeleting, onClick: () => {
                                    deleteTicket();
                                }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("delete-component.delete") }))] })] }))] }));
}
