import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ChoiceIcon from "@mui/icons-material/CheckCircle";
export default function ChoiceFieldDetails({ choice }) {
    const { t } = useTranslation();
    return (_jsx(Box, Object.assign({ sx: {
            marginBottom: 2,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                    marginRight: 2,
                                } }, { children: _jsx(ChoiceIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.campaign-steps.choice-fields.choice-fields-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                padding: 4,
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.name") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: choice.name }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.filterable") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: choice.filterable === true ? t("yes") : t("no") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.reportable") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: choice.has_metrics === true ? t("yes") : t("no") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.not-editable") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: choice.not_editable === true ? t("yes") : t("no") }))] }))] })) })) }) }))] })) })));
}
