import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../../elements/blocks/intro";
import Controls from "../../../elements/blocks/controls";
import UserDetails from "../../../elements/users/user-details";
import TicketsMetrics from "../../../elements/tickets/tickets-metrics";
import TicketsTable from "../../../elements/tickets/tickets-table";
import Nope from "../../../elements/blocks/nope";
import { useApiTenant } from "../../../../api/api-tenant";
export default function UserView() {
    const { id } = useParams();
    return _jsx(UserViewComponent, {}, id);
}
function UserViewComponent() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [user, setUser] = useState(null);
    const [tab, setTab] = useState("profile");
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        api.GET_user(id)
            .then(response => {
            setUser(response.data.tenant_user);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [id]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: user
                    ? user.first_name + (user.last_name ? " " + user.last_name : "")
                    : t("users.user") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("users.users"),
                        link: "/settings/users",
                    },
                    {
                        name: user
                            ? user.first_name + (user.last_name ? " " + user.last_name : "")
                            : "",
                        link: "/settings/users/" + id,
                    },
                ], button: user
                    ? {
                        label: t("users.edit-user"),
                        link: "/settings/users/" + id + "/edit",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : user ? (_jsxs(Fragment, { children: [_jsx(Controls, { tabs: [
                            {
                                label: t("users.profile"),
                                value: "profile",
                            },
                            {
                                label: t("tickets.tickets"),
                                value: "tickets",
                            },
                        ], tab: tab, setTab: setTab }), tab === "profile" ? (_jsx(UserDetails, { user: user, type: "client" })) : (_jsxs(Fragment, { children: [_jsx(TicketsMetrics, { type: "user", user_id: user.id }), _jsx(TicketsTable, { type: "user", user: user })] }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("users.errors.not-found") }))] }));
}
