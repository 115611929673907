import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import PaginationNumbers from "../../blocks/pagination";
import Nope from "../../blocks/nope";
import { useTenancy } from "../../../../services/tenancy";
import { useApiTenant } from "../../../../api/api-tenant";
import { prettifyText } from "../../../../helpers/clean";
export default function AudienceCards({ campaign, search }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const api = useApiTenant();
    const [audiences, setAudiences] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_audiences(campaign.id, 6, page, search !== "" ? search : undefined, "id", "desc")
            .then(response => {
            setAudiences(response.data.audiences);
            setPage(response.data.meta.current_page);
            setPages(response.data.meta.last_page);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    }, [page, search]);
    return isLoading ? (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : audiences && audiences.length > 0 ? (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ container: true, spacing: 4, direction: "row" }, { children: audiences.map(audience => {
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, style: {
                            display: "flex",
                        } }, { children: _jsxs(Card, Object.assign({ style: {
                                display: "inherit",
                                flexDirection: "column",
                                flexGrow: 1,
                                height: "100%",
                            } }, { children: [_jsxs(CardContent, Object.assign({ style: {
                                        display: "initial",
                                        flexGrow: 1,
                                        flexDirection: "column",
                                    } }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: audience.active ? (_jsx(Chip, { size: "small", color: "secondary", label: t("campaigns.audiences.audience-attributes.active") })) : (_jsx(Chip, { size: "small", color: "default", label: t("campaigns.audiences.audience-attributes.inactive") })) })), _jsx(Typography, Object.assign({ variant: "h5", noWrap: true }, { children: prettifyText(audience.name) })), _jsx(Box, Object.assign({ sx: {
                                                paddingTop: 1,
                                            } }, { children: _jsx(Typography, Object.assign({ variant: "body1", noWrap: true }, { children: audience.description
                                                    ? prettifyText(audience.description)
                                                    : "" })) }))] })), _jsx(CardActions, { children: _jsx(Fragment, { children: _jsx(Button, Object.assign({ onClick: () => {
                                                window.history.pushState({}, "", tenancy.link("/campaigns/" + campaign.id + "/audiences"));
                                                navigate(tenancy.link("/campaigns/" +
                                                    campaign.id +
                                                    "/audiences/" +
                                                    audience.id));
                                            }, size: "small" }, { children: t("campaigns.audiences.view-audience") })) }) })] })) }), audience.id));
                }) })), _jsx(PaginationNumbers, { pages: pages, page: page, setPage: setPage })] })) : (_jsx(Nope, { caption: t("campaigns.audiences.no-audiences") }));
}
