import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CountIcon from "@mui/icons-material/Functions";
import InputDatetime from "../inputs/input-datetime";
export default function Controls({ tab, setTab, tabs, order, setOrder, orderOptions, disableOrder, enableSearch, search, setSearch, enableDateChanging, startDate, startTime, endDate, endTime, setStartDate, setStartTime, setEndDate, setEndTime, range, setRange, showTabControls, setIsChosenGlobalCustomRange, setCount, countDisabled, countIsLoading, }) {
    const { t } = useTranslation();
    const [searchTemp, setSearchTemp] = useState(search);
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [openSelectCustomRange, setOpenSelectCustomRange] = useState(false);
    const debounceSearch = useCallback(debounce(searchTemp => {
        if (setSearch)
            setSearch(searchTemp);
    }, 700), []);
    const useStyles = makeStyles({
        tabs: {
            "& .MuiTab-root": {
                minWidth: "150px",
                padding: 0,
            },
            "& .MuiTabs-scrollButtons.Mui-disabled": {
                opacity: showTabControls ? 0.3 : 0,
            },
        },
    });
    useEffect(() => {
        if (setSearch) {
            setSearch("");
            setSearchTemp("");
        }
    }, [tab]);
    const classes = useStyles();
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    paddingBottom: 4,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4, alignItems: "center" }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: setCount || enableSearch || enableDateChanging ? 8 : 12 }, { children: [orderOptions && (orderOptions === null || orderOptions === void 0 ? void 0 : orderOptions.length) > 0 && order && setOrder && (_jsxs(Box, Object.assign({ sx: {
                                        display: "flex",
                                        alignItems: "center",
                                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                marginRight: 1,
                                            } }, { children: _jsx(Typography, Object.assign({ variant: "button", color: "textSecondary" }, { children: t("controls.order-by") })) })), _jsx(Box, { children: _jsx(FormControl, { children: _jsx(Select, Object.assign({ variant: "standard", disabled: disableOrder ? disableOrder : false, value: order, onChange: event => setOrder(event.target.value) }, { children: orderOptions.map(order => {
                                                        return (_jsx(MenuItem, Object.assign({ value: order.value }, { children: order.label }), order.value));
                                                    }) })) }) })] }))), tabs && (tabs === null || tabs === void 0 ? void 0 : tabs.length) > 0 && tab && setTab && (_jsx(Box, Object.assign({ className: classes.tabs }, { children: _jsx(Tabs, Object.assign({ indicatorColor: "secondary", textColor: "inherit", value: tab, onChange: (event, value) => setTab(value), variant: "scrollable", scrollButtons: !!showTabControls }, { children: tabs.map(tab => (_jsx(Tab, { value: tab.value, label: _jsxs(Box, Object.assign({ sx: {
                                                    paddingY: 1,
                                                } }, { children: [tab.icon && (_jsx(Box, Object.assign({ component: "span", sx: {
                                                            paddingRight: 1,
                                                        } }, { children: tab.icon }))), tab.label] })) }, tab.label))) })) })))] })), setCount && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                } }, { children: _jsxs(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", disabled: countDisabled, loading: countIsLoading, onClick: () => setCount(true) }, { children: [t("tickets.tickets-table.count"), _jsx(CountIcon, {})] })) })) }))), enableSearch && search !== undefined && setSearch && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                } }, { children: _jsx(TextField, { fullWidth: isMobile, value: searchTemp, onChange: event => {
                                        setSearchTemp(event.target.value);
                                        debounceSearch(event.target.value);
                                    }, placeholder: t("controls.search"), variant: "standard", InputProps: {
                                        startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchIcon, { fontSize: "small" }) }))),
                                        endAdornment: (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ disabled: search.length === 0, size: "small", onClick: () => {
                                                    setSearch("");
                                                    setSearchTemp("");
                                                } }, { children: _jsx(CloseIcon, { fontSize: "small", color: "action" }) })) }))),
                                    } }) })) }))), !enableSearch && enableDateChanging && setRange && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                } }, { children: _jsxs(Box, Object.assign({ sx: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    } }, { children: [range === "custom" && (_jsx(Box, Object.assign({ sx: {
                                                paddingRight: 1,
                                            } }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: () => {
                                                    setOpenSelectCustomRange(true);
                                                } }, { children: _jsx(DateRangeIcon, { fontSize: "small", color: "action" }) })) }))), _jsxs(Select, Object.assign({ variant: "standard", value: range, onChange: event => {
                                                setRange(event.target.value);
                                                if (event.target.value === "custom") {
                                                    setOpenSelectCustomRange(true);
                                                }
                                            } }, { children: [_jsx(MenuItem, Object.assign({ value: "none" }, { children: t("metrics.range-options.none") })), _jsx(MenuItem, Object.assign({ value: "custom" }, { children: t("metrics.range-options.custom.custom") })), _jsx(MenuItem, Object.assign({ value: "day" }, { children: t("metrics.range-options.today") })), _jsx(MenuItem, Object.assign({ value: "yesterday" }, { children: t("metrics.range-options.yesterday") })), _jsx(MenuItem, Object.assign({ value: "this_week" }, { children: t("metrics.range-options.this-week") })), _jsx(MenuItem, Object.assign({ value: "last_week" }, { children: t("metrics.range-options.last-week") })), _jsx(MenuItem, Object.assign({ value: "this_month" }, { children: t("metrics.range-options.this-month") })), _jsx(MenuItem, Object.assign({ value: "last_month" }, { children: t("metrics.range-options.last-month") })), _jsx(MenuItem, Object.assign({ value: "this_year" }, { children: t("metrics.range-options.this-year") })), _jsx(MenuItem, Object.assign({ value: "last_year" }, { children: t("metrics.range-options.last-year") })), _jsx(MenuItem, Object.assign({ value: "total" }, { children: t("metrics.range-options.total") }))] }))] })) })) })))] })) })), _jsxs(Dialog, Object.assign({ maxWidth: "xs", open: openSelectCustomRange, onClose: () => {
                    setOpenSelectCustomRange(false);
                } }, { children: [_jsx(DialogTitle, { children: t("metrics.choose-range") }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("metrics.range-options.custom.start-date"), required: true, value: startDate ? startDate : null, setValue: setStartDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: t("metrics.range-options.custom.time"), required: false, status: "start", value: startTime && startTime !== "" ? startTime : null, setValue: setStartTime, disabled: !startDate || startDate === "" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("metrics.range-options.custom.end-date"), required: true, value: endDate ? endDate : null, setValue: setEndDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: t("metrics.range-options.custom.time"), required: false, status: "end", value: endTime && endTime !== "" ? endTime : null, setValue: setEndTime, disabled: !endDate || endDate === "" }) }))] })) }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsx(Button, Object.assign({ disabled: startDate === "" || endDate === "", color: "primary", onClick: () => {
                                    if (setIsChosenGlobalCustomRange)
                                        setIsChosenGlobalCustomRange(true);
                                    setOpenSelectCustomRange(false);
                                } }, { children: t("metrics.confirm") })) })) })] }))] }));
}
