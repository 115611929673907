export var ContactDetailAttributes;
(function (ContactDetailAttributes) {
    ContactDetailAttributes["Id"] = "id";
    ContactDetailAttributes["FirstName"] = "first_name";
    ContactDetailAttributes["LastName"] = "last_name";
    ContactDetailAttributes["Company"] = "company";
    ContactDetailAttributes["Type"] = "type";
    ContactDetailAttributes["Phones"] = "phones";
    ContactDetailAttributes["Emails"] = "emails";
    ContactDetailAttributes["Address"] = "address";
    ContactDetailAttributes["OIB"] = "oib";
    ContactDetailAttributes["CreatedAt"] = "created_at";
    ContactDetailAttributes["UpdatedAt"] = "updated_at";
})(ContactDetailAttributes || (ContactDetailAttributes = {}));
