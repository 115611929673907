import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import ContactsMetrics from "../../elements/contacts/contacts-metrics";
import ContactsTable from "../../elements/contacts/contacts-table";
import { useRoles } from "../../../services/roles";
export default function Contacts() {
    const { t } = useTranslation();
    const roles = useRoles();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("contacts.contacts"), breadcrumbs: [
                    {
                        name: t("contacts.contacts"),
                        link: "/contacts",
                    },
                ], button: {
                    label: t("contacts.new-contact"),
                    link: "/contacts/new",
                    color: "secondary",
                    type: "new",
                } }), (roles.isManager || roles.isAdmin) && _jsx(ContactsMetrics, {}), _jsx(ContactsTable, {})] }));
}
