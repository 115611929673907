import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Controls from "../blocks/controls";
import ContactDetails from "./contact-details";
import TicketsTable from "../tickets/tickets-table";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
export default function ContactHistoryViewModal({ showContactModal, setShowContactModal, contact, campaign, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const [tab, setTab] = useState("profile");
    return (_jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "lg", open: showContactModal, onClose: () => {
            setShowContactModal(false);
        } }, { children: _jsxs(Fragment, { children: [_jsx(DialogTitle, { children: _jsx(Box, Object.assign({ sx: {
                            display: "inline-block",
                            paddingBottom: 1,
                        } }, { children: roles.isAgent ? (_jsx(Typography, Object.assign({ variant: "h6" }, { children: contact
                                ? contact.type
                                    ? contact.type === "business"
                                        ? contact.company
                                            ? contact.company
                                            : contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : contact.first_name || contact.last_name
                                                    ? (contact.first_name ? contact.first_name : "") +
                                                        (contact.last_name ? " " + contact.last_name : "")
                                                    : ""
                                        : contact.is_anonymous === true
                                            ? t("contacts.anonymous")
                                            : contact.first_name || contact.last_name
                                                ? (contact.first_name ? contact.first_name : "") +
                                                    (contact.last_name ? " " + contact.last_name : "")
                                                : contact.company
                                                    ? contact.company
                                                    : ""
                                    : contact.company
                                        ? contact.company
                                        : contact.is_anonymous === true
                                            ? t("contacts.anonymous")
                                            : contact.first_name || contact.last_name
                                                ? (contact.first_name ? contact.first_name : "") +
                                                    (contact.last_name ? " " + contact.last_name : "")
                                                : t("contacts.contact") + " #" + contact.id
                                : "" }))) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/contacts/" + contact.id), onClick: event => {
                                event.preventDefault();
                                navigate(tenancy.link("/contacts/" + contact.id));
                            }, underline: "hover" }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: contact
                                    ? contact.type
                                        ? contact.type === "business"
                                            ? contact.company
                                                ? contact.company
                                                : contact.is_anonymous === true
                                                    ? t("contacts.anonymous")
                                                    : contact.first_name || contact.last_name
                                                        ? (contact.first_name
                                                            ? contact.first_name
                                                            : "") +
                                                            (contact.last_name
                                                                ? " " + contact.last_name
                                                                : "")
                                                        : ""
                                            : contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : contact.first_name || contact.last_name
                                                    ? (contact.first_name ? contact.first_name : "") +
                                                        (contact.last_name ? " " + contact.last_name : "")
                                                    : contact.company
                                                        ? contact.company
                                                        : ""
                                        : contact.company
                                            ? contact.company
                                            : contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : contact.first_name || contact.last_name
                                                    ? (contact.first_name ? contact.first_name : "") +
                                                        (contact.last_name ? " " + contact.last_name : "")
                                                    : t("contacts.contact") + " #" + contact.id
                                    : "" })) }))) })) }), _jsxs(Box, Object.assign({ sx: {
                        bgcolor: "background.default",
                        paddingX: 3,
                        paddingY: 2,
                    } }, { children: [_jsx(Controls, { tabs: [
                                {
                                    label: t("contacts.profile"),
                                    value: "profile",
                                },
                                {
                                    label: t("tickets.tickets"),
                                    value: "tickets",
                                },
                            ], tab: tab, setTab: setTab }), tab === "profile" && (_jsx(ContactDetails, { type: "ticket", contact: contact, modal: true, campaign: campaign })), tab === "tickets" && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(TicketsTable, { type: "history", campaign: campaign, contact: contact, isPreview: true }) })))] }))] }) })));
}
