import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import KnowledgeForm from "../../elements/knowledge/knowledge-form";
export default function KnowledgeNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("knowledge.knowledge-new"), breadcrumbs: [
                    {
                        name: t("knowledge.knowledge"),
                        link: "/knowledge",
                    },
                    {
                        name: t("knowledge.knowledge-new"),
                        link: "/knowledge/new",
                    },
                ] }), _jsx(KnowledgeForm, { status: "new" })] }));
}
