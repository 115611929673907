import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import UserForm from "../../../elements/users/user-form";
export default function UserNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("users.new-user"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("users.users"),
                        link: "/settings/users",
                    },
                    {
                        name: t("users.new-user"),
                        link: "/settings/users/new",
                    },
                ] }), _jsx(UserForm, { type: "client", status: "new" })] }));
}
