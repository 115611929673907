import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material//CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useApiTenant } from "../../../api/api-tenant";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
export default function TicketCampaignSelect({ newTicket, setNewTicket, contact }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState(null);
    const [loading, setIsLoading] = useState(true);
    useEffect(() => {
        if (!newTicket)
            return;
        if (campaigns.length > 0)
            return;
        api.GET_campaigns(10000, 1, undefined, undefined, "name", undefined, roles.isAgent ? 1 : undefined, roles.isAgent ? 1 : undefined)
            .then(response => {
            const campaigns = response.data.campaigns;
            setCampaigns(campaigns.filter(campaign => campaign.active === true));
            if (campaigns.length === 1 && campaigns[0].active === true)
                setCampaign(campaigns[0]);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [newTicket]);
    return (_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: newTicket, onClose: () => {
            setNewTicket(false);
        } }, { children: [_jsx(DialogTitle, { children: t("tickets.new-ticket") }), _jsx(DialogContent, { children: _jsx(Autocomplete, { options: campaigns, getOptionLabel: campaign => campaign.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: campaign, onChange: (event, value) => setCampaign(value), loading: loading, renderOption: (props, campaign) => (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexDirection: "column",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: campaign.name })) })), _jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: campaign.type === "inbound"
                                            ? t("campaigns.campaign-attributes.types.inbound")
                                            : campaign.type === "outbound"
                                                ? t("campaigns.campaign-attributes.types.outbound")
                                                : campaign.type === "contract"
                                                    ? t("campaigns.campaign-attributes.types.contract")
                                                    : campaign.type === "survey"
                                                        ? t("campaigns.campaign-attributes.types.survey")
                                                        : t("campaigns.campaign-attributes.types.all") })) }))] }), campaign.id) }))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("campaigns.campaign"), required: true, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [loading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                            setNewTicket(false);
                        } }, { children: t("cancel") })), _jsx(Button, Object.assign({ disabled: !campaign, onClick: () => {
                            navigate(tenancy.link(contact
                                ? "/contacts/" +
                                    contact.id +
                                    "/campaign/" +
                                    (campaign === null || campaign === void 0 ? void 0 : campaign.id) +
                                    "/new-ticket"
                                : "/campaigns/" + (campaign === null || campaign === void 0 ? void 0 : campaign.id) + "/new-ticket"));
                        } }, { children: t("tickets.new-ticket") }))] })] })));
}
