import { jsx as _jsx } from "react/jsx-runtime";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function ContactTypeColumn({ type }) {
    const { t } = useTranslation();
    return (_jsx(Chip, { style: {
            border: "1px solid " + (type === "person" ? "rgb(33,150,243, 1)" : "rgb(255,152,0, 1)"),
            backgroundColor: type === "person" ? "rgb(33,150,243, 0.2)" : "rgb(255,152,0, 0.2)",
        }, size: "small", label: type === "business"
            ? t("contacts.contact-attributes.type.business")
            : t("contacts.contact-attributes.type.person") }));
}
