import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../elements/blocks/intro";
import PhoneCountryForm from "../../../../elements/options/phone-countries/phone-country-form";
export default function PhoneCountryNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("options.phone-countries.new-phone-country"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.phone-countries.phone-countries"),
                        link: "/settings/options/phone-countries",
                    },
                    {
                        name: t("options.phone-countries.new-phone-country"),
                        link: "/settings/options/phone-countries/new",
                    },
                ] }), _jsx(PhoneCountryForm, { status: "new" })] }));
}
