import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
export default function InputTextArea({ label, required, value, setValue, caption, info, rows, autoRows, pattern, disabled, error, errors, isError, setIsError, }) {
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: caption }) }))), _jsx(FormControl, Object.assign({ fullWidth: true, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: _jsx(TextField, { disabled: disabled ? disabled : false, label: label, fullWidth: true, multiline: true, required: required, value: value, minRows: rows, maxRows: autoRows ? Infinity : rows, inputProps: {
                        pattern: pattern,
                    }, onChange: event => {
                        setValue(event.target.value);
                        if (isError &&
                            errors &&
                            error &&
                            setIsError &&
                            (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                            setIsError(false);
                    }, helperText: isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error))
                        ? errors[error][0]
                        : info
                            ? info
                            : false, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }) }))] }));
}
