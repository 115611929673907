import { jsx as _jsx } from "react/jsx-runtime";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MetricPartition from "../metrics/metric-partition";
import { Grid } from "@mui/material";
export default function MetricPartitionModal({ isOpened, setIsOpened, title, metricData, range, setRange, isLoading, setIsLoading, cachedAt, refresh, setRefresh, customFieldId, setCustomFieldRefresh, startDate, setStartDate, startTime, setStartTime, endDate, setEndDate, endTime, setEndTime, setResetDateTimeValues, globalRange, setGlobalRange, setIsChosenCustomRange, metricType, isError, statuses, priorities, chosenStatuses, setChosenStatuses, chosenPriorities, setChosenPriorities, }) {
    return (_jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "lg", open: isOpened, onClose: () => {
            setIsOpened(false);
        } }, { children: _jsx(DialogContent, { children: _jsx(Grid, Object.assign({ item: true }, { children: _jsx(MetricPartition, { title: title, metricData: metricData, range: range, setRange: setRange, isLoading: isLoading, setIsLoading: setIsLoading, cachedAt: cachedAt, refresh: refresh, setRefresh: setRefresh, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, startTime: startTime, setStartTime: setStartTime, endTime: endTime, setEndTime: setEndTime, setIsChosenCustomRange: setIsChosenCustomRange, setResetDateTimeValues: setResetDateTimeValues, globalRange: globalRange, setGlobalRange: setGlobalRange, customFieldId: customFieldId, setCustomFieldRefresh: setCustomFieldRefresh, isModal: true, metricType: metricType, isError: isError, statuses: statuses, priorities: priorities, chosenStatuses: chosenStatuses, setChosenStatuses: setChosenStatuses, chosenPriorities: chosenPriorities, setChosenPriorities: setChosenPriorities }) })) }) })));
}
