import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import Controls from "../../elements/blocks/controls";
import KnowledgeLoading from "../../elements/knowledge/knowledge-loading";
import KnowledgeCards from "../../elements/knowledge/knowledge-cards";
import PaginationNumbers from "../../elements/blocks/pagination";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useRoles } from "../../../services/roles";
export default function Knowledge() {
    const { t } = useTranslation();
    const api = useApiTenant();
    const roles = useRoles();
    const [isLoading, setIsLoading] = useState(true);
    const [knowledge, setKnowledge] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [orderBy, setOrderBy] = useState("updated_at");
    const [search, setSearch] = useState("");
    useEffect(() => {
        setIsLoading(true);
        api.GET_knowledgeAll(12, page, orderBy, orderBy === "updated_at" ? "desc" : "asc", search !== "" ? search : undefined)
            .then(response => {
            setKnowledge(response.data.knowledge);
            setPage(response.data.meta.current_page);
            setPages(response.data.meta.last_page);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [page, orderBy, search]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("knowledge.knowledge"), breadcrumbs: [
                    {
                        name: t("knowledge.knowledge"),
                        link: "/knowledge",
                    },
                ], button: roles.isAdmin || roles.isManager
                    ? {
                        label: t("knowledge.knowledge-new"),
                        link: "/knowledge/new",
                        color: "secondary",
                        type: "new",
                    }
                    : undefined }), _jsx(Controls, { orderOptions: [
                    {
                        label: t("knowledge.order.name"),
                        value: "title",
                    },
                    {
                        label: t("knowledge.order.newest"),
                        value: "updated_at",
                    },
                ], order: orderBy, setOrder: setOrderBy, enableSearch: true, search: search, setSearch: setSearch }), isLoading ? (_jsx(KnowledgeLoading, {})) : knowledge.length > 0 ? (_jsxs(Fragment, { children: [_jsx(KnowledgeCards, { knowledge: knowledge }), _jsx(PaginationNumbers, { pages: pages, page: page, setPage: setPage })] })) : (_jsx(Nope, { caption: t("errors.knowledge.no-records"), card: true }))] }));
}
