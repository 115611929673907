import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import Nope from "../../../elements/blocks/nope";
import ClientForm from "../../../elements/clients/client-form";
import ClientDelete from "../../../elements/clients/client-delete";
import { useApiCentral } from "../../../../api/api-central";
export default function ClientEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiCentral();
    const [client, setClient] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_client(id)
            .then(response => {
            setClient(response.data.tenant);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: client ? client.name : t("clients.client") + " #" + id, breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("clients.clients"),
                        link: "/administration/clients",
                    },
                    {
                        name: client ? client.name : t("clients.client"),
                        link: "/administration/clients/" + id,
                    },
                    {
                        name: t("clients.edit-client"),
                        link: "/administration/clients/" + id + "/edit",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : client ? (_jsxs(Fragment, { children: [_jsx(ClientForm, { status: "edit", client: client }), _jsx(ClientDelete, { client: client })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("clients.errors.not-found") }))] }));
}
