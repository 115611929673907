import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../../../../elements/blocks/intro";
import ChoiceFieldForm from "../../../../../elements/campaigns/choice-fields/choice-field-form";
import ChoiceFieldDelete from "../../../../../elements/campaigns/choice-fields/choice-field-delete";
import Nope from "../../../../../elements/blocks/nope";
import { useApiTenant } from "../../../../../../api/api-tenant";
export default function ChoiceFieldEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [choiceField, setChoiceField] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_campaignsChoiceField(id)
            .then(response => {
            setChoiceField(response.data.shared_choice);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: choiceField ? choiceField.name : t("fields.field") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("fields.shared-fields.shared-fields"),
                        link: "/settings/fields/shared",
                    },
                    {
                        name: t("fields.shared-fields.choice-fields.choices"),
                        link: "/settings/fields/shared/choices",
                    },
                    {
                        name: choiceField ? choiceField.name : t("fields.field"),
                        link: "/settings/fields/shared/choices/" + id,
                    },
                    {
                        name: t("fields.edit-field"),
                        link: "/settings/fields/shared/choices/" + id + "/edit",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : choiceField ? (_jsxs(Fragment, { children: [_jsx(ChoiceFieldForm, { status: "edit", choice: choiceField }), _jsx(ChoiceFieldDelete, { choice: choiceField })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("fields.shared-fields.choice-fields.errors.not-found") }))] }));
}
