import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Hidden from "@mui/material/Hidden";
import LoadingButton from "@mui/lab/LoadingButton";
import ContactIcon from "@mui/icons-material/AccountBox";
import NextIcon from "@mui/icons-material/NavigateNext";
import InputCustomFields from "../inputs/input-custom-fields";
import InputPhones from "../inputs/input-phones";
import InputEmails from "../inputs/input-emails";
import InputAddress from "../inputs/input-address";
import InputText from "../inputs/input-text";
import InputSelect from "../inputs/input-select";
import { useApiTenant } from "../../../api/api-tenant";
import { useApiPublic } from "../../../api/api-public";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useAuth } from "../../../services/auth";
import { useRoles } from "../../../services/roles";
import { cleanOIB, cleanText } from "../../../helpers/clean";
export default function ContactForm({ kind, status, contact, setContact, setCreatedContact, setUpdatedContact, ticketDraft, newContact, callerPhone, lead, modal, campaign, setStep, createSurveyTicket, isTicketCreating, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const api = auth.isPublic ? useApiPublic() : useApiTenant();
    const apiTenant = useApiTenant();
    const apiPublic = useApiPublic();
    const tenancy = useTenancy();
    const alert = useAlert();
    const roles = useRoles();
    const [firstName, setFirstName] = useState((_a = contact === null || contact === void 0 ? void 0 : contact.first_name) !== null && _a !== void 0 ? _a : "");
    const [lastName, setLastName] = useState((_b = contact === null || contact === void 0 ? void 0 : contact.last_name) !== null && _b !== void 0 ? _b : "");
    const [company, setCompany] = useState((_c = contact === null || contact === void 0 ? void 0 : contact.company) !== null && _c !== void 0 ? _c : "");
    const [type, setType] = useState(contact === null || contact === void 0 ? void 0 : contact.type);
    const [address, setAddress] = useState((_d = contact === null || contact === void 0 ? void 0 : contact.address) !== null && _d !== void 0 ? _d : null);
    const [oib, setOib] = useState((_e = contact === null || contact === void 0 ? void 0 : contact.oib) !== null && _e !== void 0 ? _e : "");
    const [phones, setPhones] = useState((_f = contact === null || contact === void 0 ? void 0 : contact.phones) !== null && _f !== void 0 ? _f : []);
    const [emails, setEmails] = useState((_g = contact === null || contact === void 0 ? void 0 : contact.emails) !== null && _g !== void 0 ? _g : []);
    const [customFields, setCustomFields] = useState((_h = contact === null || contact === void 0 ? void 0 : contact.custom_fields) !== null && _h !== void 0 ? _h : []);
    const [isAnonymous, setIsAnonymous] = useState((_j = contact === null || contact === void 0 ? void 0 : contact.is_anonymous) !== null && _j !== void 0 ? _j : true);
    const [contactUsed, setContactUsed] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [oibError, setOibError] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(true);
    const [tenancyCustomFields, setTenancyCustomFields] = useState([]);
    const [disabledCustomFields, setDisabledCustomFields] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    useEffect(() => {
        setIsSaveDisabled((campaign &&
            campaign.field_options.length > 0 &&
            campaign.field_options.find(o => o.data === "phones" && o.table === "contacts") &&
            campaign.field_options.find(o => o.data === "phones" && o.table === "contacts")
                .required &&
            phones.length === 0) ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "emails" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "emails" && o.table === "contacts")
                    .required &&
                emails.length === 0) ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "phones_or_emails" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "phones_or_emails" && o.table === "contacts").required &&
                emails.length === 0 &&
                phones.length === 0) ||
            oibError ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "oib" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "oib" && o.table === "contacts")
                    .required &&
                oib === "") ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "first_name" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "first_name" && o.table === "contacts").required &&
                firstName === "") ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "last_name" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "last_name" && o.table === "contacts").required &&
                lastName === "") ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "company" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "company" && o.table === "contacts").required &&
                company === "") ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "type" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "type" && o.table === "contacts")
                    .required &&
                !type) ||
            (campaign &&
                campaign.field_options.length > 0 &&
                campaign.field_options.find(o => o.data === "address" && o.table === "contacts") &&
                campaign.field_options.find(o => o.data === "address" && o.table === "contacts").required &&
                address === null) ||
            false);
    }, [firstName, lastName, company, type, address, oib, phones, emails, oibError]);
    useEffect(() => {
        if (auth.isPublic) {
            if (campaign &&
                campaign.custom_field_options &&
                campaign.custom_field_options.length > 0) {
                apiPublic
                    .GET_publicContactCustomFields(process.env.APP_ENV === "local" ? "1" : tenancy.client.id, campaign.slug)
                    .then(response => {
                    setTenancyCustomFields(response.data.custom_fields);
                    setIsLoadingCustomFields(false);
                })
                    .catch(error => {
                    if (!api.isCancel(error)) {
                        console.error(error);
                        setIsLoadingCustomFields(false);
                    }
                });
            }
            else {
                setIsLoadingCustomFields(false);
            }
        }
        else {
            if (!campaign ||
                (campaign &&
                    campaign.custom_field_options &&
                    campaign.custom_field_options.find(a => a.visible))) {
                apiTenant
                    .GET_customFields("contacts", 10000, 1, "index")
                    .then(response => {
                    setTenancyCustomFields(response.data.custom_fields);
                    setIsLoadingCustomFields(false);
                })
                    .catch(error => {
                    if (!api.isCancel(error)) {
                        console.error(error);
                        setIsLoadingCustomFields(false);
                    }
                });
            }
            else {
                setIsLoadingCustomFields(false);
            }
        }
        return () => api.cancel();
    }, []);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (contact) {
            setFirstName((_a = contact === null || contact === void 0 ? void 0 : contact.first_name) !== null && _a !== void 0 ? _a : "");
            setLastName((_b = contact === null || contact === void 0 ? void 0 : contact.last_name) !== null && _b !== void 0 ? _b : "");
            setCompany((_c = contact === null || contact === void 0 ? void 0 : contact.company) !== null && _c !== void 0 ? _c : "");
            setType(contact === null || contact === void 0 ? void 0 : contact.type);
            setAddress((_d = contact === null || contact === void 0 ? void 0 : contact.address) !== null && _d !== void 0 ? _d : null);
            setOib((_e = contact === null || contact === void 0 ? void 0 : contact.oib) !== null && _e !== void 0 ? _e : "");
            setPhones((_f = contact === null || contact === void 0 ? void 0 : contact.phones) !== null && _f !== void 0 ? _f : []);
            setEmails((_g = contact === null || contact === void 0 ? void 0 : contact.emails) !== null && _g !== void 0 ? _g : []);
            setCustomFields((_h = contact === null || contact === void 0 ? void 0 : contact.custom_fields) !== null && _h !== void 0 ? _h : []);
            setDisabledCustomFields([]);
        }
    }, [contact]);
    useEffect(() => {
        if (lead)
            setPhones([lead.phone]);
        if (lead === null)
            setPhones([]);
    }, [lead]);
    useEffect(() => {
        if (firstName !== "" || lastName !== "" || company !== "")
            setIsAnonymous(false);
        else
            setIsAnonymous(true);
    }, [firstName, lastName, company]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!contactUsed)
            return;
        if (kind === "contact") {
            navigate(tenancy.link("/contacts/" + (contactUsed === null || contactUsed === void 0 ? void 0 : contactUsed.id) + "/edit"));
        }
        else {
            if (!setContact)
                return;
            setContact(contactUsed);
            setFirstName((_a = contactUsed.first_name) !== null && _a !== void 0 ? _a : "");
            setLastName((_b = contactUsed.last_name) !== null && _b !== void 0 ? _b : "");
            setCompany((_c = contactUsed.company) !== null && _c !== void 0 ? _c : "");
            setType(contactUsed.type);
            setAddress((_d = contactUsed.address) !== null && _d !== void 0 ? _d : null);
            setOib((_e = contactUsed.oib) !== null && _e !== void 0 ? _e : "");
            setPhones((_f = contactUsed.phones) !== null && _f !== void 0 ? _f : []);
            setEmails((_g = contactUsed.emails) !== null && _g !== void 0 ? _g : []);
            setCustomFields((_h = contactUsed.custom_fields) !== null && _h !== void 0 ? _h : []);
            setDisabledCustomFields([]);
        }
    }, [contactUsed]);
    const createContact = () => {
        setIsSaving(true);
        setIsError(false);
        api.CREATE_contact(cleanText(firstName), cleanText(lastName), cleanText(company), cleanText(type), address, cleanText(oib), phones, emails, customFields.sort((a, b) => a.index - b.index), isAnonymous, campaign && !auth.isPublic ? campaign.id : undefined, campaign && auth.isPublic ? campaign.slug : undefined, auth.isPublic ? (process.env.APP_ENV === "local" ? "1" : tenancy.client.id) : undefined)
            .then(response => {
            if (setContact)
                setContact(response.data.contact);
            setIsSaving(false);
            if (kind === "contact") {
                alert.show(t("contacts.contact-form.contact-created"), "success");
                navigate(tenancy.link("/contacts/" + response.data.contact.id));
            }
            else if (auth.isPublic && createSurveyTicket) {
                createSurveyTicket(response.data.contact);
            }
            else {
                setCreatedContact(true);
            }
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
                console.error(error.response);
            }
            if (auth.isPublic) {
                if (error.response && error.response.status && error.response.status === 409) {
                    alert.show(error.response.data.message, "error");
                }
                else {
                    alert.show(t("tickets.survey.error"), "error");
                }
            }
            else {
                alert.show(t("contacts.contact-form.creating-error-occured"), "error");
            }
        });
    };
    const updateContact = () => {
        if (!contact && !contactUsed)
            return;
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_contact((contact === null || contact === void 0 ? void 0 : contact.id) ? contact === null || contact === void 0 ? void 0 : contact.id : contactUsed.id, cleanText(firstName), cleanText(lastName), cleanText(company), cleanText(type), address, cleanText(oib), phones, emails, customFields.sort((a, b) => (a.custom_field ? a.custom_field.index : a.index) -
            (b.custom_field ? b.custom_field.index : b.index)), isAnonymous, campaign && !auth.isPublic ? campaign.id : undefined, campaign && auth.isPublic ? campaign.slug : undefined, auth.isPublic
            ? process.env.APP_ENV === "local"
                ? "1"
                : tenancy.client.id
            : undefined, auth.isPublic ? ((contact === null || contact === void 0 ? void 0 : contact.uuid) ? contact === null || contact === void 0 ? void 0 : contact.uuid : contactUsed.uuid) : undefined)
            .then(response => {
            setIsSaving(false);
            if (kind === "contact") {
                alert.show(t("contacts.contact-form.contact-updated"), "success");
                if (modal) {
                    setUpdatedContact(true);
                }
                else {
                    navigate(tenancy.link("/contacts/" + (contact === null || contact === void 0 ? void 0 : contact.id)));
                }
            }
            else if (auth.isPublic && setContact) {
                setContact(response.data.contact);
                if (createSurveyTicket)
                    createSurveyTicket(response.data.contact);
                if (!createSurveyTicket && setStep)
                    setStep("information");
            }
            else {
                setUpdatedContact(true);
            }
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            if (error.response) {
                setErrors(error.response.data.errors);
                console.error(error.response);
            }
            if (auth.isPublic && error.response) {
                if (error.response.status === 409) {
                    alert.show(error.response.data.message, "error");
                }
                else {
                    alert.show(t("tickets.survey.error"), "error");
                }
            }
            else {
                alert.show(t("contacts.contact-form.updating-error-occured"), "error");
            }
        });
    };
    const isOibValid = input => {
        let oib = input.toString();
        if (oib.match(/\d{11}/) === null)
            return false;
        let calculated = 10;
        for (const digit of oib.substring(0, 10)) {
            calculated += parseInt(digit);
            calculated %= 10;
            if (calculated === 0)
                calculated = 10;
            calculated *= 2;
            calculated %= 11;
        }
        let check = 11 - calculated;
        if (check === 10)
            check = 0;
        return check === parseInt(oib[10]);
    };
    return (_jsx(Fragment, { children: auth.isPublic && isLoadingCustomFields ? (_jsx(Box, Object.assign({ sx: {
                marginY: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30px",
            } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsxs(Box, { children: [(!campaign ||
                    (campaign &&
                        campaign.field_options.length > 0 &&
                        campaign.field_options.some(e => e.visible &&
                            e.table === "contacts" &&
                            e.data !== "phones_or_emails"))) && (_jsx(Box, Object.assign({ sx: {
                        marginBottom: 4,
                        paddingBottom: 0,
                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4, xl: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                        display: "flex",
                                        position: "sticky",
                                        top: "100px",
                                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                marginRight: 2,
                                            } }, { children: _jsx(ContactIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("contacts.contact") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("contacts.contact-details") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8, xl: modal ? 12 : 8 }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 4,
                                            gap: 4,
                                        } }, { children: [(!campaign ||
                                                (campaign.field_options.find(o => o.data === "phones" &&
                                                    o.table === "contacts") &&
                                                    ((_k = campaign.field_options.find(o => o.data === "phones" &&
                                                        o.table === "contacts")) === null || _k === void 0 ? void 0 : _k.visible) === true) ||
                                                (campaign.field_options.find(o => o.data === "emails" &&
                                                    o.table === "contacts") &&
                                                    ((_l = campaign.field_options.find(o => o.data === "emails" &&
                                                        o.table === "contacts")) === null || _l === void 0 ? void 0 : _l.visible) === true)) && (_jsxs(Grid, Object.assign({ container: true, spacing: 4, sx: {
                                                    order: 1,
                                                } }, { children: [(!campaign ||
                                                        (campaign.field_options.find(o => o.data === "phones" &&
                                                            o.table === "contacts") &&
                                                            ((_m = campaign.field_options.find(o => o.data === "phones" &&
                                                                o.table === "contacts")) === null || _m === void 0 ? void 0 : _m.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputPhones, { status: status, label: t("contacts.contact-attributes.phones") +
                                                                (!campaign ||
                                                                    (campaign &&
                                                                        campaign.field_options
                                                                            .length === 0)
                                                                    ? ""
                                                                    : campaign &&
                                                                        ((campaign.field_options.find(o => o.data ===
                                                                            "phones" &&
                                                                            o.table ===
                                                                                "contacts") &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "phones" &&
                                                                                o.table ===
                                                                                    "contacts").required) ||
                                                                            (campaign.field_options.find(o => o.data ===
                                                                                "phones_or_emails" &&
                                                                                o.table ===
                                                                                    "contacts") &&
                                                                                campaign.field_options.find(o => o.data ===
                                                                                    "phones_or_emails" &&
                                                                                    o.table ===
                                                                                        "contacts").required &&
                                                                                emails.length ===
                                                                                    0))
                                                                        ? " *"
                                                                        : ""), phones: phones, setPhones: setPhones, setContact: setContactUsed, emails: emails, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                campaign.field_options.find(f => f.data === "emails") &&
                                                                ((_o = campaign.field_options.find(f => f.data === "emails")) === null || _o === void 0 ? void 0 : _o.required) === true &&
                                                                campaign.field_options.find(f => f.data === "phones") &&
                                                                ((_p = campaign.field_options.find(f => f.data === "phones")) === null || _p === void 0 ? void 0 : _p.required) === false &&
                                                                emails.length === 0 &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, checkContacts: kind !== "contact" &&
                                                                campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "phones") &&
                                                                    ((_q = campaign.field_options.find(f => f.data === "phones")) === null || _q === void 0 ? void 0 : _q.required) === true) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_r = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _r === void 0 ? void 0 : _r.required) === true &&
                                                                        emails.length === 0)) &&
                                                                !auth.isPublic, error: "phones", errors: errors, isError: isError, setIsError: setIsError, callerPhone: callerPhone }) }))), _jsx(Hidden, Object.assign({ mdUp: true }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })) })), (!campaign ||
                                                        (campaign.field_options.find(o => o.data === "emails" &&
                                                            o.table === "contacts") &&
                                                            ((_s = campaign.field_options.find(o => o.data === "emails" &&
                                                                o.table === "contacts")) === null || _s === void 0 ? void 0 : _s.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputEmails, { status: status, label: t("contacts.contact-attributes.emails") +
                                                                (!campaign ||
                                                                    (campaign &&
                                                                        campaign.field_options
                                                                            .length === 0)
                                                                    ? ""
                                                                    : campaign &&
                                                                        ((campaign.field_options.find(o => o.data ===
                                                                            "emails" &&
                                                                            o.table ===
                                                                                "contacts") &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "emails" &&
                                                                                o.table ===
                                                                                    "contacts").required) ||
                                                                            (campaign.field_options.find(o => o.data ===
                                                                                "phones_or_emails" &&
                                                                                o.table ===
                                                                                    "contacts") &&
                                                                                campaign.field_options.find(o => o.data ===
                                                                                    "phones_or_emails" &&
                                                                                    o.table ===
                                                                                        "contacts").required &&
                                                                                phones.length ===
                                                                                    0))
                                                                        ? " *"
                                                                        : ""), emails: emails, setEmails: setEmails, setContact: setContactUsed, phones: phones, checkContacts: kind !== "contact" &&
                                                                campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "emails") &&
                                                                    ((_t = campaign.field_options.find(f => f.data === "emails")) === null || _t === void 0 ? void 0 : _t.required) === true) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_u = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _u === void 0 ? void 0 : _u.required) === true &&
                                                                        phones.length === 0)) &&
                                                                !auth.isPublic, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                campaign.field_options.find(f => f.data === "phones") &&
                                                                ((_v = campaign.field_options.find(f => f.data === "phones")) === null || _v === void 0 ? void 0 : _v.required) === true &&
                                                                campaign.field_options.find(f => f.data === "emails") &&
                                                                ((_w = campaign.field_options.find(f => f.data === "emails")) === null || _w === void 0 ? void 0 : _w.required) === false &&
                                                                phones.length === 0 &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, error: "emails.", errors: errors, isError: isError, setIsError: setIsError }) })))] }))), (!campaign ||
                                                (campaign.field_options.find(o => o.data === "type" && o.table === "contacts") &&
                                                    ((_x = campaign.field_options.find(o => o.data === "type" &&
                                                        o.table === "contacts")) === null || _x === void 0 ? void 0 : _x.visible) === true) ||
                                                (campaign.field_options.find(o => o.data === "company" &&
                                                    o.table === "contacts") &&
                                                    ((_y = campaign.field_options.find(o => o.data === "company" &&
                                                        o.table === "contacts")) === null || _y === void 0 ? void 0 : _y.visible) === true) ||
                                                (campaign.field_options.find(o => o.data === "first_name" &&
                                                    o.table === "contacts") &&
                                                    ((_z = campaign.field_options.find(o => o.data === "first_name" &&
                                                        o.table === "contacts")) === null || _z === void 0 ? void 0 : _z.visible) === true) ||
                                                (campaign.field_options.find(o => o.data === "last_name" &&
                                                    o.table === "contacts") &&
                                                    ((_0 = campaign.field_options.find(o => o.data === "last_name" &&
                                                        o.table === "contacts")) === null || _0 === void 0 ? void 0 : _0.visible) === true) ||
                                                (campaign.field_options.find(o => o.data === "oib" && o.table === "contacts") &&
                                                    ((_1 = campaign.field_options.find(o => o.data === "oib" &&
                                                        o.table === "contacts")) === null || _1 === void 0 ? void 0 : _1.visible) === true) ||
                                                (campaign.field_options.find(o => o.data === "address" &&
                                                    o.table === "contacts") &&
                                                    ((_2 = campaign.field_options.find(o => o.data === "address" &&
                                                        o.table === "contacts")) === null || _2 === void 0 ? void 0 : _2.visible) === true)) && (_jsxs(Grid, Object.assign({ container: true, spacing: 4, sx: {
                                                    order: 2,
                                                } }, { children: [(!campaign ||
                                                        (campaign.field_options.find(o => o.data === "phones" &&
                                                            o.table === "contacts") &&
                                                            ((_3 = campaign.field_options.find(o => o.data === "phones" &&
                                                                o.table === "contacts")) === null || _3 === void 0 ? void 0 : _3.visible) === true) ||
                                                        (campaign.field_options.find(o => o.data === "emails" &&
                                                            o.table === "contacts") &&
                                                            ((_4 = campaign.field_options.find(o => o.data === "emails" &&
                                                                o.table === "contacts")) === null || _4 === void 0 ? void 0 : _4.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) }))), (!campaign ||
                                                        (campaign.field_options.find(o => o.data === "first_name" &&
                                                            o.table === "contacts") &&
                                                            ((_5 = campaign.field_options.find(o => o.data === "first_name" &&
                                                                o.table === "contacts")) === null || _5 === void 0 ? void 0 : _5.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { label: t("contacts.contact-attributes.first-name") +
                                                                (!campaign ||
                                                                    (campaign &&
                                                                        campaign.field_options
                                                                            .length === 0)
                                                                    ? ""
                                                                    : campaign &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "first_name" &&
                                                                            o.table ===
                                                                                "contacts") &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "first_name" &&
                                                                            o.table ===
                                                                                "contacts").required
                                                                        ? " *"
                                                                        : ""), required: !campaign ||
                                                                campaign.field_options
                                                                    .length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "first_name" &&
                                                                    o.table ===
                                                                        "contacts")
                                                                    ? campaign.field_options.find(o => o.data ===
                                                                        "first_name" &&
                                                                        o.table ===
                                                                            "contacts").required
                                                                    : false, value: firstName, setValue: setFirstName, info: null, pattern: null, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "emails") &&
                                                                    ((_6 = campaign.field_options.find(f => f.data === "emails")) === null || _6 === void 0 ? void 0 : _6.required) === true &&
                                                                    emails.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data === "phones") &&
                                                                        ((_7 = campaign.field_options.find(f => f.data ===
                                                                            "phones")) === null || _7 === void 0 ? void 0 : _7.required) === true &&
                                                                        phones.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_8 = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _8 === void 0 ? void 0 : _8.required) === true &&
                                                                        phones.length === 0 &&
                                                                        emails.length === 0)) &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, error: "first_name", errors: errors, isError: isError, setIsError: setIsError }) }))), (!campaign ||
                                                        (campaign.field_options.find(o => o.data === "last_name" &&
                                                            o.table === "contacts") &&
                                                            ((_9 = campaign.field_options.find(o => o.data === "last_name" &&
                                                                o.table === "contacts")) === null || _9 === void 0 ? void 0 : _9.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { label: t("contacts.contact-attributes.last-name") +
                                                                (!campaign ||
                                                                    (campaign &&
                                                                        campaign.field_options
                                                                            .length === 0)
                                                                    ? ""
                                                                    : campaign &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "last_name" &&
                                                                            o.table ===
                                                                                "contacts") &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "last_name" &&
                                                                            o.table ===
                                                                                "contacts").required
                                                                        ? " *"
                                                                        : ""), required: !campaign ||
                                                                campaign.field_options
                                                                    .length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "last_name" &&
                                                                    o.table ===
                                                                        "contacts")
                                                                    ? campaign.field_options.find(o => o.data ===
                                                                        "last_name" &&
                                                                        o.table ===
                                                                            "contacts").required
                                                                    : false, value: lastName, setValue: setLastName, info: null, pattern: null, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "emails") &&
                                                                    ((_10 = campaign.field_options.find(f => f.data === "emails")) === null || _10 === void 0 ? void 0 : _10.required) === true &&
                                                                    emails.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data === "phones") &&
                                                                        ((_11 = campaign.field_options.find(f => f.data ===
                                                                            "phones")) === null || _11 === void 0 ? void 0 : _11.required) === true &&
                                                                        phones.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_12 = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _12 === void 0 ? void 0 : _12.required) === true &&
                                                                        phones.length === 0 &&
                                                                        emails.length === 0)) &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, error: "last_name", errors: errors, isError: isError, setIsError: setIsError }) }))), (!campaign ||
                                                        (campaign.field_options.find(o => o.data === "company" &&
                                                            o.table === "contacts") &&
                                                            ((_13 = campaign.field_options.find(o => o.data === "company" &&
                                                                o.table === "contacts")) === null || _13 === void 0 ? void 0 : _13.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { label: t("contacts.contact-attributes.company") +
                                                                (!campaign ||
                                                                    (campaign &&
                                                                        campaign.field_options
                                                                            .length === 0)
                                                                    ? ""
                                                                    : campaign &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "company" &&
                                                                            o.table ===
                                                                                "contacts") &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "company" &&
                                                                            o.table ===
                                                                                "contacts").required
                                                                        ? " *"
                                                                        : ""), required: !campaign ||
                                                                campaign.field_options
                                                                    .length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "company" &&
                                                                    o.table ===
                                                                        "contacts")
                                                                    ? campaign.field_options.find(o => o.data ===
                                                                        "company" &&
                                                                        o.table ===
                                                                            "contacts").required
                                                                    : false, value: company, setValue: setCompany, info: null, pattern: null, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "emails") &&
                                                                    ((_14 = campaign.field_options.find(f => f.data === "emails")) === null || _14 === void 0 ? void 0 : _14.required) === true &&
                                                                    emails.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data === "phones") &&
                                                                        ((_15 = campaign.field_options.find(f => f.data ===
                                                                            "phones")) === null || _15 === void 0 ? void 0 : _15.required) === true &&
                                                                        phones.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_16 = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _16 === void 0 ? void 0 : _16.required) === true &&
                                                                        phones.length === 0 &&
                                                                        emails.length === 0)) &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, error: "company", errors: errors, isError: isError, setIsError: setIsError }) }))), (!campaign ||
                                                        (campaign.field_options.find(o => o.data === "type" &&
                                                            o.table === "contacts") &&
                                                            ((_17 = campaign.field_options.find(o => o.data === "type" &&
                                                                o.table === "contacts")) === null || _17 === void 0 ? void 0 : _17.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputSelect, { label: t("contacts.contact-attributes.type.type") +
                                                                (!campaign ||
                                                                    (campaign &&
                                                                        campaign.field_options
                                                                            .length === 0)
                                                                    ? ""
                                                                    : campaign &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "type" &&
                                                                            o.table ===
                                                                                "contacts") &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "type" &&
                                                                            o.table ===
                                                                                "contacts").required
                                                                        ? " *"
                                                                        : ""), required: !campaign ||
                                                                campaign.field_options
                                                                    .length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "type" &&
                                                                    o.table ===
                                                                        "contacts")
                                                                    ? campaign.field_options.find(o => o.data ===
                                                                        "type" &&
                                                                        o.table ===
                                                                            "contacts").required
                                                                    : false, options: [
                                                                {
                                                                    label: t("contacts.contact-attributes.type.person"),
                                                                    value: "person",
                                                                },
                                                                {
                                                                    label: t("contacts.contact-attributes.type.business"),
                                                                    value: "business",
                                                                },
                                                            ], value: type ? type : "", setValue: setType, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "emails") &&
                                                                    ((_18 = campaign.field_options.find(f => f.data === "emails")) === null || _18 === void 0 ? void 0 : _18.required) === true &&
                                                                    emails.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data === "phones") &&
                                                                        ((_19 = campaign.field_options.find(f => f.data ===
                                                                            "phones")) === null || _19 === void 0 ? void 0 : _19.required) === true &&
                                                                        phones.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_20 = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _20 === void 0 ? void 0 : _20.required) === true &&
                                                                        phones.length === 0 &&
                                                                        emails.length === 0)) &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, error: "type", errors: errors, isError: isError, setIsError: setIsError }) }))), (!campaign ||
                                                        (((campaign.field_options.find(o => o.data === "type" &&
                                                            o.table === "contacts") &&
                                                            ((_21 = campaign.field_options.find(o => o.data === "type" &&
                                                                o.table === "contacts")) === null || _21 === void 0 ? void 0 : _21.visible) === true) ||
                                                            (campaign.field_options.find(o => o.data === "company" &&
                                                                o.table === "contacts") &&
                                                                ((_22 = campaign.field_options.find(o => o.data === "company" &&
                                                                    o.table === "contacts")) === null || _22 === void 0 ? void 0 : _22.visible) === true) ||
                                                            (campaign.field_options.find(o => o.data === "first_name" &&
                                                                o.table === "contacts") &&
                                                                ((_23 = campaign.field_options.find(o => o.data === "first_name" &&
                                                                    o.table === "contacts")) === null || _23 === void 0 ? void 0 : _23.visible) === true) ||
                                                            (campaign.field_options.find(o => o.data === "last_name" &&
                                                                o.table === "contacts") &&
                                                                ((_24 = campaign.field_options.find(o => o.data === "last_name" &&
                                                                    o.table === "contacts")) === null || _24 === void 0 ? void 0 : _24.visible) === true)) &&
                                                            ((campaign.field_options.find(o => o.data === "oib" &&
                                                                o.table === "contacts") &&
                                                                ((_25 = campaign.field_options.find(o => o.data === "oib" &&
                                                                    o.table === "contacts")) === null || _25 === void 0 ? void 0 : _25.visible) === true) ||
                                                                (campaign.field_options.find(o => o.data === "address" &&
                                                                    o.table === "contacts") &&
                                                                    ((_26 = campaign.field_options.find(o => o.data === "address" &&
                                                                        o.table === "contacts")) === null || _26 === void 0 ? void 0 : _26.visible) === true)))) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) }))), (!campaign ||
                                                        (campaign.field_options.find(o => o.data === "address" &&
                                                            o.table === "contacts") &&
                                                            ((_27 = campaign.field_options.find(o => o.data === "address" &&
                                                                o.table === "contacts")) === null || _27 === void 0 ? void 0 : _27.visible) === true)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputAddress, { required: !campaign ||
                                                                campaign.field_options
                                                                    .length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "address" &&
                                                                    o.table ===
                                                                        "contacts")
                                                                    ? campaign.field_options.find(o => o.data ===
                                                                        "address" &&
                                                                        o.table ===
                                                                            "contacts").required
                                                                    : false, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "emails") &&
                                                                    ((_28 = campaign.field_options.find(f => f.data === "emails")) === null || _28 === void 0 ? void 0 : _28.required) === true &&
                                                                    emails.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data === "phones") &&
                                                                        ((_29 = campaign.field_options.find(f => f.data ===
                                                                            "phones")) === null || _29 === void 0 ? void 0 : _29.required) === true &&
                                                                        phones.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_30 = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _30 === void 0 ? void 0 : _30.required) === true &&
                                                                        phones.length === 0 &&
                                                                        emails.length === 0)) &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, address: address, setAddress: setAddress }) }))), (!campaign ||
                                                        ((_31 = campaign.field_options.find(o => o.data === "oib" &&
                                                            o.table === "contacts")) === null || _31 === void 0 ? void 0 : _31.visible) === true) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { label: "OIB" +
                                                                (!campaign ||
                                                                    (campaign &&
                                                                        campaign.field_options
                                                                            .length === 0)
                                                                    ? ""
                                                                    : campaign &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "oib" &&
                                                                            o.table ===
                                                                                "contacts") &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "oib" &&
                                                                            o.table ===
                                                                                "contacts").required
                                                                        ? " *"
                                                                        : ""), required: !campaign ||
                                                                campaign.field_options
                                                                    .length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "oib" &&
                                                                    o.table ===
                                                                        "contacts")
                                                                    ? campaign.field_options.find(o => o.data ===
                                                                        "oib" &&
                                                                        o.table ===
                                                                            "contacts").required
                                                                    : false, value: oib, setValue: oib => {
                                                                if (oib.length > 0)
                                                                    setOib(cleanOIB(oib));
                                                                else
                                                                    setOib("");
                                                                if (oib.length === 0 ||
                                                                    isOibValid(oib))
                                                                    setOibError(false);
                                                                else
                                                                    setOibError(true);
                                                            }, disabled: campaign &&
                                                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                                                campaign.field_options.length >
                                                                    0 &&
                                                                ((campaign.field_options.find(f => f.data === "emails") &&
                                                                    ((_32 = campaign.field_options.find(f => f.data === "emails")) === null || _32 === void 0 ? void 0 : _32.required) === true &&
                                                                    emails.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data === "phones") &&
                                                                        ((_33 = campaign.field_options.find(f => f.data ===
                                                                            "phones")) === null || _33 === void 0 ? void 0 : _33.required) === true &&
                                                                        phones.length === 0) ||
                                                                    (campaign.field_options.find(f => f.data ===
                                                                        "phones_or_emails") &&
                                                                        ((_34 = campaign.field_options.find(f => f.data ===
                                                                            "phones_or_emails")) === null || _34 === void 0 ? void 0 : _34.required) === true &&
                                                                        phones.length === 0 &&
                                                                        emails.length === 0)) &&
                                                                status !== "edit" &&
                                                                !auth.isPublic, error: "oib", errors: oib.length > 0 &&
                                                                !isOibValid(oib)
                                                                ? {
                                                                    oib: {
                                                                        "0": t("contacts.contact-form.oib-not-valid"),
                                                                    },
                                                                }
                                                                : errors, isError: oib.length > 0 &&
                                                                !isOibValid(oib)
                                                                ? true
                                                                : isError, setIsError: setIsError }) })))] })))] })) }) }))] })) }))), isLoadingCustomFields ? (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            marginY: 3,
                            paddingY: 3,
                        } }, { children: _jsx(CircularProgress, { color: "inherit", size: 30 }) })) })) : (_jsxs(Fragment, { children: [tenancyCustomFields.length > 0 &&
                            (!campaign ||
                                (campaign &&
                                    campaign.custom_field_options &&
                                    campaign.custom_field_options.length > 0 &&
                                    campaign.custom_field_options.find(a => a.visible))) && (_jsx(InputCustomFields, { type: "contacts", tenancyCustomFields: tenancyCustomFields, customFields: customFields, setCustomFields: setCustomFields, disabled: campaign &&
                                (campaign === null || campaign === void 0 ? void 0 : campaign.field_options) &&
                                campaign.field_options.length > 0 &&
                                ((campaign.field_options.find(f => f.data === "emails") &&
                                    ((_35 = campaign.field_options.find(f => f.data === "emails")) === null || _35 === void 0 ? void 0 : _35.required) === true &&
                                    emails.length === 0) ||
                                    (campaign.field_options.find(f => f.data === "phones") &&
                                        ((_36 = campaign.field_options.find(f => f.data === "phones")) === null || _36 === void 0 ? void 0 : _36.required) === true &&
                                        phones.length === 0) ||
                                    (campaign.field_options.find(f => f.data === "phones_or_emails") &&
                                        ((_37 = campaign.field_options.find(f => f.data === "phones_or_emails")) === null || _37 === void 0 ? void 0 : _37.required) === true &&
                                        phones.length === 0 &&
                                        emails.length === 0)) &&
                                status !== "edit" &&
                                !auth.isPublic, error: "custom_fields.", errors: errors, isError: isError, setIsError: setIsError, modal: modal, campaign: campaign, contact: contact, notEditable: (kind === "ticket" &&
                                ticketDraft === false &&
                                status === "edit" &&
                                roles.isAgent) ||
                                (kind === "ticket" &&
                                    ticketDraft === true &&
                                    newContact === false &&
                                    roles.isAgent), disabledCustomFields: disabledCustomFields, setDisabledCustomFields: setDisabledCustomFields })), _jsx(Box, Object.assign({ sx: {
                                marginBottom: 2,
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [kind === "contact" && (_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: kind === "contact" ? 8 : 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: auth.isPublic
                                                    ? "center"
                                                    : "flex-end",
                                                textAlign: auth.isPublic ? "center" : "start",
                                                paddingTop: auth.isPublic ? 4 : 0,
                                            } }, { children: [auth.isPublic &&
                                                    campaign &&
                                                    campaign.instructions &&
                                                    setStep && (_jsx(LoadingButton, Object.assign({ sx: { marginRight: 2 }, variant: "outlined", onClick: () => (campaign === null || campaign === void 0 ? void 0 : campaign.instructions) &&
                                                        setStep("welcome") }, { children: t("tickets.survey.back") }))), _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: auth.isPublic && (campaign === null || campaign === void 0 ? void 0 : campaign.has_steps)
                                                        ? "primary"
                                                        : "secondary", loading: isSaving || isTicketCreating, disabled: isSaveDisabled, onClick: () => {
                                                        status == "new" && !contactUsed
                                                            ? createContact()
                                                            : updateContact();
                                                    }, endIcon: kind === "ticket" &&
                                                        !auth.isPublic &&
                                                        (status === "new" ||
                                                            contactUsed ||
                                                            ticketDraft) ? (_jsx(NextIcon, {})) : undefined, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: kind === "contact"
                                                        ? status === "new"
                                                            ? t("form-component.create")
                                                            : t("form-component.update")
                                                        : auth.isPublic
                                                            ? (campaign === null || campaign === void 0 ? void 0 : campaign.has_steps)
                                                                ? t("form-component.next")
                                                                : t("tickets.survey.send")
                                                            : status === "new" || contactUsed || ticketDraft
                                                                ? t("form-component.next")
                                                                : t("form-component.update") }))] })) }))] })) }))] }))] })) }));
}
