import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { prettifyText } from "../../../../helpers/clean";
import { flatGroups } from "../../../../helpers/groups";
import CustomFieldsView from "../../custom-fields/custom-fields-view";
const useStyles = makeStyles(() => ({
    chip: {
        height: "auto",
        paddingY: 2,
        "& .MuiChip-label": {
            paddingTop: 2,
            paddingBottom: 2,
            wordWrap: "break-word",
            whiteSpace: "normal",
            textOverflow: "clip",
            maxWidth: "100%",
        },
    },
}));
export default function StepGroup({ step, modal, isDemo }) {
    var _a;
    const { t } = useTranslation();
    const classes = useStyles();
    return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8, lg: isDemo ? 10 : modal ? 12 : 8 }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: { padding: 4 } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-information.ticket-information-form.ticket-queues") })), (_a = step.groups) === null || _a === void 0 ? void 0 : _a.map((group, index) => {
                        let parents = [];
                        if (group.parent_groups) {
                            parents = flatGroups(new Array(group.parent_groups), 0, parents).reverse();
                        }
                        return (_jsxs(Fragment, { children: [index > 0 && (_jsx(Box, Object.assign({ sx: {
                                        paddingTop: 3,
                                        paddingBottom: 2,
                                    } }, { children: _jsx(Divider, { light: true }) }))), _jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                    } }, { children: _jsx(Tooltip, Object.assign({ disableFocusListener: parents.length < 1, disableHoverListener: parents.length < 1, disableTouchListener: parents.length < 1, placement: "top", title: parents.map((parent, index) => index !== parents.length - 1
                                            ? parent.name + " › "
                                            : parent.name) }, { children: _jsx(Chip, { className: classes.chip, label: prettifyText(group.name) }) })) })), group.custom_fields && group.custom_fields.length > 0 && (_jsx(Box, Object.assign({ sx: {
                                        paddingTop: 2,
                                    } }, { children: _jsx(CustomFieldsView, { status: "view", type: "tenant_groups", customFields: group.custom_fields }) })))] }, index));
                    })] })) }) })));
}
