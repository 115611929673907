import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { useTenancy } from "../../../services/tenancy";
import { relatedParentGroups } from "../../../helpers/groups";
export default function GroupCard({ group, groups, campaign }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    let groupBreadcrumbs = "";
    const groupParentGroups = relatedParentGroups(group, groups, []);
    if (groupParentGroups.length > 0) {
        groupBreadcrumbs = groupParentGroups
            .reverse()
            .map(group => group.name)
            .join(" › ");
    }
    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, xl: 3, style: {
            display: "flex",
        } }, { children: _jsxs(Card, Object.assign({ style: {
                display: "inherit",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
            } }, { children: [_jsxs(CardContent, Object.assign({ style: {
                        display: "initial",
                        flexGrow: 1,
                        flexDirection: "column",
                    } }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: group.ticket_in_progress_count > 0 ? (_jsxs(Fragment, { children: [_jsx(Chip, { label: group.ticket_in_progress_count, size: "small", variant: "outlined", color: "default" }), _jsx(Box, Object.assign({ component: "span", sx: {
                                            paddingLeft: 1,
                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("groups.tickets-in-progress") })) }))] })) : (_jsx(Box, Object.assign({ component: "span" }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("groups.no-tickets-in-progress") })) }))) })), _jsx(Typography, Object.assign({ variant: "h5", noWrap: true }, { children: group.name })), _jsx(Typography, Object.assign({ variant: "overline", gutterBottom: true }, { children: groupBreadcrumbs }))] })), _jsx(CardActions, { children: campaign ? (_jsx(Button, Object.assign({ onClick: () => {
                            navigate(tenancy.link("/campaigns/" + campaign.id + "/groups/" + group.id));
                        }, size: "small" }, { children: t("tickets.view-tickets") }))) : (_jsx(Button, Object.assign({ onClick: () => {
                            navigate(tenancy.link("/groups/" + group.id));
                        }, size: "small" }, { children: t("tickets.view-tickets") }))) })] })) })));
}
