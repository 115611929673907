import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { differenceInSeconds } from "date-fns";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import Box from "@mui/material/Box";
import SurveyWelcome from "./survey-welcome";
import SurveyInformation from "./survey-information";
import SurveyFarewell from "./survey-farewell";
import SurveyContact from "./survey-contact";
import { useApiPublic } from "../../../../api/api-public";
import { useAlert } from "../../../../services/alert";
import { usePrefs } from "../../../../services/prefs";
import { useTenancy } from "../../../../services/tenancy";
export default function SurveyForm({ campaign, contact, setContact, ticket, setTicket }) {
    const { t } = useTranslation();
    const api = useApiPublic();
    const alert = useAlert();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const [reCaptchaToken, setReCaptchaToken] = useState();
    const [step, setStep] = useState(campaign.instructions && campaign.instructions !== ""
        ? "welcome"
        : (campaign.field_options && campaign.field_options.length > 0) ||
            (campaign.custom_field_options && campaign.custom_field_options.length > 0)
            ? "contact"
            : campaign.has_steps
                ? "information"
                : "farewell");
    const [isTicketSaving, setIsTicketSaving] = useState(false);
    const [isActivitySaving, setIsActivitySaving] = useState(false);
    const [saveActivity, setSaveActivity] = useState(false);
    const onReCaptchaVerify = useCallback(token => {
        setReCaptchaToken(token);
    }, []);
    const createSurveyTicket = (con) => {
        if (!campaign || (process.env.APP_ENV !== "local" && !tenancy.client))
            return;
        if (process.env.GOOGLE_RECAPTCHA_KEY &&
            campaign.require_captcha_on_survey &&
            !reCaptchaToken) {
            alert.show(t("tickets.survey.error"), "error");
            return;
        }
        setIsTicketSaving(true);
        api.CREATE_surveyTicket(process.env.APP_ENV === "local" ? "1" : tenancy.client.id, campaign.slug, con ? con.uuid : undefined)
            .then(response => {
            setTicket(response.data.ticket);
            setContact(response.data.ticket.contact);
            if (step !== "information" && campaign.has_steps) {
                setStep("information");
                setIsTicketSaving(false);
            }
            else {
                setSaveActivity(true);
            }
        })
            .catch(error => {
            console.error(error.response);
            alert.show(t("tickets.survey.error"), "error");
            setIsTicketSaving(false);
        });
    };
    useEffect(() => {
        if (process.env.APP_ENV !== "local" && !tenancy.client)
            return;
        if (saveActivity && ticket) {
            let workedTime = prefs.ticketStartTime
                ? differenceInSeconds(new Date(), new Date(prefs.ticketStartTime))
                : null;
            api.CREATE_surveyWorkedTime(process.env.APP_ENV === "local" ? "1" : tenancy.client.id, ticket === null || ticket === void 0 ? void 0 : ticket.id, workedTime, campaign.slug)
                .then(() => {
                var _a;
                setStep("farewell");
                if (!((_a = prefs.surveys) === null || _a === void 0 ? void 0 : _a.includes((process.env.APP_ENV === "local"
                    ? process.env.APP_ID
                    : tenancy.client.domain) +
                    "." +
                    campaign.slug +
                    ","))) {
                    prefs.setSurveys(prefs.surveys !== null && prefs.surveys !== ""
                        ? prefs.surveys +
                            (process.env.APP_ENV === "local"
                                ? process.env.APP_ID
                                : tenancy.client.domain) +
                            "." +
                            campaign.slug +
                            ","
                        : (process.env.APP_ENV === "local"
                            ? process.env.APP_ID
                            : tenancy.client.domain) +
                            "." +
                            campaign.slug +
                            ",");
                }
                setIsActivitySaving(false);
            })
                .catch(error => {
                console.error(error.response);
                alert.show(t("tickets.survey.error"), "error");
                setIsActivitySaving(false);
            });
        }
    }, [saveActivity]);
    return (_jsxs(Box, Object.assign({ sx: { display: "flex", flexDirection: "column", alignItems: "center" } }, { children: [process.env.GOOGLE_RECAPTCHA_KEY && campaign.require_captcha_on_survey && (_jsx(GoogleReCaptcha, { onVerify: onReCaptchaVerify })), step === "welcome" && (_jsx(SurveyWelcome, { campaign: campaign, setStep: setStep, isTicketSaving: isTicketSaving })), step === "contact" && (_jsx(SurveyContact, { campaign: campaign, setStep: setStep, createSurveyTicket: !ticket ? createSurveyTicket : undefined, contact: contact, setContact: setContact, isSaving: isTicketSaving, ticket: ticket })), step === "information" && (_jsx(SurveyInformation, { ticket: ticket, campaign: campaign, setStep: setStep, setContact: setContact, contact: contact, setSaveActivity: setSaveActivity, setIsSaving: setIsActivitySaving, isSaving: isActivitySaving, setTicket: setTicket })), step === "farewell" && _jsx(SurveyFarewell, { campaign: campaign })] })));
}
