import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ClientsIcon from "@mui/icons-material/Business";
import { usePrefs } from "../../../services/prefs";
export default function ClientDetails({ client }) {
    const { t } = useTranslation();
    const prefs = usePrefs();
    return (_jsx(Box, Object.assign({ sx: {
            marginBottom: 2,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                    marginRight: 2,
                                } }, { children: _jsx(ClientsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("clients.client-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                padding: 5,
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center", spacing: 4 }, { children: [client.logo_url && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                            } }, { children: _jsx("img", { src: prefs.theme === "light"
                                                    ? client.logo_url
                                                    : client.logo_dark_url
                                                        ? client.logo_dark_url
                                                        : client.logo_url, style: {
                                                    maxWidth: "100%",
                                                    maxHeight: "150px",
                                                    objectFit: "contain",
                                                }, alt: "Logo" }) })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: client && client.name })), _jsx(Typography, Object.assign({ variant: "subtitle2", color: "textSecondary" }, { children: client && client.domain + "." + location.host })), _jsx(Box, Object.assign({ sx: { paddingTop: 1 } }, { children: client.active ? (_jsx(Chip, { size: "small", color: "secondary", label: t("clients.client-attributes.active") })) : (_jsx(Chip, { size: "small", color: "default", label: t("clients.client-attributes.inactive") })) }))] })) }))] })) })) }) }))] })) })));
}
