import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ClientIcon from "@mui/icons-material/Business";
import UserIcon from "@mui/icons-material/PeopleAlt";
import BlacklistIcon from "@mui/icons-material/PhoneDisabled";
// import GroupIcon from "@mui/icons-material/Groups"
import LogIcon from "@mui/icons-material/HistoryToggleOff";
import Intro from "../../elements/blocks/intro";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
export default function Administration() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    useEffect(() => {
        if (tenancy.isSubDomain || !location.pathname.includes("administration"))
            return;
        tenancy.setClient(null);
        roles.setIsAdmin(false);
        roles.setIsManager(false);
        roles.setIsAgent(false);
        roles.setRoleTenant(null);
        roles.setGroupsTenant(null);
        roles.setUserTenant(null);
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("pages.administration"), breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                ] }), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                    navigate("/administration/clients");
                                } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                            height: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        } }, { children: [_jsx(ClientIcon, { style: {
                                                    fontSize: 50,
                                                    marginBottom: "8px",
                                                } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("clients.clients") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                    navigate("/administration/users");
                                } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                            height: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        } }, { children: [_jsx(UserIcon, { style: {
                                                    fontSize: 50,
                                                    marginBottom: "8px",
                                                } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("users.users") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                    navigate("/administration/blacklist");
                                } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                            height: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        } }, { children: [_jsx(BlacklistIcon, { style: {
                                                    fontSize: 50,
                                                    marginBottom: "8px",
                                                } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("blacklist.blacklist") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                    navigate("/administration/logs");
                                } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                            height: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        } }, { children: [_jsx(LogIcon, { style: {
                                                    fontSize: 50,
                                                    marginBottom: "8px",
                                                } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("logs.logs") }))] })) }) })) }) }))] }))] }));
}
