import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import { useAlert } from "../../../services/alert";
import { copy } from "../../../helpers/copy";
export default function ContactEmails({ emails, color }) {
    const { t } = useTranslation();
    const alert = useAlert();
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    paddingBottom: 2,
                } }, { children: _jsx(Typography, Object.assign({ style: { color: color ? color : "inherit" }, variant: "button" }, { children: t("contacts.contact-attributes.emails") })) })), _jsx(Box, { children: emails.map((email, index) => {
                    return (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            width: "100%",
                            paddingBottom: 2,
                        } }, { children: _jsx(Chip, { style: {
                                color: color ? color : "inherit",
                                display: "flex",
                                overflow: "hidden",
                            }, variant: "outlined", label: email.email, onDelete: () => {
                                copy(email.email);
                                alert.show(t("email-copied"), "info");
                            }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                // @ts-ignore
                                title: t("copy-email") }, { children: _jsx(CopyContentIcon, {}) })) }) }), index));
                }) })] }));
}
