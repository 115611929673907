import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Intro from "../../elements/blocks/intro";
import Controls from "../../elements/blocks/controls";
import GroupCard from "../../elements/groups/group-card";
import Nope from "../../elements/blocks/nope";
import { flatGroups } from "../../../helpers/groups";
import { useApiTenant } from "../../../api/api-tenant";
import { useRoles } from "../../../services/roles";
export default function GroupsView() {
    const { t } = useTranslation();
    const api = useApiTenant();
    const roles = useRoles();
    const [groups, setGroups] = useState([]);
    const [search, setSearch] = useState("");
    const [orderBy, setOrderBy] = useState("relevance");
    const [filteredGroups, setFilteredGroups] = useState(null);
    const [isGroupsLoading, setIsGroupsLoading] = useState(true);
    useEffect(() => {
        api.GET_groups("id", "asc", 1, undefined, roles.isAgent ? 1 : undefined)
            .then(response => {
            let groups = flatGroups(response.data.groups, 0, []);
            groups = groups.sort((a, b) => a.name.localeCompare(b.name));
            setGroups(groups);
            setIsGroupsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsGroupsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    useEffect(() => {
        let newGroups = [];
        if (search && search !== "") {
            groups.map(group => {
                if (group.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                    newGroups.push(group);
            });
            setFilteredGroups(newGroups);
        }
        else {
            setFilteredGroups(null);
        }
    }, [search]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("queues.queues"), breadcrumbs: [
                    {
                        name: t("queues.queues"),
                        link: "/groups",
                    },
                ], button: roles.isAdmin
                    ? {
                        label: t("queues.edit-queues"),
                        link: "/settings/groups",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isGroupsLoading }), isGroupsLoading ? (_jsx(Fragment, {})) : (_jsxs(Fragment, { children: [_jsx(Controls, { orderOptions: [
                            {
                                label: t("queues.relevance"),
                                value: "relevance",
                            },
                        ], disableOrder: true, order: orderBy, setOrder: setOrderBy, enableSearch: true, search: search, setSearch: setSearch }), _jsx(Fragment, { children: filteredGroups !== null ? (_jsx(Grid, Object.assign({ container: true, spacing: 4, direction: "row", alignItems: "stretch" }, { children: filteredGroups.length > 0 ? (filteredGroups.map(group => (_jsx(GroupCard, { group: group, groups: groups }, group.id)))) : (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Nope, { caption: t("queues.no-queues") }) }))) }))) : groups.length > 0 ? (_jsx(Grid, Object.assign({ container: true, spacing: 4, direction: "row", alignItems: "stretch" }, { children: groups.map(group => (_jsx(GroupCard, { group: group, groups: groups }, group.id))) }))) : (_jsx(Nope, { caption: t("queues.no-queues"), card: true })) })] }))] }));
}
