import { jsx as _jsx } from "react/jsx-runtime";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import TicketInformationForm from "../ticket-information-form";
export default function SurveyInformation({ ticket, campaign, setStep, setContact, contact, setSaveActivity, setIsSaving, isSaving, setTicket, }) {
    const isMobile = useMediaQuery("(max-width: 767px)");
    return (_jsx(Box, Object.assign({ sx: {
            paddingTop: 8,
            paddingBottom: 8,
            width: isMobile ? "100%" : "60%",
        } }, { children: _jsx(TicketInformationForm, { ticket: ticket, campaign: campaign, setStep: setStep, setContact: setContact, contact: contact, setSaveActivity: setSaveActivity, setIsInfSaving: setIsSaving, isInfSaving: isSaving, setTicket: setTicket }) })));
}
