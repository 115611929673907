import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import Table from "../../blocks/table";
import { useTenancy } from "../../../../services/tenancy";
import { useApiTenant } from "../../../../api/api-tenant";
export default function PhoneCountriesTable() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const api = useApiTenant();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_phoneCountries(query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null)
                .then(response => {
                resolve({
                    data: response.data.phone_countries,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    return (_jsx(Table, { title: t("options.phone-countries.phone-countries-table.all-phone-countries"), actions: [
            {
                icon: () => _jsx(ViewIcon, { color: "action" }),
                tooltip: t("options.phone-countries.view-phone-country"),
                onClick: (event, data) => navigate(tenancy.link("/settings/options/phone-countries/" + data.id)),
            },
            {
                icon: () => _jsx(EditIcon, { color: "action" }),
                tooltip: t("options.phone-countries.edit-phone-country"),
                onClick: (event, data) => navigate(tenancy.link("/settings/options/phone-countries/" + data.id + "/edit")),
            },
            {
                icon: () => _jsx(RefreshIcon, { color: "action" }),
                tooltip: t("options.phone-countries.phone-countries-table.refresh-phone-countries"),
                isFreeAction: true,
                onClick: () => refreshTableData(),
            },
        ], columns: isMobile
            ? [
                {
                    title: t("options.phone-countries.phone-countries-table.country") + " #",
                    field: "id",
                    defaultSort: "desc",
                },
            ]
            : [
                {
                    maxWidth: "80px",
                    title: "#",
                    field: "id",
                    type: "numeric",
                    defaultSort: "asc",
                },
                {
                    title: t("options.phone-countries.phone-country-attributes.name"),
                    field: "name",
                },
                {
                    title: t("options.phone-countries.phone-country-attributes.code"),
                    field: "code",
                },
                {
                    title: t("options.phone-countries.phone-country-attributes.phone"),
                    field: "phone",
                },
                {
                    title: t("updated-at"),
                    field: "updated_at",
                    width: "150px",
                    render: country => {
                        return new Date(Date.parse(country.updated_at)).toLocaleDateString("en-GB");
                    },
                },
            ], detailPanel: isMobile
            ? (country) => {
                return (_jsx(Box, Object.assign({ sx: {
                        paddingX: 2,
                        paddingY: 3,
                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("options.phone-countries.phone-country-attributes.name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: country.name }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("options.phone-countries.phone-country-attributes.code") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: country.code }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("options.phone-countries.phone-country-attributes.phone") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: country.phone }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(country.created_at)).toLocaleDateString("en-GB") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(country.updated_at)).toLocaleDateString("en-GB") }))] }))] })) })));
            }
            : undefined, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }));
}
