import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { StyledEngineProvider, createTheme, ThemeProvider } from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import { PrefsProvider, usePrefs } from "../services/prefs";
import { Router } from "./router";
import "../helpers/lang";
import { colors } from "../helpers/colors";
export default function App() {
    return (_jsx(StylesProvider, Object.assign({ injectFirst: true }, { children: _jsx(PrefsProvider, { children: _jsx(ThemedApp, {}) }) })));
}
function ThemedApp() {
    const prefs = usePrefs();
    const theme = createTheme({
        typography: {
            fontFamily: [
                ...(process.env.APP_ID === "mcd" ? ["Speedee"] : []),
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1670,
            },
        },
        palette: process.env.APP_ID === "mcd"
            ? {
                mode: prefs.theme,
                primary: {
                    main: prefs.theme === "light" ? colors.primary.light : colors.primary.dark,
                },
                secondary: {
                    main: colors.secondary.dark,
                },
                background: {
                    default: prefs.theme === "light"
                        ? colors.background.default.dark
                        : colors.background.default.light,
                    paper: prefs.theme === "light"
                        ? colors.background.paper.dark
                        : colors.background.paper.light,
                },
            }
            : {
                mode: prefs.theme,
                primary: {
                    main: prefs.theme === "light" ? colors.primary.dark : colors.primary.light,
                    dark: prefs.theme === "light" ? colors.primary.dark : colors.primary.light,
                    light: prefs.theme === "light" ? colors.primary.dark : colors.primary.light,
                },
                secondary: {
                    main: colors.secondary.dark,
                    dark: colors.secondary.dark,
                    light: colors.secondary.dark,
                    contrastText: "#fff",
                },
                background: {
                    default: prefs.theme === "light"
                        ? colors.background.default.dark
                        : colors.background.default.light,
                    paper: prefs.theme === "light"
                        ? colors.background.paper.dark
                        : colors.background.paper.light,
                },
            },
    });
    const customTheme = createTheme(theme, {
        components: Object.assign(Object.assign({}, (process.env.APP_ID === "mcd" && {
            MuiCssBaseline: {
                styleOverrides: `
                    @font-face {
                        font-family: 'Speedee';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 300;
                        src: url("/speedee-light.woff") format('woff2');
                    }

                    @font-face {
                        font-family: 'Speedee';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 400;
                        src: url("/speedee-regular.woff") format('woff2');
                    }

                    @font-face {
                        font-family: 'Speedee';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 500;
                        src: url("/speedee-regular.woff") format('woff2');
                    }

                    @font-face {
                        font-family: 'Speedee';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 700;
                        src: url("/speedee-bold.woff") format('woff2');
                    }
                `,
            },
        })), { MuiInputBase: {
                styleOverrides: {
                    root: {
                        "&.Mui-disabled": {
                            borderColor: prefs.theme === "light" ? "#ccc" : "#757575",
                            background: prefs.theme === "light" ? "#f5f5f5" : "#494949",
                        },
                    },
                },
            }, MuiDialogContent: {
                styleOverrides: {
                    root: {
                        paddingTop: "12px !important;",
                    },
                },
            }, MuiChip: {
                styleOverrides: {
                    label: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "15rem",
                    },
                },
            }, MuiPaper: {
                styleOverrides: {
                    root: {
                        background: prefs.theme === "light" ? "rgb(255, 255, 255)" : "rgb(66, 66, 66)",
                    },
                    rounded: {
                        "& .MuiTable-root th": {
                            whiteSpace: "nowrap",
                        },
                        "& .MuiTable-root td": {
                            [theme.breakpoints.down("lg")]: {
                                wordBreak: "break-all",
                            },
                        },
                        "& .MuiTableCell-alignRight": {
                            textAlign: "right",
                            flexDirection: "row-reverse",
                        },
                        "& .MuiFormControl-root": {
                            marginRight: 0,
                        },
                        "& .MuiToolbar-root": {
                            display: "flex",
                            padding: "0 0 0 8px !important",
                            "& > div[class*='MTableToolbar-title']": {
                                flexGrow: 1,
                            },
                            "& > div[class*='MTableToolbar-spacer']": {
                                display: "none",
                            },
                            "& > div[class*='MTableToolbar-searchField']": {
                                width: "250px",
                            },
                            [theme.breakpoints.down("lg")]: {
                                flexDirection: "column",
                                padding: "0 !important",
                                "& > div": {
                                    width: "100%",
                                    textAlign: "center",
                                    padding: "0 !important",
                                    marginLeft: "0 !important",
                                    marginBottom: theme.spacing(1),
                                },
                                "& > div:first-of-type": {
                                    textAlign: "left",
                                    marginTop: "10px",
                                },
                                "& > div:last-child": {
                                    textAlign: "right",
                                },
                                "& .MuiTablePagination-spacer": {
                                    marginTop: "4px !important",
                                },
                                "& .MuiTablePagination-caption": {
                                    alignSelf: "flex-end",
                                    paddingRight: "16px",
                                },
                                "& .MuiTablePagination-selectLabel": {
                                    marginBottom: "0px !important",
                                    marginTop: "0px !important",
                                },
                                "& > div[class*='MTableToolbar-searchField']": {
                                    width: "100%",
                                },
                            },
                        },
                        "& .MuiTableCell-footer": {
                            border: "0 !important",
                        },
                    },
                },
            }, MuiTableRow: {
                styleOverrides: {
                    root: {
                        backgroundColor: prefs.theme === "light" ? "rgb(255, 255, 255)" : "rgb(66, 66, 66)",
                    },
                },
            }, MuiTypography: {
                styleOverrides: {
                    overline: {
                        lineHeight: "1.2",
                    },
                },
            } }),
    });
    return (_jsx(StyledEngineProvider, Object.assign({ injectFirst: true }, { children: _jsxs(ThemeProvider, Object.assign({ theme: customTheme }, { children: [_jsx(CssBaseline, {}), _jsx(LangApp, {})] })) })));
}
function LangApp() {
    const { i18n } = useTranslation();
    const prefs = usePrefs();
    useEffect(() => {
        if (prefs.lang !== "en")
            i18n.changeLanguage(prefs.lang).then(() => { });
    }, []);
    return _jsx(Router, {});
}
