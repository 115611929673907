import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import ClientsTable from "../../../elements/clients/clients-table";
export default function Clients() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("clients.clients"), breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("clients.clients"),
                        link: "/administration/clients",
                    },
                ], button: {
                    label: t("clients.client-new"),
                    link: "/administration/clients/new",
                    color: "secondary",
                    type: "new",
                } }), _jsx(ClientsTable, {})] }));
}
