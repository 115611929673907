import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useRoles } from "../../../../services/roles";
import { useTenancy } from "../../../../services/tenancy";
export default function TicketUserColumn({ user }) {
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    return (_jsx(Fragment, { children: user && (_jsx(Fragment, { children: roles.isAdmin || roles.isManager ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" + user.id), onClick: event => {
                    event.preventDefault();
                    navigate(tenancy.link("/settings/users/" + user.id));
                }, underline: "hover" }, { children: user.first_name + (user.last_name ? " " + user.last_name : "") }))) : (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: user.first_name + (user.last_name ? " " + user.last_name : "") }))) })) }));
}
