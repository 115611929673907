import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { prettifyText } from "../../../../helpers/clean";
import { usePrefs } from "../../../../services/prefs";
import { useAuth } from "../../../../services/auth";
import { useRoles } from "../../../../services/roles";
export default function StepChoice({ step, modal, isDemo, ticket, directChildrenNames, chosenSteps, information, setInformation, setChosenSteps, setGroupStepId, setGroups, flatStepList, setErrors, setIsError, disableSave, }) {
    var _a;
    const { t } = useTranslation();
    const prefs = usePrefs();
    const auth = useAuth();
    const roles = useRoles();
    const relatedParentSteps = (chosenStep, steps, relatedSteps) => {
        steps.map(step => {
            if (!relatedSteps.find(element => element.id === step.id)) {
                if (chosenStep && chosenStep.parent_campaign_step_id === step.id) {
                    relatedSteps.push(step);
                    if (step && step.parent_campaign_step_id) {
                        relatedParentSteps(step, steps, relatedSteps);
                    }
                }
            }
        });
        return relatedSteps;
    };
    const relatedChildSteps = (selectedStep, relatedSteps) => {
        selectedStep.campaign_steps &&
            selectedStep.campaign_steps.map(step => {
                if (!relatedSteps.find(element => element.id === step.id)) {
                    relatedSteps.push(step);
                    if (step.campaign_steps && step.campaign_steps.length > 0)
                        relatedChildSteps(step, relatedSteps);
                }
            });
        return relatedSteps;
    };
    const removeInformationAfterChange = (chosenStep, information) => {
        let info = [];
        let relatedSteps = [];
        relatedSteps = relatedParentSteps(chosenStep, flatStepList, relatedSteps);
        information.map(inf => {
            if (relatedSteps.find(re => re.id === inf.campaign_step_id))
                info.push(inf);
        });
        setIsError(false);
        setErrors(null);
        setInformation(info);
    };
    const updateArrayWithChosen = (parentStep, chosenStep, information) => {
        const array = [...chosenSteps];
        let index = array.findIndex(e => e.parent_step.id === parentStep.id);
        if (index != -1) {
            let relatedParents = [];
            relatedParents = relatedParentSteps(chosenStep, flatStepList, relatedParents);
            let arr = array.filter(e => e.parent_step.id !== parentStep.id &&
                relatedParents.find(a => a.id === e.chosen_step.id));
            arr.push({ chosen_step: chosenStep, parent_step: parentStep });
            removeInformationAfterChange(chosenStep, information);
            disableSave();
            setGroups([]);
            setGroupStepId(undefined);
            setChosenSteps(arr);
        }
        else {
            array.push({ parent_step: parentStep, chosen_step: chosenStep });
            setChosenSteps(array);
        }
    };
    const displayChoiceStepsChildren = (directChild) => {
        return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: isDemo ? 3 : 4 }, { children: _jsx(Card, Object.assign({ variant: "outlined", style: {
                    border: chosenSteps.length > 0 &&
                        chosenSteps.find(step => { var _a; return ((_a = step.chosen_step) === null || _a === void 0 ? void 0 : _a.id) === directChild.id; })
                        ? "1px solid #4caf50"
                        : prefs.theme === "light"
                            ? "1px solid rgba(0, 0, 0, 0.12)"
                            : "1px solid rgba(255, 255, 255, 0.12)",
                    backgroundColor: chosenSteps.length > 0 &&
                        chosenSteps.find(step => { var _a; return ((_a = step.chosen_step) === null || _a === void 0 ? void 0 : _a.id) === directChild.id; })
                        ? "rgba(76, 175, 80, 0.2)"
                        : "transparent",
                    opacity: roles.isAgent && step.not_editable && !(ticket === null || ticket === void 0 ? void 0 : ticket.is_draft) ? 0.5 : 1,
                } }, { children: _jsx(CardActionArea, Object.assign({ disabled: roles.isAgent && step.not_editable && !(ticket === null || ticket === void 0 ? void 0 : ticket.is_draft), onClick: () => {
                        if (roles.isAgent && step.not_editable && !(ticket === null || ticket === void 0 ? void 0 : ticket.is_draft))
                            return;
                        updateArrayWithChosen(step, directChild, information);
                    } }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            wordBreak: "break-word",
                            height: directChild.url ? "150px" : "50px",
                            margin: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        } }, { children: [directChild.url && (_jsx("img", { src: directChild.url, width: "100%", height: "100px", alt: t("tickets.ticket-information.ticket-information-form.option") +
                                    " " +
                                    directChild.id, style: {
                                    objectFit: "contain",
                                    marginBottom: "4px",
                                } })), _jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                    display: "-webkit-box",
                                    lineHeight: 1.5,
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                } }, { children: prettifyText(directChild.name) }))] })) })) })) }), directChild.id));
    };
    return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8, lg: isDemo ? 10 : modal ? 12 : 8 }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: { padding: 4 } }, { children: [auth.isPublic && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: (step.caption && step.caption !== "" ? step.caption : step.name) +
                                " *" }) }))), _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: directChildrenNames.length <= 9 ? (directChildrenNames.map(directChild => displayChoiceStepsChildren(directChild))) : (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, disableClearable: true, options: directChildrenNames.sort((a, b) => a.name.localeCompare(b.name)), getOptionLabel: child => (child ? child.name : ""), isOptionEqualToValue: (option, value) => (option === null || option === void 0 ? void 0 : option.id) === (value === null || value === void 0 ? void 0 : value.id), value: chosenSteps.find(chosenStep => chosenStep.parent_step.id === step.id)
                                    ? [
                                        (_a = chosenSteps.find(chosenStep => chosenStep.parent_step.id === step.id)) === null || _a === void 0 ? void 0 : _a.chosen_step,
                                    ]
                                    : [], onChange: (event, value) => {
                                    let newArray = [];
                                    if (value.length > 1) {
                                        newArray.push(value[1]);
                                    }
                                    else {
                                        if (value[0])
                                            newArray.push(value[0]);
                                    }
                                    updateArrayWithChosen(step, newArray[0], information);
                                }, renderOption: (props, child) => (_jsx("li", Object.assign({}, props, { children: _jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            flexDirection: "column",
                                        } }, { children: _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                            } }, { children: _jsx(Typography, { children: child ? child.name : "" }) })) }), child ? child.id : "") }))), renderTags: value => value.map(child => (_jsx(Chip, { variant: "filled", label: child ? child.name : "", deleteIcon: undefined, onDelete: undefined }, child ? child.id : ""))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: step.name, fullWidth: true, InputProps: Object.assign(Object.assign({ readOnly: true }, params.InputProps), { endAdornment: (_jsx(Fragment, { children: params.InputProps.endAdornment })) }), onKeyDown: (event) => {
                                        if (event.key === "Backspace" ||
                                            event.key === "Delete" ||
                                            event.key === "ArrowLeft") {
                                            event.stopPropagation();
                                        }
                                    } }))) }) }))) }))] })) }) })));
}
