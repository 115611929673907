import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { useTenancy } from "../../../services/tenancy";
export default function SearchUserCard({ user }) {
    const navigate = useNavigate();
    const tenancy = useTenancy();
    return (_jsx(Card, Object.assign({ variant: "outlined" }, { children: _jsx(CardActionArea, Object.assign({ onClick: () => navigate(tenancy.link("/settings/users/" + user.id)) }, { children: _jsxs(CardContent, { children: [user.roles && user.roles.length > 0 && (_jsx(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: user.roles[0].name.toUpperCase() }))), user.first_name && (_jsx(Typography, Object.assign({ variant: "h6" }, { children: user.first_name + " " + user.last_name }))), user.email && _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: user.email }))] }) })) })));
}
