export const flatGroups = (groups, level, list) => {
    groups.map(group => {
        if (!list.find(element => element.id === group.id))
            list.push(group);
        if (group.groups && group.groups.length > 0) {
            group.groups.map(group => list.push(group));
            flatGroups(group.groups, level++, list);
        }
        if (group.parent_groups) {
            list.push(group.parent_groups);
            flatGroups(new Array(group.parent_groups), level++, list);
        }
    });
    return list;
};
export const flatGroupsWithLevels = (groups, level, list) => {
    groups.map(group => {
        if (!list.find(element => element.group.id === group.id)) {
            level = 0;
            list.push({ group, level });
        }
        if (group.groups && group.groups.length > 0) {
            level = level + 1;
            group.groups.map(group => list.push({ group, level }));
            flatGroupsWithLevels(group.groups, level, list);
        }
    });
    return list;
};
export const sortGroupsWithLevels = (groups, level, list, count) => {
    groups.map(group => {
        if (list && group.level == 0) {
            if (!list.find(element => element.group.id == group.id)) {
                list.push({ id: count.toString(), group: group.group });
                count++;
            }
        }
        if (group.group && group.group.groups && group.group.groups.length > 0) {
            let count2 = 1;
            group.group.groups.map(group => {
                if (!list.find(sorted => sorted.group.id == group.id)) {
                    list.map(sortedGroup => {
                        if (sortedGroup.group.id == group.parent_group_id.toString()) {
                            list.push({
                                id: sortedGroup.id.concat(".").concat(count2.toString()),
                                group,
                            });
                            count2++;
                        }
                    });
                    sortGroupsWithLevels(group.groups, level++, list, count);
                }
            });
        }
    });
    return list.sort((a, b) => -a.id.localeCompare(b.id)).reverse();
};
export const sortGroups = (groups, level, list, count) => {
    groups.map(group => {
        if (list && group.parent_group_id == null) {
            if (!list.find(element => element.group.id == group.id)) {
                list.push({ id: count.toString(), group: group });
                count++;
            }
        }
        if (group.groups && group.groups.length > 0) {
            let count2 = 1;
            group.groups.map(group => {
                if (!list.find(sorted => sorted.group.id == group.id)) {
                    list.map(sortedGroup => {
                        if (sortedGroup.group.id == group.parent_group_id.toString()) {
                            list.push({
                                id: sortedGroup.id.concat(".").concat(count2.toString()),
                                group,
                            });
                            count2++;
                        }
                    });
                    sortGroups(group.groups, level++, list, count);
                }
            });
        }
    });
    return list.sort((a, b) => -a.id.localeCompare(b.id)).reverse();
};
export const relatedChildGroups = (selectedGroup, relatedGroups) => {
    selectedGroup.groups &&
        selectedGroup.groups.map(group => {
            if (!relatedGroups.find(element => element.id === group.id)) {
                relatedGroups.push(group);
                if (group.groups && group.groups.length > 0)
                    relatedChildGroups(group, relatedGroups);
            }
        });
    return relatedGroups;
};
export const relatedParentGroups = (selectedGroup, groups, relatedGroups) => {
    groups.map(group => {
        if (!relatedGroups.find(element => element.id === group.id)) {
            if (selectedGroup.parent_group_id === group.id) {
                relatedGroups.push(group);
                if (group.parent_group_id) {
                    relatedParentGroups(group, groups, relatedGroups);
                }
            }
        }
    });
    return relatedGroups;
};
export const groupInGroups = (selectedGroup, groups) => {
    let chosen = null;
    groups.map(group => {
        if (group.id === selectedGroup.id)
            chosen = group;
    });
    return chosen;
};
