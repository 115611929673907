import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputCustomFields from "../../inputs/input-custom-fields";
import { useRoles } from "../../../../services/roles";
export default function StepInformation({ step, modal, isDemo, information, setInformation, ticket, errors, isError, setIsError, flatStepList, autofillData, }) {
    const roles = useRoles();
    const [disabledCustomFields, setDisabledCustomFields] = useState([]);
    useEffect(() => {
        let disabled = [];
        flatStepList.map(step => {
            var _a;
            if (step.type === "form" && step.custom_fields) {
                (_a = step.custom_fields) === null || _a === void 0 ? void 0 : _a.map(field => {
                    var _a, _b;
                    if ((field.field_type === "slider" || field.field_type === "range") &&
                        ((!information.find(inf => inf.custom_field_id === field.id) &&
                            !field.autofill_data) ||
                            (information.find(inf => inf.custom_field_id === field.id) &&
                                (((_a = information.find(inf => inf.custom_field_id === field.id)) === null || _a === void 0 ? void 0 : _a.value) === null ||
                                    ((_b = information.find(inf => inf.custom_field_id === field.id)) === null || _b === void 0 ? void 0 : _b.values) === null) &&
                                !field.autofill_data))) {
                        disabled.push({ customField: field, disabled: true });
                    }
                });
            }
        });
        setDisabledCustomFields(disabled);
    }, [flatStepList.length > 0]);
    return (_jsx(Fragment, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8, lg: isDemo ? 10 : modal ? 12 : 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: { padding: 2 } }, { children: step.custom_fields && (_jsx(InputCustomFields, { type: "information", tenancyCustomFields: step.custom_fields, information: information, setInformation: setInformation, disabledCustomFields: disabledCustomFields, setDisabledCustomFields: setDisabledCustomFields, step: step, notEditable: (ticket === null || ticket === void 0 ? void 0 : ticket.is_draft) === false && roles.isAgent, error: "ticket_information.", errors: errors, isError: isError, setIsError: setIsError, modal: modal, autofillData: autofillData })) })) }) })) }));
}
