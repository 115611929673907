import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import AreaIcon from "@mui/icons-material/Explore";
import { useTenancy } from "../../../../services/tenancy";
import { useAlert } from "../../../../services/alert";
import { useApiTenant } from "../../../../api/api-tenant";
export default function PhoneAreaForm({ status, phoneCountries, phoneArea }) {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [name, setName] = useState((_a = phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.name) !== null && _a !== void 0 ? _a : "");
    const [country, setCountry] = useState((_b = phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.phone_country) !== null && _b !== void 0 ? _b : null);
    const [phone, setPhone] = useState((_c = phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.phone) !== null && _c !== void 0 ? _c : "");
    const [validationRule, setValidationRule] = useState((_d = phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.validation_rule) !== null && _d !== void 0 ? _d : "");
    const [validationMessage, setValidationMessage] = useState((_e = phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.validation_message) !== null && _e !== void 0 ? _e : "");
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createPhoneArea = () => {
        if (!country)
            return;
        setIsSaving(true);
        setIsError(false);
        api.CREATE_phoneArea(name, phone, country.id, validationRule, validationMessage)
            .then(response => {
            setIsSaving(false);
            alert.show(t("options.phone-areas.phone-area-form.phone-area-created"), "success");
            navigate(tenancy.link("/settings/options/phone-areas/" + response.data.phone_area.id));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("options.phone-areas.phone-area-form.phone-area-created"), "error");
        });
    };
    const updatePhoneArea = () => {
        if (!phoneArea)
            return;
        if (!country)
            return;
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_phoneArea(phoneArea.id, name, phone, country.id, validationRule, validationMessage)
            .then(() => {
            setIsSaving(false);
            alert.show(t("options.phone-areas.phone-area-form.phone-area-updated"), "success");
            navigate(tenancy.link("/settings/options/phone-areas/" + phoneArea.id));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("options.phone-areas.phone-area-form.updating-phone-area-error-occured"), "error");
        });
    };
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(AreaIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("options.phone-areas.phone-area-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, Object.assign({ component: "form" }, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: isError ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name") : false }, { children: [_jsx(InputLabel, { children: t("options.phone-areas.phone-area-attributes.name") }), _jsx(OutlinedInput, { label: t("options.phone-areas.phone-area-attributes.name") + "*", value: name, autoComplete: "new-password", onChange: event => {
                                                                setName(event.target.value);
                                                                if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name")))
                                                                    setIsError(false);
                                                            } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["name"][0] })))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Autocomplete, { disabled: status === "edit", options: phoneCountries, getOptionLabel: country => country.name +
                                                    " (" +
                                                    country.code +
                                                    ") +" +
                                                    country.phone, isOptionEqualToValue: (option, value) => option.code === value.code, value: country, onChange: (event, value) => {
                                                    setCountry(value);
                                                    if (isError &&
                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("phone_country_id")))
                                                        setIsError(false);
                                                }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-areas.phone-area-attributes.country"), helperText: isError &&
                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("phone_country_id"))
                                                        ? errors["name"][0]
                                                        : false, fullWidth: true, required: true, inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: "new-password" }), error: isError
                                                        ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("phone_country_id")
                                                        : false }))) }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ disabled: status === "edit", required: true, fullWidth: true, error: isError ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("phone") : false }, { children: [_jsx(InputLabel, { children: t("options.phone-areas.phone-area-attributes.phone") }), _jsx(OutlinedInput, { autoComplete: "new-password", label: t("options.phone-areas.phone-area-attributes.phone") + "*", value: phone, onChange: event => {
                                                                setPhone(event.target.value);
                                                                if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("phone")))
                                                                    setIsError(false);
                                                            } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("phone")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["phone"][0] })))] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, error: isError
                                                        ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("validation_rule")
                                                        : false }, { children: [_jsx(InputLabel, { children: t("options.phone-areas.phone-area-attributes.validation-rule") }), _jsx(OutlinedInput, { autoComplete: "new-password", label: t("options.phone-areas.phone-area-attributes.validation-rule"), value: validationRule, onChange: event => {
                                                                setValidationRule(event.target.value);
                                                                if (isError &&
                                                                    (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("validation_rule")))
                                                                    setIsError(false);
                                                            } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("validation_rule")) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["validation_rule"][0] }))) : (_jsx(FormHelperText, { children: t("options.phone-areas.phone-area-form.validation-rule-caption") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, error: isError
                                                        ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("validation_message")
                                                        : false }, { children: [_jsx(InputLabel, { children: t("options.phone-areas.phone-area-attributes.validation-message") }), _jsx(OutlinedInput, { autoComplete: "new-password", label: t("options.phone-areas.phone-area-attributes.validation-message"), value: validationMessage, onChange: event => {
                                                                setValidationMessage(event.target.value);
                                                                if (isError &&
                                                                    (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("validation_message")))
                                                                    setIsError(false);
                                                            } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("validation_message")) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["validation_message"][0] }))) : (_jsx(FormHelperText, { children: t("options.phone-areas.phone-area-form.validation-message-caption") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Divider, { light: true }), _jsx(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        paddingTop: 4,
                                                    } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !name || !country || !phone, onClick: () => status === "new"
                                                            ? createPhoneArea()
                                                            : updatePhoneArea(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                                            ? t("form-component.create")
                                                            : t("form-component.update") })) }))] }))] })) })) })) }))] })) })) }));
}
