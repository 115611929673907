import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { useScripts } from "../../../services/scripts";
let placesService = null;
export default function InputAddress({ address, setAddress, caption, disabled, label, required, }) {
    const { t } = useTranslation();
    const scripts = useScripts();
    scripts.loadGoogleMaps();
    const [place, setPlace] = useState(address && typeof address !== "string"
        ? {
            description: address.street_name +
                (address.street_number !== null && address.street_number !== ""
                    ? " " + address.street_number
                    : "") +
                ", " +
                address.city +
                ", " +
                address.country,
            place_id: address.place_id,
            terms: [],
        }
        : null);
    const [places, setPlaces] = useState([]);
    const [addressError, setAddressError] = useState(false);
    const [search, setSearch] = useState("");
    const getPlaces = useMemo(() => (request, callback) => placesService.getPlacePredictions({
        input: request.search,
        types: ["address"],
        // @ts-ignore
        location: new google.maps.LatLng(45.8, 16.0),
        radius: 10000,
    }, callback), []);
    const debounceSearch = useCallback(debounce(search => {
        getPlaces({ search }, places => {
            if (!places || places.length === 0)
                return;
            setPlaces(places);
        });
    }, 700), []);
    useEffect(() => {
        // @ts-ignore
        if (!placesService && window.google)
            placesService = new window.google.maps.places.AutocompleteService();
        if (!placesService || search === "")
            return;
        debounceSearch(search);
    }, [search]);
    useEffect(() => {
        if (address && typeof address !== "string") {
            setPlace({
                description: address.street_name +
                    (address.street_number !== null && address.street_number !== ""
                        ? " " + address.street_number
                        : "") +
                    ", " +
                    address.city +
                    ", " +
                    address.country,
                place_id: address.place_id,
                terms: [],
            });
        }
        else {
            setPlace(null);
        }
    }, [address]);
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: caption }) }))), _jsx(Autocomplete, { disabled: disabled ? disabled : false, value: place ? place : null, options: place ? [place, ...places] : places, getOptionLabel: option => option.description, isOptionEqualToValue: (option, value) => option === value, filterSelectedOptions: true, disableClearable: address === null, noOptionsText: t("inputs.input-address.start-typing-address"), onChange: (event, place) => {
                    setAddressError(false);
                    setPlace(place ? place : undefined);
                    setPlaces([]);
                    if (place && (!(place === null || place === void 0 ? void 0 : place.terms[1]) || !(place === null || place === void 0 ? void 0 : place.terms[2]))) {
                        setAddressError(true);
                        return;
                    }
                    setAddress(place !== null && place !== undefined
                        ? {
                            street_name: place === null || place === void 0 ? void 0 : place.terms[0].value,
                            street_number: isNaN(place === null || place === void 0 ? void 0 : place.terms[1].value) &&
                                (place === null || place === void 0 ? void 0 : place.terms[0].value) != null
                                ? ""
                                : place === null || place === void 0 ? void 0 : place.terms[1].value,
                            city: isNaN(place === null || place === void 0 ? void 0 : place.terms[1].value)
                                ? (place === null || place === void 0 ? void 0 : place.terms[1])
                                    ? place === null || place === void 0 ? void 0 : place.terms[1].value
                                    : ""
                                : (place === null || place === void 0 ? void 0 : place.terms[2])
                                    ? place === null || place === void 0 ? void 0 : place.terms[2].value
                                    : "",
                            country: isNaN(place === null || place === void 0 ? void 0 : place.terms[1].value)
                                ? (place === null || place === void 0 ? void 0 : place.terms[2])
                                    ? place === null || place === void 0 ? void 0 : place.terms[2].value
                                    : ""
                                : (place === null || place === void 0 ? void 0 : place.terms[3])
                                    ? place === null || place === void 0 ? void 0 : place.terms[3].value
                                    : "",
                            place_id: place === null || place === void 0 ? void 0 : place.place_id,
                        }
                        : null);
                }, onInputChange: (event, value) => setSearch(value ? value : ""), renderInput: params => (_jsx(TextField, Object.assign({}, params, { disabled: (address !== null && address !== "") || (disabled ? disabled : false), error: addressError, required: required, label: label ? label : t("inputs.input-address.address"), helperText: addressError ? t("inputs.input-address.enter-valid-address") : "", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { autoComplete: "new-password" }) }))) })] }));
}
