import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { usePrefs } from "../../../services/prefs";
import { useAuth } from "../../../services/auth";
import { useApiPublic } from "../../../api/api-public";
import Button from "@mui/material/Button";
import analytics from "../../../helpers/analytics";
import { colors } from "../../../helpers/colors";
import { GlobalStyles } from "@mui/material";
const logoDark = require("../../../assets/" + process.env.APP_ID + "/logo-dark.svg");
const useStyles = makeStyles(() => createStyles({
    copy: {
        color: "#fff",
    },
}));
export default function ForgotPassword() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const prefs = usePrefs();
    const auth = useAuth();
    const api = useApiPublic();
    const [email, setEmail] = useState("");
    const [responseStatus, setResponseStatus] = useState(0);
    const [responseMessage, setResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const validateEmail = (newEmail) => {
        if (/\S+@\S+\.\S+/.test(newEmail))
            setIsError(false);
        else
            setIsError(true);
    };
    const resetPassword = () => {
        setIsLoading(true);
        api.GET_resetPassword(email)
            .then(response => {
            setResponseStatus(response.status);
            setIsLoading(false);
            analytics.sendEvent({
                category: "User",
                action: "Reset email",
            }, auth.user);
        })
            .catch(error => {
            setIsError(true);
            console.error(error.response);
            setIsLoading(false);
            setResponseStatus(error.response.status);
            setResponseMessage(error.response.data.errors["email"][0]);
        });
        return () => api.cancel();
    };
    useEffect(() => {
        if (auth.user)
            navigate("/");
    }, [auth.user]);
    return (_jsxs(Box, Object.assign({ sx: {
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
        } }, { children: [_jsx(GlobalStyles, { styles: {
                    body: {
                        backgroundColor: process.env.APP_ID === "mcd"
                            ? colors.primary.light
                            : colors.primary.dark,
                    },
                } }), _jsx(Box, Object.assign({ sx: {
                    position: "absolute",
                    top: 15,
                    right: 10,
                    backgroundColor: "#fff",
                } }, { children: _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "text", color: "inherit" }, { children: [_jsx(Button, Object.assign({ disabled: prefs.lang === "en", onClick: () => {
                                i18n.changeLanguage("en").then(() => {
                                    prefs.setLang("en");
                                });
                            } }, { children: "EN" })), _jsx(Button, Object.assign({ disabled: prefs.lang === "hr", onClick: () => {
                                i18n.changeLanguage("hr").then(() => {
                                    prefs.setLang("hr");
                                });
                            } }, { children: "HR" }))] })) })), _jsxs(Container, Object.assign({ maxWidth: "xs" }, { children: [_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 4,
                            paddingBottom: 2,
                        } }, { children: _jsx("img", { src: logoDark, style: {
                                maxHeight: "120px",
                                maxWidth: "220px",
                                objectFit: "contain",
                            }, alt: "Logo" }) })), _jsx(Box, Object.assign({ sx: {
                            paddingY: 4,
                        } }, { children: _jsx(Paper, Object.assign({ onKeyPress: event => {
                                if (event.key === "Enter" && email && !isError)
                                    resetPassword();
                            } }, { children: _jsxs(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: [responseStatus !== 200 && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                    padding: 2,
                                                } }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("pages.auth.email") }), _jsx(OutlinedInput, { autoFocus: true, type: "email", label: t("pages.auth.email") + "*", value: email, onChange: event => {
                                                                validateEmail(event.target.value);
                                                                setEmail(event.target.value);
                                                                if (responseStatus !== 0)
                                                                    setResponseStatus(0);
                                                            } })] })) })), _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingX: 2,
                                                    paddingTop: 2,
                                                    paddingBottom: 1,
                                                } }, { children: _jsx(LoadingButton, Object.assign({ fullWidth: true, disabled: !email || isError, loading: isLoading, size: "large", variant: "contained", color: "secondary", type: "button", loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), onClick: () => resetPassword() }, { children: t("pages.auth.forgot-password.send-reset-email") })) })), _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: 1,
                                                } }, { children: _jsx(Button, Object.assign({ type: "button", size: "small", color: "inherit", onClick: () => {
                                                        navigate("/login");
                                                    } }, { children: t("pages.auth.forgot-password.back-to-login") })) }))] })), responseStatus !== 0 && (_jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingX: 2,
                                            paddingTop: 2,
                                            paddingBottom: 1,
                                        } }, { children: responseStatus === 200 ? (_jsx(Alert, Object.assign({ style: { width: "100%" }, severity: "success" }, { children: t("pages.auth.forgot-password.email-sent") }))) : responseStatus === 422 ? (_jsx(Alert, Object.assign({ style: { width: "100%" }, severity: "error" }, { children: responseMessage }))) : (_jsx(Alert, Object.assign({ style: { width: "100%" }, severity: "error" }, { children: t("pages.auth.forgot-password.error-occured") }))) })))] })) })) })), _jsx(Box, Object.assign({ sx: {
                            paddingTop: 2,
                            paddingBottom: 4,
                            textAlign: "center",
                        } }, { children: _jsxs(Typography, Object.assign({ className: classes.copy }, { children: [_jsx(Link, Object.assign({ href: process.env.APP_LINK, color: "inherit", underline: "hover" }, { children: process.env.APP_NAME })), _jsxs(Box, Object.assign({ component: "span", sx: {
                                        paddingLeft: 1,
                                    } }, { children: ["\u00A9 ", new Date().getFullYear()] }))] })) }))] }))] })));
}
