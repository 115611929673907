import { createSingletonHook } from "../helpers/singleton";
export const [useScripts, ScriptsProvider] = createSingletonHook(() => {
    const loadScript = (src) => {
        let script = document.querySelector(`script[src="${src}"]`);
        if (script)
            return;
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
    };
    const loadGoogleMaps = () => {
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_KEY}&libraries=places`);
    };
    return {
        loadGoogleMaps,
    };
});
