import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import TicketsMetrics from "../../elements/tickets/tickets-metrics";
import TicketsTable from "../../elements/tickets/tickets-table";
import TicketCampaignSelect from "../../elements/tickets/ticket-campaign-select";
import { useRoles } from "../../../services/roles";
export default function Tickets() {
    const { t } = useTranslation();
    const roles = useRoles();
    const [newTicket, setNewTicket] = useState(false);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.tickets"), breadcrumbs: [
                    {
                        name: t("tickets.tickets"),
                        link: "/tickets",
                    },
                ], button: {
                    label: t("tickets.new-ticket"),
                    action: () => setNewTicket(true),
                    color: "secondary",
                    type: "new",
                } }), (roles.isManager || roles.isAdmin) && _jsx(TicketsMetrics, { type: "tickets" }), _jsx(TicketsTable, { type: "tickets" }), _jsx(TicketCampaignSelect, { newTicket: newTicket, setNewTicket: setNewTicket })] }));
}
