import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import TicketSteps from "../../elements/tickets/ticket-steps";
import TicketHistory from "../../elements/tickets/ticket-history";
import Intro from "../../elements/blocks/intro";
import ContactForm from "../../elements/contacts/contact-form";
import ContactHistory from "../../elements/contacts/contact-history";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useAlert } from "../../../services/alert";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
export default function ContactTicket() {
    const { t } = useTranslation();
    const { contactId, campaignId } = useParams();
    const api = useApiTenant();
    const alert = useAlert();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const roles = useRoles();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const [contact, setContact] = useState(null);
    const [updatedContact, setUpdatedContact] = useState(false);
    const [ticketHistory, setTicketHistory] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const createTicket = () => {
        if (!campaign || !contact)
            return;
        setIsSaving(true);
        api.CREATE_ticket(campaign.id, contact.id)
            .then(response => {
            alert.show(t("contacts.contact-ticket.draft-created"), "info");
            setIsSaving(false);
            window.history.pushState({}, "", tenancy.link("/tickets/" + response.data.ticket.id + "/contact"));
            if (response.data.ticket.campaign.has_steps) {
                navigate(tenancy.link("/tickets/" + response.data.ticket.id + "/information"));
            }
            else {
                navigate(tenancy.link("/tickets/" + response.data.ticket.id + "/details"));
            }
        })
            .catch(error => {
            console.error(error.response);
            setIsSaving(false);
            alert.show(t("contacts.contact-ticket.creating-error-occured"), "error");
        });
    };
    useEffect(() => {
        api.GET_contact(contactId)
            .then(response => {
            const contact = response.data.contact;
            setContact(contact);
            api.GET_campaign(campaignId)
                .then(response => {
                const campaign = response.data.campaign;
                if (campaign.has_contact_fields) {
                    setCampaign(campaign);
                    setIsLoading(false);
                }
                else {
                    api.CREATE_ticket(campaign.id, contact.id)
                        .then(response => {
                        alert.show(t("campaigns.campaign-ticket.draft-created"), "info");
                        if (campaign.has_steps) {
                            navigate(tenancy.link("/tickets/" +
                                response.data.ticket.id +
                                "/information"));
                        }
                        else {
                            navigate(tenancy.link("/tickets/" + response.data.ticket.id + "/details"));
                        }
                    })
                        .catch(error => {
                        console.error(error.response);
                        setIsLoading(false);
                        alert.show(t("campaigns.campaign-ticket.creating-error"), "error");
                    });
                }
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                    setIsError(true);
                }
            });
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
                setIsError(true);
            }
        });
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (!isLoading)
            return;
        prefs.setTicketStartTime(new Date().toString());
    }, [isLoading]);
    useEffect(() => {
        if (!updatedContact)
            return;
        api.GET_tickets(1, 1, "id", "desc", undefined, undefined, (campaign === null || campaign === void 0 ? void 0 : campaign.id) ? [campaign === null || campaign === void 0 ? void 0 : campaign.id] : undefined, (contact === null || contact === void 0 ? void 0 : contact.id) ? [contact === null || contact === void 0 ? void 0 : contact.id] : undefined, undefined, undefined, undefined, roles.isAgent
            ? roles.groupsTenant
                ? roles.groupsTenant.map(group => group.id)
                : []
            : ["-1"], undefined, undefined, "exclude", "include", "include")
            .then(response => {
            if (response.data.tickets.length > 0) {
                setTicketHistory(true);
            }
            else
                createTicket();
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
            }
        });
        return () => api.cancel();
    }, [updatedContact]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.new-ticket"), breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: campaign ? campaign.name : t("campaigns.campaign"),
                        link: campaign ? "/campaigns/" + campaignId : "/campaigns",
                    },
                    {
                        name: t("tickets.new-ticket"),
                        link: "/campaigns/" + campaignId + "/new-ticket",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : (_jsxs(Fragment, { children: [contact && campaign && (_jsxs(Fragment, { children: [_jsx(TicketSteps, { step: "contact", campaign: campaign }), _jsx(ContactForm, { kind: "ticket", status: "edit", campaign: campaign, contact: contact, setContact: setContact, setUpdatedContact: setUpdatedContact, ticketDraft: true, newContact: false }), contact.history && contact.history.length > 0 && (_jsx(ContactHistory, { status: "view", contact: contact })), _jsx(TicketHistory, { campaign: campaign, contact: contact, createTicket: createTicket, ticketHistoryOpened: ticketHistory, isLoading: isSaving })] })), isError && (_jsx(Nope, { title: t("errors.404"), caption: contact
                            ? t("campaigns.errors.not-found")
                            : t("contacts.errors.not-found") }))] }))] }));
}
