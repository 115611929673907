import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { isValid } from "date-fns";
import en_GBLocale from "date-fns/locale/en-GB";
export default function InputDatetime({ type, label, required, value, setValue, caption, info, status, disabled, error, errors, isError, setIsError, }) {
    const [dateError, setDateError] = useState(false);
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: caption }) }))), _jsx(FormControl, Object.assign({ fullWidth: true, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: _jsx(LocalizationProvider
                // @ts-ignore
                , Object.assign({ 
                    // @ts-ignore
                    dateAdapter: AdapterDateFns, adapterLocale: en_GBLocale }, { children: type === "date_time" ? (_jsx(DateTimePicker, { label: label, disabled: disabled ? disabled : false, renderInput: props => (_jsx(TextField, Object.assign({}, props, { label: label, required: required, fullWidth: true, helperText: isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error))
                                ? errors[error][0]
                                : info
                                    ? info
                                    : false, error: isError && errors && error
                                ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)
                                : dateError }))), onChange: date => {
                            if (date !== null) {
                                if (isValid(date)) {
                                    setDateError(false);
                                    let formatDate = new Date(date);
                                    let year = formatDate.getFullYear();
                                    let month = formatDate.getMonth() + 1;
                                    let day = formatDate.getDate();
                                    let hours = formatDate.getHours();
                                    let minutes = formatDate.getMinutes();
                                    let seconds = formatDate.getSeconds();
                                    let newDate = year +
                                        "-" +
                                        month +
                                        "-" +
                                        day +
                                        " " +
                                        hours +
                                        ":" +
                                        minutes +
                                        ":" +
                                        (status ? (status === "start" ? "00" : "59") : seconds);
                                    if (isValid(new Date(newDate))) {
                                        setValue(newDate);
                                        setDateError(false);
                                    }
                                    else {
                                        setDateError(true);
                                    }
                                }
                                else {
                                    setDateError(true);
                                }
                            }
                            else {
                                setValue(null);
                                if (dateError)
                                    setDateError(false);
                            }
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        }, value: value })) : type === "date" ? (_jsx(DatePicker, { label: label, disabled: disabled ? disabled : false, renderInput: props => (_jsx(TextField, Object.assign({}, props, { label: label, required: required, fullWidth: true, helperText: isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error))
                                ? errors[error][0]
                                : info
                                    ? info
                                    : false, error: isError && errors && error
                                ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)
                                : dateError }))), value: value, onChange: date => {
                            if (date !== null) {
                                if (isValid(date)) {
                                    setDateError(false);
                                    let formatDate = new Date(date);
                                    let year = formatDate.getFullYear();
                                    let month = formatDate.getMonth() + 1;
                                    let day = formatDate.getDate();
                                    let hours = formatDate.getHours();
                                    let minutes = formatDate.getMinutes();
                                    let seconds = formatDate.getSeconds();
                                    let newDate = year +
                                        "-" +
                                        month +
                                        "-" +
                                        day +
                                        " " +
                                        hours +
                                        ":" +
                                        minutes +
                                        ":" +
                                        seconds;
                                    if (isValid(new Date(newDate))) {
                                        setValue(newDate);
                                        setDateError(false);
                                    }
                                    else {
                                        setDateError(true);
                                    }
                                }
                                else {
                                    setDateError(true);
                                }
                            }
                            else {
                                setValue(null);
                                if (dateError)
                                    setDateError(false);
                            }
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } })) : (_jsx(TimePicker, { disabled: disabled ? disabled : false, label: label, renderInput: props => (_jsx(TextField, Object.assign({}, props, { label: label, required: required, fullWidth: true, helperText: isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error))
                                ? errors[error][0]
                                : info
                                    ? info
                                    : false, error: isError && errors && error
                                ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)
                                : dateError && value !== "" && value !== null }))), value: value, onChange: date => {
                            if (date !== null) {
                                if (isValid(date)) {
                                    setDateError(false);
                                    let formatDate = new Date(date);
                                    let year = formatDate.getFullYear();
                                    let month = formatDate.getMonth() + 1;
                                    let day = formatDate.getDate();
                                    let hours = formatDate.getHours();
                                    let minutes = formatDate.getMinutes();
                                    let seconds = formatDate.getSeconds();
                                    let newDate = year +
                                        "-" +
                                        month +
                                        "-" +
                                        day +
                                        " " +
                                        hours +
                                        ":" +
                                        minutes +
                                        ":" +
                                        (status ? "00" : seconds);
                                    if (isValid(new Date(newDate))) {
                                        setValue(newDate);
                                        setDateError(false);
                                    }
                                    else {
                                        setDateError(true);
                                    }
                                }
                                else {
                                    setDateError(true);
                                }
                            }
                            else {
                                setValue(null);
                                if (dateError)
                                    setDateError(false);
                            }
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } })) })) }))] }));
}
