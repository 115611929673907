import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import ClientForm from "../../../elements/clients/client-form";
export default function ClientNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("clients.client-new"), breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("clients.clients"),
                        link: "/administration/clients",
                    },
                    {
                        name: t("clients.client-new"),
                        link: "/administration/clients/new",
                    },
                ] }), _jsx(ClientForm, { status: "new" })] }));
}
