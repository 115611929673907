import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import InputSelect from "../inputs/input-select";
import InputSlider from "../inputs/input-slider";
import { useApiTenant } from "../../../api/api-tenant";
import { prettifyText } from "../../../helpers/clean";
export default function ContactFilterBy({ showFilters, filterType, setFilterType, filterPhoneCountry, setFilterPhoneCountry, filterPhoneArea, setFilterPhoneArea, filterCustomFields, setFilterCustomFields, isLoadingTable, showMoreFilters, setShowMoreFilters, areFieldsAndGroupsLoaded, setAreFieldsAndGroupsLoaded, phoneCountries, setPhoneCountries, phoneAreas, setPhoneAreas, customFields, setCustomFields, }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const [switchOptions] = useState([
        { label: t("yes"), value: "1", index: 1, text: "Yes" },
        { label: t("no"), value: "0", index: 0, text: "No" },
    ]);
    const [disabledCustomFields, setDisabledCustomFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);
    useEffect(() => {
        if (!showFilters)
            return;
        if (!areFieldsAndGroupsLoaded) {
            setIsLoading(true);
            setIsLoading2(true);
            setIsLoadingCustomFields(true);
            api.GET_phoneCountries()
                .then(response => {
                setPhoneCountries(response.data.phone_countries);
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
            api.GET_phoneAreas()
                .then(response => {
                setPhoneAreas(response.data.phone_areas);
                setIsLoading2(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
            api.GET_customFields("contacts", 10000, 1, undefined, undefined, undefined, undefined, undefined, undefined, 1)
                .then(response => {
                setCustomFields(response.data.custom_fields);
                setIsLoadingCustomFields(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoadingCustomFields(false);
                }
            });
            setAreFieldsAndGroupsLoaded(true);
        }
        return () => api.cancel();
    }, [showFilters]);
    useEffect(() => {
        let disabled = disabledCustomFields;
        customFields.map(field => {
            if ((field.field_type === "rating" ||
                field.field_type === "slider" ||
                field.field_type === "range") &&
                !disabledCustomFields.find(f => f.customField.id === field.id) &&
                !filterCustomFields.find(f => f.customField.id === field.id)) {
                let disabledField = {
                    customField: field,
                    disabled: true,
                };
                disabled = [...disabled, disabledField];
            }
        });
        setDisabledCustomFields(disabled);
    }, [customFields]);
    return (_jsxs(Fragment, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputSelect, { label: t("contacts.contact-attributes.type.type"), required: false, options: [
                                {
                                    label: t("contacts.contact-attributes.type.all"),
                                    value: "",
                                },
                                {
                                    label: t("contacts.contact-attributes.type.person"),
                                    value: "person",
                                },
                                {
                                    label: t("contacts.contact-attributes.type.business"),
                                    value: "business",
                                },
                            ], value: filterType ? filterType : "", setValue: setFilterType }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { options: phoneCountries, getOptionLabel: country => country.phone + " " + country.name + " (" + country.code + ")", isOptionEqualToValue: (option, value) => option.code === value.code, value: filterPhoneCountry, onChange: (event, value) => {
                                setFilterPhoneCountry(value);
                                setFilterPhoneArea(null);
                            }, loading: isLoading, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-countries.phone-country"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { disabled: !filterPhoneCountry, options: phoneAreas, getOptionLabel: area => area.phone + " - " + area.name + " [" + area.id + "]", getOptionDisabled: area => area.phone_country_id !== (filterPhoneCountry === null || filterPhoneCountry === void 0 ? void 0 : filterPhoneCountry.id), isOptionEqualToValue: (option, value) => option.id === value.id, value: filterPhoneArea, onChange: (event, value) => {
                                setFilterPhoneArea(value);
                            }, loading: isLoading2, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-areas.phone-area"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading2 ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, { item: true, xs: 12 }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Button, Object.assign({ color: "inherit", onClick: () => setShowMoreFilters(!showMoreFilters) }, { children: t("filters.field-filters") })) }))] })), _jsx(Collapse, Object.assign({ in: showMoreFilters, unmountOnExit: true }, { children: _jsx(Box, Object.assign({ sx: {
                        paddingTop: 2,
                    } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: customFields.length > 0 ? (customFields.map(customField => {
                            if ((customField.field_type === "text_select" ||
                                customField.field_type === "radio_text" ||
                                customField.field_type === "checkbox") &&
                                customField.text_items) {
                                return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, disabled: !filterCustomFields, options: customField.text_items.sort((a, b) => a.label.localeCompare(b.label)), getOptionLabel: item => item.label, isOptionEqualToValue: (option, value) => option.id === value.id, value: filterCustomFields.find(field => field.customFieldId ===
                                            customField.id.toString())
                                            ? filterCustomFields
                                                .find(field => field.customFieldId ===
                                                customField.id.toString())
                                                .chosenItems.map(item => item)
                                            : [], onChange: (event, value) => {
                                            let filteredFields = filterCustomFields.filter(field => field.customFieldId !==
                                                customField.id.toString());
                                            if (value.length > 0) {
                                                setFilterCustomFields([
                                                    ...filteredFields,
                                                    {
                                                        customFieldId: customField.id.toString(),
                                                        chosenItems: value,
                                                        customField: customField,
                                                    },
                                                ]);
                                            }
                                            else {
                                                setFilterCustomFields(filteredFields);
                                            }
                                        }, loading: isLoadingCustomFields, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: customField.field_name, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoadingCustomFields ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) }), customField.id));
                            }
                            if (customField.field_type === "switch") {
                                return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, disabled: !filterCustomFields, options: switchOptions, getOptionLabel: item => item === null || item === void 0 ? void 0 : item.label, isOptionEqualToValue: (option, value) => (option === null || option === void 0 ? void 0 : option.value) === (value === null || value === void 0 ? void 0 : value.value), value: filterCustomFields.find(field => field.customFieldId ===
                                            customField.id.toString())
                                            ? filterCustomFields
                                                .find(field => field.customFieldId ===
                                                customField.id.toString())
                                                .chosenItems.map(item => item)
                                            : [], onChange: (event, value) => {
                                            let filteredFields = filterCustomFields.filter(field => field.customFieldId !==
                                                customField.id.toString());
                                            if (value.length > 0) {
                                                setFilterCustomFields([
                                                    ...filteredFields,
                                                    {
                                                        customFieldId: customField.id.toString(),
                                                        chosenItems: value,
                                                        customField: customField,
                                                    },
                                                ]);
                                            }
                                            else {
                                                setFilterCustomFields(filteredFields);
                                            }
                                        }, loading: isLoadingCustomFields, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: customField.field_name, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoadingCustomFields ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) }), customField.id));
                            }
                            if (customField.field_type === "radio_image" &&
                                customField.image_text_items) {
                                return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, disabled: !filterCustomFields, options: customField.image_text_items.sort((a, b) => a.label.localeCompare(b.label)), getOptionLabel: item => item.label, isOptionEqualToValue: (option, value) => option.id === value.id, value: filterCustomFields.find(field => field.customFieldId ===
                                            customField.id.toString())
                                            ? filterCustomFields
                                                .find(field => field.customFieldId ===
                                                customField.id.toString())
                                                .chosenItems.map(item => item)
                                            : [], onChange: (event, value) => {
                                            let filteredFields = filterCustomFields.filter(field => field.customFieldId !==
                                                customField.id.toString());
                                            if (value.length > 0) {
                                                setFilterCustomFields([
                                                    ...filteredFields,
                                                    {
                                                        customFieldId: customField.id.toString(),
                                                        chosenItems: value,
                                                        customField: customField,
                                                    },
                                                ]);
                                            }
                                            else {
                                                setFilterCustomFields(filteredFields);
                                            }
                                        }, loading: isLoadingCustomFields, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: customField.field_name, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoadingCustomFields ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) }), customField.id));
                            }
                            if (customField.field_type === "radio_icon" &&
                                customField.text_items) {
                                return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, disabled: !filterCustomFields, options: customField.text_items.sort((a, b) => a.label.localeCompare(b.label)), getOptionLabel: item => item.label, isOptionEqualToValue: (option, value) => option.id === value.id, value: filterCustomFields.find(field => field.customFieldId ===
                                            customField.id.toString())
                                            ? filterCustomFields
                                                .find(field => field.customFieldId ===
                                                customField.id.toString())
                                                .chosenItems.map(item => item)
                                            : [], onChange: (event, value) => {
                                            let filteredFields = filterCustomFields.filter(field => field.customFieldId !==
                                                customField.id.toString());
                                            if (value.length > 0) {
                                                setFilterCustomFields([
                                                    ...filteredFields,
                                                    {
                                                        customFieldId: customField.id.toString(),
                                                        chosenItems: value,
                                                        customField: customField,
                                                    },
                                                ]);
                                            }
                                            else {
                                                setFilterCustomFields(filteredFields);
                                            }
                                        }, loading: isLoadingCustomFields, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: customField.field_name, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoadingCustomFields ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) }), customField.id));
                            }
                            if (((customField.field_type === "slider" ||
                                customField.field_type === "rating") &&
                                customField.max_value) ||
                                (customField.field_type === "range" &&
                                    customField.min_value &&
                                    customField.max_value)) {
                                return (_jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                paddingX: 1,
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "body1" }, { children: prettifyText(customField.field_name) })), _jsx(Tooltip
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    title: disabledCustomFields.find(field => field.customField.id ==
                                                        (customField === null || customField === void 0 ? void 0 : customField.id))
                                                        ? t("inputs.input-custom-fields.unlock-field")
                                                        : t("inputs.input-custom-fields.lock-field"), placement: "top", arrow: true }, { children: _jsx(Box, Object.assign({ component: "span" }, { children: _jsx(IconButton, Object.assign({ disabled: isLoadingTable, onClick: () => {
                                                                const filteredDisabledCustomFields = disabledCustomFields.filter(field => field.customField.id !==
                                                                    (customField === null || customField === void 0 ? void 0 : customField.id));
                                                                let field = disabledCustomFields.find(field => field.customField.id ===
                                                                    (customField === null || customField === void 0 ? void 0 : customField.id));
                                                                if (field) {
                                                                    setDisabledCustomFields([
                                                                        ...filteredDisabledCustomFields,
                                                                    ]);
                                                                    let filteredFields = filterCustomFields.filter(field => field.customFieldId !==
                                                                        customField.id.toString());
                                                                    setFilterCustomFields([
                                                                        ...filteredFields,
                                                                        {
                                                                            customFieldId: customField.id.toString(),
                                                                            chosenItems: [
                                                                                customField.min_value
                                                                                    ? customField.min_value
                                                                                    : 0,
                                                                                customField.max_value
                                                                                    ? customField.max_value
                                                                                    : 40,
                                                                            ],
                                                                            customField: customField,
                                                                        },
                                                                    ]);
                                                                }
                                                                else {
                                                                    let newFiltered = filterCustomFields.filter(a => a.customField.id !==
                                                                        customField.id);
                                                                    setFilterCustomFields([
                                                                        ...newFiltered,
                                                                    ]);
                                                                    setDisabledCustomFields([
                                                                        // @ts-ignore
                                                                        ...filteredDisabledCustomFields,
                                                                        {
                                                                            // @ts-ignore
                                                                            customField: customField,
                                                                            disabled: true,
                                                                        },
                                                                    ]);
                                                                }
                                                            }, style: {
                                                                padding: 0,
                                                            }, size: "large" }, { children: disabledCustomFields.find(field => field.customField.id ===
                                                                (customField === null || customField === void 0 ? void 0 : customField.id)) ? (_jsx(LockIcon, { fontSize: "small", style: {
                                                                    color: "rgb(244, 67, 54)",
                                                                } })) : (_jsx(LockOpenIcon, { fontSize: "small" })) })) })) }))] })), _jsx(Box, Object.assign({ sx: {
                                                paddingTop: 1,
                                                paddingX: 1,
                                            } }, { children: _jsx(InputSlider, { disabled: !!disabledCustomFields.find(field => field.customField.id ===
                                                    (customField === null || customField === void 0 ? void 0 : customField.id)), required: false, max: customField.max_value
                                                    ? customField.max_value
                                                    : 5, min: customField.min_value
                                                    ? customField.min_value
                                                    : 0, step: customField.step ? customField.step : 1, value: filterCustomFields.find(field => field.customFieldId ===
                                                    customField.id.toString())
                                                    ? filterCustomFields
                                                        .find(field => field.customFieldId ===
                                                        customField.id.toString())
                                                        .chosenItems.map(item => Number(item))
                                                    : [
                                                        customField.min_value
                                                            ? customField.min_value
                                                            : 0,
                                                        customField.max_value
                                                            ? customField.max_value
                                                            : 5,
                                                    ], setValue: value => {
                                                    let filteredFields = filterCustomFields.filter(field => field.customFieldId !==
                                                        customField.id.toString());
                                                    if (value.length > 0) {
                                                        setFilterCustomFields([
                                                            ...filteredFields,
                                                            {
                                                                customFieldId: customField.id.toString(),
                                                                chosenItems: value.map(val => Number(val)),
                                                                customField: customField,
                                                            },
                                                        ]);
                                                    }
                                                    else {
                                                        setFilterCustomFields(filteredFields);
                                                    }
                                                }, marks: ((customField === null || customField === void 0 ? void 0 : customField.min_label) &&
                                                    (customField === null || customField === void 0 ? void 0 : customField.min_label) !== "") ||
                                                    ((customField === null || customField === void 0 ? void 0 : customField.max_label) &&
                                                        (customField === null || customField === void 0 ? void 0 : customField.max_label) !== "")
                                                    ? [
                                                        {
                                                            value: (customField === null || customField === void 0 ? void 0 : customField.min_value)
                                                                ? customField === null || customField === void 0 ? void 0 : customField.min_value
                                                                : -1,
                                                            label: (customField === null || customField === void 0 ? void 0 : customField.min_label)
                                                                ? customField === null || customField === void 0 ? void 0 : customField.min_label
                                                                : "",
                                                        },
                                                        {
                                                            value: (customField === null || customField === void 0 ? void 0 : customField.max_value)
                                                                ? customField === null || customField === void 0 ? void 0 : customField.max_value
                                                                : 5,
                                                            label: (customField === null || customField === void 0 ? void 0 : customField.max_label)
                                                                ? customField.max_label
                                                                : "",
                                                        },
                                                    ]
                                                    : [] }) }))] }), customField.id));
                            }
                        })) : (_jsx(Box, Object.assign({ sx: { padding: "8px 24px 0" } }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-filters.no-fields") })) }))) })) })) }))] }));
}
