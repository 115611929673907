import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import InputPhone from "./input-phone";
export default function InputPhoneField({ label, phone, setPhone, info, disabled, error, errors, isError, setIsError, isCustomField, }) {
    const { t } = useTranslation();
    const [isAddPhoneOpened, setIsAddPhoneOpened] = useState(false);
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: label })) })), _jsxs(Box, { children: [phone && (_jsx(Box, Object.assign({ sx: {
                            display: "block",
                            width: "100%",
                            paddingY: 1,
                        } }, { children: _jsx(Chip, { variant: "outlined", label: (phone === null || phone === void 0 ? void 0 : phone.phone_area.phone_country.phone) !== "00"
                                ? (phone === null || phone === void 0 ? void 0 : phone.phone_area.phone_country.phone) +
                                    " " +
                                    (phone === null || phone === void 0 ? void 0 : phone.phone_area.phone) +
                                    " " +
                                    (phone === null || phone === void 0 ? void 0 : phone.number.substring(0, 4)) +
                                    " " +
                                    (phone === null || phone === void 0 ? void 0 : phone.number.substring(4, phone === null || phone === void 0 ? void 0 : phone.number.length))
                                : phone.number, onDelete: () => {
                                setPhone(null);
                            } }) }))), _jsx(Box, Object.assign({ sx: {
                            paddingTop: 1,
                        } }, { children: _jsx(Button, Object.assign({ disabled: disabled, type: "button", color: isCustomField && !phone ? "secondary" : "inherit", variant: isCustomField && !phone ? "contained" : "text", size: isCustomField ? "small" : "medium", disableElevation: true, onClick: () => {
                                setIsAddPhoneOpened(true);
                                if (setIsError) {
                                    setIsError(false);
                                }
                            } }, { children: phone
                                ? t("inputs.input-custom-fields.change-phone")
                                : t("inputs.input-custom-fields.add-phone") })) }))] }), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info })), _jsx(InputPhone, { type: "custom_field", phone: phone, setPhone: value => {
                    setPhone(value);
                }, isAddOpened: isAddPhoneOpened, setIsAddOpened: setIsAddPhoneOpened, isCustomField: isCustomField })] }));
}
