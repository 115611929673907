import axios from "axios";
import { usePrefs } from "../services/prefs";
export const useApiAuth = () => {
    const prefs = usePrefs();
    const api = axios.create({
        baseURL: process.env.API_URL,
    });
    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = error => axios.isCancel(error);
    const POST_logIn = (email, password) => {
        return api.post("/api/fortify/login", {
            email,
            password,
        }, {
            withCredentials: true,
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const POST_logOut = () => {
        return api.delete("/api/central/token", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
                Authorization: `Bearer ${prefs.centralToken}`,
            })),
        });
    };
    const GET_centralToken = (code = undefined, recovery_code = undefined) => api.post("/api/public/token", {
        code,
        recovery_code,
    }, {
        withCredentials: true,
        cancelToken: source.token,
        headers: {
            "Accept-Language": prefs.lang,
        },
    });
    // 2FA
    const GET_2faConfirmedPassword = () => api.get("/api/fortify/user/confirmed-password-status", {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    const POST_2faConfirmPassword = (email, password) => api.post("/api/fortify/user/confirm-password", {
        email,
        password,
    }, {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    const CREATE_enable2fa = () => api.post("/api/fortify/user/two-factor-authentication", null, {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    const DELETE_disable2fa = () => api.delete("/api/fortify/user/two-factor-authentication", {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    const GET_2faQrCode = () => api.get("/api/fortify/user/two-factor-qr-code", {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    const GET_2faRecoveryCodes = () => api.get("/api/fortify/user/two-factor-recovery-codes", {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    const POST_2faRecoveryCodes = () => api.post("/api/fortify/user/two-factor-recovery-codes", null, {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    const CREATE_confirm2fa = (code) => api.post("/api/fortify/user/confirmed-two-factor-authentication", {
        code,
    }, {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.centralToken && {
            Authorization: `Bearer ${prefs.centralToken}`,
        })),
    });
    return {
        cancel,
        isCancel,
        // Auth
        POST_logIn,
        POST_logOut,
        GET_centralToken,
        // 2FA
        GET_2faConfirmedPassword,
        POST_2faConfirmPassword,
        CREATE_enable2fa,
        DELETE_disable2fa,
        GET_2faQrCode,
        GET_2faRecoveryCodes,
        POST_2faRecoveryCodes,
        CREATE_confirm2fa,
    };
};
