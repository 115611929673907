import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Settings";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import ConditionDelete from "../conditions/condition-delete";
import ConditionForm from "../conditions/condition-form";
import Table from "../blocks/table";
import { useApiTenant } from "../../../api/api-tenant";
export default function ConditionsTable({ title, isAdded, setIsAdded }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [tableQuery, setTableQuery] = useState();
    const [isEditOpened, setIsEditOpened] = useState(false);
    const [conditionToEdit, setConditionToEdit] = useState(null);
    const [idToDelete, setIdToDelete] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (isAdded || isDeleted) {
            refreshTableData();
            if (isAdded)
                setIsAdded(false);
            if (isDeleted) {
                setIsDeleted(false);
                setIsEditOpened(false);
                setConditionToEdit(null);
            }
        }
    }, [isAdded, isDeleted]);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_ticketConditions(query.pageSize, query.page + 1, query.orderDirection ? query.orderDirection : "desc")
                .then(response => {
                resolve({
                    data: response.data.ticket_conditions,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    return (_jsxs(Fragment, { children: [_jsx(Table, { title: title, actions: [
                    {
                        icon: () => _jsx(EditIcon, { color: "action" }),
                        tooltip: t("conditions.conditions-table.edit-condition"),
                        onClick: (event, data) => {
                            setConditionToEdit(data);
                            setIsEditOpened(true);
                        },
                    },
                    {
                        icon: () => _jsx(RefreshIcon, { color: "action" }),
                        tooltip: t("conditions.conditions-table.refresh-conditions"),
                        isFreeAction: true,
                        onClick: () => refreshTableData(),
                    },
                ], columns: isMobile
                    ? [
                        {
                            title: t("conditions.condition") + " #",
                            field: "id",
                            defaultSort: "desc",
                        },
                    ]
                    : [
                        {
                            width: "30px",
                            title: "#",
                            field: "id",
                            type: "numeric",
                            defaultSort: "desc",
                            render: condition => {
                                return (_jsxs(Box, Object.assign({ sx: {
                                        display: "flex",
                                    } }, { children: [_jsx(Tooltip
                                        // @ts-ignore
                                        , Object.assign({ 
                                            // @ts-ignore
                                            title: condition.on
                                                ? t("conditions.conditions-table.active")
                                                : t("conditions.conditions-table.inactive") }, { children: _jsx(DotIcon, { style: { paddingTop: 4 }, fontSize: "small", color: condition.on ? "secondary" : "disabled" }) })), _jsx(Box, Object.assign({ sx: { paddingLeft: 1 } }, { children: condition.id }))] })));
                            },
                        },
                        {
                            title: t("conditions.conditions-table.campaign"),
                            sorting: false,
                            render: condition => {
                                return (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: condition.campaign.name, size: "small", variant: "filled" }) })));
                            },
                        },
                        {
                            title: t("conditions.conditions-table.old-statuses"),
                            sorting: false,
                            render: condition => condition.unchanged_ticket_detail_statuses
                                ? condition.unchanged_ticket_detail_statuses.map(status => {
                                    return (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { label: status.name, size: "small", style: {
                                                border: "1px solid " +
                                                    (status.type === "pending"
                                                        ? "rgba(156, 39, 176, 1)"
                                                        : status.type ===
                                                            "resolved"
                                                            ? "rgba(76, 175, 80, 1)"
                                                            : "rgba(37, 64, 146, 1)"),
                                                backgroundColor: status.type === "pending"
                                                    ? "rgba(156, 39, 176, 0.2)"
                                                    : status.type ===
                                                        "resolved"
                                                        ? "rgba(76, 175, 80, 0.2)"
                                                        : "rgba(37, 64, 146, 0.2)",
                                            } }, status.id) }), status.id));
                                })
                                : "",
                        },
                        {
                            title: t("conditions.conditions-table.old-priorities"),
                            sorting: false,
                            render: condition => condition.unchanged_ticket_detail_priorities
                                ? condition.unchanged_ticket_detail_priorities.map(priority => {
                                    return (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { label: priority.name, size: "small", style: {
                                                border: "1px solid " +
                                                    (priority.level < 33
                                                        ? "rgb(244, 67, 54, 1)"
                                                        : priority.level >=
                                                            33 &&
                                                            priority.level <= 66
                                                            ? "rgb(255, 152, 0, 1)"
                                                            : "rgb(33, 150, 243, 1)"),
                                                backgroundColor: priority.level < 33
                                                    ? "rgb(244, 67, 54, 0.2)"
                                                    : priority.level >=
                                                        33 &&
                                                        priority.level <= 66
                                                        ? "rgb(255, 152, 0, 0.2)"
                                                        : "rgb(33, 150, 243, 0.2)",
                                            } }, priority.id) }), priority.id));
                                })
                                : "",
                        },
                        {
                            title: t("conditions.conditions-table.unchanged-for"),
                            sorting: false,
                            render: condition => {
                                return (_jsx(Typography, { children: condition.unchanged_for_minutes === 60
                                        ? "1 " + t("conditions.condition-form.time.hour")
                                        : condition.unchanged_for_minutes === 120
                                            ? "2 " + t("conditions.condition-form.time.hours")
                                            : condition.unchanged_for_minutes === 180
                                                ? "3 " + t("conditions.condition-form.time.hours")
                                                : condition.unchanged_for_minutes === 240
                                                    ? "4 " + t("conditions.condition-form.time.hours")
                                                    : condition.unchanged_for_minutes === 300
                                                        ? "5 " +
                                                            t("conditions.condition-form.time.hours-1")
                                                        : condition.unchanged_for_minutes === 360
                                                            ? "6 " +
                                                                t("conditions.condition-form.time.hours-1")
                                                            : condition.unchanged_for_minutes === 420
                                                                ? "7 " +
                                                                    t("conditions.condition-form.time.hours-1")
                                                                : condition.unchanged_for_minutes === 480
                                                                    ? "8 " +
                                                                        t("conditions.condition-form.time.hours-1")
                                                                    : condition.unchanged_for_minutes === 540
                                                                        ? "9 " +
                                                                            t("conditions.condition-form.time.hours-1")
                                                                        : condition.unchanged_for_minutes === 600
                                                                            ? "10 " +
                                                                                t("conditions.condition-form.time.hours-1")
                                                                            : condition.unchanged_for_minutes === 660
                                                                                ? "11 " +
                                                                                    t("conditions.condition-form.time.hours-1")
                                                                                : condition.unchanged_for_minutes === 720
                                                                                    ? "12 " +
                                                                                        t("conditions.condition-form.time.hours-1")
                                                                                    : condition.unchanged_for_minutes === 1440
                                                                                        ? "1 " + t("conditions.condition-form.time.day")
                                                                                        : condition.unchanged_for_minutes === 2880
                                                                                            ? "2 " + t("conditions.condition-form.time.days")
                                                                                            : condition.unchanged_for_minutes === 4320
                                                                                                ? "3 " + t("conditions.condition-form.time.days")
                                                                                                : condition.unchanged_for_minutes === 5760
                                                                                                    ? "4 " + t("conditions.condition-form.time.days")
                                                                                                    : condition.unchanged_for_minutes === 7200
                                                                                                        ? "5 " + t("conditions.condition-form.time.days")
                                                                                                        : condition.unchanged_for_minutes === 8640
                                                                                                            ? "6 " + t("conditions.condition-form.time.days")
                                                                                                            : condition.unchanged_for_minutes === 10080
                                                                                                                ? "1 " + t("conditions.condition-form.time.week")
                                                                                                                : condition.unchanged_for_minutes === 20160
                                                                                                                    ? "2 " + t("conditions.condition-form.time.weeks")
                                                                                                                    : "3 " +
                                                                                                                        t("conditions.condition-form.time.weeks") }));
                            },
                        },
                        {
                            title: t("conditions.conditions-table.new-status"),
                            sorting: false,
                            render: condition => {
                                return condition.new_ticket_detail_status ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: condition.new_ticket_detail_status.name, size: "small", style: {
                                            border: "1px solid " +
                                                (condition.new_ticket_detail_status
                                                    .type === "pending"
                                                    ? "rgba(156, 39, 176, 1)"
                                                    : condition.new_ticket_detail_status
                                                        .type === "resolved"
                                                        ? "rgba(76, 175, 80, 1)"
                                                        : "rgba(37, 64, 146, 1)"),
                                            backgroundColor: condition.new_ticket_detail_status
                                                .type === "pending"
                                                ? "rgba(156, 39, 176, 0.2)"
                                                : condition.new_ticket_detail_status
                                                    .type === "resolved"
                                                    ? "rgba(76, 175, 80, 0.2)"
                                                    : "rgba(37, 64, 146, 0.2)",
                                        } }) }))) : ("");
                            },
                        },
                        {
                            title: t("conditions.conditions-table.new-priority"),
                            sorting: false,
                            render: condition => {
                                return condition.new_ticket_detail_priority ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: condition.new_ticket_detail_priority.name, size: "small", style: {
                                            border: "1px solid " +
                                                (condition.new_ticket_detail_priority
                                                    .level < 33
                                                    ? "rgb(244, 67, 54, 1)"
                                                    : condition.new_ticket_detail_priority
                                                        .level >= 33 &&
                                                        condition.new_ticket_detail_priority
                                                            .level <= 66
                                                        ? "rgb(255, 152, 0, 1)"
                                                        : "rgb(33, 150, 243, 1)"),
                                            backgroundColor: condition.new_ticket_detail_priority
                                                .level < 33
                                                ? "rgb(244, 67, 54, 0.2)"
                                                : condition.new_ticket_detail_priority
                                                    .level >= 33 &&
                                                    condition.new_ticket_detail_priority
                                                        .level <= 66
                                                    ? "rgb(255, 152, 0, 0.2)"
                                                    : "rgb(33, 150, 243, 0.2)",
                                        } }) }))) : ("");
                            },
                        },
                    ], detailPanel: (condition) => {
                    return (_jsx(Box, Object.assign({ sx: {
                            paddingX: isMobile ? 2 : 6,
                            paddingY: 3,
                        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [isMobile && (_jsxs(Fragment, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("conditions.conditions-table.campaign") })), _jsx(Box, Object.assign({ sx: {
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        marginRight: "8px",
                                                    } }, { children: _jsx(Chip, { label: condition.campaign.name, size: "small", variant: "filled" }) }))] })), condition.unchanged_ticket_detail_statuses && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("conditions.conditions-table.old-statuses") })), condition.unchanged_ticket_detail_statuses.map(status => {
                                                    return (_jsx(Box, Object.assign({ sx: {
                                                            paddingY: 0.5,
                                                        } }, { children: _jsx(Chip, { size: "small", label: status.name, style: {
                                                                border: "1px solid " +
                                                                    (status.type ===
                                                                        "pending"
                                                                        ? "rgba(156, 39, 176, 1)"
                                                                        : status.type ===
                                                                            "resolved"
                                                                            ? "rgba(76, 175, 80, 1)"
                                                                            : "rgba(37, 64, 146, 1)"),
                                                                backgroundColor: status.type ===
                                                                    "pending"
                                                                    ? "rgba(156, 39, 176, 0.2)"
                                                                    : status.type ===
                                                                        "resolved"
                                                                        ? "rgba(76, 175, 80, 0.2)"
                                                                        : "rgba(37, 64, 146, 0.2)",
                                                            } }) }), status.id));
                                                })] }))), condition.unchanged_ticket_detail_priorities && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("conditions.conditions-table.old-priorities") })), condition.unchanged_ticket_detail_priorities.map(priority => {
                                                    return (_jsx(Box, Object.assign({ sx: {
                                                            paddingY: 0.5,
                                                        } }, { children: _jsx(Chip, { size: "small", label: priority.name, style: {
                                                                border: "1px solid " +
                                                                    (priority.level < 33
                                                                        ? "rgb(244, 67, 54, 1)"
                                                                        : priority.level >=
                                                                            33 &&
                                                                            priority.level <=
                                                                                66
                                                                            ? "rgb(255, 152, 0, 1)"
                                                                            : "rgb(33, 150, 243, 1)"),
                                                                backgroundColor: priority.level < 33
                                                                    ? "rgb(244, 67, 54, 0.2)"
                                                                    : priority.level >=
                                                                        33 &&
                                                                        priority.level <=
                                                                            66
                                                                        ? "rgb(255, 152, 0, 0.2)"
                                                                        : "rgb(33, 150, 243, 0.2)",
                                                            } }) }), priority.id));
                                                })] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("conditions.conditions-table.unchanged-for") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: condition.unchanged_for_minutes === 60
                                                        ? "1 " +
                                                            t("conditions.condition-form.time.hour")
                                                        : condition.unchanged_for_minutes === 120
                                                            ? "2 " +
                                                                t("conditions.condition-form.time.hours")
                                                            : condition.unchanged_for_minutes === 180
                                                                ? "3 " +
                                                                    t("conditions.condition-form.time.hours")
                                                                : condition.unchanged_for_minutes === 240
                                                                    ? "4 " +
                                                                        t("conditions.condition-form.time.hours")
                                                                    : condition.unchanged_for_minutes === 300
                                                                        ? "5 " +
                                                                            t("conditions.condition-form.time.hours-1")
                                                                        : condition.unchanged_for_minutes === 360
                                                                            ? "6 " +
                                                                                t("conditions.condition-form.time.hours-1")
                                                                            : condition.unchanged_for_minutes === 420
                                                                                ? "7 " +
                                                                                    t("conditions.condition-form.time.hours-1")
                                                                                : condition.unchanged_for_minutes === 480
                                                                                    ? "8 " +
                                                                                        t("conditions.condition-form.time.hours-1")
                                                                                    : condition.unchanged_for_minutes === 540
                                                                                        ? "9 " +
                                                                                            t("conditions.condition-form.time.hours-1")
                                                                                        : condition.unchanged_for_minutes === 600
                                                                                            ? "10 " +
                                                                                                t("conditions.condition-form.time.hours-1")
                                                                                            : condition.unchanged_for_minutes === 660
                                                                                                ? "11 " +
                                                                                                    t("conditions.condition-form.time.hours-1")
                                                                                                : condition.unchanged_for_minutes === 720
                                                                                                    ? "12 " +
                                                                                                        t("conditions.condition-form.time.hours-1")
                                                                                                    : condition.unchanged_for_minutes === 1440
                                                                                                        ? "1 " + t("conditions.condition-form.time.day")
                                                                                                        : condition.unchanged_for_minutes === 2880
                                                                                                            ? "2 " +
                                                                                                                t("conditions.condition-form.time.days")
                                                                                                            : condition.unchanged_for_minutes === 4320
                                                                                                                ? "3 " +
                                                                                                                    t("conditions.condition-form.time.days")
                                                                                                                : condition.unchanged_for_minutes === 5760
                                                                                                                    ? "4 " +
                                                                                                                        t("conditions.condition-form.time.days")
                                                                                                                    : condition.unchanged_for_minutes === 7200
                                                                                                                        ? "5 " +
                                                                                                                            t("conditions.condition-form.time.days")
                                                                                                                        : condition.unchanged_for_minutes === 8640
                                                                                                                            ? "6 " +
                                                                                                                                t("conditions.condition-form.time.days")
                                                                                                                            : condition.unchanged_for_minutes === 10080
                                                                                                                                ? "1 " +
                                                                                                                                    t("conditions.condition-form.time.week")
                                                                                                                                : condition.unchanged_for_minutes === 20160
                                                                                                                                    ? "2 " +
                                                                                                                                        t("conditions.condition-form.time.weeks")
                                                                                                                                    : "3 " +
                                                                                                                                        t("conditions.condition-form.time.weeks") }))] })), condition.new_ticket_detail_status && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("conditions.conditions-table.new-status") })), _jsx(Box, Object.assign({ sx: {
                                                        paddingY: 0.5,
                                                    } }, { children: _jsx(Chip, { size: "small", label: condition.new_ticket_detail_status.name, style: {
                                                            border: "1px solid " +
                                                                (condition.new_ticket_detail_status
                                                                    .type === "pending"
                                                                    ? "rgba(156, 39, 176, 1)"
                                                                    : condition
                                                                        .new_ticket_detail_status
                                                                        .type === "resolved"
                                                                        ? "rgba(76, 175, 80, 1)"
                                                                        : "rgba(37, 64, 146, 1)"),
                                                            backgroundColor: condition.new_ticket_detail_status
                                                                .type === "pending"
                                                                ? "rgba(156, 39, 176, 0.2)"
                                                                : condition
                                                                    .new_ticket_detail_status
                                                                    .type === "resolved"
                                                                    ? "rgba(76, 175, 80, 0.2)"
                                                                    : "rgba(37, 64, 146, 0.2)",
                                                        } }) }), condition.new_ticket_detail_status.id)] }))), condition.new_ticket_detail_priority && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("conditions.conditions-table.new-priority") })), _jsx(Box, Object.assign({ sx: {
                                                        paddingY: 0.5,
                                                    } }, { children: _jsx(Chip, { size: "small", label: condition.new_ticket_detail_priority
                                                            .name, style: {
                                                            border: "1px solid " +
                                                                (condition
                                                                    .new_ticket_detail_priority
                                                                    .level < 33
                                                                    ? "rgb(244, 67, 54, 1)"
                                                                    : condition
                                                                        .new_ticket_detail_priority
                                                                        .level >= 33 &&
                                                                        condition
                                                                            .new_ticket_detail_priority
                                                                            .level <= 66
                                                                        ? "rgb(255, 152, 0, 1)"
                                                                        : "rgb(33, 150, 243, 1)"),
                                                            backgroundColor: condition.new_ticket_detail_priority
                                                                .level < 33
                                                                ? "rgb(244, 67, 54, 0.2)"
                                                                : condition
                                                                    .new_ticket_detail_priority
                                                                    .level >= 33 &&
                                                                    condition
                                                                        .new_ticket_detail_priority
                                                                        .level <= 66
                                                                    ? "rgb(255, 152, 0, 0.2)"
                                                                    : "rgb(33, 150, 243, 0.2)",
                                                        } }) }), condition.new_ticket_detail_priority.id)] })))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(condition.created_at)).toLocaleDateString("en-GB") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(condition.updated_at)).toLocaleDateString("en-GB") }))] }))] })) })));
                }, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery, disableSearch: true }), conditionToEdit && (_jsx(ConditionForm, { status: "edit", condition: conditionToEdit ? conditionToEdit : undefined, setCondition: setConditionToEdit, isOpened: isEditOpened, setIsOpened: setIsEditOpened, setIsAdded: setIsAdded, setIdToDelete: setIdToDelete })), idToDelete !== "" && (_jsx(ConditionDelete, { idToDelete: idToDelete, setIdToDelete: setIdToDelete, setIsDeleted: setIsDeleted }))] }));
}
