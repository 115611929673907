import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CheckPhone from "@mui/icons-material/AddTask";
import { useApiTenant } from "../../../api/api-tenant";
import { useApiPublic } from "../../../api/api-public";
import { useTenancy } from "../../../services/tenancy";
import { useAuth } from "../../../services/auth";
export default function InputPhone({ type, phone, setPhone, isAddOpened, setIsAddOpened, isSaving, isCustomField, }) {
    const auth = useAuth();
    const api = auth.isPublic ? useApiPublic() : useApiTenant();
    const tenancy = useTenancy();
    const { t } = useTranslation();
    const [phoneCountry, setPhoneCountry] = useState(null);
    const [phoneCountries, setPhoneCountries] = useState([]);
    const [phoneArea, setPhoneArea] = useState(null);
    const [phoneAreas, setPhoneAreas] = useState([]);
    const [number, setNumber] = useState("");
    const [phoneAsText, setPhoneAsText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [numberError, setNumberError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    useEffect(() => {
        if (!isAddOpened) {
            setPhoneAsText("");
            return;
        }
        if (phone) {
            setPhoneCountry(phone.phone_area.phone_country);
            setPhoneArea(phone.phone_area);
            setNumber(phone.number);
        }
        else {
            setPhoneCountry(null);
            setPhoneArea(null);
            setNumber("");
        }
        if (phoneCountries.length > 0)
            return;
        api.GET_phoneCountries(undefined, undefined, undefined, undefined, undefined, auth.isPublic ? (process.env.APP_ENV === "local" ? "1" : tenancy.client.id) : undefined)
            .then(response => {
            setPhoneCountries(response.data.phone_countries);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        api.GET_phoneAreas(10000, 1, "phone", "desc", undefined, auth.isPublic ? (process.env.APP_ENV === "local" ? "1" : tenancy.client.id) : undefined)
            .then(response => {
            setPhoneAreas(response.data.phone_areas);
            setIsLoading2(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        return () => api.cancel();
    }, [isAddOpened]);
    useEffect(() => {
        if (phoneAsText === "") {
            setPhoneCountry(null);
            setPhoneArea(null);
            setNumber("");
            setPhoneError(false);
        }
    }, [phoneAsText]);
    const validatePhone = (phone) => {
        if (!phone)
            return;
        if (/[a-zA-Z]/g.test(phone)) {
            setPhoneError(true);
            setPhoneCountry(null);
            setPhoneArea(null);
            setNumber("");
            setNumberError(false);
            return;
        }
        phone = phone.replace(/\D+/g, "");
        // Find phone country (3 -> 2 -> 1)
        let foundCountry;
        let foundCountryDigits;
        let countryCode = phone.slice(0, 3);
        foundCountry = phoneCountries.find(country => country.phone == countryCode);
        if (foundCountry) {
            setPhoneCountry(foundCountry);
            foundCountryDigits = 3;
            setPhoneError(false);
        }
        else {
            countryCode = phone.slice(0, 2);
            foundCountry = phoneCountries.find(country => country.phone == countryCode);
            if (foundCountry) {
                setPhoneCountry(foundCountry);
                foundCountryDigits = 2;
                setPhoneError(false);
            }
            else {
                countryCode = phone.slice(0, 1);
                foundCountry = phoneCountries.find(country => country.phone == countryCode);
                if (foundCountry) {
                    setPhoneCountry(foundCountry);
                    foundCountryDigits = 1;
                    setPhoneError(false);
                }
                else {
                    setPhoneError(true);
                    return;
                }
            }
        }
        // Find phone area (3 -> 2 -> 1)
        let foundArea;
        let foundAreaDigits;
        let areaCode = phone.slice(foundCountryDigits, foundCountryDigits + 3);
        foundArea = phoneAreas.find(area => area.phone == areaCode && area.phone_country.code == foundCountry.code);
        if (foundArea) {
            setPhoneArea(foundArea);
            foundAreaDigits = 3;
        }
        else {
            areaCode = phone.slice(foundCountryDigits, foundCountryDigits + 2);
            foundArea = phoneAreas.find(area => area.phone == areaCode && area.phone_country.code == foundCountry.code);
            if (foundArea) {
                setPhoneArea(foundArea);
                foundAreaDigits = 2;
                setPhoneError(false);
            }
            else {
                areaCode = phone.slice(foundCountryDigits, foundCountryDigits + 1);
                foundArea = phoneAreas.find(area => area.phone == areaCode && area.phone_country.code == foundCountry.code);
                if (foundArea) {
                    setPhoneArea(foundArea);
                    foundAreaDigits = 1;
                    setPhoneError(false);
                }
                else {
                    setPhoneError(true);
                    setPhoneCountry(null);
                    return;
                }
            }
        }
        // Rest of the number
        let number = phone.slice(foundCountryDigits + foundAreaDigits);
        if (number !== "") {
            setNumber(number);
            setPhoneError(false);
            validatePhoneNumber(foundArea, number);
        }
        else {
            setNumber("");
            setPhoneCountry(null);
            setPhoneArea(null);
            setPhoneError(true);
        }
    };
    const validatePhoneNumber = (phoneArea, phoneNumber) => {
        if (!phoneArea || !phoneNumber || !phoneArea.validation_rule)
            return;
        if (!(phoneNumber.match(/^[0-9]+$/) !== null)) {
            setNumberError(true);
        }
        else {
            setNumberError(false);
        }
        const regex = new RegExp(phoneArea.validation_rule);
        if (regex.test(phoneNumber))
            setNumberError(false);
        else
            setNumberError(true);
    };
    return (_jsx(Fragment, { children: _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isAddOpened, onClose: () => {
                setIsAddOpened(false);
                setPhoneAsText("");
            } }, { children: [_jsx(DialogTitle, { children: type === "blacklist"
                        ? t("blacklist.add-blacklisted-phone")
                        : type === "audience"
                            ? t("inputs.input-phone.add-audience-lead")
                            : t("inputs.input-phone.add-phone") }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [!isCustomField && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ error: phoneError, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("inputs.input-phones.phone") }), _jsx(OutlinedInput, { label: t("inputs.input-phones.phone"), value: phoneAsText, autoComplete: "new-password", endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                                validatePhone(phoneAsText);
                                                            }, size: "large" }, { children: _jsx(CheckPhone, {}) })) })), onChange: event => {
                                                        setPhoneAsText(event.target.value);
                                                    }, onKeyPress: event => {
                                                        if (event.key === "Enter" && phoneAsText)
                                                            validatePhone(phoneAsText);
                                                    } }), phoneError && (_jsx(FormHelperText, Object.assign({ error: phoneError }, { children: t("inputs.input-phones.phone-number-invalid") })))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) }))] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { options: phoneCountries, getOptionLabel: country => country.phone + " " + country.name + " (" + country.code + ")", isOptionEqualToValue: (option, value) => option.code === value.code, value: phoneCountry, onChange: (event, value) => {
                                        setPhoneCountry(value);
                                        setPhoneArea(null);
                                        setNumber("");
                                        setNumberError(false);
                                    }, autoHighlight: true, loading: isLoading, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("inputs.input-phone.country"), fullWidth: true, required: true, autoFocus: phoneCountries.length > 1, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { disabled: !phoneCountry, options: phoneAreas, getOptionLabel: area => area.phone + " - " + area.name, renderOption: (props, area) => area.phone_country_id === (phoneCountry === null || phoneCountry === void 0 ? void 0 : phoneCountry.id) ? (_jsx("li", Object.assign({}, props, { children: _jsx(Typography, Object.assign({ noWrap: true }, { children: area.phone + " - " + area.name })) }))) : null, isOptionEqualToValue: (option, value) => option.id === value.id, value: phoneArea, onChange: (event, value) => {
                                        setNumber("");
                                        setPhoneArea(value);
                                        validatePhoneNumber(value, number);
                                    }, autoHighlight: true, loading: isLoading2, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("inputs.input-phone.area"), fullWidth: true, required: true, autoFocus: !!phoneCountry, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading2 ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ disabled: !phoneArea, error: numberError, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("inputs.input-phone.number") + " *" }), _jsx(OutlinedInput, { required: true, label: t("inputs.input-phone.number") + " *", value: number, autoComplete: "new-password", startAdornment: phoneCountry ? (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: phoneCountry.phone !== "00"
                                                    ? phoneCountry.phone +
                                                        (phoneArea ? " " + phoneArea.phone : "")
                                                    : "" }))) : undefined, onChange: event => {
                                                if (event.target.value === "")
                                                    setNumberError(false);
                                                validatePhoneNumber(phoneArea, event.target.value);
                                                setNumber(event.target.value);
                                            } }), _jsx(FormHelperText, Object.assign({ error: numberError }, { children: (phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.validation_message)
                                                ? phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.validation_message
                                                : " " }))] })) }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                setIsAddOpened(false);
                                setPhoneAsText("");
                            } }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", size: "small", loading: isSaving, disabled: !phoneCountry || !phoneArea || !number || numberError, onClick: () => {
                                setPhone({
                                    phone_area_id: phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id,
                                    phone_area: phoneArea,
                                    number: number,
                                });
                                if (type === "custom_field") {
                                    setIsAddOpened(false);
                                }
                            }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: type === "blacklist" || type === "custom_field"
                                ? t("inputs.input-phone.add-phone")
                                : t("inputs.input-phone.add-lead") }))] })] })) }));
}
