import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { withStyles, createStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import PlusIcon from "@mui/icons-material/AddBoxOutlined";
import MinusIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Settings";
import UsersIcon from "@mui/icons-material/Group";
import GroupForm from "./group-form";
import GroupUsers from "./group-users";
import { useRoles } from "../../../services/roles";
import { useApiCentral } from "../../../api/api-central";
import { useApiTenant } from "../../../api/api-tenant";
const StyledTreeItem = withStyles((theme) => createStyles({
    iconContainer: {
        "& .close": {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 15,
        paddingLeft: 8,
        borderLeft: `1px dashed ${theme.palette.text.disabled}`,
    },
    label: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: "60px",
    },
}))((props) => _jsx(TreeItem, Object.assign({}, props)));
export default function GroupsTree({ type }) {
    const { t } = useTranslation();
    const roles = useRoles();
    const api = type === "client" ? useApiTenant() : useApiCentral();
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupNode, setSelectedGroupNode] = useState("");
    const [groupStatus, setGroupStatus] = useState(null);
    const [refreshGroups, setRefreshGroups] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchGroups();
        setGroupStatus(null);
        setRefreshGroups(false);
        return () => {
            api.cancel();
        };
    }, [refreshGroups]);
    const fetchGroups = () => {
        api.GET_groups()
            .then(response => {
            setGroups(response.data.groups);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    const treeItems = (groups) => {
        return groups.map((group, index) => {
            let children = null;
            if (group.groups && group.groups.length > 0) {
                children = treeItems(group.groups);
            }
            return (_jsxs(Box, Object.assign({ sx: {
                    position: "relative",
                    width: "100%",
                    paddingTop: 1,
                } }, { children: [_jsx(StyledTreeItem, { nodeId: group.id.toString(), label: group.name, children: children }, index), _jsxs(Box, Object.assign({ style: {
                            opacity: selectedGroupNode == group.id ? 1 : 0,
                            visibility: selectedGroupNode == group.id ? "initial" : "hidden",
                            position: "absolute",
                            top: 0,
                            right: "4px",
                            transition: "all .3s",
                        } }, { children: [roles.isAdmin && (_jsxs(Fragment, { children: [_jsx(Tooltip
                                    // @ts-ignore
                                    , Object.assign({ 
                                        // @ts-ignore
                                        title: t("groups.groups-tree.new-child-group"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                setSelectedGroup(group);
                                                setGroupStatus("new");
                                            }, size: "large" }, { children: _jsx(AddIcon, {}) })) })), _jsx(Tooltip
                                    // @ts-ignore
                                    , Object.assign({ 
                                        // @ts-ignore
                                        title: t("groups.edit-group"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                setSelectedGroup(group);
                                                setGroupStatus("edit");
                                            }, size: "large" }, { children: _jsx(EditIcon, {}) })) }))] })), _jsx(Tooltip
                            // @ts-ignore
                            , Object.assign({ 
                                // @ts-ignore
                                title: t("groups.group-users.group-users"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                        setSelectedGroup(group);
                                        setGroupStatus("users");
                                    }, size: "large" }, { children: _jsx(UsersIcon, {}) })) }))] }))] }), index));
        });
    };
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 5 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                            position: "relative",
                            padding: 4,
                        } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                            } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsxs(Fragment, { children: [groups.length > 0 && (_jsx(TreeView, Object.assign({ selected: selectedGroupNode, onNodeSelect: (event, value) => {
                                        setSelectedGroupNode(value);
                                        setGroupStatus(null);
                                    }, defaultExpandIcon: _jsx(PlusIcon, {}), defaultCollapseIcon: _jsx(MinusIcon, {}), defaultEndIcon: _jsx(PlusIcon, { color: "disabled" }) }, { children: treeItems(groups) }))), roles.isAdmin && (_jsx(Box, Object.assign({ sx: {
                                        paddingTop: groups.length > 0 ? 2 : 0,
                                        paddingLeft: 0.5,
                                    } }, { children: _jsx(Button, Object.assign({ size: "small", color: "inherit", startIcon: _jsx(AddIcon, {}), onClick: () => {
                                            setSelectedGroup(null);
                                            setSelectedGroupNode("");
                                            setGroupStatus("new");
                                        } }, { children: t("groups.groups-tree.new-root-group") })) })))] })) })) }) })), type && (groupStatus === "new" || groupStatus === "edit") && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 7 }, { children: _jsx(GroupForm, { type: type, status: groupStatus, group: selectedGroup, setRefreshGroups: setRefreshGroups }) }))), type && groupStatus === "users" && selectedGroup && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 7 }, { children: _jsx(GroupUsers, { type: type, group: selectedGroup }) })))] })));
}
