import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, forwardRef, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import AddBox from "@mui/icons-material/AddBox";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { usePrefs } from "../../../services/prefs";
export default function Table({ title, actions, columns, data, detailPanel, isLoading, tableRef, tableQuery, model, simplePagination, total, searchLabel, disableSearch, }) {
    const { t } = useTranslation();
    const isDesktop = useMediaQuery("(min-width: 1200px)");
    const prefs = usePrefs();
    const icons = {
        Add: forwardRef((props, ref) => _jsx(AddBox, Object.assign({}, props, { ref: ref }))),
        Check: forwardRef((props, ref) => _jsx(Check, Object.assign({}, props, { ref: ref }))),
        Clear: forwardRef((props, ref) => _jsx(Clear, Object.assign({}, props, { ref: ref }))),
        Delete: forwardRef((props, ref) => (_jsx(DeleteOutline, Object.assign({}, props, { ref: ref })))),
        DetailPanel: forwardRef((props, ref) => (_jsx(ChevronRight, Object.assign({}, props, { ref: ref })))),
        Edit: forwardRef((props, ref) => _jsx(Edit, Object.assign({}, props, { ref: ref }))),
        Export: forwardRef((props, ref) => _jsx(SaveAlt, Object.assign({}, props, { ref: ref }))),
        Filter: forwardRef((props, ref) => _jsx(FilterList, Object.assign({}, props, { ref: ref }))),
        FirstPage: forwardRef((props, ref) => (_jsx(FirstPage, Object.assign({}, props, { ref: ref })))),
        LastPage: forwardRef((props, ref) => _jsx(LastPage, Object.assign({}, props, { ref: ref }))),
        NextPage: forwardRef((props, ref) => (_jsx(ChevronRight, Object.assign({}, props, { ref: ref })))),
        PreviousPage: forwardRef((props, ref) => (_jsx(ChevronLeft, Object.assign({}, props, { ref: ref })))),
        ResetSearch: forwardRef((props, ref) => _jsx(Clear, Object.assign({}, props, { ref: ref }))),
        Search: forwardRef((props, ref) => _jsx(Search, Object.assign({}, props, { ref: ref }))),
        SortArrow: forwardRef((props, ref) => (_jsx(ArrowUpward, Object.assign({}, props, { ref: ref })))),
        ThirdStateCheck: forwardRef((props, ref) => (_jsx(Remove, Object.assign({}, props, { ref: ref })))),
        ViewColumn: forwardRef((props, ref) => (_jsx(ViewColumn, Object.assign({}, props, { ref: ref })))),
    };
    function defaultGetAriaLabel(type) {
        return type === "previous"
            ? t("table.previous-page")
            : type === "next"
                ? t("table.next-page")
                : t("table.first-page");
    }
    useEffect(() => {
        if (!tableQuery)
            return;
        prefs.setPageSize(tableQuery.pageSize.toString());
    }, [isLoading]);
    return useMemo(() => {
        return (_jsx(MaterialTable, { title: title, actions: actions, columns: columns, detailPanel: detailPanel, data: data, isLoading: isLoading, tableRef: tableRef, icons: icons, localization: {
                pagination: {
                    labelRowsSelect: t("table.rows"),
                    previousTooltip: t("table.previous-page"),
                    nextTooltip: t("table.next-page"),
                    lastTooltip: t("table.last-page"),
                    firstTooltip: t("table.first-page"),
                    labelRowsPerPage: "",
                    labelDisplayedRows: "{from}-{to}" + t("table.of") + "{count}",
                },
                toolbar: {
                    searchPlaceholder: searchLabel ? searchLabel : t("table.search"),
                },
                header: {
                    actions: t("table.actions"),
                },
                body: {
                    emptyDataSourceMessage: t("table.no-records"),
                },
            }, components: {
                Container: props => (_jsx(Paper, Object.assign({ elevation: model ? 0 : 1, variant: model ? "outlined" : "elevation" }, { children: _jsx(Box, Object.assign({ sx: {
                            paddingX: 3,
                            paddingY: 2,
                            minHeight: model ? "auto" : "440px",
                        } }, { children: _jsx("div", Object.assign({}, props)) })) }))),
                Pagination: props => simplePagination ? (_jsx(Box, Object.assign({ component: "td", sx: {
                        display: "flex",
                        justifyContent: !isDesktop ? "space-between" : "flex-end",
                        flexDirection: !isDesktop ? "row-reverse" : "row",
                    } }, { children: _jsx(TablePagination, { component: "div", count: props.count, page: props.page, onPageChange: props.onChangePage, rowsPerPage: props.rowsPerPage, rowsPerPageOptions: props.rowsPerPageOptions, onRowsPerPageChange: props.onChangeRowsPerPage, showFirstButton: true, labelRowsPerPage: "", getItemAriaLabel: defaultGetAriaLabel, SelectProps: {
                            inputProps: { "aria-label": t("table.rows") },
                            native: true,
                        }, labelDisplayedRows: () => {
                            return (_jsxs(Fragment, { children: [props.rowsPerPage * props.page +
                                        1 +
                                        "-" +
                                        (props.count === 100000000
                                            ? props.rowsPerPage * props.page +
                                                props.rowsPerPage
                                            : props.count) +
                                        t("table.of"), total ? (_jsx("strong", { children: total })) : props.count === 100000000 ? (t("table.many")) : (_jsx("strong", { children: props.count }))] }));
                        } }) }))) : (_jsx(Box, Object.assign({ component: "td", sx: {
                        display: "flex",
                        justifyContent: !isDesktop ? "space-between" : "flex-end",
                        flexDirection: !isDesktop ? "row-reverse" : "row",
                    } }, { children: _jsx(TablePagination, { component: "div", count: props.count, page: props.page, rowsPerPage: props.rowsPerPage, rowsPerPageOptions: props.rowsPerPageOptions, onPageChange: props.onChangePage, onRowsPerPageChange: props.onChangeRowsPerPage }) }))),
            }, options: {
                draggable: false,
                actionsColumnIndex: -1,
                pageSize: parseInt(tableQuery ? tableQuery.pageSize : model ? 5 : prefs.pageSize),
                pageSizeOptions: [5, 15, 25, 50],
                emptyRowsWhenPaging: false,
                debounceInterval: 700,
                search: !disableSearch,
            } }));
    }, [isLoading]);
}
