import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { hr, enUS } from "date-fns/locale";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import ActivityIcon from "@mui/icons-material/History";
import { isJsonString, prettifyText } from "../../../helpers/clean";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import InformationHistoryDelete from "./information-history-delete";
const useStyles = makeStyles(() => ({
    timeline: {
        margin: 0,
        padding: 0,
        "& .MuiTimelineContent-root": {
            padding: "6px 0 6px 16px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            content: "none",
        },
    },
    chip: {
        height: "auto",
        paddingY: 2,
        "& .MuiChip-label": {
            paddingTop: 2,
            paddingBottom: 2,
            wordWrap: "break-word",
            whiteSpace: "normal",
            textOverflow: "clip",
            maxWidth: "100%",
        },
    },
}));
export default function InformationHistory({ status, ticket, historyToDelete, setHistoryToDelete, modal, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const tenancy = useTenancy();
    const roles = useRoles();
    const prefs = usePrefs();
    const locales = { enUS, hr };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginTop: 4,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ActivityIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.ticket-information.ticket-information-history.history") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("tickets.ticket-information.ticket-information-history.information-history") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: _jsx(Timeline, Object.assign({ className: classes.timeline }, { children: ticket.information_history.map((historyRecord, index) => {
                                    var _a, _b, _c, _d;
                                    return (_jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, {}), index !== ticket.information_history.length - 1 && (_jsx(TimelineConnector, {}))] }), _jsxs(TimelineContent, { children: [_jsxs(Box, Object.assign({ sx: {
                                                            marginBottom: 2,
                                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                                                        display: "inline-block",
                                                                    } }, { children: historyRecord.created_by && (_jsx(Fragment, { children: roles.isAgent ? (_jsx(Typography, { children: `${(_a = historyRecord.created_by
                                                                                .first_name) !== null && _a !== void 0 ? _a : ""} ${(_b = historyRecord.created_by
                                                                                .last_name) !== null && _b !== void 0 ? _b : ""}` })) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" +
                                                                                historyRecord.created_by
                                                                                    .id), onClick: event => {
                                                                                if (!historyRecord.created_by) {
                                                                                    return;
                                                                                }
                                                                                event.preventDefault();
                                                                                navigate(tenancy.link("/settings/users/" +
                                                                                    historyRecord
                                                                                        .created_by
                                                                                        .id));
                                                                            }, underline: "hover" }, { children: _jsx(Typography, { children: `${(_c = historyRecord.created_by
                                                                                    .first_name) !== null && _c !== void 0 ? _c : ""} ${(_d = historyRecord.created_by
                                                                                    .last_name) !== null && _d !== void 0 ? _d : ""}` }) }))) })) })) })), _jsxs(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: prefs.lang === "hr"
                                                                            ? t("tickets.ticket-information.ticket-information-history.ago") +
                                                                                " " +
                                                                                formatDistanceToNow(new Date(historyRecord.created_at), {
                                                                                    locale: locales["hr"],
                                                                                })
                                                                            : prettifyText(formatDistanceToNow(new Date(historyRecord.created_at), {
                                                                                locale: locales["enUs"],
                                                                            })) +
                                                                                " " +
                                                                                t("tickets.ticket-information.ticket-information-history.ago") })), _jsx(Typography, Object.assign({ variant: "overline", component: "p", color: "textSecondary" }, { children: new Date(Date.parse(historyRecord.created_at)).toLocaleDateString("en-GB") +
                                                                            " " +
                                                                            new Date(Date.parse(historyRecord.created_at)).toLocaleTimeString("hr-HR", {
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                            }) }))] }))] })), _jsx(Box, Object.assign({ sx: {
                                                            marginBottom: 2,
                                                        } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                                                    paddingX: 3,
                                                                    paddingY: 2,
                                                                } }, { children: historyRecord.data &&
                                                                    historyRecord.data.map((recordData, index) => {
                                                                        return (_jsx(Fragment, { children: recordData.changes.map((change, index) => {
                                                                                var _a, _b, _c, _d, _e, _f, _g, _h;
                                                                                return (_jsx(Fragment, { children: change.custom_field_type !==
                                                                                        "text_area" &&
                                                                                        change.custom_field_type !==
                                                                                            "html_text" &&
                                                                                        change.custom_field_type !==
                                                                                            "conversation" ? (_jsxs(Box, Object.assign({ sx: {
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            flexWrap: "wrap",
                                                                                            marginY: 1,
                                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                            marginX: 0.5,
                                                                                                        } }, { children: change.name ===
                                                                                                            "queues"
                                                                                                            ? t("tickets.ticket-information.ticket-information-history.queues")
                                                                                                            : change.name }))] })), change.previous &&
                                                                                                change.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: change.name ===
                                                                                                            "queues"
                                                                                                            ? t("tickets.ticket-information.ticket-information-history.changed-from1")
                                                                                                            : t("tickets.ticket-information.ticket-information-history.changed-from") })), _jsx(Box, Object.assign({ sx: {
                                                                                                            marginX: 1,
                                                                                                        } }, { children: isJsonString(change.previous) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_b = (_a = JSON.parse(change.previous)) === null || _a === void 0 ? void 0 : _a.media) === null || _b === void 0 ? void 0 : _b.map((media, index) => {
                                                                                                                var _a;
                                                                                                                return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                        .split("/")
                                                                                                                        .pop(), size: "small", variant: "filled" }, ((_a = change.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                    index));
                                                                                                            }) }))) : change.previous.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: change.previous
                                                                                                                .split("/")
                                                                                                                .pop(), size: "small", variant: "filled" })) : (_jsx(Chip, { className: classes.chip, label: change.previous, size: "small", variant: "filled" })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-information.ticket-information-history.to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                            marginX: 1,
                                                                                                        } }, { children: isJsonString(change.updated) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_d = (_c = JSON.parse(change.updated)) === null || _c === void 0 ? void 0 : _c.media) === null || _d === void 0 ? void 0 : _d.map((media, index) => {
                                                                                                                var _a;
                                                                                                                return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                        .split("/")
                                                                                                                        .pop(), size: "small", variant: "filled" }, ((_a = change.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                    index));
                                                                                                            }) }))) : change.updated.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: change.updated
                                                                                                                .split("/")
                                                                                                                .pop(), size: "small", variant: "filled" })) : (_jsx(Chip, { className: classes.chip, label: change.updated, size: "small", variant: "filled" })) }))] })) : !change.previous &&
                                                                                                change.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: change.name ===
                                                                                                            "queues"
                                                                                                            ? t("tickets.ticket-information.ticket-information-history.set-to1")
                                                                                                            : t("tickets.ticket-information.ticket-information-history.set-to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                            marginX: 1,
                                                                                                        } }, { children: isJsonString(change.updated) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_f = (_e = JSON.parse(change.updated)) === null || _e === void 0 ? void 0 : _e.media) === null || _f === void 0 ? void 0 : _f.map((media, index) => {
                                                                                                                var _a;
                                                                                                                return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                        .split("/")
                                                                                                                        .pop(), size: "small", variant: "filled" }, ((_a = change.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                    index));
                                                                                                            }) }))) : change.updated.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: change.updated
                                                                                                                .split("/")
                                                                                                                .pop(), size: "small", variant: "filled" })) : (_jsx(Chip, { className: classes.chip, label: change.updated, size: "small", variant: "filled" })) }))] })) : (change.previous &&
                                                                                                !change.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                                                                            marginX: 1,
                                                                                                        } }, { children: isJsonString(change.previous) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_h = (_g = JSON.parse(change.previous)) === null || _g === void 0 ? void 0 : _g.media) === null || _h === void 0 ? void 0 : _h.map((media, index) => {
                                                                                                                var _a;
                                                                                                                return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                        .split("/")
                                                                                                                        .pop(), size: "small", variant: "filled" }, ((_a = change.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                    index));
                                                                                                            }) }))) : change.previous.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: change.previous
                                                                                                                .split("/")
                                                                                                                .pop(), size: "small", variant: "filled" })) : (_jsx(Chip, { className: classes.chip, label: change.previous, size: "small", variant: "filled" })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: change.name ===
                                                                                                            "queues"
                                                                                                            ? t("tickets.ticket-information.ticket-information-history.removed1")
                                                                                                            : t("tickets.ticket-information.ticket-information-history.removed") }))] })))] }), index)) : (_jsx(Box, Object.assign({ sx: {
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            flexWrap: "wrap",
                                                                                            marginY: 1,
                                                                                        } }, { children: change.previous &&
                                                                                            change.updated ? (_jsxs(Box, Object.assign({ sx: {
                                                                                                flexDirection: "column",
                                                                                                width: "100%",
                                                                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                                marginX: 0.5,
                                                                                                            } }, { children: change.name }))] })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-information.ticket-information-history.changed-from") })), _jsx(Box, Object.assign({ sx: {
                                                                                                        paddingY: 1,
                                                                                                    } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                            color: "default",
                                                                                                        } }, { children: change.custom_field_type ===
                                                                                                            "text_area" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "html_text" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingY: 1,
                                                                                                                paddingX: 2,
                                                                                                            } }, { children: _jsx(Input, { size: "small", fullWidth: true, multiline: true, value: change.previous, disableUnderline: true, inputProps: {
                                                                                                                    readOnly: true,
                                                                                                                    style: {
                                                                                                                        fontSize: 12,
                                                                                                                    },
                                                                                                                } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingX: 2,
                                                                                                                paddingBottom: 2,
                                                                                                            } }, { children: _jsx(Typography, { sx: {
                                                                                                                    margin: 0,
                                                                                                                }, variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                    __html: change.previous,
                                                                                                                } }) }))) })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-information.ticket-information-history.to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                        paddingY: 1,
                                                                                                    } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                            color: "default",
                                                                                                        } }, { children: change.custom_field_type ===
                                                                                                            "text_area" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "html_text" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingY: 1,
                                                                                                                paddingX: 2,
                                                                                                            } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: change.updated, disableUnderline: true, inputProps: {
                                                                                                                    readOnly: true,
                                                                                                                    style: {
                                                                                                                        fontSize: 12,
                                                                                                                    },
                                                                                                                } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingX: 2,
                                                                                                                paddingBottom: 2,
                                                                                                            } }, { children: _jsx(Typography, { sx: {
                                                                                                                    margin: 0,
                                                                                                                }, variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                    __html: change.updated,
                                                                                                                } }) }))) })) }))] }))) : !change.previous &&
                                                                                            change.updated ? (_jsxs(Box, Object.assign({ sx: {
                                                                                                flexDirection: "column",
                                                                                                width: "100%",
                                                                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                                marginX: 0.5,
                                                                                                            } }, { children: change.name }))] })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-information.ticket-information-history.set-to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                        paddingY: 1,
                                                                                                        width: "100%",
                                                                                                    } }, { children: _jsx(Paper, Object.assign({ variant: "outlined" }, { children: change.custom_field_type ===
                                                                                                            "text_area" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "html_text" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingY: 1,
                                                                                                                paddingX: 2,
                                                                                                            } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: change.updated, disableUnderline: true, inputProps: {
                                                                                                                    readOnly: true,
                                                                                                                    style: {
                                                                                                                        fontSize: 12,
                                                                                                                    },
                                                                                                                } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingX: 2,
                                                                                                                paddingBottom: 2,
                                                                                                            } }, { children: _jsx(Typography, { sx: {
                                                                                                                    margin: 0,
                                                                                                                }, variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                    __html: change.updated,
                                                                                                                } }) }))) })) }))] }))) : (change.previous &&
                                                                                            !change.updated && (_jsxs(Box, Object.assign({ sx: {
                                                                                                flexDirection: "column",
                                                                                                width: "100%",
                                                                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                                marginX: 0.5,
                                                                                                            } }, { children: change.name }))] })), _jsx(Box, Object.assign({ sx: {
                                                                                                        paddingY: 1,
                                                                                                    } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                            color: "default",
                                                                                                        } }, { children: change.custom_field_type ===
                                                                                                            "text_area" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "html_text" ||
                                                                                                            change.custom_field_type ===
                                                                                                                "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingY: 1,
                                                                                                                paddingX: 2,
                                                                                                            } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: change.previous, disableUnderline: true, inputProps: {
                                                                                                                    readOnly: true,
                                                                                                                    style: {
                                                                                                                        fontSize: 12,
                                                                                                                    },
                                                                                                                } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                                paddingX: 2,
                                                                                                                paddingBottom: 2,
                                                                                                            } }, { children: _jsx(Typography, { sx: {
                                                                                                                    margin: 0,
                                                                                                                }, variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                    __html: change.previous,
                                                                                                                } }) }))) })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-information.ticket-information-history.removed") }))] })))) }))) }, index));
                                                                            }) }, index));
                                                                    }) })) }) }))] })] }, historyRecord.id));
                                }) })) }))] })) })), historyToDelete && historyToDelete !== "" && (_jsx(InformationHistoryDelete, { ticketId: ticket.id, historyToDelete: historyToDelete, setHistoryToDelete: setHistoryToDelete }))] }));
}
