import { jsx as _jsx } from "react/jsx-runtime";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import ContactForm from "../../contacts/contact-form";
export default function SurveyContact({ campaign, setStep, contact, setContact, createSurveyTicket, ticket, isSaving, }) {
    const isMobile = useMediaQuery("(max-width: 767px)");
    return (_jsx(Box, Object.assign({ sx: {
            paddingTop: 8,
            paddingBottom: 8,
            width: isMobile ? "100%" : "60%",
        } }, { children: _jsx(ContactForm, { kind: "ticket", status: ticket || contact ? "edit" : "new", contact: contact, modal: true, setStep: setStep, campaign: campaign, createSurveyTicket: !ticket ? createSurveyTicket : undefined, setContact: setContact, isTicketCreating: isSaving }) })));
}
