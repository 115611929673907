import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Nope from "../../../elements/blocks/nope";
import Intro from "../../../elements/blocks/intro";
import UserDetails from "../../../elements/users/user-details";
import UserClients from "../../../elements/users/user-clients";
import { useApiCentral } from "../../../../api/api-central";
export default function CentralUserView() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiCentral();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_user(id)
            .then(response => {
            setUser(response.data.central_user);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: user
                    ? user.first_name + (user.last_name ? " " + user.last_name : "")
                    : t("users.user") + " #" + id, breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("users.users"),
                        link: "/administration/users",
                    },
                    {
                        name: user
                            ? user.first_name + (user.last_name ? " " + user.last_name : "")
                            : t("users.user"),
                        link: "/administration/users/" + id,
                    },
                ], button: user
                    ? {
                        label: t("users.edit-user"),
                        link: "/administration/users/" + id + "/edit",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : user ? (_jsxs(Fragment, { children: [_jsx(UserDetails, { user: user, type: "central" }), _jsx(UserClients, { user: user })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("users.errors.not-found") }))] }));
}
