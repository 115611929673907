import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "../elements/header/sidebar";
import { useAuth } from "../../services/auth";
export default function Frame({ children }) {
    const auth = useAuth();
    const isDesktop = useMediaQuery("(min-width: 1200px)");
    return auth.isPublic ? (_jsx(Fragment, { children: children })) : (_jsxs(Fragment, { children: [_jsx(Header, {}), _jsx(Hidden, Object.assign({ lgDown: true }, { children: _jsx(Sidebar, {}) })), _jsx(Box, Object.assign({ sx: {
                    marginLeft: isDesktop ? "280px" : 0,
                    paddingY: 3,
                } }, { children: _jsx(Container, Object.assign({ maxWidth: false }, { children: children })) })), _jsx(Footer, {})] }));
}
