import { jsx as _jsx } from "react/jsx-runtime";
import { enUS, hr } from "date-fns/locale";
import { formatDuration, intervalToDuration, sub } from "date-fns";
import Typography from "@mui/material/Typography";
import { usePrefs } from "../../../../services/prefs";
export default function TicketEstimateColumn({ estimate }) {
    const prefs = usePrefs();
    const locales = { enUS, hr };
    return (_jsx(Typography, { children: estimate &&
            formatDuration(intervalToDuration({
                start: sub(new Date(), {
                    seconds: estimate,
                }),
                end: new Date(),
            }), {
                locale: prefs.lang === "en" ? locales["enUS"] : locales["hr"],
            }) }));
}
