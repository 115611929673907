import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
export default function InputSlider({ required, value, setValue, disabled, label, info, min, max, step, marks, error, errors, isError, setIsError, }) {
    const [localValue, setLocalValue] = useState(value ? value : null);
    return (_jsxs(Fragment, { children: [label && (_jsx(Typography, Object.assign({ variant: "body1", gutterBottom: true }, { children: label }))), _jsxs(FormControl, Object.assign({ required: required, fullWidth: true, variant: "standard", error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(Slider, { disabled: disabled ? disabled : false, min: min, max: max, step: step, size: "small", value: disabled ? (value ? value : 0) : localValue ? localValue : value ? value : 0, valueLabelDisplay: "auto", color: "secondary", marks: true, onChange: (event, val) => {
                            setLocalValue(val);
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        }, onChangeCommitted: (event, val) => {
                            if (setValue)
                                setValue(localValue);
                            setLocalValue(val);
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } }), marks && (_jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: marks[2] ? 4 : 6 }, { children: marks[0] && marks[0].label !== "" && (_jsx(Box, Object.assign({ sx: { display: "flex", justifyContent: "flex-start" } }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: marks[0].label })) }))) })), _jsx(Grid, Object.assign({ item: true, xs: marks[2] ? 4 : 6 }, { children: marks[1] && marks[1].label !== "" && (_jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        justifyContent: marks[2] ? "center" : "flex-end",
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: marks[1].label })) }))) })), _jsx(Grid, Object.assign({ item: true, xs: 4 }, { children: marks[2] && marks[2].label !== "" && (_jsx(Box, Object.assign({ sx: { display: "flex", justifyContent: "flex-end" } }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: marks[2].label })) }))) }))] }))), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
