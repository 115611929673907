import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import NumberFormat from "react-number-format";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
export default function InputNumber({ label, required, value, setValue, caption, info, onlyInteger, disabled, error, errors, isError, setIsError, }) {
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: caption }) }))), _jsxs(FormControl, Object.assign({ fullWidth: true, disabled: disabled ? disabled : false, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(InputLabel, { children: label }), _jsx(NumberFormat, { disabled: disabled ? disabled : false, label: label, required: required, value: value, customInput: OutlinedInput, thousandSeparator: ".", decimalSeparator: ",", decimalScale: onlyInteger ? 0 : 2, allowNegative: false, isNumericString: true, onValueChange: values => {
                            if (onlyInteger && values.value.includes(".")) {
                                setValue(values.value.split(".")[0]);
                            }
                            else {
                                setValue(values.value);
                            }
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } }), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
