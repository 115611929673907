import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import SurveyForm from "../../elements/tickets/survey/survey-form";
import { useApiPublic } from "../../../api/api-public";
import { useTenancy } from "../../../services/tenancy";
import { usePrefs } from "../../../services/prefs";
export default function SurveyTicket() {
    const { t, i18n } = useTranslation();
    const { slug, contact_uuid } = useParams();
    const api = useApiPublic();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [isLoading, setIsLoading] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const [client, setClient] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [contact, setContact] = useState(null);
    const [surveyAlreadyTaken, setSurveyAlreadyTaken] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (!tenancy.subDomain && process.env.APP_ENV !== "local")
            window.location.replace("/login");
        if (!slug) {
            let lang = window.location.pathname.startsWith("/anketa") ? "hr" : "en";
            i18n.changeLanguage(lang).then(() => {
                // @ts-ignore
                prefs.setLang(lang);
                setErrorMessage(t("tickets.survey.not-found"));
            });
            return;
        }
        setIsLoading(true);
        api.GET_clientByDomain(process.env.APP_ENV === "local" ? "ina" : tenancy.subDomain)
            .then(response => {
            let tenant = response.data.tenant;
            tenancy.setClient(tenant);
            setClient(tenant);
            api.GET_campaignBySlug(response.data.tenant.id, slug, contact_uuid ? contact_uuid : undefined)
                .then(response => {
                var _a;
                i18n.changeLanguage(response.data.campaign.language).then(() => {
                    prefs.setLang(response.data.campaign.language);
                });
                if (response.data.campaign.allow_multiple_survey_submits === false &&
                    ((_a = prefs.surveys) === null || _a === void 0 ? void 0 : _a.includes((tenant === null || tenant === void 0 ? void 0 : tenant.domain) + "." + slug + ",")) === true) {
                    setSurveyAlreadyTaken(true);
                }
                else {
                    prefs.setTicketStartTime(new Date().toString());
                    setCampaign(response.data.campaign);
                    setTicket(response.data.ticket);
                    if (response.data.ticket !== null)
                        setContact(response.data.ticket.contact);
                    else
                        setContact(response.data.contact);
                }
                document.title = response.data.campaign.name + " - " + process.env.APP_NAME;
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    if (error.response && error.response.status === 409)
                        setSurveyAlreadyTaken(true);
                    if (error.response &&
                        (error.response.status === 404 || error.response.status === 422)) {
                        setErrorMessage(error.response.data.message);
                    }
                    console.error(error);
                    setIsLoading(false);
                }
            });
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                setIsLoading(false);
                // @ts-ignore
                window.location.replace(process.env.APP_URL);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsx(Box, Object.assign({ sx: {
            minHeight: "100vh",
            display: "flex",
        } }, { children: _jsx(Container, { children: isLoading ? (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : client ? (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 8,
                        } }, { children: prefs.theme === "dark" && (client === null || client === void 0 ? void 0 : client.logo_dark_url) ? (_jsx("img", { src: client.logo_dark_url, style: {
                                maxHeight: "100px",
                                maxWidth: "200px",
                                objectFit: "contain",
                            }, alt: "Logo" })) : (client === null || client === void 0 ? void 0 : client.logo_url) ? (_jsx("img", { src: client.logo_url, style: {
                                maxHeight: "100px",
                                maxWidth: "200px",
                                objectFit: "contain",
                            }, alt: "Logo" })) : (_jsx(Typography, Object.assign({ variant: "h2" }, { children: client === null || client === void 0 ? void 0 : client.name }))) })), client &&
                        campaign &&
                        campaign.active &&
                        !(campaign &&
                            !campaign.field_options.find(a => a.table === "contacts" && a.visible) &&
                            !campaign.custom_field_options.find(b => b.visible) &&
                            !campaign.has_steps) ? (_jsx(Fragment, { children: process.env.GOOGLE_RECAPTCHA_KEY &&
                            campaign.require_captcha_on_survey ? (_jsx(GoogleReCaptchaProvider, Object.assign({ reCaptchaKey: process.env.GOOGLE_RECAPTCHA_KEY, language: prefs.lang, scriptProps: {
                                async: true,
                            } }, { children: _jsx(SurveyForm, { campaign: campaign, contact: contact ? contact : undefined, setContact: setContact, ticket: ticket ? ticket : undefined, setTicket: setTicket }) }))) : (_jsx(SurveyForm, { campaign: campaign, contact: contact ? contact : undefined, setContact: setContact, ticket: ticket ? ticket : undefined, setTicket: setTicket })) })) : (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            paddingY: 10,
                        } }, { children: _jsx(Box, Object.assign({ sx: { width: isMobile ? "100%" : "60%" } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: isMobile ? 4 : 5,
                                    } }, { children: _jsx(Alert, Object.assign({ severity: campaign && !campaign.active
                                            ? "info"
                                            : campaign &&
                                                !campaign.field_options.find(a => a.table === "contacts" &&
                                                    a.visible) &&
                                                !campaign.custom_field_options.find(b => b.visible) &&
                                                !campaign.has_steps
                                                ? "warning"
                                                : surveyAlreadyTaken
                                                    ? "success"
                                                    : "error" }, { children: campaign && !campaign.active
                                            ? t("tickets.survey.not-active")
                                            : campaign &&
                                                !campaign.field_options.find(a => a.table === "contacts" && a.visible) &&
                                                !campaign.custom_field_options.find(b => b.visible) &&
                                                !campaign.has_steps
                                                ? t("tickets.survey.not-valid")
                                                : surveyAlreadyTaken
                                                    ? t("tickets.survey.already-taken")
                                                    : errorMessage })) })) }) })) }))), _jsx(Box, Object.assign({ sx: {
                            textAlign: "center",
                            paddingBottom: 8,
                        } }, { children: _jsxs(Typography, { children: [_jsx(Link, Object.assign({ target: "_blank", style: {
                                        color: prefs.theme === "dark" ? "#fff" : "#0000008a",
                                    }, href: process.env.APP_LINK, underline: "hover" }, { children: process.env.APP_NAME })), _jsxs(Box, Object.assign({ component: "span", sx: {
                                        paddingLeft: 1,
                                        color: prefs.theme === "dark" ? "#fff" : "#0000008a",
                                    } }, { children: ["\u00A9 ", new Date().getFullYear()] }))] }) }))] })) : ("") }) })));
}
