import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import { prettifyText } from "../../../helpers/clean";
export default function InputRadio({ label, required, type, direction, options, value, setValue, info, disabled, error, errors, isError, setIsError, }) {
    return (_jsxs(Fragment, { children: [label && (_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: label })) }))), _jsxs(FormControl, Object.assign({ required: required, fullWidth: true, variant: "standard", error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(RadioGroup, Object.assign({ style: {
                            flexDirection: direction === "horizontal" ? "row" : "column",
                            justifyContent: "flex-start",
                            marginLeft: type === "text" ? "-8px" : "0",
                        }, value: value, onChange: event => {
                            setValue(event.target.value);
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } }, { children: options.map((option, index) => {
                            var _a;
                            return (_jsx(FormControlLabel, { labelPlacement: type === "icon" || type === "image" ? "bottom" : "end", control: type !== "text" ? (_jsx(Radio, { color: "secondary", disabled: disabled ? disabled : false, checked: option.value == value, icon: type === "icon" ? (_jsx(Icon, Object.assign({ fontSize: "large" }, { children: option.icon }))) : type === "image" &&
                                        option.image.toString().startsWith("http") ? (_jsx("img", { src: option.image, alt: option.value, height: 75, style: {
                                            maxWidth: "100%",
                                        } })) : type === "image" &&
                                        option.image.toString() !== "" ? (_jsx(Fragment, { children: _jsx("img", { src: URL.createObjectURL(
                                            // @ts-ignore
                                            option.image), alt: option.value, height: 75, style: {
                                                maxWidth: "100%",
                                            } }) })) : (_jsx(Fragment, {})), checkedIcon: type === "icon" ? (_jsx(Icon, Object.assign({ fontSize: "large" }, { children: option.icon }))) : (_jsx("img", { src: option
                                            ? ((_a = option.image) === null || _a === void 0 ? void 0 : _a.toString().startsWith("http"))
                                                ? option.image
                                                : option.image
                                                    ? URL.createObjectURL(
                                                    // @ts-ignore
                                                    option.image)
                                                    : ""
                                            : "", alt: option.value, height: 75, style: {
                                            maxWidth: "100%",
                                        } })), style: {
                                        opacity: option.value == value ? "1" : ".3",
                                        maxHeight: 75,
                                        maxWidth: 150,
                                    } })) : (_jsx(Radio, { color: "secondary", disabled: disabled ? disabled : false, checked: option.value == value })), label: option.label ? prettifyText(option.label) : "", value: option.value ? option.value : "", style: {
                                    margin: type === "text" ? "0" : "0 24px 0 0",
                                } }, index));
                        }) })), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
