import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useApiCentral } from "../../../api/api-central";
export default function UserDelete({ type, user }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiCentral();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const isDeleteDisabled = type === "client" && ((user === null || user === void 0 ? void 0 : user.roles[0].name) === "admin" || (user === null || user === void 0 ? void 0 : user.roles[0].name) === "manager");
    const deleteUser = () => {
        var _a;
        if (isDeleteDisabled)
            return;
        setIsDeleting(true);
        if (type === "client") {
            if (!tenancy.client)
                return;
            api.DELETE_userFromClient((_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.id, user.global_id)
                .then(() => {
                setIsDeleting(false);
                alert.show(t("users.user-delete.user-removed"), "warning");
                navigate(tenancy.link("/settings/users"));
            })
                .catch(error => {
                setIsDeleting(false);
                console.error(error.response);
                alert.show(t("users.user-delete.removing-error-occured"), "error");
            });
        }
        else {
            api.DELETE_user(user.id)
                .then(() => {
                setIsDeleting(false);
                alert.show(t("users.user-delete.user-deleted"), "warning");
                navigate("/administration/users");
            })
                .catch(error => {
                setIsDeleting(false);
                console.error(error.response);
                alert.show(t("users.user-delete.deleting-error-occured"), "error");
            });
        }
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isDeleting, disabled: isDeleteDisabled, onClick: () => {
                        setIsOpened(true);
                    }, startIcon: _jsx(DeleteIcon, {}), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("users.user-delete.delete-user") })) })), _jsxs(Dialog, Object.assign({ open: isOpened, onClose: () => {
                    setIsOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: t("users.user-delete.delete-confirm") }), _jsx(DialogContentText, { children: _jsx("em", { children: t("delete-component.confirm-caption") }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    deleteUser();
                                } }, { children: t("delete-component.delete") }))] })] }))] }));
}
