import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import TicketSteps from "../../elements/tickets/ticket-steps";
import SearchContactCard from "../../elements/search/search-contact-card";
import TicketHistory from "../../elements/tickets/ticket-history";
import Intro from "../../elements/blocks/intro";
import ContactForm from "../../elements/contacts/contact-form";
import ContactHistory from "../../elements/contacts/contact-history";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useAlert } from "../../../services/alert";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { copy } from "../../../helpers/copy";
export default function CampaignTicket() {
    const { t } = useTranslation();
    const { id, phone } = useParams();
    const api = useApiTenant();
    const alert = useAlert();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const roles = useRoles();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [campaign, setCampaign] = useState(null);
    const [isOutbound, setIsOutbound] = useState(false);
    const [isLeadLoading, setIsLeadLoading] = useState(false);
    const [isLeadInValid, setIsLeadInValid] = useState(false);
    const [isLeadModalOpened, setIsLeadModalOpened] = useState(false);
    const [lead, setLead] = useState(undefined);
    const [leadContacts, setLeadContacts] = useState([]);
    const [contact, setContact] = useState(undefined);
    const [createdContact, setCreatedContact] = useState(false);
    const [updatedContact, setUpdatedContact] = useState(false);
    const [ticketHistory, setTicketHistory] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const createTicket = () => {
        if (!campaign || !contact)
            return;
        setIsSaving(true);
        api.CREATE_ticket(campaign.id, contact.id, lead ? lead.id : undefined)
            .then(response => {
            alert.show(t("campaigns.campaign-ticket.draft-created"), "info");
            setIsSaving(false);
            window.history.pushState({}, "", tenancy.link("/tickets/" + response.data.ticket.id + "/contact"));
            if (campaign.has_steps) {
                navigate(tenancy.link("/tickets/" + response.data.ticket.id + "/information"));
            }
            else {
                navigate(tenancy.link("/tickets/" + response.data.ticket.id + "/details"));
            }
        })
            .catch(error => {
            console.error(error.response);
            setIsSaving(false);
            alert.show(t("campaigns.campaign-ticket.creating-error"), "error");
        });
    };
    useEffect(() => {
        api.GET_campaign(id)
            .then(response => {
            const campaign = response.data.campaign;
            if (campaign.has_contact_fields) {
                setCampaign(campaign);
                if (campaign.type === "outbound")
                    setIsOutbound(true);
                setIsLoading(false);
            }
            else {
                api.CREATE_contact(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true)
                    .then(response => {
                    const contact = response.data.contact;
                    api.CREATE_ticket(campaign.id, contact.id)
                        .then(response => {
                        alert.show(t("campaigns.campaign-ticket.draft-created"), "info");
                        if (campaign.has_steps) {
                            navigate(tenancy.link("/tickets/" +
                                response.data.ticket.id +
                                "/information"));
                        }
                        else {
                            navigate(tenancy.link("/tickets/" + response.data.ticket.id + "/details"));
                        }
                    })
                        .catch(error => {
                        console.error(error.response);
                        setIsLoading(false);
                        alert.show(t("campaigns.campaign-ticket.creating-error"), "error");
                    });
                })
                    .catch(error => {
                    console.error(error.response);
                    setIsLoading(false);
                    alert.show(t("campaigns.campaign-ticket.creating-error"), "error");
                });
            }
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (!isOutbound || phone)
            return;
        setIsLeadLoading(true);
        setIsLeadModalOpened(true);
        api.GET_availableLead(id)
            .then(response => {
            if (!response.data.lead) {
                setIsLeadLoading(false);
                return;
            }
            setLead(response.data.lead);
            if (response.data.lead.contacts)
                setLeadContacts(response.data.lead.contacts);
            setIsLeadLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                setIsLeadLoading(false);
                console.error(error);
            }
        });
        return () => api.cancel();
    }, [isOutbound]);
    useEffect(() => {
        if (!isLeadInValid)
            return;
        setIsLeadLoading(true);
        setLead(undefined);
        setLeadContacts([]);
        api.GET_availableLead(id, lead === null || lead === void 0 ? void 0 : lead.id)
            .then(response => {
            setIsLeadInValid(false);
            if (!response.data.lead) {
                setIsLeadLoading(false);
                return;
            }
            setLead(response.data.lead);
            if (response.data.lead.contacts)
                setLeadContacts(response.data.lead.contacts);
            setIsLeadLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                setIsLeadLoading(false);
                console.error(error);
            }
        });
        return () => api.cancel();
    }, [isLeadInValid]);
    useEffect(() => {
        if (!isLoading)
            return;
        prefs.setTicketStartTime(new Date().toString());
        if (phone)
            prefs.setTicketPhone(phone);
    }, [isLoading]);
    useEffect(() => {
        if (!createdContact && !updatedContact)
            return;
        if (createdContact) {
            createTicket();
            return;
        }
        api.GET_tickets(1, 1, "id", "desc", undefined, undefined, (campaign === null || campaign === void 0 ? void 0 : campaign.id) ? [campaign === null || campaign === void 0 ? void 0 : campaign.id] : undefined, (contact === null || contact === void 0 ? void 0 : contact.id) ? [contact === null || contact === void 0 ? void 0 : contact.id] : undefined, undefined, undefined, undefined, roles.isAgent
            ? roles.groupsTenant
                ? roles.groupsTenant.map(group => group.id)
                : []
            : ["-1"], undefined, undefined, "exclude", "include", "include")
            .then(response => {
            if (response.data.tickets.length > 0) {
                setTicketHistory(true);
            }
            else
                createTicket();
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
            }
        });
        return () => api.cancel();
    }, [createdContact, updatedContact]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.new-ticket"), breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: campaign ? campaign.name : t("campaigns.campaign") + " #" + id,
                        link: "/campaigns/" + id,
                    },
                    {
                        name: t("tickets.new-ticket"),
                        link: "/campaigns/" + id + "/new-ticket",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : campaign ? (_jsxs(Fragment, { children: [_jsx(TicketSteps, { step: "contact", campaign: campaign }), _jsx(ContactForm, { kind: "ticket", status: contact ? "edit" : "new", contact: contact, campaign: campaign, setContact: setContact, setCreatedContact: setCreatedContact, setUpdatedContact: setUpdatedContact, callerPhone: phone, lead: lead ? lead : null, ticketDraft: true, newContact: !contact }), contact && (_jsxs(Fragment, { children: [contact.history && contact.history.length > 0 && (_jsx(ContactHistory, { status: "view", contact: contact })), _jsx(TicketHistory, { campaign: campaign, contact: contact, ticketHistoryOpened: ticketHistory, createTicket: createTicket, isLoading: isSaving, lead: lead !== null ? lead : undefined })] }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("campaigns.errors.not-found") })), _jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isLeadModalOpened }, { children: isLeadLoading ? (_jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: "center",
                        marginY: 3,
                        paddingY: 3,
                    } }, { children: _jsx(CircularProgress, { color: "inherit", size: 30 }) }))) : lead ? (_jsxs(Fragment, { children: [_jsxs(DialogContent, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("campaigns.campaign-ticket.your-next-assigned-lead") })), _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-ticket.use-this-lead") })), lead.notes && (_jsx(Box, Object.assign({ sx: { marginTop: 1, marginBottom: 2 } }, { children: _jsx(Alert, Object.assign({ severity: "info" }, { children: lead.notes })) }))), _jsx(Box, Object.assign({ sx: { marginTop: 1 } }, { children: _jsx(Chip, { style: {
                                            color: "inherit",
                                        }, variant: "outlined", label: lead.phone.phone_area.phone_country.phone !== "00"
                                            ? lead.phone.phone_area.phone_country.phone +
                                                " " +
                                                lead.phone.phone_area.phone +
                                                " " +
                                                lead.phone.number.substring(0, 4) +
                                                " " +
                                                lead.phone.number.substring(4, lead.phone.number.length)
                                            : lead.phone.number, onDelete: () => {
                                            copy(lead.phone.phone_area.phone_country.phone !== "00"
                                                ? lead.phone.phone_area.phone_country.phone +
                                                    lead.phone.phone_area.phone +
                                                    lead.phone.number
                                                : lead.phone.number);
                                            alert.show(t("phone-copied"), "info");
                                        }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                            // @ts-ignore
                                            title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) }) })), lead.contacts.length > 0 && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                marginY: 3,
                                            } }, { children: _jsx(Divider, { light: true }) })), _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("campaigns.campaign-ticket.contacts-with-same-phone-found") })), _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-ticket.use-existing-or-create-new") })), leadContacts.map(leadContact => (_jsx(Box, Object.assign({ sx: {
                                                paddingY: 1,
                                            } }, { children: _jsx(SearchContactCard, { type: "use", contact: leadContact, setContact: contact => {
                                                    setContact(contact);
                                                    setIsLeadModalOpened(false);
                                                }, lead: lead, setLead: setLead }) }), leadContact.id)))] }))] }), _jsx(DialogActions, { children: _jsxs(Box, Object.assign({ sx: {
                                    paddingTop: 1,
                                } }, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                            setLead(null);
                                            setIsLeadModalOpened(false);
                                        } }, { children: t("close") })), _jsx(Button, Object.assign({ sx: { color: "rgb(244, 67, 54)" }, onClick: () => {
                                            setIsLeadInValid(true);
                                        } }, { children: t("campaigns.campaign-ticket.invalid-lead") })), _jsx(Button, Object.assign({ onClick: () => {
                                            setLead(lead);
                                            setIsLeadModalOpened(false);
                                        } }, { children: t("campaigns.campaign-ticket.create-new-contact") }))] })) })] })) : (_jsx(Fragment, { children: lead !== null && (_jsxs(Fragment, { children: [_jsx(DialogContent, { children: _jsx(Box, Object.assign({ sx: {
                                        paddingTop: 2,
                                    } }, { children: _jsx(Alert, Object.assign({ severity: "warning" }, { children: t("campaigns.campaign-ticket.no-available-leads") })) })) }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: {
                                        paddingTop: 1,
                                    } }, { children: _jsx(Button, Object.assign({ onClick: () => {
                                            setIsLeadModalOpened(false);
                                        } }, { children: t("campaigns.campaign-ticket.create-new-contact") })) })) })] })) })) }))] }));
}
