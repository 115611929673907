import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ErrorIcon from "@mui/icons-material/Error";
import CheckPhone from "@mui/icons-material/AddTask";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/HighlightOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import { copy } from "../../../helpers/copy";
import SearchContactCard from "../search/search-contact-card";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { useApiTenant } from "../../../api/api-tenant";
import { useApiPublic } from "../../../api/api-public";
import { useAlert } from "../../../services/alert";
import { usePrefs } from "../../../services/prefs";
export default function InputPhones({ status, label, phones, setPhones, setContact, emails, disabled, checkContacts, error, errors, isError, setIsError, callerPhone, }) {
    var _a;
    const auth = useAuth();
    const tenancy = useTenancy();
    const api = auth.isPublic ? useApiPublic() : useApiTenant();
    const apiTenant = useApiTenant();
    const { t } = useTranslation();
    const alert = useAlert();
    const prefs = usePrefs();
    const [phoneCountry, setPhoneCountry] = useState(null);
    const [phoneCountries, setPhoneCountries] = useState([]);
    const [phoneArea, setPhoneArea] = useState(null);
    const [allPhoneAreas, setAllPhoneAreas] = useState([]);
    const [countryPhoneAreas, setCountryPhoneAreas] = useState([]);
    const [number, setNumber] = useState("");
    const [phoneAsText, setPhoneAsText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [isSaving, setIsSaving] = useState(!!callerPhone);
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [isPhoneModalOpened, setIsPhoneModalOpened] = useState(false);
    const [isContactModalOpened, setIsContactModalOpened] = useState(false);
    const [numberError, setNumberError] = useState(false);
    const [phoneDuplicated, setPhoneDuplicated] = useState(false);
    const [foundContacts, setFoundContacts] = useState([]);
    const [ticketPhone, setTicketPhone] = useState(callerPhone !== null && callerPhone !== void 0 ? callerPhone : null);
    const [phoneError, setPhoneError] = useState(false);
    const [search, setSearch] = useState("");
    const [filteredContacts, setFilteredContacts] = useState(null);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    useEffect(() => {
        if (!isPhoneModalOpened || allPhoneAreas.length > 0 || phoneCountries.length > 0)
            return;
        getPhoneCountriesAndAreas();
        return () => api.cancel();
    }, [isPhoneModalOpened]);
    useEffect(() => {
        if (!callerPhone)
            return;
        getPhoneCountriesAndAreas();
        return () => api.cancel();
    }, [callerPhone]);
    useEffect(() => {
        if (ticketPhone && phoneCountries.length > 0 && allPhoneAreas.length > 0)
            validatePhone(ticketPhone, true);
    }, [ticketPhone, phoneCountries, allPhoneAreas]);
    useEffect(() => {
        if (!ticketPhone || isPhoneModalOpened)
            return;
        if (phoneArea && number !== "")
            addPhoneNumber();
    }, [phoneArea, number]);
    useEffect(() => {
        let areas = new Array();
        if (phoneCountry) {
            allPhoneAreas.map(phoneArea => {
                if (phoneArea.phone_country_id == phoneCountry.id) {
                    areas.push(phoneArea);
                }
            });
            setCountryPhoneAreas(areas);
        }
    }, [phoneCountry]);
    useEffect(() => {
        if (phoneAsText === "") {
            setPhoneCountry(null);
            setPhoneArea(null);
            setNumber("");
            setPhoneError(false);
        }
    }, [phoneAsText]);
    useEffect(() => {
        let newContacts = [];
        let splitSearch = [];
        let flag = false;
        if (search && search !== "") {
            setIsSearchLoading(true);
            splitSearch = search.trimEnd().split(" ");
            foundContacts.map(contact => {
                flag = true;
                splitSearch.map(a => {
                    if (!((contact.first_name &&
                        contact.first_name
                            .toLocaleLowerCase()
                            .includes(a.toLocaleLowerCase())) ||
                        (contact.last_name &&
                            contact.last_name
                                .toLocaleLowerCase()
                                .includes(a.toLocaleLowerCase())) ||
                        (contact.company &&
                            contact.company.toLocaleLowerCase().includes(a.toLocaleLowerCase())))) {
                        flag = false;
                    }
                });
                if (flag && !newContacts.find(a => a === contact)) {
                    newContacts.push(contact);
                }
            });
            setIsSearchLoading(false);
            setFilteredContacts(newContacts);
        }
        else {
            setFilteredContacts(null);
        }
    }, [search]);
    const getPhoneCountriesAndAreas = () => {
        api.GET_phoneCountries(undefined, undefined, undefined, undefined, undefined, auth.isPublic ? (process.env.APP_ENV === "local" ? "1" : tenancy.client.id) : undefined)
            .then(response => {
            setPhoneCountries(response.data.phone_countries);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        api.GET_phoneAreas(undefined, undefined, undefined, undefined, undefined, auth.isPublic ? (process.env.APP_ENV === "local" ? "1" : tenancy.client.id) : undefined)
            .then(response => {
            setAllPhoneAreas(response.data.phone_areas);
            setIsLoading2(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
    };
    const validatePhone = (phone, isTicketPhone) => {
        if (!phone)
            return;
        if (/[a-zA-Z]/g.test(phone)) {
            setPhoneError(true);
            setPhoneCountry(null);
            setPhoneArea(null);
            setNumber("");
            setNumberError(false);
            return;
        }
        phone = phone.replace(/\D+/g, "");
        // Find phone country (3 -> 2 -> 1)
        let foundCountry;
        let foundCountryDigits;
        let countryCode = phone.slice(0, 3);
        foundCountry = phoneCountries.find(country => country.phone == countryCode);
        if (foundCountry) {
            setPhoneCountry(foundCountry);
            foundCountryDigits = 3;
            if (!isTicketPhone)
                setPhoneError(false);
        }
        else {
            countryCode = phone.slice(0, 2);
            foundCountry = phoneCountries.find(country => country.phone == countryCode);
            if (foundCountry) {
                setPhoneCountry(foundCountry);
                foundCountryDigits = 2;
                if (!isTicketPhone)
                    setPhoneError(false);
            }
            else {
                countryCode = phone.slice(0, 1);
                foundCountry = phoneCountries.find(country => country.phone == countryCode);
                if (foundCountry) {
                    setPhoneCountry(foundCountry);
                    foundCountryDigits = 1;
                    if (!isTicketPhone)
                        setPhoneError(false);
                }
                else {
                    setIsSaving(false);
                    setTicketPhone(null);
                    if (isTicketPhone) {
                        setIsSaving(false);
                        setTicketPhone(null);
                        alert.show(t("inputs.input-phones.phone-number") +
                            " (" +
                            ticketPhone +
                            ") " +
                            t("inputs.input-phones.not-valid-add-manually"), "warning", 20000);
                    }
                    else {
                        setPhoneError(true);
                    }
                    return;
                }
            }
        }
        // Find phone area (3 -> 2 -> 1)
        let foundArea;
        let foundAreaDigits;
        let areaCode = phone.slice(foundCountryDigits, foundCountryDigits + 3);
        foundArea = allPhoneAreas.find(area => area.phone == areaCode && area.phone_country.code == foundCountry.code);
        if (foundArea) {
            setPhoneArea(foundArea);
            foundAreaDigits = 3;
        }
        else {
            areaCode = phone.slice(foundCountryDigits, foundCountryDigits + 2);
            foundArea = allPhoneAreas.find(area => area.phone == areaCode && area.phone_country.code == foundCountry.code);
            if (foundArea) {
                setPhoneArea(foundArea);
                foundAreaDigits = 2;
                if (!isTicketPhone)
                    setPhoneError(false);
            }
            else {
                areaCode = phone.slice(foundCountryDigits, foundCountryDigits + 1);
                foundArea = allPhoneAreas.find(area => area.phone == areaCode && area.phone_country.code == foundCountry.code);
                if (foundArea) {
                    setPhoneArea(foundArea);
                    foundAreaDigits = 1;
                    if (!isTicketPhone)
                        setPhoneError(false);
                }
                else {
                    if (isTicketPhone) {
                        setIsSaving(false);
                        setTicketPhone(null);
                        alert.show(t("inputs.input-phones.phone-number") +
                            " (" +
                            ticketPhone +
                            ") " +
                            t("inputs.input-phones.not-valid-add-manually"), "warning", 20000);
                    }
                    else {
                        setPhoneError(true);
                        setPhoneCountry(null);
                    }
                    return;
                }
            }
        }
        // Rest of the number
        let number = phone.slice(foundCountryDigits + foundAreaDigits);
        if (number !== "") {
            setNumber(number);
            if (!isTicketPhone) {
                setPhoneError(false);
                validatePhoneNumber(foundArea, number);
            }
        }
        else {
            if (isTicketPhone) {
                setIsSaving(false);
                setTicketPhone(null);
                alert.show(t("inputs.input-phones.phone-number") +
                    " (" +
                    ticketPhone +
                    ") " +
                    t("inputs.input-phones.not-valid-add-manually"), "warning", 20000);
            }
            else {
                setNumber("");
                setPhoneCountry(null);
                setPhoneArea(null);
                setPhoneError(true);
            }
        }
    };
    const addPhoneNumber = () => {
        setIsSaving(true);
        if (phones.length === 0 && emails.length === 0 && status !== "edit" && checkContacts) {
            apiTenant
                .GET_contacts(100, 1, "id", "asc", number, "phones", undefined, undefined, phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id, undefined, undefined, undefined, undefined, undefined, 1)
                .then(response => {
                if (response.data.contacts.length === 0) {
                    const isValidPhoneNumber = validatePhoneNumber(phoneArea, number);
                    if (isValidPhoneNumber) {
                        checkBlacklisted(phoneArea, number);
                    }
                    else {
                        alert.show(t("inputs.input-phones.phone-number") +
                            " (" +
                            ticketPhone +
                            ") " +
                            t("inputs.input-phones.not-valid-add-manually"), "warning", 20000);
                        setIsSaving(false);
                        setIsPhoneModalOpened(false);
                    }
                }
                else {
                    setFoundContacts(response.data.contacts);
                    setIsSaving(false);
                    setIsPhoneModalOpened(false);
                    setIsContactModalOpened(true);
                }
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
        else {
            const isValidPhoneNumber = validatePhoneNumber(phoneArea, number);
            if (isValidPhoneNumber && !auth.isPublic) {
                checkBlacklisted(phoneArea, number);
            }
            else if (isValidPhoneNumber && auth.isPublic) {
                setPhones(phones => [
                    ...phones,
                    {
                        phone_area_id: phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id,
                        phone_area: phoneArea,
                        number: number,
                        on_tenant_blacklist: false,
                        on_central_blacklist: false,
                        tenant_blacklist_phone_id: "",
                    },
                ]);
            }
            else {
                alert.show(t("inputs.input-phones.phone-number") +
                    " (" +
                    ticketPhone +
                    ") " +
                    t("inputs.input-phones.not-valid-add-manually"), "warning", 20000);
            }
            setIsSaving(false);
            setIsPhoneModalOpened(false);
        }
        if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
            setIsError(false);
    };
    const addPhoneToBlacklist = phone => {
        if (!phone.phone_area)
            return;
        setIsSaving(true);
        apiTenant
            .CREATE_blacklistedNumber(phone.phone_area_id, phone.number)
            .then(response => {
            setIsSaving(false);
            phones.find(ph => { var _a; return ph.phone_area.id === ((_a = phone.phone_area) === null || _a === void 0 ? void 0 : _a.id) && ph.number === phone.number; }).on_tenant_blacklist = true;
            phones.find(ph => { var _a; return ph.phone_area.id === ((_a = phone.phone_area) === null || _a === void 0 ? void 0 : _a.id) && ph.number === phone.number; }).tenant_blacklist_phone_id = response.data.blacklist_phone.id;
            alert.show(t("inputs.input-phones.added-to-blacklist"), "warning");
        })
            .catch(error => {
            setIsSaving(false);
            console.error(error.response);
            if (error.response.status === 422) {
                alert.show(error.response.data.message, "error");
            }
            else {
                alert.show(t("inputs.input-phones.adding-error"), "error");
            }
        });
    };
    const removePhoneFromBlacklisted = phone => {
        apiTenant
            .DELETE_blacklistedNumber(phone.tenant_blacklist_phone_id)
            .then(() => {
            phones.find(ph => ph.phone_area.id === phone.phone_area.id && ph.number === phone.number).on_tenant_blacklist = false;
            alert.show(t("inputs.input-phones.removed-from-blacklist"), "success");
        })
            .catch(error => {
            console.error(error.response);
            alert.show(t("inputs.input-phones.removing-error"), "error");
        });
    };
    const validatePhoneNumber = (phoneArea, phoneNumber) => {
        if (!phoneArea || !phoneNumber)
            return false;
        let isValid = true;
        if (!(phoneNumber.match(/^[0-9]+$/) !== null)) {
            setNumberError(true);
        }
        else {
            setNumberError(false);
        }
        if (phoneArea.validation_rule) {
            const regex = new RegExp(phoneArea.validation_rule);
            if (regex.test(phoneNumber))
                setNumberError(false);
            else {
                isValid = false;
                setNumberError(true);
            }
        }
        if (phones.find(phone => {
            return phone.phone_area_id === phoneArea.id && phone.number === phoneNumber;
        })) {
            isValid = false;
            setPhoneDuplicated(true);
            setNumberError(true);
        }
        else
            setPhoneDuplicated(false);
        return isValid;
    };
    const checkBlacklisted = (phoneArea, phoneNumber) => {
        apiTenant
            .CHECK_blacklistedNumbers(phoneArea.id, phoneNumber)
            .then(response => {
            if (response.data.on_tenant_blacklist || response.data.on_central_blacklist) {
                alert.show(t("inputs.input-phones.phone-number") +
                    " (" +
                    phoneArea.phone_country.phone +
                    " " +
                    phoneArea.phone +
                    " " +
                    phoneNumber.substring(0, 4) +
                    " " +
                    phoneNumber.substring(4, phoneNumber.length) +
                    ") " +
                    t("inputs.input-phones.is-blacklisted"), "warning", 20000);
            }
            setPhones(phones => [
                ...phones,
                {
                    phone_area_id: phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id,
                    phone_area: phoneArea,
                    number: number,
                    on_tenant_blacklist: response.data.on_tenant_blacklist,
                    on_central_blacklist: response.data.on_central_blacklist,
                    tenant_blacklist_phone_id: response.data.tenant_blacklist_phone_id
                        ? response.data.tenant_blacklist_phone_id
                        : "",
                },
            ]);
            setIsSaving(false);
            setIsPhoneModalOpened(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
            }
        });
    };
    return (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                } }, { children: [_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "button", color: isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? "error" : "textPrimary" }, { children: label })) }), _jsx(Box, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", size: "small", disabled: disabled ? disabled : false, loading: isSaving, onClick: () => {
                                setNumber("");
                                setPhoneArea(null);
                                setNumberError(false);
                                setPhoneDuplicated(false);
                                setIsModalDelete(false);
                                setPhoneAsText("");
                                setIsPhoneModalOpened(true);
                            }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("inputs.input-phone.add-phone") })) })] })), phones.length > 0 ? (_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    flexWrap: "wrap",
                } }, { children: phones.map((phone, index) => {
                    return (_jsx(Box, Object.assign({ sx: {
                            display: "block",
                            width: "100%",
                            paddingTop: 2,
                        } }, { children: _jsx(Chip, { style: {
                                color: phone.on_central_blacklist
                                    ? "#f44336"
                                    : phone.on_tenant_blacklist
                                        ? "#ff9800"
                                        : "inherit",
                            }, icon: !auth.isPublic ? (_jsx(CancelIcon, { style: {
                                    color: prefs.theme === "light"
                                        ? "#00000042"
                                        : "#ffffff42",
                                }, onClick: () => {
                                    setNumber(phone.number);
                                    setPhoneArea(phone.phone_area);
                                    setPhoneCountry(phone.phone_area.phone_country);
                                    setNumberError(false);
                                    setPhoneDuplicated(false);
                                    setIsModalDelete(true);
                                    setIsPhoneModalOpened(true);
                                }, fontSize: "small" })) : undefined, deleteIcon: phone.on_central_blacklist || phone.on_tenant_blacklist ? (_jsx(Tooltip
                            // @ts-ignore
                            , Object.assign({ 
                                // @ts-ignore
                                title: phone.on_central_blacklist
                                    ? t("contacts.contact-phones.phone-blacklisted") + " (HAKOM)"
                                    : t("contacts.contact-phones.phone-blacklisted") }, { children: _jsx(ErrorIcon, { style: {
                                        color: phone.on_central_blacklist
                                            ? "#f44336"
                                            : "#ff9800",
                                    } }) }))) : !auth.isPublic ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                // @ts-ignore
                                title: t("copy-phone") }, { children: _jsx(ContentCopyIcon, { fontSize: "small" }) }))) : undefined, variant: "outlined", label: phone.phone_area.phone_country.phone !== "00"
                                ? phone.phone_area.phone_country.phone +
                                    " " +
                                    phone.phone_area.phone +
                                    " " +
                                    phone.number.substring(0, 4) +
                                    " " +
                                    phone.number.substring(4, phone.number.length)
                                : phone.number, onDelete: () => {
                                if (!(phone.on_central_blacklist ||
                                    phone.on_tenant_blacklist) &&
                                    !auth.isPublic) {
                                    copy(phone.phone_area.phone_country.phone !== "00"
                                        ? phone.phone_area.phone_country.phone +
                                            phone.phone_area.phone +
                                            phone.number
                                        : phone.number);
                                    alert.show(t("phone-copied"), "info");
                                }
                                if (auth.isPublic) {
                                    let newPhones = phones.filter(phone1 => !(phone1.phone_area_id ===
                                        phone.phone_area_id &&
                                        phone1.number === phone.number));
                                    setPhones(newPhones);
                                }
                            } }) }), index));
                }) }))) : (_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    paddingTop: 2,
                    paddingLeft: 1,
                    minHeight: "48px",
                } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? "error" : "textSecondary" }, { children: t("inputs.input-phones.no-phone-number") })) }))), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isPhoneModalOpened, onClose: () => {
                    setIsPhoneModalOpened(false);
                    setPhoneAsText("");
                } }, { children: [_jsx(DialogTitle, { children: isModalDelete
                            ? t("inputs.input-phones.remove-contact-phone")
                            : t("inputs.input-phones.add-phone-to-contact") }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [!isModalDelete && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ error: phoneError, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("inputs.input-phones.phone") }), _jsx(OutlinedInput, { label: t("inputs.input-phones.phone"), value: phoneAsText, autoComplete: "new-password", endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                                    validatePhone(phoneAsText, false);
                                                                }, size: "large" }, { children: _jsx(CheckPhone, {}) })) })), onChange: event => {
                                                            setPhoneAsText(event.target.value);
                                                        }, onKeyPress: event => {
                                                            if (event.key === "Enter" && phoneAsText)
                                                                validatePhone(phoneAsText, false);
                                                        } }), phoneError && (_jsx(FormHelperText, Object.assign({ error: phoneError }, { children: t("inputs.input-phones.phone-number-invalid") })))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) }))] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { disabled: isModalDelete, options: phoneCountries, getOptionLabel: country => country.phone + " " + country.name + " (" + country.code + ")", isOptionEqualToValue: (option, value) => option.code === value.code, value: phoneCountry, onChange: (event, value) => {
                                            setPhoneCountry(value);
                                            setPhoneArea(null);
                                            setNumber("");
                                            setNumberError(false);
                                            setPhoneDuplicated(false);
                                        }, autoHighlight: true, loading: isLoading, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("inputs.input-phone.country"), fullWidth: true, required: true, autoFocus: phoneCountries.length > 1, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { disabled: !phoneCountry || isModalDelete, options: countryPhoneAreas, getOptionLabel: area => area.phone + " - " + area.name, renderOption: (props, area) => (_jsx("li", Object.assign({}, props, { children: _jsx(Typography, Object.assign({ noWrap: true }, { children: area.phone + " - " + area.name })) }))), getOptionDisabled: area => area.phone_country_id !== (phoneCountry === null || phoneCountry === void 0 ? void 0 : phoneCountry.id), isOptionEqualToValue: (option, value) => option.id === value.id, value: phoneArea, onChange: (event, value) => {
                                            setNumber("");
                                            setPhoneArea(value);
                                            validatePhoneNumber(value, number);
                                        }, loading: isLoading2, autoHighlight: true, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("inputs.input-phone.area"), fullWidth: true, required: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading2 ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ disabled: !phoneArea || isModalDelete, error: numberError, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("inputs.input-phone.number") + " *" }), _jsx(OutlinedInput, { required: true, label: t("inputs.input-phone.number") + " *", value: number, autoComplete: "new-password", startAdornment: phoneCountry ? (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: phoneCountry.phone !== "00"
                                                        ? phoneCountry.phone +
                                                            (phoneArea ? " " + phoneArea.phone : "")
                                                        : "" }))) : undefined, onChange: event => {
                                                    if (event.target.value === "")
                                                        setNumberError(false);
                                                    validatePhoneNumber(phoneArea, event.target.value);
                                                    setNumber(event.target.value);
                                                } }), _jsx(FormHelperText, Object.assign({ error: numberError }, { children: phoneDuplicated
                                                    ? t("inputs.input-phones.already-added")
                                                    : (phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.validation_message)
                                                        ? phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.validation_message
                                                        : t("inputs.input-phones.phone-number-error") }))] })) }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsPhoneModalOpened(false);
                                    setPhoneAsText("");
                                } }, { children: t("cancel") })), phones.length > 0 &&
                                phones.find(phone => phone.phone_area.id === (phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id) && phone.number === number) &&
                                isModalDelete &&
                                !auth.isPublic ? (_jsxs(Fragment, { children: [_jsx(LoadingButton, Object.assign({ color: "inherit", variant: "text", disabled: (_a = phones.find(phone => phone.phone_area.id === (phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id) &&
                                            phone.number === number)) === null || _a === void 0 ? void 0 : _a.on_central_blacklist, loading: isSaving, onClick: () => {
                                            let blacklistedPhone = phones.find(phone => phone.phone_area.id === (phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id) &&
                                                phone.number === number);
                                            if (blacklistedPhone.on_tenant_blacklist) {
                                                removePhoneFromBlacklisted(blacklistedPhone);
                                            }
                                            else {
                                                addPhoneToBlacklist(blacklistedPhone);
                                            }
                                            setIsPhoneModalOpened(false);
                                        }, style: {
                                            color: "#ff9800",
                                        } }, { children: phones.find(phone => phone.phone_area.id === (phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id) &&
                                            phone.number === number).on_tenant_blacklist
                                            ? t("inputs.input-phones.unblacklist-phone")
                                            : t("inputs.input-phones.blacklist-phone") })), _jsx(Button, Object.assign({ style: { color: "#f44336" }, onClick: () => {
                                            let newPhones = phones.filter(phone => !(phone.phone_area_id === (phoneArea === null || phoneArea === void 0 ? void 0 : phoneArea.id) &&
                                                phone.number === number));
                                            setPhones(newPhones);
                                            setIsPhoneModalOpened(false);
                                        } }, { children: t("inputs.input-phones.remove-phone") }))] })) : (_jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", size: "small", loading: isSaving, disabled: !phoneCountry || !phoneArea || !number || numberError, onClick: () => addPhoneNumber(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("inputs.input-phone.add-phone") })))] })] })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isContactModalOpened && foundContacts.length > 0 }, { children: [_jsxs(DialogContent, { children: [_jsxs(Box, Object.assign({ sx: {
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                } }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("inputs.input-phones.contacts-with-same-phone-found") })), _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("inputs.input-phones.use-existing-contact-or-new") }))] })), _jsx(Box, Object.assign({ sx: {
                                    paddingTop: 1,
                                    paddingBottom: 2,
                                } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(OutlinedInput, { autoFocus: true, placeholder: t("inputs.search-contacts-(by") +
                                            t("contacts.contact-attributes.full-name-company").toLowerCase() +
                                            ")", value: search, onChange: event => setSearch(event.target.value), startAdornment: _jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchIcon, {}) })), endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: search !== "" && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => setSearch(""), size: "large" }, { children: isSearchLoading ? (_jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5, style: {
                                                        marginRight: "4px",
                                                    } })) : (_jsx(ClearIcon, {})) }))) })) }) })) })), filteredContacts !== null ? (filteredContacts.length > 0 ? (filteredContacts.map(contact => (_jsx(Box, Object.assign({ sx: {
                                    paddingY: 1,
                                } }, { children: _jsx(SearchContactCard, { type: "use", contact: contact, setContact: contact => {
                                        setContact(contact);
                                        setIsContactModalOpened(false);
                                    } }) }), contact.id)))) : (_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("inputs.no-contacts") })) }))) : (foundContacts.map(foundContact => (_jsx(Box, Object.assign({ sx: {
                                    paddingY: 1,
                                } }, { children: _jsx(SearchContactCard, { type: "use", contact: foundContact, setContact: contact => {
                                        setContact(contact);
                                        setIsContactModalOpened(false);
                                    } }) }), foundContact.id))))] }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: {
                                paddingTop: 1,
                                paddingLeft: 1,
                            } }, { children: _jsx(Button, Object.assign({ onClick: () => {
                                    checkBlacklisted(phoneArea, number);
                                    setIsContactModalOpened(false);
                                } }, { children: t("inputs.input-phones.create-new-contact") })) })) })] }))] }));
}
