import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./contact-form";
import Nope from "../blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
export default function ContactEditModal({ showEditContactModal, setShowEditContactModal, editContactModalId, updatedContact, setUpdatedContact, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useApiTenant();
    const tenancy = useTenancy();
    const roles = useRoles();
    const [contact, setContact] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (!editContactModalId)
            return;
        setIsLoading(true);
        getContact();
        return () => api.cancel();
    }, [editContactModalId]);
    useEffect(() => {
        if (!updatedContact)
            return;
        getContact();
    }, [updatedContact]);
    const getContact = () => {
        if (!editContactModalId)
            return;
        api.GET_contact(editContactModalId)
            .then(response => {
            setContact(response.data.contact);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    return (_jsx(Dialog, Object.assign({ disableEnforceFocus: true, fullWidth: true, maxWidth: "md", open: showEditContactModal, onClose: () => {
            setShowEditContactModal(false);
        }, PaperProps: {
            sx: {
                height: "100%",
                bgcolor: "background.default",
            },
        } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : contact ? (_jsxs(Fragment, { children: [_jsx(DialogTitle, Object.assign({ sx: {
                        bgcolor: "background.paper",
                    } }, { children: _jsx(Box, Object.assign({ sx: {
                            display: "inline-block",
                            paddingBottom: 1,
                        } }, { children: roles.isAgent ? (_jsx(Typography, Object.assign({ variant: "h6" }, { children: t("contacts.contact") + " #" + editContactModalId }))) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/contacts/" + editContactModalId), onClick: event => {
                                event.preventDefault();
                                navigate(tenancy.link("/contacts/" + editContactModalId));
                            }, underline: "hover" }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: t("contacts.contact") + " #" + editContactModalId })) }))) })) })), _jsx(Box, Object.assign({ sx: {
                        paddingX: 3,
                        paddingY: 2,
                    } }, { children: _jsx(ContactForm, { kind: "contact", status: "edit", contact: contact, modal: true, setUpdatedContact: setUpdatedContact }) }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("contacts.errors.not-found") })) })));
}
