import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import CampaignForm from "../../elements/campaigns/campaign-form";
export default function CampaignNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("campaigns.new-campaign"), breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: t("campaigns.new-campaign"),
                        link: "/campaigns/new",
                    },
                ] }), _jsx(CampaignForm, { status: "new" })] }));
}
