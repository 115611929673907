import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import ErrorIcon from "@mui/icons-material/Error";
import { useAlert } from "../../../services/alert";
import { copy } from "../../../helpers/copy";
export default function ContactPhones({ phones, isCustomField, color }) {
    const { t } = useTranslation();
    const alert = useAlert();
    return (_jsxs(Fragment, { children: [!isCustomField && (_jsx(Box, Object.assign({ sx: {
                    paddingBottom: 2,
                } }, { children: _jsx(Typography, Object.assign({ variant: "button", style: { color: color ? color : "inherit" } }, { children: t("contacts.contact-attributes.phones") })) }))), _jsx(Box, { children: phones.map((phone, index) => {
                    return (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            width: "100%",
                            paddingBottom: isCustomField ? 0 : 2,
                        } }, { children: _jsx(Chip, { size: isCustomField ? "small" : "medium", style: {
                                color: color
                                    ? color
                                    : !isCustomField
                                        ? phone.on_central_blacklist
                                            ? "#f44336"
                                            : phone.on_tenant_blacklist
                                                ? "#ff9800"
                                                : "inherit"
                                        : "inherit",
                                display: "flex",
                                overflow: "hidden",
                            }, variant: "outlined", label: phone.phone_area.phone_country.phone !== "00"
                                ? phone.phone_area.phone_country.phone +
                                    " " +
                                    phone.phone_area.phone +
                                    " " +
                                    phone.number.substring(0, 4) +
                                    " " +
                                    phone.number.substring(4, phone.number.length)
                                : phone.number, onDelete: () => {
                                copy(phone.phone_area.phone_country.phone !== "00"
                                    ? phone.phone_area.phone_country.phone +
                                        phone.phone_area.phone +
                                        phone.number
                                    : phone.number);
                                alert.show(t("phone-copied"), "info");
                            }, icon: !isCustomField &&
                                (phone.on_central_blacklist || phone.on_tenant_blacklist) ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                // @ts-ignore
                                title: phone.on_central_blacklist
                                    ? t("contacts.contact-phones.phone-blacklisted") + " (HAKOM)"
                                    : t("contacts.contact-phones.phone-blacklisted") }, { children: _jsx(ErrorIcon, { style: {
                                        color: phone.on_central_blacklist
                                            ? "#f44336"
                                            : "#ff9800",
                                    } }) }))) : undefined, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                // @ts-ignore
                                title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) }) }), index));
                }) })] }));
}
