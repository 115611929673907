import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import { useTenancy } from "../../../services/tenancy";
export default function SearchCampaignCard({ campaign }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    return (_jsx(Card, Object.assign({ variant: "outlined" }, { children: _jsx(CardActionArea, Object.assign({ onClick: () => navigate(tenancy.link("/campaigns/" + campaign.id)) }, { children: _jsxs(CardContent, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: campaign.active ? (_jsx(Chip, { size: "small", color: "secondary", label: t("campaigns.campaign-attributes.active") })) : (_jsx(Chip, { size: "small", color: "default", label: t("campaigns.campaign-attributes.inactive") })) })), campaign.type && (_jsx(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: campaign.type === "inbound"
                            ? t("campaigns.campaign-attributes.types.inbound")
                            : campaign.type === "outbound"
                                ? t("campaigns.campaign-attributes.types.outbound")
                                : campaign.type === "contract"
                                    ? t("campaigns.campaign-attributes.types.contract")
                                    : campaign.type === "survey"
                                        ? t("campaigns.campaign-attributes.types.survey")
                                        : t("campaigns.campaign-attributes.types.all") }))), campaign.name && _jsx(Typography, Object.assign({ variant: "h6" }, { children: campaign.name })), campaign.description && (_jsx(Typography, Object.assign({ color: "textSecondary" }, { children: campaign.description })))] }) })) })));
}
