import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTenancy } from "../../../../services/tenancy";
import { useAlert } from "../../../../services/alert";
import { useApiTenant } from "../../../../api/api-tenant";
export default function PhoneCountryDelete({ phoneCountry }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const deletePhoneCountry = () => {
        setIsDeleting(true);
        api.DELETE_phoneCountry(phoneCountry.id)
            .then(() => {
            setIsDeleting(false);
            alert.show(t("options.phone-countries.phone-country-delete.phone-country-deleted"), "warning");
            navigate(tenancy.link("/settings/options/phone-countries"));
        })
            .catch(error => {
            setIsDeleting(false);
            console.error(error.response);
            if (error.response.status === 422) {
                alert.show(error.response.data.message, "error");
            }
            else {
                alert.show(t("options.phone-countries.phone-country-delete.deleting-error-occured"), "error");
            }
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 4,
                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isDeleting, onClick: () => {
                        setIsOpened(true);
                    }, startIcon: _jsx(DeleteIcon, {}), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("options.phone-countries.phone-country-delete.delete-phone-country") })) })), _jsxs(Dialog, Object.assign({ open: isOpened, onClose: () => {
                    setIsOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: t("options.phone-countries.phone-country-delete.delete-confirm") }), _jsx(DialogContentText, { children: _jsx("em", { children: t("delete-component.confirm-caption") }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    deletePhoneCountry();
                                    setIsOpened(false);
                                } }, { children: t("delete-component.delete") }))] })] }))] }));
}
