import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InformationIcon from "@mui/icons-material/InfoOutlined";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import CustomFieldsView from "../custom-fields/custom-fields-view";
import { flatGroups } from "../../../helpers/groups";
import { useTenancy } from "../../../services/tenancy";
import { prettifyText } from "../../../helpers/clean";
const useStyles = makeStyles(() => ({
    chip: {
        height: "auto",
        paddingY: 2,
        "& .MuiChip-label": {
            paddingTop: 2,
            paddingBottom: 2,
            wordWrap: "break-word",
            whiteSpace: "normal",
            textOverflow: "clip",
            maxWidth: "100%",
        },
    },
}));
export default function TicketInformation({ ticket, status, modal, refreshTicketData }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const classes = useStyles();
    const display = () => {
        return (_jsxs(Fragment, { children: [ticket.chosen_steps
                    .sort((a, b) => Number(a.parent_step.id) - Number(b.parent_step.id))
                    .map(step => {
                    return (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: status === "table_view"
                            ? process.env.APP_ID === "mcd"
                                ? 12
                                : isMobile
                                    ? 4
                                    : 3
                            : 6 }, { children: [_jsx(Typography, Object.assign({ variant: status === "view" ? "overline" : "subtitle2" }, { children: step.parent_step.caption && step.parent_step.caption !== ""
                                    ? step.parent_step.caption
                                    : step.parent_step.name })), _jsx(Typography, Object.assign({ variant: status === "view" ? "subtitle1" : "body2" }, { children: step.chosen_step.name }))] }), step.chosen_step.id));
                }), _jsx(CustomFieldsView, { status: status, type: "information", information: ticket.information, ticket: ticket, refreshTicketData: refreshTicketData }), status === "view" && ticket.groups && ticket.groups.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("queues.queues") })), _jsx(Grid, Object.assign({ container: true, spacing: 1 }, { children: ticket.groups.map(group => {
                                let parents = [];
                                if (group.parent_groups) {
                                    parents = flatGroups(new Array(group.parent_groups), 0, parents).reverse();
                                }
                                return (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Box, Object.assign({ sx: {
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                        } }, { children: _jsx(Tooltip, Object.assign({ disableFocusListener: parents.length < 1, disableHoverListener: parents.length < 1, disableTouchListener: parents.length < 1, placement: "top", title: parents.map((parent, index) => index !== parents.length - 1
                                                ? parent.name + " › "
                                                : parent.name) }, { children: _jsx(Chip, { className: classes.chip, size: "small", variant: "outlined", label: prettifyText(group.name), onClick: () => {
                                                    navigate(tenancy.link("/campaigns/" +
                                                        ticket.campaign.id +
                                                        "/groups/" +
                                                        group.id));
                                                } }) })) })) }), group.id));
                            }) }))] })))] }));
    };
    return status === "view" ? (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                position: "sticky",
                                top: "100px",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(InformationIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.ticket-steps.information") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("tickets.ticket") +
                                                " " +
                                                t("tickets.ticket-steps.information").toLowerCase() }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: (ticket.information && ticket.information.length > 0) ||
                            (ticket.chosen_steps && ticket.chosen_steps.length > 0) ||
                            (ticket.groups && ticket.groups.length > 0) ? (_jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                    paddingX: 5,
                                    paddingY: 4,
                                    minHeight: "90px",
                                } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: display() })) })) })) : (_jsx(Box, Object.assign({ sx: {
                                paddingBottom: 2,
                            } }, { children: _jsx(Alert, Object.assign({ severity: "info" }, { children: t("tickets.ticket-information.no-information") })) }))) }))] })) })) })) : (_jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: display() })));
}
