import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
import { prettifyText } from "../../../helpers/clean";
export default function CustomFieldDelete({ type, customField, shared, step, setEditCustomField, setCustomField, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const deleteCustomField = () => {
        setIsDeleting(true);
        api.DELETE_customField(customField.id)
            .then(() => {
            setIsDeleting(false);
            alert.show(type !== "campaign_steps" || (type === "campaign_steps" && shared)
                ? (type === "contacts"
                    ? t("fields.contact-fields.contact-field")
                    : type === "tenant_users"
                        ? t("fields.user-fields.user-field")
                        : type === "tenant_groups"
                            ? t("fields.group-fields.group-field")
                            : type === "ticket_activities"
                                ? t("fields.activity-fields.activity-field")
                                : t("fields.shared-fields.form-fields.form-field")) +
                    " " +
                    t("fields.field-delete.deleted")
                : t("fields.field-delete.field-in-step") +
                    prettifyText(step.name) +
                    t("fields.field-delete.deleted"), "warning");
            if (setCustomField) {
                setCustomField(null);
                setEditCustomField(false);
            }
            else {
                navigate(tenancy.link("/settings/fields/" +
                    (type.startsWith("tenant") || type.startsWith("ticket")
                        ? type.slice(7)
                        : shared
                            ? "shared/forms"
                            : type)));
            }
        })
            .catch(error => {
            setIsDeleting(false);
            console.error(error.response);
            alert.show(error.response.data.message
                ? error.response.data.message
                : type !== "campaign_steps" || (type === "campaign_steps" && shared)
                    ? t("fields.field-delete.error-occured") +
                        (type === "contacts"
                            ? t("fields.contact-fields.contact-field")
                            : type === "tenant_users"
                                ? t("fields.user-fields.user-field")
                                : type === "tenant_groups"
                                    ? t("fields.group-fields.group-field")
                                    : type === "ticket_activities"
                                        ? t("fields.activity-fields.activity-field")
                                        : t("fields.shared-fields.form-fields.form-field")) +
                        "!"
                    : t("fields.field-delete.error-occured") +
                        t("fields.field-delete.field-in-step").toLowerCase() +
                        prettifyText(step.name), "error");
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 4,
                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isDeleting, onClick: () => {
                        setIsOpened(true);
                    }, startIcon: _jsx(DeleteIcon, {}), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("fields.field-delete.delete-field") })) })), _jsxs(Dialog, Object.assign({ open: isOpened, onClose: () => {
                    setIsOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: t("fields.field-delete.delete-confirm") }), _jsx(DialogContentText, { children: _jsx("em", { children: t("delete-component.confirm-caption") }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    deleteCustomField();
                                    setIsOpened(false);
                                } }, { children: t("delete-component.delete") }))] })] }))] }));
}
