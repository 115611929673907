import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
export default function InputCheckbox({ label, required, options, checkedValues, setCheckedValues, info, disabled, error, errors, isError, setIsError, }) {
    return (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "body1", gutterBottom: true }, { children: label })), _jsxs(FormControl, Object.assign({ required: required, fullWidth: true, variant: "standard", error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [options.map((option, index) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { color: "secondary", disabled: disabled ? disabled : false, checked: checkedValues.includes(option.value), onChange: () => {
                                setCheckedValues((checkedValues === null || checkedValues === void 0 ? void 0 : checkedValues.includes(option.value))
                                    ? checkedValues === null || checkedValues === void 0 ? void 0 : checkedValues.filter(value => value !== option.value)
                                    : [...(checkedValues !== null && checkedValues !== void 0 ? checkedValues : []), option.value]);
                                if (isError &&
                                    errors &&
                                    error &&
                                    setIsError &&
                                    (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                    setIsError(false);
                            } }), label: option.label }, index))), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
