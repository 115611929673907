import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import UserForm from "../../elements/users/user-form";
import { useRoles } from "../../../services/roles";
export default function AccountEdit() {
    const { t } = useTranslation();
    const roles = useRoles();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("users.account"), breadcrumbs: [
                    {
                        name: t("users.account"),
                        link: "/account",
                    },
                    {
                        name: t("users.edit-account"),
                        link: "/account/edit",
                    },
                ] }), roles.userTenant && (_jsx(UserForm, { type: "client", status: "account", user: roles.userTenant }))] }));
}
