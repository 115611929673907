import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../../services/auth";
export default function Nope({ title, caption, card }) {
    const auth = useAuth();
    return card ? (_jsx(Grid, Object.assign({ container: true, spacing: 4, direction: "row" }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, xl: 3 }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                        padding: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "187px",
                    } }, { children: [title && _jsx(Typography, Object.assign({ variant: "h4" }, { children: title })), caption && _jsx(Typography, { children: caption })] })) }) })) }))) : (_jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                padding: 5,
                margin: auth.isPublic ? 10 : 0,
                textAlign: "center",
            } }, { children: [title && _jsx(Typography, Object.assign({ variant: "h4" }, { children: title })), caption && _jsx(Typography, { children: caption })] })) }));
}
