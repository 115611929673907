import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import Table from "../../blocks/table";
import { useTenancy } from "../../../../services/tenancy";
import { useApiTenant } from "../../../../api/api-tenant";
import { prettifyText } from "../../../../helpers/clean";
export default function ChoiceFieldsTable() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const api = useApiTenant();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_campaignsChoiceFields(query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null)
                .then(response => {
                resolve({
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                    data: response.data.shared_choices,
                });
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    return (_jsx(Fragment, { children: _jsx(Table, { title: t("campaigns.campaign-steps.choice-fields.choice-fields-table.all-choice-fields"), actions: [
                {
                    icon: () => _jsx(ViewIcon, { color: "action" }),
                    tooltip: t("campaigns.campaign-steps.choice-fields.choice-fields-table.view-choice-field"),
                    onClick: (event, data) => {
                        navigate(tenancy.link("/settings/fields/shared/choices/" + data.id));
                    },
                },
                {
                    icon: () => _jsx(EditIcon, { color: "action" }),
                    tooltip: t("campaigns.campaign-steps.choice-fields.choice-fields-table.edit-choice-field"),
                    onClick: (event, data) => {
                        navigate(tenancy.link("/settings/fields/shared/choices/" + data.id + "/edit"));
                    },
                },
                {
                    icon: () => _jsx(RefreshIcon, { color: "action" }),
                    tooltip: t("campaigns.campaign-steps.choice-fields.choice-fields-table.refresh-choice-fields"),
                    isFreeAction: true,
                    onClick: () => refreshTableData(),
                },
            ], columns: isMobile
                ? [
                    {
                        title: t("fields.field") + " #",
                        field: "id",
                        defaultSort: "desc",
                    },
                ]
                : [
                    {
                        maxWidth: "80px",
                        title: "#",
                        field: "id",
                        type: "numeric",
                        defaultSort: "asc",
                    },
                    {
                        title: t("campaigns.campaign-steps.campaign-step-attributes.name"),
                        field: "name",
                        sorting: false,
                    },
                    {
                        title: t("details"),
                        sorting: false,
                        render: data => {
                            return (_jsxs(Fragment, { children: [data.filterable && (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("campaigns.campaign-steps.campaign-step-attributes.filterable") }) }))), data.has_metrics && (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("campaigns.campaign-steps.campaign-step-attributes.reportable") }) }))), data.not_editable && (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-attributes.not-editable") }) })))] }));
                        },
                    },
                ], detailPanel: isMobile
                ? (field) => {
                    return (_jsx(Box, Object.assign({ sx: {
                            paddingX: 2,
                            paddingY: 3,
                        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: prettifyText(field.name) }))] })), (field.filterable || field.has_metrics) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("details") })), field.filterable && (_jsx(Box, Object.assign({ sx: {
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("campaigns.campaign-steps.campaign-step-attributes.filterable") }) }))), field.has_metrics && (_jsx(Box, Object.assign({ sx: {
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("campaigns.campaign-steps.campaign-step-attributes.reportable") }) })))] })))] })) })));
                }
                : undefined, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }) }));
}
