import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import DescriptionIcon from "@mui/icons-material/Description";
import InputTextArea from "../inputs/input-textarea";
import InputEditor from "../inputs/input-editor";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
import InputSwitch from "../inputs/input-switch";
import Alert from "@mui/material/Alert";
export default function KnowledgeForm({ status, knowledge }) {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [title, setTitle] = useState((_a = knowledge === null || knowledge === void 0 ? void 0 : knowledge.title) !== null && _a !== void 0 ? _a : "");
    const [caption, setCaption] = useState((_b = knowledge === null || knowledge === void 0 ? void 0 : knowledge.caption) !== null && _b !== void 0 ? _b : "");
    const [content, setContent] = useState((_c = knowledge === null || knowledge === void 0 ? void 0 : knowledge.content) !== null && _c !== void 0 ? _c : "");
    const [isEmailTemplate, setIsEmailTemplate] = useState((_d = knowledge === null || knowledge === void 0 ? void 0 : knowledge.is_email_template) !== null && _d !== void 0 ? _d : false);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createKnowledge = () => {
        setIsSaving(true);
        setIsError(false);
        api.CREATE_knowledge(title, isEmailTemplate ? caption : "", content, isEmailTemplate)
            .then(response => {
            setIsSaving(false);
            alert.show(t("knowledge.knowledge-form.created"), "success");
            navigate(tenancy.link("/knowledge/" + response.data.knowledge.id));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("knowledge.knowledge-form.create-error"), "error");
        });
    };
    const updateKnowledge = () => {
        if (!knowledge)
            return;
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_knowledge(knowledge === null || knowledge === void 0 ? void 0 : knowledge.id, title, isEmailTemplate ? caption : "", content, isEmailTemplate)
            .then(() => {
            setIsSaving(false);
            alert.show(t("knowledge.knowledge-form.updated"), "success");
            navigate(tenancy.link("/knowledge/" + (knowledge === null || knowledge === void 0 ? void 0 : knowledge.id)));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("knowledge.knowledge-form.update-error"), "error");
        });
    };
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(DescriptionIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("knowledge.knowledge-form.knowledge-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, Object.assign({ component: "form" }, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputTextArea, { label: t("knowledge.knowledge-attributes.title"), required: true, value: title, setValue: setTitle, error: "title", errors: errors, isError: isError, setIsError: setIsError }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, sx: {
                                                alignContent: "center",
                                            } }, { children: _jsx(InputSwitch, { label: t("knowledge.knowledge-attributes.email-template"), required: false, checked: Boolean(isEmailTemplate), setChecked: () => setIsEmailTemplate(!isEmailTemplate) }) })), isEmailTemplate ? (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputTextArea, { label: t("knowledge.knowledge-attributes.caption"), required: Boolean(isEmailTemplate), value: caption, setValue: setCaption, error: "caption", errors: errors, isError: isError, setIsError: setIsError }) }))) : null, _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { value: content, setValue: setContent, error: "content", errors: errors, isError: isError, setIsError: setIsError }) })), isEmailTemplate ? (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Alert, Object.assign({ severity: "info" }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: t("notifications.notification-option-form.email-placeholders") })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::recipient_first_name::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::recipient_last_name::" }))] })) }))) : null, _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Divider, { light: true }), _jsx(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        paddingTop: 4,
                                                    } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !title ||
                                                            !content ||
                                                            (Boolean(isEmailTemplate) && !caption), onClick: () => status === "new"
                                                            ? createKnowledge()
                                                            : updateKnowledge(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                                            ? t("form-component.create")
                                                            : t("form-component.update") })) }))] }))] })) })) })) }))] })) })) }));
}
