import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { useAlert } from "../../../../services/alert";
import { copy } from "../../../../helpers/copy";
export default function ContactOibColumn({ oib }) {
    const { t } = useTranslation();
    const alert = useAlert();
    return (_jsx(Fragment, { children: oib && (_jsx(Chip, { variant: "outlined", size: "small", label: oib, onDelete: () => {
                copy(oib);
                alert.show(t("contacts.oib-copied"), "info");
            }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                // @ts-ignore
                title: t("copy-oib") }, { children: _jsx(CopyIcon, {}) })) })) }));
}
