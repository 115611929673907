import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import randomMC from "random-material-color";
import { Grid } from "@mui/material";
import MetricPartition from "../metrics/metric-partition";
import { useApiTenant } from "../../../api/api-tenant";
import useOnScreen from "../../../helpers/on-screen";
export default function InformationFieldsMetricsCard({ type, group_id, campaign, information_type, globalRange, setGlobalRange, globalStartDate, globalStartTime, globalEndDate, globalEndTime, isChosenGlobalCustomRange, metricsCustomField, customFieldsData, setCustomFieldsData, }) {
    var _a, _b;
    const api = useApiTenant();
    const ref = useRef(null);
    const onScreen = useOnScreen(ref, "-100px");
    const [wasOnScreen, setWasOnScreen] = useState(false);
    const [ticketCustomFieldsRange, setTicketCustomFieldsRange] = useState("total");
    const [, setTicketCustomFieldsLoading] = useState(false);
    const [ticketsCustomFieldsMetricsRefresh, setTicketsCustomFieldsMetricsRefresh] = useState(false);
    const [chosenCustomField, setChosenCustomField] = useState("");
    const [, setFieldSavedCustomRangeValues] = useState(false);
    const [fieldStartDate, setFieldStartDate] = useState("");
    const [fieldEndDate, setFieldEndDate] = useState("");
    const [fieldStartTime, setFieldStartTime] = useState("");
    const [fieldEndTime, setFieldEndTime] = useState("");
    const [resetDateTimeValues, setResetDateTimeValues] = useState(false);
    const [isChosenCustomRange, setIsChosenCustomRange] = useState(false);
    const [chosenStatuses, setChosenStatuses] = useState([]);
    const [chosenPriorities, setChosenPriorities] = useState([]);
    const getCustomFieldsMetrics = (customFieldsData, customFieldId, invalidateCache, range, globalStartDate, globalStartTime, globalEndDate, globalEndTime, chosenStatuses, chosenPriorities) => {
        let field = customFieldsData.find(f => f.customFieldId === customFieldId);
        field.loading = true;
        let filteredCustomFields = customFieldsData.filter(f => f.customFieldId !== customFieldId);
        setCustomFieldsData([...filteredCustomFields, field]);
        let startTime;
        let endTime;
        if (range === "custom" && fieldStartDate !== "" && fieldEndDate !== "") {
            if (fieldStartTime !== "") {
                startTime =
                    format(new Date(fieldStartDate), "yyyy-MM-dd") +
                        " " +
                        format(new Date(fieldStartTime), "HH:mm:ss");
            }
            else {
                startTime = format(new Date(fieldStartDate), "yyyy-MM-dd") + " 00:00:00";
            }
            if (fieldEndTime !== "") {
                endTime =
                    format(new Date(fieldEndDate), "yyyy-MM-dd") +
                        " " +
                        format(new Date(fieldEndTime), "HH:mm") +
                        ":59";
            }
            else {
                endTime = format(new Date(fieldEndDate), "yyyy-MM-dd") + " 23:59:59";
            }
        }
        if (range === "custom" && globalStartDate && globalEndDate) {
            if (globalStartTime !== "") {
                startTime =
                    format(new Date(globalStartDate), "yyyy-MM-dd") +
                        " " +
                        format(new Date(globalStartTime), "HH:mm:ss");
            }
            else {
                startTime = format(new Date(globalStartDate), "yyyy-MM-dd") + " 00:00:00";
            }
            if (globalEndTime !== "") {
                endTime =
                    format(new Date(globalEndDate), "yyyy-MM-dd") +
                        " " +
                        format(new Date(globalEndTime), "HH:mm:") +
                        ":59";
            }
            else {
                endTime = format(new Date(globalEndDate), "yyyy-MM-dd") + " 23:59:59";
            }
        }
        api.GET_metrics("partition", "tickets", range, field.countBy, field.customFieldId, type === "campaign"
            ? "campaign,is_draft"
            : type === "campaign_group"
                ? "campaign,is_draft,groups"
                : "is_draft", type === "campaign"
            ? campaign.id + ",0"
            : type === "campaign_group"
                ? campaign.id + ",0," + group_id
                : "0", type === "campaign_group" ? 1 : undefined, invalidateCache ? 1 : undefined, range === "custom" ? startTime : undefined, range === "custom" ? endTime : undefined, undefined, undefined, campaign && chosenStatuses && chosenStatuses.length > 0
            ? chosenStatuses.map(i => i.id)
            : undefined, campaign && chosenPriorities && chosenPriorities.length > 0
            ? chosenPriorities.map(i => i.id)
            : undefined)
            .then(response => {
            let filteredCustomFields = customFieldsData.filter(f => f.customFieldId !== customFieldId);
            let cf = customFieldsData.find(f => f.customFieldId === customFieldId);
            if (cf) {
                cf.data = [];
                response.data.data.map(d => {
                    let color = hex2rgb(randomMC.getColor({ text: d.label }));
                    cf.data.push({
                        label: d.label,
                        count: d.count,
                        color: color,
                    });
                });
                cf.data = cf.data
                    .sort(function (a, b) {
                    return a.count - b.count;
                })
                    .reverse();
                cf.cachedAt = response.data.cached_at;
                cf.range = range;
                cf.loading = false;
                cf.isError = false;
                setCustomFieldsData([...filteredCustomFields, cf]);
                setFieldSavedCustomRangeValues(false);
                if (isChosenCustomRange)
                    setIsChosenCustomRange(false);
            }
        })
            .catch(error => {
            let filteredCustomFields = customFieldsData.filter(f => f.customFieldId !== customFieldId);
            let cf = customFieldsData.find(f => f.customFieldId === customFieldId);
            if (cf) {
                cf.data = [];
                cf.range = range;
                cf.loading = false;
                cf.isError = true;
                setCustomFieldsData([...filteredCustomFields, cf]);
            }
            if (!api.isCancel(error)) {
                console.error(error);
            }
        });
    };
    const hex2rgb = hex => {
        return (
        // @ts-ignore
        (("0x" + hex[1] + hex[2]) | 0) +
            "," +
            // @ts-ignore
            (("0x" + hex[3] + hex[4]) | 0) +
            "," +
            // @ts-ignore
            (("0x" + hex[5] + hex[6]) | 0));
    };
    useEffect(() => {
        if (onScreen && !wasOnScreen)
            setWasOnScreen(true);
    }, [onScreen]);
    useEffect(() => {
        if (!wasOnScreen)
            return;
        getCustomFieldsMetrics(customFieldsData, metricsCustomField.count_by_value.toString(), undefined, ticketCustomFieldsRange, undefined, undefined, undefined, undefined, undefined, undefined);
    }, [wasOnScreen]);
    useEffect(() => {
        let field = customFieldsData.find(f => f.customFieldId === chosenCustomField);
        if (field) {
            setFieldStartDate(field.start_date);
            setFieldEndDate(field.end_date);
            setFieldStartTime(field.start_time);
            setFieldEndTime(field.end_time);
        }
        setFieldSavedCustomRangeValues(false);
    }, [chosenCustomField]);
    useEffect(() => {
        if (!resetDateTimeValues)
            return;
        let field = customFieldsData.find(f => f.customFieldId === chosenCustomField);
        if (field) {
            setFieldStartDate(field.start_date);
            setFieldEndDate(field.end_date);
            setFieldStartTime(field.start_time);
            setFieldEndTime(field.end_time);
        }
        setResetDateTimeValues(false);
    }, [resetDateTimeValues]);
    useEffect(() => {
        if (globalStartDate && globalEndDate)
            return;
        let field = customFieldsData.find(f => f.customFieldId === chosenCustomField);
        let filteredFields = customFieldsData.filter(f => f.customFieldId !== chosenCustomField);
        if (field) {
            field.start_date = fieldStartDate;
            field.end_date = fieldEndDate;
            field.start_time = fieldStartTime;
            field.end_time = fieldEndTime;
            setCustomFieldsData([...filteredFields, field]);
        }
    }, [fieldStartDate, fieldEndDate, fieldStartTime, fieldEndTime]);
    useEffect(() => {
        if (globalRange &&
            setGlobalRange &&
            globalRange !== "none" &&
            (globalStartDate !== fieldStartDate ||
                globalStartTime !== fieldStartTime ||
                globalEndDate !== fieldEndDate ||
                globalEndTime !== fieldEndDate)) {
            setGlobalRange("none");
        }
        if (isChosenCustomRange && fieldStartDate !== "" && fieldEndDate !== "") {
            getCustomFieldsMetrics(customFieldsData, chosenCustomField, undefined, ticketCustomFieldsRange, undefined, undefined, undefined, undefined, chosenStatuses, chosenPriorities);
        }
    }, [isChosenCustomRange]);
    useEffect(() => {
        if (customFieldsData.length === 0 || !chosenCustomField)
            return;
        if (setGlobalRange && globalRange !== "none")
            setGlobalRange("none");
        if (ticketCustomFieldsRange !== "custom") {
            if (fieldStartDate !== "")
                setFieldStartDate("");
            if (fieldEndDate !== "")
                setFieldEndDate("");
            if (fieldStartTime !== "")
                setFieldStartTime("");
            if (fieldEndTime !== "")
                setFieldEndTime("");
            getCustomFieldsMetrics(customFieldsData, chosenCustomField, undefined, ticketCustomFieldsRange, undefined, undefined, undefined, undefined, chosenStatuses, chosenPriorities);
        }
        else {
            let field = customFieldsData.find(f => f.customFieldId === chosenCustomField);
            let filteredFields = customFieldsData.filter(f => f.customFieldId !== chosenCustomField);
            if (field) {
                field.range = ticketCustomFieldsRange;
                setCustomFieldsData([...filteredFields, field]);
            }
        }
    }, [ticketCustomFieldsRange, chosenCustomField]);
    useEffect(() => {
        if (customFieldsData.length === 0 || !chosenCustomField)
            return;
        getCustomFieldsMetrics(customFieldsData, chosenCustomField, undefined, ticketCustomFieldsRange, globalStartDate, globalStartTime, globalEndDate, globalEndTime, chosenStatuses, chosenPriorities);
    }, [chosenStatuses.length, chosenPriorities.length]);
    useEffect(() => {
        if (customFieldsData.length === 0 || !chosenCustomField)
            return;
        getCustomFieldsMetrics(customFieldsData, chosenCustomField, true, ticketCustomFieldsRange, undefined, undefined, undefined, undefined, chosenStatuses, chosenPriorities);
        setTicketsCustomFieldsMetricsRefresh(false);
        setChosenCustomField("");
    }, [ticketsCustomFieldsMetricsRefresh]);
    useEffect(() => {
        if (globalRange && globalRange !== "none" && globalRange !== "custom") {
            if (ticketCustomFieldsRange !== globalRange) {
                setChosenCustomField("");
                setTicketCustomFieldsRange(globalRange);
                let data = [...customFieldsData];
                let newData = [];
                data.map(d => {
                    newData.push({
                        customFieldId: d.customFieldId,
                        countBy: d.countBy,
                        data: d.data,
                        range: globalRange,
                        start_date: globalStartDate ? globalStartDate : "",
                        end_date: globalEndDate ? globalEndDate : "",
                        start_time: globalStartTime ? globalStartTime : "",
                        end_time: globalEndTime ? globalEndTime : "",
                        cachedAt: d.cachedAt,
                        loading: d.loading,
                        isError: d.isError,
                    });
                });
                setCustomFieldsData(newData);
                if (wasOnScreen)
                    setWasOnScreen(false);
            }
        }
    }, [globalRange]);
    useEffect(() => {
        if (isChosenGlobalCustomRange &&
            globalRange &&
            globalRange === "custom" &&
            globalStartDate &&
            globalStartDate !== "" &&
            globalEndDate &&
            globalEndDate !== "") {
            if (wasOnScreen)
                setWasOnScreen(false);
            setChosenCustomField("");
            setTicketCustomFieldsRange(globalRange);
            setFieldStartTime(globalStartTime ? globalStartTime : "");
            setFieldStartDate(globalStartDate);
            setFieldEndDate(globalEndDate);
            setFieldEndTime(globalEndTime ? globalEndTime : "");
            let data = [...customFieldsData];
            let newData = [];
            data.map(d => {
                newData.push({
                    customFieldId: d.customFieldId,
                    countBy: d.countBy,
                    data: d.data,
                    range: globalRange,
                    start_date: globalStartDate ? globalStartDate : "",
                    end_date: globalEndDate ? globalEndDate : "",
                    start_time: globalStartTime ? globalStartTime : "",
                    end_time: globalEndTime ? globalEndTime : "",
                    cachedAt: d.cachedAt,
                    loading: d.loading,
                    isError: d.isError,
                });
            });
            setCustomFieldsData(newData);
        }
    }, [isChosenGlobalCustomRange]);
    return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, xl: 4, ref: ref }, { children: _jsx(MetricPartition, { title: information_type === "field"
                ? metricsCustomField.custom_field.field_name
                : metricsCustomField.campaign_step.name, metricData: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).data
                    : []
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).data
                    : [], range: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId == metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ==
                        metricsCustomField.custom_field.id.toString()).range
                    : "total"
                : customFieldsData.find(f => f.customFieldId == metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId == metricsCustomField.campaign_step.id.toString()).range
                    : "total", setRange: setTicketCustomFieldsRange, isLoading: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).loading
                    : false
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).loading
                    : false, setIsLoading: setTicketCustomFieldsLoading, cachedAt: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).cachedAt
                    : ""
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).cachedAt
                    : "", refresh: ticketsCustomFieldsMetricsRefresh, setRefresh: setTicketsCustomFieldsMetricsRefresh, customFieldId: information_type === "field"
                ? metricsCustomField.custom_field
                    ? metricsCustomField.custom_field.id.toString()
                    : ""
                : metricsCustomField.campaign_step
                    ? metricsCustomField.campaign_step.id.toString()
                    : "", setCustomFieldRefresh: setChosenCustomField, startDate: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).start_date
                    : ""
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).start_date
                    : "", setStartDate: setFieldStartDate, endDate: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).end_date
                    : ""
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).end_date
                    : "", setEndDate: setFieldEndDate, startTime: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).start_time
                    : ""
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).start_time
                    : "", setStartTime: setFieldStartTime, endTime: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).end_time
                    : ""
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).end_time
                    : "", setEndTime: setFieldEndTime, setIsChosenCustomRange: setIsChosenCustomRange, metricType: "information", isError: information_type === "field"
                ? customFieldsData.find(f => f.customFieldId === metricsCustomField.custom_field.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.custom_field.id.toString()).isError
                    : false
                : customFieldsData.find(f => f.customFieldId ===
                    metricsCustomField.campaign_step.id.toString())
                    ? customFieldsData.find(f => f.customFieldId ===
                        metricsCustomField.campaign_step.id.toString()).isError
                    : false, statuses: campaign === null || campaign === void 0 ? void 0 : campaign.ticket_statuses, priorities: campaign === null || campaign === void 0 ? void 0 : campaign.ticket_priorities, chosenStatuses: chosenStatuses, setChosenStatuses: setChosenStatuses, chosenPriorities: chosenPriorities, setChosenPriorities: setChosenPriorities }, information_type === "field"
            ? (_a = metricsCustomField.custom_field) === null || _a === void 0 ? void 0 : _a.id
            : (_b = metricsCustomField.campaign_step) === null || _b === void 0 ? void 0 : _b.id) })));
}
