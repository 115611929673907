import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ContactIcon from "@mui/icons-material/Person";
import InformationIcon from "@mui/icons-material/Info";
import DetailIcon from "@mui/icons-material/Assignment";
import ActivityIcon from "@mui/icons-material/Update";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
export default function TicketSteps({ step, campaign, ticket }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const [activeStep, setActiveStep] = useState(0);
    const [completedStep, setCompletedStep] = useState(0);
    const onStepNavigation = (index) => {
        if (!ticket)
            return;
        let nextStep;
        if (process.env.APP_ID === "mcd" && roles.isAgent) {
            nextStep = index === 0 ? "information" : index === 1 ? "details" : null;
        }
        else if (campaign.has_contact_fields) {
            if (campaign.has_steps) {
                if (campaign.has_activity_fields) {
                    nextStep =
                        index === 0
                            ? "contact"
                            : index === 1
                                ? "information"
                                : index === 2
                                    ? "details"
                                    : index === 3
                                        ? "activity"
                                        : null;
                }
                else {
                    nextStep =
                        index === 0
                            ? "contact"
                            : index === 1
                                ? "information"
                                : index === 2
                                    ? "details"
                                    : null;
                }
            }
            else if (campaign.has_activity_fields) {
                nextStep =
                    index === 0
                        ? "contact"
                        : index === 1
                            ? "details"
                            : index === 2
                                ? "activity"
                                : null;
            }
            else {
                nextStep = index === 0 ? "contact" : index === 1 ? "details" : null;
            }
        }
        else {
            if (campaign.has_steps) {
                if (campaign.has_activity_fields) {
                    nextStep =
                        index === 0
                            ? "information"
                            : index === 1
                                ? "details"
                                : index === 2
                                    ? "activity"
                                    : null;
                }
                else {
                    nextStep = index === 0 ? "information" : index === 1 ? "details" : null;
                }
            }
            else if (campaign.has_activity_fields) {
                nextStep = index === 0 ? "details" : index === 1 ? "activity" : null;
            }
            else {
                nextStep = index === 0 ? "details" : null;
            }
        }
        navigate(tenancy.link("/tickets/" + ticket.id + "/" + nextStep));
    };
    const steps = process.env.APP_ID === "mcd" && roles.isAgent
        ? [
            ...(campaign.has_steps
                ? [
                    {
                        icon: _jsx(InformationIcon, {}),
                        label: t("tickets.ticket-steps.information"),
                    },
                ]
                : []),
            {
                icon: _jsx(DetailIcon, {}),
                label: t("tickets.ticket-steps.details"),
            },
        ]
        : [
            ...(campaign.has_contact_fields
                ? [
                    {
                        icon: _jsx(ContactIcon, {}),
                        label: t("tickets.ticket-steps.contact"),
                    },
                ]
                : []),
            ...(campaign.has_steps
                ? [
                    {
                        icon: _jsx(InformationIcon, {}),
                        label: t("tickets.ticket-steps.information"),
                    },
                ]
                : []),
            {
                icon: _jsx(DetailIcon, {}),
                label: t("tickets.ticket-steps.details"),
            },
            ...(campaign.has_activity_fields
                ? [
                    {
                        icon: _jsx(ActivityIcon, {}),
                        label: t("tickets.ticket-steps.activity"),
                    },
                ]
                : []),
        ];
    useEffect(() => {
        if (!ticket)
            return;
        if (process.env.APP_ID === "mcd" && roles.isAgent) {
            setActiveStep(step === "information" ? 0 : step === "detail" ? 1 : -1);
        }
        else if (campaign.has_contact_fields) {
            if (campaign.has_steps) {
                setActiveStep(step === "contact"
                    ? 0
                    : step === "information"
                        ? 1
                        : step === "detail"
                            ? 2
                            : step === "activity"
                                ? 3
                                : -1);
            }
            else {
                setActiveStep(step === "contact" ? 0 : step === "detail" ? 1 : step === "activity" ? 2 : -1);
            }
        }
        else {
            if (campaign.has_steps) {
                setActiveStep(step === "information"
                    ? 0
                    : step === "detail"
                        ? 1
                        : step === "activity"
                            ? 2
                            : -1);
            }
            else {
                setActiveStep(step === "detail" ? 0 : step === "activity" ? 1 : -1);
            }
        }
        if (ticket.completed_step === "contact")
            setCompletedStep(0);
        else if (ticket.completed_step === "information")
            setCompletedStep(1);
        else if (ticket.completed_step === "detail")
            setCompletedStep(2);
        else if (ticket.completed_step === "activity")
            setCompletedStep(3);
    }, [ticket]);
    return (_jsx(Box, Object.assign({ sx: {
            marginTop: 2,
            marginBottom: 6,
        } }, { children: _jsx(Grid, Object.assign({ container: true }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Stepper, Object.assign({ nonLinear: false, activeStep: activeStep, alternativeLabel: true, connector: _jsx(StyledStepConnector, {}), style: { backgroundColor: "transparent" } }, { children: steps.map((step, index) => {
                        return (_jsx(Step, Object.assign({ completed: index <= completedStep, active: index === activeStep, onClick: () => {
                                if (!ticket ||
                                    index === activeStep ||
                                    index > completedStep)
                                    return;
                                onStepNavigation(index);
                            } }, { children: _jsx(StepLabel, Object.assign({ StepIconComponent: StepIcon, StepIconProps: {
                                    icon: step.icon,
                                } }, { children: step.label })) }), index));
                    }) })) })) })) })));
}
const StyledStepConnector = withStyles(theme => createStyles({
    root: {
        backgroundColor: theme.palette.mode === "light" ? "#ccc" : "#757575",
        height: 3,
    },
    line: {
        border: 0,
    },
    alternativeLabel: {
        top: 22,
    },
    completed: {
        "&": {
            backgroundColor: theme.palette.primary.light,
        },
    },
}))(StepConnector);
const useStepIconStyles = makeStyles((theme) => createStyles({
    root: {
        backgroundColor: theme.palette.mode === "light" ? "#ccc" : "#757575",
        color: theme.palette.background.paper,
        width: 50,
        height: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        zIndex: 1,
    },
    active: {
        backgroundColor: theme.palette.secondary.main + " !important;",
        cursor: "default !important;",
    },
    completed: {
        backgroundColor: theme.palette.primary.light,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper,
        },
        cursor: "pointer",
    },
}));
function StepIcon(props) {
    const styles = useStepIconStyles();
    return (_jsx(Box, Object.assign({ className: clsx(styles.root, {
            [styles.active]: props.active,
            [styles.completed]: props.completed,
        }) }, { children: props.icon })));
}
