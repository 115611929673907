import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const en = require("../assets/" + process.env.APP_ID + "/en.json");
const hr = require("../assets/" + process.env.APP_ID + "/hr.json");
i18n.use(initReactI18next)
    .init({
    interpolation: { escapeValue: false },
    fallbackLng: "en",
    defaultNS: "firefly",
    resources: {
        en: {
            firefly: en,
        },
        hr: {
            firefly: hr,
        },
    },
})
    .then(() => { });
export default i18n;
