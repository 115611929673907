import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useApiTenant } from "../../../../api/api-tenant";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useAlert } from "../../../../services/alert";
import DisplayOptionDelete from "./display-option-delete";
import { debounce } from "lodash";
import { prettifyCampaignDisplayOption } from "../../../../helpers/clean";
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Hidden from "@mui/material/Hidden";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Autocomplete from "@mui/material/Autocomplete";
export default function DisplayOptions({ role, options, refetchData, campaign }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    const [reordableDisplayOptions, setReordableDisplayOptions] = useState(options);
    const [deleteDisplayOptionModalOpen, setDeleteDisplayOptionModalOpen] = useState(false);
    const [deleteDisplayOptionUid, setDeleteDisplayOptionUid] = useState("");
    const [tableAttributes, setTableAttributes] = useState([]);
    const [isTableAttributesLoading, setIsTableAttributesLoading] = useState(false);
    const [searchTableAttributes, setSearchTableAttributes] = useState("");
    useEffect(() => {
        setReordableDisplayOptions(options);
    }, [options]);
    useEffect(() => {
        setTableAttributes([]);
        if (searchTableAttributes.length === 0)
            return;
        setIsTableAttributesLoading(true);
        debounceSearchContacts(searchTableAttributes);
    }, [searchTableAttributes]);
    const debounceSearchContacts = useCallback(debounce(searchTableAttributes => {
        getDisplayOptions(searchTableAttributes);
    }, 700), []);
    const getDisplayOptions = (displayOption) => {
        api.GET_displayOptions(displayOption, campaign ? "campaign-ticket" : "dashboard-ticket", campaign === null || campaign === void 0 ? void 0 : campaign.id)
            .then(response => {
            var _a, _b, _c;
            let fetchedTableAttributes = role === "admin"
                ? (_a = response.data.admin_column_display_options) !== null && _a !== void 0 ? _a : response.data.column_display_options
                : role === "manager"
                    ? (_b = response.data.manager_column_display_options) !== null && _b !== void 0 ? _b : response.data.column_display_options
                    : (_c = response.data.agent_column_display_options) !== null && _c !== void 0 ? _c : response.data.column_display_options;
            fetchedTableAttributes = fetchedTableAttributes.filter(attribute => !reordableDisplayOptions.some(displayOption => displayOption.uid === attribute.uid));
            setTableAttributes(fetchedTableAttributes);
            setIsTableAttributesLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsTableAttributesLoading(false);
            }
        });
    };
    const handleReordableDisplayOptions = result => {
        if (!result.destination)
            return;
        const fields = Array.from(reordableDisplayOptions);
        const [reorderedFields] = fields.splice(result.source.index, 1);
        fields.splice(result.destination.index, 0, reorderedFields);
        setReordableDisplayOptions(fields);
    };
    const saveReordableDisplayOptions = () => {
        setIsLoading(true);
        const type = campaign ? "campaign-ticket" : "dashboard-ticket";
        reordableDisplayOptions.map((field, index) => {
            field.order = index + 1;
            field.role = role;
            field.type = type;
        });
        api.UPDATE_displayOptions(reordableDisplayOptions, role, campaign === null || campaign === void 0 ? void 0 : campaign.id, type)
            .then(() => {
            refetchData();
            alert.show(t("campaigns.table-columns.success"));
        })
            .catch(error => {
            console.error(error);
            alert.show(t("campaigns.table-columns.error"));
        })
            .finally(() => {
            if (campaign)
                setIsLoading(false);
        });
    };
    const handleAddDisplayOption = (displayOption) => {
        const fields = Array.from(reordableDisplayOptions);
        fields.push(displayOption);
        setReordableDisplayOptions(fields);
    };
    return (_jsxs(Fragment, { children: [_jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                        paddingX: 2,
                        paddingY: 4,
                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: { paddingX: 2 } }, { children: _jsx(Autocomplete, { options: tableAttributes, renderInput: params => (_createElement(TextField, Object.assign({}, params, { label: t("campaigns.table-columns.search"), fullWidth: true, key: params.id, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsx(InputAdornment, Object.assign({ position: "end", style: {
                                                        position: "absolute",
                                                        right: "16px",
                                                    } }, { children: searchTableAttributes.length > 0 && (_jsx(Fragment, { children: isTableAttributesLoading && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                                setSearchTableAttributes("");
                                                            }, size: "large" }, { children: _jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5 }) }))) })) }))) }) }))), getOptionLabel: () => "", filterOptions: options => {
                                            return options.filter(option => !reordableDisplayOptions.some(displayOption => displayOption.uid === option.uid));
                                        }, isOptionEqualToValue: (option, value) => {
                                            return option.uid === value.uid;
                                        }, onChange: (event, value) => {
                                            value && handleAddDisplayOption(value);
                                        }, onInputChange: (event, value) => setSearchTableAttributes(value), renderOption: (props, attribute) => (_createElement("li", Object.assign({}, props, { key: attribute.uid }),
                                            _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: _jsx(Box, Object.assign({ sx: { display: "flex" } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: prettifyCampaignDisplayOption(attribute, t, true, !campaign) })) })) }), attribute.uid))) }) })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(DragDropContext, Object.assign({ onDragEnd: handleReordableDisplayOptions }, { children: _jsx(Droppable, Object.assign({ droppableId: "list" }, { children: provided => (_jsxs(List, Object.assign({}, provided.droppableProps, { ref: provided.innerRef, disablePadding: true, sx: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: [reordableDisplayOptions.map((field, index) => {
                                                        return (_jsx(Draggable, Object.assign({ draggableId: field.uid, index: index }, { children: provided => (_jsx(ListItem, Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { secondaryAction: _jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                                        setDeleteDisplayOptionModalOpen(true);
                                                                        setDeleteDisplayOptionUid(field.uid);
                                                                    }, style: {
                                                                        color: "rgb(244, 67, 54)",
                                                                    } }, { children: _jsx(RemoveCircleIcon, {}) })), button: true }, { children: _jsx(ListItemText, { primary: prettifyCampaignDisplayOption(field, t, true, !campaign) }) }))) }), field.uid));
                                                    }), provided.placeholder] }))) })) })), _jsx(Box, Object.assign({ sx: {
                                            marginTop: 2,
                                            marginLeft: 2,
                                        } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.table-columns.reorder") })) }))] }))] })) })) }), _jsx(Box, Object.assign({ sx: {
                    paddingTop: 4,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "flex-end",
                                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isLoading, onClick: () => saveReordableDisplayOptions(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("campaigns.table-columns.update") })) })) }))] })) })), _jsx(DisplayOptionDelete, { displayOptionUidToDelete: deleteDisplayOptionUid, setDeleteOptionUidToDelete: setDeleteDisplayOptionUid, deleteDisplayOptionModalOpen: deleteDisplayOptionModalOpen, closeDeleteDisplayOptionModal: () => setDeleteDisplayOptionModalOpen(false), reordableDisplayOptions: reordableDisplayOptions, setReordableDisplayOptions: setReordableDisplayOptions })] }));
}
