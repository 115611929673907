import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import Navigation from "./navigation";
import { colors } from "../../../helpers/colors";
const logo = require("../../../assets/" + process.env.APP_ID + "/logo.svg");
const logoDark = require("../../../assets/" + process.env.APP_ID + "/logo-dark.svg");
const useStyles = makeStyles((theme) => createStyles({
    offset: theme.mixins.toolbar,
}));
export default function Sidebar() {
    const location = useLocation();
    const classes = useStyles();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const isDesktop = useMediaQuery("(min-width: 1200px)");
    const [isMenuOpen, setMenuOpen] = useState(isDesktop);
    useEffect(() => {
        if (isDesktop)
            return;
        setMenuOpen(false);
    }, [location]);
    return (_jsxs(Fragment, { children: [_jsx(Hidden, Object.assign({ lgUp: true }, { children: _jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "160px",
                    } }, { children: _jsx(Button, Object.assign({ color: "inherit", size: "large", startIcon: _jsx(MenuIcon, {}), onClick: () => setMenuOpen(true), style: {
                            marginLeft: "-8px",
                        } }, { children: "MENU" })) })) })), _jsxs(Drawer, Object.assign({ open: isMenuOpen, onClose: () => setMenuOpen(false), sx: { zIndex: !isDesktop ? 1300 : "auto" }, variant: isDesktop ? "permanent" : "temporary", PaperProps: {
                    sx: Object.assign({}, (process.env.APP_ID === "mcd" &&
                        prefs.theme === "light" && {
                        backgroundColor: colors.primary.dark,
                    })),
                } }, { children: [_jsx(Hidden, Object.assign({ lgDown: true }, { children: _jsx("div", { className: classes.offset }) })), _jsxs(Box, Object.assign({ component: "nav", sx: {
                            width: "280px",
                            height: "100vh",
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        } }, { children: [_jsxs(Box, { children: [process.env.APP_ID !== "mcd" && (_jsx(Hidden, Object.assign({ lgUp: true }, { children: _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                                alignItems: "center",
                                                height: "70px",
                                                padding: 2,
                                            } }, { children: tenancy.client ? (_jsx(Fragment, { children: tenancy.client.logo_url ? (_jsx("img", { src: prefs.theme === "light"
                                                        ? tenancy.client.logo_url
                                                        : tenancy.client.logo_dark_url
                                                            ? tenancy.client.logo_dark_url
                                                            : tenancy.client.logo_url, style: {
                                                        maxHeight: "50px",
                                                        maxWidth: "200px",
                                                        objectFit: "contain",
                                                    }, alt: "Logo" })) : (_jsx(Typography, Object.assign({ variant: "h6" }, { children: tenancy.client.name }))) })) : (_jsx("img", { src: prefs.theme === "light" ? logo : logoDark, style: {
                                                    maxHeight: "50px",
                                                    maxWidth: "200px",
                                                    objectFit: "contain",
                                                }, alt: "Logo" })) })) }))), _jsx(Box, Object.assign({ sx: {
                                            paddingTop: process.env.APP_ID === "mcd" ? 0 : isDesktop ? 0 : 2,
                                            paddingBottom: isDesktop ? 0 : 2,
                                        } }, { children: _jsx(Navigation, {}) }))] }), _jsx(Box, Object.assign({ sx: {
                                    padding: 1,
                                    textAlign: "center",
                                } }, { children: _jsxs(Typography, Object.assign({ color: "textSecondary" }, { children: [_jsx(Link, Object.assign({ href: process.env.APP_LINK, color: "inherit", underline: "hover" }, { children: process.env.APP_NAME })), _jsxs(Box, Object.assign({ component: "span", sx: {
                                                paddingLeft: 1,
                                            } }, { children: ["\u00A9 ", new Date().getFullYear()] }))] })) }))] }))] }))] }));
}
