import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
export default function InputText({ label, required, value, setValue, caption, info, pattern, disabled, error, errors, isError, setIsError, }) {
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: caption }) }))), _jsxs(FormControl, Object.assign({ disabled: disabled ? disabled : false, fullWidth: true, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(InputLabel, { children: label }), _jsx(OutlinedInput, { disabled: disabled ? disabled : false, label: label, required: required, value: value, autoComplete: "new-password", type: "text", inputProps: {
                            pattern: pattern,
                        }, onChange: event => {
                            setValue(event.target.value);
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } }), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
