import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FieldsIcon from "@mui/icons-material/Input";
import CustomFieldPreview from "./custom-field-preview";
import { prettifyText } from "../../../helpers/clean";
export default function CustomFieldDetails({ type, customField, shared }) {
    const { t } = useTranslation();
    const details = () => {
        var _a;
        return (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.name") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: prettifyText(customField.field_name) }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.type") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.field_type === "text"
                                ? t("fields.field-form.field-types.text")
                                : customField.field_type === "text_area"
                                    ? t("fields.field-form.field-types.text-area")
                                    : customField.field_type === "number"
                                        ? t("fields.field-form.field-types.number")
                                        : customField.field_type === "date_time"
                                            ? t("fields.field-form.field-types.date-time")
                                            : customField.field_type === "text_select"
                                                ? t("fields.field-form.field-types.text-select")
                                                : customField.field_type === "checkbox"
                                                    ? t("fields.field-form.field-types.checkbox")
                                                    : customField.field_type === "radio_text"
                                                        ? t("fields.field-form.field-types.radio-text")
                                                        : customField.field_type === "radio_icon"
                                                            ? t("fields.field-form.field-types.radio-icon")
                                                            : customField.field_type === "radio_image"
                                                                ? t("fields.field-form.field-types.radio-image")
                                                                : customField.field_type === "switch"
                                                                    ? t("fields.field-form.field-types.switch")
                                                                    : customField.field_type === "html_text"
                                                                        ? t("fields.field-form.field-types.html-text")
                                                                        : customField.field_type === "file"
                                                                            ? t("fields.field-form.field-types.file")
                                                                            : customField.field_type === "rating"
                                                                                ? t("fields.field-form.field-types.rating")
                                                                                : customField.field_type === "slider"
                                                                                    ? t("fields.field-form.field-types.slider")
                                                                                    : customField.field_type === "range"
                                                                                        ? t("fields.field-form.field-types.range")
                                                                                        : customField.field_type === "address"
                                                                                            ? t("fields.field-form.field-types.address")
                                                                                            : customField.field_type === "link"
                                                                                                ? t("fields.field-form.field-types.link")
                                                                                                : customField.field_type === "mutable_number"
                                                                                                    ? t("fields.field-form.field-types.calculation")
                                                                                                    : customField.field_type === "conversation"
                                                                                                        ? t("fields.field-form.field-types.conversation")
                                                                                                        : t("fields.field-form.field-types.phone") }))] })), customField.field_caption && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.caption") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.field_caption }))] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.required") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.required ? t("yes") : t("no") }))] })), type === "contacts" &&
                    (customField.field_type === "text_select" ||
                        customField.field_type === "radio_text" ||
                        customField.field_type === "radio_icon" ||
                        customField.field_type === "radio_image" ||
                        customField.field_type === "checkbox" ||
                        customField.field_type === "rating" ||
                        customField.field_type === "range" ||
                        customField.field_type === "slider" ||
                        customField.field_type === "switch") && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.filterable") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.filterable ? t("yes") : t("no") }))] }))), type === "contacts" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.not-editable") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.not_editable ? t("yes") : t("no") }))] }))), customField.field_type === "file" && (_jsxs(Fragment, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-form.multiple") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.allow_multiple_files ? t("yes") : t("no") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-form.max-items") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: (_a = customField.max_file_count) !== null && _a !== void 0 ? _a : 1 }))] }))] })), (customField.field_type === "rating" ||
                    customField.field_type === "slider" ||
                    customField.field_type === "range") && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.step") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.step }))] }))), customField.field_type === "slider" ||
                    (customField.field_type === "range" && (_jsxs(Fragment, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.min-value") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.min_value }))] })), customField.min_label && customField.min_label !== "" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.min-value-label") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.min_label }))] })))] }))), (customField.field_type === "rating" ||
                    customField.field_type === "slider" ||
                    customField.field_type === "range") && (_jsxs(Fragment, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.max-value") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.max_value }))] })), customField.field_type === "slider" ||
                            (customField.field_type === "range" &&
                                customField.max_label &&
                                customField.max_label !== "" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.max-value-label") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.max_label }))] }))))] })), customField.field_type === "date_time" && (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("fields.field-attributes.date-time-type") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: customField.date_time_type === "date_time"
                                    ? t("fields.field-form.field-types.date-time-types.date-time")
                                    : customField.date_time_type === "date"
                                        ? t("fields.field-form.field-types.date-time-types.date")
                                        : t("fields.field-form.field-types.date-time-types.time") }))] })) }))] })));
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginBottom: 4,
                } }, { children: type !== "campaign_steps" || (type === "campaign_steps" && shared) ? (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(FieldsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: type === "contacts"
                                                    ? t("fields.contact-fields.contact-field")
                                                    : type === "tenant_users"
                                                        ? t("fields.user-fields.user-field")
                                                        : type === "campaign_steps"
                                                            ? t("fields.shared-fields.form-fields.form-field")
                                                            : type === "ticket_activities"
                                                                ? t("fields.activity-fields.activity-field")
                                                                : t("fields.group-fields.group-field") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        paddingX: 4,
                                        paddingY: 4,
                                    } }, { children: details() })) }) }))] }))) : (_jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                            paddingX: 4,
                            paddingY: 4,
                        } }, { children: details() })) })) })), _jsx(CustomFieldPreview, { type: customField.field_type, name: customField.field_name, caption: customField.field_caption, required: customField.required, options: customField.field_type === "radio_image"
                    ? customField.image_text_items
                    : customField.text_items, maxValue: customField.field_type === "rating" ||
                    customField.field_type === "slider" ||
                    customField.field_type === "range"
                    ? customField.max_value
                    : undefined, ratingStep: customField.field_type === "rating" ||
                    customField.field_type === "slider" ||
                    customField.field_type === "range"
                    ? customField.step
                    : undefined, minValue: customField.field_type === "slider" || customField.field_type === "range"
                    ? customField.min_value
                    : undefined, icon: customField.field_type === "rating" ? customField.icon : undefined, emptyIcon: customField.field_type === "rating" ? customField.empty_icon : undefined, maxValueLabel: customField.max_label !== "" ? customField.max_label : undefined, minValueLabel: customField.min_label !== "" ? customField.min_label : undefined, dateTimeType: customField.field_type === "date_time" ? customField.date_time_type : undefined, valueLabel: customField.value_number_title, targetLabel: customField.target_number_title, resultLabel: customField.result_number_title, changeLabel: customField.change_number_title, unit: customField.number_unit, multiple: customField.allow_multiple_files, maxItems: customField.max_file_count })] }));
}
