import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Settings";
import Table from "../blocks/table";
import { useApiCentral } from "../../../api/api-central";
export default function ClientsTable() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useApiCentral();
    const tableRef = useRef();
    const [tableQuery, setTableQuery] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_clients(query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null)
                .then(response => {
                resolve({
                    data: response.data.tenants,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    return (_jsx(Table, { title: t("clients.clients-table.all-clients"), actions: [
            {
                icon: () => _jsx(ViewIcon, { color: "action" }),
                tooltip: t("clients.view-client"),
                onClick: (event, data) => {
                    navigate("/administration/clients/" + data.id);
                },
            },
            {
                icon: () => _jsx(EditIcon, { color: "action" }),
                tooltip: t("clients.edit-client"),
                onClick: (event, data) => navigate("/administration/clients/" + data.id + "/edit"),
            },
            {
                icon: () => _jsx(RefreshIcon, { color: "action" }),
                tooltip: t("clients.clients-table.refresh-clients"),
                isFreeAction: true,
                onClick: () => refreshTableData(),
            },
        ], columns: isMobile
            ? [
                {
                    title: t("clients.client") + " #",
                    field: "id",
                    defaultSort: "desc",
                },
            ]
            : [
                {
                    title: "#",
                    field: "id",
                    maxWidth: "80px",
                    defaultSort: "desc",
                },
                {
                    title: t("clients.client-attributes.name"),
                    field: "name",
                },
                {
                    title: t("clients.client-attributes.domain"),
                    field: "domain",
                },
                {
                    title: t("details"),
                    render: client => {
                        return (_jsx(Fragment, { children: client.active ? (_jsx(Chip, { size: "small", color: "secondary", label: t("clients.client-attributes.active") })) : (_jsx(Chip, { size: "small", color: "default", label: t("clients.client-attributes.inactive") })) }));
                    },
                },
                {
                    title: t("updated-at"),
                    field: "updated_at",
                    width: "150px",
                    render: client => {
                        return new Date(Date.parse(client.created_at)).toLocaleDateString("en-GB");
                    },
                },
            ], detailPanel: isMobile
            ? (client) => {
                return (_jsx(Box, Object.assign({ sx: {
                        paddingX: 2,
                        paddingY: 3,
                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("clients.client-attributes.name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: client.name }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("clients.client-attributes.domain") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: client.domain }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(client.created_at)).toLocaleDateString("en-GB") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(client.updated_at)).toLocaleDateString("en-GB") }))] }))] })) })));
            }
            : undefined, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }));
}
