import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { useTenancy } from "../../../../services/tenancy";
import { useRoles } from "../../../../services/roles";
import { flatGroups } from "../../../../helpers/groups";
export default function GroupColumn({ ticket }) {
    const tenancy = useTenancy();
    const roles = useRoles();
    const navigate = useNavigate();
    return (_jsx(Fragment, { children: ticket.groups &&
            ticket.groups.length > 0 &&
            ticket.groups.map(group => {
                let parents = [];
                if (group.parent_groups && !roles.isAgent) {
                    parents = flatGroups(new Array(group.parent_groups), 0, parents).reverse();
                }
                return (_jsx(Tooltip, Object.assign({ disableFocusListener: parents.length < 1, disableHoverListener: parents.length < 1, disableTouchListener: parents.length < 1, placement: "top", title: parents.map((parent, index) => index !== parents.length - 1 ? parent.name + " › " : parent.name) }, { children: _jsx(Chip, { label: group.name, size: "small", variant: "outlined", onClick: () => {
                            navigate(tenancy.link("/campaigns/" +
                                ticket.campaign.id +
                                "/groups/" +
                                group.id));
                        }, style: {
                            marginTop: "4px",
                            marginBottom: "4px",
                            marginRight: "8px",
                            wordBreak: "break-word",
                        } }, group.id) }), group.id));
            }) }));
}
