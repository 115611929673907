import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Intro from "../../elements/blocks/intro";
import Controls from "../../elements/blocks/controls";
import CampaignLoading from "../../elements/campaigns/campaign-loading";
import CampaignCard from "../../elements/campaigns/campaign-card";
import PaginationNumbers from "../../elements/blocks/pagination";
import Nope from "../../elements/blocks/nope";
import { useRoles } from "../../../services/roles";
import { useApiTenant } from "../../../api/api-tenant";
export default function Campaigns() {
    const { t } = useTranslation();
    const api = useApiTenant();
    const roles = useRoles();
    const [isLoading, setIsLoading] = useState(true);
    const [campaigns, setCampaigns] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [filterBy, setFilterBy] = useState("all");
    const [search, setSearch] = useState("");
    useEffect(() => {
        setIsLoading(true);
        api.GET_campaigns(12, page, filterBy === "all"
            ? ["inbound", "outbound", "contract", "survey"]
            : new Array(filterBy), search !== "" ? search : undefined, "id", "desc", roles.isAgent ? 1 : undefined, roles.isAgent ? 1 : undefined)
            .then(response => {
            setCampaigns(response.data.campaigns);
            setPage(response.data.meta.current_page);
            setPages(response.data.meta.last_page);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [page, filterBy, search]);
    useEffect(() => {
        if (page > 1)
            setPage(0);
    }, [filterBy]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("campaigns.campaigns"), breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                ], button: roles.isAdmin
                    ? {
                        label: t("campaigns.new-campaign"),
                        link: "/campaigns/new",
                        color: "secondary",
                        type: "new",
                    }
                    : undefined }), _jsx(Controls, { tabs: [
                    {
                        label: t("campaigns.campaign-attributes.types.all"),
                        value: "all",
                    },
                    {
                        label: t("campaigns.campaign-attributes.types.inbound"),
                        value: "inbound",
                    },
                    {
                        label: t("campaigns.campaign-attributes.types.outbound"),
                        value: "outbound",
                    },
                    {
                        label: t("campaigns.campaign-attributes.types.contract"),
                        value: "contract",
                    },
                    {
                        label: t("campaigns.campaign-attributes.types.survey"),
                        value: "survey",
                    },
                ], tab: filterBy, setTab: setFilterBy, enableSearch: true, search: search, setSearch: setSearch }), isLoading ? (_jsx(CampaignLoading, {})) : campaigns.length > 0 ? (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ container: true, spacing: 4, direction: "row", alignItems: "stretch" }, { children: campaigns.map(campaign => (_jsx(CampaignCard, { campaign: campaign }, campaign.id))) })), _jsx(PaginationNumbers, { pages: pages, page: page, setPage: setPage })] })) : (_jsx(Nope, { caption: t("campaigns.no-campaigns"), card: true }))] }));
}
