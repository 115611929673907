import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import ContactIcon from "@mui/icons-material/AccountBox";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import ContactPhones from "./contact-phones";
import ContactEmails from "./contact-emails";
import CustomFieldsView from "../custom-fields/custom-fields-view";
import { copy } from "../../../helpers/copy";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useRoles } from "../../../services/roles";
import { colors } from "../../../helpers/colors";
export default function ContactDetails({ type, contact, campaign, modal }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const roles = useRoles();
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ContactIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("contacts.contact") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("contacts.contact-details") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [contact.type && (_jsx(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "overline", style: {
                                                                color: campaign &&
                                                                    campaign.field_options &&
                                                                    campaign.field_options.find(o => o.data === "type") &&
                                                                    !campaign.field_options.find(o => o.data === "type").visible
                                                                    ? "#bebebe"
                                                                    : "inherit",
                                                            } }, { children: contact.type === "business"
                                                                ? t("contacts.contact-attributes.type.business")
                                                                : t("contacts.contact-attributes.type.person") })) }))), _jsxs(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            paddingBottom: 2,
                                                        } }, { children: [_jsx(Avatar, Object.assign({ style: {
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    color: "#fff",
                                                                    backgroundColor: colors.secondary.dark,
                                                                } }, { children: (contact.type
                                                                    ? contact.type === "business"
                                                                        ? contact.company
                                                                            ? contact.company[0].toUpperCase()
                                                                            : contact.is_anonymous === true
                                                                                ? "A"
                                                                                : contact.first_name ||
                                                                                    contact.last_name
                                                                                    ? (contact.first_name
                                                                                        ? contact.first_name[0].toUpperCase()
                                                                                        : "") +
                                                                                        (contact.last_name
                                                                                            ? " " +
                                                                                                contact.last_name[0].toUpperCase()
                                                                                            : "")
                                                                                    : ""
                                                                        : contact.is_anonymous === true
                                                                            ? "A"
                                                                            : contact.first_name || contact.last_name
                                                                                ? (contact.first_name
                                                                                    ? contact.first_name[0].toUpperCase()
                                                                                    : "") +
                                                                                    (contact.last_name
                                                                                        ? " " +
                                                                                            contact.last_name[0].toUpperCase()
                                                                                        : "")
                                                                                : contact.company
                                                                                    ? contact.company[0].toUpperCase()
                                                                                    : ""
                                                                    : contact.company
                                                                        ? contact.company[0].toUpperCase()
                                                                        : contact.is_anonymous === true
                                                                            ? "A"
                                                                            : contact.first_name || contact.last_name
                                                                                ? (contact.first_name
                                                                                    ? contact.first_name[0].toUpperCase()
                                                                                    : "") +
                                                                                    (contact.last_name
                                                                                        ? " " + contact.last_name[0].toUpperCase()
                                                                                        : "")
                                                                                : "").replace(/\s/g, "") })), _jsxs(Box, Object.assign({ sx: {
                                                                    paddingLeft: 2,
                                                                } }, { children: [type === "ticket" &&
                                                                        (roles.isAdmin || roles.isManager) ? (_jsx(Box, Object.assign({ sx: {
                                                                            display: "inline-block",
                                                                        } }, { children: _jsx(Link, Object.assign({ style: {
                                                                                color: campaign &&
                                                                                    campaign.field_options &&
                                                                                    !campaign.field_options.find(o => o.data === "first_name").visible &&
                                                                                    !campaign.field_options.find(o => o.data === "last_name").visible &&
                                                                                    !campaign.field_options.find(o => o.data === "company").visible &&
                                                                                    !contact.is_anonymous
                                                                                    ? "#bebebe"
                                                                                    : "inherit",
                                                                            }, href: tenancy.link("/contacts/" + contact.id), onClick: event => {
                                                                                event.preventDefault();
                                                                                navigate(tenancy.link("/contacts/" + contact.id));
                                                                            }, underline: "hover" }, { children: _jsx(Typography, Object.assign({ variant: "h6", style: {
                                                                                    color: campaign &&
                                                                                        campaign.field_options &&
                                                                                        !campaign.field_options.find(o => o.data ===
                                                                                            "first_name").visible &&
                                                                                        !campaign.field_options.find(o => o.data ===
                                                                                            "last_name").visible &&
                                                                                        !campaign.field_options.find(o => o.data === "company").visible &&
                                                                                        !contact.is_anonymous
                                                                                        ? "#bebebe"
                                                                                        : "inherit",
                                                                                } }, { children: contact.type
                                                                                    ? contact.type === "business"
                                                                                        ? contact.company
                                                                                            ? contact.company
                                                                                            : contact.is_anonymous ===
                                                                                                true
                                                                                                ? t("contacts.anonymous")
                                                                                                : contact.first_name ||
                                                                                                    contact.last_name
                                                                                                    ? (contact.first_name
                                                                                                        ? contact.first_name
                                                                                                        : "") +
                                                                                                        (contact.last_name
                                                                                                            ? " " +
                                                                                                                contact.last_name
                                                                                                            : "")
                                                                                                    : ""
                                                                                        : contact.is_anonymous ===
                                                                                            true
                                                                                            ? t("contacts.anonymous")
                                                                                            : contact.first_name ||
                                                                                                contact.last_name
                                                                                                ? (contact.first_name
                                                                                                    ? contact.first_name
                                                                                                    : "") +
                                                                                                    (contact.last_name
                                                                                                        ? " " +
                                                                                                            contact.last_name
                                                                                                        : "")
                                                                                                : contact.company
                                                                                                    ? contact.company
                                                                                                    : ""
                                                                                    : contact.company
                                                                                        ? contact.company
                                                                                        : contact.is_anonymous === true
                                                                                            ? t("contacts.anonymous")
                                                                                            : contact.first_name ||
                                                                                                contact.last_name
                                                                                                ? (contact.first_name
                                                                                                    ? contact.first_name
                                                                                                    : "") +
                                                                                                    (contact.last_name
                                                                                                        ? " " + contact.last_name
                                                                                                        : "")
                                                                                                : "" })) })) }))) : (_jsx(Typography, Object.assign({ variant: "h6", style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data === "first_name").visible &&
                                                                                !campaign.field_options.find(o => o.data === "last_name").visible &&
                                                                                !campaign.field_options.find(o => o.data === "company").visible &&
                                                                                !contact.is_anonymous
                                                                                ? "#bebebe"
                                                                                : "inherit",
                                                                        } }, { children: contact.type
                                                                            ? contact.type === "business"
                                                                                ? contact.company
                                                                                    ? contact.company
                                                                                    : contact.is_anonymous === true
                                                                                        ? t("contacts.anonymous")
                                                                                        : contact.first_name ||
                                                                                            contact.last_name
                                                                                            ? (contact.first_name
                                                                                                ? contact.first_name
                                                                                                : "") +
                                                                                                (contact.last_name
                                                                                                    ? " " + contact.last_name
                                                                                                    : "")
                                                                                            : ""
                                                                                : contact.is_anonymous === true
                                                                                    ? t("contacts.anonymous")
                                                                                    : contact.first_name ||
                                                                                        contact.last_name
                                                                                        ? (contact.first_name
                                                                                            ? contact.first_name
                                                                                            : "") +
                                                                                            (contact.last_name
                                                                                                ? " " + contact.last_name
                                                                                                : "")
                                                                                        : contact.company
                                                                                            ? contact.company
                                                                                            : ""
                                                                            : contact.company
                                                                                ? contact.company
                                                                                : contact.is_anonymous === true
                                                                                    ? t("contacts.anonymous")
                                                                                    : contact.first_name ||
                                                                                        contact.last_name
                                                                                        ? (contact.first_name
                                                                                            ? contact.first_name
                                                                                            : "") +
                                                                                            (contact.last_name
                                                                                                ? " " + contact.last_name
                                                                                                : "")
                                                                                        : "" }))), contact.type ? (contact.type === "business" ? (contact.company ? (_jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data ===
                                                                                    "first_name").visible &&
                                                                                !campaign.field_options.find(o => o.data ===
                                                                                    "last_name").visible &&
                                                                                !contact.is_anonymous
                                                                                ? "#bebebe"
                                                                                : "inherit",
                                                                        } }, { children: contact.first_name ||
                                                                            contact.last_name
                                                                            ? ((_a = contact.first_name) !== null && _a !== void 0 ? _a : "") +
                                                                                " " +
                                                                                ((_b = contact.last_name) !== null && _b !== void 0 ? _b : "")
                                                                            : "" }))) : ("")) : contact.company &&
                                                                        (contact.first_name || contact.last_name) ? (_jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data === "company").visible &&
                                                                                !contact.is_anonymous
                                                                                ? "#bebebe"
                                                                                : "inherit",
                                                                        } }, { children: contact.company }))) : contact.company &&
                                                                        !(contact.first_name || contact.last_name) ? (_jsx(Typography, Object.assign({ style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data === "first_name").visible &&
                                                                                !campaign.field_options.find(o => o.data === "last_name").visible &&
                                                                                !campaign.field_options.find(o => o.data === "company").visible &&
                                                                                !contact.is_anonymous
                                                                                ? "#bebebe"
                                                                                : "inherit",
                                                                        } }, { children: t("contacts.anonymous") }))) : ("")) : contact.company ? (_jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data === "first_name").visible &&
                                                                                !campaign.field_options.find(o => o.data === "last_name").visible &&
                                                                                !contact.is_anonymous
                                                                                ? "#bebebe"
                                                                                : "inherit",
                                                                        } }, { children: contact.first_name || contact.last_name
                                                                            ? ((_c = contact.first_name) !== null && _c !== void 0 ? _c : "") +
                                                                                " " +
                                                                                ((_d = contact.last_name) !== null && _d !== void 0 ? _d : "")
                                                                            : "" }))) : ("")] }))] })), contact.phones.length > 0 && (_jsx(Box, Object.assign({ sx: {
                                                            paddingTop: 2,
                                                        } }, { children: _jsx(ContactPhones, { phones: contact.phones, color: campaign &&
                                                                campaign.field_options &&
                                                                !campaign.field_options.find(o => o.data === "phones").visible
                                                                ? "#bebebe"
                                                                : "inherit" }) }))), contact.emails.length > 0 && (_jsx(Box, Object.assign({ sx: {
                                                            paddingTop: 2,
                                                        } }, { children: _jsx(ContactEmails, { emails: contact.emails, color: campaign &&
                                                                campaign.field_options &&
                                                                !campaign.field_options.find(o => o.data === "emails").visible
                                                                ? "#bebebe"
                                                                : "inherit" }) }))), contact.oib && (_jsxs(Box, Object.assign({ sx: {
                                                            paddingTop: 2,
                                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                    paddingBottom: 2,
                                                                } }, { children: _jsx(Typography, Object.assign({ variant: "button", style: {
                                                                        color: campaign &&
                                                                            campaign.field_options &&
                                                                            !campaign.field_options.find(o => o.data === "oib").visible
                                                                            ? "#bebebe"
                                                                            : "inherit",
                                                                    } }, { children: "OIB" })) })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    display: "flex",
                                                                } }, { children: _jsx(Chip, { style: {
                                                                        color: campaign &&
                                                                            campaign.field_options &&
                                                                            !campaign.field_options.find(o => o.data === "oib").visible
                                                                            ? "#bebebe"
                                                                            : "inherit",
                                                                        display: "flex",
                                                                        overflow: "hidden",
                                                                    }, variant: "outlined", label: contact.oib, onDelete: () => {
                                                                        copy(contact.oib);
                                                                        alert.show(t("contacts.oib-copied"), "info");
                                                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                        // @ts-ignore
                                                                        title: t("copy-oib") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] }))), contact.uuid && (_jsxs(Box, Object.assign({ sx: {
                                                            paddingTop: 2,
                                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                    paddingBottom: 2,
                                                                } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: "UUID" })) })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    display: "flex",
                                                                } }, { children: _jsx(Chip, { style: {
                                                                        display: "flex",
                                                                        overflow: "hidden",
                                                                    }, variant: "outlined", label: contact.uuid, onDelete: () => {
                                                                        copy(contact.uuid);
                                                                        alert.show(t("uuid-copied"), "info");
                                                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                        // @ts-ignore
                                                                        title: t("copy-uuid") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] })))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: contact.address && (_jsx(Box, Object.assign({ sx: {
                                                        paddingBottom: 3,
                                                    } }, { children: _jsxs(Card, Object.assign({ variant: "outlined" }, { children: [_jsx(CardActionArea, Object.assign({ onClick: () => {
                                                                    var _a;
                                                                    window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURI(contact.address.street_name +
                                                                        " " +
                                                                        ((_a = contact.address
                                                                            .street_number) !== null && _a !== void 0 ? _a : "") +
                                                                        "," +
                                                                        contact.address.city +
                                                                        "," +
                                                                        contact.address.country)}`, "__blank");
                                                                } }, { children: _jsx(CardMedia, { component: "img", image: `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(((_e = contact.address.street_name) !== null && _e !== void 0 ? _e : "") +
                                                                        " " +
                                                                        ((_f = contact.address
                                                                            .street_number) !== null && _f !== void 0 ? _f : "") +
                                                                        "," +
                                                                        contact.address.city +
                                                                        "," +
                                                                        contact.address.country)}&zoom=12&size=640x300&key=${process.env.GOOGLE_MAPS_KEY}` }) })), _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h5", component: "span", style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data === "address").visible
                                                                                ? "#bebebe"
                                                                                : "inherit",
                                                                        } }, { children: contact.address.city })), _jsx(Typography, Object.assign({ variant: "h5", component: "span", style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data === "address").visible
                                                                                ? "#bebebe"
                                                                                : "textSecondary",
                                                                        } }, { children: _jsx(Box, Object.assign({ component: "span" }, { children: contact.address.city
                                                                                ? `, ${contact.address.country}`
                                                                                : contact.address.country })) })), _jsx(Typography, Object.assign({ style: {
                                                                            color: campaign &&
                                                                                campaign.field_options &&
                                                                                !campaign.field_options.find(o => o.data === "address").visible
                                                                                ? "#bebebe"
                                                                                : "textSecondary",
                                                                        } }, { children: ((_g = contact.address.street_name) !== null && _g !== void 0 ? _g : "") +
                                                                            " " +
                                                                            ((_h = contact.address.street_number) !== null && _h !== void 0 ? _h : "") }))] })] })) }))) }))] })) })) }) }))] })) })), contact.custom_fields && contact.custom_fields.length > 0 && (_jsx(CustomFieldsView, { status: "view", type: "contacts", customFields: contact.custom_fields, campaign: campaign, modal: modal }))] }));
}
