import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../../../services/alert";
import { useApiTenant } from "../../../../api/api-tenant";
export default function LeadAssigneeRemove({ campaignId, audienceId, lead, setLead, setIsRemoved, }) {
    const { t } = useTranslation();
    const alert = useAlert();
    const api = useApiTenant();
    const [isRemoving, setIsRemoving] = useState(false);
    const updateAudienceLead = () => {
        setIsRemoving(true);
        api.UPDATE_audienceLead(audienceId, campaignId, lead.id, null)
            .then(() => {
            alert.show(t("campaigns.audiences.leads.lead-assignee-remove.removed"), "warning");
            setIsRemoving(false);
            setLead(null);
            setIsRemoved(true);
        })
            .catch(error => {
            console.error(error.response);
            setIsRemoving(false);
            setLead(null);
            alert.show(t("campaigns.audiences.leads.lead-assignee-remove.removing-error"), "error");
        });
    };
    return (_jsxs(Dialog, Object.assign({ open: lead !== null, onClose: () => {
            setLead(null);
        } }, { children: [_jsx(DialogTitle, { children: t("campaigns.audiences.leads.lead-assignee-remove.confirm-removal") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: t("campaigns.audiences.leads.lead-assignee-remove.confirm") }), _jsx(DialogContentText, { children: _jsxs("em", { children: [t("delete-component.confirm-caption"), "."] }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                            setLead(null);
                        } }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isRemoving, onClick: () => updateAudienceLead(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), style: {
                            color: "rgb(244, 67, 54)",
                        } }, { children: t("delete-component.delete") }))] })] })));
}
