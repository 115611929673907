import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ColumnsIcon from "@mui/icons-material/TableChart";
import CountriesIcon from "@mui/icons-material/Flag";
import AreasIcon from "@mui/icons-material/Explore";
import Intro from "../../../elements/blocks/intro";
import { useTenancy } from "../../../../services/tenancy";
export default function Options() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("options.options"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                ] }), _jsx(Fragment, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/options/dashboard-columns"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                textAlign: "center",
                                            } }, { children: [_jsx(ColumnsIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("options.dashboard-columns.dashboard-columns") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/options/phone-countries"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                textAlign: "center",
                                            } }, { children: [_jsx(CountriesIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("options.phone-countries.phone-countries") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/options/phone-areas"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                textAlign: "center",
                                            } }, { children: [_jsx(AreasIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("options.phone-areas.phone-areas") }))] })) }) })) }) }))] })) })] }));
}
