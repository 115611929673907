import React, { createElement } from "react";
import { render } from "react-dom";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, } from "react-router-dom";
import * as Sentry from "@sentry/react";
import App from "./components/app";
Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
});
render(createElement(App), document.getElementById("app"));
