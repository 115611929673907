import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../../elements/blocks/intro";
import Nope from "../../../elements/blocks/nope";
import ClientDetails from "../../../elements/clients/client-details";
import ClientUsers from "../../../elements/clients/client-users";
import { useApiCentral } from "../../../../api/api-central";
export default function ClientView() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiCentral();
    const [client, setClient] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (!id)
            return;
        api.GET_client(id)
            .then(response => {
            setClient(response.data.tenant);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: client ? client.name : t("clients.client") + " #" + id, breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("clients.clients"),
                        link: "/administration/clients",
                    },
                    {
                        name: client ? client.name : t("clients.client"),
                        link: "/administration/clients/" + id,
                    },
                ], button: client
                    ? {
                        label: t("clients.edit-client"),
                        link: "/administration/clients/" + id + "/edit",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : client ? (_jsxs(Fragment, { children: [_jsx(ClientDetails, { client: client }), _jsx(ClientUsers, { client: client })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("clients.errors.not-found") }))] }));
}
