import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { enUS, hr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import { makeStyles } from "@mui/styles";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import Timeline from "@mui/lab/Timeline";
import InputCustomFields from "../inputs/input-custom-fields";
import CustomFieldsView from "../custom-fields/custom-fields-view";
import { prettifyText } from "../../../helpers/clean";
import { usePrefs } from "../../../services/prefs";
import { useAlert } from "../../../services/alert";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
const useStyles = makeStyles(() => ({
    timeline: {
        margin: 0,
        padding: 0,
        "& .MuiTimelineContent-root": {
            padding: "4px 0 4px 16px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            content: "none",
        },
        "& .MuiTimelineDot-root": {
            boxShadow: "none",
            width: "12px",
            height: "12px",
        },
    },
    content: {
        "& p": {
            margin: 0,
            paddingTop: "8px",
            paddingBottom: "8px",
        },
        "& ul": {
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
        },
        "& ol": {
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
        },
        "& p:first-child": {
            paddingTop: 0,
        },
        "& p:last-child": {
            paddingBottom: 0,
        },
    },
}));
export default function PublicActivities({ activities, user, contact, information, tenancyCustomFields, activityCustomFieldOptions, customFields, setCustomFields, notes, setNotes, isActivitySaving, createTicketActivity, errors, isError, setIsError, isReplyOpen, setIsReplyOpen, }) {
    var _a, _b;
    const { t } = useTranslation();
    const prefs = usePrefs();
    const alert = useAlert();
    const classes = useStyles();
    const locales = { enUS, hr };
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [reCaptchaToken, setReCaptchaToken] = useState();
    const onReCaptchaVerify = useCallback(token => {
        setReCaptchaToken(token);
    }, []);
    const onCreateTicketActivity = () => {
        if (process.env.GOOGLE_RECAPTCHA_KEY && !reCaptchaToken) {
            alert.show(t("tickets.activity.error"), "error");
            return;
        }
        createTicketActivity();
    };
    return (_jsxs(Fragment, { children: [process.env.GOOGLE_RECAPTCHA_KEY && _jsx(GoogleReCaptcha, { onVerify: onReCaptchaVerify }), _jsxs(Timeline, Object.assign({ className: classes.timeline }, { children: [contact && information && information.length > 0 && (_jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, {}), _jsx(TimelineConnector, {})] }), _jsxs(TimelineContent, Object.assign({ sx: {
                                    marginBottom: 4,
                                } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                            marginBottom: 2,
                                        } }, { children: [_jsx(Typography, { children: `${(_a = contact.first_name) !== null && _a !== void 0 ? _a : ""} ${(_b = contact.last_name) !== null && _b !== void 0 ? _b : ""}` }), contact.created_at && (_jsxs(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: prefs.lang === "hr"
                                                            ? t("tickets.ticket-information.ticket-information-history.ago") +
                                                                " " +
                                                                formatDistanceToNow(new Date(Date.parse(contact.created_at)), {
                                                                    locale: locales["hr"],
                                                                })
                                                            : prettifyText(formatDistanceToNow(new Date(Date.parse(contact.created_at)), {
                                                                locale: locales["enUS"],
                                                            }) +
                                                                " " +
                                                                t("tickets.ticket-information.ticket-information-history.ago")) })), _jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: new Date(Date.parse(contact.created_at)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(contact.created_at)).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }) }))] })))] })), _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                                paddingX: 3,
                                                paddingY: 2.5,
                                            } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: _jsx(CustomFieldsView, { status: "view", type: "information", information: information, modal: true }) })) })) })] }))] })), activities.map(activity => {
                        var _a, _b, _c;
                        if (!activity.notes &&
                            !activity.email_content &&
                            activity.custom_fields.length === 0) {
                            return;
                        }
                        let content = activity.email_content;
                        if (user && content) {
                            content = content.replace("::recipient_first_name::", activity.recipients[0].first_name);
                        }
                        if (contact && content) {
                            content = content.replace("::recipient_first_name::", contact.first_name);
                        }
                        if (activity.notes && !content) {
                            content = activity.notes.replace(/\n/g, "<br />");
                        }
                        return (_jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, {}), _jsx(TimelineConnector, {})] }), _jsxs(TimelineContent, Object.assign({ sx: {
                                        marginBottom: 4,
                                    } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                marginBottom: 2,
                                            } }, { children: [activity.created_by ? (_jsx(Fragment, { children: process.env.APP_ID === "mcd" &&
                                                        ((_a = activity.created_by.email) === null || _a === void 0 ? void 0 : _a.includes("hr.mcd.com")) ? (_jsx(Typography, { children: t("users.customer-service") })) : (_jsx(Typography, { children: `${activity.created_by.first_name} ${activity.created_by.last_name}` })) })) : contact ? (_jsx(Typography, { children: `${(_b = contact.first_name) !== null && _b !== void 0 ? _b : ""} ${(_c = contact.last_name) !== null && _c !== void 0 ? _c : ""}` })) : null, _jsxs(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: prefs.lang === "hr"
                                                                ? t("tickets.ticket-information.ticket-information-history.ago") +
                                                                    " " +
                                                                    formatDistanceToNow(new Date(Date.parse(activity.created_at)), {
                                                                        locale: locales["hr"],
                                                                    })
                                                                : prettifyText(formatDistanceToNow(new Date(Date.parse(activity.created_at)), {
                                                                    locale: locales["enUS"],
                                                                }) +
                                                                    " " +
                                                                    t("tickets.ticket-information.ticket-information-history.ago")) })), _jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: new Date(Date.parse(activity.created_at)).toLocaleDateString("en-GB") +
                                                                " " +
                                                                new Date(Date.parse(activity.created_at)).toLocaleTimeString("hr-HR", {
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                }) }))] }))] })), _jsx(Box, Object.assign({ sx: {
                                                marginBottom: 1,
                                            } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                                        padding: 3,
                                                    } }, { children: content && (_jsx(Typography, { className: classes.content, component: "div", dangerouslySetInnerHTML: {
                                                            __html: content,
                                                        } })) })) }) })), activity.custom_fields && activity.custom_fields.length > 0 && (_jsx(CustomFieldsView, { status: "view", type: "ticket_activities", customFields: activity.custom_fields, modal: true }))] }))] }, activity.id));
                    }), (user || contact) && (_jsxs(TimelineItem, { children: [_jsx(TimelineSeparator, { children: _jsx(TimelineDot, { color: isReplyOpen ? "secondary" : "grey" }) }), _jsxs(TimelineContent, Object.assign({ sx: {
                                    padding: isReplyOpen ? "inherit" : "0 0 4px 16px !important;",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginBottom: 2,
                                        } }, { children: isReplyOpen ? (_jsx(Fragment, { children: user ? (_jsx(Typography, { children: `${user.first_name} ${user.last_name}` })) : contact ? (_jsx(Typography, { children: `${contact.first_name} ${contact.last_name}` })) : (_jsx(Typography, { children: t("contacts.anonymous") })) })) : (_jsx(Button, Object.assign({ variant: "text", onClick: () => setIsReplyOpen(true) }, { children: t("tickets.activity.new-reply") }))) })), isReplyOpen && (_jsxs(Fragment, { children: [_jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                                                        padding: isMobile ? 2 : 3,
                                                    } }, { children: [_jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { label: t("tickets.activity.reply"), fullWidth: true, multiline: true, required: true, value: notes, minRows: "4", maxRows: Infinity, onChange: event => {
                                                                    setNotes(event.target.value);
                                                                } }) })), tenancyCustomFields.length > 0 && (_jsx(InputCustomFields, { type: "ticket_activities", tenancyCustomFields: tenancyCustomFields, customFields: customFields, setCustomFields: setCustomFields, disabled: false, error: "custom_fields.", errors: errors, isError: isError, setIsError: setIsError, modal: true, disabledCustomFields: [], setDisabledCustomFields: () => { }, activityCustomFieldOptions: activityCustomFieldOptions }))] })) }), _jsx(Box, Object.assign({ sx: {
                                                    marginTop: 3,
                                                } }, { children: _jsx(LoadingButton, Object.assign({ variant: "contained", loading: isActivitySaving, disabled: notes === "" || isActivitySaving, disableElevation: true, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), onClick: onCreateTicketActivity }, { children: t("tickets.activity.send") })) }))] }))] }))] }))] }))] }));
}
