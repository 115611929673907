import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import DescriptionIcon from "@mui/icons-material/Description";
import InputTextArea from "../../inputs/input-textarea";
import InputSwitch from "../../inputs/input-switch";
import { useTenancy } from "../../../../services/tenancy";
import { useAlert } from "../../../../services/alert";
import { useApiTenant } from "../../../../api/api-tenant";
export default function AudienceForm({ campaignId, status, audience }) {
    var _a, _b, _c;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [name, setName] = useState((_a = audience === null || audience === void 0 ? void 0 : audience.name) !== null && _a !== void 0 ? _a : "");
    const [description, setDescription] = useState((_b = audience === null || audience === void 0 ? void 0 : audience.description) !== null && _b !== void 0 ? _b : "");
    const [active, setActive] = useState((_c = audience === null || audience === void 0 ? void 0 : audience.active) !== null && _c !== void 0 ? _c : true);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createAudience = () => {
        setIsSaving(true);
        setIsError(false);
        api.CREATE_audience(campaignId, name, description, active)
            .then(response => {
            setIsSaving(false);
            alert.show(t("campaigns.audiences.audience-form.created"), "success");
            navigate(tenancy.link("/campaigns/" + campaignId + "/audiences/" + response.data.audience.id));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("campaigns.audiences.audience-form.creating-error"), "error");
        });
    };
    const updateAudience = () => {
        if (!audience)
            return;
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_audience(campaignId, audience === null || audience === void 0 ? void 0 : audience.id, name, description, active)
            .then(() => {
            setIsSaving(false);
            alert.show(t("campaigns.audiences.audience-form.updated"), "success");
            navigate(tenancy.link("/campaigns/" + campaignId + "/audiences/" + audience.id));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("campaigns.audiences.audience-form.updating-error"), "error");
        });
    };
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(DescriptionIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.audiences.audience-form.audience-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, Object.assign({ component: "form" }, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputTextArea, { label: t("campaigns.audiences.audience-attributes.name"), required: true, value: name, setValue: setName, error: "name", errors: errors, isError: isError, setIsError: setIsError }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputTextArea, { label: t("campaigns.audiences.audience-attributes.description"), required: false, value: description, setValue: setDescription, error: "description", errors: errors, isError: isError, setIsError: setIsError }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputSwitch, { label: t("campaigns.audiences.audience-attributes.active"), required: false, checked: active ? active : false, setChecked: () => setActive(!active) }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Divider, { light: true }), _jsx(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        paddingTop: 4,
                                                    } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !name, onClick: () => status === "new"
                                                            ? createAudience()
                                                            : updateAudience(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                                            ? t("form-component.create")
                                                            : t("form-component.update") })) }))] }))] })) })) })) }))] })) })) }));
}
