import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ContactFilterBy from "./contact-filter-by";
import InputRadio from "../inputs/input-radio";
export default function ContactFilters({ showFilters, searchBy, setSearchBy, filterType, setFilterType, filterPhoneCountry, setFilterPhoneCountry, filterPhoneArea, setFilterPhoneArea, filterCustomFields, setFilterCustomFields, isLoading, setFilter, showMoreFilters, setShowMoreFilters, areFieldsAndGroupsLoaded, setAreFieldsAndGroupsLoaded, phoneCountries, setPhoneCountries, phoneAreas, setPhoneAreas, customFields, setCustomFields, isGlobalSearch, }) {
    const { t } = useTranslation();
    const [tab, setTab] = useState("filter_by");
    return (_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showFilters }, { children: [_jsx(DialogTitle, { children: isGlobalSearch && process.env.APP_ID !== "mcd" ? (_jsxs(Tabs, Object.assign({ indicatorColor: "secondary", textColor: "inherit", value: tab, onChange: (event, value) => setTab(value), variant: "fullWidth" }, { children: [_jsx(Tab, { value: "filter_by", label: t("filters.filter-by") }), _jsx(Tab, { value: "search_by", label: t("filters.search-by") })] }))) : (t("filters.filter-by")) }), _jsx(DialogContent, { children: tab === "filter_by" ? (_jsx(ContactFilterBy, { showFilters: showFilters, filterType: filterType, setFilterType: setFilterType, filterPhoneCountry: filterPhoneCountry, setFilterPhoneCountry: setFilterPhoneCountry, filterPhoneArea: filterPhoneArea, setFilterPhoneArea: setFilterPhoneArea, filterCustomFields: filterCustomFields, setFilterCustomFields: setFilterCustomFields, isLoadingTable: isLoading, showMoreFilters: showMoreFilters, setShowMoreFilters: setShowMoreFilters, areFieldsAndGroupsLoaded: areFieldsAndGroupsLoaded, setAreFieldsAndGroupsLoaded: setAreFieldsAndGroupsLoaded, phoneCountries: phoneCountries, setPhoneCountries: setPhoneCountries, phoneAreas: phoneAreas, setPhoneAreas: setPhoneAreas, customFields: customFields, setCustomFields: setCustomFields })) : (setSearchBy &&
                    searchBy &&
                    isGlobalSearch && (_jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                        {
                            value: "phones",
                            label: t("contacts.contact-attributes.phones"),
                        },
                        {
                            value: "emails",
                            label: t("contacts.contact-attributes.emails"),
                        },
                        {
                            value: "full_name,company",
                            label: t("contacts.contact-attributes.full-name-company"),
                        },
                        {
                            value: "full_name",
                            label: t("contacts.contact-attributes.full-name"),
                        },
                        {
                            value: "company",
                            label: t("contacts.contact-attributes.company"),
                        },
                        {
                            value: "address",
                            label: t("contacts.contact-attributes.address"),
                        },
                        {
                            value: "oib",
                            label: "OIB",
                        },
                        {
                            value: "id",
                            label: t("contacts.contact") + " ID",
                        },
                    ], value: searchBy ? searchBy : "phones", setValue: setSearchBy }))) }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", loading: isLoading, disabled: isLoading, onClick: () => {
                            setFilter(true);
                        }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("filters.apply") })) })) })] })));
}
