import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../elements/blocks/intro";
import PhoneAreasTable from "../../../../elements/options/phone-areas/phone-areas-table";
export default function PhoneAreas() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("options.phone-areas.phone-areas"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.phone-areas.phone-areas"),
                        link: "/settings/options/phone-areas",
                    },
                ], button: {
                    label: t("options.phone-areas.new-phone-area"),
                    link: "/settings/options/phone-areas/new",
                    color: "secondary",
                    type: "new",
                } }), _jsx(PhoneAreasTable, {})] }));
}
