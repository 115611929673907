import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import CountryIcon from "@mui/icons-material/Flag";
import { useTenancy } from "../../../../services/tenancy";
import { useAlert } from "../../../../services/alert";
import { useApiTenant } from "../../../../api/api-tenant";
import { countryByName, countries } from "../../../../helpers/countries";
export default function PhoneCountryForm({ status, phoneCountry }) {
    var _a, _b, _c;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [name, setName] = useState((_a = phoneCountry === null || phoneCountry === void 0 ? void 0 : phoneCountry.name) !== null && _a !== void 0 ? _a : null);
    const [code, setCode] = useState((_b = phoneCountry === null || phoneCountry === void 0 ? void 0 : phoneCountry.code) !== null && _b !== void 0 ? _b : null);
    const [phone, setPhone] = useState((_c = phoneCountry === null || phoneCountry === void 0 ? void 0 : phoneCountry.phone) !== null && _c !== void 0 ? _c : null);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createPhoneCountry = () => {
        if (!name || !code || !phone)
            return;
        setIsSaving(true);
        setIsError(false);
        api.CREATE_phoneCountry(name, code, phone)
            .then(response => {
            setIsSaving(false);
            alert.show(t("options.phone-countries.phone-country-form.phone-country-created"), "success");
            navigate(tenancy.link("/settings/options/phone-countries/" + response.data.phone_country.id));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("options.phone-countries.phone-country-form.creating-error-occured"), "error");
        });
    };
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(CountryIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("options.phone-countries.phone-country-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, Object.assign({ component: "form" }, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Autocomplete, { disabled: status === "edit", freeSolo: status === "edit", options: countries.map(country => country.name), value: name, onChange: (event, value) => {
                                                    if (!value)
                                                        return;
                                                    setName(value);
                                                    const country = countryByName(value);
                                                    setCode(country ? country.code : "");
                                                    setPhone(country ? country.phone : "");
                                                    if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name")))
                                                        setIsError(false);
                                                }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-countries.phone-country-attributes.country"), helperText: isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name"))
                                                        ? errors["name"][0]
                                                        : false, fullWidth: true, required: true, inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: "new-password" }), error: isError
                                                        ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name")
                                                        : false }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(Autocomplete, { disabled: true, freeSolo: true, options: countries.map(country => country.code), value: code, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-countries.phone-country-attributes.code"), fullWidth: true, required: true, inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: "new-password" }), error: isError
                                                        ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("code")
                                                        : false }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(Autocomplete, { disabled: true, freeSolo: true, options: countries
                                                    .map(country => country.phone)
                                                    .sort()
                                                    .filter((i, p, s) => s.indexOf(i) == p), value: phone, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-countries.phone-country-attributes.phone"), fullWidth: true, required: true, inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: "new-password" }), error: isError
                                                        ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("phone")
                                                        : false }))) }) })), status === "new" && (_jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Divider, { light: true }), _jsx(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        paddingTop: 4,
                                                    } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !name || !code || !phone, onClick: () => {
                                                            createPhoneCountry();
                                                        }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("form-component.create") })) }))] })))] })) })) })) }))] })) })) }));
}
