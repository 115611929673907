import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import UserForm from "../../../elements/users/user-form";
export default function CentralUserNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("users.new-user"), breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("users.users"),
                        link: "/administration/users",
                    },
                    {
                        name: t("users.new-user"),
                        link: "/administration/users/new",
                    },
                ] }), _jsx(UserForm, { type: "central", status: "new" })] }));
}
