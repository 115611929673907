import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Nope from "../../../../elements/blocks/nope";
import Intro from "../../../../elements/blocks/intro";
import PhoneCountryForm from "../../../../elements/options/phone-countries/phone-country-form";
import PhoneCountryDelete from "../../../../elements/options/phone-countries/phone-country-delete";
import { useApiTenant } from "../../../../../api/api-tenant";
export default function PhoneCountryEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [phoneCountry, setPhoneCountry] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_phoneCountry(id)
            .then(response => {
            setPhoneCountry(response.data.phone_country);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: phoneCountry
                    ? phoneCountry.name
                    : t("options.phone-countries.phone-country") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.phone-countries.phone-countries"),
                        link: "/settings/options/phone-countries",
                    },
                    {
                        name: phoneCountry
                            ? phoneCountry.name
                            : t("options.phone-countries.phone-country"),
                        link: "/settings/options/phone-countries/" + id,
                    },
                    {
                        name: t("options.phone-countries.edit-phone-country"),
                        link: "/settings/options/phone-countries/" + id + "/edit",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : phoneCountry ? (_jsxs(Fragment, { children: [_jsx(PhoneCountryForm, { status: "edit", phoneCountry: phoneCountry }), _jsx(PhoneCountryDelete, { phoneCountry: phoneCountry })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("options.phone-countries.errors.not-found") }))] }));
}
