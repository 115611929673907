import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useAuth } from "../../../../services/auth";
import { useRoles } from "../../../../services/roles";
import { prettifyContactTitle } from "../../../../helpers/clean";
import analytics from "../../../../helpers/analytics";
export default function ContactColumn({ contact, setContactModalOpen, setContactModalId, displayOption, }) {
    const { t } = useTranslation();
    const auth = useAuth();
    const roles = useRoles();
    const contactTitleAndType = prettifyContactTitle(contact, t, displayOption);
    return roles.isAdmin || roles.isManager ? (_jsx(Link, Object.assign({ underline: "hover", style: {
            color: "inherit",
            cursor: "pointer",
        }, onClick: () => {
            setContactModalId(contact.id);
            setContactModalOpen(true);
            analytics.sendModalView("/contacts/" + contact.id, auth.user);
        } }, { children: contactTitleAndType.title }))) : (_jsx(Typography, { children: contactTitleAndType.title }));
}
