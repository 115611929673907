import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
export default function InputLink({ label, required, value, setValue, caption, info, disabled, error, errors, isError, setIsError, }) {
    const { t } = useTranslation();
    const [linkError, setLinkError] = useState(false);
    const validateLink = link => {
        try {
            new URL(link);
        }
        catch (e) {
            setLinkError(true);
            return;
        }
        setLinkError(false);
    };
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: caption }) }))), _jsxs(FormControl, Object.assign({ disabled: disabled ? disabled : false, fullWidth: true, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(InputLabel, { children: label }), _jsx(OutlinedInput, { disabled: disabled ? disabled : false, label: label, required: required, value: value, autoComplete: "new-password", type: "text", onChange: event => {
                            if (event.target.value !== "")
                                validateLink(event.target.value);
                            else
                                setLinkError(false);
                            setValue(event.target.value);
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } }), linkError ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: t("inputs.input-link.link-error") }))) : isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
