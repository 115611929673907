import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { useApiTenant } from "../../../api/api-tenant";
import InformationFieldsMetricsCard from "./information-fields-metrics-card";
export default function InformationFieldsMetrics({ type, group_id, campaign, information_type, globalRange, setGlobalRange, globalStartDate, globalStartTime, globalEndDate, globalEndTime, isChosenGlobalCustomRange, setIsChosenGlobalCustomRange, }) {
    const api = useApiTenant();
    const [metricsCustomFields, setMetricsCustomFields] = useState([]);
    const [metricsCustomFieldsLoading, setMetricsCustomFieldsLoading] = useState(false);
    const [customFieldsData, setCustomFieldsData] = useState([]);
    const getTicketsCustomMetricsValues = () => {
        setMetricsCustomFieldsLoading(true);
        let cfsData = new Array();
        if (information_type === "field") {
            api.GET_campaignCustomFieldMetrics(campaign.id)
                .then(response => {
                setMetricsCustomFields(response.data.sort((a, b) => a.custom_field.field_name.localeCompare(b.custom_field.field_name)));
                response.data.map(field => {
                    cfsData.push({
                        customFieldId: field.count_by_value.toString(),
                        countBy: field.count_by,
                        data: [],
                        range: "total",
                        cachedAt: "",
                        loading: true,
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: "",
                        isError: false,
                    });
                });
                setCustomFieldsData(cfsData);
                setMetricsCustomFieldsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setMetricsCustomFieldsLoading(false);
                }
            });
        }
        else {
            api.GET_campaignChoiceMetrics(campaign.id)
                .then(response => {
                setMetricsCustomFields(response.data.sort((a, b) => a.campaign_step.name.localeCompare(b.campaign_step.name)));
                response.data.map(field => {
                    cfsData.push({
                        customFieldId: field.count_by_value.toString(),
                        countBy: field.count_by,
                        data: [],
                        range: "total",
                        cachedAt: "",
                        loading: true,
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: "",
                        isError: false,
                    });
                });
                setCustomFieldsData(cfsData);
                setMetricsCustomFieldsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setMetricsCustomFieldsLoading(false);
                }
            });
        }
        return () => api.cancel();
    };
    useEffect(() => {
        getTicketsCustomMetricsValues();
    }, []);
    return (_jsx(Fragment, { children: metricsCustomFieldsLoading ? (_jsx(Box, Object.assign({ sx: {
                paddingTop: 1,
                marginBottom: 4,
            } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, style: { paddingTop: 32 } }, { children: _jsx(Box, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(CircularProgress, { color: "primary", size: 32 }) })) })) })) }))) : (metricsCustomFields.length > 0 && (_jsx(Box, Object.assign({ sx: {
                marginBottom: 4,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, style: { paddingBottom: 6 } }, { children: _jsx(Divider, {}) })), metricsCustomFields.map((metricsCustomField, index) => {
                        return (_jsx(InformationFieldsMetricsCard, { type: type, group_id: group_id, campaign: campaign, information_type: information_type, globalRange: globalRange, setGlobalRange: setGlobalRange, globalStartDate: globalStartDate, globalStartTime: globalStartTime, globalEndDate: globalEndDate, globalEndTime: globalEndTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange, metricsCustomField: metricsCustomField, customFieldsData: customFieldsData, setCustomFieldsData: setCustomFieldsData }, information_type + index));
                    })] })) })))) }));
}
