import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputDatetime from "../inputs/input-datetime";
export default function MetricCustomRangeModal({ startDate, setStartDate, startTime, setStartTime, endDate, setEndDate, endTime, setEndTime, customFieldId, setIsChosenCustomRange, openCustomFieldCustomRange, setOpenCustomFieldCustomRange, openSelectCustomRange, setOpenSelectCustomRange, }) {
    const { t } = useTranslation();
    return (_jsxs(Dialog, Object.assign({ maxWidth: "xs", open: customFieldId && openCustomFieldCustomRange
            ? openCustomFieldCustomRange !== ""
            : openSelectCustomRange }, { children: [_jsx(DialogTitle, { children: t("metrics.choose-range") }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("metrics.range-options.custom.start-date"), required: true, value: startDate, setValue: setStartDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: t("metrics.range-options.custom.time"), required: false, status: "start", value: startTime !== "" ? startTime : null, setValue: setStartTime, disabled: !startDate || startDate === "" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("metrics.range-options.custom.end-date"), required: true, value: endDate, setValue: setEndDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: t("metrics.range-options.custom.time"), required: false, status: "end", value: endTime !== "" ? endTime : null, setValue: setEndTime, disabled: !endDate || endDate === "" }) }))] })) }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsx(Button, Object.assign({ disabled: startDate === "" || endDate === "", color: "primary", onClick: () => {
                            setIsChosenCustomRange(true);
                            if (!customFieldId && setOpenSelectCustomRange) {
                                setOpenSelectCustomRange(false);
                            }
                            else {
                                if (setOpenCustomFieldCustomRange)
                                    setOpenCustomFieldCustomRange("");
                            }
                        } }, { children: t("metrics.confirm") })) })) })] })));
}
