import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { hr, enUS } from "date-fns/locale";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import ActivityIcon from "@mui/icons-material/History";
import { isJsonString, prettifyText } from "../../../helpers/clean";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import ContactHistoryDelete from "./contact-history-delete";
const useStyles = makeStyles(() => ({
    timeline: {
        margin: 0,
        padding: 0,
        "& .MuiTimelineContent-root": {
            padding: "6px 0 6px 16px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            content: "none",
        },
    },
    chip: {
        height: "auto",
        paddingY: 2,
        "& .MuiChip-label": {
            paddingTop: 2,
            paddingBottom: 2,
            wordWrap: "break-word",
            whiteSpace: "normal",
            textOverflow: "clip",
            maxWidth: "100%",
        },
    },
}));
export default function ContactHistory({ status, contact, historyToDelete, setHistoryToDelete, modal, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const tenancy = useTenancy();
    const roles = useRoles();
    const prefs = usePrefs();
    const locales = { enUS, hr };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginTop: 4,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ActivityIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("contacts.contact-history.history") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("contacts.contact-history.contact-history") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: _jsx(Timeline, Object.assign({ className: classes.timeline }, { children: contact.history.map((contactDetail, index) => {
                                    var _a, _b, _c, _d;
                                    return (_jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, {}), index !== contact.history.length - 1 && (_jsx(TimelineConnector, {}))] }), _jsxs(TimelineContent, { children: [_jsxs(Box, Object.assign({ sx: {
                                                            marginBottom: 2,
                                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                                                        display: "inline-block",
                                                                    } }, { children: contactDetail.created_by && (_jsx(Fragment, { children: roles.isAgent ? (_jsx(Typography, { children: `${(_a = contactDetail.created_by
                                                                                .first_name) !== null && _a !== void 0 ? _a : ""} ${(_b = contactDetail.created_by
                                                                                .last_name) !== null && _b !== void 0 ? _b : ""}` })) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" +
                                                                                contactDetail.created_by
                                                                                    .id), onClick: event => {
                                                                                if (!contactDetail.created_by) {
                                                                                    return;
                                                                                }
                                                                                event.preventDefault();
                                                                                navigate(tenancy.link("/settings/users/" +
                                                                                    contactDetail
                                                                                        .created_by
                                                                                        .id));
                                                                            }, underline: "hover" }, { children: _jsx(Typography, { children: `${(_c = contactDetail.created_by
                                                                                    .first_name) !== null && _c !== void 0 ? _c : ""} ${(_d = contactDetail.created_by
                                                                                    .last_name) !== null && _d !== void 0 ? _d : ""}` }) }))) })) })) })), _jsxs(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: prefs.lang === "hr"
                                                                            ? t("contacts.contact-history.ago") +
                                                                                " " +
                                                                                formatDistanceToNow(new Date(contactDetail.created_at), {
                                                                                    locale: locales["hr"],
                                                                                })
                                                                            : prettifyText(formatDistanceToNow(new Date(contactDetail.created_at), {
                                                                                locale: locales["enUs"],
                                                                            })) +
                                                                                " " +
                                                                                t("contacts.contact-history.ago") })), _jsx(Typography, Object.assign({ variant: "overline", component: "p", color: "textSecondary" }, { children: new Date(Date.parse(contactDetail.created_at)).toLocaleDateString("en-GB") +
                                                                            " " +
                                                                            new Date(Date.parse(contactDetail.created_at)).toLocaleTimeString("hr-HR", {
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                            }) }))] }))] })), _jsx(Box, Object.assign({ sx: {
                                                            marginBottom: 2,
                                                        } }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                                                                    paddingX: 3,
                                                                    paddingY: 2,
                                                                } }, { children: [contactDetail.is_anonymous && (_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            marginY: 1,
                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                            marginX: 0.5,
                                                                                        } }, { children: t("contacts.contact") }))] })), !contactDetail.is_anonymous.previous &&
                                                                                contactDetail.is_anonymous.updated ===
                                                                                    "1" ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to4") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: t("contacts.anonymous"), size: "small", variant: "filled" }) }))] })) : contactDetail.is_anonymous
                                                                                .previous === "0" &&
                                                                                contactDetail.is_anonymous.updated ===
                                                                                    "1" ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.became") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: t("contacts.anonymous"), size: "small", variant: "filled" }) }))] })) : contactDetail.is_anonymous
                                                                                .previous === "1" &&
                                                                                contactDetail.is_anonymous.updated ===
                                                                                    "0" ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.is-not") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: t("contacts.anonymous"), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.anymore") }))] })) : ("")] }))), contactDetail.phones &&
                                                                        contactDetail.phones.length > 0 &&
                                                                        contactDetail.phones.map((phone, index) => {
                                                                            return (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                    marginX: 0.5,
                                                                                                } }, { children: t("contacts.contact-attributes.phone") }))] })), phone.previous &&
                                                                                        phone.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: phone.previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: phone.updated, size: "small", variant: "filled" }) }))] })) : !phone.previous &&
                                                                                        phone.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: phone.updated, size: "small", variant: "filled" }) }))] })) : (phone.previous &&
                                                                                        !phone.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                                                                    marginX: 1,
                                                                                                } }, { children: _jsx(Chip, { label: phone.previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed") }))] })))] }), index));
                                                                        }), contactDetail.emails &&
                                                                        contactDetail.emails.length > 0 &&
                                                                        contactDetail.emails.map((email, index) => {
                                                                            return (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                    marginX: 0.5,
                                                                                                } }, { children: t("contacts.contact-attributes.email") }))] })), email.previous &&
                                                                                        email.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from3") })), _jsx(Box, Object.assign({ sx: {
                                                                                                    marginX: 1,
                                                                                                } }, { children: _jsx(Chip, { label: email.previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                    marginX: 1,
                                                                                                } }, { children: _jsx(Chip, { label: email.updated, size: "small", variant: "filled" }) }))] })) : !email.previous &&
                                                                                        email.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to3") })), _jsx(Box, Object.assign({ sx: {
                                                                                                    marginX: 1,
                                                                                                } }, { children: _jsx(Chip, { label: email.updated, size: "small", variant: "filled" }) }))] })) : (email.previous &&
                                                                                        !email.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                                                                    marginX: 1,
                                                                                                } }, { children: _jsx(Chip, { label: email.previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed3") }))] })))] }), index));
                                                                        }), contactDetail.first_name && (_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            marginY: 1,
                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                            marginX: 0.5,
                                                                                        } }, { children: t("contacts.contact-attributes.first-name") }))] })), contactDetail.first_name.previous &&
                                                                                contactDetail.first_name.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from1") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .first_name
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .first_name
                                                                                                .updated, size: "small", variant: "filled" }) }))] })) : !contactDetail.first_name
                                                                                .previous &&
                                                                                contactDetail.first_name.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to1") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .first_name
                                                                                                .updated, size: "small", variant: "filled" }) }))] })) : (contactDetail.first_name.previous &&
                                                                                !contactDetail.first_name
                                                                                    .updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .first_name
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed1") }))] })))] }))), contactDetail.last_name && (_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            marginY: 1,
                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                            marginX: 0.5,
                                                                                        } }, { children: t("contacts.contact-attributes.last-name") }))] })), contactDetail.last_name.previous &&
                                                                                contactDetail.last_name.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from1") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .last_name
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .last_name
                                                                                                .updated, size: "small", variant: "filled" }) }))] })) : !contactDetail.last_name.previous &&
                                                                                contactDetail.last_name.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to1") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .last_name
                                                                                                .updated, size: "small", variant: "filled" }) }))] })) : (contactDetail.last_name.previous &&
                                                                                !contactDetail.last_name
                                                                                    .updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .last_name
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed1") }))] })))] }))), contactDetail.company && (_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            marginY: 1,
                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                            marginX: 0.5,
                                                                                        } }, { children: t("contacts.contact-attributes.company") }))] })), contactDetail.company.previous &&
                                                                                contactDetail.company.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from3") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .company
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .company.updated, size: "small", variant: "filled" }) }))] })) : !contactDetail.company.previous &&
                                                                                contactDetail.company.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to3") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .company.updated, size: "small", variant: "filled" }) }))] })) : (contactDetail.company.previous &&
                                                                                !contactDetail.company.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .company
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed3") }))] })))] }))), contactDetail.type && (_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            marginY: 1,
                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                            marginX: 0.5,
                                                                                        } }, { children: t("contacts.contact-attributes.type.type") }))] })), contactDetail.type.previous &&
                                                                                contactDetail.type.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail.type
                                                                                                .previous ===
                                                                                                "person"
                                                                                                ? t("contacts.contact-attributes.type.person")
                                                                                                : t("contacts.contact-attributes.type.business"), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail.type
                                                                                                .updated ===
                                                                                                "person"
                                                                                                ? t("contacts.contact-attributes.type.person")
                                                                                                : t("contacts.contact-attributes.type.business"), size: "small", variant: "filled" }) }))] })) : !contactDetail.type.previous &&
                                                                                contactDetail.type.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail.type
                                                                                                .updated ===
                                                                                                "person"
                                                                                                ? t("contacts.contact-attributes.type.person")
                                                                                                : t("contacts.contact-attributes.type.business"), size: "small", variant: "filled" }) }))] })) : (contactDetail.type.previous &&
                                                                                !contactDetail.type.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .type
                                                                                                .previous ===
                                                                                                "person"
                                                                                                ? t("contacts.contact-attributes.type.person")
                                                                                                : t("contacts.contact-attributes.type.business"), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed") }))] })))] }))), contactDetail.oib && (_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            marginY: 1,
                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                            marginX: 0.5,
                                                                                        } }, { children: "OIB" }))] })), contactDetail.oib.previous &&
                                                                                contactDetail.oib.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail.oib
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail.oib
                                                                                                .updated, size: "small", variant: "filled" }) }))] })) : !contactDetail.oib.previous &&
                                                                                contactDetail.oib.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail.oib
                                                                                                .updated, size: "small", variant: "filled" }) }))] })) : (contactDetail.oib.previous &&
                                                                                !contactDetail.oib.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .oib
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed") }))] })))] }))), contactDetail.address && (_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            marginY: 1,
                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                            marginX: 0.5,
                                                                                        } }, { children: t("contacts.contact-attributes.address") }))] })), contactDetail.address.previous &&
                                                                                contactDetail.address.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from3") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .address
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .address.updated, size: "small", variant: "filled" }) }))] })) : !contactDetail.address.previous &&
                                                                                contactDetail.address.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to3") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .address.updated, size: "small", variant: "filled" }) }))] })) : (contactDetail.address.previous &&
                                                                                !contactDetail.address.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: contactDetail
                                                                                                .address
                                                                                                .previous, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed3") }))] })))] }))), contactDetail.custom_fields &&
                                                                        contactDetail.custom_fields.length > 0 &&
                                                                        contactDetail.custom_fields.map((field, index) => {
                                                                            var _a, _b, _c, _d, _e, _f, _g, _h;
                                                                            return (_jsx(Fragment, { children: field.custom_field_type !==
                                                                                    "text_area" &&
                                                                                    field.custom_field_type !==
                                                                                        "html_text" &&
                                                                                    field.custom_field_type !==
                                                                                        "conversation" ? (_jsxs(Box, Object.assign({ sx: {
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        flexWrap: "wrap",
                                                                                        marginY: 1,
                                                                                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                        marginX: 0.5,
                                                                                                    } }, { children: field.name }))] })), field.previous &&
                                                                                            field.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from") })), _jsx(Box, Object.assign({ sx: {
                                                                                                        marginX: 1,
                                                                                                    } }, { children: isJsonString(field.previous) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_b = (_a = JSON.parse(field.previous)) === null || _a === void 0 ? void 0 : _a.media) === null || _b === void 0 ? void 0 : _b.map((media, index) => {
                                                                                                            var _a;
                                                                                                            return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                    .split("/")
                                                                                                                    .pop(), size: "small", variant: "filled" }, ((_a = field.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                index));
                                                                                                        }) }))) : field.previous.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: field.previous
                                                                                                            .split("/")
                                                                                                            .pop(), size: "small", variant: "outlined" })) : (_jsx(Chip, { className: classes.chip, label: field.previous, size: "small", variant: "filled" })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                        marginX: 1,
                                                                                                    } }, { children: isJsonString(field.updated) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_d = (_c = JSON.parse(field.updated)) === null || _c === void 0 ? void 0 : _c.media) === null || _d === void 0 ? void 0 : _d.map((media, index) => {
                                                                                                            var _a;
                                                                                                            return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                    .split("/")
                                                                                                                    .pop(), size: "small", variant: "filled" }, ((_a = field.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                index));
                                                                                                        }) }))) : field.updated.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: field.updated
                                                                                                            .split("/")
                                                                                                            .pop(), size: "small", variant: "outlined" })) : (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingTop: field
                                                                                                                .updated
                                                                                                                .length >
                                                                                                                120
                                                                                                                ? 1
                                                                                                                : 0,
                                                                                                        } }, { children: _jsx(Chip, { className: classes.chip, label: field.updated, size: "small", variant: "filled" }) }))) }))] })) : !field.previous &&
                                                                                            field.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                        marginX: 1,
                                                                                                    } }, { children: isJsonString(field.updated) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_f = (_e = JSON.parse(field.updated)) === null || _e === void 0 ? void 0 : _e.media) === null || _f === void 0 ? void 0 : _f.map((media, index) => {
                                                                                                            var _a;
                                                                                                            return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                    .split("/")
                                                                                                                    .pop(), size: "small", variant: "filled" }, ((_a = field.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                index));
                                                                                                        }) }))) : field.updated.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: field.updated
                                                                                                            .split("/")
                                                                                                            .pop(), size: "small", variant: "outlined" })) : (_jsx(Chip, { className: classes.chip, label: field.updated, size: "small", variant: "filled" })) }))] })) : (field.previous &&
                                                                                            !field.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                                                                        marginX: 1,
                                                                                                    } }, { children: isJsonString(field.previous) ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: (_h = (_g = JSON.parse(field.previous)) === null || _g === void 0 ? void 0 : _g.media) === null || _h === void 0 ? void 0 : _h.map((media, index) => {
                                                                                                            var _a;
                                                                                                            return (_jsx(Chip, { className: classes.chip, label: media
                                                                                                                    .split("/")
                                                                                                                    .pop(), size: "small", variant: "filled" }, ((_a = field.custom_field_type) !== null && _a !== void 0 ? _a : "") +
                                                                                                                index));
                                                                                                        }) }))) : field.previous.startsWith("http") ? (_jsx(Chip, { className: classes.chip, label: field.previous
                                                                                                            .split("/")
                                                                                                            .pop(), size: "small", variant: "outlined" })) : (_jsx(Chip, { className: classes.chip, label: field.previous, size: "small", variant: "filled" })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed") }))] })))] }), index)) : (_jsx(Box, Object.assign({ sx: {
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        flexWrap: "wrap",
                                                                                        marginY: 1,
                                                                                    } }, { children: field.previous &&
                                                                                        field.updated ? (_jsxs(Box, Object.assign({ sx: {
                                                                                            flexDirection: "column",
                                                                                            width: "100%",
                                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                            marginX: 0.5,
                                                                                                        } }, { children: field.name }))] })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.changed-from") })), _jsx(Box, Object.assign({ sx: {
                                                                                                    paddingY: 1,
                                                                                                } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                        color: "default",
                                                                                                    } }, { children: field.custom_field_type ===
                                                                                                        "text_area" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "html_text" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingY: 1,
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Input, { size: "small", fullWidth: true, multiline: true, value: field.previous, disableUnderline: true, inputProps: {
                                                                                                                readOnly: true,
                                                                                                                style: {
                                                                                                                    fontSize: 12,
                                                                                                                },
                                                                                                            } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                __html: field.previous,
                                                                                                            } }) }))) })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                    paddingY: 1,
                                                                                                } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                        color: "default",
                                                                                                    } }, { children: field.custom_field_type ===
                                                                                                        "text_area" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "html_text" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingY: 1,
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: field.updated, disableUnderline: true, inputProps: {
                                                                                                                readOnly: true,
                                                                                                                style: {
                                                                                                                    fontSize: 12,
                                                                                                                },
                                                                                                            } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                __html: field.updated,
                                                                                                            } }) }))) })) }))] }))) : !field.previous &&
                                                                                        field.updated ? (_jsxs(Box, Object.assign({ sx: {
                                                                                            flexDirection: "column",
                                                                                            width: "100%",
                                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                            marginX: 0.5,
                                                                                                        } }, { children: field.name }))] })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.set-to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                    paddingY: 1,
                                                                                                    width: "100%",
                                                                                                } }, { children: _jsx(Paper, Object.assign({ variant: "outlined" }, { children: field.custom_field_type ===
                                                                                                        "text_area" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "html_text" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingY: 1,
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: field.updated, disableUnderline: true, inputProps: {
                                                                                                                readOnly: true,
                                                                                                                style: {
                                                                                                                    fontSize: 12,
                                                                                                                },
                                                                                                            } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                __html: field.updated,
                                                                                                            } }) }))) })) }))] }))) : (field.previous &&
                                                                                        !field.updated && (_jsxs(Box, Object.assign({ sx: {
                                                                                            flexDirection: "column",
                                                                                            width: "100%",
                                                                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                                                                                            marginX: 0.5,
                                                                                                        } }, { children: field.name }))] })), _jsx(Box, Object.assign({ sx: {
                                                                                                    paddingY: 1,
                                                                                                } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                        color: "default",
                                                                                                    } }, { children: field.custom_field_type ===
                                                                                                        "text_area" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "html_text" ||
                                                                                                        field.custom_field_type ===
                                                                                                            "conversation" ? (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingY: 1,
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: field.previous, disableUnderline: true, inputProps: {
                                                                                                                readOnly: true,
                                                                                                                style: {
                                                                                                                    fontSize: 12,
                                                                                                                },
                                                                                                            } }) }))) : (_jsx(Box, Object.assign({ sx: {
                                                                                                            paddingX: 2,
                                                                                                        } }, { children: _jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: {
                                                                                                                __html: field.previous,
                                                                                                            } }) }))) })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("contacts.contact-history.removed") }))] })))) }))) }, index));
                                                                        })] })) }) }))] })] }, contactDetail.id));
                                }) })) }))] })) })), historyToDelete && historyToDelete !== "" && (_jsx(ContactHistoryDelete, { contactId: contact.id, historyToDelete: historyToDelete, setHistoryToDelete: setHistoryToDelete }))] }));
}
