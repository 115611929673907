import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import { useAlert } from "../../../services/alert";
export default function Alert() {
    const navigate = useNavigate();
    const alert = useAlert();
    return (_jsx(Snackbar, Object.assign({ sx: {
            pointerEvents: "none",
        }, open: alert.isOpened, autoHideDuration: alert.duration, onClose: () => alert.hide(), anchorOrigin: { vertical: "bottom", horizontal: "center" }, TransitionComponent: props => {
            return _jsx(Slide, Object.assign({}, props, { direction: "up" }));
        } }, { children: _jsx(MuiAlert, Object.assign({ severity: alert.type, onClose: () => alert.hide(), action: alert.actionLink && alert.actionLabel ? (_jsx(Button, Object.assign({ sx: { pointerEvents: "auto" }, color: "inherit", size: "small", onClick: () => {
                    if (alert.actionLink)
                        navigate(alert.actionLink);
                    alert.hide();
                } }, { children: alert.actionLabel }))) : undefined, elevation: 4 }, { children: alert.message })) })));
}
