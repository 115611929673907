import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../elements/blocks/intro";
import CustomFieldsTable from "../../../../elements/custom-fields/custom-fields-table";
export default function ContactFields() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("fields.contact-fields.contact-fields"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("contacts.contacts"),
                        link: "/settings/fields/contacts",
                    },
                ], button: {
                    label: t("fields.contact-fields.new-contact-field"),
                    link: "/settings/fields/contacts/new",
                    color: "secondary",
                    type: "new",
                } }), _jsx(CustomFieldsTable, { type: "contacts" })] }));
}
