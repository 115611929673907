import { jsx as _jsx } from "react/jsx-runtime";
import Link from "@mui/material/Link";
export default function ContactAddressColumn({ address }) {
    var _a;
    const addressText = ((_a = address.street_name) !== null && _a !== void 0 ? _a : "") +
        (address.street_name && address.street_number ? ` ${address.street_number}` : "") +
        (address.street_name && address.city ? `, ${address.city}` : "") +
        (!address.street_name && address.city ? address.city : "") +
        ((address.street_name || address.city) && address.country ? `, ${address.country}` : "") +
        (!address.street_name && !address.city && address.country ? address.country : "");
    return (_jsx(Link, Object.assign({ href: `https://www.google.com/maps/search/?api=1&query=${encodeURI(addressText)}`, target: "_blank", underline: "hover" }, { children: addressText })));
}
