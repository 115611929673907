import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import { usePrefs } from "../../../services/prefs";
import { useAuth } from "../../../services/auth";
import { useApiPublic } from "../../../api/api-public";
import analytics from "../../../helpers/analytics";
import { colors } from "../../../helpers/colors";
import { GlobalStyles } from "@mui/material";
const logoDark = require("../../../assets/" + process.env.APP_ID + "/logo-dark.svg");
const useStyles = makeStyles(() => createStyles({
    copy: {
        color: "#fff",
    },
}));
export default function ResetPassword() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const prefs = usePrefs();
    const auth = useAuth();
    const api = useApiPublic();
    const query = new URLSearchParams(useLocation().search);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const [responseStatus, setResponseStatus] = useState(0);
    const [responseMessage, setResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const resetPassword = () => {
        if (!query.get("email") || !query.get("token") || !password)
            return;
        setIsLoading(true);
        api.POST_resetPassword(query.get("email"), query.get("token"), password)
            .then(response => {
            setIsLoading(false);
            setResponseStatus(response.status);
            if (responseStatus === 200) {
                analytics.sendEvent({
                    category: "User",
                    action: "Reset password",
                }, auth.user);
            }
        })
            .catch(error => {
            setIsError(true);
            setErrors(error.response.data.errors);
            setIsLoading(false);
            console.error(error.response);
            setResponseStatus(error.response.status);
            setResponseMessage(error.response.data.errors.password
                ? error.response.data.errors.password[0]
                : error.response.data.errors.email
                    ? error.response.data.errors.email[0]
                    : error.response.data.errors.token
                        ? error.response.data.errors.token[0]
                        : error.response.data.message);
        });
        return () => api.cancel();
    };
    useEffect(() => {
        if (auth.user)
            navigate("/");
    }, [auth.user]);
    const validatePassword = (password) => {
        if (/[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password) &&
            /[\W_]/.test(password) &&
            password.length >= 12) {
            setPasswordError(false);
        }
        else {
            setPasswordError(true);
        }
    };
    return (_jsxs(Box, Object.assign({ sx: {
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
        } }, { children: [_jsx(GlobalStyles, { styles: {
                    body: {
                        backgroundColor: process.env.APP_ID === "mcd"
                            ? colors.primary.light
                            : colors.primary.dark,
                    },
                } }), _jsx(Box, Object.assign({ sx: {
                    position: "absolute",
                    top: 15,
                    right: 10,
                    backgroundColor: "#fff",
                } }, { children: _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "text", color: "inherit" }, { children: [_jsx(Button, Object.assign({ disabled: prefs.lang === "en", onClick: () => {
                                i18n.changeLanguage("en").then(() => {
                                    prefs.setLang("en");
                                });
                            } }, { children: "EN" })), _jsx(Button, Object.assign({ disabled: prefs.lang === "hr", onClick: () => {
                                i18n.changeLanguage("hr").then(() => {
                                    prefs.setLang("hr");
                                });
                            } }, { children: "HR" }))] })) })), _jsxs(Container, Object.assign({ maxWidth: "xs" }, { children: [_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 4,
                            paddingBottom: 2,
                        } }, { children: _jsx("img", { src: logoDark, style: {
                                maxHeight: "120px",
                                maxWidth: "220px",
                                objectFit: "contain",
                            }, alt: "Logo" }) })), _jsx(Box, Object.assign({ sx: {
                            paddingY: 4,
                        } }, { children: _jsx(Paper, Object.assign({ onKeyPress: event => {
                                if (event.key === "Enter" &&
                                    query.get("email") &&
                                    query.get("token") &&
                                    confirmPassword === password &&
                                    !isError &&
                                    !passwordError &&
                                    password !== "")
                                    resetPassword();
                            } }, { children: _jsxs(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: [responseStatus !== 200 && (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                                                    padding: 2,
                                                } }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: isError ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("password") : false }, { children: [_jsx(InputLabel, { children: t("pages.auth.reset-password.new-password") }), _jsx(OutlinedInput, { type: showPassword ? "text" : "password", label: t("pages.auth.reset-password.new-password") +
                                                                    "*", value: password, onChange: event => {
                                                                    setPassword(event.target.value);
                                                                    validatePassword(event.target.value);
                                                                    if (isError &&
                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("password"))) {
                                                                        setIsError(false);
                                                                        if (responseStatus !== 0)
                                                                            setResponseStatus(0);
                                                                    }
                                                                }, endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ onClick: () => setShowPassword(!showPassword), edge: "end", size: "large" }, { children: showPassword ? (_jsx(VisibilityIcon, {})) : (_jsx(VisibilityOffIcon, {})) })) })) })] })), passwordError && (_jsx(FormHelperText, Object.assign({ error: true }, { children: t("pages.auth.reset-password.password-validation-message") }))), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("password")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["password"][0] })))] })), _jsx(Box, Object.assign({ sx: {
                                                    padding: 2,
                                                } }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true, disabled: !password }, { children: [_jsx(InputLabel, { children: t("pages.auth.reset-password.confirm-password") }), _jsx(OutlinedInput, { type: showConfirmPassword ? "text" : "password", label: t("pages.auth.reset-password.confirm-password") + "*", value: confirmPassword, onChange: event => {
                                                                setConfirmPassword(event.target.value);
                                                                if (isError &&
                                                                    (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("password"))) {
                                                                    if (responseStatus !== 0)
                                                                        setResponseStatus(0);
                                                                }
                                                            }, endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ disabled: !password, onClick: () => setShowConfirmPassword(!showConfirmPassword), edge: "end", size: "large" }, { children: showConfirmPassword ? (_jsx(VisibilityIcon, {})) : (_jsx(VisibilityOffIcon, {})) })) })) })] })) })), _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingX: 2,
                                                    paddingTop: 2,
                                                    paddingBottom: 1,
                                                } }, { children: _jsx(LoadingButton, Object.assign({ fullWidth: true, disabled: !query.get("email") ||
                                                        !query.get("token") ||
                                                        confirmPassword !== password ||
                                                        isError ||
                                                        passwordError ||
                                                        password === "", loading: isLoading, size: "large", variant: "contained", color: "secondary", type: "button", loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), onClick: () => resetPassword() }, { children: t("pages.auth.reset-password.change-password") })) }))] })), (responseStatus !== 0 ||
                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("token")) ||
                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("email"))) && (_jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingX: 2,
                                            paddingTop: 2,
                                            paddingBottom: 1,
                                        } }, { children: responseStatus === 200 ? (_jsx(Alert, Object.assign({ style: { width: "100%" }, severity: "success" }, { children: t("pages.auth.reset-password.password-changed") }))) : responseStatus === 422 &&
                                            !responseMessage.startsWith("The password") ? (_jsx(Alert, Object.assign({ style: { width: "100%" }, severity: "error" }, { children: responseMessage }))) : (_jsx(Alert, Object.assign({ style: { width: "100%" }, severity: "error" }, { children: t("pages.auth.reset-password.error-occured") }))) }))), responseStatus === 200 && (_jsx(Box, Object.assign({ sx: {
                                            paddingX: 2,
                                            paddingTop: 2,
                                            paddingBottom: 1,
                                        } }, { children: _jsx(LoadingButton, Object.assign({ fullWidth: true, color: "secondary", variant: "contained", type: "button", onClick: () => {
                                                navigate("/login");
                                            } }, { children: t("pages.auth.reset-password.continue-to-login") })) })))] })) })) })), _jsx(Box, Object.assign({ sx: {
                            paddingTop: 2,
                            paddingBottom: 4,
                            textAlign: "center",
                        } }, { children: _jsxs(Typography, Object.assign({ className: classes.copy }, { children: [_jsx(Link, Object.assign({ href: process.env.APP_LINK, color: "inherit", underline: "hover" }, { children: process.env.APP_NAME })), _jsxs(Box, Object.assign({ component: "span", sx: {
                                        paddingLeft: 1,
                                    } }, { children: ["\u00A9 ", new Date().getFullYear()] }))] })) }))] }))] })));
}
