import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Search from "../search/search";
import Notifications from "./notifications";
import UserMenu from "./user-menu";
import { useRoles } from "../../../services/roles";
import { useTenancy } from "../../../services/tenancy";
export default function QuickActions() {
    const roles = useRoles();
    const tenancy = useTenancy();
    return (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "160px",
        } }, { children: [_jsxs(Box, Object.assign({ sx: {
                    marginRight: 1,
                } }, { children: [(roles.isAdmin || roles.isManager) && _jsx(Search, {}), ((process.env.APP_ID === "mcd" && tenancy.client) ||
                        process.env.APP_ID !== "mcd") && _jsx(Notifications, {})] })), _jsx(Box, { children: _jsx(UserMenu, {}) })] })));
}
