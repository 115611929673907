import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import LogsTable from "../../../elements/logs/logs-table";
export default function Logs() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("logs.logs"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("logs.logs"),
                        link: "/settings/logs",
                    },
                ] }), _jsx(LogsTable, { type: "client", title: t("logs.all-logs") })] }));
}
