import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createSingletonHook } from "../helpers/singleton";
import { useApiCentral } from "../api/api-central";
import { useAuth } from "./auth";
import { usePrefs } from "./prefs";
export const [useTenancy, TenancyProvider] = createSingletonHook(() => {
    const navigate = useNavigate();
    const api = useApiCentral();
    const auth = useAuth();
    const prefs = usePrefs();
    const host = location.host;
    // @ts-ignore
    const domains = process.env.APP_DOMAINS.split(",");
    const [subDomain] = useState(domains.includes(host) ? "" : host.substr(0, host.indexOf(".")));
    const [isSubDomain] = useState(subDomain !== "");
    const [isLoading, setIsLoading] = useState(false);
    const [refreshCampaigns, setRefreshCampaigns] = useState(false);
    const [client, setClient] = useState(null);
    const [clients, setClients] = useState([]);
    useEffect(() => {
        if (auth.user && prefs.centralToken)
            currentUserClients();
    }, [auth.user, prefs.centralToken]);
    useEffect(() => {
        if (clients.length)
            currentUserClient();
    }, [clients]);
    const getTenantToken = (client) => {
        setIsLoading(true);
        api.GET_tenantToken(client.id)
            .then(response => {
            prefs.setTenantToken(response.data.token);
            setClient(client);
            navigate("/" + client.domain + "/dashboard");
        })
            .catch(error => {
            console.error(error);
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    const currentUserClients = () => {
        setIsLoading(true);
        api.GET_currentUserClients(10000, 1)
            .then(response => {
            setClients(response.data.tenants.sort((a, b) => a.name.localeCompare(b.name)));
        })
            .catch(error => {
            console.error(error);
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    const currentUserClient = () => {
        if (isSubDomain) {
            const client = clients.find(client => {
                return subDomain === client.domain;
            });
            if (client)
                setClient(client);
            else
                auth.logOut();
        }
        else {
            const clientDomain = window.location.pathname.split("/")[1];
            const client = clients.find(client => {
                return clientDomain === client.domain;
            });
            if (client)
                setClient(client);
        }
    };
    const link = (url) => {
        return isSubDomain ? url : "/" + (client === null || client === void 0 ? void 0 : client.domain) + url;
    };
    const linkToSubDomainLink = (url) => {
        var _a;
        let appUrl = (_a = process.env.APP_URL) !== null && _a !== void 0 ? _a : "";
        if (process.env.APP_ENV === "local") {
            return appUrl + url;
        }
        appUrl = appUrl.replace("https://", "").replace("http://", "");
        return "https://" + (client === null || client === void 0 ? void 0 : client.domain) + "." + appUrl + url;
    };
    return {
        subDomain,
        isSubDomain,
        isLoading,
        client,
        clients,
        setClient,
        getTenantToken,
        currentUserClients,
        link,
        linkToSubDomainLink,
        refreshCampaigns,
        setRefreshCampaigns,
    };
});
