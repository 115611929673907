import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import ContactForm from "../../elements/contacts/contact-form";
export default function ContactNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("contacts.new-contact"), breadcrumbs: [
                    {
                        name: t("contacts.contacts"),
                        link: "/contacts",
                    },
                    {
                        name: t("contacts.new-contact"),
                        link: "/contacts/new",
                    },
                ] }), _jsx(ContactForm, { kind: "contact", status: "new" })] }));
}
