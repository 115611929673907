import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
export default function InputSwitch({ label, checked, required, setChecked, info, disabled, labelPlacement, error, errors, isError, setIsError, }) {
    return (_jsxs(FormControl, Object.assign({ variant: "standard", error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", disabled: disabled ? disabled : false, required: required, checked: checked, onChange: () => {
                        setChecked(!checked);
                        if (isError &&
                            errors &&
                            error &&
                            setIsError &&
                            (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                            setIsError(false);
                    } }), labelPlacement: labelPlacement ? labelPlacement : "end", label: label ? label : "" }), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] })));
}
