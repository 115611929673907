import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import Typography from "@mui/material/Typography";
export default function TicketDateColumn({ date }) {
    return (_jsx(Fragment, { children: date && (_jsx(Typography, { children: new Date(Date.parse(date)).toLocaleDateString("en-GB") +
                " " +
                new Date(Date.parse(date)).toLocaleTimeString("hr-HR", {
                    hour: "2-digit",
                    minute: "2-digit",
                }) })) }));
}
