import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { usePrefs } from "../../../services/prefs";
import { prettifyText } from "../../../helpers/clean";
export default function TicketQuickFilters({ campaign, isLoading, filterStatuses, setFilterStatuses, filterPriorities, setFilterPriorities, showCount, statusCount, priorityCount, }) {
    const prefs = usePrefs();
    const isMobile = useMediaQuery("(max-width: 767px)");
    if (campaign.ticket_statuses &&
        campaign.ticket_statuses.length === 1 &&
        campaign.ticket_priorities &&
        campaign.ticket_priorities.length === 1) {
        return null;
    }
    return (_jsx(Box, Object.assign({ sx: {
            overflow: "hidden",
            marginBottom: 2,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2, justifyContent: "space-between", direction: "row" }, { children: [campaign.ticket_statuses && campaign.ticket_statuses.length > 1 && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: "auto", sx: {
                        flexBasis: "fit-content !important",
                    } }, { children: _jsx(ToggleButtonGroup, Object.assign({ exclusive: true, value: filterStatuses, onChange: (event, filterStatus) => {
                            if (filterStatuses.find(status => status.id === filterStatus.id)) {
                                let newFilterStatuses = [];
                                filterStatuses.map(status => {
                                    if (status.id !== filterStatus.id)
                                        newFilterStatuses.push(status);
                                });
                                setFilterStatuses(newFilterStatuses);
                            }
                            else {
                                setFilterStatuses([...filterStatuses, filterStatus]);
                            }
                        }, style: {
                            display: "inline-block",
                        } }, { children: campaign.ticket_statuses
                            .sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
                            .sort((a, b) => a.type.toString().localeCompare(b.type.toString()))
                            .map(status => {
                            var _a;
                            const ticketCount = showCount &&
                                statusCount &&
                                ((_a = statusCount.find(ticketStatus => ticketStatus.id === status.id)) === null || _a === void 0 ? void 0 : _a.ticket_count);
                            return (_jsx(ToggleButton, Object.assign({ disabled: isLoading, value: status, size: "small", style: {
                                    textTransform: "initial",
                                    borderRadius: "5px",
                                    margin: "0 10px 10px 0",
                                    border: prefs.theme === "light"
                                        ? "1px solid rgba(0, 0, 0, 0.12)"
                                        : "1px solid rgba(255, 255, 255, 0.12)",
                                }, selected: !!filterStatuses.find(s => s.id === status.id) }, { children: _jsxs(Grid, Object.assign({ container: true, style: {
                                        flexWrap: "nowrap",
                                        alignItems: "center",
                                    } }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                    color: prefs.theme === "light"
                                                        ? "rgba(0, 0, 0, 0.87)"
                                                        : "rgba(255, 255, 255, 0.87)",
                                                } }, { children: prettifyText(status.name) })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Chip, { size: "small", style: {
                                                    border: "1px solid " +
                                                        (status.type === "pending"
                                                            ? "rgba(156, 39, 176, 1)"
                                                            : status.type === "resolved"
                                                                ? "rgba(76, 175, 80, 1)"
                                                                : "rgba(37, 64, 146, 1)"),
                                                    backgroundColor: status.type === "pending"
                                                        ? "rgba(156, 39, 176, 0.2)"
                                                        : status.type === "resolved"
                                                            ? "rgba(76, 175, 80, 0.2)"
                                                            : "rgba(37, 64, 146, 0.2)",
                                                    display: "flex",
                                                    marginLeft: "0.5rem",
                                                    width: ticketCount || ticketCount === 0
                                                        ? "auto"
                                                        : "12px",
                                                    height: ticketCount || ticketCount === 0
                                                        ? "24px"
                                                        : "12px",
                                                    borderRadius: "16px",
                                                }, label: ticketCount }) }))] })) }), status.id));
                        }) })) }))), campaign.ticket_priorities && campaign.ticket_priorities.length > 1 && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: "auto", sx: {
                        flexBasis: "fit-content !important",
                    } }, { children: _jsx(ToggleButtonGroup, Object.assign({ exclusive: true, value: filterPriorities, onChange: (event, filterPriority) => {
                            if (filterPriorities.find(priority => priority.id === filterPriority.id)) {
                                let newFilterPriorities = [];
                                filterPriorities.map(priority => {
                                    if (priority.id !== filterPriority.id)
                                        newFilterPriorities.push(priority);
                                });
                                setFilterPriorities(newFilterPriorities);
                            }
                            else {
                                setFilterPriorities([...filterPriorities, filterPriority]);
                            }
                        }, style: {
                            display: "inline-block",
                            justifyContent: isMobile ? "flex-start" : "flex-end",
                        } }, { children: campaign.ticket_priorities
                            .sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
                            .sort((a, b) => a.level - b.level)
                            .map((priority, index) => {
                            var _a;
                            const ticketCount = showCount &&
                                priorityCount &&
                                ((_a = priorityCount.find(ticketPriority => ticketPriority.id === priority.id)) === null || _a === void 0 ? void 0 : _a.ticket_count);
                            return (_jsx(ToggleButton, Object.assign({ disabled: isLoading, value: priority, size: "small", style: {
                                    textTransform: "initial",
                                    borderRadius: "5px",
                                    margin: index + 1 === campaign.ticket_priorities.length
                                        ? "0 0 10px 0"
                                        : "0 10px 10px 0",
                                    border: prefs.theme === "light"
                                        ? "1px solid rgba(0, 0, 0, 0.12)"
                                        : "1px solid rgba(255, 255, 255, 0.12)",
                                }, selected: !!filterPriorities.find(p => p.id === priority.id) }, { children: _jsxs(Grid, Object.assign({ container: true, style: {
                                        flexWrap: "nowrap",
                                        alignItems: "center",
                                    } }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                    color: prefs.theme === "light"
                                                        ? "rgba(0, 0, 0, 0.87)"
                                                        : "rgba(255, 255, 255, 0.87)",
                                                } }, { children: prettifyText(priority.name) })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Chip, { size: "small", style: {
                                                    border: "1px solid " +
                                                        (priority.level < 33
                                                            ? "rgb(244, 67, 54, 1)"
                                                            : priority.level >= 33 &&
                                                                priority.level <= 66
                                                                ? "rgb(255, 152, 0, 1)"
                                                                : "rgb(33, 150, 243, 1)"),
                                                    backgroundColor: priority.level < 33
                                                        ? "rgb(244, 67, 54, 0.2)"
                                                        : priority.level >= 33 &&
                                                            priority.level <= 66
                                                            ? "rgb(255, 152, 0, 0.2)"
                                                            : "rgb(33, 150, 243, 0.2)",
                                                    display: "flex",
                                                    marginLeft: "0.5rem",
                                                    width: ticketCount || ticketCount === 0
                                                        ? "auto"
                                                        : "12px",
                                                    height: ticketCount || ticketCount === 0
                                                        ? "24px"
                                                        : "12px",
                                                    borderRadius: "16px",
                                                }, label: ticketCount }) }))] })) }), priority.id));
                        }) })) })))] })) })));
}
