import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../../elements/blocks/intro";
import ChoiceFieldForm from "../../../../../elements/campaigns/choice-fields/choice-field-form";
export default function ChoiceFieldNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("fields.shared-fields.choice-fields.new-choice-field"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("fields.shared-fields.shared-fields"),
                        link: "/settings/fields/shared",
                    },
                    {
                        name: t("fields.shared-fields.choice-fields.choices"),
                        link: "/settings/fields/shared/choices",
                    },
                    {
                        name: t("fields.new-field"),
                        link: "/settings/fields/shared/choices/new",
                    },
                ] }), _jsx(ChoiceFieldForm, { status: "new" })] }));
}
