import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MoreIcon from "@mui/icons-material/ExpandMore";
import ContactPhones from "../contacts/contact-phones";
import CustomFieldsView from "../custom-fields/custom-fields-view";
import ContactEmails from "../contacts/contact-emails";
import { useTenancy } from "../../../services/tenancy";
export default function SearchContactCard({ contact, type, setContact, lead, setLead }) {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const [isExpanded, setExpanded] = useState(false);
    return (_jsxs(Card, Object.assign({ variant: "outlined", style: {
            position: "relative",
        } }, { children: [_jsx(CardActionArea, Object.assign({ disabled: type === "use", onClick: () => type === "view" ? navigate(tenancy.link("/contacts/" + contact.id)) : undefined }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                            paddingRight: 4,
                        } }, { children: [contact.type && (_jsx(Typography, Object.assign({ variant: "caption" }, { children: contact.type.toUpperCase() }))), _jsx(Typography, Object.assign({ variant: "h6" }, { children: contact.type
                                    ? contact.type === "business"
                                        ? contact.company
                                            ? contact.company
                                            : contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : contact.first_name || contact.last_name
                                                    ? (contact.first_name ? contact.first_name : "") +
                                                        (contact.last_name ? " " + contact.last_name : "")
                                                    : ""
                                        : contact.is_anonymous === true
                                            ? t("contacts.anonymous")
                                            : contact.first_name || contact.last_name
                                                ? (contact.first_name ? contact.first_name : "") +
                                                    (contact.last_name ? " " + contact.last_name : "")
                                                : contact.company
                                                    ? contact.company
                                                    : ""
                                    : contact.company
                                        ? contact.company
                                        : contact.is_anonymous === true
                                            ? t("contacts.anonymous")
                                            : contact.first_name || contact.last_name
                                                ? (contact.first_name ? contact.first_name : "") +
                                                    (contact.last_name ? " " + contact.last_name : "")
                                                : "" })), contact.type ? (contact.type === "business" ? (contact.company ? (_jsx(Typography, Object.assign({ variant: "body1" }, { children: contact.first_name || contact.last_name
                                    ? ((_a = contact.first_name) !== null && _a !== void 0 ? _a : "") +
                                        " " +
                                        ((_b = contact.last_name) !== null && _b !== void 0 ? _b : "")
                                    : "" }))) : ("")) : contact.company && (contact.first_name || contact.last_name) ? (_jsx(Typography, Object.assign({ variant: "body1" }, { children: contact.company }))) : ("")) : contact.company ? (_jsx(Typography, Object.assign({ variant: "body1" }, { children: contact.first_name || contact.last_name
                                    ? ((_c = contact.first_name) !== null && _c !== void 0 ? _c : "") + " " + ((_d = contact.last_name) !== null && _d !== void 0 ? _d : "")
                                    : "" }))) : ("")] })) }) })), _jsx(Box, Object.assign({ sx: {
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                } }, { children: _jsx(IconButton, Object.assign({ onClick: event => {
                        event.stopPropagation();
                        setExpanded(!isExpanded);
                    }, size: "large", disabled: !(contact.phones.length > 0 ||
                        contact.emails.length > 0 ||
                        contact.custom_fields.length > 0) }, { children: _jsx(MoreIcon, {}) })) })), _jsx(Collapse, Object.assign({ in: isExpanded }, { children: _jsxs(CardContent, { children: [contact.phones.length > 0 && _jsx(ContactPhones, { phones: contact.phones }), contact.phones.length > 0 && contact.emails.length > 0 && (_jsx(Box, { sx: {
                                paddingY: 1,
                            } })), contact.emails.length > 0 && _jsx(ContactEmails, { emails: contact.emails }), contact.custom_fields && contact.custom_fields.length > 0 && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { paddingTop: 2, paddingBottom: 3 } }, { children: _jsx(Divider, {}) })), _jsx(CustomFieldsView, { status: "card", type: "contacts", customFields: contact.custom_fields })] }))] }) })), type === "use" && (_jsx(Box, Object.assign({ sx: {
                    paddingLeft: 1,
                    paddingBottom: 1,
                } }, { children: _jsx(Button, Object.assign({ onClick: () => {
                        setContact(contact);
                        lead && setLead && setLead(lead);
                    } }, { children: t("contacts.use-this-contact") })) })))] })));
}
