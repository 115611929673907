import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import NextIcon from "@mui/icons-material/NavigateNext";
import Intro from "../../elements/blocks/intro";
import TicketInformationForm from "../../elements/tickets/ticket-information-form";
import Nope from "../../elements/blocks/nope";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { useApiTenant } from "../../../api/api-tenant";
export default function CampaignTicketDemo() {
    var _a, _b;
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const api = useApiTenant();
    const [isLoading, setIsLoading] = useState(true);
    const [campaign, setCampaign] = useState(null);
    useEffect(() => {
        api.GET_campaign(id)
            .then(response => {
            setCampaign(response.data.campaign);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.new-ticket"), breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: campaign ? campaign.name : t("campaigns.campaign") + " #" + id,
                        link: "/campaigns/" + id,
                    },
                    {
                        name: t("tickets.new-ticket"),
                        link: "/campaigns/" + id + "/new-ticket",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : campaign ? (_jsxs(Fragment, { children: [campaign.instructions && (_jsx(Box, Object.assign({ sx: {
                            marginBottom: 2,
                        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4, lg: 2 }, { children: _jsx(Box, Object.assign({ sx: {
                                            position: "sticky",
                                            top: "100px",
                                        } }, { children: _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.welcome") })) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8, lg: 10 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                                paddingX: 4,
                                                paddingY: 2,
                                            } }, { children: _jsx(Typography, { dangerouslySetInnerHTML: {
                                                    __html: campaign.instructions.replace("{{user_first_name}}", (_b = (_a = roles.userTenant) === null || _a === void 0 ? void 0 : _a.first_name) !== null && _b !== void 0 ? _b : "Agent"),
                                                } }) })) }) }))] })) }))), _jsx(TicketInformationForm, { campaign: campaign, isDemo: true }), _jsx(Box, Object.assign({ sx: {
                            marginBottom: 2,
                        } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    } }, { children: _jsx(Button, Object.assign({ variant: "contained", color: "primary", endIcon: _jsx(NextIcon, {}), onClick: () => {
                                            navigate(tenancy.link("/campaigns/" + id));
                                        } }, { children: t("tickets.finish") })) })) })) })) }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("campaigns.errors.not-found") }))] }));
}
