import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
export default function NotificationOptionDelete({ idToDelete, setIdToDelete, setIsDeleted, type, }) {
    const { t } = useTranslation();
    const alert = useAlert();
    const api = useApiTenant();
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteNotificationOption = () => {
        setIsDeleting(true);
        api.DELETE_notificationOption(idToDelete, type)
            .then(() => {
            alert.show(t("notifications.notification-option-delete.option-deleted"), "warning");
            setIsDeleting(false);
            setIsDeleted(true);
            setIdToDelete("");
        })
            .catch(error => {
            console.error(error.response);
            setIsDeleting(false);
            setIdToDelete("");
            alert.show(t("notifications.notification-option-delete.deleting-error-occured"), "error");
        });
    };
    return (_jsxs(Dialog, Object.assign({ open: idToDelete !== "", onClose: () => {
            setIdToDelete("");
        } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: t("notifications.notification-option-delete.confirm") }), _jsx(DialogContentText, { children: _jsx("em", { children: t("delete-component.confirm-caption") }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                            setIdToDelete("");
                        } }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isDeleting, onClick: () => deleteNotificationOption(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), style: {
                            color: "rgb(244, 67, 54)",
                        } }, { children: t("notifications.notification-option-delete.remove") }))] })] })));
}
