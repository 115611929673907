import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/BubbleChart";
export default function UserGroups({ groups }) {
    const { t } = useTranslation();
    const userGroups = (groups, level, count) => {
        return groups.map((group, index) => {
            let children = null;
            count++;
            if (group.groups && group.groups.length > 0) {
                children = userGroups(group.groups, level + 1, count);
            }
            return (_jsxs(Fragment, { children: [level === 0 ? (_jsxs(Fragment, { children: [count > 1 && (_jsx(Box, Object.assign({ sx: {
                                    paddingTop: 1,
                                    paddingBottom: 2,
                                } }, { children: _jsx(Divider, { light: true }) }))), _jsx(Chip, { style: {
                                    marginRight: 6,
                                    marginBottom: 10,
                                }, size: "small", color: "secondary", label: group.name })] })) : (_jsx(Chip, { style: {
                            marginRight: 6,
                            marginBottom: 10,
                        }, size: "small", label: group.name })), _jsx(Fragment, { children: children })] }, index));
        });
    };
    return (_jsx(Box, Object.assign({ sx: {
            paddingTop: 2,
            marginBottom: 2,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                    marginRight: 2,
                                } }, { children: _jsx(GroupsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("users.queues") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("users.user-queues") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                padding: 4,
                            } }, { children: userGroups(groups, 0, 0) })) }) }))] })) })));
}
