import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import ConditionForm from "../../../elements/conditions/condition-form";
import ConditionsTable from "../../../elements/conditions/conditions-table";
export default function Conditions() {
    const { t } = useTranslation();
    const [isAddOpened, setIsAddOpened] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("conditions.conditions"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("conditions.conditions"),
                        link: "/notifications",
                    },
                ], button: {
                    label: t("conditions.new-condition"),
                    action: () => setIsAddOpened(true),
                    color: "secondary",
                    type: "new",
                } }), _jsx(ConditionsTable, { title: t("conditions.conditions"), isAdded: isAdded, setIsAdded: setIsAdded }), _jsx(ConditionForm, { status: "new", isOpened: isAddOpened, setIsOpened: setIsAddOpened, setIsAdded: setIsAdded })] }));
}
