import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Dropzone from "react-dropzone";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ImportIcon from "@mui/icons-material/Publish";
import FilteredIcon from "@mui/icons-material/FilterAlt";
import NewIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import DownloadIcon from "@mui/icons-material/GetApp";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import Table from "../../blocks/table";
import AudienceLeadDelete from "./audience-lead-delete";
import AudienceLeadForm from "./audience-lead-form";
import AudienceLeadsFilters from "./audience-leads-filters";
import LeadAssigneeRemove from "./audience-lead-remove-assignee";
import { useApiTenant } from "../../../../api/api-tenant";
import { useAlert } from "../../../../services/alert";
import { usePrefs } from "../../../../services/prefs";
import { useAuth } from "../../../../services/auth";
import { useRoles } from "../../../../services/roles";
import { useTenancy } from "../../../../services/tenancy";
import { copy } from "../../../../helpers/copy";
import analytics from "../../../../helpers/analytics";
export default function AudienceLeadsTable({ campaignId, audienceId, setRefreshMetrics }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useApiTenant();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const auth = useAuth();
    const roles = useRoles();
    const alert = useAlert();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [isAdded, setIsAdded] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const [lead, setLead] = useState(null);
    const [isRemoved, setIsRemoved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [isImportOpened, setIsImportOpened] = useState(false);
    const [isAddOpened, setIsAddOpened] = useState(false);
    const [importedFile, setImportedFile] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [filterPhoneCountry, setFilterPhoneCountry] = useState(null);
    const [filterPhoneArea, setFilterPhoneArea] = useState(null);
    const [filterStatus, setFilterStatus] = useState("all");
    const [filterAssignees, setFilterAssignees] = useState([]);
    const [filterContacts, setFilterContacts] = useState([]);
    const [filterUpdatedAtStartDate, setFilterUpdatedAtStartDate] = useState("");
    const [filterUpdatedAtStartTime, setFilterUpdatedAtStartTime] = useState("");
    const [filterUpdatedAtEndDate, setFilterUpdatedAtEndDate] = useState("");
    const [filterUpdatedAtEndTime, setFilterUpdatedAtEndTime] = useState("");
    const [filter, setFilter] = useState(false);
    const [areFieldsAndGroupsLoaded, setAreFieldsAndGroupsLoaded] = useState(false);
    const [phoneCountries, setPhoneCountries] = useState([]);
    const [phoneAreas, setPhoneAreas] = useState([]);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (isLoading || !filter)
            return;
        resetTablePageAndRefreshData();
        setFilter(false);
        return () => api.cancel();
    }, [filter]);
    useEffect(() => {
        if (!isAdded && !isDeleted && !isRemoved)
            return;
        if (setRefreshMetrics)
            setRefreshMetrics(true);
        refreshTableData();
        if (isAdded)
            setIsAdded(false);
        if (isDeleted)
            setIsDeleted(false);
        if (isRemoved)
            setIsRemoved(false);
    }, [isAdded, isDeleted, isRemoved]);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_audienceLeads(campaignId, audienceId, query.pageSize, query.page + 1, query.orderDirection ? query.orderDirection : "desc", query.search ? query.search : null, filterPhoneCountry ? filterPhoneCountry.id : undefined, filterPhoneArea ? filterPhoneArea.id : undefined, filterStatus !== "all" ? filterStatus : undefined, filterAssignees.length > 0 ? filterAssignees.map(assignee => assignee.id) : [], filterContacts.length > 0 ? filterContacts.map(contact => contact.id) : [], filterUpdatedAtStartDate !== ""
                ? filterUpdatedAtStartTime !== ""
                    ? format(new Date(filterUpdatedAtStartDate), "yyyy-MM-dd") +
                        " " +
                        format(new Date(filterUpdatedAtStartTime), "HH:mm:ss")
                    : format(new Date(filterUpdatedAtStartDate), "yyyy-MM-dd") + " 00:00:00"
                : undefined, filterUpdatedAtEndDate !== ""
                ? filterUpdatedAtEndTime !== ""
                    ? format(new Date(filterUpdatedAtEndDate), "yyyy-MM-dd") +
                        " " +
                        format(new Date(filterUpdatedAtEndTime), "HH:mm:ss")
                    : format(new Date(filterUpdatedAtEndDate), "yyyy-MM-dd") + " 23:59:59"
                : undefined)
                .then(response => {
                resolve({
                    data: response.data.leads,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
                if (showFilters)
                    setShowFilters(false);
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    const resetTablePageAndRefreshData = () => {
        var _a;
        setIsLoading(true);
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onChangePage(null, 0);
    };
    const downloadSample = () => {
        setIsDownloading(true);
        api.IMPORT_audienceLeadsSample()
            .then(response => {
            saveAs(new Blob([response.data], {
                type: "text/vnd.ms-excel;charset=utf-8",
            }), t("campaigns.audiences.leads.leads-table.import-sample") + ".xlsx");
            alert.show(t("campaigns.audiences.leads.leads-table.sample-downloaded"), "info");
            setIsDownloading(false);
        })
            .catch(error => {
            console.error(error.response);
            alert.show(t("campaigns.audiences.leads.leads-table.downloading-sample-error"), "error");
            setIsDownloading(false);
        });
    };
    const importAudience = () => {
        if (!importedFile)
            return;
        setIsSaving(true);
        api.IMPORT_audienceLeads(campaignId, audienceId, importedFile)
            .then(() => {
            alert.show(t("campaigns.audiences.leads.leads-table.audience-imported"), "info");
            setIsSaving(false);
            setIsImportOpened(false);
            setImportedFile(null);
            setIsAdded(true);
        })
            .catch(error => {
            console.error(error.response);
            alert.show(t("campaigns.audiences.leads.leads-table.importing-error"), "error");
            setIsSaving(false);
            setIsImportOpened(false);
        });
    };
    const exportData = query => {
        setIsExporting(true);
        api.EXPORT_audienceLeads(campaignId, audienceId, query.orderDirection ? query.orderDirection : "desc", query.search ? query.search : null, filterPhoneCountry ? filterPhoneCountry.id : undefined, filterPhoneArea ? filterPhoneArea.id : undefined, filterStatus !== "all" ? filterStatus : undefined, filterAssignees.length > 0 ? filterAssignees.map(assignee => assignee.id) : [], filterContacts.length > 0 ? filterContacts.map(contact => contact.id) : [], filterUpdatedAtStartDate !== ""
            ? filterUpdatedAtStartTime !== ""
                ? format(new Date(filterUpdatedAtStartDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterUpdatedAtStartTime), "HH:mm:ss")
                : format(new Date(filterUpdatedAtStartDate), "yyyy-MM-dd") + " 00:00:00"
            : undefined, filterUpdatedAtEndDate !== ""
            ? filterUpdatedAtEndTime !== ""
                ? format(new Date(filterUpdatedAtEndDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterUpdatedAtEndTime), "HH:mm:ss")
                : format(new Date(filterUpdatedAtEndDate), "yyyy-MM-dd") + " 23:59:59"
            : undefined)
            .then(response => {
            var _a;
            setIsExporting(false);
            saveAs(new Blob([response.data], {
                type: "text/vnd.ms-excel;charset=utf-8",
            }), t("campaigns.audiences.leads.leads") +
                " - " +
                ((_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.name) +
                " @ " +
                process.env.APP_NAME +
                " - " +
                new Date().toLocaleDateString("hr-HR") +
                ".xlsx");
            alert.show(t("campaigns.audiences.leads.leads-table.leads-exported"), "success");
        })
            .catch(error => {
            setIsExporting(false);
            console.error(error);
            alert.show(t("campaigns.audiences.leads.leads-table.exporting-error"), "error");
        });
        analytics.sendEvent({
            category: "Export",
            action: "Export audience",
        }, auth.user);
    };
    return (_jsxs(Fragment, { children: [_jsx(Table, { title: t("campaigns.audiences.leads.leads-table.audience-leads"), actions: [
                    {
                        icon: () => _jsx(FilteredIcon, { color: "action" }),
                        tooltip: t("campaigns.audiences.leads.leads-table.filter-leads"),
                        isFreeAction: true,
                        onClick: () => setShowFilters(true),
                    },
                    roles.isAdmin
                        ? {
                            icon: () => isExporting ? (_jsx(Box, Object.assign({ sx: {
                                    paddingX: "3px",
                                } }, { children: _jsx(CircularProgress, { color: "inherit", size: 18 }) }))) : (_jsx(DownloadIcon, { color: "action" })),
                            tooltip: t("campaigns.audiences.leads.leads-table.export-leads"),
                            isFreeAction: true,
                            onClick: () => exportData(tableQuery),
                        }
                        : undefined,
                    roles.isAdmin
                        ? {
                            icon: () => _jsx(ImportIcon, { color: "action" }),
                            tooltip: t("campaigns.audiences.leads.leads-table.import-leads"),
                            isFreeAction: true,
                            onClick: () => setIsImportOpened(true),
                        }
                        : undefined,
                    {
                        icon: () => _jsx(RefreshIcon, { color: "action" }),
                        tooltip: t("campaigns.audiences.leads.leads-table.refresh-leads"),
                        isFreeAction: true,
                        onClick: () => refreshTableData(),
                    },
                    {
                        icon: () => _jsx(NewIcon, { color: "action" }),
                        tooltip: t("campaigns.audiences.leads.leads-table.new-lead"),
                        isFreeAction: true,
                        onClick: () => {
                            setIsAddOpened(true);
                        },
                    },
                    data => ({
                        disabled: !data.assignee ||
                            data.status === "completed" ||
                            (data.assignee && data.status === "invalid"),
                        icon: () => (_jsx(PersonRemoveIcon, { color: !data.assignee ||
                                data.status === "completed" ||
                                (data.assignee && data.status === "invalid")
                                ? "disabled"
                                : "action" })),
                        tooltip: !data.assignee ||
                            data.status === "completed" ||
                            (data.assignee && data.status === "invalid")
                            ? ""
                            : t("campaigns.audiences.leads.leads-table.remove-assignee"),
                        onClick: () => {
                            setLead(data);
                        },
                    }),
                    {
                        icon: () => _jsx(DeleteIcon, { color: "action" }),
                        tooltip: t("campaigns.audiences.leads.lead-delete.delete-lead"),
                        onClick: (event, data) => {
                            setIdToDelete(data.id);
                        },
                    },
                ], columns: isMobile
                    ? [
                        {
                            title: t("campaigns.audiences.leads.lead-attributes.phone") + " #",
                            field: "id",
                            defaultSort: "desc",
                        },
                    ]
                    : [
                        {
                            maxWidth: "80px",
                            title: "#",
                            field: "id",
                            type: "numeric",
                            defaultSort: "desc",
                        },
                        {
                            title: t("campaigns.audiences.leads.lead"),
                            sorting: false,
                            render: lead => {
                                return (_jsx(Chip, { style: {
                                        color: lead.phone.on_central_blacklist
                                            ? "#f44336"
                                            : lead.phone.on_tenant_blacklist
                                                ? "#ff9800"
                                                : "inherit",
                                    }, variant: "outlined", size: "small", label: lead.phone.phone_area.phone_country.phone !== "00"
                                        ? lead.phone.phone_area.phone_country.phone +
                                            " " +
                                            lead.phone.phone_area.phone +
                                            " " +
                                            lead.phone.number.substring(0, 4) +
                                            " " +
                                            lead.phone.number.substring(4, lead.phone.number.length)
                                        : lead.phone.number, icon: lead.phone.on_central_blacklist ||
                                        lead.phone.on_tenant_blacklist ? (_jsx(Tooltip
                                    // @ts-ignore
                                    , Object.assign({ 
                                        // @ts-ignore
                                        title: lead.phone.on_central_blacklist
                                            ? t("contacts.contact-phones.phone-blacklisted") + " (HAKOM)"
                                            : t("contacts.contact-phones.phone-blacklisted") }, { children: _jsx(ErrorIcon, { style: {
                                                color: lead.phone
                                                    .on_central_blacklist
                                                    ? "#f44336"
                                                    : "#ff9800",
                                            } }) }))) : undefined, onDelete: () => {
                                        copy(lead.phone.phone_area.phone_country.phone !==
                                            "00"
                                            ? lead.phone.phone_area.phone_country
                                                .phone +
                                                lead.phone.phone_area.phone +
                                                lead.phone.number
                                            : lead.phone.number);
                                        alert.show(t("phone-copied"), "info");
                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                        // @ts-ignore
                                        title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) }));
                            },
                        },
                        {
                            title: t("campaigns.audiences.leads.lead-attributes.status"),
                            sorting: false,
                            render: lead => {
                                return (_jsx(Chip, { size: "small", label: lead.status === "pending"
                                        ? t("campaigns.audiences.leads.lead-attributes.status-types.pending")
                                        : lead.status === "invalid"
                                            ? t("campaigns.audiences.leads.lead-attributes.status-types.invalid")
                                            : lead.status === "completed"
                                                ? t("campaigns.audiences.leads.lead-attributes.status-types.completed")
                                                : t("campaigns.audiences.leads.lead-attributes.status-types.checking"), style: {
                                        border: "1px solid " +
                                            (lead.status === "pending"
                                                ? "rgb(33, 150, 243, 1)"
                                                : lead.status === "invalid"
                                                    ? "rgb(244, 67, 54, 1)"
                                                    : lead.status === "completed"
                                                        ? "rgb(76, 175, 80, 1)"
                                                        : "rgb(255, 203, 5, 1)"),
                                        backgroundColor: lead.status === "pending"
                                            ? "rgb(33, 150, 243, 0.2)"
                                            : lead.status === "invalid"
                                                ? "rgb(244, 67, 54, 0.2)"
                                                : lead.status === "completed"
                                                    ? "rgb(76, 175, 80, 0.2)"
                                                    : "rgb(255, 203, 5, 0.2)",
                                    } }));
                            },
                        },
                        {
                            title: t("tickets.ticket"),
                            sorting: false,
                            render: lead => {
                                return (lead.ticket &&
                                    lead.ticket.contact && (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/tickets/" + lead.ticket.id), onClick: event => {
                                        event.preventDefault();
                                        navigate(tenancy.link("/tickets/" + lead.ticket.id));
                                    }, underline: "hover" }, { children: lead.ticket.contact.type
                                        ? lead.ticket.contact.type === "business"
                                            ? lead.ticket.contact.company
                                                ? lead.ticket.contact.company
                                                : lead.ticket.contact.is_anonymous ===
                                                    true
                                                    ? t("contacts.anonymous")
                                                    : lead.ticket.contact.first_name ||
                                                        lead.ticket.contact.last_name
                                                        ? (lead.ticket.contact.first_name
                                                            ? lead.ticket.contact.first_name
                                                            : "") +
                                                            (lead.ticket.contact.last_name
                                                                ? " " +
                                                                    lead.ticket.contact.last_name
                                                                : "")
                                                        : ""
                                            : lead.ticket.contact.is_anonymous ===
                                                true
                                                ? t("contacts.anonymous")
                                                : lead.ticket.contact.first_name ||
                                                    lead.ticket.contact.last_name
                                                    ? (lead.ticket.contact.first_name
                                                        ? lead.ticket.contact.first_name
                                                        : "") +
                                                        (lead.ticket.contact.last_name
                                                            ? " " +
                                                                lead.ticket.contact.last_name
                                                            : "")
                                                    : lead.ticket.contact.company
                                                        ? lead.ticket.contact.company
                                                        : ""
                                        : lead.ticket.contact.company
                                            ? lead.ticket.contact.company
                                            : lead.ticket.contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : lead.ticket.contact.first_name ||
                                                    lead.ticket.contact.last_name
                                                    ? (lead.ticket.contact.first_name
                                                        ? lead.ticket.contact.first_name
                                                        : "") +
                                                        (lead.ticket.contact.last_name
                                                            ? " " + lead.ticket.contact.last_name
                                                            : "")
                                                    : "" }))));
                            },
                        },
                        {
                            title: t("campaigns.audiences.leads.lead-attributes.assignee"),
                            sorting: false,
                            render: lead => {
                                return lead.assignee ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" + lead.assignee.id), onClick: event => {
                                        event.preventDefault();
                                        navigate(tenancy.link("/settings/users/" + lead.assignee.id));
                                    }, underline: "hover" }, { children: lead.assignee.first_name +
                                        " " +
                                        (lead.assignee.last_name
                                            ? lead.assignee.last_name
                                            : "") }))) : undefined;
                            },
                        },
                        {
                            title: t("campaigns.audiences.leads.lead-attributes.notes"),
                            field: "notes",
                        },
                        {
                            title: t("updated-at"),
                            sorting: false,
                            render: lead => {
                                return (new Date(Date.parse(lead.updated_at)).toLocaleDateString("en-GB") +
                                    " " +
                                    new Date(Date.parse(lead.updated_at)).toLocaleTimeString("hr-HR", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    }));
                            },
                        },
                    ], detailPanel: isMobile
                    ? (lead) => {
                        return (_jsx(Box, Object.assign({ sx: {
                                paddingX: 2,
                                paddingY: 3,
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("campaigns.audiences.leads.lead") })) })), _jsx(Chip, { style: {
                                                    color: lead.phone.on_central_blacklist
                                                        ? "#f44336"
                                                        : lead.phone.on_tenant_blacklist
                                                            ? "#ff9800"
                                                            : "inherit",
                                                }, variant: "outlined", size: "small", label: lead.phone.phone_area.phone_country.phone !==
                                                    "00"
                                                    ? lead.phone.phone_area.phone_country
                                                        .phone +
                                                        " " +
                                                        lead.phone.phone_area.phone +
                                                        " " +
                                                        lead.phone.number.substring(0, 4) +
                                                        " " +
                                                        lead.phone.number.substring(4, lead.phone.number.length)
                                                    : lead.phone.number, icon: lead.phone.on_central_blacklist ||
                                                    lead.phone.on_tenant_blacklist ? (_jsx(Tooltip
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    title: lead.phone.on_central_blacklist
                                                        ? t("contacts.contact-phones.phone-blacklisted") + " (HAKOM)"
                                                        : t("contacts.contact-phones.phone-blacklisted") }, { children: _jsx(ErrorIcon, { style: {
                                                            color: lead.phone
                                                                .on_central_blacklist
                                                                ? "#f44336"
                                                                : "#ff9800",
                                                        } }) }))) : undefined, onDelete: () => {
                                                    copy(lead.phone.phone_area.phone_country
                                                        .phone !== "00"
                                                        ? lead.phone.phone_area.phone_country
                                                            .phone +
                                                            lead.phone.phone_area.phone +
                                                            lead.phone.number
                                                        : lead.phone.number);
                                                    alert.show(t("phone-copied"), "info");
                                                }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                    // @ts-ignore
                                                    title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) })] })), lead.ticket && lead.ticket.contact && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket") })) })), _jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/contacts/" + lead.ticket.contact.id), onClick: event => {
                                                    event.preventDefault();
                                                    navigate(tenancy.link("/tickets/" + lead.ticket.id));
                                                }, underline: "hover" }, { children: lead.ticket.contact.type
                                                    ? lead.ticket.contact.type === "business"
                                                        ? lead.ticket.contact.company
                                                            ? lead.ticket.contact.company
                                                            : lead.ticket.contact
                                                                .is_anonymous === true
                                                                ? t("contacts.anonymous")
                                                                : lead.ticket.contact
                                                                    .first_name ||
                                                                    lead.ticket.contact.last_name
                                                                    ? (lead.ticket.contact.first_name
                                                                        ? lead.ticket.contact
                                                                            .first_name
                                                                        : "") +
                                                                        (lead.ticket.contact.last_name
                                                                            ? " " +
                                                                                lead.ticket.contact
                                                                                    .last_name
                                                                            : "")
                                                                    : ""
                                                        : lead.ticket.contact.is_anonymous ===
                                                            true
                                                            ? t("contacts.anonymous")
                                                            : lead.ticket.contact.first_name ||
                                                                lead.ticket.contact.last_name
                                                                ? (lead.ticket.contact.first_name
                                                                    ? lead.ticket.contact.first_name
                                                                    : "") +
                                                                    (lead.ticket.contact.last_name
                                                                        ? " " +
                                                                            lead.ticket.contact.last_name
                                                                        : "")
                                                                : lead.ticket.contact.company
                                                                    ? lead.ticket.contact.company
                                                                    : ""
                                                    : lead.ticket.contact.company
                                                        ? lead.ticket.contact.company
                                                        : lead.ticket.contact.is_anonymous ===
                                                            true
                                                            ? t("contacts.anonymous")
                                                            : lead.ticket.contact.first_name ||
                                                                lead.ticket.contact.last_name
                                                                ? (lead.ticket.contact.first_name
                                                                    ? lead.ticket.contact.first_name
                                                                    : "") +
                                                                    (lead.ticket.contact.last_name
                                                                        ? " " +
                                                                            lead.ticket.contact.last_name
                                                                        : "")
                                                                : "" }))] }))), lead.assignee && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("campaigns.audiences.leads.lead-attributes.assignee") })) })), _jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" + lead.assignee.id), onClick: event => {
                                                    event.preventDefault();
                                                    navigate(tenancy.link("/settings/users/" +
                                                        lead.assignee.id));
                                                }, underline: "hover" }, { children: lead.assignee.first_name +
                                                    " " +
                                                    (lead.assignee.last_name
                                                        ? lead.assignee.last_name
                                                        : "") }))] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("campaigns.audiences.leads.lead-attributes.status") })) })), _jsx(Chip, { size: "small", label: lead.status === "pending"
                                                    ? t("campaigns.audiences.leads.lead-attributes.status-types.pending")
                                                    : lead.status === "invalid"
                                                        ? t("campaigns.audiences.leads.lead-attributes.status-types.invalid")
                                                        : lead.status === "completed"
                                                            ? t("campaigns.audiences.leads.lead-attributes.status-types.completed")
                                                            : t("campaigns.audiences.leads.lead-attributes.status-types.checking"), style: {
                                                    border: "1px solid " +
                                                        (lead.status === "pending"
                                                            ? "rgb(33, 150, 243, 1)"
                                                            : lead.status === "invalid"
                                                                ? "rgb(244, 67, 54, 1)"
                                                                : lead.status === "completed"
                                                                    ? "rgb(76, 175, 80, 1)"
                                                                    : "rgb(255, 203, 5, 1)"),
                                                    backgroundColor: lead.status === "pending"
                                                        ? "rgb(33, 150, 243, 0.2)"
                                                        : lead.status === "invalid"
                                                            ? "rgb(244, 67, 54, 0.2)"
                                                            : lead.status === "completed"
                                                                ? "rgb(76, 175, 80, 0.2)"
                                                                : "rgb(255, 203, 5, 0.2)",
                                                } })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated-at") })) })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(lead.updated_at)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(lead.updated_at)).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }) }))] }))] })) })));
                    }
                    : undefined, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery, searchLabel: t("campaigns.audiences.leads.leads-table.search-by-phones") }), idToDelete !== "" && (_jsx(AudienceLeadDelete, { campaignId: campaignId, audienceId: audienceId, idToDelete: idToDelete, setIdToDelete: setIdToDelete, setIsDeleted: setIsDeleted })), lead && (_jsx(LeadAssigneeRemove, { campaignId: campaignId, audienceId: audienceId, lead: lead, setLead: setLead, setIsRemoved: setIsRemoved })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isImportOpened, onClose: () => setIsImportOpened(false) }, { children: [_jsx(DialogTitle, { children: t("campaigns.audiences.leads.leads-table.import-audience-leads") }), _jsx(DialogContent, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(Dropzone, Object.assign({ maxFiles: 1, maxSize: 50000000, onDrop: files => {
                                        setImportedFile(files[0]);
                                    } }, { children: ({ getRootProps, getInputProps }) => (_jsx(Card, Object.assign({ variant: "outlined" }, getRootProps(), { children: _jsx(CardActionArea, { children: _jsxs(Box, Object.assign({ sx: {
                                                    height: "145px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                    backgroundColor: prefs.theme === "dark" ? "#424242" : "#fff",
                                                } }, { children: [importedFile ? (_jsx(Typography, { children: importedFile.name })) : (_jsx(Skeleton, { variant: "text", width: "200px", height: "170px" })), _jsx("input", Object.assign({}, getInputProps({
                                                        accept: ".xlsx,.xls",
                                                    })))] })) }) }))) })), _jsxs(FormHelperText, { children: [t("drag&drop") + " ", " ", _jsx("strong", { children: ".xlsx" }), " ", " " + t("or") + " ", _jsx("strong", { children: ".xls" }), " ", " " + t("file")] })] })) }), _jsxs(DialogActions, { children: [_jsx(LoadingButton, Object.assign({ color: "inherit", loading: isDownloading, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), onClick: () => {
                                    downloadSample();
                                } }, { children: t("campaigns.audiences.leads.leads-table.sample") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", size: "small", loading: isSaving, disabled: !importedFile, onClick: () => {
                                    importAudience();
                                    analytics.sendEvent({
                                        category: "Import",
                                        action: "Import audience",
                                    }, auth.user);
                                }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("campaigns.audiences.leads.leads-table.import-leads") }))] })] })), _jsx(AudienceLeadForm, { campaignId: campaignId, audienceId: audienceId, setIsAdded: setIsAdded, isAddOpened: isAddOpened, setIsAddOpened: setIsAddOpened }), _jsx(AudienceLeadsFilters, { showFilters: showFilters, filterPhoneCountry: filterPhoneCountry, setFilterPhoneCountry: setFilterPhoneCountry, filterPhoneArea: filterPhoneArea, setFilterPhoneArea: setFilterPhoneArea, filterStatus: filterStatus, setFilterStatus: setFilterStatus, filterAssignees: filterAssignees, setFilterAssignees: setFilterAssignees, filterContacts: filterContacts, setFilterContacts: setFilterContacts, filterUpdatedAtStartDate: filterUpdatedAtStartDate, setFilterUpdatedAtStartDate: setFilterUpdatedAtStartDate, filterUpdatedAtEndDate: filterUpdatedAtEndDate, setFilterUpdatedAtEndDate: setFilterUpdatedAtEndDate, filterUpdatedAtStartTime: filterUpdatedAtStartTime, setFilterUpdatedAtStartTime: setFilterUpdatedAtStartTime, filterUpdatedAtEndTime: filterUpdatedAtEndTime, setFilterUpdatedAtEndTime: setFilterUpdatedAtEndTime, setFilter: setFilter, areFieldsAndGroupsLoaded: areFieldsAndGroupsLoaded, setAreFieldsAndGroupsLoaded: setAreFieldsAndGroupsLoaded, phoneCountries: phoneCountries, setPhoneCountries: setPhoneCountries, phoneAreas: phoneAreas, setPhoneAreas: setPhoneAreas })] }));
}
