import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../elements/blocks/intro";
import ContactForm from "../../elements/contacts/contact-form";
import ContactDelete from "../../elements/contacts/contact-delete";
import ContactHistory from "../../elements/contacts/contact-history";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useRoles } from "../../../services/roles";
export default function ContactEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const roles = useRoles();
    const [contact, setContact] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [historyToDelete, setHistoryToDelete] = useState("");
    useEffect(() => {
        api.GET_contact(id)
            .then(response => {
            setContact(response.data.contact);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [historyToDelete]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: contact
                    ? contact.type
                        ? contact.type === "business"
                            ? contact.company
                                ? contact.company
                                : contact.is_anonymous === true
                                    ? t("contacts.anonymous")
                                    : contact.first_name || contact.last_name
                                        ? (contact.first_name ? contact.first_name : "") +
                                            (contact.last_name ? " " + contact.last_name : "")
                                        : ""
                            : contact.is_anonymous === true
                                ? t("contacts.anonymous")
                                : contact.first_name || contact.last_name
                                    ? (contact.first_name ? contact.first_name : "") +
                                        (contact.last_name ? " " + contact.last_name : "")
                                    : contact.company
                                        ? contact.company
                                        : ""
                        : contact.company
                            ? contact.company
                            : contact.is_anonymous === true
                                ? t("contacts.anonymous")
                                : contact.first_name || contact.last_name
                                    ? (contact.first_name ? contact.first_name : "") +
                                        (contact.last_name ? " " + contact.last_name : "")
                                    : t("contacts.contact") + " #" + id
                    : t("contacts.contact") + " #" + id, breadcrumbs: [
                    {
                        name: t("contacts.contacts"),
                        link: "/contacts",
                    },
                    {
                        name: contact
                            ? contact.type
                                ? contact.type === "business"
                                    ? contact.company
                                        ? contact.company
                                        : contact.is_anonymous === true
                                            ? t("contacts.anonymous")
                                            : contact.first_name || contact.last_name
                                                ? (contact.first_name ? contact.first_name : "") +
                                                    (contact.last_name ? " " + contact.last_name : "")
                                                : ""
                                    : contact.is_anonymous === true
                                        ? t("contacts.anonymous")
                                        : contact.first_name || contact.last_name
                                            ? (contact.first_name ? contact.first_name : "") +
                                                (contact.last_name ? " " + contact.last_name : "")
                                            : contact.company
                                                ? contact.company
                                                : ""
                                : contact.company
                                    ? contact.company
                                    : contact.is_anonymous === true
                                        ? t("contacts.anonymous")
                                        : contact.first_name || contact.last_name
                                            ? (contact.first_name ? contact.first_name : "") +
                                                (contact.last_name ? " " + contact.last_name : "")
                                            : ""
                            : t("contacts.contact"),
                        link: "/contacts/" + id,
                    },
                    {
                        name: t("contacts.edit-contact"),
                        link: "/contacts/" + id + "/edit",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : contact ? (_jsxs(Fragment, { children: [_jsx(ContactForm, { kind: "contact", status: "edit", contact: contact, newContact: false }), contact.history && contact.history.length > 0 && (_jsx(ContactHistory, { status: "edit", contact: contact, historyToDelete: historyToDelete, setHistoryToDelete: setHistoryToDelete })), (roles.isAdmin || roles.isManager) && _jsx(ContactDelete, { contact: contact })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("contacts.errors.not-found") }))] }));
}
