import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { useTenancy } from "../../../services/tenancy";
export default function SearchKnowledgeCard({ knowledge }) {
    const navigate = useNavigate();
    const tenancy = useTenancy();
    return (_jsx(Card, Object.assign({ variant: "outlined" }, { children: _jsx(CardActionArea, Object.assign({ onClick: () => navigate(tenancy.link("/knowledge/" + knowledge.id)) }, { children: _jsxs(CardContent, { children: [knowledge.title && _jsx(Typography, Object.assign({ variant: "h6" }, { children: knowledge.title })), knowledge.caption && (_jsx(Typography, Object.assign({ color: "textSecondary" }, { children: knowledge.caption })))] }) })) })));
}
