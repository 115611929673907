import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ChoiceIcon from "@mui/icons-material/CheckCircle";
import FormIcon from "@mui/icons-material/Input";
import Intro from "../../../../elements/blocks/intro";
import { useTenancy } from "../../../../../services/tenancy";
export default function SharedFields() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("fields.shared-fields.shared-fields"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("fields.shared-fields.shared-fields"),
                        link: "/settings/fields/shared",
                    },
                ] }), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                    navigate(tenancy.link("/settings/fields/shared/choices"));
                                } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                            height: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        } }, { children: [_jsx(ChoiceIcon, { style: {
                                                    fontSize: 50,
                                                    marginBottom: "8px",
                                                } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.shared-fields.choice-fields.choice-fields") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                    navigate(tenancy.link("/settings/fields/shared/forms"));
                                } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                            height: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        } }, { children: [_jsx(FormIcon, { style: {
                                                    fontSize: 50,
                                                    marginBottom: "8px",
                                                } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.shared-fields.form-fields.form-fields") }))] })) }) })) }) }))] }))] }));
}
