import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow, formatDuration, intervalToDuration } from "date-fns";
import { hr, enUS } from "date-fns/locale";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Chip from "@mui/material/Chip";
import ActivityIcon from "@mui/icons-material/History";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TicketDetailsDelete from "./ticket-details-delete";
import { prettifyText } from "../../../helpers/clean";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
const useStyles = makeStyles(() => ({
    timeline: {
        margin: 0,
        padding: 0,
        "& .MuiTimelineContent-root": {
            padding: "6px 0 6px 16px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            content: "none",
        },
    },
    chip: {
        height: "auto",
        paddingY: 2,
        "& .MuiChip-label": {
            paddingTop: 2,
            paddingBottom: 2,
            wordWrap: "break-word",
            whiteSpace: "normal",
            textOverflow: "clip",
            maxWidth: "100%",
        },
    },
}));
export default function TicketDetailsHistory({ status, ticket, detailToDelete, setDetailToDelete, modal, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const tenancy = useTenancy();
    const roles = useRoles();
    const prefs = usePrefs();
    const locales = { enUS, hr };
    const displayContent = (ticketDetail) => {
        var _a, _b, _c;
        return (_jsxs(Fragment, { children: [ticketDetail.status && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.status") }))] })), ticketDetail.status.previous ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { className: classes.chip, label: ticketDetail.status.previous.name, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") }))] })) : (_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") }))), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { className: classes.chip, label: (_a = ticketDetail.status.updated) === null || _a === void 0 ? void 0 : _a.name, size: "small", variant: "filled" }) }))] }))), ticketDetail.priority && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.priority") }))] })), ticketDetail.priority.previous ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { className: classes.chip, label: ticketDetail.priority.previous.name, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") }))] })) : (_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") }))), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { className: classes.chip, label: (_b = ticketDetail.priority.updated) === null || _b === void 0 ? void 0 : _b.name, size: "small", variant: "filled" }) }))] }))), ticketDetail.estimate && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.estimated-time") }))] })), ticketDetail.estimate.previous && ticketDetail.estimate.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from1") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: formatDuration(intervalToDuration({
                                            start: 0,
                                            end: ticketDetail.estimate.previous * 1000,
                                        }), {
                                            locale: prefs.lang === "en"
                                                ? locales["enUS"]
                                                : locales["hr"],
                                        }), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: formatDuration(intervalToDuration({
                                            start: 0,
                                            end: ticketDetail.estimate.updated * 1000,
                                        }), {
                                            locale: prefs.lang === "en"
                                                ? locales["enUS"]
                                                : locales["hr"],
                                        }), size: "small", variant: "filled" }) }))] })) : !ticketDetail.estimate.previous && ticketDetail.estimate.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to1") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: formatDuration(intervalToDuration({
                                            start: 0,
                                            end: ticketDetail.estimate.updated * 1000,
                                        }), {
                                            locale: prefs.lang === "en"
                                                ? locales["enUS"]
                                                : locales["hr"],
                                        }), size: "small", variant: "filled" }) }))] })) : (ticketDetail.estimate.previous &&
                            !ticketDetail.estimate.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: formatDuration(intervalToDuration({
                                            start: 0,
                                            end: ticketDetail.estimate.previous * 1000,
                                        }), {
                                            locale: prefs.lang === "en"
                                                ? locales["enUS"]
                                                : locales["hr"],
                                        }), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.removed1") }))] })))] }))), ticketDetail.assignees && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.assignee") }))] })), ticketDetail.assignees.previous.length > 0 &&
                            ticketDetail.assignees.updated.length > 0 ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1, display: "flex", gap: 1 } }, { children: ticketDetail.assignees.previous.map(assignee => {
                                        var _a;
                                        return (_jsx(Chip, { className: classes.chip, label: assignee.first_name +
                                                " " +
                                                ((_a = assignee.last_name) !== null && _a !== void 0 ? _a : ""), size: "small", variant: "filled" }, assignee.id));
                                    }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1, display: "flex", gap: 1 } }, { children: ticketDetail.assignees.updated.map(assignee => {
                                        var _a;
                                        return (_jsx(Chip, { className: classes.chip, label: assignee.first_name +
                                                " " +
                                                ((_a = assignee.last_name) !== null && _a !== void 0 ? _a : ""), size: "small", variant: "filled" }, assignee.id));
                                    }) }))] })) : ticketDetail.assignees.previous.length === 0 &&
                            ticketDetail.assignees.updated.length > 0 ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") })), _jsx(Box, Object.assign({ sx: { marginX: 1, display: "flex", gap: 1 } }, { children: ticketDetail.assignees.updated.map(assignee => {
                                        var _a;
                                        return (_jsx(Chip, { className: classes.chip, label: assignee.first_name +
                                                " " +
                                                ((_a = assignee.last_name) !== null && _a !== void 0 ? _a : ""), size: "small", variant: "filled" }, assignee.id));
                                    }) }))] })) : (ticketDetail.assignees.previous.length > 0 &&
                            ticketDetail.assignees.updated.length === 0 && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1, display: "flex", gap: 1 } }, { children: ticketDetail.assignees.previous.map(assignee => {
                                        var _a;
                                        return (_jsx(Chip, { className: classes.chip, label: assignee.first_name +
                                                " " +
                                                ((_a = assignee.last_name) !== null && _a !== void 0 ? _a : ""), size: "small", variant: "filled" }, assignee.id));
                                    }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.removed") }))] })))] }))), ticketDetail.forwarded_call && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.forwarded-call") }))] })), ticketDetail.forwarded_call.previous !== null ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: ticketDetail.forwarded_call.previous
                                            ? t("yes")
                                            : t("no"), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") }))] })) : (_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") }))), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: ticketDetail.forwarded_call.updated === true
                                    ? t("yes")
                                    : t("no"), size: "small", variant: "filled" }) }))] }))), ticketDetail.start_date_time && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.start-date") }))] })), ticketDetail.start_date_time.previous &&
                            ticketDetail.start_date_time.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.start_date_time.previous)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.start_date_time.previous)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.start_date_time.updated)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.start_date_time.updated)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) }))] })) : !ticketDetail.start_date_time.previous &&
                            ticketDetail.start_date_time.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.start_date_time.updated)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.start_date_time.updated)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) }))] })) : (ticketDetail.start_date_time.previous &&
                            !ticketDetail.start_date_time.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.start_date_time.previous)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.start_date_time.previous)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.removed") }))] })))] }))), ticketDetail.due_date_time && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.due-date") }))] })), ticketDetail.due_date_time.previous &&
                            ticketDetail.due_date_time.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.due_date_time.previous)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.due_date_time.previous)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.due_date_time.updated)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.due_date_time.updated)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) }))] })) : !ticketDetail.due_date_time.previous &&
                            ticketDetail.due_date_time.updated ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.due_date_time.updated)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.due_date_time.updated)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) }))] })) : (ticketDetail.due_date_time.previous &&
                            !ticketDetail.due_date_time.updated && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: new Date(Date.parse(ticketDetail.due_date_time.previous)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticketDetail.due_date_time.previous)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }), size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.removed") }))] })))] }))), ticketDetail.progress && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.progress") }))] })), ticketDetail.progress.previous ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: ticketDetail.progress.previous + "%", size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") }))] })) : (_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") }))), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: ticketDetail.progress.updated + "%", size: "small", variant: "filled" }) }))] }))), ticketDetail.notes && (_jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: ticketDetail.notes.previous && ticketDetail.notes.updated ? (_jsxs(Box, Object.assign({ sx: {
                            flexDirection: "column",
                            width: "100%",
                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                            marginX: 0.5,
                                        } }, { children: t("tickets.ticket-attributes.notes") }))] })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from2") })), _jsx(Box, Object.assign({ sx: { paddingY: 1 } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                        color: "default",
                                    } }, { children: _jsx(Box, Object.assign({ sx: {
                                            padding: 1,
                                        } }, { children: _jsx(Input, { size: "small", fullWidth: true, multiline: true, value: ticketDetail.notes.previous, disableUnderline: true, inputProps: {
                                                readOnly: true,
                                                style: {
                                                    fontSize: 12,
                                                },
                                            } }) })) })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") })), _jsx(Box, Object.assign({ sx: { paddingY: 1 } }, { children: _jsx(Paper, Object.assign({ variant: "outlined" }, { children: _jsx(Box, Object.assign({ sx: {
                                            padding: 1,
                                        } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: ticketDetail.notes.updated, disableUnderline: true, inputProps: {
                                                readOnly: true,
                                                style: {
                                                    fontSize: 12,
                                                },
                                            } }) })) })) }))] }))) : !ticketDetail.notes.previous && ticketDetail.notes.updated ? (_jsxs(Box, Object.assign({ sx: {
                            flexDirection: "column",
                            width: "100%",
                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                            marginX: 0.5,
                                        } }, { children: t("tickets.ticket-attributes.notes") }))] })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to2") })), _jsx(Box, Object.assign({ sx: {
                                    paddingY: 1,
                                    width: "100%",
                                } }, { children: _jsx(Paper, Object.assign({ variant: "outlined" }, { children: _jsx(Box, Object.assign({ sx: {
                                            padding: 1,
                                        } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: ticketDetail.notes.updated, disableUnderline: true, inputProps: {
                                                readOnly: true,
                                                style: {
                                                    fontSize: 12,
                                                },
                                            } }) })) })) }))] }))) : (ticketDetail.notes.previous &&
                        !ticketDetail.notes.updated && (_jsxs(Box, Object.assign({ sx: {
                            flexDirection: "column",
                            width: "100%",
                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                            marginX: 0.5,
                                        } }, { children: t("tickets.ticket-attributes.notes") }))] })), _jsx(Box, Object.assign({ sx: {
                                    paddingY: 1,
                                } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                        color: "default",
                                    } }, { children: _jsx(Box, Object.assign({ sx: {
                                            padding: 1,
                                        } }, { children: _jsx(Input, { fullWidth: true, multiline: true, size: "small", value: ticketDetail.notes.previous, disableUnderline: true, inputProps: {
                                                readOnly: true,
                                                style: {
                                                    fontSize: 12,
                                                },
                                            } }) })) })) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.removed2") }))] })))) }))), ticketDetail.audience && (_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginY: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption" }, { children: ["\u2022", _jsx(Box, Object.assign({ component: "strong", sx: {
                                        marginX: 0.5,
                                    } }, { children: t("tickets.ticket-attributes.audience") }))] })), ticketDetail.audience.previous ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.changed-from") })), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: ticketDetail.audience.previous.name, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.to") }))] })) : (_jsx(Typography, Object.assign({ variant: "caption" }, { children: t("tickets.ticket-details.ticket-details-history.set-to") }))), _jsx(Box, Object.assign({ sx: { marginX: 1 } }, { children: _jsx(Chip, { label: (_c = ticketDetail.audience.updated) === null || _c === void 0 ? void 0 : _c.name, size: "small", variant: "filled" }) }))] })))] }));
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginTop: 4,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ActivityIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.ticket-details.ticket-details-history.history") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("tickets.ticket-details.ticket-details-history.ticket-history") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: _jsx(Timeline, Object.assign({ className: classes.timeline }, { children: ticket.ticket_detail.history.map((ticketDetail, index) => {
                                    var _a, _b, _c, _d;
                                    return (_jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, {}), index !== ticket.ticket_detail.history.length - 1 && (_jsx(TimelineConnector, {}))] }), _jsxs(TimelineContent, { children: [_jsxs(Box, Object.assign({ sx: {
                                                            marginBottom: 2,
                                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                                                        display: "inline-block",
                                                                    } }, { children: ticketDetail.created_by && (_jsx(Fragment, { children: roles.isAgent ? (_jsx(Typography, { children: `${(_a = ticketDetail.created_by
                                                                                .first_name) !== null && _a !== void 0 ? _a : ""} ${(_b = ticketDetail.created_by
                                                                                .last_name) !== null && _b !== void 0 ? _b : ""}` })) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" +
                                                                                ticketDetail.created_by
                                                                                    .id), onClick: event => {
                                                                                if (!ticketDetail.created_by) {
                                                                                    return;
                                                                                }
                                                                                event.preventDefault();
                                                                                navigate(tenancy.link("/settings/users/" +
                                                                                    ticketDetail
                                                                                        .created_by
                                                                                        .id));
                                                                            }, underline: "hover" }, { children: _jsx(Typography, { children: `${(_c = ticketDetail.created_by
                                                                                    .first_name) !== null && _c !== void 0 ? _c : ""} ${(_d = ticketDetail.created_by
                                                                                    .last_name) !== null && _d !== void 0 ? _d : ""}` }) }))) })) })) })), _jsxs(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: prefs.lang === "hr"
                                                                            ? t("tickets.ticket-information.ticket-information-history.ago") +
                                                                                " " +
                                                                                formatDistanceToNow(new Date(ticketDetail.created_at), {
                                                                                    locale: locales["hr"],
                                                                                })
                                                                            : prettifyText(formatDistanceToNow(new Date(ticketDetail.created_at), {
                                                                                locale: locales["enUs"],
                                                                            })) +
                                                                                " " +
                                                                                t("tickets.ticket-information.ticket-information-history.ago") })), _jsx(Typography, Object.assign({ variant: "overline", component: "p", color: "textSecondary" }, { children: new Date(Date.parse(ticketDetail.created_at)).toLocaleDateString("en-GB") +
                                                                            " " +
                                                                            new Date(Date.parse(ticketDetail.created_at)).toLocaleTimeString("hr-HR", {
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                            }) }))] }))] })), ticketDetail &&
                                                        ticketDetail.created_by &&
                                                        ticketDetail.created_by.email ===
                                                            process.env.APP_SYSTEM_EMAIL ? (_jsx(Box, Object.assign({ sx: { padding: "24px" } }, { children: displayContent(ticketDetail) }))) : (_jsx(Box, Object.assign({ sx: {
                                                            marginBottom: 2,
                                                        } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                                                    paddingX: 3,
                                                                    paddingY: 2,
                                                                } }, { children: displayContent(ticketDetail) })) }) })))] })] }, ticketDetail.id));
                                }) })) }))] })) })), detailToDelete !== "" && status === "edit" && (_jsx(TicketDetailsDelete, { ticketId: ticket.id, detailToDelete: detailToDelete, setDetailToDelete: setDetailToDelete }))] }));
}
