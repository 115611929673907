import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../elements/blocks/intro";
import PhoneCountriesTable from "../../../../elements/options/phone-countries/phone-countries-table";
export default function PhoneCountries() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("options.phone-countries.phone-countries"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.phone-countries.phone-countries"),
                        link: "/settings/options/phone-countries",
                    },
                ], button: {
                    label: t("options.phone-countries.new-phone-country"),
                    link: "/settings/options/phone-countries/new",
                    color: "secondary",
                    type: "new",
                } }), _jsx(PhoneCountriesTable, {})] }));
}
