import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import { hr, enUS } from "date-fns/locale";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material//useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Fade from "@mui/material/Fade";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import AlertIcon from "@mui/icons-material/NotificationsActive";
import RefreshIcon from "@mui/icons-material/Refresh";
import FlagIcon from "@mui/icons-material/EmojiFlags";
import { prettifyText } from "../../../helpers/clean";
import { useApiCentral } from "../../../api/api-central";
import { useTenancy } from "../../../services/tenancy";
import { usePrefs } from "../../../services/prefs";
import { useRoles } from "../../../services/roles";
const useStyles = makeStyles({
    alert: {
        "& .MuiAlert-action": {
            display: "flex",
            alignItems: "flex-start",
            paddingTop: 4,
            paddingRight: 4,
        },
    },
    notification: {
        width: "100%",
        marginBottom: 16,
        "& .MuiAlert-standardInfo": {
            color: "inherit",
            backgroundColor: "inherit",
        },
    },
    scroll: {
        "& .infinite-scroll-component__outerdiv": {
            width: "100%",
        },
    },
});
export default function Notifications() {
    const { t } = useTranslation();
    const api = useApiCentral();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const prefs = usePrefs();
    const roles = useRoles();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const defaultSearchMeta = {
        current_page: 0,
        total: 0,
    };
    const [badgeCount, setBadgeCount] = useState(-1);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [onlyShowUnread, setOnlyShowUnread] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [markAsReadId, setMarkAsReadId] = useState("");
    const [markAsReadLoading, setMarkAsReadLoading] = useState(false);
    const [markAllAsRead, setMarkAllAsRead] = useState(false);
    const [unreadNotificationIds, setUnreadNotificationIds] = useState([]);
    const [ticketId, setTicketId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [isRefreshLoading, setIsRefreshLoading] = useState(false);
    const [searchMeta, setSearchMeta] = useState(defaultSearchMeta);
    const [saveChanges, setSaveChanges] = useState(false);
    const [lastNotificationId, setLastNotificationId] = useState("");
    const refreshNotificationsTimer = useRef();
    const anchorRef = useRef(null);
    const lastNotificationIdRef = useRef(lastNotificationId);
    const badgeCountRef = useRef(badgeCount);
    const locales = { enUS, hr };
    const classes = useStyles();
    useEffect(() => {
        if (!tenancy.client)
            return;
        if (!ticketId)
            return;
        navigate(tenancy.link("/tickets/" + ticketId));
        setMenuOpen(false);
        setTicketId("");
    }, [ticketId]);
    useEffect(() => {
        if (markAsReadId !== "" || markAllAsRead) {
            if (markAllAsRead) {
                setMarkAsReadLoading(true);
            }
            api.MARK_notificationAsRead(markAsReadId !== "" ? markAsReadId : undefined, markAllAsRead ? markAllAsRead : undefined)
                .then(() => {
                if (markAsReadId !== "") {
                    setMarkAsReadId("");
                    let filteredNotifications = unreadNotificationIds.filter(a => a !== markAsReadId);
                    if (badgeCount > 0) {
                        setBadgeCount(badgeCount - 1);
                        badgeCountRef.current = badgeCount - 1;
                    }
                    setUnreadNotificationIds(filteredNotifications);
                }
                if (markAllAsRead) {
                    setUnreadNotificationIds([]);
                    setMarkAllAsRead(false);
                    setBadgeCount(0);
                    badgeCountRef.current = 0;
                }
                setMarkAsReadLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
    }, [markAsReadId, markAllAsRead]);
    useEffect(() => {
        if (!prefs.centralToken)
            return;
        clearTimeout(refreshNotificationsTimer.current);
        badgeCountRef.current = badgeCount;
        getNotifications(defaultSearchMeta, false);
        refreshNotifications();
    }, [onlyShowUnread, prefs.centralToken]);
    useEffect(() => {
        if (!refresh)
            return;
        setSearchMeta(defaultSearchMeta);
        clearTimeout(refreshNotificationsTimer.current);
        badgeCountRef.current = badgeCount;
        getNotifications(defaultSearchMeta, false);
        setRefresh(false);
        refreshNotifications();
    }, [refresh]);
    useEffect(() => {
        if (!saveChanges)
            return;
        api.UPDATE_userOptions(prefs.lang, prefs.theme === "dark")
            .then(() => { })
            .catch(error => {
            console.error(error.response);
        });
        setSaveChanges(false);
    }, [saveChanges]);
    useEffect(() => {
        let titleWithoutNotifications = document.title.replace(/\((.*)\)/, "").trim();
        if (badgeCount > 0)
            document.title = "(" + badgeCount + ") " + titleWithoutNotifications;
        else
            document.title = titleWithoutNotifications;
    }, [badgeCount]);
    const showNotification = (notification, count) => {
        let browserNotification;
        if (notification) {
            browserNotification = new Notification(t("tickets.ticket") +
                " #" +
                notification.subject_id +
                " " +
                (notification.data.notify_event === "scheduled"
                    ? t("notifications.is") + " " + t("notifications.scheduled")
                    : notification.data.notify_event === "due"
                        ? t("notifications.is-1") + " " + t("notifications.due")
                        : t("notifications.was") +
                            (notification.data.notify_event === "created"
                                ? t("notifications.created")
                                : t("notifications.updated"))), {
                tag: notification.id,
                body: (t("tickets.ticket") +
                    " #" +
                    notification.subject_id +
                    " " +
                    (notification.data.notify_event === "scheduled" &&
                        new Date(Date.parse(notification.data.start_date_time)).getTime() >=
                            new Date().getTime()
                        ? t("notifications.is")
                        : notification.data.notify_event === "scheduled" &&
                            new Date(Date.parse(notification.data.start_date_time)).getTime() <
                                new Date().getTime()
                            ? t("notifications.was-2")
                            : notification.data.notify_event === "due" &&
                                new Date(Date.parse(notification.data.due_date_time)).getTime() >=
                                    new Date().getTime()
                                ? t("notifications.is-1")
                                : notification.data.notify_event === "due" &&
                                    new Date(Date.parse(notification.data.due_date_time)).getTime() <
                                        new Date().getTime()
                                    ? t("notifications.was-1")
                                    : t("notifications.was")) +
                    " " +
                    (notification.data.notify_event === "scheduled" ||
                        notification.data.notify_event === "due"
                        ? notification.data.notify_event === "scheduled"
                            ? t("notifications.scheduled") + " "
                            : t("notifications.due") + " "
                        : notification.data.notify_event === "created"
                            ? t("notifications.created")
                            : t("notifications.updated") + " ") +
                    ((notification.data.notify_event === "scheduled" &&
                        new Date(Date.parse(notification.data.start_date_time)).getTime() >
                            new Date().getTime()) ||
                        (notification.data.notify_event === "due" &&
                            new Date(Date.parse(notification.data.due_date_time)).getTime() >
                                new Date().getTime())
                        ? t("notifications.in") + " "
                        : "") +
                    " " +
                    (notification.data.notify_event === "scheduled" ||
                        notification.data.notify_event === "due"
                        ? getScheduledDueDate(notification)
                        : "") +
                    (notification.data.contact &&
                        ((process.env.APP_ID === "mcd" && !roles.isAgent) ||
                            process.env.APP_ID !== "mcd")
                        ? " " +
                            t("notifications.notification-option-form.for") +
                            " " +
                            t("notifications.contact").toLocaleLowerCase() +
                            " " +
                            (notification.data.contact.type
                                ? notification.data.contact.type === "business"
                                    ? notification.data.contact.company
                                        ? notification.data.contact.company
                                        : notification.data.contact.is_anonymous === true
                                            ? t("contacts.anonymous")
                                            : notification.data.contact.first_name ||
                                                notification.data.contact.last_name
                                                ? (notification.data.contact.first_name
                                                    ? notification.data.contact.first_name
                                                    : "") +
                                                    (notification.data.contact.last_name
                                                        ? " " + notification.data.contact.last_name
                                                        : "")
                                                : ""
                                    : notification.data.contact.is_anonymous === true
                                        ? t("contacts.anonymous")
                                        : notification.data.contact.first_name ||
                                            notification.data.contact.last_name
                                            ? (notification.data.contact.first_name
                                                ? notification.data.contact.first_name
                                                : "") +
                                                (notification.data.contact.last_name
                                                    ? " " + notification.data.contact.last_name
                                                    : "")
                                            : notification.data.contact.company
                                                ? notification.data.contact.company
                                                : ""
                                : notification.data.contact.company
                                    ? notification.data.contact.company
                                    : notification.data.contact.is_anonymous === true
                                        ? t("contacts.anonymous")
                                        : notification.data.contact.first_name ||
                                            notification.data.contact.last_name
                                            ? (notification.data.contact.first_name
                                                ? notification.data.contact.first_name
                                                : "") +
                                                (notification.data.contact.last_name
                                                    ? " " + notification.data.contact.last_name
                                                    : "")
                                            : "")
                        : "") +
                    " " +
                    t("notifications.notification-option-form.in") +
                    " " +
                    t("notifications.campaign") +
                    " " +
                    notification.data.campaign.name +
                    (notification.data.groups !== null && notification.data.groups.length > 0
                        ? " " +
                            t("notifications.in-groups") +
                            " " +
                            notification.data.groups.map((group, index) => {
                                return (group.name +
                                    (index + 1 !== notification.data.groups.length ? ", " : ""));
                            })
                        : "") +
                    (notification.data.status && notification.data.priority
                        ? " " +
                            t("notifications.with-1") +
                            t("notifications.status") +
                            " " +
                            notification.data.status.name +
                            " " +
                            t("notifications.notification-option-form.and") +
                            " " +
                            t("notifications.priority") +
                            " " +
                            notification.data.priority.name
                        : notification.data.status && !notification.data.priority
                            ? " " +
                                t("notifications.with-1") +
                                t("notifications.status") +
                                " " +
                                notification.data.status.name
                            : !notification.data.status && notification.data.priority
                                ? " " +
                                    t("notifications.with") +
                                    t("notifications.priority") +
                                    " " +
                                    notification.data.priority.name
                                : "") +
                    (notification.data.assigned_to_user
                        ? " " +
                            t("notifications.and") +
                            " " +
                            t("notifications.was") +
                            " " +
                            t("notifications.assigned-to-me")
                        : ""))
                    .replace(/\s+/g, " ")
                    .trim(),
                icon: process.env.APP_URL + "/apple-touch-icon.png",
            });
            browserNotification.addEventListener("click", event => {
                event.preventDefault();
                setMarkAsReadId(notification.id);
                window.open(process.env.APP_URL +
                    "/" +
                    notification.tenant.domain +
                    "/tickets/" +
                    notification.subject_id);
                browserNotification.close();
            });
        }
        if (count) {
            browserNotification = new Notification(t("notifications.tickets-were-updated-created"), {
                body: (t("notifications.there-are") +
                    count +
                    " " +
                    t("notifications.new-notifications"))
                    .replace(/\s+/g, " ")
                    .trim(),
                icon: process.env.APP_URL + "/apple-touch-icon.png",
            });
            browserNotification.addEventListener("click", event => {
                event.preventDefault();
                window.open(process.env.APP_URL);
                browserNotification.close();
            });
        }
        let sound = new Audio("/alert.mp3");
        sound.play().then(() => { });
    };
    const searchByScrolling = () => {
        getNotifications(searchMeta, true);
    };
    const getNotifications = (searchMeta, scroll) => {
        if (refresh)
            setIsRefreshLoading(true);
        api.GET_notifications(10, searchMeta.current_page + 1, undefined, onlyShowUnread ? "exclude" : "include", 1)
            .then(response => {
            let unreadNotifications = [];
            let unread = [];
            if (scroll)
                unreadNotifications = unreadNotificationIds;
            response.data.notifications.map(notification => {
                if (notification.is_read)
                    return;
                unreadNotifications.push(notification.id);
                unread.push(notification);
            });
            setUnreadNotificationIds(unreadNotifications);
            if (scroll === true) {
                setNotifications(notifications.concat(response.data.notifications));
            }
            else {
                if (badgeCountRef.current === -1) {
                    badgeCountRef.current = badgeCount;
                }
                let oldBadgeCount = badgeCountRef.current;
                setNotifications(response.data.notifications);
                setBadgeCount(response.data.unread_count);
                badgeCountRef.current = response.data.unread_count;
                if ("Notification" in window && prefs.notificationPermission !== "denied") {
                    let unsent = [];
                    let lastNotificationIndex = response.data.notifications.findIndex(a => a.id == lastNotificationIdRef.current);
                    if (lastNotificationIndex !== -1) {
                        response.data.notifications.map((notification, index) => {
                            if (index < lastNotificationIndex && !notification.is_read) {
                                unsent.push(notification);
                            }
                        });
                    }
                    else if (response.data.unread_count - oldBadgeCount > 10 &&
                        oldBadgeCount !== -1) {
                        response.data.notifications.map(notification => {
                            unsent.push(notification);
                        });
                    }
                    if (oldBadgeCount !== -1 &&
                        response.data.unread_count - oldBadgeCount > 0 &&
                        unsent.length > 0) {
                        if (response.data.unread_count - oldBadgeCount < 5) {
                            unsent.map(notification => {
                                showNotification(notification);
                            });
                            lastNotificationIdRef.current = unsent[0].id;
                            setLastNotificationId(unsent[0].id);
                        }
                        else {
                            showNotification(undefined, response.data.unread_count - oldBadgeCount);
                            lastNotificationIdRef.current = unsent[0].id;
                            setLastNotificationId(unsent[0].id);
                        }
                    }
                    if (lastNotificationIdRef.current === "") {
                        lastNotificationIdRef.current = response.data.notifications[0]
                            ? response.data.notifications[0].id
                            : "";
                        setLastNotificationId(lastNotificationIdRef.current);
                    }
                }
            }
            let total = response.data.notifications.length === parseInt(response.data.meta.per_page)
                ? 100000000
                : response.data.meta.current_page === 1
                    ? response.data.notifications.length
                    : response.data.notifications.length +
                        parseInt(response.data.meta.per_page) *
                            (response.data.meta.current_page - 1);
            setSearchMeta({
                current_page: response.data.meta.current_page,
                total: total,
            });
            setIsRefreshLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
            }
        });
    };
    const getScheduledDueDate = (notification) => {
        if (notification.data.notify_event === "scheduled" ||
            notification.data.notify_event === "due")
            return prefs.lang === "hr"
                ? ((notification.data.notify_event === "scheduled" &&
                    new Date(Date.parse(notification.data.start_date_time)).getTime() <=
                        new Date().getTime()) ||
                    (notification.data.notify_event === "due" &&
                        new Date(Date.parse(notification.data.due_date_time)).getTime() <=
                            new Date().getTime())
                    ? t("tickets.ticket-details.ticket-details-history.ago").toLocaleLowerCase()
                    : "") +
                    " " +
                    formatDistanceToNow(notification.data.notify_event === "scheduled"
                        ? notification.data.start_date_time
                            ? new Date(Date.parse(notification.data.start_date_time))
                            : new Date()
                        : notification.data.due_date_time
                            ? new Date(Date.parse(notification.data.due_date_time))
                            : new Date(), {
                        locale: locales["hr"],
                    }).toLocaleLowerCase()
                : formatDistanceToNow(notification.data.notify_event === "scheduled"
                    ? notification.data.start_date_time
                        ? new Date(Date.parse(notification.data.start_date_time))
                        : new Date()
                    : notification.data.due_date_time
                        ? new Date(Date.parse(notification.data.due_date_time))
                        : new Date(), {
                    locale: locales["enUS"],
                }).toLocaleLowerCase() +
                    ((notification.data.notify_event === "scheduled" &&
                        new Date(Date.parse(notification.data.start_date_time)).getTime() <=
                            new Date().getTime()) ||
                        (notification.data.notify_event === "due" &&
                            new Date(Date.parse(notification.data.due_date_time)).getTime() <=
                                new Date().getTime())
                        ? " " + t("tickets.ticket-details.ticket-details-history.ago")
                        : "");
    };
    const refreshNotifications = () => {
        // @ts-ignore
        refreshNotificationsTimer.current = setTimeout(() => {
            getNotifications(defaultSearchMeta, false);
            refreshNotifications();
        }, 60 * 1000 * 5);
    };
    const displayNotification = (notification) => {
        return (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                    } }, { children: [_jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [t("tickets.ticket") +
                                    " #" +
                                    notification.subject_id +
                                    " " +
                                    (notification.data.notify_event === "scheduled" &&
                                        new Date(Date.parse(notification.data.start_date_time)).getTime() >=
                                            new Date().getTime()
                                        ? t("notifications.is")
                                        : notification.data.notify_event === "scheduled" &&
                                            new Date(Date.parse(notification.data.start_date_time)).getTime() < new Date().getTime()
                                            ? t("notifications.was-2")
                                            : notification.data.notify_event === "due" &&
                                                new Date(Date.parse(notification.data.due_date_time)).getTime() >
                                                    new Date().getTime()
                                                ? t("notifications.is-1")
                                                : notification.data.notify_event === "due" &&
                                                    new Date(Date.parse(notification.data.due_date_time)).getTime() <
                                                        new Date().getTime()
                                                    ? t("notifications.was-1")
                                                    : t("notifications.was")) +
                                    " ", _jsx(Box, Object.assign({ component: "strong" }, { children: notification.data.notify_event === "scheduled" ||
                                        notification.data.notify_event === "due"
                                        ? notification.data.notify_event === "scheduled"
                                            ? t("notifications.scheduled") + " "
                                            : t("notifications.due") + " "
                                        : notification.data.notify_event === "created"
                                            ? t("notifications.created")
                                            : t("notifications.updated") })), (notification.data.notify_event === "scheduled" &&
                                    new Date(Date.parse(notification.data.start_date_time)).getTime() >
                                        new Date().getTime()) ||
                                    (notification.data.notify_event === "due" &&
                                        new Date(Date.parse(notification.data.due_date_time)).getTime() >
                                            new Date().getTime())
                                    ? t("notifications.in") + " "
                                    : "", _jsx(Box, Object.assign({ component: "strong" }, { children: getScheduledDueDate(notification) }))] })), notification.data.contact &&
                            ((process.env.APP_ID === "mcd" && !roles.isAgent) ||
                                process.env.APP_ID !== "mcd") && (_jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [t("notifications.notification-option-form.for") +
                                    " " +
                                    t("notifications.contact").toLocaleLowerCase() +
                                    " ", _jsx(Box, Object.assign({ component: "strong" }, { children: notification.data.contact.type
                                        ? notification.data.contact.type === "business"
                                            ? notification.data.contact.company
                                                ? notification.data.contact.company
                                                : notification.data.contact.is_anonymous === true
                                                    ? t("contacts.anonymous")
                                                    : notification.data.contact.first_name ||
                                                        notification.data.contact.last_name
                                                        ? (notification.data.contact.first_name
                                                            ? notification.data.contact.first_name
                                                            : "") +
                                                            (notification.data.contact.last_name
                                                                ? " " + notification.data.contact.last_name
                                                                : "")
                                                        : ""
                                            : notification.data.contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : notification.data.contact.first_name ||
                                                    notification.data.contact.last_name
                                                    ? (notification.data.contact.first_name
                                                        ? notification.data.contact.first_name
                                                        : "") +
                                                        (notification.data.contact.last_name
                                                            ? " " + notification.data.contact.last_name
                                                            : "")
                                                    : notification.data.contact.company
                                                        ? notification.data.contact.company
                                                        : ""
                                        : notification.data.contact.company
                                            ? notification.data.contact.company
                                            : notification.data.contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : notification.data.contact.first_name ||
                                                    notification.data.contact.last_name
                                                    ? (notification.data.contact.first_name
                                                        ? notification.data.contact.first_name
                                                        : "") +
                                                        (notification.data.contact.last_name
                                                            ? " " + notification.data.contact.last_name
                                                            : "")
                                                    : "" }))] }))), _jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [" " + t("notifications.notification-option-form.in"), " " + t("notifications.campaign") + " ", _jsx(Box, Object.assign({ component: "strong" }, { children: notification.data.campaign.name }))] })), notification.data.status && notification.data.priority && (_jsxs(Fragment, { children: [_jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [" " + t("notifications.with-1") + t("notifications.status") + " ", _jsx(Box, Object.assign({ component: "strong" }, { children: notification.data.status.name }))] })), _jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [" " +
                                            t("notifications.notification-option-form.and") +
                                            " " +
                                            t("notifications.priority") +
                                            " ", _jsx(Box, Object.assign({ component: "strong" }, { children: notification.data.priority.name }))] }))] })), notification.data.status && !notification.data.priority && (_jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [t("notifications.with-1") + t("notifications.status") + " ", _jsx(Box, Object.assign({ component: "strong" }, { children: notification.data.status.name }))] }))), !notification.data.status && notification.data.priority && (_jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [t("notifications.with") + t("notifications.priority") + " ", _jsx(Box, Object.assign({ component: "strong" }, { children: notification.data.priority.name }))] }))), notification.data.groups && notification.data.groups.length > 0 && (_jsxs(Typography, Object.assign({ variant: "body2", sx: { paddingRight: 0.5 } }, { children: [t("notifications.in-groups") + " ", notification.data.groups.map((group, index) => {
                                    return (_jsx(Box, Object.assign({ component: "strong" }, { children: group.name +
                                            (index + 1 !== notification.data.groups.length
                                                ? ", "
                                                : "") }), index));
                                })] }))), notification.data.assigned_to_user && (_jsxs(Typography, Object.assign({ variant: "body2" }, { children: [" " + t("notifications.and") + " " + t("notifications.was") + " ", _jsx(Box, Object.assign({ component: "strong" }, { children: t("notifications.assigned-to-me") }))] })))] })), _jsx(Typography, Object.assign({ variant: "caption", style: {
                        paddingTop: 1,
                        color: prefs.theme === "light"
                            ? "rgb(0, 0, 0, 0.45)"
                            : "rgb(255, 255, 255, 0.26)",
                    } }, { children: (prefs.lang === "hr"
                        ? t("tickets.ticket-details.ticket-details-history.ago") +
                            " " +
                            formatDistanceToNow(new Date(Date.parse(notification.created_at)), {
                                locale: locales["hr"],
                            })
                        : prettifyText(formatDistanceToNow(new Date(Date.parse(notification.created_at)), {
                            locale: locales["enUS"],
                        }) +
                            " " +
                            t("tickets.ticket-details.ticket-details-history.ago"))) +
                        (notification.data.event_fired_by
                            ? " | " +
                                (notification.data.event_fired_by.first_name
                                    ? notification.data.event_fired_by.first_name + " "
                                    : "") +
                                (notification.data.event_fired_by.last_name
                                    ? notification.data.event_fired_by.last_name
                                    : "")
                            : "") +
                        " | " +
                        notification.tenant.name }))] }));
    };
    return (_jsxs(Fragment, { children: [_jsx(IconButton, Object.assign({ onClick: () => setMenuOpen(!isMenuOpen), ref: anchorRef, color: "inherit", size: "large" }, { children: _jsx(Badge, Object.assign({ badgeContent: badgeCount === -1 || badgeCount === 0 ? 0 : badgeCount, color: "secondary" }, { children: _jsx(NotificationsIcon, {}) })) })), _jsx(Popover, Object.assign({ open: isMenuOpen, onClose: () => {
                    setMenuOpen(false);
                }, anchorEl: anchorRef.current, TransitionComponent: Fade }, { children: _jsxs(Box, Object.assign({ sx: {
                        paddingX: 3,
                        paddingY: 2,
                        width: isMobile ? "300px" : "500px",
                    } }, { children: [_jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: isMobile ? "flex-start" : "normal",
                                        } }, { children: _jsx(Typography, Object.assign({ variant: "h6", gutterBottom: true }, { children: t("notifications.notifications") })) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: isMobile ? "flex-start" : "flex-end",
                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "body2", sx: { paddingTop: 1, paddingRight: 1 } }, { children: t("notifications.only-show-unread") })), _jsx(Switch, { color: "secondary", style: { marginRight: 0 }, required: false, checked: onlyShowUnread, onChange: () => setOnlyShowUnread(!onlyShowUnread) })] })) }))] })), _jsx(Divider, {}), _jsxs(Grid, Object.assign({ container: true }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("notifications.latest") })), _jsx(Tooltip
                                        // @ts-ignore
                                        , Object.assign({ 
                                            // @ts-ignore
                                            title: t("notifications.refresh") }, { children: _jsx(IconButton, Object.assign({ onClick: () => setRefresh(true), disabled: isRefreshLoading, size: "large" }, { children: _jsx(RefreshIcon, { fontSize: "small" }) })) }))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: isMobile ? "flex-start" : "flex-end",
                                            paddingTop: isMobile ? 0 : 1,
                                            paddingBottom: isMobile ? 1 : 0,
                                            marginLeft: isMobile ? -0.5 : 0,
                                            marginTop: isMobile ? -1 : 0,
                                        } }, { children: _jsx(LoadingButton, Object.assign({ variant: "text", color: "inherit", size: "small", disabled: unreadNotificationIds.length === 0, style: { fontSize: isMobile ? "10px" : "12px" }, loading: markAsReadLoading, onClick: () => {
                                                setMarkAllAsRead(true);
                                            }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("notifications.mark-all-as-read") })) })) }))] })), _jsx(Box, Object.assign({ className: classes.scroll, sx: {
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 1,
                            } }, { children: _jsx(InfiniteScroll, Object.assign({ height: isMobile ? window.innerHeight - 400 : window.innerHeight - 232, dataLength: notifications.length, next: searchByScrolling, hasMore: notifications.length < searchMeta.total, loader: _jsx(Box, Object.assign({ sx: {
                                        paddingY: 2,
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("search.loading") })) })), endMessage: _jsxs(Box, Object.assign({ sx: {
                                        width: "40%",
                                        paddingY: 3,
                                        textAlign: "center",
                                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                paddingBottom: 2,
                                                display: "flex",
                                                justifyContent: "center",
                                            } }, { children: _jsx(FlagIcon, { fontSize: "large", color: "primary" }) })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: t("notifications.no-new-notifications") }))] })), style: {
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    alignContent: "flex-start",
                                } }, { children: notifications.map(notification => {
                                    return unreadNotificationIds.find(unreadNotification => unreadNotification === notification.id) ? (_jsx(Card, Object.assign({ variant: "outlined", className: classes.notification }, { children: tenancy.isSubDomain &&
                                            tenancy.subDomain !== notification.tenant.domain ? (_jsx(Alert, Object.assign({ severity: "success", icon: _jsx(AlertIcon, { fontSize: "inherit" }) }, { children: displayNotification(notification) }))) : (_jsx(CardActionArea, Object.assign({ disabled: tenancy.isSubDomain &&
                                                tenancy.subDomain !== notification.tenant.domain, className: classes.alert, onClick: () => {
                                                setMarkAsReadId(notification.id);
                                                if (tenancy.client &&
                                                    tenancy.client.id === notification.tenant.id) {
                                                    setMenuOpen(false);
                                                    navigate(tenancy.link("/tickets/" +
                                                        notification.subject_id));
                                                }
                                                else {
                                                    tenancy.setClient(notification.tenant);
                                                    setTicketId(notification.subject_id);
                                                }
                                            } }, { children: _jsx(Alert, Object.assign({ severity: "success", icon: _jsx(AlertIcon, { fontSize: "inherit" }) }, { children: displayNotification(notification) })) }))) }), notification.id)) : (_jsx(Card, Object.assign({ variant: "outlined", className: classes.notification }, { children: tenancy.isSubDomain &&
                                            tenancy.subDomain !== notification.tenant.domain ? (_jsx(Alert, Object.assign({ severity: "success", icon: _jsx(AlertIcon, { fontSize: "inherit" }) }, { children: displayNotification(notification) }))) : (_jsx(CardActionArea, Object.assign({ disabled: tenancy.isSubDomain &&
                                                tenancy.subDomain !== notification.tenant.domain, onClick: () => {
                                                if (tenancy.client &&
                                                    tenancy.client.id === notification.tenant.id) {
                                                    setMenuOpen(false);
                                                    navigate(tenancy.link("/tickets/" +
                                                        notification.subject_id));
                                                }
                                                else {
                                                    tenancy.setClient(notification.tenant);
                                                    setTicketId(notification.subject_id);
                                                }
                                            } }, { children: _jsx(Alert, Object.assign({ severity: "info", icon: _jsx(NotificationsIcon, { fontSize: "inherit", color: "disabled" }) }, { children: displayNotification(notification) })) }))) }), notification.id));
                                }) })) }))] })) }))] }));
}
