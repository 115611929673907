import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import Sidebar from "../elements/header/sidebar";
import Logo from "../elements/header/logo";
import QuickActions from "../elements/header/quick-actions";
import { colors } from "../../helpers/colors";
const useStyles = makeStyles((theme) => createStyles({
    root: {
        position: "relative",
        zIndex: 1300,
        "& .MuiAppBar-root": {
            backgroundColor: process.env.APP_ID === "mcd" ? colors.primary.light : colors.primary.dark,
            color: "#fff",
        },
    },
    offset: theme.mixins.toolbar,
}));
export default function Header() {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: classes.root }, { children: [_jsx(AppBar, { children: _jsx(Toolbar, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "space-between",
                            flexGrow: 1,
                        } }, { children: [_jsx(Hidden, Object.assign({ lgUp: true }, { children: _jsx(Sidebar, {}) })), _jsx(Logo, {}), _jsx(QuickActions, {})] })) }) }), _jsx("div", { className: classes.offset })] })));
}
