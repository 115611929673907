import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatDuration, intervalToDuration, sub } from "date-fns";
import { hr, enUS } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Input from "@mui/material/Input";
import DetailsIcon from "@mui/icons-material/Assignment";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import TicketDetailsHistory from "./ticket-details-history";
import { useRoles } from "../../../services/roles";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import { prettifyText } from "../../../helpers/clean";
export default function TicketDetails({ ticket, modal }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const prefs = usePrefs();
    const locales = { enUS, hr };
    return (_jsx(Fragment, { children: _jsxs(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(DetailsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.ticket-details.details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("tickets.ticket-details.ticket-status") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: ticket.ticket_detail ? (_jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                                        paddingX: 5,
                                        paddingY: 4,
                                    } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [ticket.ticket_detail.status && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.status") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Chip, { size: "small", label: ticket.ticket_detail.status.name, style: {
                                                                        border: "1px solid " +
                                                                            (ticket.ticket_detail.status
                                                                                .type === "pending"
                                                                                ? "rgba(156, 39, 176, 1)"
                                                                                : ticket.ticket_detail
                                                                                    .status.type ===
                                                                                    "resolved"
                                                                                    ? "rgba(76, 175, 80, 1)"
                                                                                    : "rgba(37, 64, 146, 1)"),
                                                                        backgroundColor: ticket.ticket_detail.status
                                                                            .type === "pending"
                                                                            ? "rgba(156, 39, 176, 0.2)"
                                                                            : ticket.ticket_detail
                                                                                .status.type ===
                                                                                "resolved"
                                                                                ? "rgba(76, 175, 80, 0.2)"
                                                                                : "rgba(37, 64, 146, 0.2)",
                                                                    } }) }))] })) }))), ticket.ticket_detail.priority && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.priority") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Chip, { label: ticket.ticket_detail.priority.name, size: "small", style: {
                                                                        border: "1px solid " +
                                                                            (ticket.ticket_detail.priority
                                                                                .level < 33
                                                                                ? "rgb(244, 67, 54, 1)"
                                                                                : ticket.ticket_detail
                                                                                    .priority.level >=
                                                                                    33 &&
                                                                                    ticket.ticket_detail
                                                                                        .priority.level <= 66
                                                                                    ? "rgb(255, 152, 0, 1)"
                                                                                    : "rgb(33, 150, 243, 1)"),
                                                                        backgroundColor: ticket.ticket_detail.priority
                                                                            .level < 33
                                                                            ? "rgb(244, 67, 54, 0.2)"
                                                                            : ticket.ticket_detail
                                                                                .priority.level >=
                                                                                33 &&
                                                                                ticket.ticket_detail
                                                                                    .priority.level <= 66
                                                                                ? "rgb(255, 152, 0, 0.2)"
                                                                                : "rgb(33, 150, 243, 0.2)",
                                                                    } }) }))] })) }))), ticket.ticket_detail.assignees.length > 0 && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.assignee") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: ticket.ticket_detail.assignees.map(assignee => {
                                                                    var _a, _b;
                                                                    return (_jsx(Fragment, { children: roles.isAdmin ||
                                                                            roles.isManager ? (_jsx(Chip, { onClick: () => {
                                                                                navigate(tenancy.link("/settings/users/" +
                                                                                    assignee.id));
                                                                            }, style: {
                                                                                marginTop: "4px",
                                                                                marginBottom: "4px",
                                                                                marginRight: "8px",
                                                                                wordBreak: "break-word",
                                                                                color: ticket.campaign &&
                                                                                    ticket.campaign
                                                                                        .field_options &&
                                                                                    ticket.campaign.field_options.find(o => o.data ===
                                                                                        "assignee_ids") &&
                                                                                    !ticket.campaign.field_options.find(o => o.data ===
                                                                                        "assignee_ids").visible
                                                                                    ? "#bebebe"
                                                                                    : "inherit",
                                                                            }, label: assignee.first_name +
                                                                                " " +
                                                                                ((_a = assignee.last_name) !== null && _a !== void 0 ? _a : ""), variant: "outlined", size: "small" })) : (_jsx(Chip, { style: {
                                                                                marginTop: "4px",
                                                                                marginBottom: "4px",
                                                                                marginRight: "8px",
                                                                                wordBreak: "break-word",
                                                                                color: ticket.campaign &&
                                                                                    ticket.campaign
                                                                                        .field_options &&
                                                                                    ticket.campaign.field_options.find(o => o.data ===
                                                                                        "assignee_ids") &&
                                                                                    !ticket.campaign.field_options.find(o => o.data ===
                                                                                        "assignee_ids").visible
                                                                                    ? "#bebebe"
                                                                                    : "inherit",
                                                                            }, label: assignee.first_name +
                                                                                " " +
                                                                                ((_b = assignee.last_name) !== null && _b !== void 0 ? _b : ""), size: "small" })) }, assignee.id));
                                                                }) }))] })) }))), ticket.ticket_detail.forwarded_call && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline", style: {
                                                                    color: ticket.campaign &&
                                                                        ticket.campaign.field_options &&
                                                                        ticket.campaign.field_options.find(o => o.data === "forwarded_call") &&
                                                                        !ticket.campaign.field_options.find(o => o.data === "forwarded_call").visible
                                                                        ? "#bebebe"
                                                                        : "inherit",
                                                                } }, { children: t("tickets.ticket-attributes.forwarded-call") })), _jsx(Typography, Object.assign({ style: {
                                                                    color: ticket.campaign &&
                                                                        ticket.campaign.field_options &&
                                                                        ticket.campaign.field_options.find(o => o.data === "forwarded_call") &&
                                                                        !ticket.campaign.field_options.find(o => o.data === "forwarded_call").visible
                                                                        ? "#bebebe"
                                                                        : "inherit",
                                                                } }, { children: ticket.ticket_detail.forwarded_call
                                                                    ? t("yes")
                                                                    : t("no") }))] })) }))), ticket.campaign && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Chip, { onClick: () => {
                                                                        navigate(tenancy.link("/campaigns/" +
                                                                            ticket.campaign.id));
                                                                    }, label: prettifyText(ticket.campaign.name), variant: "outlined", size: "small" }) }))] })) }))), ticket.audience && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.audiences.audience") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Chip, { onClick: () => {
                                                                        navigate(tenancy.link("/campaigns/" +
                                                                            ticket.campaign.id +
                                                                            "/audiences/" +
                                                                            ticket.audience.id));
                                                                    }, label: prettifyText(ticket.audience.name), variant: "outlined", size: "small" }) }))] })) }))), ticket.ticket_detail.start_date_time && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline", style: {
                                                                    color: ticket.campaign &&
                                                                        ticket.campaign.field_options &&
                                                                        ticket.campaign.field_options.find(o => o.data === "start_date_time") &&
                                                                        !ticket.campaign.field_options.find(o => o.data === "start_date_time").visible
                                                                        ? "#bebebe"
                                                                        : "inherit",
                                                                } }, { children: t("tickets.ticket-attributes.start-date") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                                                        color: ticket.campaign &&
                                                                            ticket.campaign.field_options &&
                                                                            ticket.campaign.field_options.find(o => o.data ===
                                                                                "start_date_time") &&
                                                                            !ticket.campaign.field_options.find(o => o.data ===
                                                                                "start_date_time").visible
                                                                            ? "#bebebe"
                                                                            : "inherit",
                                                                    } }, { children: new Date(Date.parse(ticket.ticket_detail
                                                                        .start_date_time)).toLocaleDateString("en-GB") +
                                                                        " " +
                                                                        new Date(Date.parse(ticket.ticket_detail
                                                                            .start_date_time)).toLocaleTimeString("hr-HR", {
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                        }) })) }))] })) }))), ticket.ticket_detail.due_date_time && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline", style: {
                                                                    color: ticket.campaign &&
                                                                        ticket.campaign.field_options &&
                                                                        ticket.campaign.field_options.find(o => o.data === "due_date_time") &&
                                                                        !ticket.campaign.field_options.find(o => o.data === "due_date_time").visible
                                                                        ? "#bebebe"
                                                                        : "inherit",
                                                                } }, { children: t("tickets.ticket-attributes.due-date") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                                                        color: ticket.campaign &&
                                                                            ticket.campaign.field_options &&
                                                                            ticket.campaign.field_options.find(o => o.data ===
                                                                                "due_date_time") &&
                                                                            !ticket.campaign.field_options.find(o => o.data ===
                                                                                "due_date_time").visible
                                                                            ? "#bebebe"
                                                                            : "inherit",
                                                                    } }, { children: new Date(Date.parse(ticket.ticket_detail
                                                                        .due_date_time)).toLocaleDateString("en-GB") +
                                                                        " " +
                                                                        new Date(Date.parse(ticket.ticket_detail
                                                                            .due_date_time)).toLocaleTimeString("hr-HR", {
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                        }) })) }))] })) }))), ticket.ticket_detail.estimate && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline", style: {
                                                                    color: ticket.campaign &&
                                                                        ticket.campaign.field_options &&
                                                                        ticket.campaign.field_options.find(o => o.data === "estimate") &&
                                                                        !ticket.campaign.field_options.find(o => o.data === "estimate").visible
                                                                        ? "#bebebe"
                                                                        : "inherit",
                                                                } }, { children: t("tickets.ticket-attributes.estimated-time") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                                                        color: ticket.campaign &&
                                                                            ticket.campaign.field_options &&
                                                                            ticket.campaign.field_options.find(o => o.data === "estimate") &&
                                                                            !ticket.campaign.field_options.find(o => o.data === "estimate").visible
                                                                            ? "#bebebe"
                                                                            : "inherit",
                                                                    } }, { children: formatDuration(intervalToDuration({
                                                                        start: sub(new Date(), {
                                                                            seconds: ticket.ticket_detail
                                                                                .estimate,
                                                                        }),
                                                                        end: new Date(),
                                                                    }), {
                                                                        locale: prefs.lang === "en"
                                                                            ? locales["enUS"]
                                                                            : locales["hr"],
                                                                    }) })) }))] })) }))), ticket.ticket_detail.progress !== null &&
                                                    ticket.ticket_detail.progress > 0 && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline", style: {
                                                                    color: ticket.campaign &&
                                                                        ticket.campaign.field_options &&
                                                                        ticket.campaign.field_options.find(o => o.data === "progress") &&
                                                                        !ticket.campaign.field_options.find(o => o.data === "progress").visible
                                                                        ? "#bebebe"
                                                                        : "inherit",
                                                                } }, { children: t("tickets.ticket-attributes.progress") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle1", style: {
                                                                        color: ticket.campaign &&
                                                                            ticket.campaign
                                                                                .field_options &&
                                                                            ticket.campaign.field_options.find(o => o.data ===
                                                                                "progress") &&
                                                                            !ticket.campaign.field_options.find(o => o.data ===
                                                                                "progress").visible
                                                                            ? "#bebebe"
                                                                            : "inherit",
                                                                    } }, { children: ticket.ticket_detail.progress +
                                                                        "%" })) }))] })) }))), ticket.ticket_detail.notes && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline", style: {
                                                                    color: ticket.campaign &&
                                                                        ticket.campaign.field_options &&
                                                                        ticket.campaign.field_options.find(o => o.data === "notes" &&
                                                                            o.table === "ticket_details") &&
                                                                        !ticket.campaign.field_options.find(o => o.data === "notes" &&
                                                                            o.table === "ticket_details").visible
                                                                        ? "#bebebe"
                                                                        : "inherit",
                                                                } }, { children: t("tickets.ticket-attributes.notes") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: _jsx(Input, { fullWidth: true, multiline: true, value: ticket.ticket_detail.notes, disableUnderline: true, inputProps: {
                                                                        readOnly: true,
                                                                        style: {
                                                                            color: ticket.campaign &&
                                                                                ticket.campaign
                                                                                    .field_options &&
                                                                                ticket.campaign.field_options.find(o => o.data ===
                                                                                    "notes" &&
                                                                                    o.table ===
                                                                                        "ticket_details") &&
                                                                                !ticket.campaign.field_options.find(o => o.data ===
                                                                                    "notes" &&
                                                                                    o.table ===
                                                                                        "ticket_details").visible
                                                                                ? "#bebebe"
                                                                                : "inherit",
                                                                        },
                                                                    } }) }))] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: { paddingBottom: 5 } }, { children: _jsx(Divider, {}) })) }))] })), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: new Date(Date.parse(ticket.created_at)).toLocaleDateString("en-GB") +
                                                                " " +
                                                                new Date(Date.parse(ticket.created_at)).toLocaleTimeString("hr-HR", {
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                }) }))] })), ticket.created_by && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.created-by") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: roles.isAdmin || roles.isManager ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" +
                                                                        ticket.created_by.id), onClick: event => {
                                                                        event.preventDefault();
                                                                        navigate(tenancy.link("/settings/users/" +
                                                                            ticket.created_by.id));
                                                                    }, underline: "hover" }, { children: ticket.created_by.first_name +
                                                                        (ticket.created_by.last_name
                                                                            ? " " +
                                                                                ticket.created_by
                                                                                    .last_name
                                                                            : "") }))) : (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: ticket.created_by.first_name +
                                                                        (ticket.created_by.last_name
                                                                            ? " " +
                                                                                ticket.created_by
                                                                                    .last_name
                                                                            : "") }))) }))] })) }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: new Date(Date.parse(ticket.updated_at)).toLocaleDateString("en-GB") +
                                                                " " +
                                                                new Date(Date.parse(ticket.updated_at)).toLocaleTimeString("hr-HR", {
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                }) }))] })), ticket.updated_by && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.updated-by") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingY: 0.5,
                                                                } }, { children: roles.isAdmin || roles.isManager ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" +
                                                                        ticket.updated_by.id), onClick: event => {
                                                                        event.preventDefault();
                                                                        navigate(tenancy.link("/settings/users/" +
                                                                            ticket.updated_by.id));
                                                                    }, underline: "hover" }, { children: ticket.updated_by.first_name +
                                                                        (ticket.updated_by.last_name
                                                                            ? " " +
                                                                                ticket.updated_by
                                                                                    .last_name
                                                                            : "") }))) : (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: ticket.updated_by.first_name +
                                                                        (ticket.updated_by.last_name
                                                                            ? " " +
                                                                                ticket.updated_by
                                                                                    .last_name
                                                                            : "") }))) }))] })) })))] }))] })) })) : (_jsx(Box, Object.assign({ sx: {
                                    paddingBottom: 2,
                                } }, { children: _jsx(Alert, Object.assign({ severity: "info" }, { children: t("tickets.ticket-details.no-details") })) }))) }))] })), (roles.isAdmin || roles.isManager) &&
                    ticket.ticket_detail &&
                    ticket.ticket_detail.history.length > 0 && (_jsx(TicketDetailsHistory, { status: "view", ticket: ticket, modal: modal }))] })) }));
}
