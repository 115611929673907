import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Bar } from "react-chartjs-2";
import { alpha } from "@mui/material";
import { differenceInMonths, endOfMonth, format, formatDistanceToNow, startOfDay, startOfMonth, sub, subMonths, } from "date-fns";
import { enUS, hr } from "date-fns/locale";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import ImportIcon from "@mui/icons-material/Upload";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { usePrefs } from "../../../services/prefs";
import { useRoles } from "../../../services/roles";
import { useApiTenant } from "../../../api/api-tenant";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Dropzone from "react-dropzone";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import FormHelperText from "@mui/material/FormHelperText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import { useAlert } from "../../../services/alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
export default function McdMetrics() {
    const { t } = useTranslation();
    const prefs = usePrefs();
    const roles = useRoles();
    const api = useApiTenant();
    const alert = useAlert();
    const locales = { enUS, hr };
    const firstDate = subMonths(startOfMonth(startOfDay(new Date())), 1);
    const lastDate = new Date(2024, 4, 1);
    const diffInMonths = differenceInMonths(firstDate, lastDate);
    const months = [];
    for (let i = 0; i <= diffInMonths; i++) {
        months.push(subMonths(firstDate, i));
    }
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [cachedAt, setCachedAt] = useState(new Date());
    const [month, setMonth] = useState(firstDate);
    const [labels, setLabels] = useState([]);
    const [internalValues, setInternalValues] = useState([]);
    const [externalValues, setExternalValues] = useState([]);
    const [internalLabel, setInternalLabel] = useState("");
    const [externalLabel, setExternalLabel] = useState("");
    const [isImportOpened, setIsImportOpened] = useState(false);
    const [importedFile, setImportedFile] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const isMobile = useMediaQuery("(max-width: 767px)");
    const getCustomMetric = (invalidateCache) => {
        setIsLoading(true);
        if (isError)
            setIsError(false);
        api.GET_customMetrics(1, format(startOfMonth(month), "yyyy-MM-dd").toString() + " 00:00:00", format(endOfMonth(month), "yyyy-MM-dd").toString() + " 23:59:59", invalidateCache ? 1 : undefined)
            .then(response => {
            setLabels(response.data.data.map(item => item.label));
            setInternalValues(response.data.data.map(item => item.internal_value));
            setExternalValues(response.data.data.map(item => item.external_value));
            setInternalLabel(response.data.internal_value_label);
            setExternalLabel(response.data.external_value_label);
            setCachedAt(new Date(response.data.cached_at));
            setIsLoading(false);
        })
            .catch(error => {
            setIsError(true);
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    };
    const importCustomMetric = () => {
        if (!importedFile)
            return;
        setIsSaving(true);
        api.IMPORT_customMetrics(1, importedFile)
            .then(() => {
            alert.show(t("metrics.data-imported"), "info");
            setIsSaving(false);
            setIsImportOpened(false);
            setImportedFile(null);
            getCustomMetric(true);
        })
            .catch(error => {
            console.error(error.response);
            alert.show(t("metrics.importing-error"), "error");
            setIsSaving(false);
            setIsImportOpened(false);
        });
    };
    useEffect(() => {
        getCustomMetric(undefined);
    }, [month]);
    if (isMobile) {
        return null;
    }
    return (_jsxs(Box, Object.assign({ sx: {
            marginBottom: 4,
        } }, { children: [_jsx(Card, { children: _jsxs(Box, Object.assign({ sx: {
                        padding: 3,
                    } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 2,
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: t("metrics.efficiency-trend") })) })), _jsx(Select, Object.assign({ variant: "standard", value: month.toDateString(), onChange: event => {
                                        setMonth(new Date(event.target.value));
                                        setIsLoading(true);
                                    } }, { children: months.map((item, index) => (_jsx(MenuItem, Object.assign({ value: item.toDateString() }, { children: item.toLocaleDateString(prefs.lang === "en" ? "en-GB" : "hr-HR", {
                                            month: "long",
                                            year: "numeric",
                                        }) }), index))) }))] })), isLoading ? (_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "545px",
                            } }, { children: _jsx(CircularProgress, { color: "primary", size: 30 }) }))) : isError ? (_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "545px",
                            } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: t("metrics.error-occured") })) }))) : (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        justifyContent: "center",
                                    } }, { children: _jsx(Box, Object.assign({ sx: {
                                            width: "100%",
                                            height: "470px",
                                        } }, { children: _jsx(Bar, { data: {
                                                labels: labels.map(label => label.replace("*", "")),
                                                datasets: [
                                                    {
                                                        label: internalLabel,
                                                        data: internalValues,
                                                        // @ts-ignore
                                                        type: "line",
                                                        yAxisID: "average",
                                                        backgroundColor: prefs.theme === "light" ? "#000" : "#fff",
                                                        borderColor: alpha(prefs.theme === "light" ? "#000" : "#fff", 0.1),
                                                        borderWidth: 1,
                                                    },
                                                    {
                                                        label: externalLabel,
                                                        data: externalValues,
                                                        yAxisID: "ratio",
                                                        backgroundColor: externalValues.map((value, index) => {
                                                            var _a;
                                                            const hasAsterisk = (_a = labels[index]) === null || _a === void 0 ? void 0 : _a.includes("*");
                                                            return hasAsterisk
                                                                ? alpha("#90a4ae", 0.3)
                                                                : value < 16000
                                                                    ? alpha("#db0a18", 0.3)
                                                                    : value > 50000
                                                                        ? alpha("#165331", 0.3)
                                                                        : alpha("#fbbc0e", 0.3);
                                                        }),
                                                        borderColor: externalValues.map((value, index) => {
                                                            var _a;
                                                            const hasAsterisk = (_a = labels[index]) === null || _a === void 0 ? void 0 : _a.includes("*");
                                                            return hasAsterisk
                                                                ? alpha("#90a4ae", 1)
                                                                : value < 16000
                                                                    ? alpha("#db0a18", 1)
                                                                    : value > 50000
                                                                        ? alpha("#165331", 1)
                                                                        : alpha("#fbbc0e", 1);
                                                        }),
                                                        borderWidth: 1,
                                                    },
                                                ],
                                            }, options: {
                                                maintainAspectRatio: false,
                                                animation: { duration: 0 },
                                                plugins: {
                                                    legend: {
                                                        position: "bottom",
                                                        labels: {
                                                            boxWidth: 3,
                                                            boxHeight: 3,
                                                            usePointStyle: true,
                                                            pointStyle: "circle",
                                                            color: prefs.theme === "light"
                                                                ? "rgba(0, 0, 0, 0.87)"
                                                                : "#fff",
                                                        },
                                                    },
                                                    tooltip: {
                                                        callbacks: {
                                                            label: function (context) {
                                                                if (
                                                                // @ts-ignore
                                                                context.dataset.type === "line") {
                                                                    const time = formatDistanceToNow(sub(new Date(), {
                                                                        minutes: context.parsed.y,
                                                                    }), {
                                                                        locale: locales["hr"],
                                                                    });
                                                                    return (context.dataset.label +
                                                                        ": " +
                                                                        time);
                                                                }
                                                                return (context.dataset.label +
                                                                    ": " +
                                                                    context.parsed.y);
                                                            },
                                                        },
                                                    },
                                                },
                                                scales: {
                                                    xAxes: {
                                                        ticks: {
                                                            autoSkip: false,
                                                            color: prefs.theme === "light"
                                                                ? "rgba(0, 0, 0, 0.6)"
                                                                : "rgba(255, 255, 255, 0.7)",
                                                        },
                                                    },
                                                    ratio: {
                                                        type: "linear",
                                                        display: true,
                                                        position: "left",
                                                        ticks: {
                                                            color: prefs.theme === "light"
                                                                ? "rgba(0, 0, 0, 0.6)"
                                                                : "rgba(255, 255, 255, 0.7)",
                                                        },
                                                    },
                                                    average: {
                                                        type: "linear",
                                                        display: false,
                                                        position: "right",
                                                        grid: {
                                                            drawOnChartArea: false,
                                                        },
                                                        ticks: {
                                                            color: prefs.theme === "light"
                                                                ? "rgba(0, 0, 0, 0.6)"
                                                                : "rgba(255, 255, 255, 0.7)",
                                                        },
                                                    },
                                                },
                                            }, height: 100 }) })) })), _jsxs(Box, Object.assign({ sx: {
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 2,
                                        marginBottom: 2,
                                    } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            } }, { children: [_jsx(Box, { sx: {
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: alpha("#db0a18", 0.3),
                                                        border: "1px solid #db0a18",
                                                        marginRight: 1,
                                                    } }), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", lineHeight: 1.2 }, { children: "1 prigovor na manje od 16 tisu\u0107a transakcija" }))] })), _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            } }, { children: [_jsx(Box, { sx: {
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: alpha("#fbbc0e", 0.3),
                                                        border: "1px solid #fbbc0e",
                                                        marginRight: 1,
                                                    } }), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", lineHeight: 1.2 }, { children: "1 prigovor na 16 do 50 tisu\u0107a transakcija" }))] })), _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            } }, { children: [_jsx(Box, { sx: {
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: alpha("#165331", 0.3),
                                                        border: "1px solid #165331",
                                                        marginRight: 1,
                                                    } }), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", lineHeight: 1.2 }, { children: "1 prigovor na vi\u0161e od 50 tisu\u0107a transakcija" }))] })), _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            } }, { children: [_jsx(Box, { sx: {
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: alpha("#90a4ae", 0.3),
                                                        border: "1px solid #90a4ae",
                                                        marginRight: 1,
                                                    } }), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", lineHeight: 1.2 }, { children: "Restoran nema prigovora" }))] }))] })), _jsxs(Box, { children: [_jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: [t("metrics.results-from"), startOfMonth(month).toLocaleDateString("en-GB") + " - "] })), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", style: { paddingLeft: "4px" } }, { children: endOfMonth(month).toLocaleDateString("en-GB") }))] })), _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: [t("metrics.cached-at"), cachedAt.toLocaleDateString("en-GB") +
                                                            " " +
                                                            cachedAt.toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })] })), _jsxs(Button, Object.assign({ size: "small", onClick: () => {
                                                        getCustomMetric(true);
                                                    }, style: {
                                                        padding: 0,
                                                        marginLeft: "5px",
                                                        color: prefs.theme === "light" ? "#00000099" : "#ffffffb3",
                                                        fontSize: "11px",
                                                        textTransform: "none",
                                                    } }, { children: [_jsx(RefreshIcon, { fontSize: "small", style: { marginRight: 2 }, color: "action" }), t("metrics.refresh-data")] })), roles.isAdmin && (_jsxs(Button, Object.assign({ size: "small", onClick: () => {
                                                        setIsImportOpened(true);
                                                    }, style: {
                                                        padding: 0,
                                                        color: prefs.theme === "light"
                                                            ? "#00000099"
                                                            : "#ffffffb3",
                                                        fontSize: "11px",
                                                        textTransform: "none",
                                                    } }, { children: [_jsx(ImportIcon, { fontSize: "small", style: { marginRight: 2 }, color: "action" }), t("metrics.import-data")] })))] }))] })] }))] })) }), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isImportOpened, onClose: () => setIsImportOpened(false) }, { children: [_jsx(DialogTitle, { children: t("metrics.import-data") }), _jsx(DialogContent, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(Dropzone, Object.assign({ maxFiles: 1, maxSize: 50000000, onDrop: files => {
                                        setImportedFile(files[0]);
                                    } }, { children: ({ getRootProps, getInputProps }) => (_jsx(Card, Object.assign({ variant: "outlined" }, getRootProps(), { children: _jsx(CardActionArea, { children: _jsxs(Box, Object.assign({ sx: {
                                                    height: "145px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                    backgroundColor: prefs.theme === "dark" ? "#424242" : "#fff",
                                                } }, { children: [importedFile ? (_jsx(Typography, { children: importedFile.name })) : (_jsx(Skeleton, { variant: "text", width: "200px", height: "170px" })), _jsx("input", Object.assign({}, getInputProps({
                                                        accept: ".xlsx,.xls",
                                                    })))] })) }) }))) })), _jsxs(FormHelperText, { children: [t("drag&drop") + " ", " ", _jsx("strong", { children: ".xlsx" }), " ", " " + t("or") + " ", _jsx("strong", { children: ".xls" }), " ", " " + t("file")] })] })) }), _jsx(DialogActions, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", size: "small", loading: isSaving, disabled: !importedFile, onClick: () => {
                                importCustomMetric();
                            }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("metrics.import-data") })) })] }))] })));
}
