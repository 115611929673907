import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
export default function InputRating({ required, value, setValue, label, info, icon, emptyIcon, size, max, color, precision, disabled, readonly, error, errors, isError, setIsError, }) {
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, Object.assign({ variant: "body1", gutterBottom: true }, { children: label })) })), _jsxs(FormControl, Object.assign({ required: required, fullWidth: true, variant: "standard", error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(Box, Object.assign({ sx: {
                            marginLeft: 1,
                        } }, { children: _jsx(FormControlLabel, { label: "", control: _jsx(Rating, { color: color, disabled: disabled ? disabled : false, readOnly: readonly ? readonly : false, value: value, icon: _jsx(Icon, Object.assign({ color: color ? "disabled" : "secondary" }, { children: icon })), emptyIcon: _jsx(Icon, { children: emptyIcon }), size: size, max: max, precision: precision, onChange: (event, newValue) => {
                                    if (setValue)
                                        setValue(newValue);
                                    if (isError &&
                                        errors &&
                                        error &&
                                        setIsError &&
                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                        setIsError(false);
                                } }) }) })), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
