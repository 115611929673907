import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../../../elements/blocks/intro";
import Nope from "../../../../elements/blocks/nope";
import CustomFieldDetails from "../../../../elements/custom-fields/custom-field-details";
import { useApiTenant } from "../../../../../api/api-tenant";
export default function ActivityFieldView() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [customField, setCustomField] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_customField(id)
            .then(response => {
            setCustomField(response.data.custom_field);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: customField ? customField.field_name : t("fields.field") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("fields.activity-fields.activity-fields"),
                        link: "/settings/fields/activities",
                    },
                    {
                        name: customField ? customField.field_name : t("fields.field"),
                        link: "/settings/fields/activities/" + id,
                    },
                ], button: customField
                    ? {
                        label: t("fields.activity-fields.edit-activity-field"),
                        link: "/settings/fields/activities/" + id + "/edit",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : customField ? (_jsx(CustomFieldDetails, { type: "ticket_activities", customField: customField })) : (_jsx(Nope, { title: t("errors.404"), caption: t("fields.activity-fields.errors.not-found") }))] }));
}
