import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import TicketsTable from "./tickets-table";
export default function TicketHistory({ campaign, contact, createTicket, ticketHistoryOpened, isLoading, lead, }) {
    const { t } = useTranslation();
    return (_jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "lg", open: ticketHistoryOpened }, { children: _jsxs(DialogContent, { children: [_jsxs(Box, Object.assign({ sx: {
                        paddingTop: 2,
                        paddingBottom: 2,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket-history.tickets-with-same-contact-&-campaign-found") })), _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-history.use-existing-create-new") }))] })), _jsx(TicketsTable, { type: "history", campaign: campaign, contact: contact, lead: lead }), _jsx(Box, Object.assign({ sx: {
                        paddingTop: 2,
                        paddingBottom: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                    } }, { children: _jsx(LoadingButton, Object.assign({ loading: isLoading, loadingIndicator: _jsx(CircularProgress, { color: "primary", size: 18 }), onClick: () => createTicket() }, { children: t("tickets.create-new-ticket") })) }))] }) })));
}
