import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/ExpandMore";
import AccountIcon from "@mui/icons-material/AccountCircle";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { prettifyText } from "../../../helpers/clean";
export default function SearchTicketCard({ ticket }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const roles = useRoles();
    const tenancy = useTenancy();
    const [isExpanded, setExpanded] = useState(false);
    return (_jsxs(Card, Object.assign({ variant: "outlined", style: {
            position: "relative",
        } }, { children: [_jsx(CardActionArea, Object.assign({ onClick: () => navigate(tenancy.link("/tickets/" + ticket.id)) }, { children: _jsxs(CardContent, { children: [_jsxs(Box, Object.assign({ sx: {
                                paddingRight: 4,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: ticket.is_draft ? t("tickets.draft") : "#" + ticket.id })), ticket.contact && (_jsx(Typography, Object.assign({ variant: "h6" }, { children: ticket.contact.type
                                        ? ticket.contact.type === "business"
                                            ? ticket.contact.company
                                                ? ticket.contact.company
                                                : ticket.contact.is_anonymous === true
                                                    ? t("contacts.anonymous")
                                                    : ticket.contact.first_name || ticket.contact.last_name
                                                        ? (ticket.contact.first_name
                                                            ? ticket.contact.first_name
                                                            : "") +
                                                            (ticket.contact.last_name
                                                                ? " " + ticket.contact.last_name
                                                                : "")
                                                        : ""
                                            : ticket.contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : ticket.contact.first_name || ticket.contact.last_name
                                                    ? (ticket.contact.first_name
                                                        ? ticket.contact.first_name
                                                        : "") +
                                                        (ticket.contact.last_name
                                                            ? " " + ticket.contact.last_name
                                                            : "")
                                                    : ticket.contact.company
                                                        ? ticket.contact.company
                                                        : ""
                                        : ticket.contact.company
                                            ? ticket.contact.company
                                            : ticket.contact.is_anonymous === true
                                                ? t("contacts.anonymous")
                                                : ticket.contact.first_name || ticket.contact.last_name
                                                    ? (ticket.contact.first_name ? ticket.contact.first_name : "") +
                                                        (ticket.contact.last_name
                                                            ? " " + ticket.contact.last_name
                                                            : "")
                                                    : "" }))), ticket.campaign && (_jsx(Typography, Object.assign({ color: "textSecondary" }, { children: ticket.campaign.name })))] })), ticket.ticket_detail && (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, Object.assign({ sx: {
                                                paddingTop: 2,
                                                paddingBottom: 1,
                                            } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.status") })) })), _jsx(Chip, { size: "small", label: ticket.ticket_detail.status.name, style: {
                                                border: "1px solid " +
                                                    (ticket.ticket_detail.status.type === "pending"
                                                        ? "rgba(156, 39, 176, 1)"
                                                        : ticket.ticket_detail.status.type === "resolved"
                                                            ? "rgba(76, 175, 80, 1)"
                                                            : "rgba(37, 64, 146, 1)"),
                                                backgroundColor: ticket.ticket_detail.status.type === "pending"
                                                    ? "rgba(156, 39, 176, 0.2)"
                                                    : ticket.ticket_detail.status.type === "resolved"
                                                        ? "rgba(76, 175, 80, 0.2)"
                                                        : "rgba(37, 64, 146, 0.2)",
                                            } })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, Object.assign({ sx: {
                                                paddingTop: 2,
                                                paddingBottom: 1,
                                            } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.priority") })) })), _jsx(Chip, { label: ticket.ticket_detail.priority.name, size: "small", style: {
                                                border: "1px solid " +
                                                    (ticket.ticket_detail.priority.level < 33
                                                        ? "rgb(244, 67, 54, 1)"
                                                        : ticket.ticket_detail.priority.level >= 33 &&
                                                            ticket.ticket_detail.priority.level <= 66
                                                            ? "rgb(255, 152, 0, 1)"
                                                            : "rgb(33, 150, 243, 1)"),
                                                backgroundColor: ticket.ticket_detail.priority.level < 33
                                                    ? "rgb(244, 67, 54, 0.2)"
                                                    : ticket.ticket_detail.priority.level >= 33 &&
                                                        ticket.ticket_detail.priority.level <= 66
                                                        ? "rgb(255, 152, 0, 0.2)"
                                                        : "rgb(33, 150, 243, 0.2)",
                                            } })] }))] })))] }) })), _jsx(Box, Object.assign({ sx: {
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                } }, { children: _jsx(IconButton, Object.assign({ onClick: event => {
                        event.stopPropagation();
                        setExpanded(!isExpanded);
                    }, size: "large" }, { children: _jsx(MoreIcon, {}) })) })), _jsx(Collapse, Object.assign({ in: isExpanded }, { children: _jsx(CardContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [ticket.ticket_detail && ticket.ticket_detail.assignees.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.assignee") })) }), ticket.ticket_detail.assignees.map(assignee => {
                                        var _a;
                                        return (_jsx(Chip, { onClick: () => {
                                                navigate(tenancy.link("/settings/users/" + assignee.id));
                                            }, style: {
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                                wordBreak: "break-word",
                                                color: ticket.campaign &&
                                                    ticket.campaign.field_options &&
                                                    ticket.campaign.field_options.find(o => o.data === "assignee_ids") &&
                                                    !ticket.campaign.field_options.find(o => o.data === "assignee_ids").visible
                                                    ? "#bebebe"
                                                    : "inherit",
                                            }, label: assignee.first_name +
                                                " " +
                                                (assignee.last_name ? assignee.last_name : ""), variant: "outlined", size: "small", icon: _jsx(AccountIcon, { style: {
                                                    color: assignee.id === ((_a = roles.userTenant) === null || _a === void 0 ? void 0 : _a.id)
                                                        ? "#4caf50"
                                                        : assignee.email.includes("modernekomunikacije.hr") ||
                                                            assignee.email.includes("significo.hr")
                                                            ? "#254092"
                                                            : "#bebebe",
                                                } }) }, assignee.id));
                                    })] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: new Date(Date.parse(ticket.updated_at)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(ticket.updated_at)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }) }))] })), ticket.groups && ticket.groups.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-attributes.groups") })) }), ticket.groups.map(group => {
                                        return (_jsx(Chip, { size: "small", label: prettifyText(group.name), style: {
                                                marginRight: "8px",
                                                marginBottom: "8px",
                                            } }, group.id));
                                    })] })))] })) }) }))] })));
}
