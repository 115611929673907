import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Card from "@mui/material/Card";
import { formatDistanceToNow } from "date-fns";
import { prettifyText } from "../../../helpers/clean";
import { usePrefs } from "../../../services/prefs";
import { enUS, hr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
export default function ConversationsView({ conversations, hasNewForm }) {
    const { t } = useTranslation();
    const prefs = usePrefs();
    const locales = { enUS, hr };
    return (_jsx(Fragment, { children: conversations.map((conversation, index) => (_jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, {}), hasNewForm ? (_jsx(TimelineConnector, {})) : index !== conversations.length - 1 ? (_jsx(TimelineConnector, {})) : null] }), _jsx(TimelineContent, { children: _jsxs(Box, Object.assign({ sx: {
                            marginBottom: hasNewForm
                                ? 2
                                : index !== conversations.length - 1
                                    ? 2
                                    : 0,
                        } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                    marginBottom: 1,
                                } }, { children: [conversation.tenant_user && (_jsx(Typography, { children: `${conversation.tenant_user.first_name} ${conversation.tenant_user.last_name}` })), _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: prefs.lang === "hr"
                                                    ? t("tickets.ticket-information.ticket-information-history.ago") +
                                                        " " +
                                                        formatDistanceToNow(new Date(Date.parse(conversation.created_at)), {
                                                            locale: locales["hr"],
                                                        })
                                                    : prettifyText(formatDistanceToNow(new Date(Date.parse(conversation.created_at)), {
                                                        locale: locales["enUS"],
                                                    }) +
                                                        " " +
                                                        t("tickets.ticket-information.ticket-information-history.ago")) })), _jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: new Date(Date.parse(conversation.created_at)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(conversation.created_at)).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }) }))] }))] })), _jsx(Card, Object.assign({ variant: "outlined", sx: {
                                    padding: 2,
                                } }, { children: _jsx(Typography, { component: "div", dangerouslySetInnerHTML: {
                                        __html: conversation.text.replace(/\n/g, "<br />"),
                                    } }) }))] })) })] }, conversation.id))) }));
}
