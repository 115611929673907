import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import "chart.js/auto";
import { makeStyles } from "@mui/styles";
import { format, isValid, startOfWeek, endOfWeek, startOfMonth, startOfYear } from "date-fns";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import RefreshIcon from "@mui/icons-material/Refresh";
import ViewIcon from "@mui/icons-material/Visibility";
import MetricTrendModal from "./metric-trend-modal";
import { usePrefs } from "../../../services/prefs";
import { colors } from "../../../helpers/colors";
import { alpha } from "@mui/material";
export default function MetricTrend({ title, labels, label, values, color, range, setRange, isLoading, setIsLoading, cachedAt, refresh, setRefresh, isReportModal, isError, statuses, priorities, chosenStatuses, setChosenStatuses, chosenPriorities, setChosenPriorities, }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const prefs = usePrefs();
    const [beginning, setBeginning] = useState("");
    const [end, setEnd] = useState("");
    const [isOpened, setIsOpened] = useState(false);
    const useStyles = makeStyles({
        title: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "320px",
        },
    });
    const classes = useStyles();
    let sumValues = 0;
    values.map(value => (sumValues += value));
    const data = {
        labels: labels,
        datasets: [
            {
                label: label,
                data: values,
                backgroundColor: color
                    ? "rgba(" + color + ")"
                    : process.env.APP_ID === "mcd"
                        ? prefs.theme === "dark"
                            ? colors.primary.dark
                            : colors.primary.light
                        : prefs.theme === "dark"
                            ? colors.primary.light
                            : colors.primary.dark,
                borderColor: color
                    ? "rgba(" + color + ",0.2)"
                    : process.env.APP_ID === "mcd"
                        ? alpha(prefs.theme === "dark" ? colors.primary.dark : colors.primary.light, 0.1)
                        : alpha(prefs.theme === "dark" ? colors.primary.light : colors.primary.dark, 0.1),
            },
        ],
    };
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                display: !!isReportModal,
                beginAtZero: sumValues <= 0,
            },
            x: { display: !!isReportModal },
        },
        animation: { duration: 0 },
        plugins: {
            legend: {
                display: false,
            },
        },
    };
    useEffect(() => {
        let today = new Date();
        if (range === "this_week") {
            let start = format(startOfWeek(today, { weekStartsOn: 1 }), "yyyy-MM-dd");
            setBeginning(start.toString());
            setEnd(today.toString());
        }
        else if (range === "last_week") {
            let sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
            const start = startOfWeek(sevenDaysBeforeDate, { weekStartsOn: 1 });
            const end = endOfWeek(sevenDaysBeforeDate, { weekStartsOn: 1 });
            setBeginning(format(start, "yyyy-MM-dd").toString());
            setEnd(format(end, "yyyy-MM-dd").toString());
        }
        else if (range === "this_month") {
            const start = startOfMonth(today);
            setBeginning(format(start, "yyyy-MM-dd").toString());
            setEnd(today.toString());
        }
        else if (range === "last_month") {
            const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
            setBeginning(format(firstDayPrevMonth, "yyyy-MM-dd").toString());
            setEnd(format(lastDayPrevMonth, "yyyy-MM-dd").toString());
        }
        else if (range === "this_year") {
            const start = startOfYear(today);
            setBeginning(format(start, "yyyy-MM-dd").toString());
            setEnd(today.toString());
        }
        else if (range === "last_year") {
            const firstDayPrevYear = new Date(today.getFullYear() - 1, 0, 1);
            const lastDayPrevYear = new Date(today.getFullYear(), 0, 0);
            setBeginning(format(firstDayPrevYear, "yyyy-MM-dd").toString());
            setEnd(format(lastDayPrevYear, "yyyy-MM-dd").toString());
        }
    }, [range, refresh]);
    const displayCard = () => {
        return (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                        display: !isMobile || (isMobile && !isReportModal) ? "flex" : "inline",
                        justifyContent: !isMobile || (isMobile && !isReportModal) ? "space-between" : "normal",
                        alignItems: "center",
                        marginBottom: 2,
                    } }, { children: [_jsx(Box, Object.assign({ className: !isReportModal ? classes.title : undefined, sx: {
                                marginRight: 2,
                            } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: title })) })), _jsxs(Box, Object.assign({ sx: {
                                paddingTop: isMobile && isReportModal ? 2 : 0,
                                display: !isMobile && isReportModal ? "flex" : "inline",
                                justifyContent: !isMobile && isReportModal ? "space-between" : "normal",
                                alignItems: "center",
                            } }, { children: [!isReportModal && (_jsxs(Button, Object.assign({ color: "primary", onClick: () => setIsOpened(true), style: {
                                        padding: 0,
                                        fontSize: "12px",
                                    } }, { children: [_jsx(ViewIcon, { fontSize: "small", style: { marginRight: 4 } }), t("metrics.view-report")] }))), isReportModal && (_jsxs(Fragment, { children: [statuses && chosenStatuses && setChosenStatuses && (_jsx(Box, Object.assign({ sx: {
                                                display: !isMobile ? "flex" : "inline",
                                                justifyContent: !isMobile ? "flex-start" : "normal",
                                                paddingRight: !isMobile ? 2 : 0,
                                                width: "200px",
                                            } }, { children: _jsx(Autocomplete, { fullWidth: true, multiple: true, options: statuses, getOptionLabel: status => status.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: chosenStatuses, onChange: (event, value) => {
                                                    setChosenStatuses(value);
                                                }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ size: "small", label: option.name }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { placeholder: t("tickets.ticket-attributes.status"), fullWidth: true }))) }) }))), priorities && chosenPriorities && setChosenPriorities && (_jsx(Box, Object.assign({ sx: {
                                                display: !isMobile ? "flex" : "inline",
                                                justifyContent: !isMobile ? "flex-start" : "normal",
                                                paddingRight: !isMobile ? 2 : 0,
                                                width: "200px",
                                            } }, { children: _jsx(Autocomplete, { fullWidth: true, multiple: true, options: priorities, getOptionLabel: priority => priority.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: chosenPriorities, onChange: (event, value) => {
                                                    setChosenPriorities(value);
                                                }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ size: "small", label: option.name }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { placeholder: t("tickets.ticket-attributes.priority"), fullWidth: true }))) }) }))), _jsxs(Select, Object.assign({ variant: "standard", value: range, onChange: event => {
                                                setRange(event.target.value);
                                                setIsLoading(true);
                                            } }, { children: [_jsx(MenuItem, Object.assign({ value: "this_week" }, { children: t("metrics.range-options.this-week") })), _jsx(MenuItem, Object.assign({ value: "last_week" }, { children: t("metrics.range-options.last-week") })), _jsx(MenuItem, Object.assign({ value: "this_month" }, { children: t("metrics.range-options.this-month") })), _jsx(MenuItem, Object.assign({ value: "last_month" }, { children: t("metrics.range-options.last-month") })), _jsx(MenuItem, Object.assign({ value: "this_year" }, { children: t("metrics.range-options.this-year") })), _jsx(MenuItem, Object.assign({ value: "last_year" }, { children: t("metrics.range-options.last-year") }))] }))] }))] }))] })), _jsx(Box, Object.assign({ sx: {
                        position: "relative",
                        height: isReportModal ? "520px" : "150px",
                        width: "100%",
                    } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        } }, { children: _jsx(CircularProgress, { color: "primary", size: isReportModal ? 60 : 30 }) }))) : isError ? (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: isReportModal ? "center" : "flex-start",
                            alignItems: isReportModal ? "center" : "flex-end",
                            height: isReportModal ? "465px" : "130px",
                            marginTop: 1,
                        } }, { children: _jsx(Typography, Object.assign({ variant: isReportModal ? "subtitle1" : "caption", color: "textSecondary" }, { children: t("metrics.error-occured") })) }))) : (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: isReportModal ? "center" : "normal",
                                    paddingTop: isMobile && isReportModal ? 2 : 0,
                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                        width: "100%",
                                        height: isReportModal && isMobile
                                            ? "430px"
                                            : isReportModal && !isMobile
                                                ? "465px"
                                                : "100px",
                                    } }, { children: _jsx(Line, { data: data, options: options, height: 100 }) })) })), cachedAt !== "" && (_jsxs(Grid, Object.assign({ container: true, style: {
                                    marginBottom: isMobile && isReportModal ? 2 : 0,
                                    marginTop: 7,
                                }, direction: "column" }, { children: [beginning !== "" &&
                                        isValid(new Date(beginning)) &&
                                        end !== "" && (_jsx(Grid, Object.assign({ item: true, xs: 7, sm: 7, md: 7 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                                marginTop: 1,
                                                flexDirection: "row",
                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: [t("metrics.results-from"), new Date(Date.parse(beginning)).toLocaleDateString("en-GB") + " - "] })), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", style: { paddingLeft: "4px" } }, { children: new Date(Date.parse(end)).toLocaleDateString("en-GB") }))] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 5, sm: 5, md: 5 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: [t("metrics.cached-at"), new Date(Date.parse(cachedAt)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(cachedAt)).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })] })), _jsxs(Button, Object.assign({ size: "small", onClick: () => {
                                                        setRefresh(true);
                                                    }, style: {
                                                        padding: 0,
                                                        marginLeft: "5px",
                                                        color: prefs.theme === "light"
                                                            ? "#00000099"
                                                            : "#ffffffb3",
                                                        fontSize: "11px",
                                                        textTransform: "none",
                                                    } }, { children: [_jsx(RefreshIcon, { fontSize: "small", style: { marginRight: 2 }, color: "action" }), t("metrics.refresh-data")] }))] })) }))] })))] })) }))] }));
    };
    return (_jsxs(Fragment, { children: [isReportModal ? (displayCard()) : (_jsx(Card, { children: _jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        flexDirection: "column",
                        paddingX: 3,
                        paddingTop: 2.5,
                        paddingBottom: 3,
                    } }, { children: displayCard() })) })), _jsx(MetricTrendModal, { isOpened: isOpened, setIsOpened: setIsOpened, title: title, labels: labels, label: label, values: values, color: color, range: range, setRange: setRange, isLoading: isLoading, setIsLoading: setIsLoading, cachedAt: cachedAt, refresh: refresh, setRefresh: setRefresh, isError: isError, statuses: statuses, priorities: priorities, chosenStatuses: chosenStatuses, setChosenStatuses: setChosenStatuses, chosenPriorities: chosenPriorities, setChosenPriorities: setChosenPriorities })] }));
}
