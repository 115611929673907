import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import RefreshIcon from "@mui/icons-material/Refresh";
import NewIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import Table from "../blocks/table";
import InputRadio from "../inputs/input-radio";
import { cleanText } from "../../../helpers/clean";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
import { useApiCentral } from "../../../api/api-central";
export default function GroupUsers({ type, group }) {
    const { t } = useTranslation();
    const alert = useAlert();
    const api = type == "client" ? useApiTenant() : useApiCentral();
    const tableRef = useRef();
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [isEditOpened, setIsEditOpened] = useState(false);
    const [isDeleteOpened, setIsDeleteOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allGroupUsers, setAllGroupUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [searchUsers, setSearchUsers] = useState("");
    const searchUsersRef = useRef(searchUsers);
    const [searchUsersBy, setSearchUsersBy] = useState("full_name");
    const [showUserFilters, setShowUserFilters] = useState(false);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        setAllGroupUsers([]);
        if (searchUsers.length === 0)
            return;
        setIsEditLoading(true);
        debounceSearchUsers(cleanText(searchUsersRef.current), searchUsersBy);
    }, [searchUsers]);
    const debounceSearchUsers = useCallback(debounce((search, searchUsersBy) => {
        getUsers(search, searchUsersBy);
    }, 700), []);
    const getUsers = (search, searchUsersBy) => {
        if (searchUsersRef.current !== "") {
            setIsEditLoading(true);
            api.GET_groupAttachableUsers(group.id, search, searchUsersBy)
                .then(response => {
                type === "client"
                    ? setAllGroupUsers(response.data.tenant_users)
                    : setAllGroupUsers(response.data.central_users);
                setIsEditLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
        else {
            setIsEditLoading(false);
        }
    };
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_groupUsers(group.id, query.pageSize, query.page + 1, query.orderBy ? query.id : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null)
                .then(response => {
                resolve({
                    data: type === "client"
                        ? response.data.tenant_users
                        : response.data.central_users,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
            })
                .catch(error => {
                console.error(error);
            })
                .finally(() => {
                setIsLoading(false);
            });
        });
    };
    const newGroupUser = () => {
        if (!user)
            return;
        setIsLoading(true);
        api.CREATE_userOnGroup(group.id, user.id)
            .then(() => {
            setIsLoading(false);
            refreshTableData();
            alert.show(t("groups.group-users.user-added"), "success");
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error.data);
            alert.show(t("groups.group-users.adding-error"), "error");
        })
            .finally(() => {
            setUser(null);
        });
    };
    const deleteGroupUser = () => {
        if (!user)
            return;
        setIsLoading(true);
        api.DELETE_userFromGroup(group.id, user.id)
            .then(() => {
            setIsLoading(false);
            refreshTableData();
            alert.show(t("groups.group-users.user-removed"), "warning");
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error.data);
            alert.show(t("groups.group-users.removing-error"), "error");
        })
            .finally(() => {
            setUser(null);
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginBottom: 2,
                } }, { children: _jsx(Table, { title: t("groups.group-users.group-users"), actions: [
                        {
                            icon: () => _jsx(RefreshIcon, { color: "action" }),
                            tooltip: t("groups.group-users.refresh"),
                            isFreeAction: true,
                            onClick: () => refreshTableData(),
                        },
                        {
                            icon: () => _jsx(NewIcon, { color: "action" }),
                            tooltip: t("groups.group-users.add-user-to-group"),
                            isFreeAction: true,
                            onClick: () => {
                                setUser(null);
                                setIsEditOpened(true);
                            },
                        },
                        data => ({
                            disabled: data.related_group.id !== (group === null || group === void 0 ? void 0 : group.id),
                            icon: () => _jsx(DeleteIcon, {}),
                            tooltip: data.related_group.id === (group === null || group === void 0 ? void 0 : group.id)
                                ? t("groups.group-users.remove-user")
                                : t("groups.group-users.user-in-parent"),
                            onClick: () => {
                                setUser(data);
                                setIsDeleteOpened(true);
                            },
                        }),
                    ], columns: isMobile
                        ? [
                            {
                                title: t("users.user") + " #",
                                field: "id",
                                defaultSort: "desc",
                            },
                        ]
                        : [
                            {
                                maxWidth: "80px",
                                title: "#",
                                field: "id",
                                type: "numeric",
                                defaultSort: "desc",
                            },
                            {
                                title: t("users.user-attributes.full-name"),
                                sorting: false,
                                render: data => {
                                    return (data.first_name +
                                        (data.last_name ? " " + data.last_name : ""));
                                },
                            },
                            {
                                title: t("users.user-attributes.email"),
                                field: "email",
                                sorting: false,
                            },
                        ], detailPanel: (data) => {
                        var _a, _b;
                        return (_jsx(Box, Object.assign({ sx: {
                                paddingX: 2,
                                paddingY: 3,
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.full-name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: data.first_name +
                                                    (data.last_name ? " " + data.last_name : "") }))] }))), isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.email") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: data.email }))] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("groups.group") })), _jsx(Box, Object.assign({ sx: {
                                                    paddingTop: 1,
                                                } }, { children: _jsx(Chip, { size: "small", variant: (group === null || group === void 0 ? void 0 : group.name) === ((_a = data.related_group) === null || _a === void 0 ? void 0 : _a.name)
                                                        ? "filled"
                                                        : "outlined", label: (_b = data.related_group) === null || _b === void 0 ? void 0 : _b.name }) }))] }))] })) })));
                    }, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }) })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isEditOpened, onClose: () => {
                    setIsEditOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("groups.group-users.add-user-to") + (group === null || group === void 0 ? void 0 : group.name) }), _jsx(DialogContent, { children: _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { options: allGroupUsers, getOptionLabel: user => (user.first_name ? user.first_name + " " : "") +
                                        (user.last_name ? user.last_name + " " : "") +
                                        (user.email ? "(" + user.email + ")" : ""), getOptionDisabled: option => {
                                        const user = allGroupUsers.find(user => {
                                            if (!user.attachable)
                                                return option.id === user.id;
                                        });
                                        return !!user;
                                    }, value: user, onChange: (event, value) => {
                                        setSearchUsers("");
                                        searchUsersRef.current = "";
                                        setUser(value);
                                    }, loading: isEditLoading, filterSelectedOptions: true, filterOptions: options => options, noOptionsText: t("filters.search-users-by") +
                                        " (" +
                                        (searchUsersBy === "full_name"
                                            ? t("users.user-attributes.full-name").toLocaleLowerCase()
                                            : searchUsersBy === "email"
                                                ? t("users.user-attributes.email").toLocaleLowerCase()
                                                : "ID") +
                                        ")", onInputChange: (event, value) => {
                                        setSearchUsers(value);
                                        searchUsersRef.current = value;
                                    }, renderOption: (props, user) => {
                                        var _a;
                                        return (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: (user.first_name
                                                                ? user.first_name + " "
                                                                : "") +
                                                                (user.last_name
                                                                    ? user.last_name + " "
                                                                    : "") })) })), _jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: user.email })) })), !user.attachable && (_jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: (_a = user.related_groups) === null || _a === void 0 ? void 0 : _a.map((related_group, index) => index === 0
                                                                ? related_group.name
                                                                : ", " + related_group.name) })) })))] }), user.id) })));
                                    }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("groups.group-users.select-user"), required: true, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(InputAdornment, Object.assign({ position: "end", style: {
                                                    position: "absolute",
                                                    right: "16px",
                                                } }, { children: [searchUsers.length > 0 && (_jsx(Fragment, { children: isEditLoading && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                                setSearchUsers("");
                                                                searchUsersRef.current = "";
                                                            } }, { children: _jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5 }) }))) })), _jsx(IconButton, Object.assign({ disabled: isEditLoading, edge: "end", onClick: () => setShowUserFilters(true) }, { children: _jsx(FilterIcon, { color: !isEditLoading
                                                                ? "action"
                                                                : "disabled" }) }))] }))) }) }))) }) })) })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsEditOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ disabled: !user, onClick: () => {
                                    newGroupUser();
                                    setIsEditOpened(false);
                                } }, { children: t("groups.group-users.add-user") }))] })] })), _jsxs(Dialog, Object.assign({ open: isDeleteOpened, onClose: () => {
                    setIsDeleteOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("groups.group-users.confirm-removal") }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: t("groups.group-users.remove-user-confirm") }) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsDeleteOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    deleteGroupUser();
                                    setIsDeleteOpened(false);
                                } }, { children: t("groups.group-users.remove") }))] })] })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showUserFilters, onClose: () => {
                    setShowUserFilters(false);
                } }, { children: [_jsx(DialogTitle, { children: t("filters.search-users-by") }), _jsx(DialogContent, { children: _jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                                {
                                    value: "full_name",
                                    label: t("users.user-attributes.full-name"),
                                },
                                {
                                    value: "email",
                                    label: t("users.user-attributes.email"),
                                },
                                {
                                    value: "id",
                                    label: "ID",
                                },
                            ], value: searchUsersBy ? searchUsersBy : "full_name", setValue: setSearchUsersBy }) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: () => {
                                setShowUserFilters(false);
                            } }, { children: t("close") })) })] }))] }));
}
