import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import { useTenancy } from "../../../services/tenancy";
const logoDark = require("../../../assets/" + process.env.APP_ID + "/logo-dark.svg");
export default function Logo() {
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const isDesktop = useMediaQuery("(min-width: 1200px)");
    return (_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Box, Object.assign({ sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: isDesktop ? "flex-start" : "center",
                flexGrow: isDesktop ? 0 : 1,
            } }, { children: tenancy.client ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/dashboard"), onClick: event => {
                    event.preventDefault();
                    navigate(tenancy.link("/dashboard"));
                }, sx: {
                    display: "flex",
                }, underline: "hover" }, { children: tenancy.client.logo_url || tenancy.client.logo_dark_url ? (_jsx("img", { src: tenancy.client.logo_dark_url
                        ? tenancy.client.logo_dark_url
                        : tenancy.client.logo_url, style: {
                        maxHeight: "30px",
                        maxWidth: "200px",
                        objectFit: "contain",
                    }, alt: "Logo" })) : (_jsx(Typography, Object.assign({ variant: "h6" }, { children: tenancy.client.name }))) }))) : (_jsx("img", { src: logoDark, height: "40", alt: "Logo" })) })) })));
}
