import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useAuth } from "../../../../services/auth";
export default function StepContent({ step, modal, isDemo }) {
    const auth = useAuth();
    if (modal === true) {
        return null;
    }
    return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: auth.isPublic ? 12 : 8, lg: isDemo ? 10 : auth.isPublic ? 12 : 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: { padding: 4 } }, { children: _jsx(Typography, { dangerouslySetInnerHTML: {
                        __html: step.content,
                    } }) })) }) })));
}
