import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Fab from "@mui/material/Fab";
import Skeleton from "@mui/material/Skeleton";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import NewIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Settings";
import { useTenancy } from "../../../services/tenancy";
const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: "fixed",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));
export default function Intro({ name, breadcrumbs, button, button2, button3, fab, loading, }) {
    var _a;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const classes = useStyles();
    const [isFabOpen] = useState(false);
    useEffect(() => {
        var _a;
        let oldTitle = document.title;
        let titleNew = name;
        if (oldTitle.startsWith("(")) {
            let notificationsCount = oldTitle.substring(oldTitle.indexOf("(") + 1, oldTitle.lastIndexOf(")"));
            titleNew = "(" + notificationsCount + ") " + name;
        }
        if (!titleNew.includes((_a = process.env.APP_NAME) !== null && _a !== void 0 ? _a : "Firefly360"))
            titleNew = titleNew + " - " + process.env.APP_NAME;
        document.title = titleNew;
    }, [name]);
    return (_jsxs(Box, Object.assign({ sx: {
            paddingBottom: 4,
        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: button3 && button2 && button
                            ? 6
                            : (button3 && button2) || (button && button2) || (button3 && button)
                                ? 8
                                : 9, xl: button3 && button2 && button
                            ? 6
                            : (button3 && button2) || (button && button2) || (button3 && button)
                                ? 8
                                : 9 }, { children: [_jsx(Typography, Object.assign({ component: "h1", variant: "h4" }, { children: loading ? _jsx(Skeleton, { animation: "wave", width: 300 }) : name })), _jsxs(Breadcrumbs, Object.assign({ maxItems: 8, separator: "\u203A" }, { children: [_jsx(Link, Object.assign({ component: RouterLink, to: "/", color: "inherit", underline: "hover" }, { children: process.env.APP_NAME })), !tenancy.isSubDomain &&
                                        tenancy.client &&
                                        !location.pathname.includes("/administration") && (_jsx(Link, Object.assign({ component: RouterLink, to: tenancy.link("/dashboard"), color: "inherit", underline: "hover" }, { children: (_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.name }))), breadcrumbs && loading ? (_jsx(Typography, Object.assign({ color: "textPrimary" }, { children: _jsx(Skeleton, { animation: "wave", width: 200 }) }))) : (breadcrumbs &&
                                        breadcrumbs.map((breadcrumb, key) => {
                                            return key + 1 === breadcrumbs.length ? (_jsx(Typography, Object.assign({ color: "textPrimary" }, { children: breadcrumb.name }), key)) : (_jsx(Link, Object.assign({ component: RouterLink, to: !location.pathname.includes("/administration")
                                                    ? tenancy.link(breadcrumb.link)
                                                    : breadcrumb.link, color: "inherit", underline: "hover" }, { children: breadcrumb.name }), key));
                                        }))] }))] })), button && (_jsxs(Fragment, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, md: button3 && button2 && button
                                        ? 6
                                        : (button3 && button2) ||
                                            (button && button2) ||
                                            (button3 && button)
                                            ? 4
                                            : 3, xl: button3 && button2 && button
                                        ? 6
                                        : (button3 && button2) ||
                                            (button && button2) ||
                                            (button3 && button)
                                            ? 4
                                            : 3 }, { children: _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                        } }, { children: [button2 && (_jsx(Box, Object.assign({ sx: {
                                                    marginRight: 2,
                                                } }, { children: button2.action ? (_jsx(Button, Object.assign({ onClick: button2.action, variant: button2.color !== "outlined"
                                                        ? "contained"
                                                        : "outlined", color: button2.color !== "outlined"
                                                        ? button2.color
                                                        : "primary" }, { children: button2.label }))) : button2.link &&
                                                    (button2.link.startsWith("/anketa") ||
                                                        button2.link.startsWith("/survey")) ? (_jsx(Button, Object.assign({ target: "_blank", href: tenancy.linkToSubDomainLink(button2.link), variant: button2.color !== "outlined"
                                                        ? "contained"
                                                        : "outlined", color: button2.color !== "outlined"
                                                        ? button2.color
                                                        : "primary" }, { children: button2.label }))) : button2.link ? (_jsx(Button, Object.assign({ to: !location.pathname.includes("/administration")
                                                        ? tenancy.link(button2.link)
                                                        : button2.link, component: RouterLink, variant: button2.color !== "outlined"
                                                        ? "contained"
                                                        : "outlined", color: button2.color !== "outlined"
                                                        ? button2.color
                                                        : "primary" }, { children: button2.label }))) : undefined }))), button3 && (_jsx(Box, Object.assign({ sx: {
                                                    marginRight: 2,
                                                } }, { children: button3.action ? (_jsx(Button, Object.assign({ onClick: button3.action, variant: button3.color !== "outlined"
                                                        ? "contained"
                                                        : "outlined", color: button3.color !== "outlined"
                                                        ? button3.color
                                                        : "primary" }, { children: button3.label }))) : button3.link ? (_jsx(Button, Object.assign({ to: !location.pathname.includes("/administration")
                                                        ? tenancy.link(button3.link)
                                                        : button3.link, component: RouterLink, variant: button3.color !== "outlined"
                                                        ? "contained"
                                                        : "outlined", color: button3.color !== "outlined"
                                                        ? button3.color
                                                        : "primary" }, { children: button3.label }))) : undefined }))), button.action ? (_jsx(Button, Object.assign({ onClick: button.action, variant: button.color !== "outlined"
                                                    ? "contained"
                                                    : "outlined", color: button.color !== "outlined"
                                                    ? button.color
                                                    : "primary" }, { children: button.label }))) : button.link ? (_jsx(Button, Object.assign({ to: !location.pathname.includes("/administration")
                                                    ? tenancy.link(button.link)
                                                    : button.link, component: RouterLink, variant: button.color !== "outlined"
                                                    ? "contained"
                                                    : "outlined", color: button.color !== "outlined"
                                                    ? button.color
                                                    : "primary" }, { children: button.label }))) : undefined] })) })) })), _jsx(Hidden, Object.assign({ mdUp: true }, { children: button.action ? (_jsx(Fab, Object.assign({ onClick: button.action, color: button.color !== "outlined" ? button.color : "primary", size: "medium", style: {
                                        position: "fixed",
                                        bottom: "24px",
                                        right: "24px",
                                        zIndex: 1000,
                                    } }, { children: button.type === "edit" ? _jsx(EditIcon, {}) : _jsx(NewIcon, {}) }))) : button.link ? (_jsx(Fab, Object.assign({ to: !location.pathname.includes("/administration")
                                        ? tenancy.link(button.link)
                                        : button.link, component: RouterLink, color: button.color !== "outlined" ? button.color : "primary", size: "medium", style: {
                                        position: "fixed",
                                        bottom: "24px",
                                        right: "24px",
                                        zIndex: 1000,
                                    } }, { children: button.type === "edit" ? _jsx(EditIcon, {}) : _jsx(NewIcon, {}) }))) : undefined }))] }))] })), fab && (_jsx(SpeedDial, Object.assign({ ariaLabel: t("quick-actions"), direction: "up", hidden: isFabOpen, className: classes.speedDial, icon: _jsx(SpeedDialIcon, {}), style: {
                    zIndex: 1000,
                } }, { children: fab.map((action, index) => (_jsx(SpeedDialAction, { title: action.name, icon: action.icon, onClick: () => navigate(tenancy.link(action.link)) }, index))) })))] })));
}
