import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../elements/blocks/intro";
import KnowledgeDetails from "../../elements/knowledge/knowledge-details";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useRoles } from "../../../services/roles";
export default function KnowledgeView() {
    const { id } = useParams();
    return _jsx(KnowledgeViewComponent, {}, id);
}
function KnowledgeViewComponent() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const roles = useRoles();
    const [isLoading, setIsLoading] = useState(true);
    const [knowledge, setKnowledge] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        api.GET_knowledge(id)
            .then(response => {
            setKnowledge(response.data.knowledge);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [id]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: knowledge ? knowledge.title : t("knowledge.knowledge") + " #" + id, breadcrumbs: [
                    {
                        name: t("knowledge.knowledge"),
                        link: "/knowledge",
                    },
                    {
                        name: knowledge ? knowledge.title : t("knowledge.knowledge") + " #" + id,
                        link: "/knowledge/" + id,
                    },
                ], button: (roles.isAdmin || roles.isManager) && knowledge
                    ? {
                        label: t("knowledge.edit-knowledge"),
                        link: "/knowledge/" + id + "/edit",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : knowledge ? (_jsx(KnowledgeDetails, { knowledge: knowledge })) : (_jsx(Nope, { title: t("errors.404"), caption: t("errors.knowledge.not-found") }))] }));
}
