import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenIcon from "@mui/icons-material/OpenInNew";
import Table from "../blocks/table";
import { useTenancy } from "../../../services/tenancy";
import { useApiCentral } from "../../../api/api-central";
import { useApiTenant } from "../../../api/api-tenant";
import { cleanText } from "../../../helpers/clean";
const useStyles = makeStyles(() => ({
    root: {
        "& .MuiAlert-action": {
            alignItems: "flex-start",
        },
        "& .MuiTypography-body2": {
            wordBreak: "break-all",
        },
    },
}));
export default function LogsTable({ type, title }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const tenancy = useTenancy();
    const api = type == "client" ? useApiTenant() : useApiCentral();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    if (type == "client") {
        useEffect(() => {
            if (!tenancy.client)
                return;
            refreshTableData();
        }, [tenancy.client]);
    }
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_logs(query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : "id", query.orderDirection ? query.orderDirection : "desc", query.search ? query.search : null)
                .then(response => {
                resolve({
                    data: response.data.activity_logs,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
            })
                .catch(error => {
                console.error(error);
            })
                .finally(() => {
                setIsLoading(false);
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    return (_jsx(Table, { title: title, actions: [
            {
                icon: () => _jsx(RefreshIcon, { color: "action" }),
                tooltip: t("logs.refresh-logs"),
                isFreeAction: true,
                onClick: () => refreshTableData(),
            },
        ], columns: isMobile
            ? [
                {
                    title: t("logs.log") + " #",
                    field: "id",
                    defaultSort: "desc",
                },
            ]
            : [
                {
                    maxWidth: "80px",
                    title: "#",
                    field: "id",
                    type: "numeric",
                    defaultSort: "desc",
                },
                {
                    title: t("logs.log-attributes.action"),
                    sorting: false,
                    render: log => {
                        return (_jsx(Typography, Object.assign({ variant: "subtitle2", color: log.description === "created"
                                ? "secondary"
                                : log.description === "updated"
                                    ? "primary"
                                    : "error" }, { children: log.description.toUpperCase() })));
                    },
                },
                {
                    title: t("logs.log-attributes.type"),
                    sorting: false,
                    render: log => {
                        return log.subject_type
                            .replace("App\\Models\\Tenant\\", "")
                            .replace("App\\Models\\Central\\", "")
                            .replace("App\\Models\\General\\", "")
                            .replace("Custom\\", "")
                            .replace("Data", "")
                            .replace("TenantUser", "User")
                            .replace("CentralUser", "User")
                            .replace("Tenant", "Client")
                            .replace("ClientGroup", "Group")
                            .replace("KnowledgeModule", "Knowledge")
                            .replace(/^(.*?)Data/, "CustomFieldInput")
                            .replace("Items\\ArrayTextItem", "CustomFieldItem")
                            .replace("Items\\ArrayImageTextItem", "CustomFieldItem")
                            .replace("Info\\RatingInfo", "SharedFormCustomField");
                    },
                },
                {
                    title: t("users.user"),
                    sorting: false,
                    render: log => {
                        return log.causer_id ? (log.central_user ? (_jsx(Link, Object.assign({ href: "/administration/users/" + log.central_user.id, onClick: event => {
                                event.preventDefault();
                                navigate("/administration/users/" + log.central_user.id);
                            }, underline: "hover" }, { children: log.central_user.first_name +
                                (log.central_user.last_name
                                    ? " " + log.central_user.last_name
                                    : "") }))) : log.tenant_user ? (_jsx(Link, Object.assign({ href: tenancy.link("/settings/users/" + log.tenant_user.id), onClick: event => {
                                event.preventDefault();
                                navigate(tenancy.link("/settings/users/" + log.tenant_user.id));
                            }, underline: "hover" }, { children: log.tenant_user.first_name +
                                (log.tenant_user.last_name
                                    ? " " + log.tenant_user.last_name
                                    : "") }))) : (log.causer_id)) : (t("logs.log-attributes.system"));
                    },
                },
                {
                    title: t("logs.log-attributes.time"),
                    width: "200px",
                    render: log => {
                        return new Date(Date.parse(log.updated_at)).toLocaleString();
                    },
                },
            ], detailPanel: (log) => {
            var _a;
            return (_jsx(Box, Object.assign({ className: classes.root, sx: {
                    paddingX: isMobile ? 2 : 3,
                    paddingY: 3,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("logs.log-attributes.action") })), _jsx(Typography, Object.assign({ variant: "body2", color: log.description === "created"
                                        ? "secondary"
                                        : log.description === "updated"
                                            ? "primary"
                                            : "error" }, { children: log.description.toUpperCase() }))] }))), isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("logs.log-attributes.type") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: log.subject_type
                                        .replace("App\\Models\\Tenant\\", "")
                                        .replace("App\\Models\\Central\\", "")
                                        .replace("App\\Models\\General\\", "")
                                        .replace("Custom\\", "")
                                        .replace("Data", "")
                                        .replace("TenantUser", "User")
                                        .replace("CentralUser", "User")
                                        .replace("Tenant", "Client")
                                        .replace("ClientGroup", "Group")
                                        .replace("KnowledgeModule", "Knowledge")
                                        .replace(/^(.*?)Data/, "CustomFieldInput")
                                        .replace("Items\\ArrayTextItem", "CustomFieldItem")
                                        .replace("Items\\ArrayImageTextItem", "CustomFieldItem")
                                        .replace("Info\\RatingInfo", "SharedFormCustomField") }))] }))), isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user") })), log.causer_id ? (log.central_user ? (_jsx(Link, Object.assign({ href: "/administration/users/" + log.central_user.id, onClick: event => {
                                        var _a;
                                        event.preventDefault();
                                        navigate("/administration/users/" +
                                            ((_a = log.central_user) === null || _a === void 0 ? void 0 : _a.id));
                                    }, underline: "hover" }, { children: log.central_user.first_name +
                                        (log.central_user.last_name
                                            ? " " + log.central_user.last_name
                                            : "") }))) : log.tenant_user ? (_jsx(Link, Object.assign({ href: tenancy.link("/settings/users/" + log.tenant_user.id), onClick: event => {
                                        var _a;
                                        event.preventDefault();
                                        navigate(tenancy.link("/settings/users/" + ((_a = log.tenant_user) === null || _a === void 0 ? void 0 : _a.id)));
                                    }, underline: "hover" }, { children: log.tenant_user.first_name +
                                        (log.tenant_user.last_name
                                            ? " " + log.tenant_user.last_name
                                            : "") }))) : (log.causer_id)) : (t("logs.log-attributes.system"))] }))), log.properties.old && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Alert, Object.assign({ severity: "warning", variant: "outlined" }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("logs.log-attributes.old-data") })), Object.entries(log.properties.old).map(old => {
                                        if (old[0] === "contact_id" ||
                                            old[0] === "tenancy_db_name" ||
                                            old[0] === "global_id" ||
                                            old[0] === "created_by" ||
                                            old[0] === "updated_by" ||
                                            old[0] === "ticketActivities" ||
                                            old[0] === "ticketDetail" ||
                                            old[0] === "priority")
                                            return;
                                        if (log.properties.attributes[old[0]] === old[1] &&
                                            old[0] !== "ticket_id" &&
                                            old[0] != "campaign_id")
                                            return;
                                        return (_jsxs(Box, Object.assign({ sx: {
                                                paddingTop: 1,
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: cleanText(old[0]) })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: old[1] === true
                                                        ? "true"
                                                        : old[1] === false
                                                            ? "false"
                                                            : /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(old[1])
                                                                ? new Date(Date.parse(old[1])).toLocaleString()
                                                                : typeof old[1] === "string"
                                                                    ? cleanText(old[1]).replace("App\\Models\\Tenant\\", "")
                                                                    : old[1] !== null
                                                                        ? old[1]
                                                                        : t("logs.description.no-data") }))] }), old[0]));
                                    })] })) }))), log.properties.attributes && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: log.properties.old ? 6 : 12 }, { children: _jsxs(Alert, Object.assign({ severity: log.description === "updated"
                                    ? "info"
                                    : log.description === "created"
                                        ? "success"
                                        : "error", variant: "outlined", action: log.description !== "deleted" &&
                                    (log.subject_type === "App\\Models\\Tenant\\Contact" ||
                                        log.subject_type ===
                                            "App\\Models\\Tenant\\TenantUser") ? (_jsx(IconButton, Object.assign({ color: "inherit", onClick: () => {
                                        if (log.subject_type ===
                                            "App\\Models\\Tenant\\Contact")
                                            navigate(tenancy.link("/contacts/" + log.subject_id));
                                        if (log.subject_type ===
                                            "App\\Models\\Tenant\\TenantUser")
                                            navigate(tenancy.link("/settings/users/" +
                                                log.subject_id));
                                    }, size: "large" }, { children: _jsx(OpenIcon, {}) }))) : (_jsx(Fragment, {})) }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: log.description === "updated"
                                            ? t("logs.description.new-data")
                                            : log.description === "created"
                                                ? t("logs.description.added-data")
                                                : t("logs.description.removed-data") })), Object.entries(log.properties.attributes).map(attribute => {
                                        if (attribute[0] === "contact_id" ||
                                            attribute[0] === "tenancy_db_name" ||
                                            attribute[0] === "global_id" ||
                                            attribute[0] === "created_by" ||
                                            attribute[0] === "updated_by" ||
                                            attribute[0] === "ticketActivities" ||
                                            attribute[0] === "ticketDetail" ||
                                            attribute[0] === "priority")
                                            return;
                                        if (log.properties.old) {
                                            if (log.properties.old[attribute[0]] ===
                                                attribute[1] &&
                                                attribute[0] !== "ticket_id" &&
                                                attribute[0] != "campaign_id")
                                                return;
                                        }
                                        else if (attribute[1] === null)
                                            return;
                                        return (_jsxs(Box, Object.assign({ sx: {
                                                paddingTop: 1,
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: cleanText(attribute[0]) })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: attribute[1] === true
                                                        ? "true"
                                                        : attribute[1] === false
                                                            ? "false"
                                                            : /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(attribute[1])
                                                                ? new Date(Date.parse(attribute[1])).toLocaleString()
                                                                : typeof attribute[1] === "string"
                                                                    ? cleanText(attribute[1]).replace("App\\Models\\Tenant\\Custom\\", "")
                                                                    : attribute[1] !== null
                                                                        ? attribute[1]
                                                                        : t("logs.description.no-data") }))] }), attribute[0]));
                                    })] })) }))), log.subject_type === "App\\Models\\General\\Media" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Alert, Object.assign({ severity: "info", variant: "outlined" }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("logs.related-data") })), _jsxs(Box, Object.assign({ sx: {
                                            paddingTop: 1,
                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("logs.file") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: (_a = log.subject_current_object) === null || _a === void 0 ? void 0 : _a.file_name }))] }))] })) })))] })) })));
        }, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }));
}
