import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchContactCard from "../search/search-contact-card";
import { useApiTenant } from "../../../api/api-tenant";
import { useAlert } from "../../../services/alert";
import { useAuth } from "../../../services/auth";
import { usePrefs } from "../../../services/prefs";
import { copy } from "../../../helpers/copy";
export default function InputEmails({ status, label, emails, setEmails, setContact, phones, disabled, checkContacts, error, errors, isError, setIsError, }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const alert = useAlert();
    const auth = useAuth();
    const prefs = usePrefs();
    const [email, setEmail] = useState("");
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
    const [isContactModalOpened, setIsContactModalOpened] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailDuplicated, setEmailDuplicated] = useState(false);
    const [foundContacts, setFoundContacts] = useState([]);
    const [search, setSearch] = useState("");
    const [filteredContacts, setFilteredContacts] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const useStyles = makeStyles(() => ({
        root: {
            "&:hover": {
                color: prefs.theme === "light" ? "#00000066" : "#ffffff66",
            },
        },
    }));
    const classes = useStyles();
    const addEmail = () => {
        setIsSaving(true);
        if (emails.length === 0 && phones.length === 0 && status !== "edit" && checkContacts) {
            api.GET_contacts(100, 1, "id", "asc", email, "emails")
                .then(response => {
                if (response.data.contacts.length === 0) {
                    setEmails(emails => [
                        ...emails,
                        {
                            email: email.toLowerCase(),
                        },
                    ]);
                    setIsSaving(false);
                    setIsEmailModalOpened(false);
                }
                else {
                    setFoundContacts(response.data.contacts);
                    setIsSaving(false);
                    setIsEmailModalOpened(false);
                    setIsContactModalOpened(true);
                }
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
        else {
            setEmails(emails => [
                ...emails,
                {
                    email: email.toLowerCase(),
                },
            ]);
            setIsSaving(false);
            setIsEmailModalOpened(false);
        }
        if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
            setIsError(false);
    };
    const validateEmail = (newEmail) => {
        if (/\S+@\S+\.\S+/.test(newEmail))
            setEmailError(false);
        else
            setEmailError(true);
        if (emails.find(email => email.email === newEmail)) {
            setEmailDuplicated(true);
            setEmailError(true);
        }
        else
            setEmailDuplicated(false);
    };
    useEffect(() => {
        let newContacts = [];
        let splitSearch = [];
        let flag = false;
        if (search && search !== "") {
            setIsLoading(true);
            splitSearch = search.trimEnd().split(" ");
            foundContacts.map(contact => {
                flag = true;
                splitSearch.map(a => {
                    if (!((contact.first_name &&
                        contact.first_name
                            .toLocaleLowerCase()
                            .includes(a.toLocaleLowerCase())) ||
                        (contact.last_name &&
                            contact.last_name
                                .toLocaleLowerCase()
                                .includes(a.toLocaleLowerCase())) ||
                        (contact.company &&
                            contact.company.toLocaleLowerCase().includes(a.toLocaleLowerCase())))) {
                        flag = false;
                    }
                });
                if (flag && !newContacts.find(a => a === contact)) {
                    newContacts.push(contact);
                }
            });
            setIsLoading(false);
            setFilteredContacts(newContacts);
        }
        else {
            setFilteredContacts(null);
        }
    }, [search]);
    return (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                } }, { children: [_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "button", color: isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? "error" : "textPrimary" }, { children: label })) }), _jsx(Box, { children: _jsx(Button, Object.assign({ type: "button", size: "small", variant: "contained", color: "secondary", disabled: disabled ? disabled : false, onClick: () => {
                                setEmail("");
                                setEmailError(false);
                                setEmailDuplicated(false);
                                setIsModalDelete(false);
                                setIsEmailModalOpened(true);
                            } }, { children: t("inputs.input-emails.add-email") })) })] })), emails.length > 0 ? (_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    flexWrap: "wrap",
                } }, { children: emails.map((email, index) => {
                    return (_jsxs(Box, Object.assign({ sx: {
                            display: "block",
                            width: "100%",
                            paddingTop: 2,
                        } }, { children: [_jsx(Chip, { variant: "outlined", label: email.email, icon: !auth.isPublic ? (_jsx(CancelIcon, { classes: classes, style: {
                                        color: prefs.theme === "light"
                                            ? "#00000042"
                                            : "#ffffff42",
                                    }, onClick: () => {
                                        setEmail(email.email);
                                        setIsModalDelete(true);
                                        setIsEmailModalOpened(true);
                                    }, fontSize: "small" })) : undefined, deleteIcon: !auth.isPublic ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                    // @ts-ignore
                                    title: t("copy-email") }, { children: _jsx(ContentCopyIcon, { fontSize: "small" }) }))) : undefined, onDelete: () => {
                                    if (!auth.isPublic) {
                                        copy(email.email);
                                        alert.show(t("email-copied"), "info");
                                    }
                                    else {
                                        let newEmails = emails.filter(emailLoop => emailLoop.email !== email.email);
                                        setEmails(newEmails);
                                    }
                                } }), isError &&
                                errors &&
                                error &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("emails." + index + ".email")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error + index + ".email"] })))] }), index));
                }) }))) : (_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    paddingTop: 2,
                    paddingLeft: 1,
                    minHeight: "48px",
                } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? "error" : "textSecondary" }, { children: t("inputs.input-emails.no-email-address") })) }))), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isEmailModalOpened, onClose: () => {
                    setIsEmailModalOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: isModalDelete
                            ? t("inputs.input-emails.remove-contact-email")
                            : t("inputs.input-emails.add-email-to-contact") }), _jsx(DialogContent, { children: _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ disabled: isModalDelete, error: emailError, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("inputs.input-emails.email") + " *" }), _jsx(OutlinedInput, { required: true, autoFocus: true, label: t("inputs.input-emails.email") + " *", value: email, type: "email", onChange: event => {
                                                let newEmail = event.target.value;
                                                newEmail = newEmail.toLowerCase();
                                                newEmail = newEmail.replace(/č/g, "c");
                                                newEmail = newEmail.replace(/ć/g, "c");
                                                newEmail = newEmail.replace(/š/g, "s");
                                                newEmail = newEmail.replace(/ž/g, "z");
                                                newEmail = newEmail.replace(/đ/g, "d");
                                                validateEmail(newEmail);
                                                setEmail(newEmail);
                                            }, onKeyPress: event => {
                                                if (event.key === "Enter" &&
                                                    email &&
                                                    !emailError &&
                                                    !emailDuplicated) {
                                                    setIsEmailModalOpened(false);
                                                    addEmail();
                                                }
                                            } }), _jsx(FormHelperText, Object.assign({ error: emailError }, { children: emailDuplicated
                                                ? t("inputs.input-emails.email-already-added")
                                                : t("inputs.input-emails.email-invalid-format") }))] })) })) })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsEmailModalOpened(false);
                                } }, { children: t("cancel") })), isModalDelete ? (_jsx(Button, Object.assign({ style: { color: "#f44336" }, onClick: () => {
                                    let newEmails = emails.filter(emailLoop => emailLoop.email !== email);
                                    setEmails(newEmails);
                                    setIsEmailModalOpened(false);
                                } }, { children: t("inputs.input-emails.remove-email") }))) : (_jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", size: "small", loading: isSaving, disabled: !email || emailError, onClick: () => addEmail(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("inputs.input-emails.add-email") })))] })] })), _jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isContactModalOpened && foundContacts.length > 0 }, { children: _jsxs(DialogContent, { children: [_jsxs(Box, Object.assign({ sx: {
                                paddingBottom: 2,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("inputs.input-emails.contacts-with-this-email-found") })), _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("inputs.input-emails.use-existing-contact-or-create-new") }))] })), _jsx(Box, Object.assign({ sx: {
                                paddingTop: 1,
                                paddingBottom: 2,
                            } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(OutlinedInput, { autoFocus: true, placeholder: t("inputs.search-contacts-(by") +
                                        t("contacts.contact-attributes.full-name-company").toLowerCase() +
                                        ")", value: search, onChange: event => setSearch(event.target.value), startAdornment: _jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchIcon, {}) })), endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: search !== "" && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => setSearch(""), size: "large" }, { children: isLoading ? (_jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5, style: {
                                                    marginRight: "4px",
                                                } })) : (_jsx(ClearIcon, {})) }))) })) }) })) })), filteredContacts !== null ? (filteredContacts.length > 0 ? (filteredContacts.map(contact => (_jsx(Box, Object.assign({ sx: {
                                paddingY: 1,
                            } }, { children: _jsx(SearchContactCard, { type: "use", contact: contact, setContact: contact => {
                                    setContact(contact);
                                    setIsContactModalOpened(false);
                                } }) }), contact.id)))) : (_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("inputs.no-contacts") })) }))) : (foundContacts.map(foundContact => (_jsx(Box, Object.assign({ sx: {
                                paddingY: 1,
                            } }, { children: _jsx(SearchContactCard, { type: "use", contact: foundContact, setContact: contact => {
                                    setContact(contact);
                                    setIsContactModalOpened(false);
                                } }) }), foundContact.id)))), _jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingY: 1,
                            } }, { children: _jsx(Button, Object.assign({ onClick: () => {
                                    setEmails(emails => [
                                        ...emails,
                                        {
                                            email: email,
                                        },
                                    ]);
                                    setIsContactModalOpened(false);
                                } }, { children: t("inputs.input-emails.create-new-contact") })) }))] }) }))] }));
}
