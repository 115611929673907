import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material//DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "../../../services/alert";
import { useApiCentral } from "../../../api/api-central";
export default function ClientDelete({ client }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const api = useApiCentral();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const deleteClient = () => {
        setIsDeleting(true);
        api.DELETE_client(client.id)
            .then(() => {
            setIsDeleting(false);
            alert.show(t("clients.client-delete.deleted"), "warning");
            navigate("/administration/clients");
        })
            .catch(error => {
            setIsDeleting(false);
            console.error(error.response);
            alert.show(t("clients.client-delete.deleting-error-occured"), "error");
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 4,
                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isDeleting, onClick: () => {
                        setIsOpened(true);
                    }, startIcon: _jsx(DeleteIcon, {}), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("clients.client-delete.delete-client") })) })), _jsxs(Dialog, Object.assign({ open: isOpened, onClose: () => {
                    setIsOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: t("clients.client-delete.delete-confirm") }), _jsx(DialogContentText, { children: _jsx("strong", { children: t("delete-component.confirm-caption") }) })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    deleteClient();
                                } }, { children: t("clients.client-delete.permanently-delete") }))] })] }))] }));
}
