import axios from "axios";
import { usePrefs } from "../services/prefs";
export const useApiPublic = () => {
    const prefs = usePrefs();
    const api = axios.create({
        baseURL: process.env.API_URL + "/api",
        withCredentials: true,
    });
    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = error => axios.isCancel(error);
    const GET_clientByDomain = (domain) => {
        return api.get("/public/tenant", {
            params: {
                domain,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_resetPassword = (email) => {
        return api.get("/public/reset-password", {
            params: {
                email,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const POST_resetPassword = (email, token, password) => {
        return api.post("/public/reset-password", {
            email,
            token,
            password,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_campaignBySlug = (tenant_id, slug, contact_uuid) => {
        return api.get("/tenant/" + tenant_id + "/public/campaigns/" + slug, {
            params: {
                contact_uuid,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_campaignStepsBySlug = (tenant_id, slug) => {
        return api.get("/tenant/" + tenant_id + "/public/campaigns/" + slug + "/steps", {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_surveyTicket = (client_id, campaign_slug, contact_uuid) => {
        return api.post("/tenant/" + client_id + "/public/campaigns/" + campaign_slug + "/tickets", {
            contact_uuid,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_surveyTicketInformation = (campaign_slug, ticket_information, chosen_steps, groups, groups_campaign_step_id, client_id, contact_uuid, ticket_id) => {
        return api.post("/tenant/" + client_id + "/public/campaigns/" + campaign_slug + "/tickets/information", {
            ticket_information,
            chosen_steps,
            groups,
            groups_campaign_step_id,
            contact_uuid,
            ticket_id,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_surveyWorkedTime = (client_id, ticket_id, seconds_active, campaign_slug) => {
        return api.post("/tenant/" +
            client_id +
            "/public/campaigns/" +
            campaign_slug +
            "/tickets/" +
            ticket_id +
            "/activity", {
            seconds_active,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_contact = (first_name, last_name, company, type, address, oib, phones, emails, custom_fields, is_anonymous, campaign_id, campaign_slug, client_id) => {
        // @ts-ignore
        if (address === null || address === "")
            address = [];
        return api.post("/tenant/" + client_id + "/public/campaigns/" + campaign_slug + "/contacts", {
            first_name,
            last_name,
            company,
            type,
            address,
            oib,
            phones,
            emails,
            custom_fields,
            is_anonymous,
            campaign_id,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const UPDATE_contact = (id, first_name, last_name, company, type, address, oib, phones, emails, custom_fields, is_anonymous, campaign_id, campaign_slug, client_id, contact_uuid) => {
        // @ts-ignore
        if (address === null || address === "")
            address = [];
        return api.post("/tenant/" +
            client_id +
            "/public/campaigns/" +
            campaign_slug +
            "/contacts/" +
            contact_uuid, {
            first_name,
            last_name,
            company,
            type,
            address,
            oib,
            phones,
            emails,
            custom_fields,
            is_anonymous,
            campaign_id,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_phoneCountries = (page_size, page, order_by, order_direction, search, client_id) => {
        return api.get("/tenant/" + client_id + "/public/phone-countries", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_phoneAreas = (page_size, page, order_by, order_direction, search, client_id) => {
        return api.get("/tenant/" + client_id + "/public/phone-areas", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_publicContactCustomFields = (client_id, slug) => {
        return api.get("/tenant/" + client_id + "/public/campaigns/" + slug + "/contact-custom-fields", {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_ticketActivitiesByUser = (tenant_id, ticket_uuid, user_uuid) => {
        return api.get("/tenant/" + tenant_id + "/public/ticket-activity/" + ticket_uuid + "/" + user_uuid, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_ticketActivityByUser = (tenant_id, ticket_uuid, user_uuid, notes, custom_fields) => {
        return api.post("/tenant/" + tenant_id + "/public/ticket-activity/" + ticket_uuid + "/" + user_uuid, {
            notes,
            manually_entered: true,
            custom_fields,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    return {
        cancel,
        isCancel,
        GET_clientByDomain,
        GET_resetPassword,
        POST_resetPassword,
        GET_campaignBySlug,
        GET_campaignStepsBySlug,
        CREATE_surveyTicket,
        CREATE_surveyTicketInformation,
        CREATE_surveyWorkedTime,
        CREATE_contact,
        UPDATE_contact,
        GET_phoneCountries,
        GET_phoneAreas,
        GET_publicContactCustomFields,
        GET_ticketActivitiesByUser,
        CREATE_ticketActivityByUser,
    };
};
