export const defaultColumnDisplayOptions = [
    {
        id: 1,
        order: 1,
        base_class: "App\\Models\\Tenant\\Ticket",
        relation: null,
        relation_class: null,
        column: "id",
        campaign_id: null,
        custom_field_id: null,
        campaign_step_id: null,
        created_at: "2023-02-22T15:23:08.000000Z",
        campaign_step: null,
        custom_field: null,
        uid: "d050f44636d1d995e51ac8446fc6c13933123c3f",
        relevance: "",
        role: "agent",
    },
    {
        id: 2,
        order: 2,
        base_class: "App\\Models\\Tenant\\Ticket",
        relation: "contact",
        relation_class: "App\\Models\\Tenant\\Contact",
        column: null,
        campaign_id: null,
        custom_field_id: null,
        campaign_step_id: null,
        created_at: "2023-02-22T15:23:08.000000Z",
        relevance: "",
        campaign_step: null,
        custom_field: null,
        uid: "0446baead7bf46bb6f954413c8defd04310a544e",
        role: "agent",
    },
    {
        id: 3,
        order: 3,
        base_class: "App\\Models\\Tenant\\Ticket",
        relation: "ticketDetail.status",
        relation_class: "App\\Models\\Tenant\\TicketDetailStatus",
        column: null,
        campaign_id: null,
        custom_field_id: null,
        campaign_step_id: null,
        created_at: "2023-02-22T15:23:08.000000Z",
        relevance: "",
        campaign_step: null,
        custom_field: null,
        uid: "b553822e078166160684fbfe854636d3c3458872",
        role: "agent",
    },
    {
        id: 4,
        order: 4,
        base_class: "App\\Models\\Tenant\\Ticket",
        relation: "ticketDetail.assignees",
        relation_class: "App\\Models\\Tenant\\TenantUser",
        column: null,
        campaign_id: null,
        custom_field_id: null,
        campaign_step_id: null,
        created_at: "2023-02-22T15:23:08.000000Z",
        relevance: "",
        campaign_step: null,
        custom_field: null,
        uid: "a10886fb68e46503dd3ec4429a62b8ccd21f9253",
        role: "agent",
    },
    {
        id: 5,
        order: 5,
        base_class: "App\\Models\\Tenant\\Ticket",
        relation: "ticketDetail.priority",
        relation_class: "App\\Models\\Tenant\\TicketDetailPriority",
        column: null,
        campaign_id: null,
        custom_field_id: null,
        campaign_step_id: null,
        created_at: "2023-02-22T15:23:08.000000Z",
        relevance: "",
        campaign_step: null,
        custom_field: null,
        uid: "1e65bb956eafa037eb20e4c86a25064141b37e87",
        role: "agent",
    },
    {
        id: 6,
        order: 6,
        base_class: "App\\Models\\Tenant\\Ticket",
        relation: "groups",
        relation_class: "App\\Models\\Tenant\\TenantGroup",
        column: null,
        campaign_id: null,
        custom_field_id: null,
        campaign_step_id: null,
        created_at: "2023-02-22T15:23:08.000000Z",
        relevance: "",
        campaign_step: null,
        custom_field: null,
        uid: "642f26b06805ada2569918ff494c16c9be595a8a",
        role: "agent",
    },
    {
        id: 7,
        order: 7,
        base_class: "App\\Models\\Tenant\\Ticket",
        relation: null,
        relation_class: null,
        column: "created_at",
        campaign_id: null,
        custom_field_id: null,
        campaign_step_id: null,
        created_at: "2023-02-22T15:23:08.000000Z",
        relevance: "",
        campaign_step: null,
        custom_field: null,
        uid: "a477aa429b464d93d56e5f195c1d2e277f747e0c",
        role: "agent",
    },
];
