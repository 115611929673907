const shared = {
    background: {
        default: {
            dark: "rgb(250, 250, 250)",
            light: "rgb(48, 48, 48)",
        },
        paper: {
            dark: "rgb(255, 255, 255)",
            light: "rgb(66, 66, 66)",
        },
    },
};
export const colors = process.env.APP_ID === "mcd"
    ? {
        primary: {
            dark: "#fbbc0e",
            light: "#264F36",
        },
        secondary: {
            dark: "#fbbc0e",
            light: "#fbbc0e",
        },
        background: shared.background,
    }
    : {
        primary: {
            dark: "#254092",
            light: "#90caf9",
        },
        secondary: {
            dark: "#4caf50",
            light: "#4caf50",
        },
        background: shared.background,
    };
