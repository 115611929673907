// " firefly  360 " -> "firefly 360"
export const cleanText = text => {
    if (!text)
        return "";
    return text
        .replaceAll("_", " ") // "firefly_360" -> firefly 360"
        .replace(/ +(?= )/g, "") // No multiple whitespaces
        .trim(); // No whitespace on beginning and end
};
// "firefly -360" -> "firefly-360"
export const cleanDomain = text => {
    if (!text)
        return;
    return text.replace(/[^a-zA-Z-]/gi, ""); // Only letters and dash
};
export const cleanSlug = text => {
    if (!text)
        return;
    return text.replace(/[^a-zA-Z0-9-]/gi, ""); // Only letters, numbers and dash
};
// 123tomi- -> 123
export const cleanOIB = oib => {
    if (!oib)
        return;
    return oib.replace(/[^0-9]/gi, ""); // Only digits
};
// "firefly_360" -> "Firefly 360"
export const prettifyText = text => {
    if (!text)
        return;
    text = text.charAt(0).toUpperCase() + text.slice(1);
    text = text.replaceAll("_", " ");
    return text;
};
export const prettifyDisplayOptionText = text => {
    if (!text)
        return;
    text = text.charAt(0).toUpperCase() + text.slice(1);
    if (text.includes("."))
        text = text.split(".")[1];
    if (text.includes("_"))
        text = text.replaceAll("_", " ");
    return text;
};
export const prettifyCampaignDisplayOption = (field, t, searchColumn, isCampaignEdit) => {
    var _a;
    let displayName = "";
    if (field.relation && field.relation.includes("contact")) {
        if (searchColumn) {
            displayName = "[" + t("contact") + "] ";
        }
        if (field.column == null) {
            if (field.campaign_step) {
                displayName += field.campaign_step.name;
            }
            else if (field.custom_field) {
                displayName += (_a = field.custom_field) === null || _a === void 0 ? void 0 : _a.field_name;
            }
            else if (field.relation.includes("phones")) {
                displayName += t("contacts.contact-attributes.phones");
            }
            else if (field.relation.includes("address")) {
                displayName += t("contacts.contact-attributes.address");
            }
            else if (field.relation.includes("contactEmails")) {
                displayName += t("contacts.contact-attributes.emails");
            }
            else {
                displayName += t("tickets.ticket-steps.contact");
            }
        }
        else {
            if (field.column == "company") {
                displayName += t("contacts.contact-attributes.company");
            }
            else if (field.column == "first_name") {
                displayName += t("contacts.contact-attributes.first-name");
            }
            else if (field.column == "last_name") {
                displayName += t("contacts.contact-attributes.last-name");
            }
            else if (field.column == "type") {
                displayName += t("contacts.contact-attributes.type.type");
            }
            else if (field.column == "oib") {
                displayName += t("contacts.contact-attributes.oib");
            }
            else {
                displayName += prettifyDisplayOptionText(field.column);
            }
        }
    }
    else {
        if (searchColumn) {
            displayName = "[" + t("tickets.ticket") + "] ";
        }
        if (!field.relation) {
            if (field.column) {
                if (field.column.includes("created_at")) {
                    displayName += t("created-at");
                }
                else if (field.column.includes("updated_at")) {
                    displayName += t("updated-at");
                }
                else {
                    if (searchColumn) {
                        displayName += t("users.user-filters.id");
                    }
                    else {
                        displayName += process.env.APP_ID === "mcd" ? t("tickets.code") : "#";
                    }
                }
            }
        }
        else if (field.relation == "groups") {
            displayName += t("tickets.ticket-attributes.groups");
        }
        else if (field.relation && !field.column) {
            if (field.relation.includes(".")) {
                if (field.relation.includes("status")) {
                    displayName += t("tickets.ticket-attributes.status");
                }
                else if (field.relation.includes("priority")) {
                    displayName += t("tickets.ticket-attributes.priority");
                }
                else if (field.relation.includes("assignee") ||
                    field.relation.includes("assignees")) {
                    displayName += t("tickets.ticket-attributes.assignee");
                }
                else if (field.relation.includes("audience")) {
                    displayName += t("tickets.ticket-attributes.audience");
                }
            }
            else if (field.relation) {
                if (field.relation.includes("createdBy")) {
                    displayName += t("tickets.ticket-attributes.created-by");
                }
                else if (field.relation.includes("updatedBy")) {
                    displayName += t("tickets.ticket-attributes.updated-by");
                }
                else if (field.relation == "ticketInformation") {
                    if (field.campaign_step) {
                        displayName += field.campaign_step.name;
                    }
                    else if (field.custom_field) {
                        displayName += field.custom_field.field_name;
                    }
                }
            }
        }
        else if (field.relation && field.column) {
            if (field.column.includes("start_date")) {
                displayName += t("tickets.ticket-attributes.start-date");
            }
            else if (field.column.includes("due_date")) {
                displayName += t("tickets.ticket-attributes.due-date");
            }
            else if (field.column.includes("estimate")) {
                displayName += t("tickets.ticket-attributes.estimated-time");
            }
            else if (field.column.includes("progress")) {
                displayName += t("tickets.ticket-attributes.progress");
            }
            else if (field.column.includes("notes")) {
                displayName += t("tickets.ticket-attributes.notes");
            }
            else if (field.column.includes("forwarded_call")) {
                displayName += t("tickets.ticket-attributes.forwarded-call");
            }
        }
    }
    if (isCampaignEdit && field.campaign) {
        displayName += " [" + field.campaign.name + "] ";
    }
    return displayName;
};
export const prettifyContactTitle = (contact, t, field) => {
    let title = "";
    let type;
    if (field && field.column !== null) {
        if (field.column === "company" && contact.company.length > 0) {
            title = contact.company;
            type = "company";
        }
        else if (field.column === "first_name" && contact.first_name) {
            title = contact.first_name;
            type = "name";
        }
        else if (field.column === "last_name" && contact.last_name) {
            title = contact.last_name;
            type = "name";
        }
        else if (contact.is_anonymous === true) {
            title = t("contacts.anonymous");
            type = "anonymous";
        }
    }
    else if (contact.type) {
        if (contact.type === "business") {
            if (contact.company) {
                title = contact.company;
                type = "company";
            }
            else if (contact.is_anonymous) {
                title = t("contacts.anonymous");
                type = "anonymous";
            }
            else if (contact.first_name || contact.last_name) {
                title =
                    (contact.first_name ? contact.first_name : "") +
                        (contact.last_name ? " " + contact.last_name : "");
                type = "name";
            }
        }
        else {
            if (contact.is_anonymous === true) {
                title = t("contacts.anonymous");
                type = "anonymous";
            }
            else if (contact.first_name || contact.last_name) {
                title =
                    (contact.first_name ? contact.first_name : "") +
                        (contact.last_name ? " " + contact.last_name : "");
                type = "name";
            }
            else if (contact.company) {
                title = contact.company;
                type = "company";
            }
        }
    }
    else {
        if (contact.company) {
            title = contact.company;
            type = "company";
        }
        else if (contact.is_anonymous === true) {
            title = t("contacts.anonymous");
            type = "anonymous";
        }
        else if (contact.first_name || contact.last_name) {
            title =
                (contact.first_name ? contact.first_name : "") +
                    (contact.last_name ? " " + contact.last_name : "");
            type = "name";
        }
    }
    return {
        title: title.toUpperCase(),
        type: type,
    };
};
export const isJsonString = (text) => {
    try {
        JSON.parse(text);
    }
    catch (e) {
        return false;
    }
    return true;
};
