import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReorderIcon from "@mui/icons-material/SwapVert";
import AddIcon from "@mui/icons-material/Add";
import Table from "../blocks/table";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
import { prettifyText } from "../../../helpers/clean";
export default function CustomFieldsTable({ type, shared, setAddNewField, setCustomField, setEditCustomField, step, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [isReorderLoading, setIsReorderLoading] = useState(true);
    const [isReorderOpened, setIsReorderOpened] = useState(false);
    const [reorderedCustomFields, setReorderedCustomFields] = useState([]);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_customFields(type, query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null, step === null || step === void 0 ? void 0 : step.id, shared ? 1 : undefined)
                .then(response => {
                resolve({
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                    data: response.data.custom_fields,
                });
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    const getReorderCustomFields = () => {
        setIsReorderLoading(true);
        api.GET_customFields(type, 10000, 1, "index", undefined, undefined, step === null || step === void 0 ? void 0 : step.id, shared ? 1 : undefined)
            .then(response => {
            setReorderedCustomFields(response.data.custom_fields);
        })
            .catch(error => {
            console.error(error);
        })
            .finally(() => {
            setIsReorderLoading(false);
        });
    };
    const handleReorderCustomFields = result => {
        if (!result.destination)
            return;
        const fields = Array.from(reorderedCustomFields);
        const [reorderedFields] = fields.splice(result.source.index, 1);
        fields.splice(result.destination.index, 0, reorderedFields);
        setReorderedCustomFields(fields);
    };
    const saveReorderedCustomFields = () => {
        setIsLoading(true);
        reorderedCustomFields.map((field, index) => {
            field.index = index + 1;
        });
        api.REORDER_customFields(type, reorderedCustomFields)
            .then(() => {
            setIsReorderOpened(false);
            alert.show(prettifyText(type.startsWith("tenant") || type.startsWith("ticket")
                ? type.slice(7, -1)
                : type.slice(0, -1)) + t("fields.fields-table.fields-reordered"), "success");
            refreshTableData();
        })
            .catch(error => {
            console.error(error);
            alert.show(t("fields.fields-table.reordering-error") +
                prettifyText(type.startsWith("tenant") || type.startsWith("ticket")
                    ? type.slice(7, -1)
                    : type.slice(0, -1)) +
                " " +
                t("fields.field").toLowerCase() +
                " !", "error");
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Table, { title: t("fields.fields-table.all-fields"), actions: [
                    type !== "campaign_steps" || (type === "campaign_steps" && shared)
                        ? {
                            icon: () => _jsx(ViewIcon, { color: "action" }),
                            tooltip: t("fields.fields-table.view-field"),
                            onClick: (event, data) => {
                                navigate(tenancy.link("/settings/fields/" +
                                    (type.startsWith("tenant") ||
                                        type.startsWith("ticket")
                                        ? type.slice(7)
                                        : shared
                                            ? "shared/forms"
                                            : type) +
                                    "/" +
                                    data.id));
                            },
                        }
                        : undefined,
                    {
                        icon: () => _jsx(EditIcon, { color: "action" }),
                        tooltip: t("fields.edit-field"),
                        onClick: (event, data) => {
                            if (type !== "campaign_steps" ||
                                (type === "campaign_steps" && shared)) {
                                navigate(tenancy.link("/settings/fields/" +
                                    (type.startsWith("tenant") || type.startsWith("ticket")
                                        ? type.slice(7)
                                        : shared
                                            ? "shared/forms"
                                            : type) +
                                    "/" +
                                    data.id +
                                    "/edit"));
                            }
                            else {
                                setCustomField(data);
                                setEditCustomField(true);
                            }
                        },
                    },
                    type !== "campaign_steps"
                        ? {
                            icon: () => _jsx(RefreshIcon, { color: "action" }),
                            tooltip: t("fields.fields-table.refresh-fields"),
                            isFreeAction: true,
                            onClick: () => refreshTableData(),
                        }
                        : undefined,
                    !shared
                        ? {
                            icon: () => _jsx(ReorderIcon, { color: "action" }),
                            tooltip: t("fields.fields-table.reorder-fields"),
                            isFreeAction: true,
                            onClick: () => {
                                getReorderCustomFields();
                                setIsReorderOpened(true);
                            },
                        }
                        : undefined,
                    type === "campaign_steps" && !shared
                        ? {
                            icon: () => _jsx(AddIcon, { color: "action" }),
                            tooltip: t("fields.fields-table.add-new-field"),
                            isFreeAction: true,
                            onClick: () => {
                                setEditCustomField(true);
                                setAddNewField(true);
                            },
                        }
                        : undefined,
                ], columns: isMobile
                    ? [
                        {
                            title: t("fields.field") + " #",
                            field: type === "campaign_steps" ? "id" : "index",
                            defaultSort: "desc",
                        },
                    ]
                    : shared
                        ? [
                            {
                                maxWidth: "60px",
                                title: "#",
                                field: "id",
                                defaultSort: "asc",
                            },
                            {
                                title: t("fields.field-attributes.name"),
                                field: "field_name",
                                sorting: false,
                            },
                            {
                                title: t("fields.field-attributes.type"),
                                sorting: false,
                                render: data => {
                                    return data.field_type === "text"
                                        ? t("fields.field-form.field-types.text")
                                        : data.field_type === "text_area"
                                            ? t("fields.field-form.field-types.text-area")
                                            : data.field_type === "number"
                                                ? t("fields.field-form.field-types.number")
                                                : data.field_type === "date_time"
                                                    ? t("fields.field-form.field-types.date-time")
                                                    : data.field_type === "text_select"
                                                        ? t("fields.field-form.field-types.text-select")
                                                        : data.field_type === "checkbox"
                                                            ? t("fields.field-form.field-types.checkbox")
                                                            : data.field_type === "radio_text"
                                                                ? t("fields.field-form.field-types.radio-text")
                                                                : data.field_type === "radio_icon"
                                                                    ? t("fields.field-form.field-types.radio-icon")
                                                                    : data.field_type === "radio_image"
                                                                        ? t("fields.field-form.field-types.radio-image")
                                                                        : data.field_type === "switch"
                                                                            ? t("fields.field-form.field-types.switch")
                                                                            : data.field_type === "html_text"
                                                                                ? t("fields.field-form.field-types.html-text")
                                                                                : data.field_type === "file"
                                                                                    ? t("fields.field-form.field-types.file")
                                                                                    : data.field_type === "rating"
                                                                                        ? t("fields.field-form.field-types.rating")
                                                                                        : data.field_type === "slider"
                                                                                            ? t("fields.field-form.field-types.slider")
                                                                                            : data.field_type === "range"
                                                                                                ? t("fields.field-form.field-types.range")
                                                                                                : data.field_type === "address"
                                                                                                    ? t("fields.field-form.field-types.address")
                                                                                                    : data.field_type === "link"
                                                                                                        ? t("fields.field-form.field-types.link")
                                                                                                        : data.field_type === "mutable_number"
                                                                                                            ? t("fields.field-form.field-types.calculation")
                                                                                                            : data.field_type === "conversation"
                                                                                                                ? t("fields.field-form.field-types.conversation")
                                                                                                                : t("fields.field-form.field-types.phone");
                                },
                            },
                            {
                                title: t("details"),
                                sorting: false,
                                render: data => {
                                    return (_jsxs(Fragment, { children: [data.required && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "secondary", label: t("fields.field-attributes.required") }) }))), data.autofill && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "primary", label: t("fields.field-attributes.autofill") }) }))), data.shared_parent && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-form.field-types.shared") }) }))), (data.filterable ||
                                                (data.shared_parent &&
                                                    data.shared_parent.filterable)) && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-attributes.filterable") }) }))), (data.not_editable ||
                                                (data.shared_parent &&
                                                    data.shared_parent.not_editable)) && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-attributes.not-editable") }) }))), (data.has_metrics ||
                                                (data.shared_parent &&
                                                    data.shared_parent.has_metrics)) && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", label: t("fields.field-attributes.reportable") }) })))] }));
                                },
                            },
                        ]
                        : [
                            {
                                maxWidth: "90px",
                                title: t("fields.field-attributes.order"),
                                field: "index",
                                type: "numeric",
                                defaultSort: "asc",
                            },
                            {
                                title: t("fields.field-attributes.name"),
                                field: "field_name",
                                sorting: false,
                            },
                            {
                                title: t("fields.field-attributes.type"),
                                sorting: false,
                                render: data => {
                                    return data.field_type === "text"
                                        ? t("fields.field-form.field-types.text")
                                        : data.field_type === "text_area"
                                            ? t("fields.field-form.field-types.text-area")
                                            : data.field_type === "number"
                                                ? t("fields.field-form.field-types.number")
                                                : data.field_type === "date_time"
                                                    ? t("fields.field-form.field-types.date-time")
                                                    : data.field_type === "text_select"
                                                        ? t("fields.field-form.field-types.text-select")
                                                        : data.field_type === "checkbox"
                                                            ? t("fields.field-form.field-types.checkbox")
                                                            : data.field_type === "radio_text"
                                                                ? t("fields.field-form.field-types.radio-text")
                                                                : data.field_type === "radio_icon"
                                                                    ? t("fields.field-form.field-types.radio-icon")
                                                                    : data.field_type === "radio_image"
                                                                        ? t("fields.field-form.field-types.radio-image")
                                                                        : data.field_type === "switch"
                                                                            ? t("fields.field-form.field-types.switch")
                                                                            : data.field_type === "html_text"
                                                                                ? t("fields.field-form.field-types.html-text")
                                                                                : data.field_type === "file"
                                                                                    ? t("fields.field-form.field-types.file")
                                                                                    : data.field_type === "rating"
                                                                                        ? t("fields.field-form.field-types.rating")
                                                                                        : data.field_type === "slider"
                                                                                            ? t("fields.field-form.field-types.slider")
                                                                                            : data.field_type === "range"
                                                                                                ? t("fields.field-form.field-types.range")
                                                                                                : data.field_type === "address"
                                                                                                    ? t("fields.field-form.field-types.address")
                                                                                                    : data.field_type === "link"
                                                                                                        ? t("fields.field-form.field-types.link")
                                                                                                        : data.field_type === "mutable_number"
                                                                                                            ? t("fields.field-form.field-types.calculation")
                                                                                                            : data.field_type === "conversation"
                                                                                                                ? t("fields.field-form.field-types.conversation")
                                                                                                                : t("fields.field-form.field-types.phone");
                                },
                            },
                            {
                                title: t("details"),
                                sorting: false,
                                render: data => {
                                    return (_jsxs(Fragment, { children: [data.required && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "secondary", label: t("fields.field-attributes.required") }) }))), data.autofill && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "primary", label: t("fields.field-attributes.autofill") }) }))), data.shared_parent && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-form.field-types.shared") }) }))), (data.filterable ||
                                                (data.shared_parent &&
                                                    data.shared_parent.filterable)) && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-attributes.filterable") }) }))), (data.not_editable ||
                                                (data.shared_parent &&
                                                    data.shared_parent.not_editable)) && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-attributes.not-editable") }) }))), (data.has_metrics ||
                                                (data.shared_parent &&
                                                    data.shared_parent.has_metrics)) && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { size: "small", label: t("fields.field-attributes.reportable") }) })))] }));
                                },
                            },
                        ], detailPanel: isMobile
                    ? (field) => (_jsx(Box, Object.assign({ sx: {
                            paddingX: 2,
                            paddingY: 3,
                        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("fields.field-attributes.name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: prettifyText(field.field_name) }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("fields.field-attributes.type") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: field.field_type === "text"
                                                ? t("fields.field-form.field-types.text")
                                                : field.field_type === "text_area"
                                                    ? t("fields.field-form.field-types.text-area")
                                                    : field.field_type === "number"
                                                        ? t("fields.field-form.field-types.number")
                                                        : field.field_type === "date_time"
                                                            ? t("fields.field-form.field-types.date-time")
                                                            : field.field_type === "text_select"
                                                                ? t("fields.field-form.field-types.text-select")
                                                                : field.field_type === "checkbox"
                                                                    ? t("fields.field-form.field-types.checkbox")
                                                                    : field.field_type === "radio_text"
                                                                        ? t("fields.field-form.field-types.radio-text")
                                                                        : field.field_type === "radio_icon"
                                                                            ? t("fields.field-form.field-types.radio-icon")
                                                                            : field.field_type === "radio_image"
                                                                                ? t("fields.field-form.field-types.radio-image")
                                                                                : field.field_type === "switch"
                                                                                    ? t("fields.field-form.field-types.switch")
                                                                                    : field.field_type === "html_text"
                                                                                        ? t("fields.field-form.field-types.html-text")
                                                                                        : field.field_type === "file"
                                                                                            ? t("fields.field-form.field-types.file")
                                                                                            : field.field_type === "rating"
                                                                                                ? t("fields.field-form.field-types.rating")
                                                                                                : field.field_type === "slider"
                                                                                                    ? t("fields.field-form.field-types.slider")
                                                                                                    : field.field_type === "range"
                                                                                                        ? t("fields.field-form.field-types.range")
                                                                                                        : field.field_type === "address"
                                                                                                            ? t("fields.field-form.field-types.address")
                                                                                                            : field.field_type === "link"
                                                                                                                ? t("fields.field-form.field-types.link")
                                                                                                                : field.field_type === "mutable_number"
                                                                                                                    ? t("fields.field-form.field-types.calculation")
                                                                                                                    : field.field_type === "conversation"
                                                                                                                        ? t("fields.field-form.field-types.conversation")
                                                                                                                        : t("fields.field-form.field-types.phone") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("fields.field-attributes.caption") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: prettifyText(field.field_caption) }))] })), (field.required ||
                                    field.autofill ||
                                    field.shared_parent ||
                                    field.filterable ||
                                    field.has_metrics) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("details") })), _jsxs(Fragment, { children: [field.required && (_jsx(Box, Object.assign({ sx: {
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        marginRight: "8px",
                                                    } }, { children: _jsx(Chip, { size: "small", color: "secondary", label: t("fields.field-attributes.required") }) }))), field.autofill && (_jsx(Box, Object.assign({ sx: {
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        marginRight: "8px",
                                                    } }, { children: _jsx(Chip, { size: "small", color: "primary", label: t("fields.field-attributes.autofill") }) }))), field.shared_parent && (_jsx(Box, Object.assign({ sx: {
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        marginRight: "8px",
                                                    } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-form.field-types.shared") }) }))), (field.filterable ||
                                                    (field.shared_parent &&
                                                        field.shared_parent.filterable)) && (_jsx(Box, Object.assign({ sx: {
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        marginRight: "8px",
                                                    } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-attributes.filterable") }) }))), (field.not_editable ||
                                                    (field.shared_parent &&
                                                        field.shared_parent.not_editable)) && (_jsx(Box, Object.assign({ sx: {
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        marginRight: "8px",
                                                    } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("fields.field-attributes.not-editable") }) }))), (field.has_metrics ||
                                                    (field.shared_parent &&
                                                        field.shared_parent.has_metrics)) && (_jsx(Box, Object.assign({ sx: {
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        marginRight: "8px",
                                                    } }, { children: _jsx(Chip, { size: "small", label: t("fields.field-attributes.reportable") }) })))] })] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(field.created_at)).toLocaleDateString("en-GB") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(field.updated_at)).toLocaleDateString("en-GB") }))] }))] })) })))
                    : undefined, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }), _jsxs(Dialog, Object.assign({ maxWidth: "xs", fullWidth: true, scroll: "body", open: isReorderOpened, onClose: () => {
                    setIsReorderOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: _jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: t("fields.fields-table.reorder") })) }), _jsx(DialogContent, Object.assign({ dividers: true, style: {
                            height: "268px",
                        } }, { children: isReorderLoading ? (_jsx(Box, Object.assign({ sx: {
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            } }, { children: _jsx(CircularProgress, {}) }))) : (_jsx(DragDropContext, Object.assign({ onDragEnd: handleReorderCustomFields }, { children: _jsx(Droppable, Object.assign({ droppableId: "list" }, { children: provided => (_jsxs(List, Object.assign({}, provided.droppableProps, { ref: provided.innerRef, disablePadding: true }, { children: [reorderedCustomFields.map((field, index) => {
                                            return (_jsx(Draggable, Object.assign({ draggableId: field.id.toString(), index: index }, { children: provided => (_jsx(ListItem, Object.assign({ button: true, ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: _jsx(ListItemText, { primary: field.field_name }) }))) }), field.id));
                                        }), provided.placeholder] }))) })) }))) })), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsReorderOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ onClick: () => {
                                    saveReorderedCustomFields();
                                    setIsReorderOpened(false);
                                } }, { children: t("fields.fields-table.save-order") }))] })] }))] }));
}
