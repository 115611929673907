import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { useApiTenant } from "../../../../../api/api-tenant";
import DisplayOptions from "../../../../elements/options/display-options/display-options";
import Intro from "../../../../elements/blocks/intro";
import Nope from "../../../../elements/blocks/nope";
export default function DashboardColumns() {
    const { t } = useTranslation();
    const api = useApiTenant();
    const [displayOptionsAgent, setDisplayOptionsAgent] = useState();
    const [displayOptionsManager, setDisplayOptionsManager] = useState();
    const [displayOptionsAdmin, setDisplayOptionsAdmin] = useState();
    const [tab, setTab] = useState("agent");
    const [isLoading, setIsLoading] = useState(true);
    const fetchDisplayOptionsForDashboard = () => {
        setIsLoading(true);
        setDisplayOptionsAgent(undefined);
        setDisplayOptionsManager(undefined);
        setDisplayOptionsAdmin(undefined);
        api.GET_displayOptionsForDashboard()
            .then(response => {
            setDisplayOptionsAgent(response.data.agent);
            setDisplayOptionsManager(response.data.manager);
            setDisplayOptionsAdmin(response.data.admin);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    useEffect(() => {
        fetchDisplayOptionsForDashboard();
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("options.dashboard-columns.dashboard-columns"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.dashboard-columns.dashboard-columns"),
                        link: "/settings/options/dashboard-columns",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : displayOptionsAgent && displayOptionsManager && displayOptionsAdmin ? (_jsxs(Fragment, { children: [_jsxs(Tabs, Object.assign({ indicatorColor: "secondary", textColor: "inherit", value: tab, onChange: (_event, value) => {
                            setTab(value);
                        }, sx: {
                            marginBottom: 2,
                        } }, { children: [_jsx(Tab, { value: "agent", label: t("users.roles.agent") }), _jsx(Tab, { value: "manager", label: t("users.roles.manager") }), _jsx(Tab, { value: "admin", label: t("users.roles.admin") })] })), tab === "agent" && (_jsx(DisplayOptions, { role: "agent", options: displayOptionsAgent, refetchData: fetchDisplayOptionsForDashboard })), tab === "manager" && (_jsx(DisplayOptions, { role: "manager", options: displayOptionsManager, refetchData: fetchDisplayOptionsForDashboard })), tab === "admin" && (_jsx(DisplayOptions, { role: "admin", options: displayOptionsAdmin, refetchData: fetchDisplayOptionsForDashboard }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("options.dashboard-columns.errors.not-found") }))] }));
}
