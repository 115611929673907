import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { format, isValid, startOfWeek, endOfWeek, startOfMonth, startOfYear } from "date-fns";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import RefreshIcon from "@mui/icons-material/Refresh";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ViewIcon from "@mui/icons-material/Visibility";
import InputDatetime from "../inputs/input-datetime";
import MetricValueModal from "./metric-value-modal";
import { usePrefs } from "../../../services/prefs";
export default function MetricValue({ title, value, range, setRange, isLoading, setIsLoading, cachedAt, refresh, setRefresh, startDate, setStartDate, startTime, setStartTime, endDate, setEndDate, endTime, setEndTime, globalRange, setGlobalRange, setIsChosenCustomRange, isReportModal, isError, statuses, priorities, chosenStatuses, setChosenStatuses, chosenPriorities, setChosenPriorities, }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [openSelectCustomRange, setOpenSelectCustomRange] = useState(false);
    const [beginning, setBeginning] = useState("");
    const [end, setEnd] = useState("");
    const [isOpened, setIsOpened] = useState(false);
    const prefs = usePrefs();
    const useStyles = makeStyles({
        title: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "320px",
        },
    });
    const classes = useStyles();
    useEffect(() => {
        if (startDate === "")
            setStartTime("");
        if (endDate === "")
            setEndTime("");
    }, [startDate, endDate]);
    useEffect(() => {
        let today = new Date();
        if (range !== "custom") {
            if (range === "yesterday") {
                let yesterday = format(new Date(today.getTime() - 86400000), "yyyy-MM-dd");
                setBeginning(yesterday.toString());
                setEnd(yesterday.toString());
            }
            else if (range === "day") {
                setBeginning(format(today, "yyyy-MM-dd").toString());
                setEnd(format(today, "yyyy-MM-dd").toString());
            }
            else if (range === "this_week") {
                let start = format(startOfWeek(today, { weekStartsOn: 1 }), "yyyy-MM-dd");
                setBeginning(start.toString());
                setEnd(today.toString());
            }
            else if (range === "last_week") {
                let sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
                const start = startOfWeek(sevenDaysBeforeDate, { weekStartsOn: 1 });
                const end = endOfWeek(sevenDaysBeforeDate, { weekStartsOn: 1 });
                setBeginning(format(start, "yyyy-MM-dd").toString());
                setEnd(format(end, "yyyy-MM-dd").toString());
            }
            else if (range === "this_month") {
                const start = startOfMonth(today);
                setBeginning(format(start, "yyyy-MM-dd").toString());
                setEnd(today.toString());
            }
            else if (range === "last_month") {
                const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                setBeginning(format(firstDayPrevMonth, "yyyy-MM-dd").toString());
                setEnd(format(lastDayPrevMonth, "yyyy-MM-dd").toString());
            }
            else if (range === "this_year") {
                const start = startOfYear(today);
                setBeginning(format(start, "yyyy-MM-dd").toString());
                setEnd(today.toString());
            }
            else if (range === "last_year") {
                const firstDayPrevYear = new Date(today.getFullYear() - 1, 0, 1);
                const lastDayPrevYear = new Date(today.getFullYear(), 0, 0);
                setBeginning(format(firstDayPrevYear, "yyyy-MM-dd").toString());
                setEnd(format(lastDayPrevYear, "yyyy-MM-dd").toString());
            }
            else {
                setBeginning(today.toString());
                setEnd(today.toString());
            }
        }
    }, [range, refresh]);
    const displayCard = () => {
        return (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                        display: !isMobile || (isMobile && !isReportModal) ? "flex" : "inline",
                        justifyContent: !isMobile || (isMobile && !isReportModal) ? "space-between" : "normal",
                        alignItems: "center",
                        marginBottom: 2,
                    } }, { children: [_jsx(Box, Object.assign({ className: !isReportModal ? classes.title : undefined, sx: { marginRight: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: title })) })), _jsxs(Box, Object.assign({ sx: {
                                paddingTop: isMobile && isReportModal ? 2 : 0,
                                display: !isMobile && isReportModal ? "flex" : "inline",
                                justifyContent: !isMobile && isReportModal ? "space-between" : "normal",
                                alignItems: "center",
                            } }, { children: [isReportModal && (_jsxs(Fragment, { children: [statuses && chosenStatuses && setChosenStatuses && (_jsx(Box, Object.assign({ sx: {
                                                display: !isMobile ? "flex" : "inline",
                                                justifyContent: !isMobile ? "flex-start" : "normal",
                                                paddingRight: !isMobile ? 2 : 0,
                                                width: "200px",
                                            } }, { children: _jsx(Autocomplete, { fullWidth: true, multiple: true, options: statuses, getOptionLabel: status => status.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: chosenStatuses, onChange: (event, value) => {
                                                    setChosenStatuses(value);
                                                }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ size: "small", label: option.name }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { placeholder: t("tickets.ticket-attributes.status"), fullWidth: true }))) }) }))), priorities && chosenPriorities && setChosenPriorities && (_jsx(Box, Object.assign({ sx: {
                                                display: !isMobile ? "flex" : "inline",
                                                justifyContent: !isMobile ? "flex-start" : "normal",
                                                paddingRight: !isMobile ? 2 : 0,
                                                width: "200px",
                                            } }, { children: _jsx(Autocomplete, { fullWidth: true, multiple: true, options: priorities, getOptionLabel: priority => priority.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: chosenPriorities, onChange: (event, value) => {
                                                    setChosenPriorities(value);
                                                }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ size: "small", label: option.name }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { placeholder: t("tickets.ticket-attributes.priority"), fullWidth: true }))) }) })))] })), !isReportModal && (_jsxs(Button, Object.assign({ color: "primary", onClick: () => setIsOpened(true), style: {
                                        padding: 0,
                                        fontSize: "12px",
                                    } }, { children: [_jsx(ViewIcon, { fontSize: "small", style: { marginRight: 4 } }), t("metrics.view-report")] }))), range === "custom" && isReportModal && (_jsx(Box, Object.assign({ sx: {
                                        paddingRight: 1,
                                    } }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: () => {
                                            setOpenSelectCustomRange(true);
                                            if (globalRange &&
                                                setGlobalRange &&
                                                globalRange !== "none") {
                                                setGlobalRange("none");
                                            }
                                        } }, { children: _jsx(DateRangeIcon, { fontSize: "small", color: "action" }) })) }))), isReportModal && (_jsxs(Select, Object.assign({ disabled: isLoading, variant: "standard", value: range, onChange: event => {
                                        setRange(event.target.value);
                                        if (event.target.value === "custom") {
                                            setOpenSelectCustomRange(true);
                                        }
                                        setIsLoading(true);
                                    } }, { children: [_jsx(MenuItem, Object.assign({ value: "custom" }, { children: t("metrics.range-options.custom.custom") })), _jsx(MenuItem, Object.assign({ value: "day" }, { children: t("metrics.range-options.today") })), _jsx(MenuItem, Object.assign({ value: "yesterday" }, { children: t("metrics.range-options.yesterday") })), _jsx(MenuItem, Object.assign({ value: "this_week" }, { children: t("metrics.range-options.this-week") })), _jsx(MenuItem, Object.assign({ value: "last_week" }, { children: t("metrics.range-options.last-week") })), _jsx(MenuItem, Object.assign({ value: "this_month" }, { children: t("metrics.range-options.this-month") })), _jsx(MenuItem, Object.assign({ value: "last_month" }, { children: t("metrics.range-options.last-month") })), _jsx(MenuItem, Object.assign({ value: "this_year" }, { children: t("metrics.range-options.this-year") })), _jsx(MenuItem, Object.assign({ value: "last_year" }, { children: t("metrics.range-options.last-year") })), _jsx(MenuItem, Object.assign({ value: "total" }, { children: t("metrics.range-options.total") }))] })))] }))] })), _jsx(Box, Object.assign({ sx: {
                        position: "relative",
                        height: isReportModal ? "510px" : "150px",
                        width: "100%",
                    } }, { children: range === "custom" &&
                        (startDate === "" || endDate === "" || openSelectCustomRange) ? (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-end",
                                height: isReportModal ? "500px" : "130px",
                            } }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("metrics.enter-range-options") })) })) })) : isLoading ? (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        } }, { children: _jsx(CircularProgress, { color: "primary", size: isReportModal ? 60 : 30 }) }))) : isError ? (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: isReportModal ? "center" : "flex-start",
                            alignItems: isReportModal ? "center" : "flex-end",
                            height: isReportModal ? "465px" : "130px",
                            marginTop: 1,
                        } }, { children: _jsx(Typography, Object.assign({ variant: isReportModal ? "subtitle1" : "caption", color: "textSecondary" }, { children: t("metrics.error-occured") })) }))) : (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                    paddingTop: 0,
                                    height: isReportModal && isMobile
                                        ? "445px"
                                        : isReportModal && !isMobile
                                            ? "465px"
                                            : "105px",
                                    display: "flex",
                                    justifyContent: isReportModal ? "center" : "flex-start",
                                    alignItems: isReportModal ? "center" : "flex-start",
                                } }, { children: _jsx(Typography, Object.assign({ variant: isReportModal ? "h2" : "h4" }, { children: value })) })), cachedAt !== "" && (_jsxs(Grid, Object.assign({ container: true, direction: "column" }, { children: [((range !== "custom" &&
                                        beginning !== "" &&
                                        isValid(new Date(beginning)) &&
                                        end !== "") ||
                                        (range === "custom" &&
                                            startDate !== "" &&
                                            endDate !== "")) && (_jsx(Grid, Object.assign({ item: true, xs: 7, sm: 7, md: 7 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                                marginTop: 1,
                                                flexDirection: "row",
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: range === "custom"
                                                        ? t("metrics.results-from") +
                                                            new Date(Date.parse(startDate)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(startTime !== ""
                                                                ? startTime
                                                                : startDate
                                                                    ? format(new Date(startDate), "yyyy-MM-dd") + " 00:00:00"
                                                                    : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }) +
                                                            " - "
                                                        : range === "total"
                                                            ? t("metrics.results-from") +
                                                                t("metrics.beginning")
                                                            : t("metrics.results-from") +
                                                                new Date(Date.parse(beginning)).toLocaleDateString("en-GB") +
                                                                " - " })), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", style: { paddingLeft: "4px" } }, { children: range === "custom"
                                                        ? new Date(Date.parse(endDate)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(endTime !== ""
                                                                ? endTime
                                                                : endDate
                                                                    ? format(new Date(endDate), "yyyy-MM-dd") + " 23:59:59"
                                                                    : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })
                                                        : new Date(Date.parse(end)).toLocaleDateString("en-GB") }))] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 5, sm: 5, md: 5 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: [t("metrics.cached-at"), new Date(Date.parse(cachedAt)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(cachedAt)).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })] })), _jsxs(Button, Object.assign({ size: "small", onClick: () => {
                                                        setRefresh(true);
                                                    }, style: {
                                                        padding: 0,
                                                        marginLeft: "5px",
                                                        color: prefs.theme === "light"
                                                            ? "#00000099"
                                                            : "#ffffffb3",
                                                        fontSize: "11px",
                                                        textTransform: "none",
                                                    } }, { children: [_jsx(RefreshIcon, { fontSize: "small", style: { marginRight: 2 }, color: "action" }), t("metrics.refresh-data")] }))] })) }))] })))] })) }))] }));
    };
    return (_jsxs(Fragment, { children: [isReportModal ? (displayCard()) : (_jsx(Card, { children: _jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        flexDirection: "column",
                        paddingX: 3,
                        paddingTop: 2.5,
                        paddingBottom: 3,
                    } }, { children: displayCard() })) })), _jsxs(Dialog, Object.assign({ maxWidth: "xs", open: openSelectCustomRange }, { children: [_jsxs(DialogTitle, { children: [" ", t("metrics.choose-range")] }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("metrics.range-options.custom.start-date"), required: true, value: startDate, setValue: setStartDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: t("metrics.range-options.custom.time"), required: false, status: "start", value: startTime !== "" ? startTime : null, setValue: setStartTime, disabled: !startDate || startDate === "" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("metrics.range-options.custom.end-date"), required: true, value: endDate, setValue: setEndDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: t("metrics.range-options.custom.time"), required: false, status: "end", value: endTime !== "" ? endTime : null, setValue: setEndTime, disabled: !endDate || endDate === "" }) }))] })) }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsx(Button, Object.assign({ disabled: startDate === "" || endDate === "", color: "primary", onClick: () => {
                                    setIsChosenCustomRange(true);
                                    setOpenSelectCustomRange(false);
                                } }, { children: t("metrics.confirm") })) })) })] })), _jsx(MetricValueModal, { isOpened: isOpened, setIsOpened: setIsOpened, title: title, value: value, range: range, setRange: setRange, isLoading: isLoading, setIsLoading: setIsLoading, cachedAt: cachedAt, refresh: refresh, setRefresh: setRefresh, startDate: startDate, setStartDate: setStartDate, startTime: startTime, setStartTime: setStartTime, endDate: endDate, setEndDate: setEndDate, endTime: endTime, setEndTime: setEndTime, globalRange: globalRange, setGlobalRange: setGlobalRange, setIsChosenCustomRange: setIsChosenCustomRange, isError: isError, statuses: statuses, priorities: priorities, chosenStatuses: chosenStatuses, setChosenStatuses: setChosenStatuses, chosenPriorities: chosenPriorities, setChosenPriorities: setChosenPriorities })] }));
}
