import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import UsersTable from "../../../elements/users/users-table";
export default function CentralUsers() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("users.users"), breadcrumbs: [
                    {
                        name: t("pages.administration"),
                        link: "/administration",
                    },
                    {
                        name: t("users.users"),
                        link: "/administration/users",
                    },
                ], button: {
                    label: t("users.new-user"),
                    link: "/administration/users/new",
                    color: "secondary",
                    type: "new",
                } }), _jsx(UsersTable, { type: "central", title: t("users.users-table.all-users"), link: "/administration/users/" })] }));
}
