import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FieldsIcon from "@mui/icons-material/PermContactCalendar";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import InputText from "./input-text";
import InputDatetime from "./input-datetime";
import InputNumber from "./input-number";
import InputAutocomplete from "./input-autocomplete";
import InputCheckbox from "./input-checkbox";
import InputRadio from "./input-radio";
import InputLink from "./input-link";
import InputTextArea from "./input-textarea";
import InputSwitch from "./input-switch";
import InputEditor from "./input-editor";
import InputRating from "./input-rating";
import InputSlider from "./input-slider";
import InputAddress from "./input-address";
import InputFile from "./input-file";
import InputPhoneField from "./input-phone-field";
import InputCalculation from "./input-calculation";
import InputConversation from "./input-conversation";
import { prettifyText } from "../../../helpers/clean";
export default function InputCustomFields({ type, tenancyCustomFields, customFields, setCustomFields, information, setInformation, disabledCustomFields, setDisabledCustomFields, notEditable, campaign, contact, step, info, disabled, error, errors, isError, setIsError, modal, autofillData, activityCustomFieldOptions, }) {
    const { t } = useTranslation();
    const setValues = (customField, value, tenancyCustomField, fieldType, disabled, filteredDisabledCustomFields) => {
        if (customFields && setCustomFields) {
            const filteredCustomFields = customFields.filter(field => field.custom_field_id !== (customField === null || customField === void 0 ? void 0 : customField.custom_field_id));
            if (fieldType === "text" ||
                fieldType === "text_area" ||
                fieldType === "html_text" ||
                fieldType === "conversation") {
                customField.text = value;
            }
            else if (fieldType === "link") {
                customField.url = value;
            }
            else if (fieldType === "date_time") {
                customField.date_time = value;
            }
            else if (fieldType === "number") {
                customField.number = value;
            }
            else if (fieldType === "radio_text" ||
                fieldType === "radio_image" ||
                fieldType === "radio_icon") {
                customField.selected_item_id = value;
            }
            else if (fieldType === "text_select") {
                customField.selected_item_id = value;
            }
            else if (fieldType === "checkbox") {
                customField.checkbox_items_ids = value;
            }
            else if (fieldType === "switch") {
                customField.on = value;
            }
            else if (fieldType === "file") {
                customField.media = value;
            }
            else if (fieldType === "slider" || fieldType === "rating") {
                customField.value = value;
            }
            else if (fieldType === "range") {
                customField.values = value;
            }
            else if (fieldType === "address") {
                customField.address = value;
            }
            else if (fieldType === "mutable_number") {
                customField.previous_number = value.previous_number;
                customField.current_number = value.current_number;
                customField.target_number = value.target_number;
            }
            else if (fieldType === "phone") {
                customField.phone = value;
            }
            setCustomFields([...filteredCustomFields, customField]);
            if (disabled) {
                setDisabledCustomFields([
                    // @ts-ignore
                    ...filteredDisabledCustomFields,
                    {
                        // @ts-ignore
                        customField: tenancyCustomField,
                        disabled: true,
                    },
                ]);
            }
        }
        else {
            addInformation(step.id, tenancyCustomField.id, tenancyCustomField.field_name, fieldType === "text" ||
                fieldType === "text_area" ||
                fieldType === "html_text" ||
                fieldType === "conversation"
                ? value
                : "", fieldType === "date_time" ? value : "", fieldType === "number" ? value : "", fieldType === "radio_text" ||
                fieldType === "radio_image" ||
                fieldType === "radio_icon"
                ? value
                : fieldType === "text_select"
                    ? value
                    : "", fieldType === "checkbox" ? value : [], fieldType === "radio_text" ? value : "", fieldType === "radio_image" ? value : "", fieldType === "radio_icon" ? value : "", fieldType === "switch" ? value : false, fieldType === "file" ? value : [], fieldType === "slider" ? value : fieldType === "rating" ? value : null, fieldType === "range" ? value : null, fieldType === "address" ? value : null, fieldType === "phone" ? value : null, fieldType === "link" ? value : null, fieldType === "mutable_number" ? value.previous_number : null, fieldType === "mutable_number" ? value.current_number : null, fieldType === "mutable_number" ? value.target_number : null);
            if (disabled) {
                setDisabledCustomFields([
                    // @ts-ignore
                    ...filteredDisabledCustomFields,
                    {
                        // @ts-ignore
                        customField: tenancyCustomField,
                        disabled: true,
                    },
                ]);
            }
        }
    };
    const addInformation = (stepId, fieldId, fieldName, text, dateTime, number, selectedItemId, checkboxItemsIds, radioText, radioImage, radioIcon, on, files, value, values, address, phone, url, previousNumber, currentNumber, targetNumber) => {
        // @ts-ignore
        const updatedArray = [...information];
        let index = updatedArray.findIndex(a => a.campaign_step_id === stepId && a.custom_field_id === fieldId);
        if (index === -1) {
            updatedArray.push({
                campaign_step_id: stepId,
                custom_field_id: fieldId,
                custom_field_name: fieldName,
                text: text,
                date_time: dateTime,
                number: number,
                selected_item_id: selectedItemId,
                checkbox_items_ids: checkboxItemsIds,
                radio_text: radioText,
                radio_image: radioImage,
                radio_icon: radioIcon,
                on: on,
                media: files,
                value: value,
                values: values,
                address: address,
                phone: phone,
                url: url,
                previous_number: previousNumber,
                current_number: currentNumber,
                target_number: targetNumber,
            });
        }
        else {
            if (updatedArray[index].custom_field_id !== null && fieldId) {
                updatedArray[index].custom_field_id = fieldId;
            }
            if (updatedArray[index].custom_field_name !== null && fieldName) {
                updatedArray[index].custom_field_name = fieldName;
            }
            updatedArray[index].text = text;
            updatedArray[index].date_time = dateTime;
            updatedArray[index].number = number;
            updatedArray[index].selected_item_id = selectedItemId;
            updatedArray[index].checkbox_items_ids = checkboxItemsIds;
            updatedArray[index].radio_text = radioText;
            updatedArray[index].radio_image = radioImage;
            updatedArray[index].radio_icon = radioIcon;
            updatedArray[index].on = on;
            updatedArray[index].media = files;
            updatedArray[index].value = value;
            updatedArray[index].values = values;
            updatedArray[index].address = address;
            updatedArray[index].phone = phone;
            updatedArray[index].url = url;
            updatedArray[index].previous_number = previousNumber;
            updatedArray[index].current_number = currentNumber;
            updatedArray[index].target_number = targetNumber;
        }
        if (setInformation) {
            setInformation(updatedArray);
        }
    };
    const setDefaultInformation = customField => {
        customField &&
            !information.find(e => e.campaign_step_id === step.id && e.custom_field_id === customField.id) &&
            information.push({
                campaign_step_id: step.id,
                custom_field_id: customField.id ? customField.id : -1,
                custom_field_name: customField.field_name,
                text: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data.text
                    : customField.text
                        ? customField.text
                        : "",
                date_time: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .date_time
                    : customField.date_time
                        ? customField.date_time
                        : "",
                number: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .number
                    : customField.number
                        ? customField.number
                        : "",
                selected_item_id: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .selected_item_id
                    : customField.selected_item_id
                        ? customField.selected_item_id
                        : "",
                checkbox_items_ids: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .checkbox_items_ids
                    : customField.checkbox_items_ids
                        ? customField.checkbox_items_ids
                        : [],
                radio_text: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .radio_text
                    : customField.radio_text
                        ? customField.radio_text
                        : "",
                radio_icon: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .radio_icon
                    : customField.radio_icon
                        ? customField.radio_icon
                        : "",
                radio_image: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .radio_image
                    : customField.radio_image
                        ? customField.radio_image
                        : "",
                on: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data.on
                    : customField.on
                        ? customField.on
                        : false,
                media: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .media
                    : customField.media
                        ? customField.media
                        : [],
                value: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .value
                    : customField.value
                        ? customField.value
                        : null,
                values: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .values
                    : customField.values
                        ? customField.values
                        : null,
                address: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .address
                    : customField.address
                        ? customField.address
                        : null,
                phone: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .phone
                    : customField.phone
                        ? customField.phone
                        : null,
                url: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data.url
                    : customField.url
                        ? customField.url
                        : null,
                previous_number: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .previous_number
                    : customField.previous_number
                        ? customField.previous_number
                        : null,
                current_number: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .current_number
                    : customField.current_number
                        ? customField.current_number
                        : null,
                target_number: autofillData &&
                    autofillData.find(a => a.step_id === step.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id) &&
                    autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                    ? autofillData
                        .find(a => a.step_id === step.id)
                        .custom_fields.find(b => b.id === customField.id).autofill_data
                        .target_number
                    : customField.target_number
                        ? customField.target_number
                        : null,
            });
    };
    useEffect(() => {
        if (type !== "information" && customFields && setCustomFields) {
            let customFieldsWithEmpty = customFields;
            let disabled = disabledCustomFields;
            tenancyCustomFields.map(tenancyCustomField => {
                var _a;
                let customField = customFieldsWithEmpty.length > 0
                    ? customFieldsWithEmpty.find(customField => customField.custom_field_id === tenancyCustomField.id)
                    : null;
                if (!customField) {
                    if (tenancyCustomField.field_type === "text" ||
                        tenancyCustomField.field_type === "html_text" ||
                        tenancyCustomField.field_type === "text_area") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            text: "",
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "radio_image" ||
                        tenancyCustomField.field_type === "radio_icon" ||
                        tenancyCustomField.field_type === "radio_text" ||
                        tenancyCustomField.field_type === "text_select") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            selected_item_id: "",
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "number") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            number: "",
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "checkbox") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            checkbox_items_ids: [],
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "switch") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            on: false,
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "date_time") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            date_time: "",
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "rating" ||
                        tenancyCustomField.field_type === "slider") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            value: null,
                            index: tenancyCustomField.index,
                        };
                        if (tenancyCustomField.field_type === "slider" &&
                            !disabledCustomFields.find(field => field.customField.id === tenancyCustomField.id)) {
                            let disabledField = {
                                customField: tenancyCustomField,
                                disabled: true,
                            };
                            disabled = [...disabled, disabledField];
                        }
                    }
                    else if (tenancyCustomField.field_type === "range") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            values: null,
                            index: tenancyCustomField.index,
                        };
                        if (!disabledCustomFields.find(field => field.customField.id === tenancyCustomField.id)) {
                            let disabledField = {
                                customField: tenancyCustomField,
                                disabled: true,
                            };
                            disabled = [...disabled, disabledField];
                        }
                    }
                    else if (tenancyCustomField.field_type === "address") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            address: null,
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "phone") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            phone: null,
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "link") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            url: "",
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "mutable_number") {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            previous_number: null,
                            current_number: null,
                            target_number: null,
                            index: tenancyCustomField.index,
                        };
                    }
                    else if (tenancyCustomField.field_type === "conversation") {
                        const conversationItems = (_a = contact === null || contact === void 0 ? void 0 : contact.custom_fields.find(field => field.custom_field_id === tenancyCustomField.id)) === null || _a === void 0 ? void 0 : _a.conversation_items;
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            text: "",
                            conversation_items: conversationItems,
                            index: tenancyCustomField.index,
                        };
                    }
                    else {
                        customField = {
                            custom_field_id: tenancyCustomField.id,
                            media: [],
                            index: tenancyCustomField.index,
                        };
                    }
                    customFieldsWithEmpty = [...customFieldsWithEmpty, customField];
                }
            });
            setDisabledCustomFields(disabled);
            setCustomFields(customFieldsWithEmpty);
        }
    }, [customFields, disabledCustomFields]);
    const inputCustomFields = () => {
        return (_jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: tenancyCustomFields.map((tenancyCustomField, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                if (type === "information") {
                    setDefaultInformation(tenancyCustomField);
                }
                const customField = customFields && customFields.length > 0
                    ? customFields.find(customField => customField.custom_field_id === tenancyCustomField.id)
                    : information &&
                        information.find(customField => customField.custom_field_id == tenancyCustomField.id);
                const fieldRequired = campaign
                    ? type === "ticket_activities"
                        ? campaign.activity_custom_field_options.length > 0 &&
                            campaign.activity_custom_field_options.find(e => e.custom_field_id === tenancyCustomField.id)
                            ? campaign.activity_custom_field_options.find(e => e.custom_field_id === tenancyCustomField.id).required
                            : false
                        : campaign.custom_field_options.length > 0 &&
                            campaign.custom_field_options.find(e => e.custom_field_id === tenancyCustomField.id)
                            ? campaign.custom_field_options.find(e => e.custom_field_id === tenancyCustomField.id).required
                            : false
                    : activityCustomFieldOptions
                        ? activityCustomFieldOptions.find(e => e.custom_field_id === tenancyCustomField.id).required
                        : Boolean(tenancyCustomField.required);
                const fieldVisible = campaign
                    ? type === "ticket_activities"
                        ? campaign &&
                            campaign.activity_custom_field_options.length > 0 &&
                            ((_a = campaign.activity_custom_field_options.find(e => e.custom_field_id === tenancyCustomField.id)) === null || _a === void 0 ? void 0 : _a.visible)
                        : campaign &&
                            campaign.custom_field_options.length > 0 &&
                            ((_b = campaign.custom_field_options.find(e => e.custom_field_id === tenancyCustomField.id)) === null || _b === void 0 ? void 0 : _b.visible)
                    : activityCustomFieldOptions
                        ? (_c = activityCustomFieldOptions.find(e => e.custom_field_id === tenancyCustomField.id)) === null || _c === void 0 ? void 0 : _c.visible
                        : true;
                const fieldIndex = customFields
                    ? index
                    : information &&
                        information.findIndex(customField => customField.custom_field_id === tenancyCustomField.id);
                if (tenancyCustomField.field_type === "html_text" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, height: 300, label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.text) ? customField.text : "", setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".text")
                                    ? error + fieldIndex + ".text"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if ((tenancyCustomField.field_type === "radio_image" ||
                    tenancyCustomField.field_type === "radio_icon") &&
                    fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputRadio, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, direction: "horizontal", type: tenancyCustomField.field_type === "radio_image"
                                ? "image"
                                : "icon", label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.selected_item_id)
                                ? customField.selected_item_id
                                : "", setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, options: customFields
                                ? tenancyCustomField.field_type === "radio_image"
                                    ? tenancyCustomField.image_text_items
                                    : tenancyCustomField.text_items
                                : tenancyCustomField.field_type === "radio_icon"
                                    ? tenancyCustomField.text_items
                                        ? tenancyCustomField.text_items.concat(tenancyCustomField.shared_text_items
                                            ? tenancyCustomField.shared_text_items
                                            : [])
                                        : tenancyCustomField.shared_text_items
                                            ? tenancyCustomField.shared_text_items
                                            : []
                                    : tenancyCustomField.image_text_items
                                        ? tenancyCustomField.image_text_items.concat(tenancyCustomField.shared_image_text_items
                                            ? tenancyCustomField.shared_image_text_items
                                            : [])
                                        : tenancyCustomField.shared_image_text_items
                                            ? tenancyCustomField.shared_image_text_items
                                            : [], error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".selected_item_id")
                                    ? error + fieldIndex + ".selected_item_id"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "text" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, info: info, label: prettifyText(tenancyCustomField.field_name) +
                                (fieldRequired && !tenancyCustomField.field_caption
                                    ? " *"
                                    : ""), caption: tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption +
                                    (fieldRequired ? " *" : "")
                                : undefined, required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.text) ? customField.text : "", setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".text")
                                    ? error + fieldIndex + ".text"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "link" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputLink, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, info: info, label: prettifyText(tenancyCustomField.field_name) +
                                (fieldRequired && !tenancyCustomField.field_caption
                                    ? " *"
                                    : ""), caption: tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption +
                                    (fieldRequired ? " *" : "")
                                : undefined, required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.url) ? customField.url : "", setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".url")
                                    ? error + fieldIndex + ".url"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "address" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(FormControl, Object.assign({ required: fieldRequired, fullWidth: true, error: isError
                                ? fieldRequired &&
                                    (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error + fieldIndex + ".address")) &&
                                    errors
                                    ? true
                                    : !!(errors &&
                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error + fieldIndex)))
                                : false }, { children: [_jsx(InputAddress, { disabled: notEditable
                                        ? notEditable && tenancyCustomField.not_editable
                                        : disabled
                                            ? disabled
                                            : false, label: prettifyText(tenancyCustomField.field_name) +
                                        (fieldRequired && !tenancyCustomField.caption
                                            ? " *"
                                            : ""), caption: tenancyCustomField.field_caption
                                        ? tenancyCustomField.field_caption +
                                            (fieldRequired ? " *" : "")
                                        : undefined, address: (customField === null || customField === void 0 ? void 0 : customField.address) ? customField.address : null, setAddress: value => {
                                        setValues(customField, value, tenancyCustomField, tenancyCustomField.field_type, false, null);
                                        if (isError &&
                                            (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error + fieldIndex + ".address")))
                                            setIsError(false);
                                    } }), isError &&
                                    fieldRequired &&
                                    errors &&
                                    ((errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error + fieldIndex + ".address")) ||
                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error + fieldIndex))) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors
                                        ? fieldRequired &&
                                            errors.hasOwnProperty(error + fieldIndex + ".address")
                                            ? errors[error + fieldIndex + ".address"][0]
                                            : errors.hasOwnProperty(error + fieldIndex)
                                                ? error + fieldIndex
                                                : ""
                                        : "" })))] })) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "phone" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputPhoneField, { label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), phone: (customField === null || customField === void 0 ? void 0 : customField.phone) ? customField === null || customField === void 0 ? void 0 : customField.phone : undefined, setPhone: value => {
                                setValues(customField, value, tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".phone.number")
                                    ? error + fieldIndex + ".phone.number"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError, isCustomField: true }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "rating" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputRating, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, info: info, label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), max: tenancyCustomField.max_value
                                ? tenancyCustomField.max_value
                                : 5, precision: tenancyCustomField.step ? tenancyCustomField.step : 1, required: fieldRequired, icon: tenancyCustomField.icon, emptyIcon: tenancyCustomField.empty_icon, value: (customField === null || customField === void 0 ? void 0 : customField.value) ? Number(customField.value) : 0, setValue: value => {
                                setValues(customField, value ? value : null, tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".value")
                                    ? error + fieldIndex + ".value"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "slider" && fieldVisible)
                    return (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                } }, { children: [_jsx(Typography, Object.assign({ variant: "body1" }, { children: prettifyText(tenancyCustomField.field_caption
                                            ? tenancyCustomField.field_caption
                                            : tenancyCustomField.field_name) + (fieldRequired ? " *" : "") })), _jsx(Tooltip
                                    // @ts-ignore
                                    , Object.assign({ 
                                        // @ts-ignore
                                        title: disabledCustomFields.find(field => field.customField.id == (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id))
                                            ? t("inputs.input-custom-fields.unlock-field")
                                            : t("inputs.input-custom-fields.lock-field"), placement: "top", arrow: true }, { children: _jsx(Box, Object.assign({ component: "span" }, { children: _jsx(IconButton, Object.assign({ disabled: notEditable
                                                    ? notEditable &&
                                                        tenancyCustomField.not_editable
                                                    : disabled
                                                        ? disabled
                                                        : false, onClick: () => {
                                                    const filteredDisabledCustomFields = disabledCustomFields.filter(field => field.customField.id !=
                                                        (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id));
                                                    let field = disabledCustomFields.find(field => field.customField.id ==
                                                        (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id));
                                                    if (field) {
                                                        setDisabledCustomFields([
                                                            ...filteredDisabledCustomFields,
                                                        ]);
                                                        setValues(customField, Number(tenancyCustomField.min_value), tenancyCustomField, tenancyCustomField.field_type, false, null);
                                                    }
                                                    else {
                                                        setValues(customField, null, tenancyCustomField, tenancyCustomField.field_type, true, filteredDisabledCustomFields);
                                                    }
                                                }, style: {
                                                    padding: 0,
                                                }, size: "large" }, { children: disabledCustomFields.find(field => field.customField.id ==
                                                    tenancyCustomField.id) ? (_jsx(LockIcon, { fontSize: "small", style: {
                                                        color: "rgb(244, 67, 54)",
                                                    } })) : (_jsx(LockOpenIcon, { fontSize: "small" })) })) })) }))] })), _jsx(Box, Object.assign({ sx: {
                                    paddingTop: 1,
                                } }, { children: _jsx(InputSlider, { disabled: notEditable
                                        ? notEditable && tenancyCustomField.not_editable
                                        : disabled
                                            ? disabled
                                            : !!disabledCustomFields.find(field => field.customField.id ===
                                                (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id)), info: info, max: tenancyCustomField.max_value
                                        ? tenancyCustomField.max_value
                                        : 5, min: tenancyCustomField.min_value
                                        ? tenancyCustomField.min_value
                                        : -1, step: tenancyCustomField.step ? tenancyCustomField.step : 1, required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.value) ? Number(customField.value) : -1, setValue: value => {
                                        setValues(customField, value ? Number(value) : null, tenancyCustomField, tenancyCustomField.field_type, false, null);
                                    }, marks: ((tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label) &&
                                        (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label) !== "") ||
                                        ((tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_label) &&
                                            (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_label) !== "")
                                        ? [
                                            {
                                                value: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_value)
                                                    ? Number(tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_value)
                                                    : -1,
                                                label: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label)
                                                    ? tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label
                                                    : "",
                                            },
                                            {
                                                value: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_value)
                                                    ? Number(tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_value)
                                                    : 5,
                                                label: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_label)
                                                    ? tenancyCustomField.max_label
                                                    : "",
                                            },
                                        ]
                                        : [], error: errors
                                        ? fieldRequired &&
                                            errors.hasOwnProperty(error + fieldIndex + ".value")
                                            ? error + fieldIndex + ".value"
                                            : errors.hasOwnProperty(error + fieldIndex)
                                                ? error + fieldIndex
                                                : ""
                                        : "", errors: errors, isError: isError, setIsError: setIsError }) }))] }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "range" && fieldVisible)
                    return (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                } }, { children: [_jsx(Typography, Object.assign({ variant: "body1" }, { children: prettifyText(tenancyCustomField.field_caption
                                            ? tenancyCustomField.field_caption
                                            : tenancyCustomField.field_name) + (fieldRequired ? " *" : "") })), _jsx(Tooltip
                                    // @ts-ignore
                                    , Object.assign({ 
                                        // @ts-ignore
                                        title: disabledCustomFields.find(field => field.customField.id == (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id))
                                            ? t("inputs.input-custom-fields.unlock-field")
                                            : t("inputs.input-custom-fields.lock-field"), placement: "top", arrow: true }, { children: _jsx(Box, Object.assign({ component: "span" }, { children: _jsx(IconButton, Object.assign({ disabled: notEditable
                                                    ? notEditable &&
                                                        tenancyCustomField.not_editable
                                                    : disabled
                                                        ? disabled
                                                        : false, onClick: () => {
                                                    const filteredDisabledCustomFields = disabledCustomFields.filter(field => field.customField.id !==
                                                        (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id));
                                                    let field = disabledCustomFields.find(field => field.customField.id ===
                                                        (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id));
                                                    if (field) {
                                                        setDisabledCustomFields([
                                                            ...filteredDisabledCustomFields,
                                                        ]);
                                                        setValues(customField, [
                                                            tenancyCustomField.min_value
                                                                ? tenancyCustomField.min_value
                                                                : 20,
                                                            tenancyCustomField.max_value
                                                                ? tenancyCustomField.max_value
                                                                : 40,
                                                        ], tenancyCustomField, tenancyCustomField.field_type, false, null);
                                                    }
                                                    else {
                                                        setValues(customField, null, tenancyCustomField, tenancyCustomField.field_type, true, filteredDisabledCustomFields);
                                                    }
                                                }, style: {
                                                    padding: 0,
                                                }, size: "large" }, { children: disabledCustomFields.find(field => field.customField.id ===
                                                    (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id)) ? (_jsx(LockIcon, { fontSize: "small", style: {
                                                        color: "rgb(244, 67, 54)",
                                                    } })) : (_jsx(LockOpenIcon, { fontSize: "small" })) })) })) }))] })), _jsx(Box, Object.assign({ sx: {
                                    paddingTop: 1,
                                } }, { children: _jsx(InputSlider, { disabled: notEditable
                                        ? notEditable && tenancyCustomField.not_editable
                                        : disabled
                                            ? disabled
                                            : !!disabledCustomFields.find(field => field.customField.id ===
                                                (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.id)), info: info, max: tenancyCustomField.max_value
                                        ? tenancyCustomField.max_value
                                        : 5, min: tenancyCustomField.min_value
                                        ? tenancyCustomField.min_value
                                        : -1, step: tenancyCustomField.step ? tenancyCustomField.step : 1, required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.values)
                                        ? customField.values.map(value => Number(value))
                                        : [
                                            tenancyCustomField.min_value
                                                ? tenancyCustomField.min_value
                                                : 20,
                                            tenancyCustomField.max_value
                                                ? tenancyCustomField.max_value
                                                : 40,
                                        ], setValue: value => {
                                        setValues(customField, value ? value.map(val => Number(val)) : null, tenancyCustomField, tenancyCustomField.field_type, false, null);
                                    }, marks: ((tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label) &&
                                        (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label) !== "") ||
                                        ((tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_label) &&
                                            (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_label) !== "")
                                        ? [
                                            {
                                                value: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_value)
                                                    ? tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_value
                                                    : -1,
                                                label: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label)
                                                    ? tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.min_label
                                                    : "",
                                            },
                                            {
                                                value: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_value)
                                                    ? tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_value
                                                    : 5,
                                                label: (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.max_label)
                                                    ? tenancyCustomField.max_label
                                                    : "",
                                            },
                                        ]
                                        : [], error: errors
                                        ? fieldRequired &&
                                            errors.hasOwnProperty(error + fieldIndex + ".values")
                                            ? error + fieldIndex + ".values"
                                            : errors.hasOwnProperty(error + fieldIndex)
                                                ? error + fieldIndex
                                                : ""
                                        : "", errors: errors, isError: isError, setIsError: setIsError }) }))] }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "date_time" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputDatetime, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, label: prettifyText(tenancyCustomField.field_name), required: fieldRequired && !tenancyCustomField.field_caption, type: tenancyCustomField.date_time_type
                                ? tenancyCustomField.date_time_type
                                : "date_time", caption: tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption +
                                    (fieldRequired ? " *" : "")
                                : undefined, value: (customField === null || customField === void 0 ? void 0 : customField.date_time) ? customField.date_time : null, setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".date_time")
                                    ? error + fieldIndex + ".date_time"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "number" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputNumber, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, label: prettifyText(tenancyCustomField.field_name) +
                                (fieldRequired && !tenancyCustomField.field_caption
                                    ? " *"
                                    : ""), caption: tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption +
                                    (fieldRequired ? " *" : "")
                                : undefined, required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.number) ? customField.number : "", setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: errors
                                ? errors.hasOwnProperty(error + fieldIndex + ".number")
                                    ? error + fieldIndex + ".number"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "text_select" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputAutocomplete, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, label: prettifyText(tenancyCustomField.field_name), disableClearable: fieldRequired, required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.selected_item_id)
                                ? customField.selected_item_id
                                : "", caption: tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption +
                                    (fieldRequired ? " *" : "")
                                : undefined, setValue: value => {
                                setValues(customField, value ? value.id : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, options: customFields
                                ? tenancyCustomField.text_items
                                : tenancyCustomField.text_items
                                    ? tenancyCustomField.text_items.concat(tenancyCustomField.shared_text_items
                                        ? tenancyCustomField.shared_text_items
                                        : [])
                                    : tenancyCustomField.shared_text_items
                                        ? tenancyCustomField.shared_text_items
                                        : [], error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".selected_item_id")
                                    ? error + fieldIndex + ".selected_item_id"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "checkbox" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputCheckbox, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), required: fieldRequired, checkedValues: (customField === null || customField === void 0 ? void 0 : customField.checkbox_items_ids)
                                ? customField.checkbox_items_ids
                                : [], setCheckedValues: value => {
                                setValues(customField, value, tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, options: customFields
                                ? tenancyCustomField.text_items
                                : tenancyCustomField.text_items
                                    ? tenancyCustomField.text_items.concat(tenancyCustomField.shared_text_items
                                        ? tenancyCustomField.shared_text_items
                                        : [])
                                    : tenancyCustomField.shared_text_items
                                        ? tenancyCustomField.shared_text_items
                                        : [], error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".checkbox_items_ids")
                                    ? error + fieldIndex + ".checkbox_items_ids"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "radio_text" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputRadio, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, direction: "vertical", type: "text", label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), required: fieldRequired, value: (customField === null || customField === void 0 ? void 0 : customField.selected_item_id)
                                ? customField.selected_item_id
                                : "", setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, options: customFields
                                ? tenancyCustomField.text_items
                                : tenancyCustomField.text_items
                                    ? tenancyCustomField.text_items.concat(tenancyCustomField.shared_text_items
                                        ? tenancyCustomField.shared_text_items
                                        : [])
                                    : tenancyCustomField.shared_text_items
                                        ? tenancyCustomField.shared_text_items
                                        : [], error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".selected_item_id")
                                    ? error + fieldIndex + ".selected_item_id"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "text_area" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputTextArea, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, rows: 4, autoRows: true, info: info, label: prettifyText(tenancyCustomField.field_name), required: fieldRequired && !tenancyCustomField.field_caption, value: (customField === null || customField === void 0 ? void 0 : customField.text) ? customField.text : "", caption: tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption +
                                    (fieldRequired ? " *" : "")
                                : undefined, setValue: value => {
                                setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".text")
                                    ? error + fieldIndex + ".text"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "switch" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputSwitch, { disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, info: info, label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), required: fieldRequired, checked: (customField === null || customField === void 0 ? void 0 : customField.on) ? customField.on : false, setChecked: value => {
                                setValues(customField, value, tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, error: fieldRequired ? error + fieldIndex : "", errors: errors, isError: isError, setIsError: setIsError }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "file" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputFile, { label: prettifyText(tenancyCustomField.field_caption
                                ? tenancyCustomField.field_caption
                                : tenancyCustomField.field_name) + (fieldRequired ? " *" : ""), files: (_f = (type !== "information"
                                ? customFields &&
                                    ((_d = customFields.find(field => field.custom_field_id ===
                                        tenancyCustomField.id)) === null || _d === void 0 ? void 0 : _d.media)
                                : information &&
                                    ((_e = information.find(e => e.custom_field_id === tenancyCustomField.id)) === null || _e === void 0 ? void 0 : _e.media))) !== null && _f !== void 0 ? _f : [], setFiles: value => {
                                setValues(customField, value, tenancyCustomField, tenancyCustomField.field_type, false, null);
                            }, disabled: notEditable
                                ? notEditable && tenancyCustomField.not_editable
                                : disabled
                                    ? disabled
                                    : false, error: errors
                                ? fieldRequired &&
                                    errors.hasOwnProperty(error + fieldIndex + ".media")
                                    ? error + fieldIndex + ".media"
                                    : errors.hasOwnProperty(error + fieldIndex)
                                        ? error + fieldIndex
                                        : ""
                                : "", errors: errors, isError: isError, setIsError: setIsError, multiple: (_g = tenancyCustomField.allow_multiple_files) !== null && _g !== void 0 ? _g : false, maxItems: (_h = tenancyCustomField.max_file_count) !== null && _h !== void 0 ? _h : 1 }) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "mutable_number" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Paper, Object.assign({ elevation: 0, variant: "outlined" }, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 2,
                                } }, { children: _jsx(InputCalculation, { disabled: notEditable
                                        ? notEditable && tenancyCustomField.not_editable
                                        : disabled
                                            ? disabled
                                            : false, label: prettifyText(tenancyCustomField.field_name) +
                                        (fieldRequired && !tenancyCustomField.field_caption
                                            ? " *"
                                            : ""), required: fieldRequired, value: {
                                        previous_number: (_j = customField === null || customField === void 0 ? void 0 : customField.previous_number) !== null && _j !== void 0 ? _j : null,
                                        current_number: (_k = customField === null || customField === void 0 ? void 0 : customField.current_number) !== null && _k !== void 0 ? _k : null,
                                        target_number: (_l = customField === null || customField === void 0 ? void 0 : customField.target_number) !== null && _l !== void 0 ? _l : null,
                                    }, setValue: value => {
                                        if (!customField)
                                            return;
                                        setValues(customField, value
                                            ? value
                                            : {
                                                previous_number: null,
                                                current_number: null,
                                                target_number: null,
                                            }, tenancyCustomField, tenancyCustomField.field_type, false, null);
                                    }, valueLabel: tenancyCustomField.value_number_title, targetLabel: tenancyCustomField.target_number_title, resultLabel: tenancyCustomField.result_number_title, changeLabel: tenancyCustomField.change_number_title, unit: tenancyCustomField.number_unit, error: errors
                                        ? fieldRequired &&
                                            errors.hasOwnProperty(error + fieldIndex + ".target_number")
                                            ? error + fieldIndex + ".target_number"
                                            : errors.hasOwnProperty(error + fieldIndex)
                                                ? error + fieldIndex
                                                : ""
                                        : "", errors: errors, isError: isError, setIsError: setIsError }) })) })) }), tenancyCustomField.id));
                if (tenancyCustomField.field_type === "conversation" && fieldVisible)
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Paper, Object.assign({ elevation: 0, variant: "outlined" }, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 2,
                                } }, { children: _jsx(InputConversation, { disabled: notEditable
                                        ? notEditable && tenancyCustomField.not_editable
                                        : disabled
                                            ? disabled
                                            : false, rows: 4, autoRows: true, info: info, label: prettifyText(tenancyCustomField.field_name), required: fieldRequired && !tenancyCustomField.field_caption, value: (customField === null || customField === void 0 ? void 0 : customField.text) ? customField.text : "", caption: tenancyCustomField.field_caption
                                        ? tenancyCustomField.field_caption +
                                            (fieldRequired ? " *" : "")
                                        : undefined, setValue: value => {
                                        setValues(customField, value ? value : "", tenancyCustomField, tenancyCustomField.field_type, false, null);
                                    }, error: errors
                                        ? fieldRequired &&
                                            errors.hasOwnProperty(error + fieldIndex + ".text")
                                            ? error + fieldIndex + ".text"
                                            : errors.hasOwnProperty(error + fieldIndex)
                                                ? error + fieldIndex
                                                : ""
                                        : "", errors: errors, isError: isError, setIsError: setIsError, conversations: customField === null || customField === void 0 ? void 0 : customField.conversation_items }) })) })) }), tenancyCustomField.id));
            }) })));
    };
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: activityCustomFieldOptions ? 0 : 4,
            } }, { children: type !== "groups" && type !== "information" ? (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                position: "sticky",
                                top: "100px",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(FieldsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.fields") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: type === "contacts"
                                                ? t("fields.contact-fields.contact-fields")
                                                : t("fields.activity-fields.activity-fields") }))] })] })) }))), activityCustomFieldOptions ? (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: {
                                paddingTop: 3.5,
                            } }, { children: inputCustomFields() })) }))) : (_jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: inputCustomFields() })) }) })))] }))) : (_jsx(Box, Object.assign({ sx: {
                    paddingLeft: type === "information" ? 2 : 0,
                    paddingTop: type === "information" ? 2 : 0,
                    paddingRight: type === "information" ? 2 : 0,
                } }, { children: inputCustomFields() }))) })) }));
}
