import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
const useStyles = makeStyles({
    root: {
        "& .MuiTypography-root img": {
            maxWidth: "100% !important",
        },
    },
});
export default function SurveyWelcome({ campaign, setStep, isTicketSaving }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const classes = useStyles();
    return (_jsxs(Box, Object.assign({ sx: {
            paddingY: 8,
            width: isMobile ? "100%" : "60%",
        } }, { children: [_jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                        paddingY: isMobile ? 2 : 4,
                        paddingX: isMobile ? 4 : 8,
                    }, className: classes.root }, { children: _jsx(Typography, { dangerouslySetInnerHTML: {
                            __html: campaign.instructions,
                        } }) })) }), _jsx(Box, Object.assign({ sx: {
                    paddingTop: 8,
                    textAlign: "center",
                } }, { children: _jsx(LoadingButton, Object.assign({ variant: "contained", loading: isTicketSaving, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), onClick: () => {
                        if ((campaign.field_options && campaign.field_options.length > 0) ||
                            (campaign.custom_field_options &&
                                campaign.custom_field_options.length > 0)) {
                            setStep("contact");
                        }
                        else if (campaign.has_steps) {
                            setStep("information");
                        }
                    } }, { children: t("tickets.survey.start") })) }))] })));
}
