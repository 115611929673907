import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Intro from "../../../../elements/blocks/intro";
import PhoneAreaForm from "../../../../elements/options/phone-areas/phone-area-form";
import { useApiTenant } from "../../../../../api/api-tenant";
export default function PhoneAreaNew() {
    const { t } = useTranslation();
    const api = useApiTenant();
    const [phoneCountries, setPhoneCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_phoneCountries()
            .then(response => {
            setPhoneCountries(response.data.phone_countries);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("options.phone-areas.new-phone-area"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.phone-areas.phone-areas"),
                        link: "/settings/options/phone-areas",
                    },
                    {
                        name: t("options.phone-areas.new-phone-area"),
                        link: "/settings/options/phone-areas/new",
                    },
                ] }), isLoading ? (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsx(PhoneAreaForm, { status: "new", phoneCountries: phoneCountries }))] }));
}
