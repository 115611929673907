import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createSingletonHook } from "../helpers/singleton";
import analytics from "../helpers/analytics";
import { useAuth } from "./auth";
export const [useTracking, TrackingProvider] = createSingletonHook(() => {
    const location = useLocation();
    const auth = useAuth();
    useEffect(() => {
        analytics.initGA();
    }, []);
    useEffect(() => {
        analytics.sendPageView(location.pathname, auth.user);
    }, [location]);
    return {};
});
