import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
export default function ConditionForm({ status, condition, setCondition, isOpened, setIsOpened, setIsAdded, setIdToDelete, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation();
    const alert = useAlert();
    const api = useApiTenant();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const unchangedForOptions = [
        60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 1440, 2880, 4320, 5760, 7200,
        8640, 10080, 20160, 30240,
    ];
    const [chosenCampaign, setChosenCampaign] = useState((_a = condition === null || condition === void 0 ? void 0 : condition.campaign) !== null && _a !== void 0 ? _a : null);
    const [unchangedStatuses, setUnchnagedStatuses] = useState((_b = condition === null || condition === void 0 ? void 0 : condition.unchanged_ticket_detail_statuses) !== null && _b !== void 0 ? _b : []);
    const [unchangedPriorities, setUnchangedPriorities] = useState((_c = condition === null || condition === void 0 ? void 0 : condition.unchanged_ticket_detail_priorities) !== null && _c !== void 0 ? _c : []);
    const [newStatus, setNewStatus] = useState((_d = condition === null || condition === void 0 ? void 0 : condition.new_ticket_detail_status) !== null && _d !== void 0 ? _d : null);
    const [newPriority, setNewPriority] = useState((_e = condition === null || condition === void 0 ? void 0 : condition.new_ticket_detail_priority) !== null && _e !== void 0 ? _e : null);
    const [unchangedFor, setUnchangedFor] = useState((_f = condition === null || condition === void 0 ? void 0 : condition.unchanged_for_minutes) !== null && _f !== void 0 ? _f : null);
    const [campaigns, setCampaigns] = useState([]);
    const [isOptionOn, setIsOptionOn] = useState((_g = condition === null || condition === void 0 ? void 0 : condition.on) !== null && _g !== void 0 ? _g : true);
    const [changeActive, setChangeActive] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
    useEffect(() => {
        if (campaigns.length > 0)
            return;
        setIsCampaignsLoading(true);
        api.GET_campaigns(10000, 1, undefined, undefined, "name", undefined, 1)
            .then(response => {
            const campaign = response.data.campaigns.filter(campaign => campaign.active === true);
            setCampaigns(campaign);
            setIsCampaignsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsCampaignsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (changeActive && condition) {
            updateCondition();
            setChangeActive(false);
        }
    }, [changeActive]);
    useEffect(() => {
        if (isOpened && status === "new") {
            setNewStatus(null);
            setNewPriority(null);
            setUnchangedFor(null);
            setUnchangedPriorities([]);
            setUnchnagedStatuses([]);
            setChosenCampaign(null);
            setIsSaving(false);
        }
    }, [isOpened]);
    const createCondition = () => {
        setIsSaving(true);
        api.CREATE_ticketCondition(isOptionOn, chosenCampaign.id, unchangedFor ? unchangedFor : 0, unchangedPriorities.map(prio => prio.id), unchangedStatuses.map(status => status.id), newStatus ? newStatus === null || newStatus === void 0 ? void 0 : newStatus.id : null, newPriority ? newPriority === null || newPriority === void 0 ? void 0 : newPriority.id : null)
            .then(() => {
            if (setIsAdded)
                setIsAdded(true);
            if (setIsOpened)
                setIsOpened(false);
            if (setCondition)
                setCondition(null);
            alert.show(t("conditions.condition-form.successfully-added"), "success");
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                if (setIsAdded)
                    setIsAdded(true);
                if (setIsOpened)
                    setIsOpened(false);
                console.error(error);
                alert.show(t("conditions.condition-form.creating-error-occured"), "error");
            }
        });
    };
    const updateCondition = () => {
        setIsSaving(true);
        api.UPDATE_ticketCondition(condition.id, isOptionOn, chosenCampaign.id, unchangedFor ? unchangedFor : 0, unchangedPriorities.map(prio => prio.id), unchangedStatuses.map(status => status.id), newStatus ? newStatus === null || newStatus === void 0 ? void 0 : newStatus.id : null, newPriority ? newPriority === null || newPriority === void 0 ? void 0 : newPriority.id : null)
            .then(() => {
            if (setIsAdded)
                setIsAdded(true);
            if (setIsOpened)
                setIsOpened(false);
            if (setCondition)
                setCondition(null);
            alert.show(t("conditions.condition-form.successfully-updated"), "success");
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                if (setIsAdded)
                    setIsAdded(true);
                if (setIsOpened)
                    setIsOpened(false);
                console.error(error);
                alert.show(t("conditions.condition-form.updating-error-occured"), "error");
            }
        });
    };
    return (_jsx(Fragment, { children: _jsxs(Dialog, Object.assign({ open: isOpened, onClose: () => {
                setIsOpened(false);
                if (setCondition)
                    setCondition(null);
            } }, { children: [_jsx(DialogTitle, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6, md: 8 }, { children: _jsx(Typography, Object.assign({ sx: { paddingTop: 0.5 }, variant: "h6" }, { children: t("conditions.condition-form.set-condition") })) })), status === "edit" && (_jsx(Grid, Object.assign({ item: true, xs: 6, md: 4 }, { children: _jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        paddingTop: isMobile ? 2 : 0,
                                    } }, { children: _jsx(FormControlLabel, { sx: {
                                            marginRight: 0,
                                        }, control: _jsx(Switch, { color: "secondary", checked: isOptionOn, onChange: () => {
                                                setIsOptionOn(!isOptionOn);
                                                setChangeActive(true);
                                            } }), 
                                        // @ts-ignore
                                        label: t("conditions.conditions-table.active") }) })) })))] })) }), _jsxs(DialogContent, { children: [status === "edit" && !(condition === null || condition === void 0 ? void 0 : condition.on) && (_jsx(Box, Object.assign({ sx: { paddingBottom: 4 } }, { children: _jsx(Alert, Object.assign({ severity: "warning" }, { children: t("conditions.condition-form.condition-disabled") })) }))), _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { options: campaigns, getOptionLabel: campaign => campaign.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: chosenCampaign, onChange: (event, value) => {
                                            setChosenCampaign(value);
                                            setUnchangedPriorities([]);
                                            setUnchnagedStatuses([]);
                                            setNewPriority(null);
                                            setNewStatus(null);
                                        }, loading: isCampaignsLoading, renderOption: (props, campaign) => (_jsx("li", Object.assign({}, props, { children: _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                    } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: campaign.name })) })) }), campaign.id) }))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("conditions.condition-form.for-campaign") + " *", variant: "outlined", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { options: unchangedForOptions, getOptionLabel: option => option === 60
                                            ? "1 " + t("conditions.condition-form.time.hour")
                                            : option === 120
                                                ? "2 " + t("conditions.condition-form.time.hours")
                                                : option === 180
                                                    ? "3 " + t("conditions.condition-form.time.hours")
                                                    : option === 240
                                                        ? "4 " + t("conditions.condition-form.time.hours")
                                                        : option === 300
                                                            ? "5 " + t("conditions.condition-form.time.hours-1")
                                                            : option === 360
                                                                ? "6 " + t("conditions.condition-form.time.hours-1")
                                                                : option === 420
                                                                    ? "7 " + t("conditions.condition-form.time.hours-1")
                                                                    : option === 480
                                                                        ? "8 " + t("conditions.condition-form.time.hours-1")
                                                                        : option === 540
                                                                            ? "9 " + t("conditions.condition-form.time.hours-1")
                                                                            : option === 600
                                                                                ? "10 " + t("conditions.condition-form.time.hours-1")
                                                                                : option === 660
                                                                                    ? "11 " + t("conditions.condition-form.time.hours-1")
                                                                                    : option === 720
                                                                                        ? "12 " + t("conditions.condition-form.time.hours-1")
                                                                                        : option === 1440
                                                                                            ? "1 " + t("conditions.condition-form.time.day")
                                                                                            : option === 2880
                                                                                                ? "2 " + t("conditions.condition-form.time.days")
                                                                                                : option === 4320
                                                                                                    ? "3 " + t("conditions.condition-form.time.days")
                                                                                                    : option === 5760
                                                                                                        ? "4 " + t("conditions.condition-form.time.days")
                                                                                                        : option === 7200
                                                                                                            ? "5 " + t("conditions.condition-form.time.days")
                                                                                                            : option === 8640
                                                                                                                ? "6 " + t("conditions.condition-form.time.days")
                                                                                                                : option === 10080
                                                                                                                    ? "1 " + t("conditions.condition-form.time.week")
                                                                                                                    : option === 20160
                                                                                                                        ? "2 " + t("conditions.condition-form.time.weeks")
                                                                                                                        : "3 " + t("conditions.condition-form.time.weeks"), isOptionEqualToValue: (option, value) => option === value, value: unchangedFor ? unchangedFor : null, onChange: (event, value) => {
                                            setUnchangedFor(value);
                                        }, renderOption: (props, option) => (_jsx("li", Object.assign({}, props, { children: _jsx(Typography, { children: option === 60
                                                    ? "1 " + t("conditions.condition-form.time.hour")
                                                    : option === 120
                                                        ? "2 " + t("conditions.condition-form.time.hours")
                                                        : option === 180
                                                            ? "3 " + t("conditions.condition-form.time.hours")
                                                            : option === 240
                                                                ? "4 " + t("conditions.condition-form.time.hours")
                                                                : option === 300
                                                                    ? "5 " + t("conditions.condition-form.time.hours-1")
                                                                    : option === 360
                                                                        ? "6 " + t("conditions.condition-form.time.hours-1")
                                                                        : option === 420
                                                                            ? "7 " + t("conditions.condition-form.time.hours-1")
                                                                            : option === 480
                                                                                ? "8 " + t("conditions.condition-form.time.hours-1")
                                                                                : option === 540
                                                                                    ? "9 " + t("conditions.condition-form.time.hours-1")
                                                                                    : option === 600
                                                                                        ? "10 " +
                                                                                            t("conditions.condition-form.time.hours-1")
                                                                                        : option === 660
                                                                                            ? "11 " +
                                                                                                t("conditions.condition-form.time.hours-1")
                                                                                            : option === 720
                                                                                                ? "12 " +
                                                                                                    t("conditions.condition-form.time.hours-1")
                                                                                                : option === 1440
                                                                                                    ? "1 " + t("conditions.condition-form.time.day")
                                                                                                    : option === 2880
                                                                                                        ? "2 " + t("conditions.condition-form.time.days")
                                                                                                        : option === 4320
                                                                                                            ? "3 " + t("conditions.condition-form.time.days")
                                                                                                            : option === 5760
                                                                                                                ? "4 " + t("conditions.condition-form.time.days")
                                                                                                                : option === 7200
                                                                                                                    ? "5 " + t("conditions.condition-form.time.days")
                                                                                                                    : option === 8640
                                                                                                                        ? "6 " + t("conditions.condition-form.time.days")
                                                                                                                        : option === 10080
                                                                                                                            ? "1 " + t("conditions.condition-form.time.week")
                                                                                                                            : option === 20160
                                                                                                                                ? "2 " + t("conditions.condition-form.time.weeks")
                                                                                                                                : "3 " + t("conditions.condition-form.time.weeks") }) }))), renderInput: params => (_jsx(TextField, Object.assign({ required: true }, params, { label: t("conditions.condition-form.are-unchanged-for"), variant: "outlined", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { multiple: true, disabled: !chosenCampaign, options: chosenCampaign ? chosenCampaign.ticket_statuses : [], getOptionLabel: status => status.name, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => newStatus ? newStatus.id === option.id : false, value: unchangedStatuses, onChange: (event, value) => {
                                            setUnchnagedStatuses(value);
                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("conditions.condition-form.if-statuses"), variant: "outlined", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { multiple: true, disabled: !chosenCampaign, options: chosenCampaign ? chosenCampaign.ticket_priorities : [], getOptionLabel: priority => priority.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: unchangedPriorities, getOptionDisabled: option => newPriority ? newPriority.id === option.id : false, onChange: (event, value) => {
                                            setUnchangedPriorities(value);
                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("conditions.condition-form.priorities"), variant: "outlined", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { disabled: !chosenCampaign, options: chosenCampaign ? chosenCampaign.ticket_statuses : [], getOptionLabel: status => status.name, getOptionDisabled: option => !!unchangedStatuses.find(s => s.id === option.id), isOptionEqualToValue: (option, value) => option.id === value.id, value: newStatus, onChange: (event, value) => {
                                            setNewStatus(value);
                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("conditions.condition-form.status-after"), variant: "outlined", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { disabled: !chosenCampaign, options: chosenCampaign ? chosenCampaign.ticket_priorities : [], getOptionLabel: priority => priority.name, getOptionDisabled: option => !!unchangedPriorities.find(s => s.id === option.id), isOptionEqualToValue: (option, value) => option.id === value.id, value: newPriority, onChange: (event, value) => {
                                            setNewPriority(value);
                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("conditions.condition-form.priority-after"), variant: "outlined", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) }))] }))] }), _jsx(DialogActions, { children: _jsxs(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: [status === "edit" && (_jsx(Button, Object.assign({ style: { color: "red", float: "left" }, onClick: () => {
                                    if (setIdToDelete && condition)
                                        setIdToDelete(condition.id);
                                } }, { children: t("conditions.condition-form.delete-condition") }))), _jsx(LoadingButton, Object.assign({ type: "button", disableElevation: true, disabled: !unchangedFor ||
                                    (unchangedStatuses.length === 0 &&
                                        unchangedPriorities.length === 0) ||
                                    (!newStatus && !newPriority), variant: "text", color: "primary", loading: isSaving, onClick: () => status === "new" ? createCondition() : updateCondition(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                    ? t("conditions.condition-form.create")
                                    : t("conditions.condition-form.update") }))] })) })] })) }));
}
