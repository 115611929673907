import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Nope from "../../../elements/blocks/nope";
import Intro from "../../../elements/blocks/intro";
import UserForm from "../../../elements/users/user-form";
import UserDelete from "../../../elements/users/user-delete";
import { useApiTenant } from "../../../../api/api-tenant";
export default function UserEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_user(id)
            .then(response => {
            setUser(response.data.tenant_user);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: user
                    ? user.first_name + (user.last_name ? " " + user.last_name : "")
                    : t("users.user") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("users.users"),
                        link: "/settings/users",
                    },
                    {
                        name: user
                            ? user.first_name + (user.last_name ? " " + user.last_name : "")
                            : "",
                        link: "/settings/users/" + id,
                    },
                    {
                        name: t("users.edit-user"),
                        link: "/settings/users/" + id + "/edit",
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : user ? (_jsxs(Fragment, { children: [_jsx(UserForm, { type: "client", status: "edit", user: user }), _jsx(UserDelete, { type: "client", user: user })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("users.errors.not-found") }))] }));
}
