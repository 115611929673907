import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import { useTenancy } from "../../../services/tenancy";
import Chip from "@mui/material/Chip";
export default function KnowledgeCards({ knowledge }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    return (_jsx(Grid, Object.assign({ container: true, spacing: 4, direction: "row" }, { children: knowledge.map(knowledgeCard => {
            return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, xl: 3, style: {
                    display: "flex",
                } }, { children: _jsx(Card, Object.assign({ style: {
                        display: "inherit",
                        flexDirection: "column",
                        flexGrow: 1,
                        height: "100%",
                    } }, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                            navigate(tenancy.link("/knowledge/" + knowledgeCard.id));
                        }, style: {
                            display: "inherit",
                            flexDirection: "column",
                            flexGrow: 1,
                            alignItems: "inherit",
                        } }, { children: _jsx(CardContent, Object.assign({ style: {
                                display: "initial",
                                flexGrow: 1,
                                flexDirection: "column",
                            } }, { children: _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5", gutterBottom: true }, { children: knowledgeCard.title })), knowledgeCard.is_email_template ? (_jsx(Chip, { size: "small", color: "default", label: t("knowledge.knowledge-attributes.email-template") })) : null] }) })) })) })) }), knowledgeCard.id));
        }) })));
}
