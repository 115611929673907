import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Controls from "../elements/blocks/controls";
import Intro from "../elements/blocks/intro";
import Nope from "../elements/blocks/nope";
import { usePrefs } from "../../services/prefs";
import { useTenancy } from "../../services/tenancy";
import { useRoles } from "../../services/roles";
export default function Home() {
    const { t } = useTranslation();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const roles = useRoles();
    const [filteredClients, setFilteredClients] = useState(null);
    const [orderBy, setOrderBy] = useState("alphabet");
    const [search, setSearch] = useState("");
    useEffect(() => {
        if (tenancy.isSubDomain || !(location.pathname === "/"))
            return;
        tenancy.setClient(null);
        roles.setIsAdmin(false);
        roles.setIsManager(false);
        roles.setIsAgent(false);
        roles.setRoleTenant(null);
        roles.setGroupsTenant(null);
        roles.setUserTenant(null);
    }, []);
    useEffect(() => {
        let newClients = [];
        if (search && search !== "") {
            tenancy.clients.map(client => {
                if (client.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                    newClients.push(client);
            });
            setFilteredClients(newClients);
        }
        else {
            setFilteredClients(null);
        }
    }, [search]);
    const getClientCard = (client) => {
        return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, xl: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                        tenancy.getTenantToken(client);
                    } }, { children: _jsx(CardContent, { children: _jsx(Box, Object.assign({ sx: {
                                height: "150px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                gap: "4px",
                            } }, { children: client.logo_url ? (_jsxs(Fragment, { children: [_jsx("img", { src: prefs.theme === "light"
                                            ? client.logo_url
                                            : client.logo_dark_url
                                                ? client.logo_dark_url
                                                : client.logo_url, style: {
                                            maxHeight: process.env.APP_ID === "mcd" ? "90px" : "120px",
                                            maxWidth: "200px",
                                            objectFit: "contain",
                                            marginTop: process.env.APP_ID === "mcd" ? "8px" : "0",
                                        }, alt: "Logo" }), process.env.APP_ID === "mcd" && (_jsx(Typography, Object.assign({ variant: "h6", sx: {
                                            fontWeight: "400",
                                        } }, { children: client.name })))] })) : (_jsx(Typography, Object.assign({ variant: "h6" }, { children: client.name }))) })) }) })) }) }), client.id));
    };
    return tenancy.isSubDomain ? (_jsx(Navigate, { to: "/dashboard" })) : (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("home.message." + process.env.APP_ID) }), _jsx(Controls, { orderOptions: [
                    {
                        label: t("home.alphabet"),
                        value: "alphabet",
                    },
                ], disableOrder: true, order: orderBy, setOrder: setOrderBy, enableSearch: true, search: search, setSearch: setSearch }), filteredClients !== null ? (_jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: filteredClients.length > 0 ? (filteredClients === null || filteredClients === void 0 ? void 0 : filteredClients.sort((a, b) => a.name.localeCompare(b.name)).map(client => getClientCard(client))) : (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Nope, { caption: t("home.no-clients") }) }))) }))) : tenancy.clients.length > 0 ? (_jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: tenancy.clients
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(client => getClientCard(client)) }))) : (_jsx(Nope, { title: t("errors.unauthorized"), caption: t("errors.no-permission-to-access-clients") }))] }));
}
