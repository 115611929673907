import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, Fragment } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { isIE } from "react-device-detect";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import * as Sentry from "@sentry/react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Frame from "./layout/frame";
import Error401 from "./pages/errors/401";
import Error404 from "./pages/errors/404";
import { useTracking, TrackingProvider } from "../services/tracking";
import { useAuth, AuthProvider } from "../services/auth";
import { useTenancy, TenancyProvider } from "../services/tenancy";
import { useRoles, RolesProvider } from "../services/roles";
import { ScriptsProvider } from "../services/scripts";
import { AlertProvider } from "../services/alert";
import { publicRoutes, generalRoutes, tenantRoutes } from "../routes";
export function Router() {
    const { t } = useTranslation();
    return isIE ? (_jsx(Fragment, { children: t("errors.incompatible-browser") })) : (_jsx(BrowserRouter, { children: _jsx(AuthProvider, { children: _jsx(TenancyProvider, { children: _jsx(RolesProvider, { children: _jsx(TrackingProvider, { children: _jsx(ScriptsProvider, { children: _jsx(AlertProvider, { children: _jsx(I18nextProvider, Object.assign({ i18n: i18next }, { children: _jsx(Pages, {}) })) }) }) }) }) }) }) }));
}
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
function Pages() {
    const { pathname } = useLocation();
    const auth = useAuth();
    const tenancy = useTenancy();
    const roles = useRoles();
    useTracking();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return auth.isLoading ? (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsx(Frame, { children: tenancy.isLoading || roles.isLoading ? (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsxs(SentryRoutes, { children: [publicRoutes.map((route, index) => (_jsx(Route, { path: route.path, element: _jsx(route.element, {}) }, index))), generalRoutes.map((route, index) => {
                    var _a;
                    return (_jsx(Route, { path: route.path, element: route.roles.includes((_a = roles.roleCentral) !== null && _a !== void 0 ? _a : "") ? (_jsx(route.element, {})) : (_jsx(Error401, {})) }, index));
                }), tenantRoutes.map((route, index) => {
                    var _a, _b;
                    return (_jsx(Route, { path: tenancy.isSubDomain
                            ? route.path
                            : "/" + ((_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.domain) + route.path, element: route.roles.includes((_b = roles.roleTenant) !== null && _b !== void 0 ? _b : "") ? (_jsx(route.element, {})) : (_jsx(Error401, {})) }, index));
                }), _jsx(Route, { path: "*", element: _jsx(Error404, {}) })] })) }));
}
