import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
export default function InputCalculation({ label, required, value, setValue, valueLabel, targetLabel, resultLabel, changeLabel, unit, showInputs = true, info, disabled, error, errors, isError, setIsError, }) {
    const [previousValue, setPreviousValue] = useState(value.previous_number);
    const [currentValue, setCurrentValue] = useState(value.current_number);
    const [targetValue, setTargetValue] = useState(value.target_number);
    const [newValue, setNewValue] = useState("");
    const [formula, setFormula] = useState("addition");
    const [isTargetDisabled, setIsTargetDisabled] = useState(targetValue !== "" && targetValue !== null);
    const [calcChange, setCalcChange] = useState("");
    const [calcProgress, setCalcProgress] = useState(0);
    useEffect(() => {
        const previousValueAsFloat = isNaN(parseFloat(previousValue !== null && previousValue !== void 0 ? previousValue : ""))
            ? 0
            : parseFloat(previousValue !== null && previousValue !== void 0 ? previousValue : "");
        const newValueAsFloat = isNaN(parseFloat(newValue)) ? 0 : parseFloat(newValue);
        const result = formula === "addition"
            ? previousValueAsFloat + newValueAsFloat
            : previousValueAsFloat - newValueAsFloat;
        setCurrentValue(result.toString());
    }, [newValue, formula]);
    useEffect(() => {
        const currentValueAsFloat = isNaN(parseFloat(currentValue !== null && currentValue !== void 0 ? currentValue : ""))
            ? 0
            : parseFloat(currentValue !== null && currentValue !== void 0 ? currentValue : "");
        const targetValueAsFloat = isNaN(parseFloat(targetValue !== null && targetValue !== void 0 ? targetValue : ""))
            ? null
            : parseFloat(targetValue !== null && targetValue !== void 0 ? targetValue : "");
        if (targetValueAsFloat) {
            setCalcChange((targetValueAsFloat - currentValueAsFloat).toString());
            setCalcProgress(targetValueAsFloat > 0
                ? Math.round((currentValueAsFloat / targetValueAsFloat) * 100)
                : currentValueAsFloat < 0
                    ? -1
                    : 100);
        }
        setValue({
            previous_number: value.previous_number,
            current_number: currentValueAsFloat,
            target_number: targetValueAsFloat,
        });
    }, [currentValue, targetValue]);
    useEffect(() => {
        setPreviousValue(value.previous_number);
        setCurrentValue(value.current_number);
        setTargetValue(value.target_number);
        setIsTargetDisabled(value.target_number !== "" && value.target_number !== null);
    }, [value.previous_number]);
    return (_jsxs(Fragment, { children: [label && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: label }) }))), _jsx(Grid, Object.assign({ container: true }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: 2,
                        } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                    flexGrow: 1,
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginY: 1,
                                        } }, { children: _jsx(LinearProgress, { variant: "determinate", value: calcProgress < 0
                                                ? 0
                                                : calcProgress > 100
                                                    ? 100
                                                    : calcProgress, color: calcProgress < 0 || calcProgress > 100 ? "error" : "success" }) })), _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginTop: 0.5,
                                        } }, { children: [_jsxs(Typography, Object.assign({ variant: "body2" }, { children: [_jsx(NumberFormat, { value: currentValue !== null && currentValue !== void 0 ? currentValue : "0", displayType: "text", thousandSeparator: ".", decimalSeparator: ",", decimalScale: 2, isNumericString: true }), ` ${unit !== null && unit !== void 0 ? unit : ""} (${resultLabel.toLowerCase()})`] })), targetValue && (_jsxs(Typography, Object.assign({ variant: "body2" }, { children: [_jsx(NumberFormat, { value: calcChange, displayType: "text", thousandSeparator: ".", decimalSeparator: ",", decimalScale: 2, isNumericString: true }), ` ${unit !== null && unit !== void 0 ? unit : ""} (${changeLabel.toLowerCase()})`] })))] }))] })), _jsx(Box, Object.assign({ sx: {
                                    marginLeft: 1,
                                } }, { children: _jsxs(Typography, Object.assign({ variant: "body2" }, { children: [targetValue ? (_jsx(NumberFormat, { value: targetValue, displayType: "text", thousandSeparator: ".", decimalSeparator: ",", decimalScale: 2, isNumericString: true })) : ("∞"), _jsx(Box, Object.assign({ component: "span", sx: {
                                                marginLeft: 0.5,
                                            } }, { children: `${unit !== null && unit !== void 0 ? unit : ""} (${targetLabel.toLowerCase()})` }))] })) }))] })) })) })), showInputs && (_jsxs(Fragment, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, Object.assign({ sx: {
                                        display: "flex",
                                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                marginRight: 1,
                                            } }, { children: _jsxs(ToggleButtonGroup, Object.assign({ exclusive: true, value: formula, onChange: (event, newFormula) => setFormula(newFormula), disabled: disabled ? disabled : false, orientation: "vertical", size: "small" }, { children: [_jsx(ToggleButton, Object.assign({ value: "addition", sx: {
                                                            paddingTop: "3.5px",
                                                            paddingBottom: "3.5px",
                                                        } }, { children: _jsx(AddIcon, { sx: {
                                                                fontSize: "1.2rem",
                                                            } }) })), _jsx(ToggleButton, Object.assign({ value: "subtraction", sx: {
                                                            paddingTop: "3.5px",
                                                            paddingBottom: "3.5px",
                                                        } }, { children: _jsx(RemoveIcon, { sx: {
                                                                fontSize: "1.2rem",
                                                            } }) }))] })) })), _jsxs(FormControl, Object.assign({ disabled: disabled ? disabled : false, fullWidth: true, sx: {
                                                marginRight: "0 !important;",
                                            } }, { children: [_jsx(InputLabel, { children: valueLabel }), _jsx(NumberFormat, { disabled: disabled ? disabled : false, label: valueLabel, value: newValue, endAdornment: unit ? (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: unit }))) : undefined, customInput: OutlinedInput, thousandSeparator: ".", decimalSeparator: ",", decimalScale: 2, allowNegative: false, isNumericString: true, onValueChange: values => setNewValue(values.value) })] }))] })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: required, disabled: disabled ? disabled : isTargetDisabled, fullWidth: true, error: isError && errors && error
                                            ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)
                                            : false }, { children: [_jsx(InputLabel, { children: targetLabel }), _jsx(NumberFormat, { disabled: disabled ? disabled : isTargetDisabled, label: targetLabel, required: required, value: targetValue, endAdornment: _jsxs(InputAdornment, Object.assign({ position: "end" }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                marginRight: 0.5,
                                                            } }, { children: unit !== null && unit !== void 0 ? unit : "" })), _jsx(IconButton, Object.assign({ edge: "end", disabled: disabled ? disabled : false, onClick: () => setIsTargetDisabled(!isTargetDisabled) }, { children: isTargetDisabled ? (_jsx(LockIcon, { fontSize: "small" })) : (_jsx(LockOpenIcon, { fontSize: "small" })) }))] })), customInput: OutlinedInput, thousandSeparator: ".", decimalSeparator: ",", decimalScale: 2, allowNegative: false, isNumericString: true, onValueChange: values => {
                                                    setTargetValue(values.value);
                                                    if (isError &&
                                                        errors &&
                                                        error &&
                                                        setIsError &&
                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                                        setIsError(false);
                                                } })] })), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] })))] }))] })), info && _jsx(FormHelperText, { children: info })] }))] }));
}
