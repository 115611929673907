import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PreviewIcon from "@mui/icons-material/Visibility";
import InputText from "../inputs/input-text";
import InputLink from "../inputs/input-link";
import InputDatetime from "../inputs/input-datetime";
import InputAddress from "../inputs/input-address";
import InputNumber from "../inputs/input-number";
import InputCheckbox from "../inputs/input-checkbox";
import InputRating from "../inputs/input-rating";
import InputSwitch from "../inputs/input-switch";
import InputSlider from "../inputs/input-slider";
import InputRadio from "../inputs/input-radio";
import InputTextArea from "../inputs/input-textarea";
import InputAutocomplete from "../inputs/input-autocomplete";
import InputFile from "../inputs/input-file";
import InputPhoneField from "../inputs/input-phone-field";
import InputEditor from "../inputs/input-editor";
import InputCalculation from "../inputs/input-calculation";
import InputConversation from "../inputs/input-conversation";
import { prettifyText } from "../../../helpers/clean";
export default function CustomFieldPreview({ type, name, caption, required, options, isStep, ratingStep, maxValue, minValue, icon, emptyIcon, maxValueLabel, minValueLabel, dateTimeType, valueLabel, targetLabel, resultLabel, changeLabel, unit, multiple, maxItems, }) {
    const { t } = useTranslation();
    const [text, setText] = useState("");
    const [number, setNumber] = useState("");
    const [date, setDate] = useState("");
    const [textArea, setTextArea] = useState("");
    const [address, setAddress] = useState(null);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
    const [checkedRadio, setCheckedRadio] = useState("");
    const [switcher, setSwitcher] = useState(false);
    const [rating, setRating] = useState(0);
    const [slider, setSlider] = useState(20);
    const [range, setRange] = useState([20, 40]);
    const [phone, setPhone] = useState(undefined);
    const [link, setLink] = useState("");
    const [calculation, setCalculation] = useState({
        previous_number: null,
        current_number: null,
        target_number: null,
    });
    const [conversation, setConversation] = useState("");
    const [iconSelected, setIconSelected] = useState("");
    const [imageSelected, setImageSelected] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    useEffect(() => {
        if (minValue && maxValue)
            setRange([Number(minValue), Number(maxValue)]);
    }, [minValue, maxValue]);
    const previewForm = () => {
        return (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [type === "text" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputText, { label: (name
                            ? prettifyText(name)
                            : t("fields.field-form.field-types.text")) +
                            (required ? " *" : ""), caption: caption, required: false, value: text, setValue: setText }) }))), type === "text_area" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputTextArea, { label: (name
                            ? prettifyText(name)
                            : t("fields.field-form.field-types.text-area")) +
                            (required ? " *" : ""), caption: caption, required: false, value: textArea, setValue: setTextArea, rows: 4 }) }))), type === "number" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputNumber, { label: (name
                            ? prettifyText(name)
                            : t("fields.field-form.field-types.number")) +
                            (required ? " *" : ""), caption: caption, required: false, value: number, setValue: setNumber }) }))), type === "date_time" && dateTimeType && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputDatetime, { type: dateTimeType, label: (name
                            ? prettifyText(name)
                            : t("fields.field-form.field-types.date-time")) +
                            (required ? " *" : ""), caption: caption, required: false, value: date, setValue: setDate }) }))), type === "text_select" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputAutocomplete, { label: (name
                            ? prettifyText(name)
                            : t("fields.field-form.field-types.text-select")) +
                            (required ? " *" : ""), caption: caption, options: options ? options : [], value: checkedRadio ? checkedRadio : "", setValue: value => {
                            setCheckedRadio(value !== undefined ? value.value.toString() : "");
                        } }) }))), type === "checkbox" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputCheckbox, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.checkbox")) +
                            (required ? " *" : ""), required: false, options: options ? options : [], checkedValues: checkedCheckboxes, setCheckedValues: setCheckedCheckboxes }) }))), type === "radio_text" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputRadio, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.radio-text")) +
                            (required ? " *" : ""), required: false, type: "text", direction: "vertical", options: options ? options : [], value: checkedRadio, setValue: setCheckedRadio }) }))), type === "radio_icon" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputRadio, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.radio-icon")) +
                            (required ? " *" : ""), required: false, type: "icon", direction: "horizontal", value: iconSelected, setValue: setIconSelected, options: options ? options : [] }) }))), type === "radio_image" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputRadio, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.radio-image")) +
                            (required ? " *" : ""), required: false, type: "image", direction: "horizontal", options: options ? options : [], value: imageSelected, setValue: setImageSelected }) }))), type === "html_text" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.html-text")) +
                            (required ? " *" : ""), required: false, value: textArea, setValue: setTextArea }) }))), type === "switch" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.switch")) +
                            (required ? " *" : ""), required: false, checked: switcher, setChecked: setSwitcher }) }))), type === "address" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputAddress, { address: address, setAddress: setAddress, caption: caption, label: (name
                            ? prettifyText(name)
                            : t("fields.field-form.field-types.address")) +
                            (required ? " *" : "") }) }))), type === "rating" && ratingStep && maxValue && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputRating, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.rating")) +
                            (required ? " *" : ""), required: required ? required : false, value: rating, icon: icon, emptyIcon: emptyIcon, setValue: setRating, precision: Number(ratingStep), max: maxValue ? Number(maxValue) : 1, size: "medium" }) }))), type === "slider" && maxValue && minValue && ratingStep && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSlider, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.slider")) +
                            (required ? " *" : ""), required: required ? required : false, value: slider, setValue: setSlider, min: minValue ? Number(minValue) : 1, max: maxValue ? Number(maxValue) : 1, step: Number(ratingStep), marks: (minValueLabel && minValueLabel !== "") ||
                            (maxValueLabel && maxValueLabel !== "")
                            ? [
                                {
                                    value: minValue,
                                    label: minValueLabel ? minValueLabel : "",
                                },
                                {
                                    value: maxValue,
                                    label: maxValueLabel ? maxValueLabel : "",
                                },
                            ]
                            : [] }) }))), type === "range" && maxValue && minValue && ratingStep && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSlider, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.range")) +
                            (required ? " *" : ""), required: required, value: range, setValue: setRange, min: minValue ? Number(minValue) : 1, max: maxValue ? Number(maxValue) : 100, step: Number(ratingStep), marks: (minValueLabel && minValueLabel !== "") ||
                            (maxValueLabel && maxValueLabel !== "")
                            ? [
                                {
                                    value: minValue,
                                    label: minValueLabel ? minValueLabel : "",
                                },
                                {
                                    value: maxValue,
                                    label: maxValueLabel ? maxValueLabel : "",
                                },
                            ]
                            : [] }) }))), type === "file" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputFile, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.file")) +
                            (required ? " *" : ""), files: uploadedFiles, setFiles: setUploadedFiles, multiple: multiple, maxItems: maxItems }) }))), type === "link" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputLink, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.link")) +
                            (required ? " *" : ""), required: false, value: link, setValue: setLink, disabled: false }) }))), type === "phone" && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputPhoneField, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.phone")) +
                            (required ? " *" : ""), phone: phone, setPhone: setPhone }) }))), type === "mutable_number" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputCalculation, { label: (caption
                            ? prettifyText(caption)
                            : name
                                ? prettifyText(name)
                                : t("fields.field-form.field-types.calculation")) +
                            (required ? " *" : ""), value: calculation, setValue: setCalculation, required: required !== null && required !== void 0 ? required : false, valueLabel: valueLabel !== null && valueLabel !== void 0 ? valueLabel : "", targetLabel: targetLabel !== null && targetLabel !== void 0 ? targetLabel : "", resultLabel: resultLabel !== null && resultLabel !== void 0 ? resultLabel : "", changeLabel: changeLabel !== null && changeLabel !== void 0 ? changeLabel : "", unit: unit !== null && unit !== void 0 ? unit : "" }) }))), type === "conversation" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputConversation, { label: name
                            ? prettifyText(name)
                            : t("fields.field-form.field-types.conversation"), caption: caption, required: required !== null && required !== void 0 ? required : false, value: conversation, setValue: setConversation, rows: 4 }) })))] })));
    };
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: !isStep ? (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(PreviewIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.field-preview.preview") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: (type === "text"
                                                ? t("fields.field-form.field-types.text")
                                                : type === "text_area"
                                                    ? t("fields.field-form.field-types.text-area")
                                                    : type === "number"
                                                        ? t("fields.field-form.field-types.number")
                                                        : type === "date_time"
                                                            ? t("fields.field-form.field-types.date-time")
                                                            : type === "text_select"
                                                                ? t("fields.field-form.field-types.text-select")
                                                                : type === "checkbox"
                                                                    ? t("fields.field-form.field-types.checkbox")
                                                                    : type === "radio_text"
                                                                        ? t("fields.field-form.field-types.radio-text")
                                                                        : type === "radio_icon"
                                                                            ? t("fields.field-form.field-types.radio-icon")
                                                                            : type === "radio_image"
                                                                                ? t("fields.field-form.field-types.radio-image")
                                                                                : type === "switch"
                                                                                    ? t("fields.field-form.field-types.switch")
                                                                                    : type === "html_text"
                                                                                        ? t("fields.field-form.field-types.html-text")
                                                                                        : type === "file"
                                                                                            ? t("fields.field-form.field-types.file")
                                                                                            : type === "rating"
                                                                                                ? t("fields.field-form.field-types.rating")
                                                                                                : type === "slider"
                                                                                                    ? t("fields.field-form.field-types.slider")
                                                                                                    : type === "range"
                                                                                                        ? t("fields.field-form.field-types.range")
                                                                                                        : type === "address"
                                                                                                            ? t("fields.field-form.field-types.address")
                                                                                                            : type === "link"
                                                                                                                ? t("fields.field-form.field-types.link")
                                                                                                                : type === "mutable_number"
                                                                                                                    ? t("fields.field-form.field-types.calculation")
                                                                                                                    : type === "conversation"
                                                                                                                        ? t("fields.field-form.field-types.conversation")
                                                                                                                        : t("fields.field-form.field-types.phone")) +
                                                " " +
                                                t("fields.field").toLowerCase() }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: previewForm() })) }) }))] }))) : (_jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                        padding: 4,
                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                paddingBottom: 4,
                            } }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: t("fields.field-preview.preview") })) })), previewForm()] })) })) })) }));
}
