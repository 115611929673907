import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Settings";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import Table from "../blocks/table";
import NotificationOptionForm from "./notification-option-form";
import NotificationOptionDelete from "./notification-option-delete";
import { useApiTenant } from "../../../api/api-tenant";
import { useTenancy } from "../../../services/tenancy";
export default function NotificationsTable({ title, isAdded, setIsAdded, type }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const tenancy = useTenancy();
    const navigate = useNavigate();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [tableQuery, setTableQuery] = useState();
    const [isEditOpened, setIsEditOpened] = useState(false);
    const [optionToEdit, setOptionToEdit] = useState(null);
    const [idToDelete, setIdToDelete] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (isAdded || isDeleted) {
            refreshTableData();
            if (isAdded)
                setIsAdded(false);
            if (isDeleted) {
                setIsDeleted(false);
                setIsEditOpened(false);
                setOptionToEdit(null);
            }
        }
    }, [isAdded, isDeleted]);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_notificationOptions(query.pageSize, query.page + 1, query.orderDirection ? query.orderDirection : null, type)
                .then(response => {
                resolve({
                    data: response.data.ticket_notification_options,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    return (_jsxs(Fragment, { children: [_jsx(Table, { title: title, actions: [
                    {
                        icon: () => _jsx(EditIcon, { color: "action" }),
                        tooltip: t("notifications.notifications-table.edit-notification"),
                        onClick: (event, data) => {
                            setOptionToEdit(data);
                            setIsEditOpened(true);
                        },
                    },
                    {
                        icon: () => _jsx(RefreshIcon, { color: "action" }),
                        tooltip: t("notifications.notifications-table.refresh-options"),
                        isFreeAction: true,
                        onClick: () => refreshTableData(),
                    },
                ], columns: isMobile
                    ? [
                        {
                            title: t("notifications.notification-option") + " #",
                            field: "id",
                            defaultSort: "desc",
                        },
                    ]
                    : [
                        {
                            maxWidth: "80px",
                            title: "#",
                            field: "id",
                            type: "numeric",
                            defaultSort: "desc",
                            render: option => {
                                return (_jsxs(Box, Object.assign({ sx: {
                                        display: "flex",
                                    } }, { children: [_jsx(Tooltip
                                        // @ts-ignore
                                        , Object.assign({ 
                                            // @ts-ignore
                                            title: option.on
                                                ? t("notifications.notifications-table.active")
                                                : t("notifications.notifications-table.inactive") }, { children: _jsx(DotIcon, { style: { paddingTop: 4 }, fontSize: "small", color: option.on ? "secondary" : "disabled" }) })), _jsx(Box, Object.assign({ sx: { paddingLeft: 1 } }, { children: option.id }))] })));
                            },
                        },
                        {
                            title: t("notifications.notifications-table.event"),
                            sorting: false,
                            render: notification => {
                                return notification.notify_event === "created"
                                    ? t("notifications.notification-option-form.new")
                                    : notification.notify_event === "updated"
                                        ? t("notifications.notification-option-form.existing")
                                        : notification.notify_event === "created_or_updated"
                                            ? t("notifications.notification-option-form.new-existing")
                                            : notification.notify_event === "public_activity_created"
                                                ? t("notifications.notification-option-form.new-public-activity")
                                                : notification.notify_event === "scheduled"
                                                    ? t("notifications.notification-option-form.scheduled-ticket")
                                                    : t("notifications.notification-option-form.due-date-ticket");
                            },
                        },
                        {
                            title: t("notifications.notifications-table.campaigns"),
                            sorting: false,
                            render: notification => {
                                return notification.campaigns.length === 0 ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-campaign"), size: "small", variant: "filled" }) }))) : (notification.campaigns.map(campaign => {
                                    return (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { label: campaign.name, onClick: () => {
                                                navigate(tenancy.link("/campaigns/" + campaign.id));
                                            }, size: "small", variant: "outlined" }, campaign.id) }), campaign.id));
                                }));
                            },
                        },
                        {
                            title: t("queues.queues"),
                            sorting: false,
                            render: notification => {
                                return notification.groups.length > 0 ? (notification.groups.map(group => {
                                    return (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { label: group.name, size: "small", variant: "outlined" }) }), group.id));
                                })) : notification.groups_condition_type === "any" ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-group"), size: "small", variant: "filled" }) }))) : notification.groups_condition_type === "none" ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.none-group"), size: "small", variant: "filled" }) }))) : (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-or-none"), size: "small", variant: "filled" }) })));
                            },
                        },
                        {
                            title: t("notifications.notifications-table.statuses"),
                            sorting: false,
                            render: notification => {
                                return notification.ticket_detail_statuses.length === 0 ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-status"), size: "small", variant: "filled" }) }))) : (notification.ticket_detail_statuses.map(status => {
                                    return (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { label: status.name, size: "small", style: {
                                                border: "1px solid " +
                                                    (status.type === "pending"
                                                        ? "rgba(156, 39, 176, 1)"
                                                        : status.type === "resolved"
                                                            ? "rgba(76, 175, 80, 1)"
                                                            : "rgba(37, 64, 146, 1)"),
                                                backgroundColor: status.type === "pending"
                                                    ? "rgba(156, 39, 176, 0.2)"
                                                    : status.type === "resolved"
                                                        ? "rgba(76, 175, 80, 0.2)"
                                                        : "rgba(37, 64, 146, 0.2)",
                                            } }, status.id) }), status.id));
                                }));
                            },
                        },
                        {
                            title: t("notifications.notifications-table.priorities"),
                            sorting: false,
                            render: notification => {
                                return notification.ticket_detail_priorities.length === 0 ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-priority"), size: "small", variant: "filled" }) }))) : (notification.ticket_detail_priorities.map(priority => {
                                    return (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { label: priority.name, size: "small", style: {
                                                border: "1px solid " +
                                                    (priority.level < 33
                                                        ? "rgb(244, 67, 54, 1)"
                                                        : priority.level >= 33 &&
                                                            priority.level <= 66
                                                            ? "rgb(255, 152, 0, 1)"
                                                            : "rgb(33, 150, 243, 1)"),
                                                backgroundColor: priority.level < 33
                                                    ? "rgb(244, 67, 54, 0.2)"
                                                    : priority.level >= 33 &&
                                                        priority.level <= 66
                                                        ? "rgb(255, 152, 0, 0.2)"
                                                        : "rgb(33, 150, 243, 0.2)",
                                            } }, priority.id) }), priority.id));
                                }));
                            },
                        },
                        {
                            title: t("notifications.notifications-table.recipients"),
                            sorting: false,
                            hidden: type === "user",
                            render: notification => {
                                return notification.recipients_condition_type !== null &&
                                    notification.recipients_condition_type !== "specific" ? (_jsx(Box, Object.assign({ sx: {
                                        marginTop: "4px",
                                        marginBottom: "4px",
                                        marginRight: "8px",
                                    } }, { children: _jsx(Chip, { label: notification.recipients_condition_type ===
                                            "created_by"
                                            ? t("notifications.notification-option-form.created-by")
                                            : notification.recipients_condition_type ===
                                                "updated_by"
                                                ? t("notifications.notification-option-form.updated-by")
                                                : notification.recipients_condition_type ===
                                                    "contact"
                                                    ? t("notifications.notification-option-form.contact")
                                                    : t("notifications.notification-option-form.assigned-to"), size: "small", variant: "filled" }) }))) : (notification.recipients.map(recipient => {
                                    return (_jsx(Box, Object.assign({ sx: {
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            marginRight: "8px",
                                        } }, { children: _jsx(Chip, { label: (recipient.first_name
                                                ? recipient.first_name + " "
                                                : "") +
                                                (recipient.last_name
                                                    ? recipient.last_name + " "
                                                    : ""), onClick: () => navigate(tenancy.link("/settings/users/" +
                                                recipient.id)), size: "small", variant: "outlined" }, recipient.id) }), recipient.id));
                                }));
                            },
                        },
                    ], detailPanel: (option) => {
                    return (_jsx(Box, Object.assign({ sx: {
                            paddingX: isMobile ? 2 : 6,
                            paddingY: 3,
                        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.event") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: option.notify_event === "created"
                                                ? t("notifications.notification-option-form.new")
                                                : option.notify_event === "updated"
                                                    ? t("notifications.notification-option-form.existing")
                                                    : option.notify_event === "created_or_updated"
                                                        ? t("notifications.notification-option-form.new-existing")
                                                        : option.notify_event === "public_activity_created"
                                                            ? t("notifications.notification-option-form.new-public-activity")
                                                            : option.notify_event === "scheduled"
                                                                ? t("notifications.notification-option-form.scheduled-ticket")
                                                                : t("notifications.notification-option-form.due-date-ticket") }))] }))), isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.campaigns") })), option.campaigns.length === 0 ? (_jsx(Box, Object.assign({ sx: {
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-campaign"), size: "small", variant: "filled" }) }))) : (option.campaigns.map(campaign => {
                                            return (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { label: campaign.name, size: "small", variant: "outlined" }, campaign.id) }), campaign.id));
                                        }))] }))), isMobile && option.groups && option.groups.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("groups.groups") })), option.groups.map(group => {
                                            return (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { label: group.name, size: "small", variant: "outlined" }, group.id) }), group.id));
                                        })] }))), isMobile && option.ticket_detail_statuses && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.statuses") })), option.ticket_detail_statuses.length === 0 ? (_jsx(Box, Object.assign({ sx: {
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-status"), size: "small", variant: "filled" }) }))) : (option.ticket_detail_statuses.map(status => {
                                            return (_jsx(Box, Object.assign({ sx: {
                                                    paddingY: 0.5,
                                                } }, { children: _jsx(Chip, { size: "small", label: status.name, style: {
                                                        border: "1px solid " +
                                                            (status.type === "pending"
                                                                ? "rgba(156, 39, 176, 1)"
                                                                : status.type === "resolved"
                                                                    ? "rgba(76, 175, 80, 1)"
                                                                    : "rgba(37, 64, 146, 1)"),
                                                        backgroundColor: status.type === "pending"
                                                            ? "rgba(156, 39, 176, 0.2)"
                                                            : status.type === "resolved"
                                                                ? "rgba(76, 175, 80, 0.2)"
                                                                : "rgba(37, 64, 146, 0.2)",
                                                    } }) }), status.id));
                                        }))] }))), isMobile && option.ticket_detail_priorities && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.priorities") })), option.ticket_detail_priorities.length === 0 ? (_jsx(Box, Object.assign({ sx: {
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { label: t("notifications.notification-option-form.any-priority"), size: "small", variant: "filled" }) }))) : (option.ticket_detail_priorities.map(priority => {
                                            return (_jsx(Box, Object.assign({ sx: {
                                                    paddingY: 0.5,
                                                } }, { children: _jsx(Chip, { size: "small", label: priority.name, style: {
                                                        border: "1px solid " +
                                                            (priority.level < 33
                                                                ? "rgb(244, 67, 54, 1)"
                                                                : priority.level >= 33 &&
                                                                    priority.level <= 66
                                                                    ? "rgb(255, 152, 0, 1)"
                                                                    : "rgb(33, 150, 243, 1)"),
                                                        backgroundColor: priority.level < 33
                                                            ? "rgb(244, 67, 54, 0.2)"
                                                            : priority.level >= 33 &&
                                                                priority.level <= 66
                                                                ? "rgb(255, 152, 0, 0.2)"
                                                                : "rgb(33, 150, 243, 0.2)",
                                                    } }) }), priority.id));
                                        }))] }))), isMobile &&
                                    type === "tenant" &&
                                    (option.recipients.length > 0 ||
                                        option.recipients_condition_type !== null) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.recipients") })), option.recipients_condition_type !== null &&
                                            option.recipients_condition_type !== "specific" ? (_jsx(Box, Object.assign({ sx: {
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { label: option.recipients_condition_type ===
                                                    "created_by"
                                                    ? t("notifications.notification-option-form.created-by")
                                                    : option.recipients_condition_type ===
                                                        "updated_by"
                                                        ? t("notifications.notification-option-form.updated-by")
                                                        : option.recipients_condition_type ===
                                                            "contact"
                                                            ? t("notifications.notification-option-form.contact")
                                                            : t("notifications.notification-option-form.assigned-to"), size: "small", variant: "filled" }) }))) : (option.recipients.map(recipient => {
                                            return (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    marginRight: "8px",
                                                } }, { children: _jsx(Chip, { label: (recipient.first_name
                                                        ? recipient.first_name + " "
                                                        : "") +
                                                        (recipient.last_name
                                                            ? recipient.last_name + " "
                                                            : ""), onClick: () => navigate(tenancy.link("/settings/users/" +
                                                        recipient.id)), size: "small", variant: "outlined" }, recipient.id) }), recipient.id));
                                        }))] }))), option.assigned_to_user && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notification-option-form.assigned-to-me") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: option.assigned_to_user ? t("yes") : t("no") }))] }))), (option.notify_event === "scheduled" ||
                                    option.notify_event === "due") && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.alert") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: option.alert_advance_minutes === 0
                                                ? option.notify_event === "scheduled"
                                                    ? t("notifications.notification-option-form.time.at-scheduled-date-time")
                                                    : t("notifications.notification-option-form.time.at-due-date-time")
                                                : option.alert_advance_minutes === 5
                                                    ? "5 " +
                                                        t("notifications.notification-option-form.time.minutes-before")
                                                    : option.alert_advance_minutes === 10
                                                        ? "10 " +
                                                            t("notifications.notification-option-form.time.minutes-before")
                                                        : option.alert_advance_minutes === 15
                                                            ? "15 " +
                                                                t("notifications.notification-option-form.time.minutes-before")
                                                            : option.alert_advance_minutes === 30
                                                                ? "30 " +
                                                                    t("notifications.notification-option-form.time.minutes-before")
                                                                : option.alert_advance_minutes === 60
                                                                    ? t("notifications.notification-option-form.time.1-hour")
                                                                    : option.alert_advance_minutes === 120
                                                                        ? t("notifications.notification-option-form.time.2-hours")
                                                                        : option.alert_advance_minutes === 1440
                                                                            ? t("notifications.notification-option-form.time.1-day")
                                                                            : option.alert_advance_minutes === 2880
                                                                                ? t("notifications.notification-option-form.time.2-days")
                                                                                : t("notifications.notification-option-form.time.1-week") }))] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.type") })), option.via_email && (_jsx(Box, Object.assign({ sx: {
                                                display: "inline-block",
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { size: "small", label: t("notifications.notifications-table.via-email") }) }))), option.via_web && (_jsx(Box, Object.assign({ sx: {
                                                display: "inline-block",
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                marginRight: "8px",
                                            } }, { children: _jsx(Chip, { size: "small", label: t("notifications.notifications-table.via-web") }) })))] })), option.via_email && option.email_options && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("notifications.notifications-table.content") })), option.email_options.map((option, key) => {
                                            return (_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: option === "toggle_message"
                                                        ? t("notifications.notification-option-form.extended-email-options.toggle-message")
                                                        : option === "contact"
                                                            ? t("notifications.notification-option-form.extended-email-options.contact")
                                                            : option === "information"
                                                                ? t("notifications.notification-option-form.extended-email-options.information")
                                                                : option === "details"
                                                                    ? t("notifications.notification-option-form.extended-email-options.details")
                                                                    : option === "custom_subject"
                                                                        ? t("notifications.notification-option-form.extended-email-options.custom-subject")
                                                                        : option === "custom_content"
                                                                            ? t("notifications.notification-option-form.extended-email-options.custom-content")
                                                                            : t("notifications.notification-option-form.extended-email-options.button-sections") })) }, key));
                                        })] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(option.created_at)).toLocaleDateString("en-GB") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(option.updated_at)).toLocaleDateString("en-GB") }))] }))] })) })));
                }, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery, disableSearch: true }), optionToEdit && (_jsx(NotificationOptionForm, { status: "edit", option: optionToEdit ? optionToEdit : undefined, setOption: setOptionToEdit, isOpened: isEditOpened, setIsOpened: setIsEditOpened, setIsAdded: setIsAdded, setIdToDelete: setIdToDelete, type: type })), idToDelete !== "" && (_jsx(NotificationOptionDelete, { idToDelete: idToDelete, setIdToDelete: setIdToDelete, setIsDeleted: setIsDeleted, type: type }))] }));
}
