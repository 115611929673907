import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../../elements/blocks/intro";
import CustomFieldsTable from "../../../../../elements/custom-fields/custom-fields-table";
export default function FormFields() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("fields.shared-fields.form-fields.form-fields"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("fields.shared-fields.shared-fields"),
                        link: "/settings/fields/shared",
                    },
                    {
                        name: t("fields.shared-fields.form-fields.forms"),
                        link: "/settings/fields/shared/forms",
                    },
                ], button: {
                    label: t("fields.shared-fields.form-fields.new-form-field"),
                    link: "/settings/fields/shared/forms/new",
                    color: "secondary",
                    type: "new",
                } }), _jsx(CustomFieldsTable, { type: "campaign_steps", shared: true })] }));
}
