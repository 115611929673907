import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import GroupsTree from "../../../elements/groups/groups-tree";
export default function Groups() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("groups.groups"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("groups.groups"),
                        link: "/settings/groups",
                    },
                ] }), _jsx(GroupsTree, { type: "client" })] }));
}
