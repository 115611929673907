import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Pie, Bar, Doughnut, Radar, PolarArea } from "react-chartjs-2";
import { format, isValid, startOfWeek, endOfWeek, startOfMonth, startOfYear } from "date-fns";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import RefreshIcon from "@mui/icons-material/Refresh";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ViewIcon from "@mui/icons-material/Visibility";
import MetricPartitionModal from "./metric-partition-modal";
import MetricCustomRangeModal from "./metric-custom-range-modal";
import { prettifyText } from "../../../helpers/clean";
import { usePrefs } from "../../../services/prefs";
export default function MetricPartition({ title, metricData, range, setRange, isLoading, setIsLoading, cachedAt, refresh, setRefresh, customFieldId, setCustomFieldRefresh, startDate, setStartDate, startTime, setStartTime, endDate, setEndDate, endTime, setEndTime, setResetDateTimeValues, globalRange, setGlobalRange, setIsChosenCustomRange, isModal, type, metricType, isError, statuses, priorities, chosenStatuses, setChosenStatuses, chosenPriorities, setChosenPriorities, }) {
    const { t } = useTranslation();
    const smallScreen = useMediaQuery("(max-width: 950px)");
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [openSelectCustomRange, setOpenSelectCustomRange] = useState(false);
    const [openCustomFieldCustomRange, setOpenCustomFieldCustomRange] = useState("");
    const [beginning, setBeginning] = useState("");
    const [end, setEnd] = useState("");
    const [chartType, setChartType] = useState("pie");
    const [isOpened, setIsOpened] = useState(false);
    const [labelsFromData, setLabelsFromData] = useState([]);
    const [valuesFromData, setValuesFromData] = useState([]);
    const [colorsFromData, setColorsFromData] = useState([]);
    const [orderBy, setOrderBy] = useState("desc");
    const [numberOfResults, setNumberOfResults] = useState("-1");
    const prefs = usePrefs();
    const useStyles = makeStyles({
        title: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "320px",
        },
    });
    const classes = useStyles();
    let backgroundColor;
    let borderColor;
    if (metricData) {
        backgroundColor = metricData.map(data => {
            return "rgba(" + data.color + ", 0.2)";
        });
        borderColor = metricData.map(data => {
            return "rgba(" + data.color + ", 1)";
        });
    }
    else {
        backgroundColor = [
            "rgba(244, 67, 54, 0.2)",
            "rgba(156, 39, 176, 0.2)",
            "rgba(33, 150, 243, 0.2)",
            "rgba(76, 175, 80, 0.2)",
            "rgba(255, 235, 59, 0.2)",
            "rgba(255, 152, 0, 0.2)",
            "rgba(121, 85, 72, 0.2)",
            "rgba(96, 125, 139, 0.2)",
        ];
        borderColor = [
            "rgba(244, 67, 54, 1)",
            "rgba(156, 39, 176, 1)",
            "rgba(33, 150, 243, 1)",
            "rgba(76, 175, 80, 1)",
            "rgba(255, 235, 59, 1)",
            "rgba(255, 152, 0, 1)",
            "rgba(121, 85, 72, 1)",
            "rgba(96, 125, 139, 1)",
        ];
    }
    const data = {
        labels: labelsFromData.map(label => prettifyText(label)),
        datasets: [
            {
                label: chartType === "radar" ? prettifyText(title) : undefined,
                data: valuesFromData,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1.5,
            },
        ],
    };
    const plugins = [
        {
            beforeInit(chart) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                    originalFit.bind(chart.legend)();
                    this.height += 20;
                };
            },
        },
    ];
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        animation: { duration: 0 },
        layout: {
            autoPadding: 0,
        },
        plugins: {
            paddingBelowLegends: true,
            legend: {
                after: 1,
                display: !(chartType === "bar" || chartType === "radar" || !isModal),
                position: isModal ? "top" : "right",
                labels: {
                    boxWidth: 15,
                    textAlign: "left",
                },
            },
            tooltip: {
                callbacks: {
                    label: context => {
                        return context.label;
                    },
                },
            },
        },
    };
    const reduceNumberOfResults = (data) => {
        if (numberOfResults === "-1")
            return data;
        return data.slice(0, parseInt(numberOfResults));
    };
    useEffect(() => {
        if (!metricData || metricData.length === 0)
            return;
        setIsLoading(true);
        let sortedData;
        if (orderBy === "desc") {
            sortedData = metricData.sort((a, b) => a.count - b.count).reverse();
        }
        else if (orderBy === "asc") {
            sortedData = metricData.sort((a, b) => a.count - b.count);
        }
        else if (orderBy === "alphabet") {
            sortedData = metricData.sort((a, b) => a.label.localeCompare(b.label));
        }
        else {
            sortedData = metricData.sort((a, b) => a.label.localeCompare(b.label)).reverse();
        }
        setLabelsAndValues(sortedData);
        setIsLoading(false);
    }, [orderBy]);
    useEffect(() => {
        if (startDate === "")
            setStartTime("");
        if (endDate === "")
            setEndTime("");
    }, [startDate, endDate]);
    useEffect(() => {
        let today = new Date();
        if (range !== "custom") {
            if (range === "yesterday") {
                let yesterday = format(new Date(today.getTime() - 86400000), "yyyy-MM-dd");
                setBeginning(yesterday.toString());
                setEnd(yesterday.toString());
            }
            else if (range === "day") {
                setBeginning(format(today, "yyyy-MM-dd").toString());
                setEnd(today.toString());
            }
            else if (range === "this_week") {
                let start = format(startOfWeek(today, { weekStartsOn: 1 }), "yyyy-MM-dd");
                setBeginning(start.toString());
                setEnd(today.toString());
            }
            else if (range === "last_week") {
                let sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
                const start = startOfWeek(sevenDaysBeforeDate, { weekStartsOn: 1 });
                const end = endOfWeek(sevenDaysBeforeDate, { weekStartsOn: 1 });
                setBeginning(format(start, "yyyy-MM-dd").toString());
                setEnd(format(end, "yyyy-MM-dd").toString());
            }
            else if (range === "this_month") {
                const start = startOfMonth(today);
                setBeginning(format(start, "yyyy-MM-dd").toString());
                setEnd(today.toString());
            }
            else if (range === "last_month") {
                const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                setBeginning(format(firstDayPrevMonth, "yyyy-MM-dd").toString());
                setEnd(format(lastDayPrevMonth, "yyyy-MM-dd").toString());
            }
            else if (range === "this_year") {
                const start = startOfYear(today);
                setBeginning(format(start, "yyyy-MM-dd").toString());
                setEnd(today.toString());
            }
            else if (range === "last_year") {
                const firstDayPrevYear = new Date(today.getFullYear() - 1, 0, 1);
                const lastDayPrevYear = new Date(today.getFullYear(), 0, 0);
                setBeginning(format(firstDayPrevYear, "yyyy-MM-dd").toString());
                setEnd(format(lastDayPrevYear, "yyyy-MM-dd").toString());
            }
            else {
                setBeginning(today.toString());
                setEnd(today.toString());
            }
        }
    }, [range, refresh]);
    useEffect(() => {
        if (metricData && (metricData === null || metricData === void 0 ? void 0 : metricData.length) > 0) {
            setLabelsAndValues(metricData);
        }
        else {
            setValuesFromData([]);
            setLabelsFromData([]);
            setColorsFromData([]);
        }
    }, [metricData, numberOfResults]);
    const setLabelsAndValues = (sortedData) => {
        sortedData = reduceNumberOfResults(sortedData);
        setValuesFromData(sortedData.map(a => a.count));
        setColorsFromData(sortedData.map(a => a.color));
        let cnt = 0;
        sortedData.map(a => (cnt = cnt + a.count));
        let statusLabels = [];
        if (type && type !== "campaign" && type !== "campaign_group") {
            statusLabels.push("Total (" + cnt + " - 100%)");
        }
        sortedData.map(a => statusLabels.push(metricType === "status" ||
            metricType === "priority" ||
            metricType === "social_channel" ||
            metricType === "information"
            ? a.label + " (" + a.count + " ~ " + ((a.count / cnt) * 100).toFixed(0) + "%)"
            : metricType === "new_contact"
                ? a.label === "1"
                    ? t("tickets.metrics.new") +
                        " (" +
                        a.count +
                        " ~ " +
                        ((a.count / cnt) * 100).toFixed(0) +
                        "%)"
                    : t("tickets.metrics.existing") +
                        " (" +
                        a.count +
                        " ~ " +
                        ((a.count / cnt) * 100).toFixed(0) +
                        "%)"
                : metricType === "channel"
                    ? (a.label === "personally"
                        ? t("tickets.ticket-activities.ticket-activity-form.channel-types.personally")
                        : a.label === "post"
                            ? t("tickets.ticket-activities.ticket-activity-form.channel-types.post")
                            : a.label === "social"
                                ? t("tickets.ticket-activities.ticket-activity-form.channel-types.social")
                                : a.label === "none"
                                    ? t("tickets.ticket-activities.ticket-activity-form.channel-types.none")
                                    : a.label === "email"
                                        ? t("tickets.ticket-activities.ticket-activity-form.channel-types.email")
                                        : t("tickets.ticket-activities.ticket-activity-form.channel-types.phone")) +
                        " (" +
                        a.count +
                        " ~ " +
                        ((a.count / cnt) * 100).toFixed(0) +
                        "%)"
                    : metricType === "forwarded_calls"
                        ? a.label == "1"
                            ? t("tickets.metrics.forwarded") +
                                " (" +
                                a.count +
                                " ~ " +
                                ((a.count / cnt) * 100).toFixed(0) +
                                "%)"
                            : t("tickets.metrics.not-forwarded") +
                                " (" +
                                a.count +
                                " ~ " +
                                ((a.count / cnt) * 100).toFixed(0) +
                                "%)"
                        : metricType === "contacts"
                            ? (a.label === "business"
                                ? t("contacts.contact-attributes.type.business")
                                : a.label === "person"
                                    ? t("contacts.contact-attributes.type.person")
                                    : t("contacts.contact-attributes.type.none")) +
                                " (" +
                                a.count +
                                " ~ " +
                                ((a.count / cnt) * 100).toFixed(0) +
                                "%)"
                            : metricType === "users"
                                ? (a.label === "Agent"
                                    ? t("users.roles.agent")
                                    : a.label === "Manager"
                                        ? t("users.roles.manager")
                                        : t("users.roles.admin")) +
                                    " (" +
                                    a.count +
                                    " ~ " +
                                    ((a.count / cnt) * 100).toFixed(0) +
                                    "%)"
                                : metricType === "audience"
                                    ? (a.label === "pending"
                                        ? t("campaigns.audiences.leads.lead-attributes.status-types.pending")
                                        : a.label === "invalid"
                                            ? t("campaigns.audiences.leads.lead-attributes.status-types.invalid")
                                            : a.label === "completed"
                                                ? t("campaigns.audiences.leads.lead-attributes.status-types.completed")
                                                : t("campaigns.audiences.leads.lead-attributes.status-types.checking")) +
                                        " (" +
                                        a.count +
                                        " ~ " +
                                        ((a.count / cnt) * 100).toFixed(0) +
                                        "%)"
                                    : ""));
        setLabelsFromData(statusLabels);
    };
    const displayCard = () => {
        return (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                        display: !isMobile || (isMobile && !isModal) ? "flex" : "inline",
                        justifyContent: !isMobile || (isMobile && !isModal) ? "space-between" : "normal",
                        alignItems: "center",
                        marginBottom: 2,
                    } }, { children: [_jsx(Box, Object.assign({ className: !isModal ? classes.title : undefined, sx: { marginRight: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: title })) })), _jsx(Box, Object.assign({ sx: {
                                paddingTop: isMobile && isModal ? 2 : 0,
                                display: !isMobile && isModal ? "flex" : "inline",
                                justifyContent: !isMobile && isModal ? "space-between" : "normal",
                                alignItems: "center",
                            } }, { children: _jsxs(Fragment, { children: [isModal && (_jsxs(Fragment, { children: [statuses &&
                                                chosenStatuses &&
                                                setChosenStatuses &&
                                                metricType !== "status" && (_jsx(Box, Object.assign({ sx: {
                                                    display: !isMobile ? "flex" : "inline",
                                                    justifyContent: !isMobile
                                                        ? "flex-start"
                                                        : "normal",
                                                    paddingRight: !isMobile ? 2 : 0,
                                                    width: "200px",
                                                } }, { children: _jsx(Autocomplete, { fullWidth: true, multiple: true, options: statuses, getOptionLabel: status => status.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: chosenStatuses, onChange: (event, value) => {
                                                        setChosenStatuses(value);
                                                        setRefresh(true);
                                                        if (setCustomFieldRefresh &&
                                                            customFieldId) {
                                                            setCustomFieldRefresh(customFieldId);
                                                        }
                                                    }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ size: "small", label: option.name }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { placeholder: t("tickets.ticket-attributes.status"), fullWidth: true }))) }) }))), priorities &&
                                                chosenPriorities &&
                                                setChosenPriorities &&
                                                metricType !== "priority" && (_jsx(Box, Object.assign({ sx: {
                                                    display: !isMobile ? "flex" : "inline",
                                                    justifyContent: !isMobile
                                                        ? "flex-start"
                                                        : "normal",
                                                    paddingRight: !isMobile ? 2 : 0,
                                                    width: "200px",
                                                } }, { children: _jsx(Autocomplete, { fullWidth: true, multiple: true, options: priorities, getOptionLabel: priority => priority.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: chosenPriorities, onChange: (event, value) => {
                                                        setChosenPriorities(value);
                                                        setRefresh(true);
                                                        if (setCustomFieldRefresh &&
                                                            customFieldId) {
                                                            setCustomFieldRefresh(customFieldId);
                                                        }
                                                    }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ size: "small", label: option.name }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { placeholder: t("tickets.ticket-attributes.priority"), fullWidth: true }))) }) }))), _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsxs(Select, Object.assign({ disabled: isLoading, variant: "standard", value: orderBy, onChange: event => {
                                                        setOrderBy(event.target.value);
                                                    } }, { children: [_jsx(MenuItem, Object.assign({ value: "desc" }, { children: t("metrics.desc") })), _jsx(MenuItem, Object.assign({ value: "asc" }, { children: t("metrics.asc") })), _jsx(MenuItem, Object.assign({ value: "alphabet" }, { children: t("metrics.alphabetically") })), _jsx(MenuItem, Object.assign({ value: "desc-alphabet" }, { children: t("metrics.desc-alphabetically") }))] })) })), _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsxs(Select, Object.assign({ disabled: isLoading, variant: "standard", value: numberOfResults, onChange: event => {
                                                        setNumberOfResults(event.target.value);
                                                    } }, { children: [_jsx(MenuItem, Object.assign({ value: "-1" }, { children: t("metrics.all-results") })), _jsxs(MenuItem, Object.assign({ value: "3" }, { children: [t("metrics.top"), " 3"] })), _jsxs(MenuItem, Object.assign({ value: "5" }, { children: [t("metrics.top"), " 5"] })), _jsxs(MenuItem, Object.assign({ value: "10" }, { children: [t("metrics.top"), " 10"] })), _jsxs(MenuItem, Object.assign({ value: "15" }, { children: [t("metrics.top"), " 15"] })), _jsxs(MenuItem, Object.assign({ value: "20" }, { children: [t("metrics.top"), " 20"] }))] })) })), _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsxs(Select, Object.assign({ disabled: isLoading, variant: "standard", value: chartType, onChange: event => {
                                                        setChartType(event.target.value);
                                                    } }, { children: [_jsx(MenuItem, Object.assign({ value: "bar" }, { children: t("metrics.types.bar") })), _jsx(MenuItem, Object.assign({ value: "pie" }, { children: t("metrics.types.pie") })), _jsx(MenuItem, Object.assign({ value: "doughnut" }, { children: t("metrics.types.doughnut") })), _jsx(MenuItem, Object.assign({ value: "polarArea" }, { children: t("metrics.types.polar") })), _jsx(MenuItem, Object.assign({ value: "radar" }, { children: t("metrics.types.radar") }))] })) }))] })), !isModal && (_jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        } }, { children: _jsxs(Button, Object.assign({ color: "primary", onClick: () => {
                                                setIsOpened(true);
                                                if (setCustomFieldRefresh)
                                                    setCustomFieldRefresh(customFieldId);
                                            }, style: {
                                                padding: 0,
                                                fontSize: "12px",
                                            } }, { children: [_jsx(ViewIcon, { fontSize: "small", style: { marginRight: 4 } }), t("metrics.view-report")] })) }))), range === "custom" && isModal && (_jsx(Box, Object.assign({ sx: {
                                            paddingRight: 1,
                                        } }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: () => {
                                                if (!customFieldId) {
                                                    setOpenSelectCustomRange(true);
                                                }
                                                else {
                                                    setOpenCustomFieldCustomRange(customFieldId);
                                                }
                                                if (setResetDateTimeValues)
                                                    setResetDateTimeValues(true);
                                                if (setCustomFieldRefresh)
                                                    setCustomFieldRefresh(customFieldId);
                                                if (globalRange &&
                                                    setGlobalRange &&
                                                    globalRange !== "none") {
                                                    setGlobalRange("none");
                                                }
                                            } }, { children: _jsx(DateRangeIcon, { fontSize: "small", color: "action" }) })) }))), isModal && (_jsxs(Select, Object.assign({ disabled: isLoading, variant: "standard", value: range, onChange: event => {
                                            setRange(event.target.value);
                                            if (setCustomFieldRefresh && customFieldId) {
                                                setCustomFieldRefresh(customFieldId);
                                            }
                                            if (event.target.value === "custom" && !customFieldId) {
                                                setOpenSelectCustomRange(true);
                                            }
                                            if (event.target.value === "custom" && customFieldId) {
                                                setOpenCustomFieldCustomRange(customFieldId);
                                            }
                                            if (event.target.value !== "custom") {
                                                setIsLoading(true);
                                            }
                                        } }, { children: [_jsx(MenuItem, Object.assign({ value: "custom" }, { children: t("metrics.range-options.custom.custom") })), _jsx(MenuItem, Object.assign({ value: "day" }, { children: t("metrics.range-options.today") })), _jsx(MenuItem, Object.assign({ value: "yesterday" }, { children: t("metrics.range-options.yesterday") })), _jsx(MenuItem, Object.assign({ value: "this_week" }, { children: t("metrics.range-options.this-week") })), _jsx(MenuItem, Object.assign({ value: "last_week" }, { children: t("metrics.range-options.last-week") })), _jsx(MenuItem, Object.assign({ value: "this_month" }, { children: t("metrics.range-options.this-month") })), _jsx(MenuItem, Object.assign({ value: "last_month" }, { children: t("metrics.range-options.last-month") })), _jsx(MenuItem, Object.assign({ value: "this_year" }, { children: t("metrics.range-options.this-year") })), _jsx(MenuItem, Object.assign({ value: "last_year" }, { children: t("metrics.range-options.last-year") })), _jsx(MenuItem, Object.assign({ value: "total" }, { children: t("metrics.range-options.total") }))] })))] }) }))] })), _jsx(Box, Object.assign({ sx: {
                        position: "relative",
                        height: isModal ? "510px" : "150px",
                        width: "100%",
                    } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        } }, { children: _jsx(CircularProgress, { color: "primary", size: isModal ? 60 : 30 }) }))) : isError ? (_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: isModal ? "center" : "flex-start",
                            alignItems: isModal ? "center" : "flex-end",
                            height: isModal ? "465px" : "130px",
                            marginTop: 1,
                        } }, { children: _jsx(Typography, Object.assign({ variant: isModal ? "subtitle1" : "caption", color: "textSecondary" }, { children: t("metrics.error-occured") })) }))) : range === "custom" &&
                        (startDate === "" || endDate === "" || openSelectCustomRange) ? (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-end",
                                height: isModal ? "500px" : "135px",
                            } }, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: t("metrics.enter-range-options") })) })) })) : valuesFromData.length > 0 &&
                        labelsFromData.length > 0 &&
                        colorsFromData.length > 0 ? (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ sx: {
                                    display: !isModal ? "flex" : "inline",
                                    justifyContent: !isModal ? "space-between" : "normal",
                                } }, { children: [!isModal && (_jsx(Box, Object.assign({ sx: {
                                            flexGrow: 1,
                                            height: "100px",
                                            overflow: "auto",
                                            marginRight: 2,
                                        } }, { children: labelsFromData.map((label, key) => {
                                            return (_jsx(Box, { children: _jsx(Typography, Object.assign({ variant: "caption" }, { children: prettifyText(label) })) }, key));
                                        }) }))), _jsx(Box, Object.assign({ sx: {
                                            width: isModal ? "100%" : "100px",
                                            height: isModal && isMobile
                                                ? "445px"
                                                : isModal && !isMobile
                                                    ? "465px"
                                                    : "105px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        } }, { children: _jsx(Box, Object.assign({ sx: {
                                                paddingTop: isMobile && isModal ? 2 : 0,
                                                paddingBottom: isMobile && isModal ? 2 : 0,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                width: isModal
                                                    ? chartType === "bar"
                                                        ? valuesFromData.length <= 2
                                                            ? "300px"
                                                            : valuesFromData.length > 2 &&
                                                                valuesFromData.length < 5
                                                                ? "500px"
                                                                : "100%"
                                                        : "100%"
                                                    : "100px",
                                                height: isModal
                                                    ? valuesFromData.length <= 2 && chartType !== "bar"
                                                        ? "300px"
                                                        : valuesFromData.length > 2 &&
                                                            valuesFromData.length < 5 &&
                                                            chartType !== "bar"
                                                            ? "300px"
                                                            : isModal && valuesFromData.length >= 5
                                                                ? "465px"
                                                                : "465px"
                                                    : "100px",
                                            } }, { children: chartType === "bar" ? (_jsx(Bar, { data: data, 
                                                // @ts-ignore
                                                options: options, height: 100, 
                                                // @ts-ignore
                                                plugins: plugins })) : chartType === "pie" ? (_jsx(Pie, { data: data, 
                                                // @ts-ignore
                                                options: options, height: 100, 
                                                // @ts-ignore
                                                plugins: plugins })) : chartType === "doughnut" ? (_jsx(Doughnut, { data: data, 
                                                // @ts-ignore
                                                options: options, height: 100, 
                                                // @ts-ignore
                                                plugins: plugins })) : chartType === "radar" ? (_jsx(Radar, { data: data, 
                                                // @ts-ignore
                                                options: options, height: 100, 
                                                // @ts-ignore
                                                plugins: plugins })) : (_jsx(PolarArea, { data: data, 
                                                // @ts-ignore
                                                options: options, height: 100, 
                                                // @ts-ignore
                                                plugins: plugins })) })) }))] })), cachedAt !== "" && (_jsxs(Grid, Object.assign({ container: true, style: { marginBottom: 2 }, direction: "column" }, { children: [((range !== "custom" &&
                                        beginning !== "" &&
                                        end !== "" &&
                                        isValid(new Date(beginning))) ||
                                        (range === "custom" &&
                                            startDate !== "" &&
                                            endDate !== "")) && (_jsx(Grid, Object.assign({ item: true, xs: 7, sm: 7, md: 7 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: smallScreen && !isMobile
                                                    ? "flex-start"
                                                    : "center",
                                                marginTop: 1,
                                                flexDirection: "row",
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: range === "custom"
                                                        ? t("metrics.results-from") +
                                                            new Date(Date.parse(startDate)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(startTime !== ""
                                                                ? startTime
                                                                : startDate
                                                                    ? format(new Date(startDate), "yyyy-MM-dd") + " 00:00:00"
                                                                    : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }) +
                                                            " - "
                                                        : range === "total"
                                                            ? t("metrics.results-from") +
                                                                t("metrics.beginning")
                                                            : new Date(Date.parse(beginning)).toLocaleDateString("en-GB") + " - " })), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", style: {
                                                        paddingLeft: "4px",
                                                        paddingRight: "4px",
                                                    } }, { children: range === "custom"
                                                        ? new Date(Date.parse(endDate)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(endTime !== ""
                                                                ? endTime
                                                                : endDate
                                                                    ? format(new Date(endDate), "yyyy-MM-dd") + " 23:59:59"
                                                                    : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })
                                                        : new Date(Date.parse(end)).toLocaleDateString("en-GB") }))] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 5, sm: 5, md: 5 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: [t("metrics.cached-at"), new Date(Date.parse(cachedAt)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(cachedAt)).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })] })), _jsxs(Button, Object.assign({ size: "small", onClick: () => {
                                                        setRefresh(true);
                                                        if (setCustomFieldRefresh && customFieldId) {
                                                            setCustomFieldRefresh(customFieldId);
                                                        }
                                                    }, style: {
                                                        padding: 0,
                                                        marginLeft: "5px",
                                                        color: prefs.theme === "light"
                                                            ? "#00000099"
                                                            : "#ffffffb3",
                                                        fontSize: "11px",
                                                        textTransform: "none",
                                                    } }, { children: [_jsx(RefreshIcon, { fontSize: "small", style: { marginRight: 2 }, color: "action" }), t("metrics.refresh-data")] }))] })) }))] })))] })) : (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: isModal ? "center" : "flex-start",
                                    alignItems: isModal ? "center" : "flex-end",
                                    height: isModal && isMobile
                                        ? "445px"
                                        : isModal && !isMobile
                                            ? "465px"
                                            : "100px",
                                } }, { children: _jsx(Typography, Object.assign({ variant: isModal ? "subtitle1" : "caption" }, { children: t("metrics.no-records") })) })), cachedAt !== "" && (_jsxs(Grid, Object.assign({ container: true, style: { marginBottom: 2 }, direction: "column" }, { children: [((range !== "custom" &&
                                        beginning !== "" &&
                                        isValid(new Date(beginning)) &&
                                        end !== "") ||
                                        (range === "custom" &&
                                            startDate !== "" &&
                                            endDate !== "")) && (_jsx(Grid, Object.assign({ item: true, xs: 7, sm: 7, md: 7 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: (smallScreen && !isMobile) ||
                                                    range === "custom"
                                                    ? "flex-start"
                                                    : "center",
                                                marginTop: isMobile ? 0 : 1,
                                                flexDirection: "row",
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: range === "custom"
                                                        ? t("metrics.results-from") +
                                                            new Date(Date.parse(startDate)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(startTime !== ""
                                                                ? startTime
                                                                : startDate
                                                                    ? format(new Date(startDate), "yyyy-MM-dd") + " 00:00:00"
                                                                    : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }) +
                                                            " - "
                                                        : range === "total"
                                                            ? t("metrics.results-from") +
                                                                t("metrics.beginning")
                                                            : t("metrics.results-from") +
                                                                new Date(Date.parse(beginning)).toLocaleDateString("en-GB") +
                                                                " - " })), _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary", style: {
                                                        paddingLeft: "4px",
                                                        paddingRight: "4px",
                                                    } }, { children: range === "custom"
                                                        ? new Date(Date.parse(endDate)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(endTime !== ""
                                                                ? endTime
                                                                : endDate
                                                                    ? format(new Date(endDate), "yyyy-MM-dd") + " 23:59:59"
                                                                    : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })
                                                        : new Date(Date.parse(end)).toLocaleDateString("en-GB") }))] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 5, sm: 5, md: 5 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            } }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", color: "textSecondary" }, { children: [t("metrics.cached-at"), new Date(Date.parse(cachedAt)).toLocaleDateString("en-GB") +
                                                            " " +
                                                            new Date(Date.parse(cachedAt)).toLocaleTimeString("hr-HR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })] })), _jsxs(Button, Object.assign({ size: "small", onClick: () => {
                                                        setRefresh(true);
                                                        if (setCustomFieldRefresh && customFieldId) {
                                                            setCustomFieldRefresh(customFieldId);
                                                        }
                                                    }, style: {
                                                        padding: 0,
                                                        marginLeft: "5px",
                                                        color: prefs.theme === "light"
                                                            ? "#00000099"
                                                            : "#ffffffb3",
                                                        fontSize: "11px",
                                                        textTransform: "none",
                                                    } }, { children: [_jsx(RefreshIcon, { fontSize: "small", style: { marginRight: 2 }, color: "action" }), t("metrics.refresh-data")] }))] })) }))] })))] })) }))] }));
    };
    return (_jsxs(Fragment, { children: [isModal ? (displayCard()) : (_jsx(Card, { children: _jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        flexDirection: "column",
                        paddingX: 3,
                        paddingTop: 2.5,
                        paddingBottom: 3,
                    } }, { children: displayCard() })) })), _jsx(MetricPartitionModal, { isOpened: isOpened, setIsOpened: setIsOpened, title: title, metricData: metricData ? metricData : [], range: range, setRange: setRange, isLoading: isLoading, setIsLoading: setIsLoading, cachedAt: cachedAt, refresh: refresh, setRefresh: setRefresh, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, startTime: startTime, setStartTime: setStartTime, endTime: endTime, setEndTime: setEndTime, setIsChosenCustomRange: setIsChosenCustomRange, setResetDateTimeValues: setResetDateTimeValues, globalRange: globalRange, setGlobalRange: setGlobalRange, customFieldId: customFieldId, setCustomFieldRefresh: setCustomFieldRefresh, metricType: metricType, isError: isError, statuses: statuses, priorities: priorities, chosenStatuses: chosenStatuses, setChosenStatuses: setChosenStatuses, chosenPriorities: chosenPriorities, setChosenPriorities: setChosenPriorities }), _jsx(MetricCustomRangeModal, { startDate: startDate, setStartDate: setStartDate, startTime: startTime, setStartTime: setStartTime, endDate: endDate, setEndDate: setEndDate, endTime: endTime, setEndTime: setEndTime, customFieldId: customFieldId, setIsChosenCustomRange: setIsChosenCustomRange, openCustomFieldCustomRange: openCustomFieldCustomRange, setOpenCustomFieldCustomRange: setOpenCustomFieldCustomRange, openSelectCustomRange: openSelectCustomRange, setOpenSelectCustomRange: setOpenSelectCustomRange })] }));
}
