import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import UserDetails from "../../elements/users/user-details";
import { useRoles } from "../../../services/roles";
export default function Account() {
    const { t } = useTranslation();
    const roles = useRoles();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("users.account"), breadcrumbs: [
                    {
                        name: t("users.account"),
                        link: "/account",
                    },
                ], button: {
                    label: t("users.edit-account"),
                    link: "/account/edit",
                    color: "primary",
                    type: "edit",
                } }), roles.userTenant && _jsx(UserDetails, { user: roles.userTenant, type: "account" })] }));
}
