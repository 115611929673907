import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { useAlert } from "../../../../services/alert";
import { copy } from "../../../../helpers/copy";
export default function ContactEmailsColumn({ emails }) {
    const { t } = useTranslation();
    const alert = useAlert();
    return (_jsx(Fragment, { children: emails.length > 0 &&
            emails.map(email => {
                return (_jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        paddingY: 0.5,
                    } }, { children: _jsx(Chip, { style: {
                            display: "flex",
                            overflow: "hidden",
                        }, variant: "outlined", size: "small", label: email.email, onDelete: () => {
                            copy(email.email);
                            alert.show(t("email-copied"), "info");
                        }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                            // @ts-ignore
                            title: t("copy-email") }, { children: _jsx(CopyIcon, {}) })) }) }), email.id));
            }) }));
}
