import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
export default function InputSelect({ label, required, options, value, setValue, multiple, info, disabled, error, errors, isError, setIsError, }) {
    return (_jsxs(FormControl, Object.assign({ fullWidth: true, disabled: disabled ? disabled : false, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(InputLabel, { children: label }), _jsx(Select, Object.assign({ disabled: disabled ? disabled : false, required: required, label: label, value: value, multiple: multiple ? multiple : false, onChange: event => {
                    setValue(event.target.value);
                    if (isError && errors && error && setIsError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                        setIsError(false);
                } }, { children: options.map(option => {
                    return (_jsx(MenuItem, Object.assign({ value: option.value }, { children: option.label }), option.value));
                }) })), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] })));
}
