import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useRoles } from "../../../services/roles";
import TicketSteps from "../../elements/tickets/ticket-steps";
import Intro from "../../elements/blocks/intro";
import ContactForm from "../../elements/contacts/contact-form";
import ContactHistory from "../../elements/contacts/contact-history";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
export default function TicketContact() {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useApiTenant();
    const tenancy = useTenancy();
    const alert = useAlert();
    const roles = useRoles();
    const [isLoading, setIsLoading] = useState(true);
    const [ticket, setTicket] = useState(null);
    const [updatedContact, setUpdatedContact] = useState(false);
    const [historyToDelete, setHistoryToDelete] = useState("");
    useEffect(() => {
        api.GET_ticket(id)
            .then(response => {
            setTicket(response.data.ticket);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [historyToDelete]);
    useEffect(() => {
        if (!updatedContact || !ticket)
            return;
        alert.show(t("tickets.ticket-contact.ticket-contact-updated"), "success");
        if (ticket.is_draft) {
            if (ticket.campaign.has_steps) {
                navigate(tenancy.link("/tickets/" + id + "/information"));
            }
            else {
                navigate(tenancy.link("/tickets/" + id + "/details"));
            }
        }
        else
            navigate(tenancy.link("/tickets/" + id));
    }, [updatedContact]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.ticket") + " #" + id, breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: ticket ? ticket.campaign.name : t("campaigns.campaign"),
                        link: "/campaigns/" + (ticket ? ticket.campaign.id : ""),
                    },
                    {
                        name: t("tickets.ticket") + " #" + id,
                        link: "/tickets/" + id,
                    },
                ], loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : ticket ? (_jsxs(Fragment, { children: [_jsx(TicketSteps, { step: "contact", campaign: ticket.campaign, ticket: ticket }), _jsx(ContactForm, { kind: "ticket", status: "edit", campaign: ticket.campaign, contact: ticket.contact, setUpdatedContact: setUpdatedContact, ticketDraft: ticket.is_draft, newContact: ticket.contact_is_new }), (roles.isAdmin || roles.isManager) &&
                        ticket.contact.history &&
                        ticket.contact.history.length > 0 && (_jsx(ContactHistory, { status: "edit", contact: ticket.contact, historyToDelete: historyToDelete, setHistoryToDelete: setHistoryToDelete }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("tickets.ticket-not-found") }))] }));
}
