import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import BlacklistTable from "../../../elements/blacklist/blacklist-table";
import BlacklistForm from "../../../elements/blacklist/blacklist-form";
export default function Blacklist() {
    const { t } = useTranslation();
    const [isAddOpened, setIsAddOpened] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("blacklist.blacklisted-phones"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("blacklist.blacklisted-phones"),
                        link: "/settings/blacklist",
                    },
                ], button: {
                    label: t("blacklist.add-blacklisted-phone"),
                    action: () => setIsAddOpened(true),
                    color: "secondary",
                    type: "new",
                } }), _jsx(BlacklistTable, { type: "client", isAdded: isAdded, setIsAdded: setIsAdded }), _jsx(BlacklistForm, { type: "client", isAddOpened: isAddOpened, setIsAddOpened: setIsAddOpened, setIsAdded: setIsAdded })] }));
}
