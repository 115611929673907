import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Intro from "../../elements/blocks/intro";
import Nope from "../../elements/blocks/nope";
export default function Error401() {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, 700);
        return () => clearTimeout(timer);
    }, []);
    return (_jsxs(Box, Object.assign({ sx: {
            display: isShown ? "block" : "none",
            padding: 2,
        } }, { children: [_jsx(Intro, { name: t("errors.401"), breadcrumbs: [
                    {
                        name: t("errors.error"),
                        link: "/error",
                    },
                ] }), _jsx(Nope, { title: t("errors.unauthorized"), caption: t("errors.no-permission") })] })));
}
