import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputPhone from "../inputs/input-phone";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
import { useApiCentral } from "../../../api/api-central";
export default function BlacklistForm({ type, isAddOpened, setIsAddOpened, setIsAdded }) {
    const { t } = useTranslation();
    const api = type == "client" ? useApiTenant() : useApiCentral();
    const alert = useAlert();
    const [phone, setPhone] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => {
        if (phone) {
            setIsSaving(true);
            api.CREATE_blacklistedNumber(phone.phone_area_id, phone.number)
                .then(() => {
                setIsSaving(false);
                setIsAddOpened(false);
                setIsAdded(true);
                setPhone(null);
                alert.show(t("blacklist.blacklist-form.added"), "success");
            })
                .catch(error => {
                setIsSaving(false);
                setIsAddOpened(false);
                console.error(error.response);
                if (error.response.status === 422) {
                    alert.show(error.response.data.message, "error");
                }
                else {
                    alert.show(t("blacklist.blacklist-form.create-error"), "error");
                }
            });
        }
    }, [phone]);
    return (_jsx(InputPhone, { type: "blacklist", setPhone: setPhone, isAddOpened: isAddOpened, setIsAddOpened: setIsAddOpened, isSaving: isSaving }));
}
