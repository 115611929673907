import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { usePrefs } from "../../../services/prefs";
export default function InputEditor({ label, required, height, value, setValue, disabled, error, errors, isError, setIsError, }) {
    const prefs = usePrefs();
    return (_jsxs(Fragment, { children: [label && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: label })) }))), _jsxs(FormControl, Object.assign({ fullWidth: true, disabled: disabled ? disabled : false, required: required, error: isError ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("content") : false }, { children: [_jsx(Editor, { disabled: disabled ? disabled : false, tinymceScriptSrc: `${process.env.APP_URL}/tinymce/tinymce.js`, apiKey: process.env.TINYMCE_KEY, init: {
                            height: height ? height : 500,
                            menubar: false,
                            language: "en",
                            plugins: [
                                "lists",
                                "advlist",
                                "link",
                                "image",
                                "table",
                                "code",
                                "autolink",
                                "autoresize",
                            ],
                            relative_urls: true,
                            convert_urls: false,
                            toolbar: "undo redo | formatselect | bold italic underline |" +
                                "align bullist numlist |" +
                                "hr link image table | removeformat code",
                            skin: prefs.theme === "dark" ? "oxide-dark" : undefined,
                            content_css: prefs.theme === "dark" ? "dark" : undefined,
                        }, value: value, onEditorChange: content => {
                            setValue(content);
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        } }), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] })))] }))] }));
}
