import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { prettifyText } from "../../../helpers/clean";
export default function CampaignCard({ campaign }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, style: {
            display: "flex",
        } }, { children: _jsxs(Card, Object.assign({ style: {
                display: "inherit",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
            } }, { children: [_jsxs(CardContent, Object.assign({ style: {
                        display: "initial",
                        flexGrow: 1,
                        flexDirection: "column",
                    } }, { children: [roles.isManager ||
                            (roles.isAdmin && (_jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    paddingBottom: 1,
                                } }, { children: [_jsx(Box, { children: campaign.active ? (_jsx(Chip, { size: "small", color: "secondary", label: t("campaigns.campaign-attributes.active") })) : (_jsx(Chip, { size: "small", color: "default", label: t("campaigns.campaign-attributes.inactive") })) }), _jsx(Box, { children: campaign.type === "survey" && (_jsx(Button, Object.assign({ onClick: () => {
                                                process.env.APP_ENV === "local"
                                                    ? window.open(window.location.protocol +
                                                        "//localhost/" +
                                                        (campaign.language === "hr"
                                                            ? "anketa"
                                                            : "survey") +
                                                        "/" +
                                                        campaign.slug)
                                                    : tenancy.isSubDomain
                                                        ? window.open(tenancy.link("/" +
                                                            (campaign.language === "hr"
                                                                ? "anketa"
                                                                : "survey") +
                                                            "/" +
                                                            campaign.slug))
                                                        : window.open(tenancy.linkToSubDomainLink("/" +
                                                            (campaign.language === "hr"
                                                                ? "anketa"
                                                                : "survey") +
                                                            "/" +
                                                            campaign.slug));
                                            }, size: "small" }, { children: t("campaigns.go-to-survey") }))) })] })))), _jsx(Typography, Object.assign({ variant: "h5", noWrap: true }, { children: campaign.name })), _jsx(Typography, Object.assign({ variant: "overline", gutterBottom: true }, { children: campaign.type === "inbound"
                                ? t("campaigns.campaign-attributes.types.inbound")
                                : campaign.type === "outbound"
                                    ? t("campaigns.campaign-attributes.types.outbound")
                                    : campaign.type === "contract"
                                        ? t("campaigns.campaign-attributes.types.contract")
                                        : campaign.type === "survey"
                                            ? t("campaigns.campaign-attributes.types.survey")
                                            : t("campaigns.campaign-attributes.types.all") })), _jsx(Box, Object.assign({ sx: {
                                paddingTop: 1,
                            } }, { children: _jsx(Typography, Object.assign({ variant: "body2", noWrap: true }, { children: prettifyText(campaign.description) })) }))] })), _jsxs(CardActions, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                navigate(tenancy.link("/campaigns/" + campaign.id));
                            }, size: "small" }, { children: t("campaigns.view-campaign") })), _jsx(Button, Object.assign({ onClick: () => {
                                navigate(tenancy.link("/campaigns/" + campaign.id + "/new-ticket"));
                            }, size: "small" }, { children: t("tickets.new-ticket") }))] })] })) })));
}
