import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography, Tabs, Tab } from "@mui/material";
import TableChartIcon from "@mui/icons-material/TableChart";
import DisplayOptions from "../options/display-options/display-options";
export default function CampaignColumns({ campaign, fetchCampaign }) {
    var _a, _b, _c;
    const { t } = useTranslation();
    const [tab, setTab] = useState("agent");
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                position: "sticky",
                                top: "100px",
                                display: "flex",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(TableChartIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("campaigns.table-columns.title") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.table-columns.description") }))] })] })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: [_jsxs(Tabs, Object.assign({ indicatorColor: "secondary", textColor: "inherit", value: tab, onChange: (_event, value) => {
                                    setTab(value);
                                }, sx: {
                                    marginBottom: 2,
                                } }, { children: [_jsx(Tab, { value: "agent", label: t("users.roles.agent") }), _jsx(Tab, { value: "manager", label: t("users.roles.manager") }), _jsx(Tab, { value: "admin", label: t("users.roles.admin") })] })), tab === "agent" && (_jsx(DisplayOptions, { role: "agent", options: (_a = campaign.agent_column_display_options) !== null && _a !== void 0 ? _a : campaign.column_display_options, refetchData: fetchCampaign, campaign: campaign })), tab === "manager" && (_jsx(DisplayOptions, { role: "manager", options: (_b = campaign.manager_column_display_options) !== null && _b !== void 0 ? _b : campaign.column_display_options, refetchData: fetchCampaign, campaign: campaign })), tab === "admin" && (_jsx(DisplayOptions, { role: "admin", options: (_c = campaign.admin_column_display_options) !== null && _c !== void 0 ? _c : campaign.column_display_options, refetchData: fetchCampaign, campaign: campaign }))] }))] })) })) }));
}
