import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../elements/blocks/intro";
import CustomFieldsTable from "../../../../elements/custom-fields/custom-fields-table";
export default function GroupFields() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("fields.group-fields.group-fields"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("groups.groups"),
                        link: "/settings/fields/groups",
                    },
                ], button: {
                    label: t("fields.group-fields.new-group-field"),
                    link: "/settings/fields/groups/new",
                    color: "secondary",
                    type: "new",
                } }), _jsx(CustomFieldsTable, { type: "tenant_groups" })] }));
}
