import { jsx as _jsx } from "react/jsx-runtime";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles({
    root: {
        "& .MuiTypography-root img": {
            maxWidth: "100% !important",
        },
    },
});
export default function SurveyFarewell({ campaign }) {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const classes = useStyles();
    return (_jsx(Box, Object.assign({ sx: {
            paddingY: 8,
            width: isMobile ? "100%" : "60%",
        } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                    paddingY: isMobile ? 2 : 4,
                    paddingX: isMobile ? 4 : 8,
                }, className: classes.root }, { children: _jsx(Typography, { dangerouslySetInnerHTML: {
                        __html: campaign.farewell_message,
                    } }) })) }) })));
}
