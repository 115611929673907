import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow, formatDuration, intervalToDuration, sub } from "date-fns";
import { hr, enUS } from "date-fns/locale";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import ActivitiesIcon from "@mui/icons-material/History";
import { usePrefs } from "../../../services/prefs";
import { useRoles } from "../../../services/roles";
import { useTenancy } from "../../../services/tenancy";
import { prettifyText } from "../../../helpers/clean";
import CustomFieldsView from "../custom-fields/custom-fields-view";
const useStyles = makeStyles(() => ({
    timeline: {
        margin: 0,
        padding: 0,
        "& .MuiTimelineContent-root": {
            padding: "4px 0 4px 16px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            content: "none",
        },
        "& .MuiTimelineDot-root": {
            boxShadow: "none",
            width: "12px",
            height: "12px",
        },
    },
    chip: {
        height: "auto",
        paddingY: 2,
        "& .MuiChip-label": {
            paddingTop: 2,
            paddingBottom: 2,
            wordWrap: "break-word",
            whiteSpace: "normal",
            textOverflow: "clip",
            maxWidth: "100%",
        },
    },
    email: {
        "& p": {
            margin: 0,
            paddingTop: "8px",
            paddingBottom: "8px",
        },
        "& ul": {
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
        },
        "& ol": {
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
        },
        "& p:first-child": {
            paddingTop: 0,
        },
        "& p:last-child": {
            paddingBottom: 0,
        },
    },
}));
export default function TicketActivityHistory({ ticket, modal }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const roles = useRoles();
    const tenancy = useTenancy();
    const navigate = useNavigate();
    const prefs = usePrefs();
    const locales = { enUS, hr };
    const activities = ticket.ticket_activities.filter(ticketActivity => {
        if (!ticketActivity.type &&
            !ticketActivity.ticket_activity_type_id &&
            !ticketActivity.seconds_active &&
            !ticketActivity.notes &&
            !ticketActivity.incoming_channel &&
            !ticketActivity.outgoing_channel &&
            ticketActivity.recipients.length === 0 &&
            !ticketActivity.recipient_is_contact)
            return;
        return ticketActivity;
    });
    const totalWorkedTime = () => {
        let count = 0;
        activities.map(activity => (count += activity.seconds_active));
        return count;
    };
    return (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                            position: "sticky",
                            top: "100px",
                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                    marginRight: 2,
                                } }, { children: _jsx(ActivitiesIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.ticket-activities.activities") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("tickets.ticket-activities.ticket-activities") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: activities && activities.length > 0 ? (_jsxs(Fragment, { children: [activities.map((ticketActivity, index) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
                                return (_jsx(Fragment, { children: _jsx(Timeline, Object.assign({ className: classes.timeline }, { children: _jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, {}), index !== activities.length - 1 && (_jsx(TimelineConnector, {}))] }), _jsx(TimelineContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                            paddingBottom: 2,
                                                        } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                                    marginBottom: 2,
                                                                } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                                    display: "inline-block",
                                                                                } }, { children: ticketActivity.created_by ? (_jsx(Fragment, { children: roles.isAgent ? (_jsx(Typography, { children: `${(_a = ticketActivity
                                                                                            .created_by
                                                                                            .first_name) !== null && _a !== void 0 ? _a : ""} ${(_b = ticketActivity
                                                                                            .created_by
                                                                                            .last_name) !== null && _b !== void 0 ? _b : ""}` })) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" +
                                                                                            ticketActivity
                                                                                                .created_by
                                                                                                .id), onClick: event => {
                                                                                            if (!ticketActivity.created_by) {
                                                                                                return;
                                                                                            }
                                                                                            event.preventDefault();
                                                                                            navigate(tenancy.link("/settings/users/" +
                                                                                                ticketActivity
                                                                                                    .created_by
                                                                                                    .id));
                                                                                        }, underline: "hover" }, { children: _jsx(Typography, { children: `${(_c = ticketActivity
                                                                                                .created_by
                                                                                                .first_name) !== null && _c !== void 0 ? _c : ""} ${(_d = ticketActivity
                                                                                                .created_by
                                                                                                .last_name) !== null && _d !== void 0 ? _d : ""}` }) }))) })) : ticket.contact
                                                                                    .is_anonymous ? (_jsx(Typography, { children: t("contacts.anonymous") })) : (_jsx(Fragment, { children: roles.isAgent ? (_jsx(Typography, { children: `${(_e = ticket
                                                                                            .contact
                                                                                            .first_name) !== null && _e !== void 0 ? _e : ""} ${(_f = ticket
                                                                                            .contact
                                                                                            .last_name) !== null && _f !== void 0 ? _f : ""}` })) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/contacts/" +
                                                                                            ticket
                                                                                                .contact
                                                                                                .id), onClick: event => {
                                                                                            event.preventDefault();
                                                                                            navigate(tenancy.link("/contacts/" +
                                                                                                ticket
                                                                                                    .contact
                                                                                                    .id));
                                                                                        }, underline: "hover" }, { children: _jsx(Typography, { children: `${(_g = ticket
                                                                                                .contact
                                                                                                .first_name) !== null && _g !== void 0 ? _g : ""} ${(_h = ticket
                                                                                                .contact
                                                                                                .last_name) !== null && _h !== void 0 ? _h : ""}` }) }))) })) })), ticketActivity.is_created_publicly && (_jsx(Typography, Object.assign({ variant: "overline", component: "p" }, { children: t("tickets.ticket-activities.ticket-activity-history.created-publicly") })))] })), _jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p", color: "textSecondary" }, { children: prefs.lang === "hr"
                                                                                    ? t("tickets.ticket-activities.ticket-activity-history.ago") +
                                                                                        " " +
                                                                                        formatDistanceToNow(new Date(ticketActivity.created_at), {
                                                                                            locale: locales["hr"],
                                                                                        })
                                                                                    : prettifyText(formatDistanceToNow(new Date(ticketActivity.created_at), {
                                                                                        locale: locales["enUs"],
                                                                                    })) +
                                                                                        " " +
                                                                                        t("tickets.ticket-activities.ticket-activity-history.ago") })), _jsx(Typography, Object.assign({ variant: "overline", component: "p", color: "textSecondary" }, { children: new Date(Date.parse(ticketActivity.created_at)).toLocaleDateString("en-GB") +
                                                                                    " " +
                                                                                    new Date(Date.parse(ticketActivity.created_at)).toLocaleTimeString("hr-HR", {
                                                                                        hour: "2-digit",
                                                                                        minute: "2-digit",
                                                                                    }) }))] }))] })), _jsx(Box, Object.assign({ sx: {
                                                                    marginBottom: 2,
                                                                } }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                                                                            paddingX: 3,
                                                                            paddingY: 2,
                                                                        } }, { children: [ticketActivity.incoming_channel !==
                                                                                null && (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: ticketActivity
                                                                                            .incoming_channel
                                                                                            .type !== "post"
                                                                                            ? t("tickets.ticket-activities.ticket-activity-history.received")
                                                                                            : t("tickets.ticket-activities.ticket-activity-history.received-letter") })), ticketActivity
                                                                                        .incoming_channel
                                                                                        .type !==
                                                                                        "personally" &&
                                                                                        ticketActivity
                                                                                            .incoming_channel
                                                                                            .type !==
                                                                                            "post" && (_jsx(Box, Object.assign({ sx: {
                                                                                            marginX: 1,
                                                                                        } }, { children: _jsx(Chip, { label: ticketActivity
                                                                                                .incoming_channel
                                                                                                .type ===
                                                                                                "phone"
                                                                                                ? t("tickets.ticket-activities.ticket-activity-history.phone")
                                                                                                : ticketActivity
                                                                                                    .incoming_channel
                                                                                                    .type ===
                                                                                                    "email"
                                                                                                    ? t("tickets.ticket-activities.ticket-activity-history.email")
                                                                                                    : prettifyText(ticketActivity
                                                                                                        .incoming_channel
                                                                                                        .social_channel), size: "small", variant: "filled" }) }))), ticketActivity
                                                                                        .incoming_channel
                                                                                        .type !==
                                                                                        "post" && (_jsx(Typography, Object.assign({ sx: {
                                                                                            paddingLeft: ticketActivity
                                                                                                .incoming_channel
                                                                                                .type ===
                                                                                                "personally"
                                                                                                ? 0.5
                                                                                                : 0,
                                                                                        }, variant: "overline" }, { children: ticketActivity
                                                                                            .incoming_channel
                                                                                            .type ===
                                                                                            "phone"
                                                                                            ? t("tickets.ticket-activities.ticket-activity-history.call") +
                                                                                                " " +
                                                                                                t("tickets.ticket-activities.ticket-activity-history.from")
                                                                                            : ticketActivity
                                                                                                .incoming_channel
                                                                                                .type ===
                                                                                                "email" ||
                                                                                                ticketActivity
                                                                                                    .incoming_channel
                                                                                                    .type ===
                                                                                                    "social"
                                                                                                ? t("tickets.ticket-activities.ticket-activity-history.message") +
                                                                                                    " " +
                                                                                                    t("tickets.ticket-activities.ticket-activity-history.from")
                                                                                                : " " +
                                                                                                    t("tickets.ticket-activities.ticket-activity-history.information") }))), _jsx(Box, Object.assign({ sx: {
                                                                                            marginX: 1,
                                                                                        } }, { children: _jsx(Chip, { label: ticketActivity
                                                                                                .incoming_channel
                                                                                                .type ===
                                                                                                "phone"
                                                                                                ? ((_j = ticketActivity
                                                                                                    .incoming_channel
                                                                                                    .phone) === null || _j === void 0 ? void 0 : _j.phone_area.phone_country.phone) !==
                                                                                                    "00"
                                                                                                    ? ((_k = ticketActivity
                                                                                                        .incoming_channel
                                                                                                        .phone) === null || _k === void 0 ? void 0 : _k.phone_area.phone_country.phone) +
                                                                                                        " " +
                                                                                                        ((_l = ticketActivity
                                                                                                            .incoming_channel
                                                                                                            .phone) === null || _l === void 0 ? void 0 : _l.phone_area.phone) +
                                                                                                        " " +
                                                                                                        ((_m = ticketActivity
                                                                                                            .incoming_channel
                                                                                                            .phone) === null || _m === void 0 ? void 0 : _m.number)
                                                                                                    : (_o = ticketActivity
                                                                                                        .incoming_channel
                                                                                                        .phone) === null || _o === void 0 ? void 0 : _o.number
                                                                                                : ticketActivity
                                                                                                    .incoming_channel
                                                                                                    .type ===
                                                                                                    "email"
                                                                                                    ? (_p = ticketActivity
                                                                                                        .incoming_channel
                                                                                                        .email) === null || _p === void 0 ? void 0 : _p.email
                                                                                                    : ticketActivity
                                                                                                        .incoming_channel
                                                                                                        .type ===
                                                                                                        "social"
                                                                                                        ? prettifyText(ticketActivity
                                                                                                            .incoming_channel
                                                                                                            .social_profile)
                                                                                                        : ticketActivity
                                                                                                            .incoming_channel
                                                                                                            .type ===
                                                                                                            "post"
                                                                                                            ? t("tickets.ticket-activities.ticket-activity-history.post")
                                                                                                            : t("tickets.ticket-activities.ticket-activity-history.personally"), size: "small", variant: "filled" }) })), ticketActivity
                                                                                        .incoming_channel
                                                                                        .type !==
                                                                                        "personally" &&
                                                                                        ticketActivity
                                                                                            .incoming_channel
                                                                                            .type !==
                                                                                            "post" && (_jsx(Typography, Object.assign({ variant: "overline" }, { children: ticketActivity
                                                                                            .incoming_channel
                                                                                            .type ===
                                                                                            "phone"
                                                                                            ? t("tickets.ticket-activities.ticket-activity-history.number")
                                                                                            : ticketActivity
                                                                                                .incoming_channel
                                                                                                .type ===
                                                                                                "email"
                                                                                                ? t("tickets.ticket-activities.ticket-activity-history.address")
                                                                                                : t("tickets.ticket-activities.ticket-activity-history.profile") })))] }))), ticketActivity.outgoing_channel !==
                                                                                null && (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: ticketActivity
                                                                                            .outgoing_channel
                                                                                            .type === "post"
                                                                                            ? t("tickets.ticket-activities.ticket-activity-history.replied-with-letter")
                                                                                            : ticketActivity
                                                                                                .outgoing_channel
                                                                                                .type !==
                                                                                                "personally"
                                                                                                ? t("tickets.ticket-activities.ticket-activity-history.replied-with")
                                                                                                : t("tickets.ticket-activities.ticket-activity-history.replied") })), _jsx(Box, Object.assign({ sx: {
                                                                                            marginX: 1,
                                                                                        } }, { children: _jsx(Chip, { label: ticketActivity
                                                                                                .outgoing_channel
                                                                                                .type ===
                                                                                                "phone"
                                                                                                ? t("tickets.ticket-activities.ticket-activity-history.phone1")
                                                                                                : ticketActivity
                                                                                                    .outgoing_channel
                                                                                                    .type ===
                                                                                                    "email"
                                                                                                    ? t("tickets.ticket-activities.ticket-activity-history.email")
                                                                                                    : ticketActivity
                                                                                                        .outgoing_channel
                                                                                                        .type ===
                                                                                                        "social"
                                                                                                        ? prettifyText(ticketActivity
                                                                                                            .outgoing_channel
                                                                                                            .social_channel)
                                                                                                        : ticketActivity
                                                                                                            .outgoing_channel
                                                                                                            .type ===
                                                                                                            "post"
                                                                                                            ? t("tickets.ticket-activities.ticket-activity-history.post")
                                                                                                            : t("tickets.ticket-activities.ticket-activity-history.personally"), size: "small", variant: "filled" }) })), ticketActivity
                                                                                        .outgoing_channel
                                                                                        .type !==
                                                                                        "personally" &&
                                                                                        ticketActivity
                                                                                            .outgoing_channel
                                                                                            .type !==
                                                                                            "post" && (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: ticketActivity
                                                                                                    .outgoing_channel
                                                                                                    .type ===
                                                                                                    "phone"
                                                                                                    ? t("tickets.ticket-activities.ticket-activity-history.call1") +
                                                                                                        " " +
                                                                                                        t("tickets.ticket-activities.ticket-activity-history.to")
                                                                                                    : t("tickets.ticket-activities.ticket-activity-history.message1") +
                                                                                                        " " +
                                                                                                        t("tickets.ticket-activities.ticket-activity-history.to") })), _jsx(Box, Object.assign({ sx: {
                                                                                                    marginX: 1,
                                                                                                } }, { children: _jsx(Chip, { label: ticketActivity
                                                                                                        .outgoing_channel
                                                                                                        .type ===
                                                                                                        "phone"
                                                                                                        ? ((_q = ticketActivity
                                                                                                            .outgoing_channel
                                                                                                            .phone) === null || _q === void 0 ? void 0 : _q.phone_area.phone_country.phone) !==
                                                                                                            "00"
                                                                                                            ? ((_r = ticketActivity
                                                                                                                .outgoing_channel
                                                                                                                .phone) === null || _r === void 0 ? void 0 : _r.phone_area.phone_country.phone) +
                                                                                                                " " +
                                                                                                                ((_s = ticketActivity
                                                                                                                    .outgoing_channel
                                                                                                                    .phone) === null || _s === void 0 ? void 0 : _s.phone_area.phone) +
                                                                                                                " " +
                                                                                                                ((_t = ticketActivity
                                                                                                                    .outgoing_channel
                                                                                                                    .phone) === null || _t === void 0 ? void 0 : _t.number)
                                                                                                            : (_u = ticketActivity
                                                                                                                .outgoing_channel
                                                                                                                .phone) === null || _u === void 0 ? void 0 : _u.number
                                                                                                        : ticketActivity
                                                                                                            .outgoing_channel
                                                                                                            .type ===
                                                                                                            "email"
                                                                                                            ? (_v = ticketActivity
                                                                                                                .outgoing_channel
                                                                                                                .email) === null || _v === void 0 ? void 0 : _v.email
                                                                                                            : ticketActivity
                                                                                                                .outgoing_channel
                                                                                                                .social_profile, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "overline" }, { children: ticketActivity
                                                                                                    .outgoing_channel
                                                                                                    .type ===
                                                                                                    "phone"
                                                                                                    ? t("tickets.ticket-activities.ticket-activity-history.number1")
                                                                                                    : ticketActivity
                                                                                                        .outgoing_channel
                                                                                                        .type ===
                                                                                                        "email"
                                                                                                        ? t("tickets.ticket-activities.ticket-activity-history.address1")
                                                                                                        : t("tickets.ticket-activities.ticket-activity-history.profile1") }))] }))] }))), ticketActivity.seconds_active !==
                                                                                null &&
                                                                                ticketActivity.seconds_active >
                                                                                    0 &&
                                                                                roles.isAdmin && (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-activities.ticket-activity-history.worked") })), _jsx(Box, Object.assign({ sx: {
                                                                                            marginLeft: 1,
                                                                                        } }, { children: _jsx(Chip, { label: formatDuration(intervalToDuration({
                                                                                                start: 0,
                                                                                                end: ticketActivity.seconds_active *
                                                                                                    1000,
                                                                                            }), {
                                                                                                locale: prefs.lang ===
                                                                                                    "en"
                                                                                                    ? locales["enUS"]
                                                                                                    : locales["hr"],
                                                                                            }), size: "small", variant: "filled" }) }))] }))), ticketActivity.type &&
                                                                                ticketActivity.created_by && (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-activities.ticket-activity-history.worked") })), _jsx(Box, Object.assign({ sx: {
                                                                                            marginX: 1,
                                                                                        } }, { children: _jsx(Chip, { label: ticketActivity
                                                                                                .type
                                                                                                .name, size: "small", variant: "filled" }) })), _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-activities.ticket-activity-history.activity") }))] }))), ticketActivity.notes && (_jsxs(Box, Object.assign({ sx: {
                                                                                    flexDirection: "column",
                                                                                    width: "100%",
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-activities.ticket-activity-history.left-message") })), _jsx(Box, Object.assign({ sx: {
                                                                                            paddingY: 1,
                                                                                        } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                color: "default",
                                                                                            } }, { children: _jsx(Box, Object.assign({ sx: {
                                                                                                    padding: 2,
                                                                                                } }, { children: _jsx(Typography, { component: "div", dangerouslySetInnerHTML: {
                                                                                                        __html: ticketActivity.notes.replace(/\n/g, "<br />"),
                                                                                                    } }) })) })) }))] }))), ticketActivity.recipients &&
                                                                                ticketActivity.recipients
                                                                                    .length > 0 && (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.activity.recipients") })), _jsx(Box, Object.assign({ sx: {
                                                                                            marginX: 1,
                                                                                            display: "flex",
                                                                                            gap: 1,
                                                                                        } }, { children: ticketActivity.recipients.map(recipient => {
                                                                                            var _a;
                                                                                            return (_jsx(Chip, { className: classes.chip, label: recipient.first_name +
                                                                                                    " " +
                                                                                                    ((_a = recipient.last_name) !== null && _a !== void 0 ? _a : ""), size: "small", variant: "filled" }, recipient.id));
                                                                                        }) }))] }))), ticketActivity.recipient_is_contact && (_jsxs(Box, Object.assign({ sx: {
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                    marginY: 1,
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.activity.recipients") })), _jsx(Box, Object.assign({ sx: {
                                                                                            marginX: 1,
                                                                                            display: "flex",
                                                                                            gap: 1,
                                                                                        } }, { children: _jsx(Chip, { className: classes.chip, label: `[${t("contacts.contact")}] ${ticket
                                                                                                .contact
                                                                                                .first_name} ${ticket
                                                                                                .contact
                                                                                                .last_name}`, size: "small", variant: "filled" }, ticket
                                                                                            .contact
                                                                                            .id) }))] }))), ticketActivity.email_subject && (_jsxs(Box, Object.assign({ sx: {
                                                                                    flexDirection: "column",
                                                                                    width: "100%",
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-activities.ticket-activity-history.left-subject") })), _jsx(Box, Object.assign({ sx: {
                                                                                            paddingY: 1,
                                                                                        } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                color: "default",
                                                                                            } }, { children: _jsx(Box, Object.assign({ sx: {
                                                                                                    padding: 2,
                                                                                                } }, { children: _jsx(Typography, { component: "div", dangerouslySetInnerHTML: {
                                                                                                        __html: ticketActivity.email_subject,
                                                                                                    } }) })) })) }))] }))), ticketActivity.email_content && (_jsxs(Box, Object.assign({ sx: {
                                                                                    flexDirection: "column",
                                                                                    width: "100%",
                                                                                } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-activities.ticket-activity-history.left-content") })), _jsx(Box, Object.assign({ sx: {
                                                                                            paddingY: 1,
                                                                                        } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: {
                                                                                                color: "default",
                                                                                            } }, { children: _jsx(Box, Object.assign({ sx: {
                                                                                                    padding: 2,
                                                                                                } }, { children: _jsx(Typography, { className: classes.email, component: "div", dangerouslySetInnerHTML: {
                                                                                                        __html: ticketActivity.email_content,
                                                                                                    } }) })) })) }))] })))] })) }) })), ticketActivity.custom_fields &&
                                                                ticketActivity.custom_fields.length >
                                                                    0 && (_jsx(Box, Object.assign({ sx: {
                                                                    marginBottom: 2,
                                                                } }, { children: _jsx(CustomFieldsView, { status: "view", type: "ticket_activities", customFields: ticketActivity.custom_fields, modal: true }) })))] })) })] }) })) }, ticketActivity.id));
                            }), totalWorkedTime() > 0 && roles.isAdmin && (_jsxs(Box, Object.assign({ sx: { textAlign: "center", paddingBottom: 4 } }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-activities.ticket-activity-history.total-worked-time") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: formatDuration(intervalToDuration({
                                            start: sub(new Date(), {
                                                seconds: totalWorkedTime(),
                                            }),
                                            end: new Date(),
                                        }), {
                                            locale: prefs.lang === "en"
                                                ? locales["enUS"]
                                                : locales["hr"],
                                        }) }))] })))] })) : (_jsx(Alert, Object.assign({ severity: "info" }, { children: t("tickets.ticket-activities.ticket-activity-history.no-activities") }))) }))] })) }));
}
