import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Intro from "../../elements/blocks/intro";
import CampaignForm from "../../elements/campaigns/campaign-form";
import CampaignDelete from "../../elements/campaigns/campaign-delete";
import CampaignStepsView from "../../elements/campaigns/campaign-step/campaign-steps-view";
import Nope from "../../elements/blocks/nope";
import Controls from "../../elements/blocks/controls";
import TicketInformationForm from "../../elements/tickets/ticket-information-form";
import CampaignColumns from "../../elements/campaigns/campaign-columns";
import { useApiTenant } from "../../../api/api-tenant";
import { useRoles } from "../../../services/roles";
export default function CampaignEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const roles = useRoles();
    const [tab, setTab] = useState("details");
    const [isLoading, setIsLoading] = useState(true);
    const [openPreview, setOpenPreview] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const fetchCampaign = () => {
        api.GET_campaign(id, undefined, undefined, 1)
            .then(response => {
            setCampaign(response.data.campaign);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    useEffect(() => {
        fetchCampaign();
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: campaign ? campaign.name : t("campaigns.campaign") + " #" + id, breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: campaign ? campaign.name : t("campaigns.campaign"),
                        link: "/campaigns/" + id,
                    },
                    {
                        name: t("campaigns.edit-campaign"),
                        link: "/campaigns/" + id + "/edit",
                    },
                ], button: tab === "information"
                    ? {
                        label: t("campaigns.information-preview"),
                        action: () => setOpenPreview(true),
                        color: "secondary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : campaign ? (_jsxs(Fragment, { children: [_jsx(Controls, { tabs: [
                            {
                                label: t("details"),
                                value: "details",
                            },
                            {
                                label: t("tickets.ticket-steps.information"),
                                value: "information",
                            },
                            {
                                label: t("campaigns.table-columns.title"),
                                value: "columns",
                            },
                        ], tab: tab, setTab: setTab }), tab === "details" ? (_jsxs(Fragment, { children: [_jsx(CampaignForm, { status: "edit", campaign: campaign }), (roles.isAdmin || roles.isManager) && (_jsx(CampaignDelete, { campaign: campaign }))] })) : tab === "information" ? (_jsxs(Fragment, { children: [_jsx(CampaignStepsView, { campaign: campaign }), _jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "lg", open: openPreview, onClose: () => setOpenPreview(false) }, { children: _jsx(Box, Object.assign({ sx: {
                                        bgcolor: "background.default",
                                        padding: 4,
                                    } }, { children: _jsx(TicketInformationForm, { campaign: campaign }) })) }))] })) : (_jsx(CampaignColumns, { campaign: campaign, fetchCampaign: fetchCampaign }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("campaigns.errors.not-found") }))] }));
}
