import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import NotificationOptionForm from "../../../elements/notifications/notification-option-form";
import NotificationsTable from "../../../elements/notifications/notifications-table";
export default function TenantNotifications() {
    const { t } = useTranslation();
    const [isAddOpened, setIsAddOpened] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("notifications.notifications"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("notifications.notifications"),
                        link: "/settings/notifications",
                    },
                ], button: {
                    label: t("notifications.new-notification"),
                    action: () => setIsAddOpened(true),
                    color: "secondary",
                    type: "new",
                } }), _jsx(NotificationsTable, { title: t("notifications.notification-options"), isAdded: isAdded, setIsAdded: setIsAdded, type: "tenant" }), _jsx(NotificationOptionForm, { status: "new", isOpened: isAddOpened, setIsOpened: setIsAddOpened, setIsAdded: setIsAdded, type: "tenant" })] }));
}
