import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ContactDetails from "./contact-details";
import ContactHistory from "./contact-history";
import ContactDelete from "./contact-delete";
import TicketsTable from "../tickets/tickets-table";
import TicketCampaignSelect from "../tickets/ticket-campaign-select";
import TicketsMetrics from "../tickets/tickets-metrics";
import Nope from "../blocks/nope";
import Controls from "../blocks/controls";
import { useApiTenant } from "../../../api/api-tenant";
import { useTenancy } from "../../../services/tenancy";
import { useAuth } from "../../../services/auth";
import { useRoles } from "../../../services/roles";
import analytics from "../../../helpers/analytics";
export default function ContactViewModal({ contactModalOpen, setContactModalOpen, contactModalId, setDeletedContact, type, campaign, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useApiTenant();
    const tenancy = useTenancy();
    const roles = useRoles();
    const auth = useAuth();
    const [contact, setContact] = useState(null);
    const [tab, setTab] = useState("profile");
    const [isLoading, setIsLoading] = useState(true);
    const [newTicket, setNewTicket] = useState(false);
    useEffect(() => {
        if (!contactModalId) {
            setContact(null);
            return;
        }
        setIsLoading(true);
        setTab("profile");
        api.GET_contact(contactModalId)
            .then(response => {
            setContact(response.data.contact);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [contactModalId]);
    useEffect(() => {
        if (!contactModalId)
            return;
        analytics.sendModalView("/contacts/" + contactModalId + "/" + tab, auth.user);
    }, [tab]);
    return (_jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "lg", open: contactModalOpen, onClose: () => {
            setContactModalOpen(false);
        }, PaperProps: {
            sx: {
                height: "100%",
                bgcolor: "background.default",
            },
        } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : contact ? (_jsxs(Fragment, { children: [_jsx(DialogTitle, Object.assign({ sx: {
                        bgcolor: "background.paper",
                    } }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                    display: "inline-block",
                                } }, { children: roles.isAgent ? (_jsx(Typography, Object.assign({ variant: "h6" }, { children: t("contacts.contact") + " #" + contactModalId }))) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/contacts/" + contactModalId), onClick: event => {
                                        event.preventDefault();
                                        navigate(tenancy.link("/contacts/" + contactModalId));
                                    }, underline: "hover" }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: t("contacts.contact") + " #" + contactModalId })) }))) })), _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                } }, { children: _jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { paddingLeft: 2 } }, { children: _jsx(Button, Object.assign({ size: "small", color: "primary", onClick: () => {
                                                    if (!campaign) {
                                                        setNewTicket(true);
                                                    }
                                                    else {
                                                        navigate(tenancy.link("/contacts/" +
                                                            contact.id +
                                                            "/campaign/" +
                                                            (campaign === null || campaign === void 0 ? void 0 : campaign.id) +
                                                            "/new-ticket"));
                                                    }
                                                } }, { children: t("tickets.new-ticket") })) })), (roles.isAdmin || roles.isManager) && type === "contact" && (_jsx(Box, Object.assign({ sx: { paddingLeft: 2 } }, { children: _jsx(ContactDelete, { contact: contact, setDeletedContact: setDeletedContact, setContactModalOpen: setContactModalOpen }) })))] }) }))] })) })), _jsxs(Box, Object.assign({ sx: {
                        paddingX: 3,
                        paddingY: 2,
                    } }, { children: [_jsx(Controls, { tabs: [
                                {
                                    label: t("contacts.profile"),
                                    value: "profile",
                                },
                                {
                                    label: t("tickets.tickets"),
                                    value: "tickets",
                                },
                            ], tab: tab, setTab: setTab }), tab === "profile" ? (_jsxs(Fragment, { children: [_jsx(ContactDetails, { type: "contact", contact: contact, modal: true }), contact.history && contact.history.length > 0 && (_jsx(ContactHistory, { modal: true, status: "view", contact: contact })), _jsx(TicketCampaignSelect, { newTicket: newTicket, setNewTicket: setNewTicket, contact: contact })] })) : (_jsxs(Fragment, { children: [(roles.isManager || roles.isAdmin) && (_jsx(TicketsMetrics, { type: "contact", contact_id: contact.id })), _jsx(TicketsTable, { type: "contact", contact: contact, isPreview: true })] }))] }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("contacts.errors.not-found") })) })));
}
