import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../../elements/blocks/intro";
import CustomFieldForm from "../../../../elements/custom-fields/custom-field-form";
export default function GroupFieldNew() {
    const { t } = useTranslation();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("fields.group-fields.new-group-field"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                    {
                        name: t("groups.groups"),
                        link: "/settings/fields/groups",
                    },
                    {
                        name: t("fields.new-field"),
                        link: "/settings/fields/groups/new",
                    },
                ] }), _jsx(CustomFieldForm, { status: "new", type: "tenant_groups" })] }));
}
