var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, createContext, useEffect, useRef, useContext, cloneElement, forwardRef, Fragment, } from "react";
import { VariableSizeList } from "react-window";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { prettifyText } from "../../../helpers/clean";
export default function InputAutocomplete({ label, required, options, value, setValue, caption, info, icons, disableClearable, disabled, error, errors, isError, setIsError, }) {
    const sortOptions = [...options].sort((a, b) => a.label.localeCompare(b.label));
    const getOption = (options, value) => {
        for (let option of options) {
            if (option.value === value) {
                return option;
            }
        }
    };
    return (_jsxs(Fragment, { children: [caption && (_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, { children: caption }) }))), _jsxs(FormControl, Object.assign({ fullWidth: true, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false }, { children: [_jsx(Autocomplete, { disabled: disabled ? disabled : false, options: sortOptions, isOptionEqualToValue: (option, value) => option.value === value.value, value: icons
                            ? value
                            : getOption(sortOptions, value)
                                ? getOption(sortOptions, value)
                                : null, getOptionLabel: option => (option.label ? prettifyText(option.label) : ""), onChange: (event, value) => {
                            setValue(value);
                            if (isError &&
                                errors &&
                                error &&
                                setIsError &&
                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
                                setIsError(false);
                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: label, required: required, error: isError && errors && error ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error) : false, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { autoComplete: "new-password" }) }))), renderOption: (props, option) => (_jsx("li", Object.assign({}, props, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center" }, { children: [icons && (_jsx(Grid, Object.assign({ item: true, xs: 2 }, { children: _jsx(Box, Object.assign({ sx: {
                                                paddingTop: 1,
                                                textAlign: "center",
                                            } }, { children: _jsx(Icon, { children: option.label }) })) }))), _jsx(Grid, Object.assign({ item: true, xs: icons ? 10 : 12 }, { children: _jsx(Typography, { children: prettifyText(option.label) }) }))] })) }), option.value)), 
                        // @ts-ignore
                        ListboxComponent: icons ? ListboxComponent : "ul", disableClearable: disableClearable }), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }))] }));
}
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
    const { children } = props, other = __rest(props, ["children"]);
    const itemData = Children.toArray(children);
    return (_jsx(Box, Object.assign({ ref: ref }, { children: _jsx(OuterElementContext.Provider, Object.assign({ value: other }, { children: _jsx(VariableSizeList, Object.assign({ itemData: itemData, height: 220, width: "100%", ref: useResetCache(itemData.length), outerElementType: forwardRef((props, ref) => {
                    const outerProps = useContext(OuterElementContext);
                    return _jsx(Box, Object.assign({ ref: ref }, props, outerProps));
                }), innerElementType: "ul", itemSize: () => {
                    return 48;
                }, overscanCount: 5, itemCount: itemData.length }, { children: props => {
                    return cloneElement(props.data[props.index], {
                        style: Object.assign(Object.assign({}, props.style), { top: props.style.top + 16 }),
                    });
                } })) })) })));
});
const OuterElementContext = createContext({});
const useResetCache = data => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null)
            ref.current.resetAfterIndex(0, true);
    }, [data]);
    return ref;
};
