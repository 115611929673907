import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../elements/blocks/intro";
import TicketsMetrics from "../../elements/tickets/tickets-metrics";
import TicketsTable from "../../elements/tickets/tickets-table";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { flatGroups } from "../../../helpers/groups";
import { useRoles } from "../../../services/roles";
export default function GroupTicketsView() {
    const { t } = useTranslation();
    const { groupId } = useParams();
    const api = useApiTenant();
    const roles = useRoles();
    const [group, setGroup] = useState(null);
    const [groupBreadcrumbs, setGroupBreadcrumbs] = useState(null);
    const [isGroupLoading, setIsGroupLoading] = useState(true);
    useEffect(() => {
        api.GET_group(groupId)
            .then(response => {
            var _a;
            setGroup(response.data.group);
            setIsGroupLoading(false);
            if ((_a = response.data.group) === null || _a === void 0 ? void 0 : _a.parent_groups) {
                let groupParents = [];
                groupParents = flatGroups([response.data.group.parent_groups], 0, groupParents).reverse();
                let groupBreadcrumbs;
                groupBreadcrumbs = groupParents.map(group => group.name).join(" › ");
                groupBreadcrumbs = groupBreadcrumbs + " › " + response.data.group.name;
                setGroupBreadcrumbs(groupBreadcrumbs);
            }
            else {
                setGroupBreadcrumbs(response.data.group.name);
            }
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsGroupLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: group ? group.name : t("queues.queues") + " #" + groupId, breadcrumbs: [
                    {
                        name: t("queues.queues"),
                        link: "/groups",
                    },
                    {
                        name: groupBreadcrumbs ? groupBreadcrumbs : t("queues.queue"),
                        link: "/groups/" + groupId,
                    },
                ], loading: isGroupLoading }), isGroupLoading ? (_jsx(Fragment, {})) : group ? (_jsxs(Fragment, { children: [(roles.isManager || roles.isAdmin) && (_jsx(TicketsMetrics, { type: "group", group_id: group.id })), _jsx(TicketsTable, { type: "group", group: group })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("error.queues.not-found") }))] }));
}
