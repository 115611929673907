import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
export default function DisplayOptionDelete({ displayOptionUidToDelete, setDeleteOptionUidToDelete, deleteDisplayOptionModalOpen, closeDeleteDisplayOptionModal, reordableDisplayOptions, setReordableDisplayOptions, }) {
    const { t } = useTranslation();
    const [isDeleting, setIsDeleting] = useState(false);
    const removeDisplayOption = () => {
        setIsDeleting(true);
        let fields = Array.from(reordableDisplayOptions);
        fields = fields.filter(field => field.uid !== displayOptionUidToDelete);
        setReordableDisplayOptions(fields);
        closeDeleteDisplayOptionModal();
        setDeleteOptionUidToDelete("");
        setIsDeleting(false);
    };
    return (_jsxs(Dialog, Object.assign({ open: deleteDisplayOptionModalOpen, onClose: closeDeleteDisplayOptionModal }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: t("campaigns.table-columns.attribute-remove-conformation") }) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: closeDeleteDisplayOptionModal }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isDeleting, onClick: () => removeDisplayOption(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), style: {
                            color: "rgb(244, 67, 54)",
                        } }, { children: t("campaigns.table-columns.remove") }))] })] })));
}
