import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRoles } from "../../../services/roles";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Intro from "../../elements/blocks/intro";
import Controls from "../../elements/blocks/controls";
import ContactDetails from "../../elements/contacts/contact-details";
import Nope from "../../elements/blocks/nope";
import TicketActivityHistory from "../../elements/tickets/ticket-activity-history";
import TicketDetails from "../../elements/tickets/ticket-details";
import ContactHistory from "../../elements/contacts/contact-history";
import TicketInformation from "../../elements/tickets/ticket-information";
import InformationHistory from "../../elements/tickets/information-history";
import { useApiTenant } from "../../../api/api-tenant";
export default function TicketView() {
    const { id } = useParams();
    return _jsx(TicketViewComponent, {}, id);
}
function TicketViewComponent() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const roles = useRoles();
    const [ticket, setTicket] = useState(null);
    const [tab, setTab] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        api.GET_ticket(id)
            .then(response => {
            const ticket = response.data.ticket;
            setTicket(ticket);
            if (process.env.APP_ID === "mcd" && roles.isAgent) {
                if (ticket.campaign.has_steps) {
                    setTab("information");
                }
                else {
                    setTab("detail");
                }
            }
            else if (ticket.campaign.has_contact_fields) {
                setTab("contact");
            }
            else if (ticket.campaign.has_steps) {
                setTab("information");
            }
            else {
                setTab("detail");
            }
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [id]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.ticket") + " #" + id, breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: ticket ? ticket.campaign.name : t("campaigns.campaign"),
                        link: "/campaigns/" + (ticket ? ticket.campaign.id : ""),
                    },
                    {
                        name: t("tickets.ticket") + " #" + id,
                        link: "/tickets/" + id,
                    },
                ], button: ticket
                    ? ticket.is_draft
                        ? {
                            label: t("tickets.complete-ticket"),
                            link: "/tickets/" + id + "/contact",
                            color: "secondary",
                            type: "edit",
                        }
                        : tab === "contact"
                            ? {
                                label: t("tickets.edit-ticket"),
                                link: "/tickets/" + id + "/contact",
                                color: "primary",
                                type: "edit",
                            }
                            : tab === "information"
                                ? {
                                    label: t("tickets.edit-ticket"),
                                    link: "/tickets/" + id + "/information",
                                    color: "primary",
                                    type: "edit",
                                }
                                : tab === "detail"
                                    ? {
                                        label: t("tickets.edit-ticket"),
                                        link: "/tickets/" + id + "/details",
                                        color: "primary",
                                        type: "edit",
                                    }
                                    : {
                                        label: t("tickets.ticket-activities.add-activity"),
                                        link: "/tickets/" + id + "/activity",
                                        color: "secondary",
                                        type: "edit",
                                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : ticket ? (_jsxs(Fragment, { children: [ticket.is_draft ? (_jsx(Box, Object.assign({ sx: {
                            marginBottom: 2,
                        } }, { children: _jsxs(Alert, Object.assign({ severity: "warning" }, { children: [_jsx("span", { children: t("tickets.this-ticket-is-a") }), _jsx("strong", { children: t("tickets.draft").toLowerCase() }), _jsx("span", { children: "!" })] })) }))) : undefined, _jsx(Controls, { tabs: process.env.APP_ID === "mcd" && roles.isAgent
                            ? [
                                ...(ticket.campaign.has_steps
                                    ? [
                                        {
                                            label: t("tickets.ticket-steps.information"),
                                            value: "information",
                                        },
                                    ]
                                    : []),
                                {
                                    label: t("tickets.ticket-steps.details"),
                                    value: "detail",
                                },
                            ]
                            : [
                                ...(ticket.campaign.has_contact_fields
                                    ? [
                                        {
                                            label: t("tickets.ticket-steps.contact"),
                                            value: "contact",
                                        },
                                    ]
                                    : []),
                                ...(ticket.campaign.has_steps
                                    ? [
                                        {
                                            label: t("tickets.ticket-steps.information"),
                                            value: "information",
                                        },
                                    ]
                                    : []),
                                {
                                    label: t("tickets.ticket-steps.details"),
                                    value: "detail",
                                },
                                ...(ticket.campaign.has_activity_fields
                                    ? [
                                        {
                                            label: t("tickets.ticket-steps.activity"),
                                            value: "activity",
                                        },
                                    ]
                                    : []),
                            ], tab: tab, setTab: setTab }), tab === "contact" && (_jsxs(Fragment, { children: [_jsx(ContactDetails, { type: "ticket", contact: ticket.contact, campaign: ticket.campaign }), (roles.isAdmin || roles.isManager) &&
                                ticket.contact.history &&
                                ticket.contact.history.length > 0 && (_jsx(ContactHistory, { status: "view", contact: ticket.contact }))] })), tab === "information" && (_jsxs(Fragment, { children: [_jsx(TicketInformation, { status: "view", ticket: ticket }), (roles.isAdmin || roles.isManager) &&
                                ticket.information_history &&
                                ticket.information_history.length > 0 && (_jsx(InformationHistory, { status: "view", ticket: ticket }))] })), tab === "detail" && _jsx(TicketDetails, { ticket: ticket }), tab === "activity" && _jsx(TicketActivityHistory, { ticket: ticket })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("tickets.ticket-not-found") }))] }));
}
