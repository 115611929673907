import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CountryIcon from "@mui/icons-material/Flag";
import PhoneIcon from "@mui/icons-material/Phone";
export default function PhoneCountryDetails({ phoneCountry }) {
    const { t } = useTranslation();
    return (_jsx(Box, Object.assign({ sx: {
            marginBottom: 2,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                    marginRight: 2,
                                } }, { children: _jsx(CountryIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("options.phone-countries.phone-country-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                padding: 4,
                            } }, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    alignItems: "flex-start",
                                } }, { children: _jsxs(Box, Object.assign({ sx: {
                                        paddingLeft: 2,
                                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: phoneCountry.name })), _jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: phoneCountry.code })), _jsxs(Box, Object.assign({ sx: {
                                                display: "flex",
                                                paddingTop: 3,
                                            } }, { children: [_jsx(Box, { children: _jsx(PhoneIcon, { fontSize: "small", color: "action" }) }), _jsx(Box, Object.assign({ sx: {
                                                        paddingLeft: 1,
                                                    } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2", color: "textSecondary" }, { children: phoneCountry.phone })) }))] }))] })) })) })) }) }))] })) })));
}
