import { useState } from "react";
import { createSingletonHook } from "../helpers/singleton";
export const [useAlert, AlertProvider] = createSingletonHook(() => {
    const [message, setMessage] = useState("");
    const [type, setType] = useState("info");
    const [duration, setDuration] = useState(3000);
    const [isOpened, setIsOpened] = useState(false);
    const [actionLink, setActionLink] = useState(null);
    const [actionLabel, setActionLabel] = useState(null);
    const show = (message, type, duration, actionLink, actionLabel) => {
        setMessage(message);
        if (type)
            setType(type);
        if (duration)
            setDuration(duration);
        if (actionLink)
            setActionLink(actionLink);
        if (actionLabel)
            setActionLabel(actionLabel);
        setIsOpened(true);
    };
    const hide = () => {
        setIsOpened(false);
        setMessage("");
        setType("info");
        setActionLink(null);
        setActionLabel(null);
        setDuration(5000);
    };
    return {
        message,
        type,
        duration,
        actionLink,
        actionLabel,
        isOpened,
        show,
        hide,
    };
});
