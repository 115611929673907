import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "../../elements/blocks/alert";
import { useApiPublic } from "../../../api/api-public";
import { useAlert } from "../../../services/alert";
import { useTenancy } from "../../../services/tenancy";
import { usePrefs } from "../../../services/prefs";
import PublicActivities from "../../elements/tickets/public-activities";
import Paper from "@mui/material/Paper";
import MuiAlert from "@mui/material/Alert";
export default function ActivityTicket() {
    const { t, i18n } = useTranslation();
    const { ticket_uuid, user_uuid } = useParams();
    const api = useApiPublic();
    const alert = useAlert();
    const prefs = usePrefs();
    const tenancy = useTenancy();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [isLoading, setIsLoading] = useState(false);
    const [client, setClient] = useState(null);
    const [notes, setNotes] = useState("");
    const [activities, setActivities] = useState([]);
    const [information, setInformation] = useState([]);
    const [user, setUser] = useState();
    const [contact, setContact] = useState();
    const [isActivitySaving, setIsActivitySaving] = useState(false);
    const [isReplyOpen, setIsReplyOpen] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [isError, setIsError] = useState(false);
    const [errors] = useState(null);
    const [activityCustomFieldOptions, setActivityCustomFieldOptions] = useState([]);
    const [tenancyCustomFields, setTenancyCustomFields] = useState([]);
    const getTicketActivities = (tenantId, ticketUuid, userUuid) => {
        api.GET_ticketActivitiesByUser(tenantId, ticketUuid, userUuid)
            .then(response => {
            const publicActivity = response.data.public_ticket_activity_base;
            setActivities(publicActivity.ticket_activities);
            setUser(publicActivity.recipient);
            setContact(publicActivity.contact);
            setInformation(publicActivity.information);
            setTenancyCustomFields(publicActivity.activity_custom_field_options.map(field => field.custom_field));
            setActivityCustomFieldOptions(publicActivity.activity_custom_field_options);
            setIsLoading(false);
        })
            .catch(error => {
            console.error(error);
            setIsLoading(false);
        });
    };
    const createTicketActivity = () => {
        if (!client || !ticket_uuid || !user_uuid || notes === "") {
            return;
        }
        setIsActivitySaving(true);
        api.CREATE_ticketActivityByUser(client.id, ticket_uuid, user_uuid, notes, customFields === null || customFields === void 0 ? void 0 : customFields.sort((a, b) => a.index - b.index))
            .then(() => {
            getTicketActivities(client.id, ticket_uuid, user_uuid);
            setIsActivitySaving(false);
            setNotes("");
            setCustomFields([]);
            setIsReplyOpen(false);
            alert.show(t("tickets.activity.sent"), "success");
        })
            .catch(error => {
            console.error(error);
            setIsActivitySaving(false);
            alert.show(t("tickets.activity.error"), "error");
        });
    };
    useEffect(() => {
        if ((!tenancy.subDomain && process.env.APP_ENV !== "local") || !ticket_uuid || !user_uuid) {
            window.location.replace("/login");
            return;
        }
        let lang = window.location.pathname.startsWith("/aktivnost") ? "hr" : "en";
        i18n.changeLanguage(lang).then(() => {
            // @ts-ignore
            prefs.setLang(lang);
        });
        setIsLoading(true);
        api.GET_clientByDomain(process.env.APP_ENV === "local" ? "ina" : tenancy.subDomain)
            .then(response => {
            let tenant = response.data.tenant;
            tenancy.setClient(tenant);
            setClient(tenant);
            getTicketActivities(tenant.id, ticket_uuid, user_uuid);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                setIsLoading(false);
                // @ts-ignore
                window.location.replace(process.env.APP_URL);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Box, Object.assign({ sx: {
            minHeight: "100vh",
            display: "flex",
        } }, { children: [_jsx(Container, { children: isLoading ? (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : client ? (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 8,
                            } }, { children: prefs.theme === "dark" && client.logo_dark_url ? (_jsx("img", { src: client.logo_dark_url, style: {
                                    maxHeight: "100px",
                                    maxWidth: "200px",
                                    objectFit: "contain",
                                }, alt: "Logo" })) : client.logo_url ? (_jsx("img", { src: client.logo_url, style: {
                                    maxHeight: "100px",
                                    maxWidth: "200px",
                                    objectFit: "contain",
                                }, alt: "Logo" })) : (_jsx(Typography, Object.assign({ variant: "h2" }, { children: client.name }))) })), user || contact ? (_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                            } }, { children: _jsx(Box, Object.assign({ sx: {
                                    paddingY: 8,
                                    width: isMobile ? "100%" : "60%",
                                } }, { children: process.env.GOOGLE_RECAPTCHA_KEY ? (_jsx(GoogleReCaptchaProvider, Object.assign({ reCaptchaKey: process.env.GOOGLE_RECAPTCHA_KEY, language: prefs.lang, scriptProps: {
                                        async: true,
                                    } }, { children: _jsx(PublicActivities, { activities: activities, user: user, contact: contact, information: information, tenancyCustomFields: tenancyCustomFields, activityCustomFieldOptions: activityCustomFieldOptions, customFields: customFields, setCustomFields: setCustomFields, notes: notes, setNotes: setNotes, isActivitySaving: isActivitySaving, createTicketActivity: createTicketActivity, errors: errors, isError: isError, setIsError: setIsError, isReplyOpen: isReplyOpen, setIsReplyOpen: setIsReplyOpen }) }))) : (_jsx(PublicActivities, { activities: activities, user: user, contact: contact, information: information, tenancyCustomFields: tenancyCustomFields, activityCustomFieldOptions: activityCustomFieldOptions, customFields: customFields, setCustomFields: setCustomFields, notes: notes, setNotes: setNotes, isActivitySaving: isActivitySaving, createTicketActivity: createTicketActivity, errors: errors, isError: isError, setIsError: setIsError, isReplyOpen: isReplyOpen, setIsReplyOpen: setIsReplyOpen })) })) }))) : (_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                paddingY: 10,
                            } }, { children: _jsx(Box, Object.assign({ sx: { width: isMobile ? "100%" : "60%" } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                            padding: isMobile ? 4 : 5,
                                        } }, { children: _jsx(MuiAlert, Object.assign({ severity: "error" }, { children: t("tickets.activity.not-found") })) })) }) })) }))), _jsx(Box, Object.assign({ sx: {
                                textAlign: "center",
                                paddingBottom: 8,
                            } }, { children: _jsxs(Typography, { children: [_jsx(Link, Object.assign({ target: "_blank", style: {
                                            color: prefs.theme === "dark" ? "#fff" : "#0000008a",
                                        }, href: process.env.APP_LINK, underline: "hover" }, { children: process.env.APP_NAME })), _jsxs(Box, Object.assign({ component: "span", sx: {
                                            paddingLeft: 1,
                                            color: prefs.theme === "dark" ? "#fff" : "#0000008a",
                                        } }, { children: ["\u00A9 ", new Date().getFullYear()] }))] }) }))] })) : (_jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: "center",
                        paddingY: 10,
                    } }, { children: _jsx(Box, Object.assign({ sx: { width: isMobile ? "100%" : "60%" } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: isMobile ? 4 : 5,
                                } }, { children: _jsx(MuiAlert, Object.assign({ severity: "error" }, { children: t("tickets.activity.not-found") })) })) }) })) }))) }), _jsx(Alert, {})] })));
}
