import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ChoiceIcon from "@mui/icons-material/CheckCircle";
import { useTenancy } from "../../../../services/tenancy";
import { useAlert } from "../../../../services/alert";
import { useApiTenant } from "../../../../api/api-tenant";
export default function ChoiceFieldForm({ status, choice }) {
    var _a;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [name, setName] = useState((_a = choice === null || choice === void 0 ? void 0 : choice.name) !== null && _a !== void 0 ? _a : "");
    const [notEditable, setNotEditable] = useState(!!(choice === null || choice === void 0 ? void 0 : choice.not_editable));
    const [filterable, setFilterable] = useState(!!(choice === null || choice === void 0 ? void 0 : choice.filterable));
    const [hasMetrics, setHasMetrics] = useState(!!(choice === null || choice === void 0 ? void 0 : choice.has_metrics));
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createChoiceField = () => {
        setIsSaving(true);
        setIsError(false);
        api.CREATE_campaignsChoiceField(name, notEditable, filterable, hasMetrics)
            .then(() => {
            setIsSaving(false);
            alert.show(t("campaigns.campaign-steps.choice-fields.choice-field-form.created"), "success");
            navigate(tenancy.link("/settings/fields/shared/choices"));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("campaigns.campaign-steps.choice-fields.choice-field-form.creating-error"), "error");
        });
    };
    const updateChoiceField = () => {
        if (!choice)
            return;
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_campaignsChoiceField(choice.id, name, notEditable, filterable, hasMetrics)
            .then(() => {
            setIsSaving(false);
            alert.show(t("campaigns.campaign-steps.choice-fields.choice-field-form.updated"), "success");
            navigate(tenancy.link("/settings/fields/shared/choices"));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("campaigns.campaign-steps.choice-fields.choice-field-form.updating-error"), "error");
        });
    };
    return (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(ChoiceIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.campaign-steps.choice-fields.choice-fields-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: isError ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name") : false }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-steps.campaign-step-attributes.name") }), _jsx(OutlinedInput, { label: t("campaigns.campaign-steps.campaign-step-attributes.name") + " *", value: name, autoComplete: "new-password", onChange: event => {
                                                                setName(event.target.value);
                                                                if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name")))
                                                                    setIsError(false);
                                                            } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["name"][0] })))] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", checked: filterable, onChange: () => setFilterable(!filterable) }), 
                                                        // @ts-ignore
                                                        label: t("campaigns.campaign-steps.campaign-step-attributes.filterable") }) }), _jsx(FormHelperText, { children: t("campaigns.campaign-steps.choice-fields.choice-field-form.filterable-message") })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", checked: hasMetrics, onChange: () => setHasMetrics(!hasMetrics) }), 
                                                        // @ts-ignore
                                                        label: t("campaigns.campaign-steps.campaign-step-attributes.reportable") }) }), _jsx(FormHelperText, { children: t("campaigns.campaign-steps.choice-fields.choice-field-form.reportable-message") })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", checked: notEditable, onChange: () => setNotEditable(!notEditable) }), 
                                                        // @ts-ignore
                                                        label: t("fields.field-attributes.not-editable") }) }), _jsx(FormHelperText, { children: t("fields.field-form.not-editable-message") })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Divider, { light: true }), _jsx(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        paddingTop: 4,
                                                    } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !name, onClick: () => status === "new"
                                                            ? createChoiceField()
                                                            : updateChoiceField(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                                            ? t("form-component.create")
                                                            : t("form-component.update") })) }))] }))] })) })) }) }))] })) })) }));
}
