import { startOfWeek, endOfMonth, startOfYear } from "date-fns";
export const getTrendLabels = (range) => {
    let trendsLabels = [];
    let currentDate = new Date();
    if (range === "this_week" ||
        range === "last_week" ||
        range === "this_month" ||
        range === "last_month") {
        if (range === "this_week" || range === "last_week") {
            for (let i = 0; i < 7; i++) {
                let newDate = new Date(startOfWeek(range === "this_week"
                    ? currentDate
                    : new Date(new Date().setDate(new Date().getDate() - 7)), { weekStartsOn: 1 }).getTime() +
                    i * 86400000);
                let newDateStr = newDate.getFullYear() +
                    "-" +
                    ("0" + (newDate.getMonth() + 1).toString()).slice(-2) +
                    "-" +
                    ("0" + newDate.getDate().toString()).slice(-2);
                trendsLabels.push(newDateStr);
            }
        }
        if (range === "this_month" || range === "last_month") {
            const lastDay = range === "this_month"
                ? endOfMonth(currentDate).getDate()
                : new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
            for (let i = 0; i < lastDay; i++) {
                let newDate = range === "this_month"
                    ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 1 + i)
                    : new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1 + i);
                let newDateStr = newDate.getFullYear() +
                    "-" +
                    ("0" + (newDate.getMonth() + 1).toString()).slice(-2) +
                    "-" +
                    ("0" + newDate.getDate().toString()).slice(-2);
                trendsLabels.push(newDateStr);
            }
        }
    }
    else {
        for (let i = 0; i < 12; i++) {
            let newDate = range === "this_year"
                ? startOfYear(currentDate)
                : new Date(currentDate.getFullYear() - 1, 0, 1);
            newDate.setDate(1);
            newDate.setMonth(newDate.getMonth() + i);
            let newDateStr = newDate.getFullYear() + "-" + ("0" + (newDate.getMonth() + 1).toString()).slice(-2);
            trendsLabels.push(newDateStr);
        }
    }
    return trendsLabels;
};
export const getLabels = (trendsLabels, range) => {
    let labels = [];
    if (range === "this_year" || range === "last_year") {
        trendsLabels.map(label => {
            let date = label.split("-");
            let month = date[1];
            let year = date[0];
            labels.push(month + "/" + year);
        });
    }
    else {
        trendsLabels.map(label => {
            let date = label.split("-");
            let day = date[2];
            let month = date[1];
            let year = date[0];
            labels.push(day + "/" + month + "/" + year);
        });
    }
    return labels;
};
