import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Nope from "../../../../elements/blocks/nope";
import Intro from "../../../../elements/blocks/intro";
import PhoneAreaForm from "../../../../elements/options/phone-areas/phone-area-form";
import PhoneAreaDelete from "../../../../elements/options/phone-areas/phone-area-delete";
import { useApiTenant } from "../../../../../api/api-tenant";
export default function PhoneAreaEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [phoneArea, setPhoneArea] = useState(null);
    const [phoneCountries, setPhoneCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    useEffect(() => {
        api.GET_phoneArea(id)
            .then(response => {
            setPhoneArea(response.data.phone_area);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        api.GET_phoneCountries()
            .then(response => {
            setPhoneCountries(response.data.phone_countries);
            setIsLoading2(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading2(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: phoneArea ? phoneArea.name : t("options.phone-areas.phone-area") + " #" + id, breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("options.options"),
                        link: "/settings/options",
                    },
                    {
                        name: t("options.phone-areas.phone-areas"),
                        link: "/settings/options/phone-areas",
                    },
                    {
                        name: phoneArea ? phoneArea.name : t("options.phone-areas.phone-area"),
                        link: "/settings/options/phone-areas/" + id,
                    },
                    {
                        name: t("options.phone-areas.edit-phone-area"),
                        link: "/settings/options/phone-areas/" + id + "/edit",
                    },
                ], loading: isLoading || isLoading2 }), isLoading && isLoading2 ? (_jsx(Fragment, {})) : phoneArea ? (_jsxs(Fragment, { children: [_jsx(PhoneAreaForm, { status: "edit", phoneCountries: phoneCountries, phoneArea: phoneArea }), _jsx(PhoneAreaDelete, { phoneArea: phoneArea })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("options.phone-areas.errors.not-found") }))] }));
}
