import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../elements/blocks/intro";
import AudienceMetrics from "../../elements/campaigns/audience/audience-metrics";
import AudienceLeadsTable from "../../elements/campaigns/audience/audience-leads-table";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
export default function AudienceView() {
    const { t } = useTranslation();
    const { campaignId, audienceId } = useParams();
    const api = useApiTenant();
    const [audience, setAudience] = useState(null);
    const [refreshMetrics, setRefreshMetrics] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.GET_audience(audienceId, campaignId)
            .then(response => {
            setAudience(response.data.audience);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: audience ? audience.name : t("campaigns.audiences.audience") + " #" + audienceId, breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns/",
                    },
                    {
                        name: audience ? audience.campaign.name : t("campaigns.campaign"),
                        link: audience ? "/campaigns/" + campaignId : null,
                    },
                    {
                        name: t("campaigns.audiences.audiences"),
                        link: campaignId ? "/campaigns/" + campaignId + "/audiences" : null,
                    },
                    {
                        name: audience
                            ? audience.name
                            : t("campaigns.audiences.audience") + " #" + audienceId,
                        link: audience
                            ? "/campaigns/" + campaignId + "/audiences/" + audienceId
                            : null,
                    },
                ], button: audience
                    ? {
                        label: t("campaigns.audiences.edit-audience"),
                        link: "/campaigns/" + campaignId + "/audiences/" + audienceId + "/edit",
                        color: "primary",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : audience ? (_jsxs(Fragment, { children: [_jsx(AudienceMetrics, { audience_id: audienceId, refreshMetrics: refreshMetrics }), _jsx(AudienceLeadsTable, { campaignId: campaignId, audienceId: audienceId, setRefreshMetrics: setRefreshMetrics })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("campaigns.audiences.leads.leads-not-found") }))] }));
}
