import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { isBefore } from "date-fns";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import ScheduleIcon from "@mui/icons-material/Alarm";
import DueIcon from "@mui/icons-material/Timer";
import { usePrefs } from "../../../../services/prefs";
export default function IdColumn({ ticket }) {
    const { t } = useTranslation();
    const prefs = usePrefs();
    return ticket.is_draft ? (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
        } }, { children: [_jsx(Tooltip
            // @ts-ignore
            , Object.assign({ 
                // @ts-ignore
                title: t("tickets.draft") }, { children: _jsx(ErrorIcon, { style: {
                        color: "#f44336",
                    } }) })), _jsx(Box, Object.assign({ sx: { paddingLeft: 1 } }, { children: ticket.id }))] }))) : ticket.ticket_detail &&
        (ticket.ticket_detail.start_date_time || ticket.ticket_detail.due_date_time) ? (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexWrap: "wrap",
        } }, { children: [_jsxs(Box, Object.assign({ sx: {
                    display: "flex",
                } }, { children: [ticket.ticket_detail.start_date_time && (_jsx(Tooltip, Object.assign({ placement: "top", arrow: true, 
                        // @ts-ignore
                        title: t("tickets.scheduled") +
                            ": " +
                            (new Date(Date.parse(ticket.ticket_detail.start_date_time)).toLocaleDateString("en-GB") +
                                " " +
                                new Date(Date.parse(ticket.ticket_detail.start_date_time)).toLocaleTimeString("hr-HR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })) }, { children: _jsx(ScheduleIcon, { style: {
                                color: isBefore(new Date(Date.parse(ticket.ticket_detail.start_date_time)), new Date())
                                    ? prefs.theme === "light"
                                        ? "rgba(0, 0, 0, 0.26)"
                                        : "rgba(255, 255, 255, 0.26)"
                                    : "#ff9800",
                            } }) }))), ticket.ticket_detail && ticket.ticket_detail.due_date_time && (_jsx(Tooltip, Object.assign({ placement: "top", arrow: true, 
                        // @ts-ignore
                        title: t("tickets.due") +
                            ": " +
                            (new Date(Date.parse(ticket.ticket_detail.due_date_time)).toLocaleDateString("en-GB") +
                                " " +
                                new Date(Date.parse(ticket.ticket_detail.due_date_time)).toLocaleTimeString("hr-HR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })) }, { children: _jsx(DueIcon, { style: {
                                color: isBefore(new Date(Date.parse(ticket.ticket_detail.due_date_time)), new Date())
                                    ? prefs.theme === "light"
                                        ? "rgba(0, 0, 0, 0.26)"
                                        : "rgba(255, 255, 255, 0.26)"
                                    : "#f44336",
                            } }) })))] })), _jsx(Box, Object.assign({ sx: {
                    paddingLeft: "4px",
                } }, { children: ticket.id }))] }))) : (_jsx(Fragment, { children: ticket.id }));
}
