import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../elements/blocks/intro";
import Nope from "../../elements/blocks/nope";
export default function Error404() {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    return isShown ? (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("errors.404"), breadcrumbs: [
                    {
                        name: t("errors.error"),
                        link: "/error",
                    },
                ] }), _jsx(Nope, { title: t("errors.page-not-found"), caption: t("errors.page-does-not-exists") })] })) : (_jsx(Fragment, {}));
}
