import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ImportIcon from "@mui/icons-material/Publish";
import Table from "../blocks/table";
import BlacklistNumberDelete from "./blacklist-delete";
import { useAlert } from "../../../services/alert";
import { usePrefs } from "../../../services/prefs";
import { useApiCentral } from "../../../api/api-central";
import { useApiTenant } from "../../../api/api-tenant";
import { copy } from "../../../helpers/copy";
export default function BlacklistTable({ type, isAdded, setIsAdded }) {
    const { t } = useTranslation();
    const alert = useAlert();
    const prefs = usePrefs();
    const api = type == "client" ? useApiTenant() : useApiCentral();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isImportOpened, setIsImportOpened] = useState(false);
    const [importedFile, setImportedFile] = useState(null);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (!isAdded && !isDeleted)
            return;
        refreshTableData();
        if (isAdded)
            setIsAdded(false);
        if (isDeleted)
            setIsDeleted(false);
    }, [isAdded, isDeleted]);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_blacklistedNumbers(query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : "id", query.orderDirection ? query.orderDirection : "desc", query.search ? query.search : null)
                .then(response => {
                resolve({
                    data: response.data.blacklist_phones,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    const importBlacklistedNumbers = () => {
        if (!importedFile)
            return;
        setIsSaving(true);
        api.IMPORT_blacklistedNumbers(importedFile)
            .then(() => {
            alert.show(t("blacklist.blacklist-table.import.imported"), "info");
            setIsSaving(false);
            setIsImportOpened(false);
            setImportedFile(null);
            setIsAdded(true);
        })
            .catch(error => {
            console.error(error.response);
            alert.show(t("blacklist.blacklist-table.import.importing-error"), "error");
            setIsSaving(false);
            setIsImportOpened(false);
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Table, { title: type === "central"
                    ? "e-Registar NE ZOVI - HAKOM"
                    : t("blacklist.blacklisted-phones"), actions: [
                    {
                        icon: () => _jsx(RefreshIcon, { color: "action" }),
                        tooltip: t("blacklist.blacklist-table.refresh-phones"),
                        isFreeAction: true,
                        onClick: () => refreshTableData(),
                    },
                    {
                        icon: () => _jsx(ImportIcon, { color: "action" }),
                        tooltip: t("blacklist.blacklist-table.import.import-phones"),
                        isFreeAction: true,
                        onClick: () => setIsImportOpened(true),
                    },
                    type === "client"
                        ? {
                            icon: () => _jsx(DeleteIcon, { color: "action" }),
                            tooltip: t("blacklist.blacklist-table.delete.delete-phone"),
                            onClick: (event, data) => {
                                setIdToDelete(data.id);
                            },
                        }
                        : undefined,
                ], columns: isMobile
                    ? [
                        {
                            title: t("blacklist.phone") + " #",
                            field: "id",
                            defaultSort: "desc",
                        },
                    ]
                    : [
                        {
                            maxWidth: "50px",
                            title: "#",
                            field: "id",
                            type: "numeric",
                        },
                        {
                            title: t("blacklist.phone"),
                            sorting: false,
                            render: phone => {
                                return (_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: _jsx(Chip, { variant: "outlined", size: "small", label: phone.phone_area.phone_country.phone !== "00"
                                            ? phone.phone_area.phone_country.phone +
                                                " " +
                                                phone.phone_area.phone +
                                                " " +
                                                phone.number.substring(0, 4) +
                                                " " +
                                                phone.number.substring(4, phone.number.length)
                                            : phone.number, onDelete: () => {
                                            copy(phone.phone_area.phone_country.phone !==
                                                "00"
                                                ? phone.phone_area.phone_country
                                                    .phone +
                                                    phone.phone_area.phone +
                                                    phone.number
                                                : phone.number);
                                            alert.show(t("phone-copied"), "info");
                                        }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                            // @ts-ignore
                                            title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) }) })));
                            },
                        },
                        type === "client"
                            ? {
                                title: t("blacklist.blacklist-table.added-at"),
                                field: "created_at",
                                defaultSort: "desc",
                                render: phone => {
                                    return (new Date(Date.parse(phone.created_at)).toLocaleDateString("en-GB") +
                                        " " +
                                        new Date(Date.parse(phone.created_at)).toLocaleTimeString("hr-HR", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }));
                                },
                            }
                            : {
                                title: t("blacklist.blacklist-table.added-at"),
                                field: "updated_at_remote",
                                defaultSort: "desc",
                                render: phone => {
                                    return phone.updated_at_remote
                                        ? new Date(Date.parse(phone.updated_at_remote)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(phone.updated_at_remote)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })
                                        : new Date(Date.parse(phone.created_at)).toLocaleDateString("en-GB") +
                                            " " +
                                            new Date(Date.parse(phone.created_at)).toLocaleTimeString("hr-HR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            });
                                },
                            },
                    ], detailPanel: isMobile
                    ? (phone) => {
                        return (_jsx(Box, Object.assign({ sx: {
                                paddingX: 2,
                                paddingY: 3,
                            } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 3 }, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("blacklist.phone") })) })), _jsx(Chip, { variant: "outlined", size: "small", label: phone.phone_area.phone_country.phone !== "00"
                                                ? phone.phone_area.phone_country.phone +
                                                    " " +
                                                    phone.phone_area.phone +
                                                    " " +
                                                    phone.number.substring(0, 4) +
                                                    " " +
                                                    phone.number.substring(4, phone.number.length)
                                                : phone.number, onDelete: () => {
                                                copy(phone.phone_area.phone_country.phone !==
                                                    "00"
                                                    ? phone.phone_area.phone_country
                                                        .phone +
                                                        phone.phone_area.phone +
                                                        phone.number
                                                    : phone.number);
                                                alert.show(t("phone-copied"), "info");
                                            }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                // @ts-ignore
                                                title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) })] })) })) })));
                    }
                    : undefined, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }), idToDelete !== "" && (_jsx(BlacklistNumberDelete, { type: type, idToDelete: idToDelete, setIdToDelete: setIdToDelete, setIsDeleted: setIsDeleted })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isImportOpened, onClose: () => setIsImportOpened(false) }, { children: [_jsx(DialogTitle, { children: t("blacklist.blacklist-table.import.import-blacklisted-phones") }), _jsx(DialogContent, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(Dropzone, Object.assign({ maxFiles: 1, maxSize: 50000000, onDrop: files => {
                                        setImportedFile(files[0]);
                                    } }, { children: ({ getRootProps, getInputProps }) => (_jsx(Card, Object.assign({ variant: "outlined" }, getRootProps(), { children: _jsx(CardActionArea, { children: _jsxs(Box, Object.assign({ sx: {
                                                    height: "145px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                    backgroundColor: prefs.theme === "dark" ? "#424242" : "#fff",
                                                } }, { children: [importedFile ? (_jsx(Typography, { children: importedFile.name })) : (_jsx(Skeleton, { variant: "text", width: "200px", height: "170px" })), _jsx("input", Object.assign({}, getInputProps({
                                                        accept: type === "client" ? ".xlsx,.xls" : ".csv",
                                                    })))] })) }) }))) })), _jsx(FormHelperText, { children: type === "client" ? (_jsxs(Fragment, { children: [t("drag&drop") + " ", " ", _jsx("strong", { children: ".xlsx" }), " ", " " + t("or") + " ", " ", _jsx("strong", { children: ".xls" }), " ", " " + t("file")] })) : (_jsxs(Fragment, { children: [t("drag&drop") + " ", " ", _jsx("strong", { children: ".csv" }), " " + t("file")] })) })] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsImportOpened(false);
                                } }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", size: "small", loading: isSaving, disabled: !importedFile, onClick: () => {
                                    importBlacklistedNumbers();
                                }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("blacklist.blacklist-table.import.import-phones") }))] })] }))] }));
}
