import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Hidden from "@mui/material/Hidden";
import Badge from "@mui/material/Badge";
import Skeleton from "@mui/material/Skeleton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ClientsIcon from "@mui/icons-material/Business";
import InputSwitch from "../inputs/input-switch";
import { useAlert } from "../../../services/alert";
import { useApiCentral } from "../../../api/api-central";
import { cleanText, cleanDomain } from "../../../helpers/clean";
export default function ClientForm({ status, client }) {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const api = useApiCentral();
    const [name, setName] = useState((_a = client === null || client === void 0 ? void 0 : client.name) !== null && _a !== void 0 ? _a : "");
    const [domain, setDomain] = useState((_b = client === null || client === void 0 ? void 0 : client.domain) !== null && _b !== void 0 ? _b : undefined);
    const [logo, setLogo] = useState((_c = client === null || client === void 0 ? void 0 : client.logo_url) !== null && _c !== void 0 ? _c : "");
    const [logoDark, setLogoDark] = useState((_d = client === null || client === void 0 ? void 0 : client.logo_dark_url) !== null && _d !== void 0 ? _d : "");
    const [newLogo, setNewLogo] = useState(undefined);
    const [newLogoDark, setNewLogoDark] = useState(undefined);
    const [active, setActive] = useState((_e = client === null || client === void 0 ? void 0 : client.active) !== null && _e !== void 0 ? _e : true);
    const [seedTestData, setSeedTestData] = useState((_f = client === null || client === void 0 ? void 0 : client.seed_test_data) !== null && _f !== void 0 ? _f : false);
    const [isSaving, setIsSaving] = useState(false);
    const [domainError, setDomainError] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createClient = () => {
        setIsSaving(true);
        setIsError(false);
        api.CREATE_client(cleanText(name), domain === undefined && status === "new"
            ? name.split(" ").join("-").toLowerCase()
            : cleanDomain(domain), newLogo, newLogoDark, active, seedTestData)
            .then(response => {
            setIsSaving(false);
            alert.show(t("clients.client-form.client-created"), "success");
            navigate("/administration/clients/" + response.data.tenant.id);
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("clients.client-form.creating-error-occured"), "error");
        });
    };
    const updateClient = () => {
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_client(client.id, cleanText(name), cleanDomain(domain), newLogo, newLogoDark, active)
            .then(() => {
            setIsSaving(false);
            alert.show(t("clients.client-form.client-updated"), "success");
            navigate("/administration/clients/" + client.id);
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("clients.client-form.creating-error-occured"), "error");
        });
    };
    const validateDomain = (domain) => {
        if (domain.startsWith("-") || domain.endsWith("-")) {
            setDomainError(true);
        }
        else {
            setDomainError(false);
        }
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ClientsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("clients.client-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, Object.assign({ component: "form" }, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: isError ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name") : false }, { children: [_jsx(InputLabel, { children: t("clients.client-attributes.client-name") }), _jsx(OutlinedInput, { label: t("clients.client-attributes.client-name") +
                                                                    " *", value: name, onChange: event => {
                                                                    setName(event.target.value);
                                                                    if (isError)
                                                                        setIsError(false);
                                                                } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["name"][0] })))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: isError
                                                            ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("domain")
                                                            : domainError }, { children: [_jsx(InputLabel, { children: t("clients.client-attributes.client-domain") }), _jsx(OutlinedInput, { label: t("clients.client-attributes.client-domain") +
                                                                    " *", endAdornment: "." + location.host, value: domain === undefined && status === "new"
                                                                    ? name.split(" ").join("-").toLowerCase()
                                                                    : domain, onChange: event => {
                                                                    validateDomain(event.target.value);
                                                                    setDomain(event.target.value
                                                                        ? cleanDomain(event.target.value)
                                                                        : "");
                                                                    if (isError)
                                                                        setIsError(false);
                                                                } })] })), domainError ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: t("campaigns.campaign-form.domain-error-message") }))) : isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("domain")) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["domain"][0] }))) : ("")] })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(InputSwitch, { label: t("clients.client-attributes.active"), required: false, checked: active ? active : false, setChecked: () => setActive(!active) }) })), process.env.APP_ENV !== "production" && status === "new" && (_jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(InputSwitch, { label: t("clients.client-attributes.seed-test-data"), required: false, checked: seedTestData ? seedTestData : false, setChecked: () => setSeedTestData(!seedTestData) }) }))), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Badge, Object.assign({ sx: {
                                                        width: "100%",
                                                        color: "rgb(244, 67, 54)",
                                                    }, invisible: logo === "" &&
                                                        (newLogo === undefined || newLogo === null), badgeContent: _jsx(Box, Object.assign({ sx: {
                                                            paddingBottom: 0.5,
                                                        } }, { children: _jsx(Tooltip
                                                        // @ts-ignore
                                                        , Object.assign({ 
                                                            // @ts-ignore
                                                            title: t("clients.client-form.remove-logo"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ style: {
                                                                    color: "rgb(244, 67, 54)",
                                                                }, onClick: () => {
                                                                    if (logo) {
                                                                        setLogo("");
                                                                        setNewLogo(null);
                                                                    }
                                                                }, size: "large" }, { children: _jsx(RemoveCircleIcon, {}) })) })) })) }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(Dropzone, Object.assign({ maxFiles: 1, maxSize: 50000000, onDrop: files => {
                                                                    setNewLogo(files[0]);
                                                                } }, { children: ({ getRootProps, getInputProps }) => (_jsx(Card, Object.assign({ variant: "outlined" }, getRootProps(), { children: _jsx(CardActionArea, { children: _jsxs(Box, Object.assign({ sx: {
                                                                                height: "150px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                backgroundColor: "#fff",
                                                                            } }, { children: [newLogo ? (_jsx("img", { src: URL.createObjectURL(newLogo), width: "200", height: "100", alt: "Logo", style: {
                                                                                        objectFit: "contain",
                                                                                    } })) : logo ? (_jsx("img", { src: logo, width: "200", height: "100", alt: "Logo", style: {
                                                                                        objectFit: "contain",
                                                                                    } })) : (_jsx(Skeleton, { width: "200px", height: "150px" })), _jsx("input", Object.assign({}, getInputProps({
                                                                                    accept: "image/*,image/svg+xml",
                                                                                })))] })) }) }))) })), _jsx(FormHelperText, { children: t("clients.client-form.drag&drop-logo") })] })) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Badge, Object.assign({ sx: {
                                                        width: "100%",
                                                        color: "rgb(244, 67, 54)",
                                                    }, invisible: logoDark === "" &&
                                                        (newLogoDark === undefined || newLogoDark === null), badgeContent: _jsx(Box, Object.assign({ sx: {
                                                            paddingBottom: 0.5,
                                                        } }, { children: _jsx(Tooltip
                                                        // @ts-ignore
                                                        , Object.assign({ 
                                                            // @ts-ignore
                                                            title: t("clients.client-form.remove-dark-logo"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ style: {
                                                                    color: "rgb(244, 67, 54)",
                                                                }, onClick: () => {
                                                                    if (logoDark) {
                                                                        setLogoDark("");
                                                                        setNewLogoDark(null);
                                                                    }
                                                                }, size: "large" }, { children: _jsx(RemoveCircleIcon, {}) })) })) })) }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(Dropzone, Object.assign({ maxFiles: 1, maxSize: 50000000, onDrop: files => {
                                                                    setNewLogoDark(files[0]);
                                                                } }, { children: ({ getRootProps, getInputProps }) => (_jsx(Card, Object.assign({ variant: "outlined" }, getRootProps(), { children: _jsx(CardActionArea, { children: _jsxs(Box, Object.assign({ sx: {
                                                                                height: "150px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                backgroundColor: "#424242",
                                                                            } }, { children: [newLogoDark ? (_jsx("img", { src: URL.createObjectURL(newLogoDark), width: "200", height: "100", alt: "Logo", style: {
                                                                                        objectFit: "contain",
                                                                                    } })) : logoDark ? (_jsx("img", { src: logoDark, width: "200", height: "100", alt: "Logo", style: {
                                                                                        objectFit: "contain",
                                                                                    } })) : (_jsx(Skeleton, { width: "200px", height: "150px" })), _jsx("input", Object.assign({}, getInputProps({
                                                                                    accept: "image/*,image/svg+xml",
                                                                                })))] })) }) }))) })), _jsx(FormHelperText, { children: t("clients.client-form.drag&drop-dark-logo") })] })) })) }))] })) })) })) }))] })) })), _jsx(Box, Object.assign({ sx: {
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: 2,
                                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !name ||
                                        (status === "edit" && !domain) ||
                                        (status === "new" && domain === "") ||
                                        isError ||
                                        domainError, onClick: () => {
                                        status == "new" ? createClient() : updateClient();
                                    }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status == "new"
                                        ? t("form-component.create")
                                        : t("form-component.update") })) })) }))] })) }))] }));
}
