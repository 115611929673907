import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "../../../../services/alert";
import { useApiTenant } from "../../../../api/api-tenant";
export default function CampaignStepDelete({ step, setRefreshSteps, campaign }) {
    const { t } = useTranslation();
    const alert = useAlert();
    const api = useApiTenant();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const deleteStep = () => {
        setIsDeleting(true);
        api.DELETE_campaignStep(step.id, campaign.id)
            .then(() => {
            setIsDeleting(false);
            setRefreshSteps(true);
            alert.show(t("campaigns.campaign-steps.campaign-step-delete.step-deleted"), "warning");
        })
            .catch(error => {
            setIsDeleting(false);
            console.error(error.response);
            if (error.response.status === 422) {
                alert.show(error.response.data.message, "warning");
            }
            else {
                alert.show(t("campaigns.campaign-steps.campaign-step-delete.deleting-error"), "error");
            }
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: step.type === "form" ? 4 : 0,
                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", size: "small", loading: isDeleting, onClick: () => {
                        setIsOpened(true);
                    }, startIcon: _jsx(DeleteIcon, {}), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("campaigns.campaign-steps.campaign-step-delete.delete-step") })) })), _jsxs(Dialog, Object.assign({ open: isOpened, onClose: () => {
                    setIsOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: t("campaigns.campaign-steps.campaign-step-delete.confirm") }), step.type === "form" || step.type === "choice" ? (_jsx(Alert, Object.assign({ severity: "error" }, { children: t("campaigns.campaign-steps.campaign-step-delete.alert") +
                                    " " +
                                    t("delete-component.confirm-caption") }))) : step.type === "group" ? (_jsx(Alert, Object.assign({ severity: "warning" }, { children: t("campaigns.campaign-steps.campaign-step-delete.alert-groups") +
                                    " " +
                                    t("delete-component.confirm-caption") }))) : (_jsx(DialogContentText, { children: t("delete-component.confirm-caption") }))] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    deleteStep();
                                    setIsOpened(false);
                                } }, { children: t("delete-component.delete") }))] })] }))] }));
}
