export var exportTicketTabs;
(function (exportTicketTabs) {
    exportTicketTabs[exportTicketTabs["FilterBy"] = 0] = "FilterBy";
    exportTicketTabs[exportTicketTabs["ExportOptions"] = 1] = "ExportOptions";
})(exportTicketTabs || (exportTicketTabs = {}));
export var ticketDetailAttributes;
(function (ticketDetailAttributes) {
    ticketDetailAttributes["Id"] = "id";
    ticketDetailAttributes["Status"] = "status";
    ticketDetailAttributes["Priority"] = "priority";
    ticketDetailAttributes["Assignee"] = "assignees";
    ticketDetailAttributes["Queues"] = "queues";
    ticketDetailAttributes["StartDateTime"] = "start_date_time";
    ticketDetailAttributes["DueDateTime"] = "due_date_time";
    ticketDetailAttributes["Estimate"] = "estimate";
    ticketDetailAttributes["Progress"] = "progress";
    ticketDetailAttributes["ForwardedCall"] = "forwarded_call";
    ticketDetailAttributes["Notes"] = "notes";
    ticketDetailAttributes["TotalWorkingTime"] = "total_working_time";
    ticketDetailAttributes["IsDraft"] = "is_draft";
    ticketDetailAttributes["ContactIsNew"] = "contact_is_new";
    ticketDetailAttributes["CreatedAt"] = "created_at";
    ticketDetailAttributes["UpdatedAt"] = "updated_at";
    ticketDetailAttributes["CreatedBy"] = "created_by";
    ticketDetailAttributes["UpdatedBy"] = "updated_by";
})(ticketDetailAttributes || (ticketDetailAttributes = {}));
